import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from 'react-bootstrap/Popover'
import Swiper from "react-id-swiper";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { commaize } from "../Common";
import "./LandingPage.css";

SwiperCore.use([Pagination]);

const Resources = (props) => {
  const [resources, setResouces] = useState([]);
  const [referredLoanOfficers, setReferredLoanOfficers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mobileResouceCount, setMobileResouceCount] = useState(1);

  const isMobile = props.width <= 480;
  const resourceSwiperRef = useRef(null);
  const resourceGoNext = () => {
    if (resourceSwiperRef.current && resourceSwiperRef.current.swiper) {
      resourceSwiperRef.current.swiper.slideNext();
    }
  };
  const resourceGoPrev = () => {
    if (resourceSwiperRef.current && resourceSwiperRef.current.swiper) {
      resourceSwiperRef.current.swiper.slidePrev();
    }
  };

  const getResouces = () => {
    fetch("/re/getresources", {
      method: "GET",
      headers: {
        Cache: "no-cache",
        "X-RE": props.id,
      },
    }).then((response) => {
      if (response.status !== 200) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return;
      }
      response.json().then((r) => {
        console.log(r);
        if (r) {
          setResouces(r);
        }
      });
    });
  };

  const getReferredLoanOfficers = () => {
    fetch("/re/getconnectedloanofficers", {
      method: "GET",
      headers: {
        Cache: "no-cache",
        "X-RE": props.id,
      },
    }).then((response) => {
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((r) => {
        console.log(r);
        setReferredLoanOfficers(r);
      });
    });
  };

  const getLOIntroduction = (intro) => {
    if (intro.length <= 250) {
      return intro;
    }
    return (
      <OverlayTrigger
        overlay={
          <Popover style={{padding: "10px"}}>
            {intro}
          </Popover>
        }
      >
        <div>
        {intro.slice(0, 250) + "..."}{" "}
        <a style={{textDecoration:"underline"}}>see more</a>
        </div>
      </OverlayTrigger>
    );
  };

  useEffect(() => {
    getResouces();
    getReferredLoanOfficers();
    console.log(isMobile)
  }, []);

  useEffect(() => {
    console.log(referredLoanOfficers);
    console.log(resources);
  }, [referredLoanOfficers, resources]);

  const getLOCard = (data) => {

    if (data.LandingUrl === "") {
      return (
        <div className="loInfo">
          <div>
            <img className="loImage" src="/images/defaultAvatar.png" />
            <div className="loName">{data.FirstName}</div>

            <div className="loIntroduction">
              <p>Email: {data.Email}</p>
              <p>Phone: {data.Phone}</p>
            </div>
          </div>
          <hr className="loDivider" />
          <div className="loButtonGroup">
            <Button disabled variant="loButton" onClick={()=>window.open("https://" + window.location.hostname + data.LandingUrl, "_blank")}>View My Page</Button>
            <Button disabled variant="loButton" onClick={()=>window.open("https://" + window.location.hostname + data.LandingUrl, "_blank")}>Contact me</Button>
          </div>
          <div className="loButtonGroup2">
            <Button disabled variant="loButton2" onClick={()=>window.open("https://" + window.location.hostname + data.LandingUrl, "_blank")}>Get Pre-Qualified</Button>
          </div>
        </div>
      );
    }

    return (
      <div className="loInfo">
        <div>
          <img className="loImage" src={data.Photo} />
          <div className="loName">{data.FirstName + " " + data.LastName}</div>
          <div className="loNMLS">NMLS: {data.NMLS}</div>
          <div className="loIntroduction">
            {getLOIntroduction(data.Introduction)}
          </div>
        </div>
        <hr className="loDivider" />
        <div className="loButtonGroup">
          <Button variant="loButton" onClick={()=>window.open("https://" + window.location.hostname + data.LandingUrl, "_blank")}>View My Page</Button>
          <Button variant="loButton" onClick={()=>window.open("https://" + window.location.hostname + data.LandingUrl, "_blank")}>Contact me</Button>
        </div>
        <div className="loButtonGroup2">
          <Button variant="loButton2" onClick={()=>window.open("https://" + window.location.hostname + data.LandingUrl, "_blank")}>Get Pre-Qualified</Button>
        </div>
      </div>
    );
  };

  const showLos = () => {
    if (referredLoanOfficers=== null || referredLoanOfficers.length === 0) {
      return "";
    }
    let out = [];
    let slideCount = Math.floor(referredLoanOfficers.length / 3);
    if (referredLoanOfficers.length % 3 > 0) {
      slideCount += 1;
    }
    for (let i = 0; i < slideCount; i++) {
      out.push(
        <div>
          <Container>
            <Row>
              <Col xs={12} lg={4} className="loCardCol">
                {getLOCard(referredLoanOfficers[i * 3 + 0])}
              </Col>
              <Col xs={12} lg={4} className="loCardCol">
                {i * 3 + 1 <= referredLoanOfficers.length - 1
                  ? getLOCard(referredLoanOfficers[i * 3 + 1])
                  : ""}
              </Col>
              <Col xs={12} lg={4} className="loCardCol">
                {i * 3 + 2 <= referredLoanOfficers.length - 1
                  ? getLOCard(referredLoanOfficers[i * 3 + 2])
                  : ""}
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return  (
      <div>
        <Row>
      <Col className="resourcesTitle3 mt-5" md={12}>
        MORTGAGE OFFICERS
      </Col>
        </Row>
  <Swiper>
    {out}
  </Swiper>
  </div>
    )
 
  };

  const getResourceCard = (data, i) => {
    if (isMobile) {
      let st = { display: "block" };
      if (i >= mobileResouceCount * 6) {
        st = { display: "none" };
      }
      return (
        <div className="resourceInfo" style={st}>
            <div className="resourceName">{data.Name}</div>
            <div className="resourceType">{data.Type}</div>
            <div className="resourcePhone">{data.Phone}</div>
            <div className="resourceEmail">{data.Email}</div>
        </div>
      );
    }

    return (
      <div className="resourceInfo">
        <div className="resourceName">{data.Name}</div>
        <div className="resourceType">{data.Type}</div>
        <div className="resourcePhone">{data.Phone}</div>
        <div className="resourceEmail">{data.Email}</div>
      </div>
    );
  };

  const showResources = () => {
    if (resources.length === 0) {
      return "";
    }

    let out = [];

    if (isMobile) {
      let slideCount = Math.floor(resources.length / 6);
      if (resources.length % 6 > 0) {
        slideCount += 1;
      }
      for (let i = 0; i < resources.length; i++) {
        out.push(getResourceCard(resources[i], i));
      }
      if (slideCount > 1 && slideCount > mobileResouceCount) {
        out.push(
          <div className="text-center">
            <Button
              variant="showMoreResouces"
              onClick={() => setMobileResouceCount(mobileResouceCount + 1)}
            >
              Check more{" "}
              <img src="/images/checkMoreSign.png" style={{ width: "25px" }} />
            </Button>
          </div>
        );
      }
    } else {
      let slideCount = Math.floor(resources.length / 8);
      if (resources.length % 8 > 0) {
        slideCount += 1;
      }
      for (let i = 0; i < slideCount; i++) {
        out.push(
          <div>
            <Container>
              <Row>
                <Col>{getResourceCard(resources[i * 8 + 0])}</Col>
                <Col>
                  {i * 8 + 1 <= resources.length - 1
                    ? getResourceCard(resources[i * 8 + 1])
                    : ""}
                </Col>
                <Col>
                  {i * 8 + 2 <= resources.length - 1
                    ? getResourceCard(resources[i * 8 + 2])
                    : ""}
                </Col>
                {i * 8 + 3 <= resources.length - 1 && (
                  <Col>{getResourceCard(resources[i * 8 + 3])}</Col>
                )}


                {i * 8 + 4 <= resources.length - 1 && (
                  <Col>{getResourceCard(resources[i * 8 + 4])}</Col>
                )}
                {i * 8 + 5 <= resources.length - 1 && (
                  <Col>{getResourceCard(resources[i * 8 + 5])}</Col>
                )}
                {i * 8 + 6 <= resources.length - 1 && (
                  <Col>{getResourceCard(resources[i * 8 + 6])}</Col>
                )}
                {i * 8 + 7 <= resources.length - 1 && (
                  <Col>{getResourceCard(resources[i * 8 + 7])}</Col>
                )}
              </Row>
            </Container>
          </div>
        );
      }
    }

    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => {
          return '<span class="' + className + '" >' + (index + 1) + "</span>";
        },
      },
    };

    return (
      <div>
        <hr className="resouceDivider" />
        <Row className="resourceHeader">
          <Col className="resourcesTitle3" md={12}>
            THIRD PARTY RESOURCES
          </Col>
          {/* {!isMobile && slideCount > 1 && (
            <div>
              {" "}
              <a className="slideArrow " onClick={resourceGoPrev}>
                ←
              </a>
              <a className="slideArrow" onClick={resourceGoNext}>
                →
              </a>
            </div>
          )} */}
        </Row>
        
        {isMobile ? (
          <div>{out}</div>
        ) : (
          // <Swiper ref={resourceSwiperRef}>{out}</Swiper>
          <Swiper {...params}>{out}</Swiper>
        )}
      </div>
    );
  };

  return (
    <Container>
      <Row>
        <Col className="resourcesTitle1">RECOMMENDATIONS</Col>
      </Row>
      <Row>
        <Col className="resourcesTitle2">
          <a style={{ color: "#6E6E70" }}>These guys are </a> awesome
        </Col>
      </Row>
      <Row>

        {/* <Col md={{ span: 4, offset: 4 }} className="text-right">
          <a className="slideArrow" onClick={()=>{console.log("ajj")}}>←</a>
          <a className="slideArrow">→</a>
        </Col> */}
      </Row>
      {showLos()}
      {showResources()}
    </Container>
  );
};

export default Resources;
