import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Modal, Switch, Button, Radio, DatePicker, InputNumber, Space, Select } from 'antd';
import dayjs from 'dayjs';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import './AutoReminderModal.css';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
    }
}
const mapDispatchToProps = (dispatch) => ({
    
})
export default function AutoReminderModal(props) {
    const {
        open, settings, lastReminder, type, save, changeWhenLoanFunded, onCancel
    } = props
    const [frequency, setFrequency] = useState('every2Days')
    const [terminationConditions, setTerminationConditions] = useState(true)
    const [date, setDate] = useState(null)
    const [days, setDays] = useState(0)
    const history = useHistory()
    useEffect(() => {
        if (open === true) {
            setFrequency(settings.ReminderFrequency ? settings.ReminderFrequency : undefined)
            setTerminationConditions(settings.EndWhen.WhenLoanFunded === false ? false : true)
            setDate(settings.EndWhen.WhenDate ? dayjs(settings.EndWhen.WhenDate) : null)
            setDays(settings.EndWhen.AfterNumberOfDays ? settings.EndWhen.AfterNumberOfDays : 0)
        }
    }, [open, settings])

    const reminderCancel = () => {
        onCancel()
    }

    const onChangeFrequency = (value) => {
        setDate(null)
        setFrequency(value)
    }
    const onChangeTerminationConditions = (e) => {
        setTerminationConditions(e.target.value)
        if (changeWhenLoanFunded) {
            changeWhenLoanFunded(e.target.value)
        }
    }
    const onChangeDays = (value) => {
        if (value === null || value === undefined) {
            setDays(0)
        } else {
            setDays(value)
        }
    }
    const onChangeDate = (date) => {
        setDate(date)
    }
    const reminderSave = () => {
        let data = {
            TurnedOn: settings.TurnedOn,
            ReminderFrequency: frequency,
            EndWhen: {
                WhenLoanFunded: terminationConditions,
                WhenDate: date,
                AfterNumberOfDays: days
            }
        }
        save(data)
    }
    const footer = [
        <Button style={{maxWidth: 300}} className="reminder-btn-cancel" onClick={reminderCancel}>Cancel</Button>,
        <Button style={{maxWidth: 300}} disabled={type !== 'futureLoans' && terminationConditions === false && !date} className="reminder-btn-confirm" type="primary" onClick={reminderSave}>Save</Button>
    ]
    const frequencyOptions = [
        {
            label: 'Daily',
            value: 'daily',
        },
        {
            label: 'Every 2 days',
            value: 'every2Days',
        },
        {
            label: 'Every 3 days',
            value: 'every3Days',
        },
        {
            label: 'Weekly',
            value: 'weekly',
        },
        {
            label: 'Bi-weekly',
            value: 'biWeekly',
        }
    ]

    const disabledDate = (current) => {
        const dateMap = {
            'daily': 1,
            'every2Days': 2,
            'every3Days': 3,
            'weekly': 7,
            'biWeekly': 14
        }
        return current && current < dayjs().endOf('day').add(dateMap[frequency], 'day')
    }
    const toSettings = () => {
        history.push({
            pathname: '/app/creditpullsetting',
            state: { selected: 'feature' }
        })
    }
    return (
        <>
            <Modal
                className='auto-reminder-modal'
                maskStyle={{ backgroundColor: 'rgba(34, 34, 34, 0.80)' }}
                destroyOnClose
                maskClosable={true}
                width={744}
                open={open}
                title=""
                onCancel={reminderCancel}
                footer={footer}
            >
                <div className='auto-reminder-content'>
                    <div className='auto-reminder-switch'>{type === 'futureLoans' ? 'Auto-Reminder for Documents' : 'Set Auto-Reminder'}</div>
                    <div className='auto-reminder-tip'>{type === 'futureLoans' ? 'The setting will apply to your future loans, but not to the existing ones.' : lastReminder ? `Last reminder sent on ${lastReminder}.` : 'Customize reminder frequency and end condition for this loan.'}</div>
                    <div style={{marginBottom: '56px'}}>
                        <div className='auto-reminder-form-label'>Reminder frequency</div>
                        <Select
                            className="auto-reminder-frequency-select"
                            value={frequency}
                            onChange={onChangeFrequency}
                            placeholder="Select reminder frequency"
                            options={frequencyOptions}
                            suffixIcon={<img src="/images/auto-reminder-select-arrow.svg" />}
                        />
                    </div>
                    <div style={{marginBottom: '68px'}}>
                        <div className='auto-reminder-form-label'>{type === 'futureLoans' ? 'End auto-reminder for a loan when' : 'Auto-reminder will end when'}</div>
                        {type !== 'futureLoans' && <div className='auto-reminder-form-subLabel'>If all document requests are uploaded, no reminders will be sent.</div>}
                        <div style={{padding: '14px 10px'}}>
                            <Radio.Group onChange={onChangeTerminationConditions} value={terminationConditions}>
                                <Space size={12} direction="vertical">
                                    <Radio className='auto-reminder-radio' value={true}><span className='auto-reminder-radio-label'>{type === 'futureLoans' ? 'When loan status becomes “Loan funded”' : 'Loan status becomes “Loan funded”'}</span></Radio>
                                    <Radio className='auto-reminder-radio' value={false}>
                                        <span className='auto-reminder-radio-label'>{type === 'futureLoans' ? 'After a total number of days' : 'On a specific date'}</span>
                                        {
                                            type === 'futureLoans' ? (
                                                <InputNumber
                                                    style={{
                                                        width: '80px',
                                                        marginInlineStart: 22,
                                                        backgroundColor: '#FCFCFC',
                                                        border: '1px solid #ECECEC'
                                                    }}
                                                    className='auto-reminder-input-number'
                                                    value={days}
                                                    onChange={onChangeDays}
                                                    min={0} 
                                                    max={30}
                                                    precision={0}
                                                    keyboard 
                                                />
                                            ) : (
                                                <DatePicker
                                                    style={{
                                                        width: '160px',
                                                        height: '40px',
                                                        marginInlineStart: 14,
                                                        backgroundColor: '#FCFCFC',
                                                        border: '1px solid #ECECEC'
                                                    }}
                                                    className='auto-reminder-date'
                                                    value={date}
                                                    onChange={onChangeDate}
                                                    disabledDate={disabledDate}
                                                    placeholder="MM/DD/YYYY"
                                                    format="MM/DD/YYYY"
                                                    suffixIcon={null}
                                                />
                                            )
                                        }
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                        {type !== 'futureLoans' && <div style={{fontFamily: 'Inter', fontSize: 14, margin: '68px 0 56px', color: '#6E6E70'}}>To manage default auto-reminders for future loans, go to <span style={{color: '#325CEB', fontWeight: 600, textDecorationLine: 'underline', cursor: 'pointer'}} onClick={toSettings}>Settings - Feature Customization</span></div>}
                    </div>
                </div>
            </Modal>
        </>
    )
}
AutoReminderModal = connect(mapStateToProps, mapDispatchToProps)(AutoReminderModal)