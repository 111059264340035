import React, { useState, useEffect } from "react";
import "./index.css";
import { Menu, Button } from "antd";



const BillingManagement = () => {



    return (
        <div className='mx-3'>

            <div className='User_title mt-4 mb-3 ml-4 mr-5 Support'>
                <div>
                    Billing Management
                </div>
                {/* <div >
                    <Button className='ant-btn-default-zeitro' onClick={() => {


                        setSupportModalOpen(true)
                    }}>Support</Button>
                </div> */}

            </div>
            <div className="Billing_title ml-4">How to access the following features in your Zeitro Billing Portal powered by Stripe</div>
            <div className="Billing_content ml-4">
                <div className="ul_content">
                    <ul>
                        <li>View current plan</li>
                        <li>View invoice history</li>
                        <li>Update billing information</li>
                        <li>Update payment method</li>
                    </ul>
                </div>

                <div>
                    <img src="/images/stripe.svg"></img>
                </div>
            </div>
            <div className="Billing_bottom ml-4 mt-4">
                <div style={{ color: '#797979', }}>Please follow these steps</div>
                <ol>
                    <li>Click <span style={{ color: ' #325CEB', cursor: 'pointer' }}>Continue</span> to be redirected to the Billing Portal login page.</li>
                    <li>Enter your email address.</li>
                    <li>Use the link received in your email to log in.</li>

                </ol>
            </div>


            <div className="billingButton">

                <Button type='primary' style={{
                    height: "44px", width: '220px',
                }} onClick={()=>{
                    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('zeitro.us')) {
                    window.open("https://billing.stripe.com/p/login/test_cN214oeAa7Ip3BecMM", "_blank")
                    } else {
                    window.open("https://billing.stripe.com/p/login/cN20188QT87Ebo46oo", "_blank")
                    }
                }}>Continue</Button>
            </div>
        </div>
    );
};

export default BillingManagement;
