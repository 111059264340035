import React, { Component } from "react";
import Calendar from "react-calendar";
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import "./App.css";
import "./Calendar.css";
export default class DateOfBirth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      max: false,
    };
    let now = new Date();
    //now.setFullYear(now.getFullYear() - 18)
    this.max = now;
    if (this.props.maxdate) this.max = this.props.maxdate;
    this.ref = React.createRef();
    if (this.props.onRef) {
      this.props.onRef(this.ref);
    }
  }
  componentDidMount() {}

  componentWillUnmount() {}

  onChange = (e) => {
    this.props.onChange(e);
  };
  show = (e) => {};
  hide = (e) => {
    this.setState({ show: false });
  };
  press = (e) => {
    this.setState({ show: true });
  };
  setCustomValidity(what) {
    if (this.ref.current) this.ref.current.setCustomValidity(what);
  }
  setFocus() {
    if (this.ref.current) this.ref.current.setFocus();
  }
  container = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  dateSelected = (e) => {
    let v =
      ("0" + (e.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + e.getDate()).slice(-2) +
      "/" +
      e.getFullYear();

    this.props.onChange({ target: { value: v } });
    this.setState({ show: false });
  };
  seed = () => {
    if (this.props.value === "") {
      return new Date();
    } else {
      let n = new Date(this.props.value);
      let old = new Date(1960, 0, 1);
      if (n < old) return old;
      return n;
    }
  };
  renderCalendar = () => {
    return (
      <div
        id="myModal"
        style={{ display: "block" }}
        className="mymodal"
        onClick={this.hide}
      >
        <div
          style={{ borderWidth: "0px", backgroundColor: "rgb(0,0,0,0.0)" }}
          className="mymodal-content"
          onClick={this.container}
        >
          <div
            style={{ backgroundColor: "rgb(0,0,0,0.0)" }}
            className="  text-right p-0 m-0"
          >
            <i
              onClick={this.hide}
              style={{ cursor: "pointer" }}
              className=" p-0 m-0 fa fa-window-close"
            ></i>
          </div>
          <div>
            <Calendar
              onChange={this.dateSelected}
              maxDate={this.max}
              minDate={new Date(1960, 0, 1)}
              value={this.seed()}
              calendarType={"Hebrew"}
              //   defaultView={"year"}  //"decade", "century"
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <span>
        <InputGroup className="">
          <Form.Control
            name={this.props.name}
            required={this.props.required}
            size={this.props.size}
            value={this.props.value}
            onChange={this.props.onChange}
            pattern={this.props.pattern}
            isValid={this.props.isValie}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            ref={this.ref}
            type="text"
          ></Form.Control>
          <InputGroup.Text
            style={{ paddingRight: "8px", paddingLeft: "8px" }}
            className="btn-dropdown  mx-0 form-control-sm"
            size="sm"
            onClick={this.press}
          >
            <i
              className="fas fa-caret-down"
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            ></i>
          </InputGroup.Text>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {this.props.negativefeedback}
          </Form.Control.Feedback>
        </InputGroup>
        {this.state.show ? this.renderCalendar() : ""}
      </span>
    );
  }
}
