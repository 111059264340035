let occupations=[
"Architecture and Engineering",
"Arts and Design",
"Building and Grounds Cleaning",
"Business and Financial",
"Community and Social Service",
"Computer and Information Technology",
"Construction and Extraction",
"Education, Training, and Library",
"Entertainment and Sports",
"Farming, Fishing, and Forestry",
"Food Preparation and Serving",
"Healthcare",
"Installation, Maintenance, and Repair",
"Legal",
"Life, Physical, and Social Science",
"Management",
"Math",
"Media and Communication",
"Military",
"Office and Administrative Support",
"Personal Care and Service",
"Production",
"Protective Service",
"Sales",
"Transportation and Material Moving",
"Accommodation",
"Accommodation and Food Services",
"Administrative and Support Services",
"Administrative and Support and Waste Management and Remediation Services",
"Agriculture, Forestry, Fishing and Hunting",
"Air Transportation",
"Ambulatory Health Care Services",
"Amusement, Gambling, and Recreation Industries",
"Animal Production",
"Apparel Manufacturing",
"Arts, Entertainment, and Recreation",
"Beverage and Tobacco Product Manufacturing",
"Broadcasting (except Internet)",
"Building Material and Garden Equipment and Supplies Dealers",
"Chemical Manufacturing",
"Clothing and Clothing Accessories Stores",
"Computer and Electronic Product Manufacturing",
"Construction",
"Construction of Buildings",
"Couriers and Messengers",
"Credit Intermediation and Related Activities",
"Crop Production",
"Data Processing, Hosting, and Related Services",
"Education and Health Services",
"Educational Services",
"Electrical Equipment, Appliance, and Component Manufacturing",
"Electronics and Appliance Stores",
"Fabricated Metal Product Manufacturing",
"Finance and Insurance",
"Financial Activities",
"Fishing, Hunting and Trapping",
"Food Manufacturing",
"Food Services and Drinking Places",
"Food and Beverage Stores",
"Forestry and Logging",
"Funds, Trusts, and Other Financial Vehicles",
"Furniture and Home Furnishings Stores",
"Furniture and Related Product Manufacturing",
"Gasoline Stations",
"General Merchandise Stores",
"Goods-Producing Industries",
"Health Care and Social Assistance",
"Health and Personal Care Stores",
"Heavy and Civil Engineering Construction",
"Hospitals",
"Information",
"Insurance Carriers and Related Activities",
"Internet Publishing and Broadcasting",
"Leather and Allied Product Manufacturing",
"Leisure and Hospitality",
"Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
"Machinery Manufacturing",
"Management of Companies and Enterprises",
"Manufacturing",
"Merchant Wholesalers, Durable Goods",
"Merchant Wholesalers, Nondurable Goods",
"Mining (except Oil and Gas)",
"Mining, Quarrying, and Oil and Gas Extraction",
"Miscellaneous Manufacturing",
"Miscellaneous Store Retailers",
"Monetary Authorities - Central Bank",
"Motion Picture and Sound Recording Industries",
"Motor Vehicle and Parts Dealers",
"Museums, Historical Sites, and Similar Institutions",
"Natural Resources and Mining",
"Nonmetallic Mineral Product Manufacturing",
"Nonstore Retailers",
"Nursing and Residential Care Facilities",
"Oil and Gas Extraction",
"Other Information Services",
"Other Services (except Public Administration)",
"Paper Manufacturing",
"Performing Arts, Spectator Sports, and Related Industries",
"Personal and Laundry Services",
"Petroleum and Coal Products Manufacturing",
"Pipeline Transportation",
"Plastics and Rubber Products Manufacturing",
"Postal Service",
"Primary Metal Manufacturing",
"Printing and Related Support Activities",
"Private Households",
"Professional and Business Services",
"Professional, Scientific, and Technical Services",
"Publishing Industries (except Internet)",
"Rail Transportation",
"Real Estate",
"Real Estate and Rental and Leasing",
"Religious, Grantmaking, Civic, Professional, and Similar Organizations",
"Rental and Leasing Services",
"Repair and Maintenance",
"Retail Trade",
"Scenic and Sightseeing Transportation",
"Securities, Commodity Contracts, and Other Financial Investments and Related Activities",
"Service-Providing Industries",
"Social Assistance",
"Specialty Trade Contractors",
"Sporting Goods, Hobby, Book, and Music Stores",
"Support Activities for Agriculture and Forestry",
"Support Activities for Mining",
"Support Activities for Transportation",
"Telecommunications",
"Textile Mills",
"Textile Product Mills",
"Trade, Transportation, and Utilities",
"Transit and Ground Passenger Transportation",
"Transportation Equipment Manufacturing",
"Transportation and Warehousing",
"Truck Transportation",
"Utilities",
"Warehousing and Storage",
"Waste Management and Remediation Services",
"Water Transportation",
"Wholesale Electronic Markets and Agents and Brokers",
"Wholesale Trade",
"Wood Product Manufacturing",
]
 /*
let titles = [
 'Architecture and Engineering Occupations',
 'Aerospace Engineering and Operations Technicians',
 'Aerospace Engineers',
 'Agricultural Engineers',
 'Architects, Except Landscape and Naval',
 'Architectural Drafters',
 'Automotive Engineering Technicians',
 'Automotive Engineers',
 'Biochemical Engineers',
 'Biomedical Engineers',
 'Cartographers and Photogrammetrists',
 'Chemical Engineers',
 'Civil Drafters',
 'Civil Engineering Technicians',
 'Civil Engineers',
 'Computer Hardware Engineers',
 'Drafters, All Other',
 'Electrical Drafters',
 'Electrical Engineering Technicians',
 'Electrical Engineering Technologists',
 'Electrical Engineers',
 'Electromechanical Engineering Technologists',
 'Electro-Mechanical Technicians',
 'Electronic Drafters',
 'Electronics Engineering Technicians',
 'Electronics Engineering Technologists',
 'Electronics Engineers, Except Computer',
 'Energy Engineers',
 'Engineering Technicians, Except Drafters, All Other',
 'Engineers, All Other',
 'Environmental Engineering Technicians',
 'Environmental Engineers',
 'Fire-Prevention and Protection Engineers',
 'Fuel Cell Engineers',
 'Fuel Cell Technicians',
 'Geodetic Surveyors',
 'Human Factors Engineers and Ergonomists',
 'Industrial Engineering Technicians',
 'Industrial Engineering Technologists',
 'Industrial Engineers',
 'Industrial Safety and Health Engineers',
 'Landscape Architects',
 'Manufacturing Engineering Technologists',
 'Manufacturing Engineers',
 'Manufacturing Production Technicians',
 'Mapping Technicians',
 'Marine Architects',
 'Marine Engineers',
 'Materials Engineers',
 'Mechanical Drafters',
 'Mechanical Engineering Technicians',
 'Mechanical Engineering Technologists',
 'Mechanical Engineers',
 'Mechatronics Engineers',
 'Microsystems Engineers',
 'Mining and Geological Engineers, Including Mining Safety Engineers',
 'Nanosystems Engineers',
 'Nanotechnology Engineering Technicians',
 'Nanotechnology Engineering Technologists',
 'Non-Destructive Testing Specialists',
 'Nuclear Engineers',
 'Petroleum Engineers',
 'Photonics Engineers',
 'Photonics Technicians',
 'Product Safety Engineers',
 'Radio Frequency Identification Device Specialists',
 'Robotics Engineers',
 'Robotics Technicians',
 'Solar Energy Systems Engineers',
 'Surveying Technicians',
 'Surveyors',
 'Transportation Engineers',
 'Validation Engineers',
 'Water/Wastewater Engineers',
 'Wind Energy Engineers',
 'Actors',
 'Art Directors',
 'Artists and Related Workers, All Other',
 'Athletes and Sports Competitors',
 'Audio and Video Equipment Technicians',
 'Broadcast News Analysts',
 'Broadcast Technicians',
 'Camera Operators, Television, Video, and Motion Picture',
 'Choreographers',
 'Coaches and Scouts',
 'Commercial and Industrial Designers',
 'Copy Writers',
 'Craft Artists',
 'Dancers',
 'Designers, All Other',
 'Directors- Stage, Motion Pictures, Television, and Radio',
 'Editors',
 'Entertainers and Performers, Sports and Related Workers, All Other',
 'Fashion Designers',
 'Film and Video Editors',
 'Fine Artists, Including Painters, Sculptors, and Illustrators',
 'Floral Designers',
 'Graphic Designers',
 'Interior Designers',
 'Interpreters and Translators',
 'Media and Communication Equipment Workers, All Other',
 'Media and Communication Workers, All Other',
 'Merchandise Displayers and Window Trimmers',
 'Multimedia Artists and Animators',
 'Music Composers and Arrangers',
 'Music Directors',
 'Musicians, Instrumental',
 'Photographers',
 'Poets, Lyricists and Creative Writers',
 'Producers',
 'Program Directors',
 'Public Address System and Other Announcers',
 'Public Relations Specialists',
 'Radio and Television Announcers',
 'Radio Operators',
 'Reporters and Correspondents',
 'Set and Exhibit Designers',
 'Singers',
 'Sound Engineering Technicians',
 'Talent Directors',
 'Technical Directors/Managers',
 'Technical Writers',
 'Umpires, Referees, and Other Sports Officials',
 'Building Cleaning Workers, All Other',
 'First-Line Supervisors of Housekeeping and Janitorial Workers',
 'First-Line Supervisors of Landscaping, Lawn Service, and Groundskeeping Workers',
 'Grounds Maintenance Workers, All Other',
 'Janitors and Cleaners, Except Maids and Housekeeping Cleaners',
 'Landscaping and Groundskeeping Workers',
 'Maids and Housekeeping Cleaners',
 'Pest Control Workers',
 'Pesticide Handlers, Sprayers, and Applicators, Vegetation',
 'Tree Trimmers and Pruners',
 'Accountants',
 'Agents and Business Managers of Artists, Performers, and Athletes',
 'Appraisers, Real Estate',
 'Assessors',
 'Auditors',
 'Budget Analysts',
 'Business Continuity Planners',
 'Business Operations Specialists, All Other',
 'Buyers and Purchasing Agents, Farm Products',
 'Claims Examiners, Property and Casualty Insurance',
 'Compensation, Benefits, and Job Analysis Specialists',
 'Coroners',
 'Cost Estimators',
 'Credit Analysts',
 'Credit Counselors',
 'Customs Brokers',
 'Energy Auditors',
 'Environmental Compliance Inspectors',
 'Equal Opportunity Representatives and Officers',
 'Farm Labor Contractors',
 'Financial Analysts',
 'Financial Examiners',
 'Financial Quantitative Analysts',
 'Financial Specialists, All Other',
 'Fraud Examiners, Investigators and Analysts',
 'Fundraisers',
 'Government Property Inspectors and Investigators',
 'Human Resources Specialists',
 'Insurance Adjusters, Examiners, and Investigators',
 'Insurance Appraisers, Auto Damage',
 'Insurance Underwriters',
 'Investment Underwriters',
 'Labor Relations Specialists',
 'Licensing Examiners and Inspectors',
 'Loan Counselors',
 'Loan Officers',
 'Logisticians',
 'Logistics Analysts',
 'Logistics Engineers',
 'Management Analysts',
 'Market Research Analysts and Marketing Specialists',
 'Meeting, Convention, and Event Planners',
 'Online Merchants',
 'Personal Financial Advisors',
 'Purchasing Agents, Except Wholesale, Retail, and Farm Products',
 'Regulatory Affairs Specialists',
 'Risk Management Specialists',
 'Security Management Specialists',
 'Sustainability Specialists',
 'Tax Examiners and Collectors, and Revenue Agents',
 'Tax Preparers',
 'Training and Development Specialists',
 'Wholesale and Retail Buyers, Except Farm Products',
 'Child, Family, and School Social Workers',
 'Clergy',
 'Community and Social Service Specialists, All Other',
 'Community Health Workers',
 'Counselors, All Other',
 'Directors, Religious Activities and Education',
 'Educational, Guidance, School, and Vocational Counselors',
 'Health Educators',
 'Healthcare Social Workers',
 'Marriage and Family Therapists',
 'Mental Health and Substance Abuse Social Workers',
 'Mental Health Counselors',
 'Probation Officers and Correctional Treatment Specialists',
 'Rehabilitation Counselors',
 'Religious Workers, All Other',
 'Social and Human Service Assistants',
 'Social Workers, All Other',
 'Substance Abuse and Behavioral Disorder Counselors',
 'Actuaries',
 'Biostatisticians',
 'Business Intelligence Analysts',
 'Clinical Data Managers',
 'Computer and Information Research Scientists',
 'Computer Network Architects',
 'Computer Network Support Specialists',
 'Computer Occupations, All Other',
 'Computer Programmers',
 'Computer Systems Analysts',
 'Computer Systems Engineers/Architects',
 'Computer User Support Specialists',
 'Data Warehousing Specialists',
 'Database Administrators',
 'Database Architects',
 'Document Management Specialists',
 'Geographic Information Systems Technicians',
 'Geospatial Information Scientists and Technologists',
 'Informatics Nurse Specialists',
 'Information Security Analysts',
 'Information Technology Project Managers',
 'Mathematical Science Occupations, All Other',
 'Mathematical Technicians',
 'Mathematicians',
 'Network and Computer Systems Administrators',
 'Operations Research Analysts',
 'Search Marketing Strategists',
 'Software Developers, Applications',
 'Software Developers, Systems Software',
 'Software Quality Assurance Engineers and Testers',
 'Statisticians',
 'Telecommunications Engineering Specialists',
 'Video Game Designers',
 'Web Administrators',
 'Web Developers',
 'Boilermakers',
 'Brickmasons and Blockmasons',
 'Carpet Installers',
 'Cement Masons and Concrete Finishers',
 'Construction and Building Inspectors',
 'Construction and Related Workers, All Other',
 'Construction Carpenters',
 'Construction Laborers',
 'Continuous Mining Machine Operators',
 'Derrick Operators, Oil and Gas',
 'Drywall and Ceiling Tile Installers',
 'Earth Drillers, Except Oil and Gas',
 'Electricians',
 'Elevator Installers and Repairers',
 'Explosives Workers, Ordnance Handling Experts, and Blasters',
 'Extraction Workers, All Other',
 'Fence Erectors',
 'First-Line Supervisors of Construction Trades and Extraction Workers',
 'Floor Layers, Except Carpet, Wood, and Hard Tiles',
 'Floor Sanders and Finishers',
 'Glaziers',
 'Hazardous Materials Removal Workers',
 'Helpers, Construction Trades, All Other',
 'Helpers--Brickmasons, Blockmasons, Stonemasons, and Tile and Marble Setters',
 'Helpers--Carpenters',
 'Helpers--Electricians',
 'Helpers--Extraction Workers',
 'Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons',
 'Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters',
 'Helpers--Roofers',
 'Highway Maintenance Workers',
 'Insulation Workers, Floor, Ceiling, and Wall',
 'Insulation Workers, Mechanical',
 'Mine Cutting and Channeling Machine Operators',
 'Mining Machine Operators, All Other',
 'Operating Engineers and Other Construction Equipment Operators',
 'Painters, Construction and Maintenance',
 'Paperhangers',
 'Paving, Surfacing, and Tamping Equipment Operators',
 'Pile-Driver Operators',
 'Pipe Fitters and Steamfitters',
 'Pipelayers',
 'Plasterers and Stucco Masons',
 'Plumbers',
 'Rail-Track Laying and Maintenance Equipment Operators',
 'Reinforcing Iron and Rebar Workers',
 'Rock Splitters, Quarry',
 'Roof Bolters, Mining',
 'Roofers',
 'Rotary Drill Operators, Oil and Gas',
 'Rough Carpenters',
 'Roustabouts, Oil and Gas',
 'Segmental Pavers',
 'Septic Tank Servicers and Sewer Pipe Cleaners',
 'Service Unit Operators, Oil, Gas, and Mining',
 'Sheet Metal Workers',
 'Solar Energy Installation Managers',
 'Solar Photovoltaic Installers',
 'Solar Thermal Installers and Technicians',
 'Stonemasons',
 'Structural Iron and Steel Workers',
 'Tapers',
 'Terrazzo Workers and Finishers',
 'Tile and Marble Setters',
 'Weatherization Installers and Technicians',
 'Adapted Physical Education Specialists',
 'Adult Basic and Secondary Education and Literacy Teachers and Instructors',
 'Agricultural Sciences Teachers, Postsecondary',
 'Anthropology and Archeology Teachers, Postsecondary',
 'Architecture Teachers, Postsecondary',
 'Archivists',
 'Area, Ethnic, and Cultural Studies Teachers, Postsecondary',
 'Art, Drama, and Music Teachers, Postsecondary',
 'Atmospheric, Earth, Marine, and Space Sciences Teachers, Postsecondary',
 'Audio-Visual and Multimedia Collections Specialists',
 'Biological Science Teachers, Postsecondary',
 'Business Teachers, Postsecondary',
 'Career/Technical Education Teachers, Middle School',
 'Career/Technical Education Teachers, Secondary School',
 'Chemistry Teachers, Postsecondary',
 'Communications Teachers, Postsecondary',
 'Computer Science Teachers, Postsecondary',
 'Criminal Justice and Law Enforcement Teachers, Postsecondary',
 'Curators',
 'Economics Teachers, Postsecondary',
 'Education Teachers, Postsecondary',
 'Education, Training, and Library Workers, All Other',
 'Elementary School Teachers, Except Special Education',
 'Engineering Teachers, Postsecondary',
 'English Language and Literature Teachers, Postsecondary',
 'Environmental Science Teachers, Postsecondary',
 'Farm and Home Management Advisors',
 'Foreign Language and Literature Teachers, Postsecondary',
 'Forestry and Conservation Science Teachers, Postsecondary',
 'Geography Teachers, Postsecondary',
 'Graduate Teaching Assistants',
 'Health Specialties Teachers, Postsecondary',
 'History Teachers, Postsecondary',
 'Home Economics Teachers, Postsecondary',
 'Instructional Coordinators',
 'Instructional Designers and Technologists',
 'Kindergarten Teachers, Except Special Education',
 'Law Teachers, Postsecondary',
 'Librarians',
 'Library Science Teachers, Postsecondary',
 'Library Technicians',
 'Mathematical Science Teachers, Postsecondary',
 'Middle School Teachers, Except Special and Career/Technical Education',
 'Museum Technicians and Conservators',
 'Nursing Instructors and Teachers, Postsecondary',
 'Philosophy and Religion Teachers, Postsecondary',
 'Physics Teachers, Postsecondary',
 'Political Science Teachers, Postsecondary',
 'Postsecondary Teachers, All Other',
 'Preschool Teachers, Except Special Education',
 'Psychology Teachers, Postsecondary',
 'Recreation and Fitness Studies Teachers, Postsecondary',
 'Secondary School Teachers, Except Special and Career/Technical Education',
 'Self-Enrichment Education Teachers',
 'Social Sciences Teachers, Postsecondary, All Other',
 'Social Work Teachers, Postsecondary',
 'Sociology Teachers, Postsecondary',
 'Special Education Teachers, All Other',
 'Special Education Teachers, Kindergarten and Elementary School',
 'Special Education Teachers, Middle School',
 'Special Education Teachers, Preschool',
 'Special Education Teachers, Secondary School',
 'Teacher Assistants',
 'Teachers and Instructors, All Other',
 'Tutors',
 'Vocational Education Teachers, Postsecondary',
 'Agricultural Equipment Operators',
 'Agricultural Inspectors',
 'Agricultural Workers, All Other',
 'Animal Breeders',
 'Fallers',
 'Farmworkers and Laborers, Crop',
 'Farmworkers, Farm, Ranch, and Aquacultural Animals',
 'First-Line Supervisors of Agricultural Crop and Horticultural Workers',
 'First-Line Supervisors of Animal Husbandry and Animal Care Workers',
 'First-Line Supervisors of Aquacultural Workers',
 'First-Line Supervisors of Logging Workers',
 'Fishers and Related Fishing Workers',
 'Forest and Conservation Workers',
 'Graders and Sorters, Agricultural Products',
 'Hunters and Trappers',
 'Log Graders and Scalers',
 'Logging Equipment Operators',
 'Logging Workers, All Other',
 'Nursery Workers',
 'Baristas',
 'Bartenders',
 'Chefs and Head Cooks',
 'Combined Food Preparation and Serving Workers, Including Fast Food',
 'Cooks, All Other',
 'Cooks, Fast Food',
 'Cooks, Institution and Cafeteria',
 'Cooks, Private Household',
 'Cooks, Restaurant',
 'Cooks, Short Order',
 'Counter Attendants, Cafeteria, Food Concession, and Coffee Shop',
 'Dining Room and Cafeteria Attendants and Bartender Helpers',
 'Dishwashers',
 'First-Line Supervisors of Food Preparation and Serving Workers',
 'Food Preparation and Serving Related Workers, All Other',
 'Food preparation Workers',
 'Food Servers, Nonrestaurant',
 'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop',
 'Waiters and Waitresses',
 'Acupuncturists',
 'Acute Care Nurses',
 'Advanced Practice Psychiatric Nurses',
 'Allergists and Immunologists',
 'Anesthesiologist Assistants',
 'Anesthesiologists',
 'Art Therapists',
 'Athletic Trainers',
 'Audiologists',
 'Cardiovascular Technologists and Technicians',
 'Chiropractors',
 'Clinical Nurse Specialists',
 'Critical Care Nurses',
 'Cytogenetic Technologists',
 'Cytotechnologists',
 'Dental Hygienists',
 'Dentists, All Other Specialists',
 'Dentists, General',
 'Dermatologists',
 'Diagnostic Medical Sonographers',
 'Dietetic Technicians',
 'Dietitians and Nutritionists',
 'Emergency Medical Technicians and Paramedics',
 'Exercise Physiologists',
 'Family and General Practitioners',
 'Genetic Counselors',
 'Health Diagnosing and Treating Practitioners, All Other',
 'Health Technologists and Technicians, All Other',
 'Healthcare Practitioners and Technical Workers, All Other',
 'Hearing Aid Specialists',
 'Histotechnologists and Histologic Technicians',
 'Hospitalists',
 'Internists, General',
 'Licensed Practical and Licensed Vocational Nurses',
 'Low Vision Therapists, Orientation and Mobility Specialists, and Vision Rehabilitation Therapists',
 'Magnetic Resonance Imaging Technologists',
 'Medical and Clinical Laboratory Technicians',
 'Medical and Clinical Laboratory Technologists',
 'Medical Records and Health Information Technicians',
 'Midwives',
 'Music Therapists',
 'Naturopathic Physicians',
 'Neurodiagnostic Technologists',
 'Neurologists',
 'Nuclear Medicine Physicians',
 'Nuclear Medicine Technologists',
 'Nurse Anesthetists',
 'Nurse Midwives',
 'Nurse Practitioners',
 'Obstetricians and Gynecologists',
 'Occupational Health and Safety Specialists',
 'Occupational Health and Safety Technicians',
 'Occupational Therapists',
 'Ophthalmic Medical Technicians',
 'Ophthalmic Medical Technologists',
 'Ophthalmologists',
 'Opticians, Dispensing',
 'Optometrists',
 'Oral and Maxillofacial Surgeons',
 'Orthodontists',
 'Orthoptists',
 'Orthotists and Prosthetists',
 'Pathologists',
 'Pediatricians, General',
 'Pharmacists',
 'Pharmacy Technicians',
 'Physical Medicine and Rehabilitation Physicians',
 'Physical Therapists',
 'Physician Assistants',
 'Physicians and Surgeons, All Other',
 'Podiatrists',
 'Preventive Medicine Physicians',
 'Prosthodontists',
 'Psychiatric Technicians',
 'Psychiatrists',
 'Radiation Therapists',
 'Radiologic Technicians',
 'Radiologic Technologists',
 'Radiologists',
 'Recreational Therapists',
 'Registered Nurses',
 'Respiratory Therapists',
 'Respiratory Therapy Technicians',
 'Speech-Language Pathologists',
 'Sports Medicine Physicians',
 'Surgeons',
 'Surgical Assistants',
 'Surgical Technologists',
 'Therapists, All Other',
 'Urologists',
 'Veterinarians',
 'Veterinary Technologists and Technicians',
 'Dental Assistants',
 'Endoscopy Technicians',
 'Healthcare Support Workers, All Other',
 'Home Health Aides',
 'Massage Therapists',
 'Medical Assistants',
 'Medical Equipment Preparers',
 'Medical Transcriptionists',
 'Nursing Assistants',
 'Occupational Therapy Aides',
 'Occupational Therapy Assistants',
 'Orderlies',
 'Pharmacy Aides',
 'Phlebotomists',
 'Physical Therapist Aides',
 'Physical Therapist Assistants',
 'Psychiatric Aides',
 'Speech-Language Pathology Assistants',
 'Veterinary Assistants and Laboratory Animal Caretakers',
 'Aircraft Mechanics and Service Technicians',
 'Automotive Body and Related Repairers',
 'Automotive Glass Installers and Repairers',
 'Automotive Master Mechanics',
 'Automotive Service Technicians and Mechanics',
 'Automotive Specialty Technicians',
 'Avionics Technicians',
 'Bicycle Repairers',
 'Bus and Truck Mechanics and Diesel Engine Specialists',
 'Camera and Photographic Equipment Repairers',
 'Coin, Vending, and Amusement Machine Servicers and Repairers',
 'Commercial Divers',
 'Computer, Automated Teller, and Office Machine Repairers',
 'Control and Valve Installers and Repairers, Except Mechanical Door',
 'Electric Motor, Power Tool, and Related Repairers',
 'Electrical and Electronics Installers and Repairers, Transportation Equipment',
 'Electrical and Electronics Repairers, Commercial and Industrial Equipment',
 'Electrical and Electronics Repairers, Powerhouse, Substation, and Relay',
 'Electrical Power-Line Installers and Repairers',
 'Electronic Equipment Installers and Repairers, Motor Vehicles',
 'Electronic Home Entertainment Equipment Installers and Repairers',
 'Fabric Menders, Except Garment',
 'Farm Equipment Mechanics and Service Technicians',
 'First-Line Supervisors of Mechanics, Installers, and Repairers',
 'Geothermal Technicians',
 'Heating and Air Conditioning Mechanics and Installers',
 'Helpers--Installation, Maintenance, and Repair Workers',
 'Home Appliance Repairers',
 'Industrial Machinery Mechanics',
 'Installation, Maintenance, and Repair Workers, All Other',
 'Locksmiths and Safe Repairers',
 'Maintenance and Repair Workers, General',
 'Maintenance Workers, Machinery',
 'Manufactured Building and Mobile Home Installers',
 'Mechanical Door Repairers',
 'Medical Equipment Repairers',
 'Millwrights',
 'Mobile Heavy Equipment Mechanics, Except Engines',
 'Motorboat Mechanics and Service Technicians',
 'Motorcycle Mechanics',
 'Musical Instrument Repairers and Tuners',
 'Outdoor Power Equipment and Other Small Engine Mechanics',
 'Precision Instrument and Equipment Repairers, All Other',
 'Radio Mechanics',
 'Radio, Cellular, and Tower Equipment Installers and Repairers',
 'Rail Car Repairers',
 'Recreational Vehicle Service Technicians',
 'Refractory Materials Repairers, Except Brickmasons',
 'Refrigeration Mechanics and Installers',
 'Riggers',
 'Security and Fire Alarm Systems Installers',
 'Signal and Track Switch Repairers',
 'Telecommunications Equipment Installers and Repairers, Except Line Installers',
 'Telecommunications Line Installers and Repairers',
 'Tire Repairers and Changers',
 'Watch Repairers',
 'Wind Turbine Service Technicians',
 'Administrative Law Judges, Adjudicators, and Hearing Officers',
 'Arbitrators, Mediators, and Conciliators',
 'Court Reporters',
 'Judges, Magistrate Judges, and Magistrates',
 'Judicial Law Clerks',
 'Lawyers',
 'Legal Support Workers, All Other',
 'Paralegals and Legal Assistants',
 'Title Examiners, Abstractors, and Searchers',
 'Agricultural Technicians',
 'Animal Scientists',
 'Anthropologists',
 'Archeologists',
 'Astronomers',
 'Atmospheric and Space Scientists',
 'Biochemists and Biophysicists',
 'Bioinformatics Scientists',
 'Biological Scientists, All Other',
 'Biological Technicians',
 'Biologists',
 'Chemical Technicians',
 'Chemists',
 'City and Regional Planning Aides',
 'Climate Change Analysts',
 'Clinical Psychologists',
 'Counseling Psychologists',
 'Economists',
 'Environmental Economists',
 'Environmental Restoration Planners',
 'Environmental Science and Protection Technicians, Including Health',
 'Environmental Scientists and Specialists, Including Health',
 'Epidemiologists',
 'Food Science Technicians',
 'Food Scientists and Technologists',
 'Forensic Science Technicians',
 'Forest and Conservation Technicians',
 'Foresters',
 'Geneticists',
 'Geographers',
 'Geological Sample Test Technicians',
 'Geophysical Data Technicians',
 'Geoscientists, Except Hydrologists and Geographers',
 'Historians',
 'Hydrologists',
 'Industrial Ecologists',
 'Industrial-Organizational Psychologists',
 'Life Scientists, All Other',
 'Life, Physical, and Social Science Technicians, All Other',
 'Materials Scientists',
 'Medical Scientists, Except Epidemiologists',
 'Microbiologists',
 'Molecular and Cellular Biologists',
 'Neuropsychologists and Clinical Neuropsychologists',
 'Nuclear Equipment Operation Technicians',
 'Nuclear Monitoring Technicians',
 'Park Naturalists',
 'Physical Scientists, All Other',
 'Physicists',
 'Political Scientists',
 'Precision Agriculture Technicians',
 'Psychologists, All Other',
 'Quality Control Analysts',
 'Range Managers',
 'Remote Sensing Scientists and Technologists',
 'Remote Sensing Technicians',
 'School Psychologists',
 'Social Science Research Assistants',
 'Social Scientists and Related Workers, All Other',
 'Sociologists',
 'Soil and Plant Scientists',
 'Soil and Water Conservationists',
 'Survey Researchers',
 'Transportation Planners',
 'Urban and Regional Planners',
 'Zoologists and Wildlife Biologists',
 'Administrative Services Managers',
 'Advertising and Promotions Managers',
 'Aquacultural Managers',
 'Architectural and Engineering Managers',
 'Biofuels Production Managers',
 'Biofuels/Biodiesel Technology and Product Development Managers',
 'Biomass Power Plant Managers',
 'Brownfield Redevelopment Specialists and Site Managers',
 'Chief Executives',
 'Chief Sustainability Officers',
 'Clinical Research Coordinators',
 'Compensation and Benefits Managers',
 'Compliance Managers',
 'Computer and Information Systems Managers',
 'Construction Managers',
 'Distance Learning Coordinators',
 'Education Administrators, All Other',
 'Education Administrators, Elementary and Secondary School',
 'Education Administrators, Postsecondary',
 'Education Administrators, Preschool and Childcare Center/Program',
 'Emergency Management Directors',
 'Farm and Ranch Managers',
 'Farmers, Ranchers, and Other Agricultural Managers',
 'Financial Managers, Branch or Department',
 'Fitness and Wellness Coordinators',
 'Food Service Managers',
 'Funeral Service Managers',
 'Gaming Managers',
 'General and Operations Managers',
 'Geothermal Production Managers',
 'Green Marketers',
 'Human Resources Managers',
 'Hydroelectric Production Managers',
 'Industrial Production Managers',
 'Investment Fund Managers',
 'Legislators',
 'Lodging Managers',
 'Logistics Managers',
 'Loss Prevention Managers',
 'Managers, All Other',
 'Marketing Managers',
 'Medical and Health Services Managers',
 'Methane/Landfill Gas Collection System Operators',
 'Natural Sciences Managers',
 'Nursery and Greenhouse Managers',
 'Postmasters and Mail Superintendents',
 'Property, Real Estate, and Community Association Managers',
 'Public Relations and Fundraising Managers',
 'Purchasing Managers',
 'Quality Control Systems Managers',
 'Regulatory Affairs Managers',
 'Sales Managers',
 'Security Managers',
 'Social and Community Service Managers',
 'Storage and Distribution Managers',
 'Supply Chain Managers',
 'Training and Development Managers',
 'Transportation Managers',
 'Treasurers and Controllers',
 'Water Resource Specialists',
 'Wind Energy Operations Managers',
 'Wind Energy Project Managers',
 'Air Crew Members',
 'Air Crew Officers',
 'Aircraft Launch and Recovery Officers',
 'Aircraft Launch and Recovery Specialists',
 'Armored Assault Vehicle Crew Members',
 'Armored Assault Vehicle Officers',
 'Artillery and Missile Crew Members',
 'Artillery and Missile Officers',
 'Command and Control Center Officers',
 'Command and Control Center Specialists',
 'First-Line Supervisors of Air Crew Members',
 'First-Line Supervisors of All Other Tactical Operations Specialists',
 'First-Line Supervisors of Weapons Specialists/Crew Members',
 'Infantry',
 'Infantry Officers',
 'Military Enlisted Tactical Operations and Air/Weapons Specialists and Crew Members, All Other',
 'Military Officer Special and Tactical Operations Leaders, All Other',
 'Radar and Sonar Technicians',
 'Special Forces',
 'Special Forces Officers',
 'Bill and Account Collectors',
 'Billing, Cost, and Rate Clerks',
 'Bioinformatics Technicians',
 'Bookkeeping, Accounting, and Auditing Clerks',
 'Brokerage Clerks',
 'Cargo and Freight Agents',
 'Communications Equipment Operators, All Other',
 'Computer Operators',
 'Correspondence Clerks',
 'Couriers and Messengers',
 'Court Clerks',
 'Credit Authorizers',
 'Credit Checkers',
 'Customer Service Representatives',
 'Data Entry Keyers',
 'Desktop Publishers',
 'Dispatchers, Except Police, Fire, and Ambulance',
 'Eligibility Interviewers, Government Programs',
 'Executive Secretaries and Executive Administrative Assistants',
 'File Clerks',
 'Financial Clerks, All Other',
 'First-Line Supervisors of Office and Administrative Support Workers',
 'Freight Forwarders',
 'Gaming Cage Workers',
 'Hotel, Motel, and Resort Desk Clerks',
 'Human Resources Assistants, Except Payroll and Timekeeping',
 'Information and Record Clerks, All Other',
 'Insurance Claims Clerks',
 'Insurance Policy Processing Clerks',
 'Interviewers, Except Eligibility and Loan',
 'Legal Secretaries',
 'Library Assistants, Clerical',
 'License Clerks',
 'Loan Interviewers and Clerks',
 'Mail Clerks and Mail Machine Operators, Except Postal Service',
 'Marking Clerks',
 'Medical Secretaries',
 'Meter Readers, Utilities',
 'Municipal Clerks',
 'New Accounts Clerks',
 'Office and Administrative Support Workers, All Other',
 'Office Clerks, General',
 'Office Machine Operators, Except Computer',
 'Order Clerks',
 'Order Fillers, Wholesale and Retail Sales',
 'Patient Representatives',
 'Payroll and Timekeeping Clerks',
 'Police, Fire, and Ambulance Dispatchers',
 'Postal Service Clerks',
 'Postal Service Mail Carriers',
 'Postal Service Mail Sorters, Processors, and Processing Machine Operators',
 'Procurement Clerks',
 'Production, Planning, and Expediting Clerks',
 'Proofreaders and Copy Markers',
 'Receptionists and Information Clerks',
 'Reservation and Transportation Ticket Agents and Travel Clerks',
 'Secretaries and Administrative Assistants, Except Legal, Medical, and Executive',
 'Shipping, Receiving, and Traffic Clerks',
 'Statement Clerks',
 'Statistical Assistants',
 'Stock Clerks- Stockroom, Warehouse, or Storage Yard',
 'Stock Clerks, Sales Floor',
 'Switchboard Operators, Including Answering Service',
 'Telephone Operators',
 'Tellers',
 'Weighers, Measurers, Checkers, and Samplers, Recordkeeping',
 'Word Processors and Typists',
 'Amusement and Recreation Attendants',
 'Animal Trainers',
 'Baggage Porters and Bellhops',
 'Barbers',
 'Childcare Workers',
 'Concierges',
 'Costume Attendants',
 'Embalmers',
 'Entertainment Attendants and Related Workers, All Other',
 'First-Line Supervisors of Personal Service Workers',
 'Fitness Trainers and Aerobics Instructors',
 'Funeral Attendants',
 'Gaming and Sports Book Writers and Runners',
 'Gaming Dealers',
 'Gaming Service Workers, All Other',
 'Gaming Supervisors',
 'Hairdressers, Hairstylists, and Cosmetologists',
 'Locker Room, Coatroom, and Dressing Room Attendants',
 'Makeup Artists, Theatrical and Performance',
 'Manicurists and Pedicurists',
 'Morticians, Undertakers, and Funeral Directors',
 'Motion Picture Projectionists',
 'Nannies',
 'Nonfarm Animal Caretakers',
 'Personal Care Aides',
 'Personal Care and Service Workers, All Other',
 'Recreation Workers',
 'Residential Advisors',
 'Shampooers',
 'Skincare Specialists',
 'Slot Supervisors',
 'Spa Managers',
 'Tour Guides and Escorts',
 'Travel Guides',
 'Ushers, Lobby Attendants, and Ticket Takers',
 'Adhesive Bonding Machine Operators and Tenders',
 'Aircraft Structure, Surfaces, Rigging, and Systems Assemblers',
 'Assemblers and Fabricators, All Other',
 'Bakers',
 'Biofuels Processing Technicians',
 'Biomass Plant Technicians',
 'Butchers and Meat Cutters',
 'Cabinetmakers and Bench Carpenters',
 'Chemical Equipment Operators and Tenders',
 'Chemical Plant and System Operators',
 'Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders',
 'Coating, Painting, and Spraying Machine Setters, Operators, and Tenders',
 'Coil Winders, Tapers, and Finishers',
 'Computer Numerically Controlled Machine Tool Programmers, Metal and Plastic',
 'Computer-Controlled Machine Tool Operators, Metal and Plastic',
 'Cooling and Freezing Equipment Operators and Tenders',
 'Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders',
 'Cutters and Trimmers, Hand',
 'Cutting and Slicing Machine Setters, Operators, and Tenders',
 'Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic',
 'Dental Laboratory Technicians',
 'Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
 'Electrical and Electronic Equipment Assemblers',
 'Electromechanical Equipment Assemblers',
 'Engine and Other Machine Assemblers',
 'Etchers and Engravers',
 'Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic',
 'Extruding and Forming Machine Setters, Operators, and Tenders, Synthetic and Glass Fibers',
 'Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders',
 'Fabric and Apparel Patternmakers',
 'Fiberglass Laminators and Fabricators',
 'First-Line Supervisors of Production and Operating Workers',
 'Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders',
 'Food Batchmakers',
 'Food Cooking Machine Operators and Tenders',
 'Food Processing Workers, All Other',
 'Forging Machine Setters, Operators, and Tenders, Metal and Plastic',
 'Foundry Mold and Coremakers',
 'Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders',
 'Furniture Finishers',
 'Gas Plant Operators',
 'Gem and Diamond Workers',
 'Glass Blowers, Molders, Benders, and Finishers',
 'Grinding and Polishing Workers, Hand',
 'Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
 'Heat Treating Equipment Setters, Operators, and Tenders, Metal and Plastic',
 'Helpers--Production Workers',
 'Hydroelectric Plant Technicians',
 'Inspectors, Testers, Sorters, Samplers, and Weighers',
 'Jewelers',
 'Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
 'Laundry and Dry-Cleaning Workers',
 'Layout Workers, Metal and Plastic',
 'Machinists',
 'Meat, Poultry, and Fish Cutters and Trimmers',
 'Medical Appliance Technicians',
 'Metal Workers and Plastic Workers, All Other',
 'Metal-Refining Furnace Operators and Tenders',
 'Methane/Landfill Gas Generation System Technicians',
 'Milling and Planing Machine Setters, Operators, and Tenders, Metal and Plastic',
 'Mixing and Blending Machine Setters, Operators, and Tenders',
 'Model Makers, Metal and Plastic',
 'Model Makers, Wood',
 'Molding and Casting Workers',
 'Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic',
 'Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
 'Nuclear Power Reactor Operators',
 'Ophthalmic Laboratory Technicians',
 'Packaging and Filling Machine Operators and Tenders',
 'Painters, Transportation Equipment',
 'Painting, Coating, and Decorating Workers',
 'Paper Goods Machine Setters, Operators, and Tenders',
 'Patternmakers, Metal and Plastic',
 'Patternmakers, Wood',
 'Petroleum Pump System Operators, Refinery Operators, and Gaugers',
 'Photographic Process Workers and Processing Machine Operators',
 'Plant and System Operators, All Other',
 'Plating and Coating Machine Setters, Operators, and Tenders, Metal and Plastic',
 'Potters, Manufacturing',
 'Pourers and Casters, Metal',
 'Power Distributors and Dispatchers',
 'Power Plant Operators',
 'Precious Metal Workers',
 'Prepress Technicians and Workers',
 'Pressers, Textile, Garment, and Related Materials',
 'Print Binding and Finishing Workers',
 'Printing Press Operators',
 'Production Workers, All Other',
 'Recycling and Reclamation Workers',
 'Rolling Machine Setters, Operators, and Tenders, Metal and Plastic',
 'Sawing Machine Setters, Operators, and Tenders, Wood',
 'Semiconductor Processors',
 'Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders',
 'Sewers, Hand',
 'Sewing Machine Operators',
 'Shoe and Leather Workers and Repairers',
 'Shoe Machine Operators and Tenders',
 'Slaughterers and Meat Packers',
 'Solderers and Brazers',
 'Stationary Engineers and Boiler Operators',
 'Stone Cutters and Carvers, Manufacturing',
 'Structural Metal Fabricators and Fitters',
 'Tailors, Dressmakers, and Custom Sewers',
 'Team Assemblers',
 'Textile Bleaching and Dyeing Machine Operators and Tenders',
 'Textile Cutting Machine Setters, Operators, and Tenders',
 'Textile Knitting and Weaving Machine Setters, Operators, and Tenders',
 'Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders',
 'Textile, Apparel, and Furnishings Workers, All Other',
 'Timing Device Assemblers and Adjusters',
 'Tire Builders',
 'Tool and Die Makers',
 'Tool Grinders, Filers, and Sharpeners',
 'Upholsterers',
 'Water and Wastewater Treatment Plant and System Operators',
 'Welders, Cutters, and Welder Fitters',
 'Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders',
 'Woodworkers, All Other',
 'Woodworking Machine Setters, Operators, and Tenders, Except Sawing',
 'Animal Control Workers',
 'Bailiffs',
 'Correctional Officers and Jailers',
 'Criminal Investigators and Special Agents',
 'Crossing Guards',
 'Fire Inspectors',
 'Fire Investigators',
 'First-Line Supervisors of Correctional Officers',
 'First-Line Supervisors of Police and Detectives',
 'First-Line Supervisors of Protective Service Workers, All Other',
 'Fish and Game Wardens',
 'Forest Fire Fighting and Prevention Supervisors',
 'Forest Fire Inspectors and Prevention Specialists',
 'Forest Firefighters',
 'Gaming Surveillance Officers and Gaming Investigators',
 'Immigration and Customs Inspectors',
 'Intelligence Analysts',
 'Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers',
 'Municipal Fire Fighting and Prevention Supervisors',
 'Municipal Firefighters',
 'Parking Enforcement Workers',
 'Police Detectives',
 'Police Identification and Records Officers',
 'Police Patrol Officers',
 'Private Detectives and Investigators',
 'Protective Service Workers, All Other',
 'Retail Loss Prevention Specialists',
 'Security Guards',
 'Sheriffs and Deputy Sheriffs',
 'Transit and Railroad Police',
 'Transportation Security Screeners',
 'Advertising Sales Agents',
 'Cashiers',
 'Counter and Rental Clerks',
 'Demonstrators and Product Promoters',
 'Door-To-Door Sales Workers, News and Street Vendors, and Related Workers',
 'Energy Brokers',
 'First-Line Supervisors of Non-Retail Sales Workers',
 'First-Line Supervisors of Retail Sales Workers',
 'Gaming Change Persons and Booth Cashiers',
 'Insurance Sales Agents',
 'Models',
 'Parts Salespersons',
 'Real Estate Brokers',
 'Real Estate Sales Agents',
 'Retail Salespersons',
 'Sales Agents, Financial Services',
 'Sales Agents, Securities and Commodities',
 'Sales and Related Workers, All Other',
 'Sales Engineers',
 'Sales Representatives, Services, All Other',
 'Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products',
 'Sales Representatives, Wholesale and Manufacturing, Technical and Scientific Products',
 'Securities and Commodities Traders',
 'Solar Sales Representatives and Assessors',
 'Telemarketers',
 'Travel Agents',
 'Air Traffic Controllers',
 'Aircraft Cargo Handling Supervisors',
 'Airfield Operations Specialists',
 'Airline Pilots, Copilots, and Flight Engineers',
 'Ambulance Drivers and Attendants, Except Emergency Medical Technicians',
 'Automotive and Watercraft Service Attendants',
 'Aviation Inspectors',
 'Bridge and Lock Tenders',
 'Bus Drivers, School or Special Client',
 'Bus Drivers, Transit and Intercity',
 'Cleaners of Vehicles and Equipment',
 'Commercial Pilots',
 'Conveyor Operators and Tenders',
 'Crane and Tower Operators',
 'Dredge Operators',
 'Driver/Sales Workers',
 'Excavating and Loading Machine and Dragline Operators',
 'First-Line Supervisors of Helpers, Laborers, and Material Movers, Hand',
 'First-Line Supervisors of Transportation and Material-Moving Machine and Vehicle Operators',
 'Flight Attendants',
 'Freight and Cargo Inspectors',
 'Gas Compressor and Gas Pumping Station Operators',
 'Heavy and Tractor-Trailer Truck Drivers',
 'Hoist and Winch Operators',
 'Industrial Truck and Tractor Operators',
 'Laborers and Freight, Stock, and Material Movers, Hand',
 'Light Truck or Delivery Services Drivers',
 'Loading Machine Operators, Underground Mining',
 'Locomotive Engineers',
 'Locomotive Firers',
 'Machine Feeders and Offbearers',
 'Material Moving Workers, All Other',
 'Mates- Ship, Boat, and Barge',
 'Mine Shuttle Car Operators',
 'Motor Vehicle Operators, All Other',
 'Motorboat Operators',
 'Packers and Packagers, Hand',
 'Parking Lot Attendants',
 'Pilots, Ship',
 'Pump Operators, Except Wellhead Pumpers',
 'Rail Transportation Workers, All Other',
 'Rail Yard Engineers, Dinkey Operators, and Hostlers',
 'Railroad Brake, Signal, and Switch Operators',
 'Railroad Conductors and Yardmasters',
 'Recycling Coordinators',
 'Refuse and Recyclable Material Collectors',
 'Sailors and Marine Oilers',
 'Ship and Boat Captains',
 'Ship Engineers',
 'Subway and Streetcar Operators',
 'Tank Car, Truck, and Ship Loaders',
 'Taxi Drivers and Chauffeurs',
 'Traffic Technicians',
 'Transportation Attendants, Except Flight Attendants',
 'Transportation Vehicle, Equipment and Systems Inspectors, Except Aviation',
 'Transportation Workers, All Other',
 'Wellhead Pumpers',
]
*/
export const  titles=[
"Accountant",
 "Actor",
 "Actuary",
 "Adhesive Bonding Machine Tender",
 "Adjudicator",
 "Administrative Assistant",
 "Administrative Services Manager",
 "Adult Education Teacher",
 "Advertising Manager",
 "Advertising Sales Agent",
 "Aerobics Instructor",
 "Aerospace Engineer",
 "Aerospace Engineering Technician",
 "Agent",
 "Agricultural Engineer",
 "Agricultural Equipment Operator",
 "Agricultural Grader",
 "Agricultural Inspector",
 "Agricultural Manager",
 "Agricultural Sciences Teacher",
 "Agricultural Sorter",
 "Agricultural Technician",
 "Agricultural Worker",
 "Air Conditioning Installer",
 "Air Conditioning Mechanic",
 "Air Traffic Controller",
 "Aircraft Cargo Handling Supervisor",
 "Aircraft Mechanic",
 "Aircraft Service Technician",
 "Airline Copilot",
 "Airline Pilot",
 "Ambulance Dispatcher",
 "Ambulance Driver",
 "Amusement Machine Servicer",
 "Anesthesiologist",
 "Animal Breeder",
 "Animal Control Worker",
 "Animal Scientist",
 "Animal Trainer",
 "Animator",
 "Answering Service Operator",
 "Anthropologist",
 "Apparel Patternmaker",
 "Apparel Worker",
 "Arbitrator",
 "Archeologist",
 "Architect",
 "Architectural Drafter",
 "Architectural Manager",
 "Archivist",
 "Art Director",
 "Art Teacher",
 "Artist",
 "Assembler",
 "Astronomer",
 "Athlete",
 "Athletic Trainer",
 "ATM Machine Repairer",
 "Atmospheric Scientist",
 "Attendant",
 "Audio And Video Equipment Technician",
 "Audio-visual And Multimedia Collections Specialist",
 "Audiologist",
 "Auditor",
 "Author",
 "Auto Damage Insurance Appraiser",
 "Automotive And Watercraft Service Attendant",
 "Automotive Glass Installer",
 "Automotive Mechanic",
 "Avionics Technician",
 "Baggage Porter",
 "Bailiff",
 "Baker",
 "Barback",
 "Barber",
 "Bartender",
 "Basic Education Teacher",
 "Behavioral Disorder Counselor",
 "Bellhop",
 "Bench Carpenter",
 "Bicycle Repairer",
 "Bill And Account Collector",
 "Billing And Posting Clerk",
 "Biochemist",
 "Biological Technician",
 "Biomedical Engineer",
 "Biophysicist",
 "Blaster",
 "Blending Machine Operator",
 "Blockmason",
 "Boiler Operator",
 "Boilermaker",
 "Bookkeeper",
 "Boring Machine Tool Tender",
 "Brazer",
 "Brickmason",
 "Bridge And Lock Tender",
 "Broadcast News Analyst",
 "Broadcast Technician",
 "Brokerage Clerk",
 "Budget Analyst",
 "Building Inspector",
 "Bus Mechanic",
 "Butcher",
 "Buyer",
 "Cabinetmaker",
 "Cafeteria Attendant",
 "Cafeteria Cook",
 "Camera Operator",
 "Camera Repairer",
 "Cardiovascular Technician",
 "Cargo Agent",
 "Carpenter",
 "Carpet Installer",
 "Cartographer",
 "Cashier",
 "Caster",
 "Ceiling Tile Installer",
 "Cellular Equipment Installer",
 "Cement Mason",
 "Channeling Machine Operator",
 "Chauffeur",
 "Checker",
 "Chef",
 "Chemical Engineer",
 "Chemical Plant Operator",
 "Chemist",
 "Chemistry Teacher",
 "Chief Executive",
 "Child Social Worker",
 "Childcare Worker",
 "Chiropractor",
 "Choreographer",
 "Civil Drafter",
 "Civil Engineer",
 "Civil Engineering Technician",
 "Claims Adjuster",
 "Claims Examiner",
 "Claims Investigator",
 "Cleaner",
 "Clinical Laboratory Technician",
 "Clinical Laboratory Technologist",
 "Clinical Psychologist",
 "Coating Worker",
 "Coatroom Attendant",
 "Coil Finisher",
 "Coil Taper",
 "Coil Winder",
 "Coin Machine Servicer",
 "Commercial Diver",
 "Commercial Pilot",
 "Commodities Sales Agent",
 "Communications Equipment Operator",
 "Communications Teacher",
 "Community Association Manager",
 "Community Service Manager",
 "Compensation And Benefits Manager",
 "Compliance Officer",
 "Composer",
 "Computer Hardware Engineer",
 "Computer Network Architect",
 "Computer Operator",
 "Computer Programmer",
 "Computer Science Teacher",
 "Computer Support Specialist",
 "Computer Systems Administrator",
 "Computer Systems Analyst",
 "Concierge",
 "Conciliator",
 "Concrete Finisher",
 "Conservation Science Teacher",
 "Conservation Scientist",
 "Conservation Worker",
 "Conservator",
 "Construction Inspector",
 "Construction Manager",
 "Construction Painter",
 "Construction Worker",
 "Continuous Mining Machine Operator",
 "Convention Planner",
 "Conveyor Operator",
 "Cook",
 "Cooling Equipment Operator",
 "Copy Marker",
 "Correctional Officer",
 "Correctional Treatment Specialist",
 "Correspondence Clerk",
 "Correspondent",
 "Cosmetologist",
 "Cost Estimator",
 "Costume Attendant",
 "Counseling Psychologist",
 "Counselor",
 "Courier",
 "Court Reporter",
 "Craft Artist",
 "Crane Operator",
 "Credit Analyst",
 "Credit Checker",
 "Credit Counselor",
 "Criminal Investigator",
 "Criminal Justice Teacher",
 "Crossing Guard",
 "Curator",
 "Custom Sewer",
 "Customer Service Representative",
 "Cutter",
 "Cutting Machine Operator",
 "Dancer",
 "Data Entry Keyer",
 "Database Administrator",
 "Decorating Worker",
 "Delivery Services Driver",
 "Demonstrator",
 "Dental Assistant",
 "Dental Hygienist",
 "Dental Laboratory Technician",
 "Dentist",
 "Derrick Operator",
 "Designer",
 "Desktop Publisher",
 "Detective",
 "Diagnostic Medical Sonographer",
 "Die Maker",
 "Diesel Engine Specialist",
 "Dietetic Technician",
 "Dietitian",
 "Dinkey Operator",
 "Director",
 "Dishwasher",
 "Dispatcher",
 "Door-to-door Sales Worker",
 "Drafter",
 "Dragline Operator",
 "Drama Teacher",
 "Dredge Operator",
 "Dressing Room Attendant",
 "Dressmaker",
 "Drier Operator",
 "Drilling Machine Tool Operator",
 "Dry-cleaning Worker",
 "Drywall Installer",
 "Dyeing Machine Operator",
 "Earth Driller",
 "Economics Teacher",
 "Economist",
 "Editor",
 "Education Administrator",
 "Electric Motor Repairer",
 "Electrical Electronics Drafter",
 "Electrical Engineer",
 "Electrical Equipment Assembler",
 "Electrical Installer",
 "Electrical Power-line Installer",
 "Electrician",
 "Electro-mechanical Technician",
 "Elementary School Teacher",
 "Elevator Installer",
 "Elevator Repairer",
 "Embalmer",
 "Emergency Management Director",
 "Emergency Medical Technician",
 "Engine Assembler",
 "Engineer",
 "Engineering Manager",
 "Engineering Teacher",
 "English Language Teacher",
 "Engraver",
 "Entertainment Attendant",
 "Environmental Engineer",
 "Environmental Science Teacher",
 "Environmental Scientist",
 "Epidemiologist",
 "Escort",
 "Etcher",
 "Event Planner",
 "Excavating Operator",
 "Executive Administrative Assistant",
 "Executive Secretary",
 "Exhibit Designer",
 "Expediting Clerk",
 "Explosives Worker",
 "Extraction Worker",
 "Fabric Mender",
 "Fabric Patternmaker",
 "Fabricator",
 "Faller",
 "Family Practitioner",
 "Family Social Worker",
 "Family Therapist",
 "Farm Advisor",
 "Farm Equipment Mechanic",
 "Farm Labor Contractor",
 "Farmer",
 "Farmworker",
 "Fashion Designer",
 "Fast Food Cook",
 "Fence Erector",
 "Fiberglass Fabricator",
 "Fiberglass Laminator",
 "File Clerk",
 "Filling Machine Operator",
 "Film And Video Editor",
 "Financial Analyst",
 "Financial Examiner",
 "Financial Manager",
 "Financial Services Sales Agent",
 "Fine Artist",
 "Fire Alarm System Installer",
 "Fire Dispatcher",
 "Fire Inspector",
 "Fire Investigator",
 "Firefighter",
 "Fish And Game Warden",
 "Fish Cutter",
 "Fish Trimmer",
 "Fisher",
 "Fitness Studies Teacher",
 "Fitness Trainer",
 "Flight Attendant",
 "Floor Finisher",
 "Floor Layer",
 "Floor Sander",
 "Floral Designer",
 "Food Batchmaker",
 "Food Cooking Machine Operator",
 "Food Preparation Worker",
 "Food Science Technician",
 "Food Scientist",
 "Food Server",
 "Food Service Manager",
 "Food Technologist",
 "Foreign Language Teacher",
 "Foreign Literature Teacher",
 "Forensic Science Technician",
 "Forest Fire Inspector",
 "Forest Fire Prevention Specialist",
 "Forest Worker",
 "Forester",
 "Forestry Teacher",
 "Forging Machine Setter",
 "Foundry Coremaker",
 "Freight Agent",
 "Freight Mover",
 "Fundraising Manager",
 "Funeral Attendant",
 "Funeral Director",
 "Funeral Service Manager",
 "Furnace Operator",
 "Furnishings Worker",
 "Furniture Finisher",
 "Gaming Booth Cashier",
 "Gaming Cage Worker",
 "Gaming Change Person",
 "Gaming Dealer",
 "Gaming Investigator",
 "Gaming Manager",
 "Gaming Surveillance Officer",
 "Garment Mender",
 "Garment Presser",
 "Gas Compressor",
 "Gas Plant Operator",
 "Gas Pumping Station Operator",
 "General Manager",
 "General Practitioner",
 "Geographer",
 "Geography Teacher",
 "Geological Engineer",
 "Geological Technician",
 "Geoscientist",
 "Glazier",
 "Government Program Eligibility Interviewer",
 "Graduate Teaching Assistant",
 "Graphic Designer",
 "Groundskeeper",
 "Groundskeeping Worker",
 "Gynecologist",
 "Hairdresser",
 "Hairstylist",
 "Hand Grinding Worker",
 "Hand Laborer",
 "Hand Packager",
 "Hand Packer",
 "Hand Polishing Worker",
 "Hand Sewer",
 "Hazardous Materials Removal Worker",
 "Head Cook",
 "Health And Safety Engineer",
 "Health Educator",
 "Health Information Technician",
 "Health Services Manager",
 "Health Specialties Teacher",
 "Healthcare Social Worker",
 "Hearing Officer",
 "Heat Treating Equipment Setter",
 "Heating Installer",
 "Heating Mechanic",
 "Heavy Truck Driver",
 "Highway Maintenance Worker",
 "Historian",
 "History Teacher",
 "Hoist And Winch Operator",
 "Home Appliance Repairer",
 "Home Economics Teacher",
 "Home Entertainment Installer",
 "Home Health Aide",
 "Home Management Advisor",
 "Host",
 "Hostess",
 "Hostler",
 "Hotel Desk Clerk",
 "Housekeeping Cleaner",
 "Human Resources Assistant",
 "Human Resources Manager",
 "Human Service Assistant",
 "Hunter",
 "Hydrologist",
 "Illustrator",
 "Industrial Designer",
 "Industrial Engineer",
 "Industrial Engineering Technician",
 "Industrial Machinery Mechanic",
 "Industrial Production Manager",
 "Industrial Truck Operator",
 "Industrial-organizational Psychologist",
 "Information Clerk",
 "Information Research Scientist",
 "Information Security Analyst",
 "Information Systems Manager",
 "Inspector",
 "Instructional Coordinator",
 "Instructor",
 "Insulation Worker",
 "Insurance Claims Clerk",
 "Insurance Sales Agent",
 "Insurance Underwriter",
 "Intercity Bus Driver",
 "Interior Designer",
 "Internist",
 "Interpreter",
 "Interviewer",
 "Investigator",
 "Jailer",
 "Janitor",
 "Jeweler",
 "Judge",
 "Judicial Law Clerk",
 "Kettle Operator",
 "Kiln Operator",
 "Kindergarten Teacher",
 "Laboratory Animal Caretaker",
 "Landscape Architect",
 "Landscaping Worker",
 "Lathe Setter",
 "Laundry Worker",
 "Law Enforcement Teacher",
 "Law Teacher",
 "Lawyer",
 "Layout Worker",
 "Leather Worker",
 "Legal Assistant",
 "Legal Secretary",
 "Legislator",
 "Librarian",
 "Library Assistant",
 "Library Science Teacher",
 "Library Technician",
 "Licensed Practical Nurse",
 "Licensed Vocational Nurse",
 "Life Scientist",
 "Lifeguard",
 "Light Truck Driver",
 "Line Installer",
 "Literacy Teacher",
 "Literature Teacher",
 "Loading Machine Operator",
 "Loan Clerk",
 "Loan Interviewer",
 "Loan Officer",
 "Lobby Attendant",
 "Locker Room Attendant",
 "Locksmith",
 "Locomotive Engineer",
 "Locomotive Firer",
 "Lodging Manager",
 "Log Grader",
 "Logging Equipment Operator",
 "Logistician",
 "Machine Feeder",
 "Machinist",
 "Magistrate Judge",
 "Magistrate",
 "Maid",
 "Mail Clerk",
 "Mail Machine Operator",
 "Mail Superintendent",
 "Maintenance Painter",
 "Maintenance Worker",
 "Makeup Artist",
 "Management Analyst",
 "Manicurist",
 "Manufactured Building Installer",
 "Mapping Technician",
 "Marble Setter",
 "Marine Engineer",
 "Marine Oiler",
 "Market Research Analyst",
 "Marketing Manager",
 "Marketing Specialist",
 "Marriage Therapist",
 "Massage Therapist",
 "Material Mover",
 "Materials Engineer",
 "Materials Scientist",
 "Mathematical Science Teacher",
 "Mathematical Technician",
 "Mathematician",
 "Maxillofacial Surgeon",
 "Measurer",
 "Meat Cutter",
 "Meat Packer",
 "Meat Trimmer",
 "Mechanical Door Repairer",
 "Mechanical Drafter",
 "Mechanical Engineer",
 "Mechanical Engineering Technician",
 "Mediator",
 "Medical Appliance Technician",
 "Medical Assistant",
 "Medical Equipment Preparer",
 "Medical Equipment Repairer",
 "Medical Laboratory Technician",
 "Medical Laboratory Technologist",
 "Medical Records Technician",
 "Medical Scientist",
 "Medical Secretary",
 "Medical Services Manager",
 "Medical Transcriptionist",
 "Meeting Planner",
 "Mental Health Counselor",
 "Mental Health Social Worker",
 "Merchandise Displayer",
 "Messenger",
 "Metal Caster",
 "Metal Patternmaker",
 "Metal Pickling Operator",
 "Metal Pourer",
 "Metal Worker",
 "Metal-refining Furnace Operator",
 "Metal-refining Furnace Tender",
 "Meter Reader",
 "Microbiologist",
 "Middle School Teacher",
 "Milling Machine Setter",
 "Millwright",
 "Mine Cutting Machine Operator",
 "Mine Shuttle Car Operator",
 "Mining Engineer",
 "Mining Safety Engineer",
 "Mining Safety Inspector",
 "Mining Service Unit Operator",
 "Mixing Machine Setter",
 "Mobile Heavy Equipment Mechanic",
 "Mobile Home Installer",
 "Model Maker",
 "Model",
 "Molder",
 "Mortician",
 "Motel Desk Clerk",
 "Motion Picture Projectionist",
 "Motorboat Mechanic",
 "Motorboat Operator",
 "Motorboat Service Technician",
 "Motorcycle Mechanic",
 "Multimedia Artist",
 "Museum Technician",
 "Music Director",
 "Music Teacher",
 "Musical Instrument Repairer",
 "Musician",
 "Natural Sciences Manager",
 "Naval Architect",
 "Network Systems Administrator",
 "New Accounts Clerk",
 "News Vendor",
 "Nonfarm Animal Caretaker",
 "Nuclear Engineer",
 "Nuclear Medicine Technologist",
 "Nuclear Power Reactor Operator",
 "Nuclear Technician",
 "Nursing Aide",
 "Nursing Instructor",
 "Nursing Teacher",
 "Nutritionist",
 "Obstetrician",
 "Occupational Health And Safety Specialist",
 "Occupational Health And Safety Technician",
 "Occupational Therapist",
 "Occupational Therapy Aide",
 "Occupational Therapy Assistant",
 "Offbearer",
 "Office Clerk",
 "Office Machine Operator",
 "Operating Engineer",
 "Operations Manager",
 "Operations Research Analyst",
 "Ophthalmic Laboratory Technician",
 "Optician",
 "Optometrist",
 "Oral Surgeon",
 "Order Clerk",
 "Order Filler",
 "Orderly",
 "Ordnance Handling Expert",
 "Orthodontist",
 "Orthotist",
 "Outdoor Power Equipment Mechanic",
 "Oven Operator",
 "Packaging Machine Operator",
 "Painter ",
 "Painting Worker",
 "Paper Goods Machine Setter",
 "Paperhanger",
 "Paralegal",
 "Paramedic",
 "Parking Enforcement Worker",
 "Parking Lot Attendant",
 "Parts Salesperson",
 "Paving Equipment Operator",
 "Payroll Clerk",
 "Pediatrician",
 "Pedicurist",
 "Personal Care Aide",
 "Personal Chef",
 "Personal Financial Advisor",
 "Pest Control Worker",
 "Pesticide Applicator",
 "Pesticide Handler",
 "Pesticide Sprayer",
 "Petroleum Engineer",
 "Petroleum Gauger",
 "Petroleum Pump System Operator",
 "Petroleum Refinery Operator",
 "Petroleum Technician",
 "Pharmacist",
 "Pharmacy Aide",
 "Pharmacy Technician",
 "Philosophy Teacher",
 "Photogrammetrist",
 "Photographer",
 "Photographic Process Worker",
 "Photographic Processing Machine Operator",
 "Physical Therapist Aide",
 "Physical Therapist Assistant",
 "Physical Therapist",
 "Physician Assistant",
 "Physician",
 "Physicist",
 "Physics Teacher",
 "Pile-driver Operator",
 "Pipefitter",
 "Pipelayer",
 "Planing Machine Operator",
 "Planning Clerk",
 "Plant Operator",
 "Plant Scientist",
 "Plasterer",
 "Plastic Patternmaker",
 "Plastic Worker",
 "Plumber",
 "Podiatrist",
 "Police Dispatcher",
 "Police Officer",
 "Policy Processing Clerk",
 "Political Science Teacher",
 "Political Scientist",
 "Postal Service Clerk",
 "Postal Service Mail Carrier",
 "Postal Service Mail Processing Machine Operator",
 "Postal Service Mail Processor",
 "Postal Service Mail Sorter",
 "Postmaster",
 "Postsecondary Teacher",
 "Poultry Cutter",
 "Poultry Trimmer",
 "Power Dispatcher",
 "Power Distributor",
 "Power Plant Operator",
 "Power Tool Repairer",
 "Precious Stone Worker",
 "Precision Instrument Repairer",
 "Prepress Technician",
 "Preschool Teacher",
 "Priest",
 "Print Binding Worker",
 "Printing Press Operator",
 "Private Detective",
 "Probation Officer",
 "Procurement Clerk",
 "Producer",
 "Product Promoter",
 "Production Clerk",
 "Production Occupation",
 "Proofreader",
 "Property Manager",
 "Prosthetist",
 "Prosthodontist",
 "Psychiatric Aide",
 "Psychiatric Technician",
 "Psychiatrist",
 "Psychologist",
 "Psychology Teacher",
 "Public Relations Manager",
 "Public Relations Specialist",
 "Pump Operator",
 "Purchasing Agent",
 "Purchasing Manager",
 "Radiation Therapist",
 "Radio Announcer",
 "Radio Equipment Installer",
 "Radio Operator",
 "Radiologic Technician",
 "Radiologic Technologist",
 "Rail Car Repairer",
 "Rail Transportation Worker",
 "Rail Yard Engineer",
 "Rail-track Laying Equipment Operator",
 "Railroad Brake Operator",
 "Railroad Conductor",
 "Railroad Police",
 "Rancher",
 "Real Estate Appraiser",
 "Real Estate Broker",
 "Real Estate Manager",
 "Real Estate Sales Agent",
 "Receiving Clerk",
 "Receptionist",
 "Record Clerk",
 "Recreation Teacher",
 "Recreation Worker",
 "Recreational Therapist",
 "Recreational Vehicle Service Technician",
 "Recyclable Material Collector",
 "Referee",
 "Refractory Materials Repairer",
 "Refrigeration Installer",
 "Refrigeration Mechanic",
 "Refuse Collector",
 "Regional Planner",
 "Registered Nurse",
 "Rehabilitation Counselor",
 "Reinforcing Iron Worker",
 "Reinforcing Rebar Worker",
 "Religion Teacher",
 "Religious Activities Director",
 "Religious Worker",
 "Rental Clerk",
 "Repair Worker",
 "Reporter",
 "Residential Advisor",
 "Resort Desk Clerk",
 "Respiratory Therapist",
 "Respiratory Therapy Technician",
 "Retail Buyer",
 "Retail Salesperson",
 "Revenue Agent",
 "Rigger",
 "Rock Splitter",
 "Rolling Machine Tender",
 "Roof Bolter",
 "Roofer",
 "Rotary Drill Operator",
 "Roustabout",
 "Safe Repairer",
 "Sailor",
 "Sales Engineer",
 "Sales Manager",
 "Sales Representative",
 "Sampler",
 "Sawing Machine Operator",
 "Scaler",
 "School Bus Driver",
 "School Psychologist",
 "School Social Worker",
 "Scout Leader",
 "Sculptor",
 "Secondary Education Teacher",
 "Secondary School Teacher",
 "Secretary",
 "Securities Sales Agent",
 "Security Guard",
 "Security System Installer",
 "Segmental Paver",
 "Self-enrichment Education Teacher",
 "Semiconductor Processor",
 "Septic Tank Servicer",
 "Set Designer",
 "Sewer Pipe Cleaner",
 "Sewing Machine Operator",
 "Shampooer",
 "Shaper",
 "Sheet Metal Worker",
 "Sheriff's Patrol Officer",
 "Ship Captain",
 "Ship Engineer",
 "Ship Loader",
 "Shipmate",
 "Shipping Clerk",
 "Shoe Machine Operator",
 "Shoe Worker",
 "Short Order Cook",
 "Signal Operator",
 "Signal Repairer",
 "Singer",
 "Ski Patrol",
 "Skincare Specialist",
 "Slaughterer",
 "Slicing Machine Tender",
 "Slot Supervisor",
 "Social Science Research Assistant",
 "Social Sciences Teacher",
 "Social Scientist",
 "Social Service Assistant",
 "Social Service Manager",
 "Social Work Teacher",
 "Social Worker",
 "Sociologist",
 "Sociology Teacher",
 "Software Developer",
 "Software Engineer",
 "Soil Scientist",
 "Solderer",
 "Sorter",
 "Sound Engineering Technician",
 "Space Scientist",
 "Special Education Teacher",
 "Speech-language Pathologist",
 "Sports Book Runner",
 "Sports Entertainer",
 "Sports Performer",
 "Stationary Engineer",
 "Statistical Assistant",
 "Statistician",
 "Steamfitter",
 "Stock Clerk",
 "Stock Mover",
 "Stonemason",
 "Street Vendor",
 "Streetcar Operator",
 "Structural Iron Worker",
 "Structural Metal Fabricator",
 "Structural Metal Fitter",
 "Structural Steel Worker",
 "Stucco Mason",
 "Substance Abuse Counselor",
 "Substance Abuse Social Worker",
 "Subway Operator",
 "Surfacing Equipment Operator",
 "Surgeon",
 "Surgical Technologist",
 "Survey Researcher",
 "Surveying Technician",
 "Surveyor",
 "Switch Operator",
 "Switchboard Operator",
 "Tailor",
 "Tamping Equipment Operator",
 "Tank Car Loader",
 "Taper",
 "Tax Collector",
 "Tax Examiner",
 "Tax Preparer",
 "Taxi Driver",
 "Teacher Assistant",
 "Teacher",
 "Team Assembler",
 "Technical Writer",
 "Telecommunications Equipment Installer",
 "Telemarketer",
 "Telephone Operator",
 "Television Announcer",
 "Teller",
 "Terrazzo Finisher",
 "Terrazzo Worker",
 "Tester",
 "Textile Bleaching Operator",
 "Textile Cutting Machine Setter",
 "Textile Knitting Machine Setter",
 "Textile Presser",
 "Textile Worker",
 "Therapist",
 "Ticket Agent",
 "Ticket Taker",
 "Tile Setter",
 "Timekeeping Clerk",
 "Timing Device Assembler",
 "Tire Builder",
 "Tire Changer",
 "Tire Repairer",
 "Title Abstractor",
 "Title Examiner",
 "Title Searcher",
 "Tobacco Roasting Machine Operator",
 "Tool Filer",
 "Tool Grinder",
 "Tool Maker",
 "Tool Sharpener",
 "Tour Guide",
 "Tower Equipment Installer",
 "Tower Operator",
 "Track Switch Repairer",
 "Tractor Operator",
 "Tractor-trailer Truck Driver",
 "Traffic Clerk",
 "Traffic Technician",
 "Training And Development Manager",
 "Training And Development Specialist",
 "Transit Police",
 "Translator",
 "Transportation Equipment Painter",
 "Transportation Inspector",
 "Transportation Security Screener",
 "Transportation Worker",
 "Trapper",
 "Travel Agent",
 "Travel Clerk",
 "Travel Guide",
 "Tree Pruner",
 "Tree Trimmer",
 "Trimmer",
 "Truck Loader",
 "Truck Mechanic",
 "Tuner",
 "Turning Machine Tool Operator",
 "Typist",
 "Umpire",
 "Undertaker",
 "Upholsterer",
 "Urban Planner",
 "Usher",
 "Valve Installer",
 "Vending Machine Servicer",
 "Veterinarian",
 "Veterinary Assistant",
 "Veterinary Technician",
 "Vocational Counselor",
 "Vocational Education Teacher",
 "Waiter",
 "Waitress",
 "Watch Repairer",
 "Water Treatment Plant Operator",
 "Weaving Machine Setter",
 "Web Developer",
 "Weigher",
 "Welder",
 "Wellhead Pumper",
 "Wholesale Buyer",
 "Wildlife Biologist",
 "Window Trimmer",
 "Wood Patternmaker",
 "Woodworker",
 "Word Processor",
 "Writer",
 "Yardmaster",
 "Zoologist"]

export default  occupations
