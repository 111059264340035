import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container,ButtonGroup} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../App.css";
import "./NonQMCalculator.css"; 

import * as com from "../../Common.js";
import NumericalInput, {CommaizedInput} from "../../NumericalInput";
import { withRouter } from "react-router-dom";
import   PricingResults from "./PricingResults";

import { YellowWarning } from "../../Common/Warnings";
import {Radio, Skeleton, Select, InputNumber, Switch, Checkbox, Popover, Popconfirm, Spin} from "antd";
import { isMobile } from "react-device-detect";
import { checkAccess } from "../../Auth";
import { UR_PriceEngineUser, UR_LoanOfficer, UR_Owner } from "../../State";


let fixed30 = [];
let fixed25 = [];
let fixed20 = [];
let fixed15 = [];
let fixed10 = [];
let arm56 = [];
let arm76 = [];
let arm106 = [];
let allRates = {};

const NonQMCalculator = (props) => {
  let customerid = com.getCustomerId();

  const [specialAdjustments, setSpecialAdjustments] = useState({});
  const [selectedLenders, setSelectedLenders] = useState([]);
  const [selectedIncomeTypes, setSelectedIncomeTypes] = useState([]);
  const [loanTerm,setLoanTerm] = useState("fixed30");
  const [showForm, setShowForm] = useState(true);

  const [showConnectedLendersOnly, setShowConnectedLendersOnly] = useState(true);
  const [eligibilityView, setEligibilityView] = useState("eligible");
  const [pointFormat, setPointFormat] = useState(true);
  const [targetPriceDollar, setTargetPriceDollar] = useState(3000);
  const [targetPricePoint, setTargetPricePoint] = useState(101.75); //for zeitro set compensation as 1.75, for generate client use 100
  const [calcOptions, setCalcOptions] = useState(["lockpoint", "adjustment", "compensation"]);
  const [resultlenderSet, setResultlenderSet] = useState(new Set());
  const [resultIncomeTypes, setResultIncomeTypes] = useState([]);
  const [formContainerHeight, setFormContainerHeight] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [incomeCondition, setIncomeCondition] = useState(["VOE24Mo", "DebtServiceCoverageRatio"]);
  const [employment,setEmployment] = useState("Employed");
  const [emptydti,setEmptyDTI] = useState(false);
  const [emptymonthlyIncome,setEmptymonthlyIncome] = useState(false);
  const [notmodified,setNotmodified] = useState(true);
  const [loanLimits, setLoanLimits] = useState({});
  const [emptyLtv, setEmptyLtv] = useState(false)
  const [emptyDownpay,setEmptyDownpay] = useState(false)
  const [emptyPropertyVal,setEmptyPropertyVal] = useState(false)
  const [propertyType, setPropertyType] = useState("singlefamilydetached");
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");
  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(100000);
  const [county, setCounty] = useState("ALAMEDA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [dti, setDTI] = useState(10);
  const [dscr, setDSCR] = useState(1);
  const [monthlyIncome, setMonthlyIncome] = useState(10000);
  const [monthlyDebt, setMonthlyDebt] = useState(3000);
  const [estOtherCosts, setEstOtherCosts] = useState(3195);
  const [units, setUnits] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState(fixed30);
  const [fico, setFico] = useState(780);
  const [lockDay, setLockDay] = useState(30);
  const [citizenship, setCitizenship] = useState("US_CITIZENS");
  const [visaType, setVisaType] = useState("H_1B");
  const [numberOfOwnedProperties, setNumberOfOwnedProperties] = useState(1);
  const [currentJobInMonths, setCurrentJobInMonths] = useState(24);
  const [secondMortageAmount, setSecondMortgageAmount] = useState(0);
  const [escrowWaiver, setEscrowWaiver] = useState(false)
  const [selfEmployed, setSelfEmployed] = useState(false)
  const [firstTimeHomeBuyer, setfirstTimeHomeBuyer] = useState("Yes")
  const [appraisalWaiver, setAppraisalWaiver] = useState(false)
  const [rsuAsIncome, setRsuAsIncome] = useState(false)
  const [giftFundUsed, setGiftFundUsed] = useState(false)
  const [workForSpecialBusiness, setWorkForSpecialBusiness] = useState(false)
  const [borrowerPaidCompensationAmount, setBorrowerPaidCompensationAmount] = useState(0)
  
  const [indexfixed30, setIndexFixed30] = useState(8);
  const [indexfixed25, setIndexFixed25] = useState(6);
  const [indexfixed20, setIndexFixed20] = useState(6);
  const [indexfixed15, setIndexFixed15] = useState(6);
  const [indexfixed10, setIndexFixed10] = useState(6);
  const [indexarm56, setIndexArm56] = useState(6);
  const [indexarm76, setIndexArm76] = useState(6);
  const [indexarm106, setIndexArm106] = useState(6);
  const [ineligibleProducts, setIneligibleProducts] = useState({});
  const [showIneligible, setShowIneligible] = useState(false);
  const [noProducts, setNoProducts] = useState(false);
  const [showRatePreview, setShowRatePreview] = useState(false);
  const [loProfile, setLOProfile] = useState({});

  const [message, setMessage] = useState("");
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showAddLenderModal, setShowAddLendersModal] = useState(false);
  const [showCreateLoan, setShowCreateLoan] = useState(false);

  const [connectedLendersMap, setConnectedLendersMap] = useState({});
  const specificProgram = props.specificProgram


  let targetPriceOptions = [
    { label: "Lock point", value: "lockpoint" },
    { label: "Adjustment", value: "adjustment" },
    { label: "Compensation", value: "compensation" },

  ];


  useEffect(() => {
    measureFormContainerHeight();

    window.addEventListener("resize", measureFormContainerHeight);

    return () => {
      window.removeEventListener("resize", measureFormContainerHeight);
    }
  },[]);

  useEffect(() => {
    // Function to update viewport height when the window is resized
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth)
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=>{
    if (propertyType === "manufacturedhome") {
      setLoanTerm("fixed25")
    }
  }, [propertyType])


  const underwritingOptions = [
    { label: "DU", value: "DU" },
    { label: "LP", value: "LP" },
    { label: "Manual", value: "Manual" },
  ];
  const [selectedAUS, setSelectedAUS] = useState(underwritingOptions);

  const form = useRef();

  let getLtv = () => {
    let ltv = Math.round((getLoanAmount() / parseInt(propertyValue)) * 100);
    let cltv =  Math.round(((getLoanAmount() + parseInt(secondMortageAmount)) / parseInt(propertyValue)) * 100);
    let hcltv =  Math.round(((getLoanAmount() + parseInt(secondMortageAmount))/ parseInt(propertyValue)) * 100);
    return [ltv, cltv, hcltv];
  };

  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };


  let getProducts = () => {
    let pmi = 0.51;
    let zeitro_fee = [{ lender: "default", percent: 1.0, fixed: 500 }];
    let _units = 1;
    let _county = "ZAVALA COUNTY";
    if (propertyType === "twotofourfamily") _units = units;

    setMessage("");

    arm56 = [];
    arm76 = [];
    arm106 = [];
    fixed30 = [];
    fixed25 = [];
    fixed20 = [];
    fixed15 = [];
    fixed10 = [];
    let [ltv, cltv, hcltv] = getLtv();
    
    if (state != "TEXAS") _county = county;

    let aus = []
    for (let a of selectedAUS) {
      aus.push(a.label)
    }

    let body = {
      state: state,
      county: _county,
      purpose: purpose,
      loanAmount: getLoanAmount(),
      units: parseInt(_units),
      LTV: ltv,
      CLTV: cltv,
      HCLTV: hcltv,
      FICO: fico,
      occupancy: com.getOccupancy(occupancy),
      property: com.getPropertyType(propertyType),
      point: zeitro_fee,
      estimatedClosingCosts: parseFloat(estOtherCosts),
      pmi: pmi,
      getFiles: false,
      monthlyIncome: parseFloat(monthlyIncome),
      monthlyDebt: parseFloat(monthlyDebt),
      firsttimehomebuyer: firstTimeHomeBuyer==="Yes" ? true : false,
      selfemployed: selfEmployed,
      citizenship: citizenship,
      visaType: visaType,
      numberOfOwnedProperties: numberOfOwnedProperties,
      escrowWaiver: escrowWaiver,
      appraisalWaiver: appraisalWaiver,
      currentJobInMonths: currentJobInMonths,
      rsuAsIncome: rsuAsIncome ? 10 : -1,
      giftFundUsed: giftFundUsed,
      familyRelatedOrSmallBusiness: workForSpecialBusiness,
      borrowerPaidCompensationAmount: borrowerPaidCompensationAmount,
      dti:dti,
      incomeDocumentationType: "",
      nonQMDocTypes: incomeCondition,
      DSCR: dscr,
    };
    setLoading(true);
    fetch("/data/nonqmcalculator/" + customerid, {
      method: "POST",
      headers: {
        Cache: "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          setMessage("An internal error has occured");
          return;
        }
        response.json().then((prd) => {
          const noLendersAdded = Object.values(prd.connected_lender_map).every(value => value === false)
          setConnectedLendersMap(prd.connected_lender_map)
          let products = prd.result;
          let prTypesSet = new Set();
          if (products !== undefined) {
            if (specificProgram !== undefined) {
              products = products.filter((p) => p.Lender === specificProgram);
            }
            let lenderSet = new Set();
            products.forEach((product) => {
              lenderSet.add(product.Lender);
            });
            setResultlenderSet(lenderSet);
            setSelectedLenders(showConnectedLendersOnly? Array.from(lenderSet).filter(x=>prd.connected_lender_map[x]): Array.from(lenderSet));
            // if no lenders are added
            if (noLendersAdded) {
              setShowConnectedLendersOnly(false);
              setShowIneligible(false);
              setShowAddLendersModal(true);
              setSelectedLenders(Array.from(lenderSet))
            }            
          } 

          if (prd.ineligible_reasons != undefined) {
            if (specificProgram !== undefined) {
              if (prd.ineligible_reasons[specificProgram]) {
                let ine = {}
                ine[specificProgram] = prd.ineligible_reasons[specificProgram]
                setIneligibleProducts(ine);
              }
            } else {
              let ine = {}
              for (let key in prd.connected_lender_map) {
                if (prd.ineligible_reasons[key]) {
                  ine[key] = prd.ineligible_reasons[key]
                }
              }
              setIneligibleProducts(ine);
            }
          } else {
            setIneligibleProducts({});
          }
          if (prd.message != undefined) {
            setMessage(prd.message);
          } else {
            setMessage("");
          }
          if (products === undefined) {
            setNoProducts(true)
            setShowIneligible(true);
            if(prd.message ==="can not get lender fee data"){
              setShowIneligible(false);
              setShowAddLendersModal(true);
            }
            setLoading(false);
            return;
          } else {
            setNoProducts(false)
          }
          let incomeTypesSet = []
          products.forEach((product) => {
            let lender = product.Lender;
            let fb = product.FileB;
            product.ProductRatesCosts.forEach((pr) => {
              let commonproduct = pr.product;
              if (commonproduct.IncomeDocTypes) {
                for (let incomeType of commonproduct.IncomeDocTypes) {
                  if (!incomeTypesSet.includes(incomeType)) {
                    incomeTypesSet.push(incomeType);
                  }
                }
              }
              let adjustments = pr.appliedAdjustments;
              let ausengine = pr.AUSEngine;
              if (showConnectedLendersOnly && !noLendersAdded) {
                if (prd.connected_lender_map[lender]) {
                  prTypesSet.add(commonproduct.Mtype);
                }
              } else {
                prTypesSet.add(commonproduct.Mtype);
              }

              pr.rateCosts.forEach((rate) => {
                let pdkt = {
                  Lender: lender,
                  ...commonproduct,
                  Aus: ausengine,
                  ...rate,
                  Adjustments: adjustments,
                  FileB: fb,
                };
                
                if (rate.lockDay !== lockDay) {
                  return;
                }
                let commonAUS = selectedAUS.filter(val => pdkt.Aus.includes(val.label));
                if (commonAUS.length === 0) {
                  return
                }
                const filterPdkt = () => {
                  if (customerid==="siliconcitymortgage" && pdkt.Mtype.includes("FHA")) {
                    return false
                  }
                  return true
                }

                /// SOMRTHING ELSE HERE
                pdkt.loanAmount = getLoanAmount();
                if (filterPdkt()) {
                  if (pdkt.Arm != null) {
                    if (pdkt.Arm.fixedperiod == 5) {
                      arm56.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 7) {
                      arm76.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 10) {
                      arm106.push(pdkt);
                    }
                  } else {
                    if (pdkt.Term == 30) {
                      fixed30.push(pdkt);
                    } else if (pdkt.Term == 25) {
                      fixed25.push(pdkt);
                    } else if (pdkt.Term == 20) {
                      fixed20.push(pdkt);
                    } else if (pdkt.Term == 15) {
                      fixed15.push(pdkt);
                    } else if (pdkt.Term == 10) {
                      fixed10.push(pdkt);
                    }
                  }
                }
              });
            });
          });

          setSelectedIncomeTypes(incomeTypesSet);
          setResultIncomeTypes(incomeTypesSet);
          console.log(incomeTypesSet)
          
          let compareRate = (a, b) => {
            if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate;

            return a.closing_cost - b.closing_cost; // take into account remaining credits
          };

          let cullRate = (products) => {
            const filteredPrds = products.filter((p) => {
              return p.lender_credit > 0;
            });

            if (filteredPrds.length === 0) return filteredPrds;
            let newproducts = [];
            newproducts.push(filteredPrds[0]);
            let base_rate = filteredPrds[0].base_rate;
            let closing_cost = filteredPrds[0].closing_cost;

            for (let i = 1; i < filteredPrds.length; i++) {
              if (filteredPrds[i].base_rate !== base_rate) {
                if (filteredPrds[i].closing_cost < closing_cost) {
                  newproducts.push(filteredPrds[i]);
                  closing_cost = filteredPrds[i].closing_cost;
                }
                base_rate = filteredPrds[i].base_rate;
              } else {
              }
            }
            return newproducts;
          };

          let getIndex = (loans) => {
            let bestIndex = 0;
            let bestPoints = 1000000;
            for (let i = 0; i < loans.length; i++) {
              if (Math.abs(loans[i].lender_credit - 1500) < bestPoints) {
                bestPoints = Math.abs(loans[i].lender_credit - 1500);
                bestIndex = i;
              }
            }
            return bestIndex;
          };

          allRates = {};
          allRates.fixed30 = fixed30;
          allRates.fixed25 = fixed25;
          allRates.fixed20 = fixed20;
          allRates.fixed15 = fixed15;
          allRates.fixed10 = fixed10;
          allRates.arm56 = arm56;
          allRates.arm76 = arm76;
          allRates.arm106 = arm106;
          fixed30.sort(compareRate);
          fixed25.sort(compareRate);
          fixed20.sort(compareRate);
          fixed15.sort(compareRate);
          fixed10.sort(compareRate);
          arm56.sort(compareRate);
          arm76.sort(compareRate);
          arm106.sort(compareRate);
          fixed30 = cullRate(fixed30);
          fixed25 = cullRate(fixed25);
          fixed20 = cullRate(fixed20);
          fixed15 = cullRate(fixed15);
          fixed10 = cullRate(fixed10);
          arm56 = cullRate(arm56);
          arm76 = cullRate(arm76);
          arm106 = cullRate(arm106);


          setIndexFixed30(getIndex(fixed30));
          setIndexFixed25(getIndex(fixed25));
          setIndexFixed20(getIndex(fixed20));
          setIndexFixed15(getIndex(fixed15));
          setIndexFixed10(getIndex(fixed10));
          setIndexArm56(getIndex(arm56));
          setIndexArm76(getIndex(arm76));
          setIndexArm106(getIndex(arm106));

          if (fixed30.length !== 0) {
            setLoans(fixed30);
          } else if (arm56.length !== 0) {
            setLoans(arm56);
          }
          setLoading(false);
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  const getLoanLimits = () => {
    setLoading(true);
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          // console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    };


  const ref = useRef();
  const formContainerRef = useRef(null);

  const measureFormContainerHeight = () => {
    if (formContainerRef.current) {
      setFormContainerHeight(formContainerRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    measureFormContainerHeight();

    window.addEventListener("resize", measureFormContainerHeight);

    return () => {
      window.removeEventListener("resize", measureFormContainerHeight);
    }
  },[]);



  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });


  useEffect(() => {
    // getProducts();
    getLoanLimits();

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Error);
          } else {
            let lo = js.LoanOfficers[0];
            setLOProfile({
              firstName: lo.FirstName,
              lastName: lo.LastName,
              email: lo.Email,
              phone: lo.Phone,
              avatarsrc: lo.Image,
              nmls: lo.Nmls,
            });
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
    // console.log("lo", loProfile);
  }, []);

  useEffect(() => {
    if (!showSubscribe) setShowRatePreview(false);
  }, [showSubscribe, showRatePreview]);

  useEffect(() => {
    setMonthlyDebt(parseInt(monthlyIncome * dti / 100));
  }, [monthlyIncome, dti]);



  let showCounties = () => {
    let options = [<option value="">Select your county</option>];
    if (state in loanLimits) {
      let keys = Object.keys(loanLimits[state]);
      
      keys.forEach((x) => {
        options.push(<option value={x}> {com.capitalize(x)}</option>);
      });
    }
    return options;
  };

  let showStates = () => {
    let options = [<option value="">Select your state</option>];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  }

  let updateTargetPrice = (e) => {
    let val = e.target.value;
    if (val === "") {
      setTargetPriceDollar(0);
      setTargetPricePoint(0);
    } else {
      let num = parseInt(val);
      setTargetPriceDollar(num);
      setTargetPricePoint(num * 100);
    }
  };

  let handleCalcOptionChange = (value) => {
    setCalcOptions(value);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }
 
    if (parseInt(downpayment) >= parseInt(propertyValue)) {
      setValidated(true);
      alert("Downpayment can't be bigger than purchase price");
      return false;
    }
    if (parseInt(fico) < 300 || parseInt(fico) > 1000) {
      setValidated(true);
      alert("FICO score should be in the range of 300 - 1000");
      return false;
    }
    setNotmodified(false);
    setShowForm(false);
    getProducts();
    return false;
  };

  let onMoreOptions = (e) => {
    if (showMoreOptions) {
      setShowMoreOptions(false);
    } else {
      setShowMoreOptions(true);
    }
  };


  const updateProperValue = (e) => {
    let val = e.target.value
    setEmptyLtv(false)
    if (val==="") {
      setEmptyPropertyVal(true)
      setPropertyValue(100000)
    } else {
      setEmptyPropertyVal(false)
      setPropertyValue(parseInt(val))
    }
  }
  const updateMonthlyIncome = (e) => {
    let val = e.target.value
    if (val==="") {
      setEmptymonthlyIncome(true)
      setMonthlyIncome(0)
    } else {
      setEmptymonthlyIncome(false)
      setMonthlyIncome(parseInt(val))
    }
  }
 
  const updateDownpayment = (e) => {
    let val = e.target.value
    setEmptyLtv(false)
    if (val==="") {
      setDownpayment(Math.round(propertyValue * 0.03))
      setEmptyDownpay(true)
    } else {
      setEmptyDownpay(false)
      setDownpayment(parseInt(val))
    }
  }
  const updateLTV = (e) => {
    let val = e.target.value
    setEmptyDownpay(false)
    if (val==="") {
      setDownpayment(Math.round(propertyValue))
      setEmptyLtv(true)
    } else {
      let ltv = parseInt(val)
      setEmptyLtv(false)
      if (ltv <= 0) { // TBD
        setDownpayment(Math.round(propertyValue))
      } else if (ltv > 100) {
        setDownpayment(Math.round(propertyValue * 0))
      } else {
        if(purpose==="Purchase"){
          setDownpayment(Math.round(propertyValue * (1 - 0.01 * ltv)))
        }
        else{
          setDownpayment(Math.round(propertyValue * (0.01 * ltv)))
        }
      }
    }
  }

  const updateDTI = (e) => {
    let val = e.target.value
    setEmptyDTI(false)
    if (val==="") {
      setDTI(0)
      setEmptyDTI(true)
    } else {
      setEmptyDTI(false)
      setDTI(parseInt(val))
      // setMonthlyDebt(parseInt(val) * 0.01 * parseInt(monthlyIncome))
    }
  }
  const handleChangeEmployment = (e) => {
    setEmployment(e.target.value)
    if (e.target.value==="Selfemployed") {
      setSelfEmployed(true)
    }
  }

  const radioOptions=[
    {
      label:"Purchase",
      value:"Purchase"
    },
    {
      label:"Refinance",
      value:"RateTermRefi"
    },
    {
      label:"Cashout",
      value:"CashOutRefi"
    }
  ]

  const allIncomeTypes = {
    VOE: 'VOE',
    VOE24Mo: '2-Year VOE',
    PersonalBankStatement12Mo: '1-Year personal bank statement',
    PersonalBankStatement24Mo: '2-Year personal bank statement',
    BusinessBankStatement12Mo: '1-Year business bank statement',
    BusinessBankStatement24Mo: '2-Year business bank statement',
    Form109924Mo: '2-Year form 1099',
    Form109912Mo: '1-Year form 1099',
    PL12: '1-Year P & L',
    PL24: '2-Year P & L',
    AssetUtilization: 'Asset utilization',
    DebtServiceCoverageRatio: 'DSCR',
  }

  const onRadioChange=({target:{value}}) =>{
    setPurpose(value);
  }


  const onEligibilityViewChange = (e) => {
    setEligibilityView(e);
  }

  const getFormBody = () => {
    return (
      <div
      style={{maxWidth:"1050px",display:"flex",flexDirection:"column",justifyContent:"space-between",height:notmodified?formContainerHeight-30:formContainerHeight-60}}
      className="nonqm-rate-quoter-form"
    >
      <div style={{height:formContainerHeight-120,overflowY:"auto"}}>
      <div style={{textAlign:'center',marginBottom:"20px",paddingLeft:"10px"}}>
            <Radio.Group
              className="purposeRadiobutton"
              options={radioOptions}
              onChange={onRadioChange}
              value={purpose}
              optionType="button"
              buttonStyle="solid"
            />
      </div> 
      <hr style={{marginLeft:"15px",marginRight:"5px",borderTop:"1px solid #DBDCDE"}}/> 

      <Row>
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              {purpose === "Purchase"
                ? "Property value"
                : "Home value"}
            </Form.Label>
            <div className="formCommaizedInput">
              <div className="prefix">$</div>
              <CommaizedInput value={emptyPropertyVal?"":propertyValue} onChange={updateProperValue} />
            </div>
          </Form.Group>
        </Col>

        <Col md={4} style={{marginBottom:"0px", paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label
              className="ratequoterFormLabels text-left"
            >
              {purpose === "Purchase"
                ? "Down payment"
                : "Mortgage balance"}
            </Form.Label>
            {/* <div className="rateQuoterFormSliderOutline"> */}
            <div className="formCommaizedInput">
              <div className="prefix">$</div>
              <CommaizedInput value={emptyDownpay?"":downpayment} onChange={updateDownpayment} />
            </div>
          </Form.Group>
        </Col>

        <Col md={4} style={{marginBottom:"0px", paddingRight:"10px"}}>
          <Form.Group className="text-left" style={{display:"flex", flexDirection:"column"}}>
            <Form.Label className="ratequoterFormLabels">
              LTV
            </Form.Label>
            <div className="formInputWithSuffix">
              <input className="ltv" type="number" style={{minWidth:"15px",border:"none",paddingLeft:"10px"}} value={emptyLtv?"":getLtv()[0]} onChange={updateLTV}/>
              <div className="ltvSuffix" style={{marginRight:"10px", backgroundColor:"#FCFCFC"}}>%</div>
            </div>
          </Form.Group>
        </Col>

        <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="incomeCondition" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Income documentation
            </Form.Label>
            <Select
              mode="multiple"
              style={{ width: '100%'}}
              placeholder="Select income conditions"
              defaultValue={incomeCondition}
              maxTagCount="responsive"
              onChange={(values) => {
                setIncomeCondition(values);
              }}
              options={Object.keys(allIncomeTypes).map((x)=>({value:x,label:allIncomeTypes[x]}))}
            />
          </Form.Group>
        </Col>
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              Monthly income
            </Form.Label>
            <div className="formCommaizedInput">
              <div className="prefix">$</div>
              <CommaizedInput value={emptymonthlyIncome?"":monthlyIncome} onChange={updateMonthlyIncome} />

            </div>
          </Form.Group>
        </Col>
        {incomeCondition.includes("DebtServiceCoverageRatio") && <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
         <Form.Group className="text-left ">
          <Form.Label
            className="ratequoterFormLabels"
          >
            DSCR
          </Form.Label>
          <Form.Control
              size="sm"
              type="number"
              step="0.01"
              onChange={(e) => e.target.value === "" ? setDSCR(0) : setDSCR(parseFloat(e.target.value))}
              value={dscr}
              placeholder="Enter DSCR"
              required
            ></Form.Control>
        </Form.Group> </Col> } 
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
        <Form.Group className="text-left ">
          <Form.Label
            className="ratequoterFormLabels"
          >
            Current DTI
          </Form.Label>
          <div className="formInputWithSuffix">
            <input className="ltv" type="number" style={{minWidth:"15px",border:"none",paddingLeft:"10px"}} value={emptydti?"":dti} onChange={updateDTI}/>
            <div className="ltvSuffix" style={{marginRight:"10px", backgroundColor:"#FCFCFC"}}>%</div>
          </div>
        </Form.Group>  
      </Col>

  
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              Loan term
            </Form.Label>
            <Form.Control
              size="sm"
              as="select"
              onChange={(e) => setLoanTerm(e.target.value)}
              value={loanTerm}
              required
            >
              {propertyType !== "manufacturedhome" && <option value="fixed30">Fixed 30 year</option>}
              <option value="fixed25">Fixed 25 year</option>
              <option value="fixed20">Fixed 20 year</option>
              <option value="fixed15">Fixed 15 year</option>
              <option value="fixed10">Fixed 10 year</option>
              <option value="arm56">ARM 5/6</option>
              <option value="arm76">ARM 7/6</option>
              <option value="arm106">ARM 10/6</option>

            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="occupancy" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Occupancy
            </Form.Label>
            <Form.Control
              name="occupancy"
              as="select"
              size="sm"
              defaultValue={occupancy}
              onChange={(e) => {
                onOccupancy(e.target.value);
              }}
            >
              <option value="principal">Primary Residence</option>
              <option value="secondhome">Second Home</option>
              <option value="investment">Investment</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="propertytype" className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              Property type
            </Form.Label>
            <Form.Control
              name="propertytype"
              as="select"
              size="sm"
              type="text"
              required
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
            >
              <option value=""></option>
              <option value="singlefamilydetached">
                Single Family Home, Detached
              </option>
              <option value="singlefamilyattached">
                Single Family Home, Attached
              </option>
              <option value="condominiumdetached">
                Condominium, Detached
              </option>
              <option value="condominium">Condominium, Attached</option>
              <option value="pudattached">
                Planned Unit Development, attached
              </option>
              <option value="puddetached">
                Planned Unit Development, detached
              </option>
              <option value="twotofourfamily">
                2 to 4 Unit Multifamily
              </option>
              <option value="manufacturedhome">Manufactured Home</option>
            </Form.Control>
          </Form.Group>
        </Col>
        {propertyType === "twotofourfamily" ? (
          <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
            <Form.Group controlId="propertytype" className="text-left ">
              <Form.Label 
                className="ratequoterFormLabels"
              >
                Number of units
              </Form.Label>
              <Form.Control
                name="propertytype"
                as="select"
                size="sm"
                type="text"
                required
                value={units}
                onChange={(e) => setUnits(e.target.value)}
              >
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </Form.Control>
            </Form.Group>
          </Col>
        ) : (
          ""
        )}

        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">State</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              id="state"
              onChange={(e) => setState(e.target.value)}
              required
              value={state}
            >
              {showStates()}
            </Form.Control>
          </Form.Group>
        </Col>
        {state !== "TEXAS" ? (
          <Col md={4} style={{marginBottom:"0px", paddingRight:"10px"}} >
            <Form.Group className="text-left ">
              <Form.Label className="ratequoterFormLabels">
                County
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                id="country"
                name="country"
                onChange={(e) => setCounty(e.target.value)}
                value={county}
                required
              >
                {showCounties()}
              </Form.Control>
            </Form.Group>
          </Col>
        ) : (
          ""
        )}
        
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group controlId="firstTimeHomeBuyer" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              First time home buyer
            </Form.Label>
            <Form.Control
              name="firstTimeHomeBuyer"
              as="select"
              size="sm"
              defaultValue={firstTimeHomeBuyer}
              onChange={(e) => {
                setfirstTimeHomeBuyer(e.target.value);
              }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Control>
          </Form.Group>
        </Col>
      
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              Credit score
            </Form.Label>
            <Form.Control
              size="sm"
              as="input"
              onChange={(e) => e.target.value===""? setFico(0) : setFico(parseInt(e.target.value))}
              value={fico}
              placeholder="Enter credit score"
              required
            ></Form.Control>
          </Form.Group>
        </Col>
      
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
            <Form.Group className="text-left ">
              <Form.Label className="ratequoterFormLabels">
                Lock day
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                onChange={(e) => setLockDay(parseInt(e.target.value))}
                value={lockDay}
                required
              >
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
              </Form.Control>
            </Form.Group>
          </Col>
        <Col md={12}>
          <div className=" d-flex text-left" style={{ marginBottom: "0px", fontWeight:"500", fontSize:"12px",lineHeight:"20px",color:'#586570'}}>
              Target price (Base 100)
          </div>
          <div className="targetPriceFilter">
            <div className="targetPriceInputWrap">
              {pointFormat ? (
                <>
                  <input type="number" className="targetPriceInput" value={targetPricePoint} onChange={e=>setTargetPricePoint(e.target.value)} />
                  <div className="inputSign">%</div>
                </>
              ) : (
                <>
                  <div style={{backgroundColor:"#FCFCFC", marginLeft:"10px",borderRight:"1px solid #CFD4D9",paddingTop:"13px",paddingBottom:"13px",paddingRight:"8px"}}>$</div>
                  <CommaizedInput style={{border:"none"}} className="targetPriceInput" value={targetPriceDollar} onChange={updateTargetPrice} />
                </>
              )}
            </div>
            <div className="calcOptionSelectWrap">
              <div className="calcOptionsPrefix">Includes</div>
              <Select 
                mode= "multiple"
                className="calcOptionsSelect"
                allowClear
                value={calcOptions}
                onChange={handleCalcOptionChange}
                options={targetPriceOptions}
                placeholder="Select point type included in the calculation"
              />
            </div>
          </div>
          <div className="calculationHightlight">
            <div>{"*This filter helps identify rates matching your target lender pricing by calculating the current target price from the sum of "}
            {calcOptions.includes("lockpoint") && " lock point "} {calcOptions.includes("lockpoint") && (calcOptions.includes("adjustment") || calcOptions.includes("compensation")) && " , "} 
            {calcOptions.includes("adjustment") && " adjustment point "} {calcOptions.includes("adjustment") && calcOptions.includes("compensation") && " , "}
            {calcOptions.includes("compensation") && " compensation point "}</div>
          </div>
        </Col>
      </Row>
      {showMoreOptions && (
        <Row>
          <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="employment" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Employment
            </Form.Label>
            <Form.Control
              name="employment"
              as="select"
              size="sm"
              defaultValue={employment}
              onChange={handleChangeEmployment}
            >
              <option value="Employed">Employed</option>
              <option value="Selfemployed">Self-Employed</option>
              {/* <option value="Unemployed">Unemployed</option> */}
            </Form.Control>
          </Form.Group>
        </Col>
       
        <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="assets" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Impound
            </Form.Label>
            <Form.Control
              name="escrow"
              as="select"
              size="sm"
              defaultValue={escrowWaiver}
              onChange={(e) => {
                setEscrowWaiver(e.target.value);
              }}
            >
              <option value="false">Yes</option>
              <option value="true">No</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left numericalInputWraper">
            <Form.Label className="ratequoterFormLabels">
              Second loan amount
            </Form.Label>
            <NumericalInput
              size="sm"
              value={secondMortageAmount}
              required
              onChange={(e) => setSecondMortgageAmount(e.target.value)}
              min={0}
              step={10000}
              noarrow={true}
            />
          </Form.Group>
        </Col>
        <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
            <Form.Group className="text-left">
              <Form.Label className="ratequoterFormLabels">
                Citizenship
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                onChange={(e) => setCitizenship(e.target.value)}
                value={citizenship}
                required
              >
                <option value="US_CITIZENS">US Citizen</option>
                <option value="PERMANENT_RESIDENT_ALIEN">
                  Permanet Resident Alien
                </option>
                <option value="NON_RESIDENT_ALIEN">
                  Non Resident Alien
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
          {citizenship !== "US_CITIZENS" && (
            <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
              <Form.Group className="text-left ">
                <Form.Label className="ratequoterFormLabels">
                  Visa type
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  onChange={(e) => setVisaType(e.target.value)}
                  value={visaType}
                  required
                >
                  <option value="A_1"> A-1</option>
                  <option value="A_2"> A-2</option>
                  <option value="A_3"> A-3</option>
                  <option value="B_1"> B-1</option>
                  <option value="B_2"> B-2</option>
                  <option value="B_3"> B-3</option>
                  <option value="C_1"> C-1</option>
                  <option value="C_2"> C-2</option>
                  <option value="C_3"> C-3</option>
                  <option value="C_4"> C-4</option>
                  <option value="C_1D"> C-1D</option>
                  <option value="CP"> CP</option>
                  <option value="D_1"> D-1</option>
                  <option value="D_2"> D-2</option>
                  <option value="E_1"> E-1</option>
                  <option value="E_2"> E-2</option>
                  <option value="E_3"> E-3</option>
                  <option value="F_1"> F-1</option>
                  <option value="F_2"> F-2</option>
                  <option value="F_3"> F-3</option>
                  <option value="G_1"> G-1</option>
                  <option value="G_2"> G-2</option>
                  <option value="G_3"> G-3</option>
                  <option value="G_4"> G-4</option>
                  <option value="G_5"> G-5</option>
                  <option value="H_1A"> H-1A</option>
                  <option value="H_1B"> H-1B</option>
                  <option value="H_1B1"> H-1B1</option>
                  <option value="H_1C"> H-1C</option>
                  <option value="H_2"> H-2</option>
                  <option value="H_2A"> H-2A</option>
                  <option value="H_2B"> H-2B</option>
                  <option value="H_3"> H-3</option>
                  <option value="H_4"> H-4</option>
                  <option value="I"> I </option>
                  <option value="OR_1"> OR-1</option>
                  <option value="J_1"> J-1</option>
                  <option value="J_2"> J-2</option>
                  <option value="K_1"> K-1</option>
                  <option value="K_2"> K-2</option>
                  <option value="K_3"> K-3</option>
                  <option value="K_4"> K-4</option>
                  <option value="L_1"> L-1</option>
                  <option value="L_2"> L-2</option>
                  <option value="M_1"> M-1</option>
                  <option value="M_2"> M-2</option>
                  <option value="M_3"> M-3</option>
                  <option value="N_8"> N-8</option>
                  <option value="N_9"> N-9</option>
                  <option value="NACARA"> NACARA</option>
                  <option value="NATO_1_6"> NATO-1-6</option>
                  <option value="NATO_7"> NATO-7</option>
                  <option value="O_1"> O-1</option>
                  <option value="O_2"> O-2</option>
                  <option value="O_3"> O-3</option>
                  <option value="P_1"> P-1</option>
                  <option value="P_2"> P-2</option>
                  <option value="P_3"> P-3</option>
                  <option value="P_4"> P-4</option>
                  <option value="Q_1"> Q-1</option>
                  <option value="Q_2"> Q-2</option>
                  <option value="Q_3"> Q-3</option>
                  <option value="R_1"> R-1</option>
                  <option value="R_2"> R-2</option>
                  <option value="S_5"> S-5</option>
                  <option value="S_6"> S-6</option>
                  <option value="S_7"> S-7</option>
                  <option value="T_1"> T-1</option>
                  <option value="T_2"> T-2</option>
                  <option value="T_3"> T-3</option>
                  <option value="T_4"> T-4</option>
                  <option value="TD"> TD</option>
                  <option value="TN_1"> TN-1</option>
                  <option value="TN_2"> TN-2</option>
                  <option value="U_1"> U-1</option>
                  <option value="U_2"> U-2</option>
                  <option value="U_3"> U-3</option>
                  <option value="U_4"> U-4</option>
                  <option value="V_1"> V-1</option>
                  <option value="V_2"> V-2</option>
                  <option value="V_3"> V-3</option>
                </Form.Control>
              </Form.Group>
            </Col>
          )}


        </Row>
        
      )}
    
      <Row className="text-left" style={{paddingLeft:'5px'}}>
          <Button style={{paddingLeft:"0", color:"#325CEB",marginBottom: props.isMobile?"20px":""}}
            variant="rateQuoterAdvancedOptions"
            onClick={onMoreOptions}
            size="sm"
          >
            {showMoreOptions ? <>Less Options<img src="/images/blueMinusInCircleMark.png" style={{maxWidth:"20px", paddingLeft:"5px"}}/></> : <>Advanced Options<img src="/images/bluePlusInCircleMark.png" style={{maxWidth:"20px", paddingLeft:"5px"}}/></>}
            
          </Button>{" "}
      </Row>
      </div>
    </div>
  )}

  const updateResultFromFilter = () => {
    setNotmodified(false);
    getProducts();
  }

  const handleChangeSelectedlenders = (value) => {
    setSelectedLenders(value)
  }


  return (
    <div
      id="contain"
      ref={ref}
      style={{backgroundColor:"#F6F6F6",color: "black",justifyContent:"start"}}
      className="w-100 h-100 text-center"
    >
      <div className="w-100">
        <div className="filterWraper">
          <div className="d-flex align-items-center h-100 w-100">
              <Select 
                className="eligibilitySelect"
                onChange={(e)=> onEligibilityViewChange(e)}
                value = {eligibilityView}
                options={[
                  {value:"eligible",label:"Eligible"},
                  {value:"ineligible",label:"Ineligible"}
                ]}
              />
              <div className="d-flex pl-2 w-100">
                <div className="d-flex flex-grow-1">
                  <div className="formInputOutline" style={{width:"172px"}}>
                    <div className="d-flex mr-2" style={{fontSize:"14px",whiteSpace:"nowrap"}}>Target price</div>
                    <input type="number"  value={targetPricePoint} onChange={e=>setTargetPricePoint(e.target.value)} /> 
                  </div>
                  <Select 
                    style={{marginRight:"5px"}}
                    allowClear
                    mode="multiple"
                    className="lenderDisplaySelect"
                    placeholder="Select lenders"
                    maxTagCount='responsive'
                    maxTagTextLength={5}
                    maxTagPlaceholder={(values)=>`${values.length} lenders`}
                    value={selectedLenders}
                    onChange={handleChangeSelectedlenders}
                    options={
                      !showConnectedLendersOnly?
                        Array.from(resultlenderSet).map((x)=>({value:x,label:com.capitalize(x)}))
                        :
                        Array.from(resultlenderSet).filter(x=> connectedLendersMap[x]).map((x)=>({value:x,label:com.capitalize(x)}))
                    }
                  />
                  <Select 
                    style={{marginRight:"5px"}}
                    allowClear
                    mode="multiple"
                    className="lenderDisplaySelect"
                    placeholder="Select income"
                    maxTagCount='responsive'
                    maxTagTextLength={5}
                    maxTagPlaceholder={(values)=>`${values.length} income types`}
                    value={selectedIncomeTypes}
                    onChange={(e)=> setSelectedIncomeTypes(e)}
                    options={resultIncomeTypes.map((x)=>({value:x,label: allIncomeTypes[x]}))}
                  />
                  <Select
                    style={{marginRight:"5px"}}
                    className="termSelectShortcut"
                    onChange={(e)=> setLoanTerm(e)}
                    value={loanTerm}
                    options={[
                      {value:"fixed30",label:"Fixed 30 year"},
                      {value:"fixed25",label:"Fixed 25 year"},
                      {value:"fixed20",label:"Fixed 20 year"},
                      {value:"fixed15",label:"Fixed 15 year"},
                      {value:"fixed10",label:"Fixed 10 year"},
                      {value:"arm56",label:"ARM 5/6"},
                      {value:"arm76",label:"ARM 7/6"},
                      {value:"arm106",label:"ARM 10/6"}
                    ]}
                  />
                </div>
              
                <div className="d-flex">
                  <Button className="showfilterBtn mr-2" onClick={()=> setShowForm(true)}>
                    All filters
                  </Button>
                  {/* <Button className="createLoanBtn" onClick={()=> setShowCreateLoan(true)}>Create loan</Button> */}
                </div>
              </div>

          </div>

        </div>
      </div>
      {notmodified? (<div className={props.isMobile?"messageContainer-mobile":"messageContainer"}>
          <div style={{justifyContent:"center", marginTop:props.isMobile?"20px":"300px"}}>
            <img style={{marginLeft:"20px"}} src="/images/explorerates.png"></img>
            <div style={{textAlign:"center", fontSize:"16px", fontWeight:"500",padding:"0px 60px 0px 60px"}}>Modify borrower's information to explore rates & pricing </div>
            <div className="d-flex justify-content-center"><Button className="checkrates" style={{width:"150px",marginTop:"20px"}} onClick={()=> setShowForm(true)}>Search rates</Button></div>
          </div>
        </div>
      ):           
      loading ? (
        <Container id="spinnerContainer" className="pt-5">
          <Row >
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
          </Row>
        </Container>
      ) : 
      ( Object.keys(allRates).length === 0 && !noProducts ? 
        <div className="errorMessageWraper">
          <YellowWarning text="Unfortunately, this feature is currently unavailable. Please contact support@zeitro.com for assistance."/>
        </div>
       :
        (
          (!notmodified)&&noProducts ? 
            <div className="errorMessageWraper">
              <YellowWarning text="No qualified products."/>
            </div> 
          :
          <>
          
          <div className={props.isMobile?"resultContainer-mobile":"resultContainer"} style={{height:"100%"}}>
            <PricingResults
            resultlenderSet={resultlenderSet}
            resultIncomeTypes={resultIncomeTypes}
            connectedLendersMap={connectedLendersMap}
            selectedlenders={selectedLenders}
            selectedIncomeTypes={selectedIncomeTypes}
            allRates={allRates}
            viewportH = {viewportHeight}
            borrowerPaidCompensationAmount={borrowerPaidCompensationAmount}
            loanInfo={{loanAmount: getLoanAmount(), occupancy: occupancy, purpose: purpose, propertyType: propertyType, fico:fico, ltv:getLtv(),escrow:escrowWaiver}}
            isMobile={isMobile}
            targetPrice={targetPricePoint}
            setTargetPrice={setTargetPricePoint}
            targetOptions={calcOptions}
            loanterm={loanTerm}
            eligibilityView={eligibilityView}
            ineligibleProducts={ineligibleProducts}
            setShowForm={setShowForm}
            specialAdjustments={specialAdjustments}
          />
          </div>
          </>
        )
      )
      }

      <Modal
        className="ratequoterFormModal"
        show={showForm}
        onHide={()=>setShowForm(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form
          ref={form}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{height:"80%"}}
        >
          <Modal.Header style={{boxShadow:"0 2px 5px rgba(0,0,0,0.2)"}} closeButton>
            <Modal.Title>
              <div style={{fontSize:"20px",fontWeight:"500"}}>Search fields</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{overflowY:"auto"}}>
            {getFormBody()}
          </Modal.Body>
          <Modal.Footer style={{position:"relative",boxShadow:"0 -2px 5px rgba(0,0,0,0.2)"}}>
            <div className="d-flex align-items-center mr-3">
              {checkAccess([UR_Owner]) && <Checkbox rootClassName="showOtherLendersCheck" style={{display:"flex", alignItems:"center",whiteSpace:'nowrap', marginRight:"15px"}} checked={!showConnectedLendersOnly} onChange={(e)=> setShowConnectedLendersOnly(!e.target.checked)}>
                Show products from other lenders
                <Popover overlayClassName="showConnectedTip" content="Explore competitive rates from Zeitro's partner lenders. Connect directly to learn more and get started.">
                  <img src="/images/questionMark.png" style={{marginLeft:"5px",width:"15px",height:"15px"}}/>
                </Popover>
              </Checkbox>}
              <Button type="submit" size="sm" className="checkrates" style={{width:props.isMobile?"100%":"", marginBottom:props.isMobile?"10px":""}}>
                  Check rates
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>



    </div>
  );
};
export default withRouter(NonQMCalculator);
