import React from 'react';
import { Table, Button, Row, Col } from 'antd';
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import { commaize } from '../Common';
import {calcIncomeFromExtractedDocData} from '../DocumentVerification/Calculations';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({

    changeOccupationInfo: (val, who, verb) => {
        dispatch(act.ChangeOccupationInfo(val, who, verb))
    },
    updateIncome: (val, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(val, who, verb))
    },
});





export default function DocumentAnalysis(props) {
    const getData = (docType, borrowerStateData, extractedData) => {
        let fields = [];
        let borrowerData = [];
        let documentData = [];
        let updatingFunctions = [];
        switch (docType) {
            case "paystub":
    
                fields = [
                    "Employer",
                    "Monthly base income",
                    "Monthly commission income",
                    "Monthly bonus income",
                    "Monthly overtime income",
                ]
                borrowerData = [
                    borrowerStateData.occupation.employername,
                    "$"+commaize(borrowerStateData.occupation.income.base),
                    "$"+commaize(borrowerStateData.occupation.income.commissions),
                    "$"+commaize(borrowerStateData.occupation.income.bonuses),
                    "$"+commaize(borrowerStateData.occupation.income.overtime),
                ]
                updatingFunctions = [
                    val => props.changeOccupationInfo(val, "borrower", "employername"),
                    val => props.updateIncome(val, "borrower", "base"),
                    val => props.updateIncome(val, "borrower", "commissions"),
                    val => props.updateIncome(val, "borrower", "bonuses"),
                    val => props.updateIncome(val, "borrower", "overtime"),
                ]
                const {base, commissions, bonuses, overtime} = calcIncomeFromExtractedDocData(extractedData)
                documentData = [
                    "N/A",
                    base,
                    commissions,
                    bonuses,
                    overtime,
                ]
                break;
        
            default:
                // Handle other cases or default initialization
                break;
        }
        return { fields, borrowerData, documentData, updatingFunctions };
    }

    const { fields, borrowerData, documentData, updatingFunctions } = getData(props.docType, props.application.borrower, props.extractedData);

    return (
        <div className="d-flex flex-column mt-3" style={{flex:1}}>
            <div className='flex-grow-1 px-4' style={{overflowY:"scroll"}}>
                <div className='title-5'>Comparing borrower application information with document information</div>
                <Row style={{backgroundColor:"#F5F5F7", color:"#586570", fontWeight:500, padding:"14px 0",marginTop:12}}>
                    <Col span={8}></Col>
                    <Col span={8}>Borrower application review</Col>
                    <Col span={8}>Document analysis insights</Col>
                </Row>
                {fields.map((field, index) => {
                    let rowStyle = {padding:"12px 0", borderBottom:"1px solid #DBDCDE"}
                    let dataMatched = true
                    let bd = borrowerData[index];
                    let dd = documentData[index];
                    if (bd && dd !== "N/A" && bd !== dd) {
                        dataMatched = false;
                        rowStyle.backgroundColor = "#F8E8D3";
                    }
                    let update = () => {
                        let val = dd.replace(/,/g, "").replace("$", "");
                        updatingFunctions[index](val);
                    }

                    return <Row key={index} style={rowStyle}>
                        <Col span={8} className='pl-2'>{field}</Col>
                        <Col span={8}>
                            <div>{bd}</div>
                            {!dataMatched && <Button className='mt-1' type='boarder-round' onClick={()=>update() }>Auto-fill with document info</Button>}
                        </Col>
                        <Col span={8}>{documentData[index]}</Col>
                    </Row>
                    })}
            </div>
            <div className='border-top d-flex justify-content-between align-items-center'>
                <Button type="border" style={{height:44}} className='ml-3 mt-2' onClick={()=>props.onBack()}>Back</Button>
                <Button type="primary" style={{height:44}} className='mr-3 mt-2' onClick={()=>props.onCancel()}>Finish verification</Button>
            </div>
        </div>
    );
};


DocumentAnalysis = connect(mapStateToProps, mapDispatchToProps)(DocumentAnalysis);
