import { commaize } from "../Common"

export function calcIncomeFromExtractedDocData(extractedData) {
    let base = [];
    let commissions = [];
    let bonuses = [];
    let overtime = [];
    let frequencies = [];

    for (let data of extractedData) {
        if (data.Periods) {
            let freq = data.Periods.payFrequency;
            frequencies.push(freq);
            if (data.Earnings.Regular.length>0) {
                base.push(data.Earnings.Regular[0].ThisPeriodAmount.Value)
            }
            if (data.Earnings.Commission.length>0) {
                commissions.push(data.Earnings.Commission[0].ThisPeriodAmount.Value)
            }
            if (data.Earnings.Bonus.length>0) {
                bonuses.push(data.Earnings.Bonus[0].ThisPeriodAmount.Value)
            }
            if (data.Earnings.Overtime.length>0) {
                overtime.push(data.Earnings.Overtime[0].ThisPeriodAmount.Value)
            }
        }
    }

    let b = calculateIncome(base, frequencies)
    let c = calculateIncome(commissions, frequencies)
    let bo = calculateIncome(bonuses, frequencies)
    let ot = calculateIncome(overtime, frequencies)
    return { base: b, commissions: c, bonuses: bo, overtime: ot }
}

function calculateIncome(amounts, payFrequencies) {
    
    const allBiweekly = payFrequencies.every(frequency => frequency === "biweekly");
    if (allBiweekly && amounts.length === 2) {
        return "$" + commaize(Math.round(amounts[0] + amounts[1]))
    }
    return "N/A"
}