import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'
import smartlookClient from 'smartlook-client'
import {
    RightOutlined,
    LinkOutlined,
    LikeOutlined,
    DislikeOutlined,
    CopyOutlined,
    CloseOutlined,
    ShrinkOutlined,
    ArrowsAltOutlined,
    MinusOutlined,
} from '@ant-design/icons'
import {
    Drawer,
    theme,
    Space,
    Input,
    Button,
    message,
    Modal,
    Spin,
    Skeleton,
    Tooltip,
} from 'antd'
import { useEventEmitter } from 'ahooks'
import './Chat.css'
import PDFViewer from './PDFViewer'
import {
    ChatPromptExamples,
    DocPromptExamples,
    NewfiDocPromptExamples,
    ProcessAnswerText,
    FHADocPromptExamples,
    VADocPromptExamples,
} from './utils'
import ChatFeedback from './ChatFeedback'
import ChatHistory from './ChatHistory'
import MarkdownRenderer from './MarkdownRender'
import {
    PDF_GUIDELINES,
    CONTENT_TYPES,
    PDF_TYPE_NAME,
    PROMPT_EXAMPLES,
    PDF_CONTENT,
    PDF_API_URL,
    GUIDELINE_PDF_LIST,
    findPromptAnswer,
} from './constants'
import FullScreenModal from '../Common/components/FullHeightModal'
import { isEmpty } from 'lodash'

function Chat() {
    const { threadID } = useParams()
    const chatHistoryRef = useRef(null)

    const [messages, setMessages] = useState([])
    const [pdCFontent, setpdCFontent] = useState('Fannie Mae')
    const [showHistory, setShowHistory] = useState(true)
    const [newfiMessages, setNewfiMessages] = useState([])
    const [chatThreadID, setChatThreadID] = useState('')
    const [docThreadID, setDocThreadID] = useState('')
    const [newfiThreadID, setNewfiThreadID] = useState('')
    const [chatTitle, setChatTitle] = useState('')
    const [showWelcome, setShowWelcome] = useState(false)
    const [showDoc, setShowDoc] = useState(true)
    const [isBotThinking, setIsBotThinking] = useState(false)
    const [isReceivingAnswer, setIsReceivingAnswer] = useState(false)
    const [isBotAnswering, setIsBotAnswering] = useState(false)
    const [messageToSend, setMessageToSend] = useState('')
    const [currentDocName, setCurrentDocName] = useState(PDF_GUIDELINES.FANNIE_MAE)
    const [currentMessageID, setCurrentMessageID] = useState('')
    const [currentMessageText, setCurrentMessageText] = useState('')

    const [openChatHistory, setOpenChatHistory] = useState(false)

    const [loadingChat, setLoadingChat] = useState(false)
    const [showChat, setShowChat] = useState(true)
    const [showPdfContent, setShowPdfContent] = useState(true)

    const [uniqueQuoteLocations, setUniqueQuoteLocations] = useState([])

    const isMobile = window.innerWidth < 576

    const currentPdfType = useSelector((state) => state.common.content)

    const event$ = useEventEmitter()

    const createGreetingMessage = (content, prompts, handleClick) => ({
        text: (
            <div>
                <div>
                    Hello! I'm an AI assistant specialized in the {content}{' '}
                    lending guidelines. How can I help you today?
                </div>
                {!isEmpty(prompts) && (
                    <div style={{ marginTop: 5, color: '#586570' }}>
                        You can ask me about:{' '}
                    </div>
                )}
                <div className="greetingPrompt-container">
                    {prompts.map((prompt, index) => (
                        <div
                            key={`${content}-prompt-${index}`}
                            className="greetingPrompt"
                            onClick={() => handleClick(prompt.question, PDF_GUIDELINES.FANNIE_MAE)}
                        >
                            {prompt.question}
                        </div>
                    ))}
                </div>
            </div>
        ),
        sender: 'bot',
    })

    useEffect(() => {
        const greetingMessage = createGreetingMessage(
            PDF_TYPE_NAME[currentPdfType],
            PROMPT_EXAMPLES[currentPdfType] || [],
            handleSendDocMessage
        )
        setDocMessages([greetingMessage])
    }, [currentPdfType])

    const handleBackClick = () => {
        setShowWelcome(true)
        setShowDoc(false)
        setCurrentDocName('')
    }

    const jumpToPage = (pageNumber, uniqueLocations = []) => {
        // Find matching page coordinates if uniqueLocations is provided
        const pageCoordinates = uniqueLocations.find((location) => location[0] === pageNumber.toString())
        // Emit event to update highlight page number and coordinates
        event$.emit({
            pageNumber: parseInt(pageNumber) || 1,
            pageCoordinates: pageCoordinates ? pageCoordinates[1] : [],
        })
    }

    /**
     * Formulate bot answer
     */
    const formulateAnswer = useCallback(
        (
            answer,
            quoteLocations,
            pdfType = currentPdfType,
            isPrompt = false
        ) => {
            let text = (
                <MarkdownRenderer
                    answer={answer}
                    referenceFunc={(pageNumber) =>
                        jumpToPage(pageNumber, uniqueQuoteLocations)
                    }
                />
            )

            /**
             * Highlighting for Fannie Mae guidelines for now
             * NOTE: Quote Locations type in these scenarios is an array containing complicated data structures.
             */
            if (Array.isArray(quoteLocations) && quoteLocations.length > 0) {
                // group the page numbers
                const groupedLocations = quoteLocations.reduce(
                    (acc, [page, coords]) => {
                        if (!acc[page]) {
                            acc[page] = []
                        }
                        acc[page] = acc[page].concat(coords)
                        return acc
                    },
                    {}
                )

                // convert to the required format and deduplicate
                const uniqueLocations = Object.entries(groupedLocations).map(
                    ([page, coords]) => [page, coords]
                )
                setUniqueQuoteLocations(uniqueLocations)

                // sort the page numbers
                const sortedPages = Array.from(
                    new Set(uniqueLocations.map((location) => location[0]))
                ).sort((a, b) => a - b)

                text = (
                    <div>
                        {text}
                        <div className="mt-3">Check reference pages:</div>
                        <div className="d-flex mt-2" style={{ flexWrap: 'wrap' }}>
                            {sortedPages.map((val, index) => (
                                <Tooltip
                                    key={index}
                                    title={`Scroll to Page ${val}`}
                                    placement="top"
                                    overlayClassName="page-reference-tooltip"
                                >
                                    <div
                                        key={`page-reference-${index}`}
                                        className="pageReference"
                                        onClick={() => jumpToPage(val, uniqueLocations)}
                                    >
                                        {val}
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                )
            }

            return text
        },
        [jumpToPage, uniqueQuoteLocations]
    )

    const handleSendDocMessage = async (newMessage, docName) => {
        smartlookClient.track('guidelineGPT_GeneralInteraction', {
            label: 'GPT actions',
            category: 'Clicks',
        })

        setDocMessages((prevMessages) => [
            ...prevMessages,
            { text: newMessage, sender: 'user' },
        ])
        setIsBotThinking(true)
        setIsBotAnswering(false)
        setCurrentMessageID('')

        const promptAnswer = findPromptAnswer(newMessage, currentPdfType)
        if (promptAnswer !== null) {
            setTimeout(() => {
                const answer = formulateAnswer(
                    promptAnswer.Answer,
                    promptAnswer.QuoteLocations,
                    currentPdfType,
                    true
                )

                setIsBotAnswering(true)
                setDocMessages((prevMessages) => [
                    ...prevMessages,
                    { text: answer, sender: 'bot' },
                ])
                setIsBotThinking(false)
            }, 100)

            return
        }

        let lastMessageData = null
        let isFirstMessage = true

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

        setIsReceivingAnswer(true)

        try {
            const token = sessionStorage.getItem('ZeitroA')
            const url = PDF_API_URL[currentPdfType]

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
                body: JSON.stringify({
                    Message: newMessage,
                    ThreadID: docThreadID,
                    Doc: currentPdfType,
                }),
            })

            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`)

            const reader = response.body.getReader()
            const decoder = new TextDecoder()
            let buffer = ''

            const processBuffer = async () => {
                let newlineIndex = buffer.indexOf('\n')
                while (newlineIndex > -1) {
                    let line = buffer.slice(0, newlineIndex)
                    buffer = buffer.slice(newlineIndex + 1)
                    if (line.startsWith('data: ')) {
                        const data = JSON.parse(line.slice(6))
                        if (data.RunID === 'Not Mortgage') {
                            setIsBotAnswering(true)
                            setDocMessages((prevMessages) => [
                                ...prevMessages,
                                { text: data.Answer, sender: 'bot' },
                            ])
                            setIsBotThinking(false)
                            return
                        }

                        lastMessageData = data
                        const currentAnswer = formulateAnswer(data.Answer, data.QuoteLocations)
                        setCurrentMessageText(data.Answer)

                        await delay(50)

                        setIsBotAnswering(true)
                        setDocMessages((prevMessages) => {
                            if (isFirstMessage) {
                                isFirstMessage = false
                                return [
                                    ...prevMessages,
                                    { text: currentAnswer, sender: 'bot' },
                                ]
                            } else {
                                const newMessages = [...prevMessages]
                                newMessages[newMessages.length - 1] = {
                                    text: currentAnswer,
                                    sender: 'bot',
                                }
                                return newMessages
                            }
                        })

                        setIsBotThinking(false)
                    }
                    newlineIndex = buffer.indexOf('\n')
                }
            }

            while (true) {
                const { done, value } = await reader.read()
                if (done) break

                // Append new data to the buffer
                buffer += decoder.decode(value, { stream: true })

                await processBuffer()
            }

            // Process any remaining data in the buffer
            if (buffer.length > 0) {
                await processBuffer()
            }

            // After stream processing ends, update other states using the data from the last message
            if (lastMessageData) {
                // console.log( '🚀 ~ file: Chat.js:268 ~ handleSendDocMessage ~ Answer:', lastMessageData)
                setCurrentMessageID(lastMessageData.MessageID)
                if (lastMessageData.InitialChatTitle) {
                    setChatTitle(lastMessageData.InitialChatTitle)
                }
                if (docName === PDF_GUIDELINES.FANNIE_MAE) {
                    setDocThreadID(lastMessageData.ThreadID)
                } else {
                    setNewfiThreadID(lastMessageData.ThreadID)
                }

                // Refresh chat history after new thread is created
                if (chatHistoryRef.current) {
                    chatHistoryRef.current.refreshChatList()
                }
            }
        } catch (error) {
            console.error('Fetching error:', error)
            setIsBotAnswering(true)
            setDocMessages((prevMessages) => [
                ...prevMessages,
                {
                    text: 'Sorry something went wrong, please try later',
                    sender: 'bot',
                },
            ])
        } finally {
            setIsBotThinking(false)
            setIsReceivingAnswer(false)
        }
    }

    const greetingMessage = createGreetingMessage(
        pdCFontent,
        DocPromptExamples,
        handleSendDocMessage
    )

    const [docMessages, setDocMessages] = useState([greetingMessage])

    const handleSendMessage = async (newMessage) => {
        setMessages([...messages, { text: newMessage, sender: 'user' }])
        setIsBotThinking(true)

        const ans = findPromptAnswer(newMessage, currentPdfType)
        if (ans !== null) {
            setIsBotThinking(true)
            setTimeout(() => {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: ans, sender: 'bot' },
                ])
                setIsBotThinking(false)
            }, 3000)
            return
        }

        try {
            const token = sessionStorage.getItem('ZeitroA')
            const response = await fetch('/los/mortgagegpt/askwithoutdoc', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
                body: JSON.stringify({
                    Message: newMessage,
                    ThreadID: chatThreadID,
                }),
            })

            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`)

            const data = await response.json()
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: data.answer, sender: 'bot' },
            ])
            setChatThreadID(data.threadID)
        } catch (error) {
            console.error('Fetching error:', error)
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    text: 'Sorry something went wrong, please try later',
                    sender: 'bot',
                },
            ])
        } finally {
            setIsBotThinking(false)
        }
    }

    const handlePromptClick = (prompt) => {
        handleSendMessage(prompt)
        setShowWelcome(false)
    }

    const handleDocPromptClick = (prompt, docName) => {
        handleSendDocMessage(prompt, docName)
        setShowWelcome(false)
        setCurrentDocName(docName)
        setShowDoc(true)
    }

    const dispatch = useDispatch()

    const showChatHeader = () => {
        const pc = (
            <div
                className="d-flex"
                style={{
                    height: '60px',
                    borderBottom: showHistory
                        ? 'none'
                        : '1px rgb(211, 211, 211) solid',
                    backgroundImage: showHistory
                        ? 'linear-gradient(to right, transparent 16.65%, rgb(211, 211, 211) 16.65%)'
                        : 'none',
                    backgroundSize: showHistory ? '100% 1px' : '0',
                    backgroundPosition: 'bottom',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div
                    className={`d-flex align-items-center ${
                        showHistory ? 'justify-content-between' : ''
                    }`}
                    // Using inline expression for style assignment
                    style={{
                        width: showHistory
                            ? '16.65%'
                            : showPdf
                            ? '60px'
                            : '200px',
                        background: showHistory ? '#F6F6F6' : '#FFFFFF',
                        padding: '0  16px',
                        borderRight: showHistory
                            ? '1px rgb(211, 211, 211) solid'
                            : undefined,
                    }}
                >
                    <div
                        style={{ cursor: 'pointer', marginRight: '12px' }}
                        onClick={() => {
                            setShowHistory(!showHistory)
                        }}
                    >
                        <img src="/images/close.svg"></img>
                    </div>
                    {!showPdf && (
                        <Button
                            onClick={() => {
                                dispatch({ type: 'SHOW_PDF', data: true })
                                dispatch({ type: 'CHANGE_PDF', data: undefined })
                                setDocMessages([greetingMessage])
                                setChatTitle('')
                                setDocThreadID('')
                                setNewfiThreadID('')
                                setChatThreadID('')
                                setCurrentMessageID('')
                            }}
                            type="outline"
                            style={{
                                width: 95,
                                height: 42,
                                padding: '0',
                                border: 'none',
                                color: 'white',
                                backgroundColor: '#325CEB',
                                borderRadius: '8px',
                                fontSize: '16px',
                                fontWeight: '600',
                            }}
                        >
                            New chat
                        </Button>
                    )}
                </div>
                <div
                    className="d-flex align-items-center justify-content-between "
                    style={
                        showHistory
                            ? { width: '82%', marginLeft: 10 }
                            : { width: '90%', padding: 10 }
                    }
                >
                    <div
                        style={{
                            fontSize: '18px',
                            fontWeight: '500',
                            color: '#222222',
                            marginLeft: '10px',
                        }}
                    >
                        Guideline GPT
                    </div>
                </div>
            </div>
        )
        const Mobile = (
            <div
                className="d-flex align-items-center justify-content-between"
                style={{ height: '60px', padding: '0 20px' }}
            >
                <div
                    style={{
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#222222',
                        marginLeft: '10px',
                    }}
                >
                    Guideline GPT
                </div>
                <div className="d-flex align-items-center">
                    <div
                        style={{ cursor: 'pointer', marginRight: 10 }}
                        onClick={() => {
                            setShowHistory(!showHistory)
                        }}
                    >
                        <img src="/images/pdfMobileHistory.svg"></img>
                    </div>
                    {!showPdf && (
                        <Button
                            onClick={() => {
                                dispatch({ type: 'SHOW_PDF', data: true })
                                dispatch({ type: 'CHANGE_PDF', data: undefined })
                                setDocMessages([greetingMessage])
                                setChatTitle('')
                                setDocThreadID('')
                                setNewfiThreadID('')
                                setChatThreadID('')
                            }}
                            type="outline"
                            style={{
                                width: 95,
                                height: 42,
                                padding: '0',
                                border: 'none',
                                color: 'white',
                                backgroundColor: '#325CEB',
                                borderRadius: '8px',
                                fontSize: '16px',
                                fontWeight: '600',
                            }}
                        >
                            New chat
                        </Button>
                    )}
                </div>
            </div>
        )
        return isMobile ? Mobile : pc
    }

    const onChangeMessageToSend = (val) => {
        setMessageToSend(val)
    }

    const onSendMessageOnWelcomePage = () => {
        if (messageToSend.trim() === '') return

        if (currentDocName !== '') {
            handleSendDocMessage(messageToSend, currentDocName)
        } else {
            handleSendMessage(messageToSend)
        }

        setShowWelcome(false)
    }

    const loadChat = async (threadID, type) => {
        setLoadingChat(true)
        const token = sessionStorage.getItem('ZeitroA')
        try {
            const response = await fetch('/los/mortgagegpt/loadchat', {
                method: 'POST',
                body: JSON.stringify({ ThreadID: threadID }),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.error('There was an error fetching the chat history: ', response.status)
                setLoadingChat(false)
                return
            }

            const json = await response.json()
            setDocThreadID(json.thread.thread_id)
            if (json.thread.custom_title !== null) {
                setChatTitle(json.thread.custom_title)
            } else if (json.thread.initial_title !== null) {
                setChatTitle(json.thread.initial_title)
            }

            const chatHistory = json.chat_history.map((msg) => {
                // console.log('🚀 ~ file: Chat.js:664 ~ chatHistory ~ msg:', msg)
                if (msg.role === 'user') {
                    return {
                        text: msg.content,
                        sender: 'user',
                    }
                }
                // Format the answer from the bot
                return {
                    text: formulateAnswer(msg.content, msg.locations, type),
                    sender: 'bot',
                }
            })

            setDocMessages(chatHistory)
        } catch (err) {
            console.error('Fetch Error :-S', err)
        } finally {
            setLoadingChat(false)
        }
    }

    const processMoreDetails = (data) => {
        setCurrentMessageID(data.msg_id)
        setCurrentMessageText(data.answer)
        const answer = formulateAnswer(data.answer, data.QuoteLocations)
        const newMsg = { text: answer, sender: 'bot' }
        setDocMessages((prevMessages) => [...prevMessages, newMsg])
    }

    useEffect(() => {
        if (typeof threadID !== 'undefined') {
            loadChat(threadID)
            setChatThreadID(threadID)
        }
    }, [])

    const showPdf = useSelector((state) => {
        return state.common.showPdf
    })

    const getPdfType = (type) => {
        switch (type) {
            case '':
            case 'FannieMae':
                return CONTENT_TYPES.FANNIE_MAE
            case 'VA':
                return CONTENT_TYPES.VA
            case 'FHA':
                return CONTENT_TYPES.FHA
            case 'FreddieMac':
                return CONTENT_TYPES.FREDDIE
            case 'USDA':
                return CONTENT_TYPES.USDA
            default:
                return ''
        }
    }

    return (
        <div className="chat">
            {showWelcome ? (
                <WelcomePage
                    onPromptClick={handlePromptClick}
                    onDocPromptClick={handleDocPromptClick}
                    onChangeMessageToSend={onChangeMessageToSend}
                    onSendMessage={onSendMessageOnWelcomePage}
                    isBotThinking={isBotThinking}
                    docName={currentDocName}
                    setDocName={setCurrentDocName}
                    showDoc={showDoc}
                    setShowDoc={setShowDoc}
                />
            ) : (
                <div style={{ width: '100%' }}>
                    {showChatHeader()}
                    {showDoc ? (
                        <Row className="px-0">
                            {showHistory && (
                                <Col md={2} className="px-0">
                                    <div
                                        className="rightBorder"
                                        style={{
                                            background: '#F6F6F6',
                                            height: 'calc(100vh - 60px)',
                                            overflowX: 'auto',
                                        }}
                                    >
                                        <ChatHistory
                                            ref={chatHistoryRef}
                                            getHistoryList={(threadId, type) => {
                                                dispatch({ type: 'SHOW_PDF', data: false })
                                                dispatch({ type: 'CHANGE_PDF', data: getPdfType(type) })
                                                loadChat(threadId, type)
                                            }}
                                            currentThreadID={chatThreadID}
                                            openChatHistory={openChatHistory}
                                            setOpenChatHistory={setOpenChatHistory}
                                        />
                                    </div>
                                </Col>
                            )}
                            {showPdfContent && (
                                <Col md={showHistory ? 5 : 6} className="px-0">
                                    {showPdf ? (
                                        <PDFSelector />
                                    ) : (
                                        <PDFViewer file={PDF_CONTENT[currentPdfType]} event$={event$} />
                                    )}
                                </Col>
                            )}
                            <Col md={showHistory ? 5 : 6} className="px-0">
                                {isMobile && (
                                    <div
                                        className="d-flex justify-content-between align-items-center"
                                        style={{
                                            height: 58,
                                            padding: '0 20px',
                                            color: '#010101',
                                            fontSize: 16,
                                            fontWeight: 500,
                                            borderBottom: '1px solid #DBDCDE',
                                        }}
                                    >
                                        <div>
                                            {chatTitle == ''
                                                ? 'New chat'
                                                : chatTitle}
                                        </div>
                                        <div
                                            className="d-flex justify-content-between "
                                            style={{ color: '#6E6E70' }}
                                        >
                                            <div
                                                className="mobileC"
                                                onClick={() => {
                                                    setShowChat(!showChat)
                                                }}
                                                style={{
                                                    marginRight: 10,
                                                }}
                                            >
                                                <MinusOutlined />
                                            </div>
                                            <div
                                                className="mobileC"
                                                onClick={() => {
                                                    setShowPdfContent(!showPdfContent)
                                                }}
                                            >
                                                {showPdfContent ? (
                                                    <ShrinkOutlined />
                                                ) : (
                                                    <ArrowsAltOutlined />
                                                )}
                                            </div>
                                            {/*  */}
                                        </div>
                                    </div>
                                )}
                                {showChat && (
                                    <ChatApp>
                                        {!showPdf && (
                                            <MessageHeader
                                                chatTitle={chatTitle}
                                                setChatTitle={setChatTitle}
                                                threadID={docThreadID}
                                            />
                                        )}
                                        <MessageList
                                            loadingChat={loadingChat}
                                            messages={docMessages}
                                            processMoreDetails={processMoreDetails}
                                            threadID={docThreadID}
                                            isBotThinking={isBotThinking}
                                            isBotAnswering={isBotAnswering}
                                            currentMessageID={currentMessageID}
                                            currentMessageText={currentMessageText}
                                        />
                                        {!showPdf && (
                                            <ChatInput
                                                onSendMessage={showDoc ? handleSendDocMessage : handleSendMessage}
                                                isBotThinking={isBotThinking}
                                                isReceivingAnswer={isReceivingAnswer}
                                                docName={currentDocName}
                                            />
                                        )}
                                    </ChatApp>
                                )}
                            </Col>
                        </Row>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <ChatApp className="width70">
                                <MessageList
                                    messages={messages}
                                    processMoreDetails={processMoreDetails}
                                    threadID={threadID}
                                    isBotThinking={isBotThinking}
                                    isBotAnswering={isBotAnswering}
                                    currentMessageID={currentMessageID}
                                    currentMessageText={currentMessageText}
                                />
                                <ChatInput
                                    onSendMessage={handleSendMessage}
                                    style={{ marginBottom: 10 }}
                                    isBotThinking={isBotThinking}
                                    isReceivingAnswer={isReceivingAnswer}
                                    docName={currentDocName}
                                />
                            </ChatApp>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

function PDFSelector() {
    const dispatch = useDispatch()

    return (
        <div className="pdf-selector rightBorder">
            <div className="pdf-selector-content">
                <img
                    src="/images/welcome-guideline-gpt.svg"
                    alt="Welcome Guideline GPT"
                    style={{ width: 114, height: 69, marginBottom: '25px' }}
                />
                <div
                    style={{
                        color: '#222222',
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: '8px',
                    }}
                >
                    Welcome to Guideline GPT
                </div>

                <div style={{ color: '#6E6E70', fontSize: 16, fontWeight: 400, marginBottom: '12px' }}>
                    Please select a guideline to start a chat
                </div>
                <div className="pdf-list">
                    {GUIDELINE_PDF_LIST.map((item) => (
                        <div
                            key={item.id}
                            className="padList"
                            onClick={() => {
                                dispatch({ type: 'SHOW_PDF', data: false })
                                dispatch({ type: 'CHANGE_PDF', data: item.id })
                            }}
                        >
                            <img
                                src="/images/pdfIcon.svg"
                                alt="PDF Icon"
                                style={{ width: 24, height: 24, flexShrink: 0 }}
                            />
                            <span
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                    color: '#222222',
                                }}
                            >
                                {item.value}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

function WelcomePage({
    onPromptClick,
    onDocPromptClick,
    onChangeMessageToSend,
    onSendMessage,
    isBotThinking,
    docName,
    setDocName,
    showDoc,
    setShowDoc,
}) {
    const inputRef = useRef(null)

    useEffect(() => {
        // Auto-focus the input when the component mounts
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSendMessage()
        }
    }

    const handleDocSelection = (e) => {
        const value = e.target.value
        setDocName(
            value === '1'
                ? ''
                : value === '2'
                ? CONTENT_TYPES.FANNIE_MAE
                : CONTENT_TYPES.NEWFI_JUMBO
        )
        setShowDoc(value !== '1')
    }

    return (
        <div className="welcome-page px-4">
            <div className="title-1 mt-4">GuidelineGPT</div>
            <div className="title-5 mt-2">
                Get quick answers for all your mortgage questions
            </div>
            <div className="d-flex justify-content-center">
                <div className="chatprompts">
                    <img
                        src="/images/chatsign.png"
                        style={{ width: 52, height: 52 }}
                    />
                    <div style={{ fontWeight: 500 }}>
                        You can start the chat by asking:
                    </div>
                    <Row className="px-3 text-left mt-3">
                        {ChatPromptExamples.map((prompt, index) => (
                            <Col
                                md={6}
                                className="p-2 "
                                onClick={() => onPromptClick(prompt.question)}
                            >
                                <div className="prompt d-flex justify-space-between">
                                    <div className="mr-3" key={index}>
                                        {`"` + prompt.question + `"`}
                                    </div>
                                    <RightOutlined />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className="px-3 text-left mt-3">
                        {DocPromptExamples.map((prompt, index) => (
                            <Col
                                md={6}
                                className="p-2 "
                                onClick={() =>
                                    onDocPromptClick(
                                        prompt.question,
                                        CONTENT_TYPES.FANNIE_MAE
                                    )
                                }
                            >
                                <div className="prompt d-flex justify-space-between">
                                    <div>
                                        <div className="mr-3" key={index}>
                                            {`"` + prompt.question + `"`}
                                        </div>
                                        <div className="promptdoc">
                                            <img
                                                src="/images/pdf2.png"
                                                style={{
                                                    height: 22,
                                                    marginRight: 6,
                                                }}
                                            />
                                            Fannie Mae Conventional
                                            Guideline.pdf
                                        </div>
                                    </div>
                                    <RightOutlined />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className="px-3 text-left mt-3">
                        {NewfiDocPromptExamples.map((prompt, index) => (
                            <Col
                                md={6}
                                className="p-2 "
                                onClick={() =>
                                    onDocPromptClick(
                                        prompt.question,
                                        CONTENT_TYPES.NEWFI_JUMBO
                                    )
                                }
                            >
                                <div className="prompt d-flex justify-space-between">
                                    <div>
                                        <div className="mr-3" key={index}>
                                            {`"` + prompt.question + `"`}
                                        </div>
                                        <div className="promptdoc">
                                            <img
                                                src="/images/pdf2.png"
                                                style={{
                                                    height: 22,
                                                    marginRight: 6,
                                                }}
                                            />
                                            NewFi Jumbo Guideline.pdf
                                        </div>
                                    </div>
                                    <RightOutlined />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className="px-3 mt-3">
                        <Col className="p-2">
                            <div className="chat-input">
                                <input
                                    type="text"
                                    ref={inputRef}
                                    // value={inputValue}
                                    disabled={isBotThinking}
                                    onChange={(e) =>
                                        onChangeMessageToSend(e.target.value)
                                    }
                                    onKeyPress={handleKeyPress}
                                    placeholder="Ask any mortgage-related questions"
                                />
                                <select
                                    onChange={handleDocSelection}
                                    disabled={isBotThinking}
                                    className="welcomePageDropdown"
                                >
                                    <option value="1">
                                        General Mortgage Queries
                                    </option>
                                    <option value="2">
                                        Inquire Fannie Mae Guidelines
                                    </option>
                                    <option value="3">
                                        Consult Newfi Jumbo Guidelines
                                    </option>
                                </select>
                                <Button
                                    variant="sendmessage"
                                    onClick={() => onSendMessage()}
                                    disabled={isBotThinking}
                                >
                                    {isBotThinking ? 'Pending' : 'Send'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

function ChatApp({ children, className }) {
    return <div className={'chat-app ' + className}>{children}</div>
}

function MessageList({
    messages,
    processMoreDetails,
    threadID,
    isBotThinking,
    isBotAnswering,
    currentMessageID,
    currentMessageText,
    loadingChat,
}) {
    const messageContainerRef = useRef(null)
    const showFeedback = useMemo(
        () => currentMessageID && !isBotThinking,
        [currentMessageID, isBotThinking]
    )
    const showPdf = useSelector((state) => state.common.showPdf)

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop =
                messageContainerRef.current.scrollHeight
        }
    }

    useEffect(() => {
        // Scroll to bottom when the bot is not answering
        if (!isBotAnswering) {
            scrollToBottom()
        }
    }, [messages, isBotAnswering])

    const handleFeedbackChange = () => {
        // Use setTimeout to ensure scrolling after DOM update
        setTimeout(scrollToBottom, 100)
    }

    const renderLoadingSkeletons = () => (
        <div className="message-list pt-3">
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
        </div>
    )

    if (loadingChat) return renderLoadingSkeletons()

    return (
        <>
            {!showPdf ? (
                <div className="message-list" ref={messageContainerRef}>
                    {messages.map(({ text, sender }, index) => (
                        <Message key={index} text={text} sender={sender} />
                    ))}
                    {isBotThinking && <Message text="..." sender="bot" />}
                    {showFeedback && (
                        <ChatFeedback
                            threadID={threadID}
                            processMoreDetails={processMoreDetails}
                            chatAnswer={currentMessageText}
                            messageID={currentMessageID}
                            onFeedbackChange={handleFeedbackChange}
                        />
                    )}
                </div>
            ) : (
                <div
                    className="bot-message message"
                    style={{ display: 'block', marginTop: 28, marginLeft: 17 }}
                >
                    <div className="d-flex">
                        <img
                            src="/images/chatbot.png"
                            alt="Chatbot"
                            className="avatar"
                        />
                        <div
                            style={{
                                color: '#222222',
                                fontSize: 16,
                                fontWeight: 600,
                            }}
                        >
                            Welcome to Guideline GPT.
                        </div>
                    </div>
                    <div
                        style={{
                            marginLeft: 30,
                            marginTop: '12px',
                            color: '#62707C',
                            fontSize: 16,
                            fontWeight: 400,
                        }}
                    >
                        Select a guideline on the left to start a chat.
                    </div>
                </div>
            )}
        </>
    )
}

function MessageHeader({ chatTitle, setChatTitle, threadID }) {
    const [isEditing, setIsEditing] = useState(false)
    const [editableTitle, setEditableTitle] = useState(chatTitle)
    const [showShareModal, setShowShareModal] = useState(false)
    const [loadingShare, setLoadingShare] = useState(false)
    const [loadingSent, setLoadingSent] = useState(false)
    const [chatHistory, setChatHistory] = useState([])
    const [emailToShare, setEmailToShare] = useState('')

    const handleDoubleClick = () => {
        setIsEditing(true)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsEditing(false)
            setChatTitle(editableTitle)
            customizeChatTitle(threadID, editableTitle)
        }
    }

    const handleChange = (event) => {
        setEditableTitle(event.target.value)
    }

    const customizeChatTitle = async (threadID, title) => {
        let token = sessionStorage.getItem('ZeitroA')

        try {
            const response = await fetch('/los/mortgagegpt/customchattitle', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
                body: JSON.stringify({
                    ThreadID: threadID,
                    ChatTitle: title,
                }),
            })

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
            setChatTitle(title)
        } catch (error) {
            console.error('Fetch Error:', error)
        }
    }

    const handleShare = async () => {
        setLoadingShare(true)

        let token = sessionStorage.getItem('ZeitroA')
        let body = { ThreadID: threadID }

        try {
            const response = await fetch('/los/mortgagegpt/sharechat', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.error(`Request failed. Status code: ${response.status}`)
                setLoadingShare(false)
                alert('Something went wrong, please try again later.')
                return
            }

            const js = await response.json()
            setChatHistory(js.chat_history)
            setLoadingShare(false)
            setShowShareModal(true)
        } catch (err) {
            setLoadingShare(false) // Reset loading state
            console.error('Fetch Error:', err)
        }
    }

    if (threadID === '' || threadID === null) {
        return ''
    }

    const displayChatHistory = () => {
        return (
            <div className="chatHistoryWrap">
                <div className="mb-2">
                    <div className="title-5">Email address</div>
                    <Input
                        placeholder="Enter email address"
                        onChange={(e) => setEmailToShare(e.target.value)}
                    />
                </div>
                <div className="title-5 mb-2">Preview</div>
                <div style={{ flex: 1, overflow: 'auto', marginBottom: '24px' }}>
                    <div className="chatHistoryPreview">
                        {chatHistory.map((msg, index) => (
                            <div
                                key={index}
                                className={msg.role === 'user' ? 'chatHistoryUser' : 'chatHistoryBot'}
                            >
                                <img
                                    src={
                                        msg.role === 'user'
                                            ? '/images/chatbotuseravatar.png'
                                            : '/images/chatbot.png'
                                    }
                                    style={{ width: 30, height: 30, marginRight: 10 }}
                                    alt={msg.role === 'user' ? 'User Avatar' : 'Bot Avatar'}
                                />
                                <div>
                                    <div className="bold">
                                        {msg.role === 'user' ? 'You' : 'GuidelineGPT'}
                                    </div>
                                    {msg.role === 'user' ? (
                                        <div>{msg.content}</div>
                                    ) : (
                                        <MarkdownRenderer answer={msg.content} referenceFunc={() => {}} />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="chatHistoryFooter">
                    <a className="aAsLink mr-3" onClick={() => copyLinkToClipboard()}>
                        <LinkOutlined /> Copy link
                    </a>
                    <Button type="primary" loading={loadingSent} onClick={() => sendShare()}>
                        Send
                    </Button>
                </div>
            </div>
        )
    }

    const copyLinkToClipboard = async () => {
        try {
            const url = window.location.origin + '/mortgagegpt/sharechat/' + threadID
            await navigator.clipboard.writeText(url)
            alert('Text copied to clipboard')
        } catch (err) {
            console.error('Failed to copy: ', err)
        }
    }

    const hideChat = async () => {
        let token = sessionStorage.getItem('ZeitroA')
        let body = { ThreadID: threadID }
        try {
            const response = await fetch('/los/mortgagegpt/hidechat', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })
            if (response.status !== 200) {
                console.error(
                    'Looks like there was a problem. Status Code: ' +
                        response.status
                )
                return
            }
        } catch (err) {
            console.error('Fetch Error :-S', err)
        }
    }

    const handleHide = () => {
        hideChat()
        window.location.href = '/app/mortgagegpt'
    }

    const sendShare = async () => {
        setLoadingSent(true)
        let token = sessionStorage.getItem('ZeitroA')
        let body = { Email: emailToShare, ThreadID: threadID }

        try {
            const response = await fetch('/los/mortgagegpt/sendshareemail', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.error(
                    'Looks like there was a problem. Status Code: ' +
                        response.status
                )
                setLoadingSent(false)
                return
            }

            setLoadingSent(false)
            setShowShareModal(false)
            alert('Email sent successfully')
        } catch (err) {
            setLoadingSent(false)
            console.error('Fetch Error :-S', err)
        }
    }

    return (
        <div className="message-header bottomBorder d-flex justify-content-between">
            <div className="message-title-container d-flex">
                {isEditing ? (
                    <input
                        className="message-title-input flex-grow-1"
                        type="text"
                        value={editableTitle}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                    />
                ) : (
                    <div className="d-flex flex-grow-1 align-items-center justify-content-between">
                        <div className="message-title ">{chatTitle}</div>
                        <img
                            src="/images/edit.png"
                            style={{ width: 32, cursor: 'pointer' }}
                            onClick={handleDoubleClick}
                        />
                    </div>
                )}
            </div>

            <div className="icon-container d-flex">
                {loadingShare ? (
                    <Spin className="mr-2" />
                ) : (
                    <img
                        src="/images/share.png"
                        style={{
                            width: 32,
                            marginRight: 10,
                            cursor: 'pointer',
                        }}
                        onClick={() => handleShare()}
                    />
                )}
                <img
                    src="/images/delete.png"
                    style={{ width: 32, cursor: 'pointer' }}
                    onClick={() => handleHide()}
                />
            </div>

            <FullScreenModal
                title="Share link of chat"
                open={showShareModal}
                onClose={() => setShowShareModal(false)}
                className="share-chat"
                width={800}
                style={{ top: 0 }}
                content={displayChatHistory()}
                footer={null}
            />
        </div>
    )
}

function Message({ text, sender }) {
    const avatar =
        sender === 'bot' ? (
            <img src="/images/chatbot.png" alt="Chatbot" className="avatar" />
        ) : (
            <img
                src="/images/chatbotuseravatar.png"
                alt="Chatbot"
                className="avatar"
            />
        )
    const name = sender === 'bot' ? 'GuidelineGPT' : 'You'

    return (
        <div className={`${sender}-message`}>
            {sender === 'bot' ? avatar : <></>}
            <div
                className={`text-bubble ${
                    text === '...' ? 'typing-indicator' : ''
                }`}
            >
                {text === '...' ? (
                    <>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                    </>
                ) : (
                    <div style={{}}>{text}</div>
                )}
            </div>
        </div>
    )
}

function ChatInput({ onSendMessage, style, isBotThinking, isReceivingAnswer, docName }) {
    const [inputValue, setInputValue] = useState('')
    const textareaRef = useRef(null)

    const isButtonActive = useMemo(() => {
        return inputValue.trim() && !isReceivingAnswer
    }, [inputValue, isReceivingAnswer])

    useEffect(() => {
        textareaRef.current.focus()
    }, [])

    useEffect(() => {
        adjustTextareaHeight()
    }, [inputValue])

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current
        if (textarea) {
            textarea.style.height = 'auto'
            const scrollHeight = textarea.scrollHeight
            const maxHeight = 8 * 26

            // When the content exceeds 7 lines, add the scrollable class
            if (scrollHeight <= maxHeight) {
                textarea.style.height = `${scrollHeight}px`
                textarea.classList.remove('scrollable')
            } else {
                textarea.style.height = `${maxHeight}px`
                textarea.classList.add('scrollable')
            }
        }
    }

    const handleSend = () => {
        if (inputValue.trim() && !isReceivingAnswer) {
            smartlookClient.track('guidelineGPT_GeneralInteraction', {
                label: 'GPT actions',
                category: 'Clicks',
            })
            onSendMessage(inputValue.trim(), docName)
            setInputValue('')
            textareaRef.current.style.height = 'auto'
        }
    }

    const handleKeyPress = (e) => {
        if (e.key !== 'Enter') return

        e.preventDefault()

        if (isReceivingAnswer) return

        if (!e.shiftKey) {
            handleSend()
        }
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
        adjustTextareaHeight()
    }

    return (
        <div className='chat-input-wrapper' style={style}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className='ask-ai-button'>
                        <img src='/images/light.svg' alt="Ask AI" />
                    </div>
                    <div className='input-container'>
                        <textarea
                            ref={textareaRef}
                            value={inputValue}
                            onChange={handleInputChange}
                            onFocus={(e) => {
                                smartlookClient.track('guidelineGPT_Typing', {
                                    label: 'Chat typing',
                                    category: 'Focus',
                                })
                            }}
                            onKeyPress={handleKeyPress}
                            placeholder='Ask AI...'
                            className='chat-input'
                            rows={1}
                        />
                        <div
                            className={`send-button ${isButtonActive ? 'active' : ''}`}
                            onClick={handleSend}
                            style={{ pointerEvents: isBotThinking || isReceivingAnswer ? 'none' : 'auto' }}
                        >
                            <img
                                src={isButtonActive ? '/images/sendBlue.svg' : '/images/send.svg'}
                                alt='Send'
                            />
                        </div>
                    </div>
                </div>
                <div style={{
                        textAlign: 'center',
                        marginTop: '4px',
                        color: '#6E6E70',
                        fontSize: '12px',
                        fontWeight: 400,
                    }}
                >
                    AI may make mistakes. Check responses.
                </div>
            </div>
        </div>
    )
}

export default withRouter(Chat)
