import React, { Component } from "react";
import Menu from "./Menu";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

  
export default class Validation extends Component {

    render() {
        return (
            <div>
                <Menu />
                <div className="mx-5 mt-5 mb-3 px-5 text-center">
                <h2>
                    Thank you for creating your account! 
                </h2>
                <div className=" bluish nav-link text-center mt-4  mb-5 pt-5 pb-5" >
                    In order to maintain your privacy and security we've generated and sent  validation email to your account. 
                    <p/>
                    Please click on the link in this email, and it will take you to the login screen!
                </div>
                <div className="mt-5">
                    {/* <a target="_top" href="/revalidate">Resend account validation email</a> */}
                </div>
                <div className="mt-5 pt-5 pb-5 mb-5">&nbsp;</div>
            </div>
            </div>
        )
    }
}
