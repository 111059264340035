import React, { useState } from 'react';
import { Modal, Button, Select, Row, Col,  } from 'antd';
import * as com from '../Common'
const BulkChangeModal = ({ show, type, options, handleSubmit, handleClose }) => {
const [slOption, setSlOption] = useState("");


  const handleChange = (value) => {
    setSlOption(value)
  }
  const selectOption = () => {
    if (type === 'status') {
        return Object.entries(com.loanStatusMap).map(([key, val]) => ({
          value: key,
          label: val
        }))
    } else {
        return options.map(t => ({
          value: t.id,
          label: `${t.firstname} ${t.lastname}`
        }))
    }
  }
  const confirmModal = () => {
    handleSubmit(slOption)
  }
  const closeModal = () => {
    setSlOption("")
    handleClose()
  }
  const titleStyle = { fontSize: 12, color: "#6E6E70", fontWeight: 500, marginBottom: 8 }
  return (
    <Modal
      title={type === 'status' ? 'Change selected status' : 'Assign loan officer to the borrower'}
      open={show}
      onCancel={closeModal}
      onOk={confirmModal}
      okText="Submit"
      destroyOnClose
      okButtonProps={{
        disabled: slOption === "",
      }}
    >
      <div className="pt-3 pb-3">

        <Row>
          <Col span={24} className="px-0">
            <div style={titleStyle}>{type === 'status' ? 'Status' : 'Loan Officer'}</div>
            <div>
                <Select
                    placeholder={type === 'status' ? 'Select status' : 'Select Loan Officer'}
                    style={{
                        width: '100%',
                    }}
                    onChange={handleChange}
                    options={selectOption()}
                />
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default BulkChangeModal;