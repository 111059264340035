import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import NumericalInput, { PennyInput } from '../NumericalInput'
import InfoModal from '../CommonUIComponents/InfoModal'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import state from '../Store/index'
import * as com from "../Common.js"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import { getassets } from '../Interview/Finances'

const counties = com.counties

const mapStateToProps = (state) => {
    return {
        transaction: state.application.transaction,
        product: state.application.product,
        application: state.application,
        selection: state.application.selection,
        borrower: state.borrower,
    }
}


const mapDispatchToProps = (dispatch) => ({
    updateProduct: (event, verb) => {
        dispatch(act.UpdateProduct(event.target.value, verb))
    },
    updateProductValue: (value, verb) => {
        dispatch(act.UpdateProduct(value, verb))
    },
    updateTransaction: (input, verb) => {
        dispatch(act.UpdateTransaction(input, verb))
    },
    updateSelectedLoanField: (t, verb) => {
        dispatch(act.UpdateSelectedLoanField(t, verb))
    },
    updateSelectedLoanProductField: (t, index, verb) => {
        dispatch(act.UpdateSelectedLoanProductField(t, index, verb))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event.target.value))
    },
    updateSelectedLoanAttribute: (t, verb) => {
        dispatch(act.UpdateSelectedLoanAttribute(t, verb))
    },
});


export default class Loan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ltv: 0,
            cltv: 0,
            hcltv: 0,
            lockDetails: {
                Extensions: ""
            },
            showModal: false,
            infoModal: false,
            currentExtensionDateTime: "",
            currentExtensionDuration: "",
            modifyingExtensionDateTime: "",
            modifyingExtensionDuration: "",
            disableLock: false,
            dtiFinalData: [],
        }
        // calculate the set of tasks
        console.log("Loan: state" + state)
        this.yearstohold = 7

    }
    calculateAssets = getassets.bind(this)

    componentDidMount = () => {
        this.getDTI()
        this.getLockInformation()
        this.checkIfLockIsPossible()
    }

    getLockInformation = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/getlockinformation', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
                "X-CustomerID": this.props.borrower.id
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log("Bad response, should not happen")
                    return;
                }
                response.json().then(js => {
                    let lockDetails = {}
                    lockDetails.Extensions = js.Extensions === "" ? js.Extensions : JSON.parse(js.Extensions)
                    lockDetails.LoNotes = js.LoNotes
                    lockDetails.LockMethod = js.LockMethod
                    lockDetails.LockRequested = js.LockRequested
                    this.setState({ lockDetails: lockDetails })
                })
            })
    }

    // /los/getPreDTI/

    getDTI = () => {
        let token = sessionStorage.getItem("ZeitroA")
        this.setState({ loading: true })
        fetch('/los/getDTI/' + this.props.borrower.loan_id, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
            },

        }).then(
            response => {
                if (response.status !== 200) {
                    alert("Bad response, should not happen")
                    this.setState({
                        loading: false
                    })
                    return;
                }
                response.json().then(pr => {
                    this.setState({ loading: false })

                    if (typeof pr["Error"] !== "undefined") {
                        alert("getDTI: " + pr.Error)
                    } else {
                        this.setState({
                            ltv: pr.ltv, cltv: pr.cltv, hcltv: pr.hcltv, fees: pr.fees,
                            fico: pr.fico, prepaid_interest_days: pr['prepaid_interest_days'],
                            dtiFinalData: pr.dtiFinalData
                        })
                    }
                })
            }

        ).catch((err) => {
            this.setState({ loading: false })
            alert("Network error")
        });
    }

    getClosingCosts = (card) => {
        let cc = card.closing_cost
        if (cc < 0)
            cc = 0
        return parseInt(cc)
    }

    lock = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let dat = new Date()
        fetch('/los/lock', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify({ LockDate: dat.toString(), CustomerID: this.props.borrower.id })
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status)
                } else {
                    // Examine the text in the response
                    response.json().then(js => {
                        if (js.Status !== "OK") {
                            alert("Lock: " + js.Text)
                        } else {
                            this.props.updateSelectedLoanAttribute("requested", "lockstatus")
                            this.getLockInformation()
                        }
                    });
                }
            }
        ).catch((err) => {
            alert('Fetch Error :', err);
        });
    }

    lockMethodChangeHandler = e => {
        e.persist();
        this.setState(prev => (
            { lockDetails: { ...prev.lockDetails, LockMethod: e.target.value } }
        ), () => this.updateLockDetails())
    }

    updateLockDetails = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = {}
        body.LoNotes = this.state.lockDetails.LoNotes
        body.Extensions = JSON.stringify(this.state.lockDetails.Extensions)
        body.LockMethod = this.state.lockDetails.LockMethod
        fetch('/los/updatelockinformation', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
                "X-CustomerID": this.props.borrower.id
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log("Bad response, should not happen")
                    return;
                }
            })
    }

    commentChangeHandler = (e) => {
        e.persist();
        this.setState(prev => (
            { lockDetails: { ...prev.lockDetails, LoNotes: e.target.value } }
        ))
    }

    openModal = () => {
        this.setState({ showModal: true })
    }

    closeModal = () => {
        this.setState({ showModal: false, currentExtensionDateTime: "", currentExtensionDuration: "", modifyingExtensionDateTime: "", modifyingExtensionDuration: "" })
    }

    modalSubmit = () => {
        const extension = {
            ExtensionDateTime: this.state.currentExtensionDateTime,
            ExtensionDuration: this.state.currentExtensionDuration
        }

        let newExtensions

        if (this.state.lockDetails.Extensions === "") {
            newExtensions = [extension]
        } else {
            let index = this.state.lockDetails.Extensions.findIndex(ex => ex.ExtensionDateTime === this.state.modifyingExtensionDateTime && ex.ExtensionDuration === this.state.modifyingExtensionDuration)
            if (index !== -1) {
                newExtensions = [...this.state.lockDetails.Extensions]
                newExtensions.splice(index, 1, extension)
            } else {
                newExtensions = [...this.state.lockDetails.Extensions, extension]
            }
        }

        this.setState(prev => (
            { lockDetails: { ...prev.lockDetails, Extensions: newExtensions } }
        ), () => this.updateLockDetails())
        this.closeModal()
    }

    extensionDateModificationHandler = e => {
        this.setState({ currentExtensionDateTime: e.target.value })
    }

    extensionDurationModificationHandler = e => {
        this.setState({ currentExtensionDuration: e.target.value })
    }

    closeInfoModal = () => {
        this.setState({ infoModal: false, modifyingExtensionDateTime: "", modifyingExtensionDuration: "" })
    }

    deleteExtension = () => {
        let index = this.state.lockDetails.Extensions.findIndex(ex => ex.ExtensionDateTime === this.state.modifyingExtensionDateTime && ex.ExtensionDuration === this.state.modifyingExtensionDuration)
        let tmp = [...this.state.lockDetails.Extensions]
        tmp.splice(index, 1)

        this.setState(prev => (
            { lockDetails: { ...prev.lockDetails, Extensions: tmp } }
        ), () => this.updateLockDetails())
        this.closeInfoModal()
    }

    checkIfLockIsPossible = () => {
        // check for 6 pieces
        let result
        result = result && this.props.borrower.fullname !== "" //(1) borrower's name
        result = result && (this.props.application.borrower.income.hasdividends || this.props.application.borrower.income.netrental !== "0" || this.props.application.borrower.income.otherincome.length !== 0 || this.props.application.borrower.occupation.income.base) //(2) income
        result = result && this.props.application.borrower.socialsecurity !== '' // (3) unique identifier
        result = result && this.props.application.property.address !== '' // (4) property address
        result = result && this.props.application.property.appraisal !== ''// (5) an estimate of the value of the property
        result = result && this.props.application.selection.selectedloan.loansize !== 0 // (6) the mortgage loan amount sought
        this.setState({ disableLock: result })
    }
    displayTabs = (dtiFinalDataI, i) => {
        let showAus = (pr, index) => {

            switch (pr.toUpperCase()) {
                case 'DU':
                    return "Fannie Mae"
                case 'LP':
                    return "Freddie Mac"
                case 'MANUAL':
                    return "Manual Underwriting"

                case 'TOTAL':
                    return "FHA"
                default:
                    return `Unknown aus type ${pr}. Please let the Engineering know!`
            }
        }
        let glue = (out) => {
            return out.join(", ") + " guidelines"
        }
        let out = []

        let auss = []
        for (let j = 0; j < dtiFinalDataI.details.length; j++) {
            auss.push(<div>
                <div className="p-2 d-flex" >

                    {
                        glue(dtiFinalDataI.details[0].aus_engines.map(showAus))
                    }
                </div>
                <Row className=" text-center mt-0 mb-0 mx-0">
                    <Col className="border-bottom border-top loancard py-2" >
                        <b>LTV:</b> <br />{this.state.ltv.toFixed(1)}
                    </Col>
                    <Col className="border-bottom border-top loancard py-2" >
                        <b>CLTV:</b> <br />{this.state.cltv.toFixed(1)}
                    </Col>
                    <Col className="border-bottom border-top loancard py-2" >
                        <b>HCLTV:</b> <br />{this.state.hcltv.toFixed(1)}
                    </Col>
                    <Col className="border-bottom border-top loancard py-2" >
                        <b>DTI:</b> <br />{(100 * dtiFinalDataI.details[0].monthly_payment / dtiFinalDataI.details[0].monthly_income).toFixed(1)}
                    </Col>
                    <Col className="border-bottom border-top loancard py-2" >
                        <b>Monthly income:</b> <br />${com.commaize(dtiFinalDataI.details[0].monthly_income)}
                    </Col>
                    <Col className="border-bottom border-top loancard py-2" >
                        <b>Monthly expenses:</b> <br />${com.commaize(dtiFinalDataI.details[0].monthly_payment)}
                    </Col>
                    <Col className="border-bottom border-top loancard py-2" >
                        <b>Gross Assets:</b> <br />${com.commaize(this.calculateAssets())}
                    </Col>
                </Row>
            </div>)
        }
        let line = <Tab eventKey={i} title={dtiFinalDataI.lender.toString() + ", " + dtiFinalDataI.loanProduct} >
            {auss}

        </Tab>
        out.push(line)

        return out
    }
    displaySelectedCard = () => {
        let card = this.props.selection.selectedloan

        if (this.props.selection.selectedloan.hash === "" ||
            card.matchingproducts.length === 0) {
            return <div>No product selected yet</div>
        }

        let getType = () => {

            if (card.amortization === "fixed")
                return "Fixed rate"
            return "ARM " + card.Arm.fixedperiod + "/1"
        }
        let loansize = parseInt(card.loansize)
        let interest10 = com.getClosedInterestPaid(0.01 * card.base_rate, loansize, card.Term, this.yearstohold)
        let interesttotal = com.getClosedInterestPaid(0.01 * card.base_rate, loansize, card.Term, card.Term)
        let purposeOfLoan = () => {
            switch (this.props.application.property.purpose) {
                case "purchase": {
                    return "Purchase"
                }
                case "refinance": {
                    return "Refinance to improve rate or term"
                }
                case "cashoutrefinance": {
                    return "Cash out refinance"
                }
                default: return "Unknown"
            }
        }
        let getLoanSize = () => {
            return card.loansize

        }

        let getPaydown = () => {
            switch (this.props.application.property.purpose) {
                case "purchase":
                    return <div> Downpayment:<div style={{ marginTop: '12px' }}> ${com.commaizeFloat(this.props.application.selection.selectedloan.downpayment.toFixed(2))} </div></div>

                case "refinance":
                    return <div> Cash to close:<div style={{ marginTop: '12px' }}> ${com.commaizeFloat(this.props.application.selection.selectedloan.paydown.toFixed(2))} </div></div>

                case "cashoutrefinance":
                    return <div> Cash out:<div style={{ marginTop: '12px' }}> ${com.commaizeFloat(this.props.application.selection.selectedloan.cashout.toFixed(2))} </div></div>
                default:
                    alert("should not see this!")
                    return 0
            }
        }
        let showAus = (pr, index) => {

            switch (pr.toUpperCase()) {
                case 'DU':
                    return <div key={pr + index}>
                        Fannie Mae
                    </div>
                case 'LP':
                    return <div key={pr + index}>
                        Freddie Mac
                    </div>
                case 'MANUAL':
                    return <div key={pr + index}>
                        Manual underwriting
                    </div>
                case 'TOTAL key={pr+index}':
                    return <div>
                        FHA
                    </div>
                case 'TOTAL':
                    return <div>
                        FHA
                    </div>
                default:
                    return <div key={pr + index}>
                        Unknown aus type {pr}. Please let the Engineering know!
                    </div>
            }
        }
        let getAppraisal = () => {
            return parseFloat(this.props.application.property.appraisal)
        }
        let getCurrentProgram = () => {
            switch (this.props.application.property.refinance.currentprogram) {
                case "fixed": return "Fixed rate"
                case "arm": return "ARM"
                case "baloon": return "Baloon"
                case "heloc": return "HELOC"
                case "negativeamortization": return "Negative Amortization"
                case "interestonly": return "Interest Only"
                case "noloan": return "No current loan"
                default: return "No current loan specified"
            }
        }
        let getMaxLoanSize = () => {
            if (undefined === counties[this.props.application.property.county.toUpperCase()]) {
                return "Unknown for " + this.props.application.property.county
            }
            return counties[this.props.application.property.county.toUpperCase()][parseInt(this.props.application.property.units) - 1]
        }
        let changeName = e => {
            this.props.updateSelectedLoanProductField(e.target.value, 0, "Lender")

        }
        let changeProduct = e => {
            this.props.updateSelectedLoanProductField(e.target.value, 0, "Name")
        }
        let changeAmount = e => {
            this.props.updateSelectedLoanField(e.target.value, "loansize")

        }
        let changeAppraisal = e => {
            this.props.changeMainPropertyAppraisal(e)

        }
        let changeRate = e => {
            this.props.updateSelectedLoanField(e.target.value, "base_rate")
        }
        let changeMonthlyPaymeny = e => {
            this.props.updateSelectedLoanField(e.target.value, "monthly_payment")
        }
        let changeClosingCosts = e => {
            this.props.updateSelectedLoanField(e.target.value, "closing_cost")
        }
        let changePoints = e => {
            this.props.updateSelectedLoanField(e.target.value, "points")
        }
        let changeLenderCredit = e => {
            this.props.updateSelectedLoanField(e.target.value, "lender_credit")

        }
        let changeLenderFee = e => {
            this.props.updateSelectedLoanField(e.target.value, "lender_fee")
        }
        let changePayout = e => {
            this.props.updateSelectedLoanField(e.target.value, "payout")
        }
        let getCurrentRate = () => {
            let rate = 0
            let loans = this.props.application.assetsandliabilities.loans
            for (let i = 0; i < loans.length; i++) {
                let cc = loans[i]

                if (cc.forsubjectproperty && this.props.application.property.purpose !== "purchase") {
                    rate = cc.rate
                }

            }
            return rate
        }
        let getProperty = () => {
            if (this.props.application.property.purpose === "purchase" &&
                false === this.props.application.property.hascontract)
                return "TBD"
            return this.props.application.property.address
        }
        let defaultPayout = () => {
            if (card.payout == null || card.payout == "") {
                card.payout = card.loansize
                this.props.updateSelectedLoanField(card.loansize, "payout")
            }

            return card.payout
        }
        return <div className="mb-4 mt-2">
            <h4>Please only edit after the loan has been submitted</h4>
            <Container key={card.hash} className=" drop-shadow w-100 p-3 bluishbackground">
                {/*}
                <Row className="bluishbackground text-center mt-3 mb-3 mx-0">
                    <Col style={{ fontWeight: '500', fontSize: '1.5em' }} className="loancard pt-2 ">

            
                        <Form.Group controlId="mailingCity" className="text-left" >
                                            <Form.Label className="text-left" >Lender:</Form.Label>
                                            <Form.Control isValid={false} size="sm"
                                                required
                                                name="mailingcity"
                                                type="text"
                                                autoComplete="locality"
                                                value={card.matchingproducts[0].Lender}
                                                onChange={changeName}

                                                pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide city.
                </Form.Control.Feedback>
                                        </Form.Group>

                 
                    </Col>
                    <Col style={{ fontWeight: '500', fontSize: '1.5em' }} className="loancard pt-2 ">
                        <Form.Group controlId="mailingCity" className="text-left" >
                                            <Form.Label className="text-left" >Product:</Form.Label>
                                            <Form.Control isValid={false} size="sm"
                                                required
                                                name="mailingcity"
                                                type="text"
                                                autoComplete="locality"
                                                value={card.matchingproducts[0].Name}
                                                onChange={changeProduct}

                                                pattern="^[\p{L}\p{N}\s,()-@.\/#+]+$"
                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide city.
                </Form.Control.Feedback>
                                        </Form.Group>                        
                        {}
                    </Col>
                </Row>
    */}
                <Row>
                    <Col className="border-top loancard pt-2 ">
                        <b>Purpose of Loan:</b>
                        <div style={{ marginTop: '12px' }}>{purposeOfLoan()}</div>
                    </Col>
                    <Col className="border-top loancard pt-2">
                        <Form.Group controlId="mailingCity" className="text-left" >

                            <Form.Label className="text-left" >Loan Amount:</Form.Label>
                            <NumericalInput isValid={false} size="sm"
                                required
                                name="mailingcity"
                                type="text"
                                autoComplete="locality"
                                value={getLoanSize()}
                                onChange={changeAmount}

                                pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide city.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                    <Col className="border-top loancard pt-2">

                        <Form.Group controlId="mailingCity" className="text-left" >

                            <Form.Label className="text-left" >Estimated Market Value:</Form.Label>
                            <NumericalInput isValid={false} size="sm"
                                required
                                name="mailingcity"
                                type="text"
                                autoComplete="locality"
                                value={getAppraisal()}
                                onChange={changeAppraisal}

                                pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide city.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    {
                        this.props.application.property.purpose === "purchase" ?
                            <Col className="border-top loancard pt-2"><b>Purchase Price:</b><br />${com.commaize(this.props.application.property.salesprice)}</Col>
                            : ""
                    }
                    <Col className="border-top loancard pt-2">
                        {getPaydown()}
                    </Col>

                    <Col className="border-top loancard pt-2">
                        <Form.Group controlId="mailingCity" className="text-left" >
                            <Form.Label className="text-left" >Payout:</Form.Label>
                            <PennyInput isValid={false} size="sm"
                                required
                                name="payout"
                                type="text"
                                autoComplete="off"
                                defaultValue={defaultPayout()}
                                onChange={changePayout}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className=" text-center mt-0 mb-0 mx-0">
                    <Col className="border-bottom border-top loancard pt-2 ">
                        <b>{getType()}</b><div style={{ marginTop: '12px' }}>
                            <b>{card.Term} years</b> </div>
                    </Col>
                    <Col className="border-bottom border-top loancard pt-2 ">

                        <Form.Group controlId="mailingCity" className="text-left" >
                            <Form.Label className="text-left" >Rate:</Form.Label>
                            <Form.Control isValid={false} size="sm"
                                required
                                name="mailingcity"
                                type="text"
                                autoComplete="locality"
                                value={card.base_rate}
                                onChange={changeRate}

                                pattern="^[0-9.]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide city.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                    <Col className="border-bottom border-top loancard pt-2 ">
                        APR:<div style={{ marginTop: '12px' }}>
                            <b>{card.APR}%</b></div>
                    </Col>
                    <Col className="border-bottom border-top loancard pt-2 ">
                        <Form.Group controlId="mailingCity" className="text-left" >

                            <Form.Label className="text-left" >Mo. Payment (P&amp;I):</Form.Label>
                            <PennyInput isValid={false} size="sm"
                                required
                                name="mailingcity"
                                type="text"
                                autoComplete="locality"
                                defaultValue={card.monthly_payment}
                                onChange={changeMonthlyPaymeny}

                                pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide city.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                    <Col className="border-bottom border-top loancard pt-2 ">
                        <Form.Group controlId="mailingCity" className="text-left" >

                            <Form.Label className="text-left" >Closing Costs:</Form.Label>
                            <NumericalInput isValid={false} size="sm"
                                required
                                name="mailingcity"
                                type="text"
                                autoComplete="locality"
                                value={this.getClosingCosts(card)}
                                onChange={changeClosingCosts}

                                pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide city.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>

                </Row>
                <Row className="mx-0 text-center  mb-0 my-0 ">

                    <Col className="border-bottom  py-2 loancard">
                        Interest over<br />{this.yearstohold} years:<br />
                        <b>${com.commaizeFloat(interest10.toFixed(2))}</b>
                    </Col>
                    <Col className="border-bottom py-2 loancard">
                        Interest over<br />loan life:<br />
                        <b>${com.commaizeFloat(interesttotal.toFixed(2))}</b>
                    </Col>

                    <Col className="border-bottom py-2 loancard">
                        Prepaid interest:<br />
                        over {this.state.prepaid_interest_days} days<br />
                        <b>${com.commaizeFloat(card.prepaid_interest.toFixed(2))}</b>
                    </Col>


                    <Col className="border-bottom py-2 loancard">
                        <Form.Group controlId="mailingCity" className="text-left" >
                            <Form.Label className="text-left" >Points:</Form.Label>
                            <Form.Control isValid={false} size="sm"
                                required
                                name="mailingcity"
                                type="text"
                                autoComplete="locality"
                                value={card.points}
                                onChange={changePoints}

                                pattern="^[0-9.-]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide city.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>

                </Row>
                <Row>
                    <Col className="border-bottom  loancard pt-2 ">
                        <Form.Group controlId="mailingCity" className="text-left" >
                            <Form.Label className="text-left" >Lender credits:</Form.Label>
                            <Form.Control isValid={false} size="sm"
                                required
                                name="mailingcity"
                                type="text"
                                autoComplete="locality"
                                value={card.lender_credit}
                                onChange={changeLenderCredit}

                                pattern="^[0-9.-]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide city.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                    {card.lender_fee ?
                        <Col className="border-bottom loancard pt-2 ">
                            <Form.Group controlId="mailingCity" className="text-left" >

                                <Form.Label className="text-left" >Lender fee</Form.Label>
                                <NumericalInput isValid={false} size="sm"
                                    required
                                    name="mailingcity"
                                    type="text"
                                    autoComplete="locality"
                                    value={card.lender_fee}
                                    onChange={changeLenderFee}

                                    pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                                />
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide city.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col> : ""
                    }



                    <Col className="border-bottom loancard pt-2 ">
                        <b>Minimal reserves:</b><br />
                        <b>{card.min_months_reserves} months</b>
                    </Col>
                </Row>
                {
                    (this.props.application.property.purpose !== "purchase") ?
                        <Row>
                            <Col className="border-bottom border-top loancard py-2" >
                                Current Program: {getCurrentProgram()}
                            </Col>
                            <Col className="border-bottom border-top loancard py-2" >
                                Current Rate: {getCurrentRate()}%
                            </Col>
                        </Row>
                        : ""
                }

                <Row>
                    <Col className="border-bottom border-top loancard py-2" >
                        <b>Subject property:</b> <br />{getProperty()}
                    </Col>
                    <Col className="border-bottom border-top loancard py-2"><b>County:</b> <br />{this.props.application.property.county}</Col>
                    <Col className="border-bottom border-top loancard py-2">
                        <b>Maximum loan size:</b><br /> {getMaxLoanSize()}
                    </Col>
                    {card.maxcashout != null ?
                        <Col className="border-bottom border-top loancard py-2">
                            <b>Maximum cash out:</b><br /> {com.commaizeFloat(card.maxcashout.toFixed(2))}
                        </Col> : ""
                    }
                </Row>


                <Tabs defaultActiveKey={0} className="mt-2">
                    {this.state.dtiFinalData.map(this.displayTabs)}
                </Tabs>
                <div className="mt-3" style={{ fontWeight: 'bold', fontSize: '1.3em' }}>
                    {card.high_cost_mortgage === true ? <div>This is a high cost loan</div> : ""}

                </div>
                {card.features.hasprepaymentpenalty ? <div>
                    Has prepayment penalty: {card.features.prepaymentpenaltyamount}, term: {card.features.prepaymentpenaltyamount}
                    over {card.features.prepaymentpenaltyterm} months.
                </div> : ""}
                {card.features.high_cost_mortgage ? <div> This is a high cost mortgage!</div> : ""}


            </Container>
        </div>

    }
    render() {
        let changeProduct = (verb) => {

            return e => {
                switch (e.target.value) {
                    case "year5":
                        this.props.updateProductValue(5 * 12, "mortgagetermmonths")
                        break
                    case "year7":
                        this.props.updateProductValue(7 * 12, "mortgagetermmonths")
                        break
                    case "year10":
                        this.props.updateProductValue(10 * 12, "mortgagetermmonths")
                        break
                    case "year15":
                        this.props.updateProductValue(15 * 12, "mortgagetermmonths")
                        break
                    case "year20":
                        this.props.updateProductValue(20 * 12, "mortgagetermmonths")
                        break
                    case "year25":
                        this.props.updateProductValue(25 * 12, "mortgagetermmonths")
                        break
                    case "year30":
                        this.props.updateProductValue(30 * 12, "mortgagetermmonths")
                        break
                    default:
                        break

                }
                return this.props.updateProduct(e, verb)
            }
        }
        return (
            <div className="mx-5 px-5 mb-3 px-1 text-center ">

                <Row>
                    <Col> {this.displaySelectedCard()} </Col>
                </Row>
                <Row className="mt-3">

                    <Col >
                        <Form.Group controlId="Agency" className="text-left" >
                            <Form.Label className="text-left" >Agency Case Number:&nbsp;
                            </Form.Label>
                            <Form.Control isValid={false} size="sm"

                                name="agency"
                                type="text"
                                pattern="^.+$"
                                defaultValue={this.props.product.agencycasenumber}
                                onChange={changeProduct("agencycasenumber")}
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide agency case number.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col >
                        <Form.Group controlId="Lender" className="text-left" >
                            <Form.Label className="text-left" >Lender Case Number:&nbsp;
                            </Form.Label>
                            <Form.Control isValid={false} size="sm"

                                name="other"
                                type="text"
                                pattern="^.+$"
                                defaultValue={this.props.product.lendercasenumber}
                                onChange={changeProduct("lendercasenumber")}
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide lender case number.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                {this.props.selection.selectedloan.lockstatus === 'none' || this.state.lockDetails.LockRequested === "" ?
                    <fieldset disabled={!this.state.disableLock}>
                        <hr />
                        <Button onClick={this.lock}>Lock the rate</Button>
                        {!this.state.disableLock &&
                            <Alert style={{ marginTop: '10px' }} variant='secondary'>
                                <div>
                                    You are unable to lock, because something of this information is missing:
                                    <ul style={{ textAlign: 'left' }}>
                                        <li>The consumer’s name</li>
                                        <li>The consumer’s income</li>
                                        <li>The consumer’s unique identifier</li>
                                        <li>The property address</li>
                                        <li>An estimate of the value of the property</li>
                                        <li>The mortgage loan amount sought</li>
                                    </ul>
                                </div>
                            </Alert>}
                    </fieldset>
                    :
                    <>
                        <hr />
                        <h4>Lock details</h4>
                        <Row>
                            <Col className="pt-4">Rate was locked: <b>{this.state.lockDetails.LockRequested}</b></Col>
                            <Form.Group as={Col} controlId="lockMethod">
                                <Form.Label>Choose lock method</Form.Label>
                                <Form.Control value={this.state.lockDetails.LockMethod || null} as="select" onChange={this.lockMethodChangeHandler}>
                                    <option hidden></option>
                                    <option>By Interview</option>
                                    <option>By Phone</option>
                                    <option>By Email</option>
                                    <option>By Chat</option>
                                    <option>Other</option>
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group as={Col} controlId="loComments">
                                    <Form.Label>Loan officer comments</Form.Label>
                                    <Form.Control onChange={this.commentChangeHandler} onBlur={this.updateLockDetails} value={this.state.lockDetails.LoNotes} as="textarea" />
                                </Form.Group>
                            </Col>
                            <Col>
                                {this.state.lockDetails.Extensions.length !== 0 && this.state.lockDetails.Extensions.map(e => (
                                    <Row>
                                        <Col className='divlink' xs='10' onClick={() => {
                                            this.setState({ currentExtensionDateTime: e.ExtensionDateTime, currentExtensionDuration: e.ExtensionDuration, modifyingExtensionDateTime: e.ExtensionDateTime, modifyingExtensionDuration: e.ExtensionDuration },
                                                () => this.openModal())
                                        }}>
                                            <p>Extension date and time: {e.ExtensionDateTime}</p>
                                            <p>Extension duration: {e.ExtensionDuration}</p>
                                        </Col>
                                        <Col className='divlink' onClick={() => this.setState({ infoModal: true, modifyingExtensionDateTime: e.ExtensionDateTime, modifyingExtensionDuration: e.ExtensionDuration })}>
                                            &times;
                                        </Col>
                                    </Row>
                                ))}
                                <Button onClick={this.openModal}>Add lock extension</Button>
                            </Col>
                        </Row>
                    </>}
                {this.state.showModal &&
                    <Modal
                        onHide={this.closeModal}
                        show={true}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Add lock extension
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={this.addLockExtension}>

                                <Form.Group controlId="extensionDateAndTime">
                                    <Form.Label>Enter date and time</Form.Label>
                                    <Form.Control placeholder="mm/dd/yyyy hh:mm AM/PM" value={this.state.currentExtensionDateTime} onChange={this.extensionDateModificationHandler} />
                                    <Form.Text className="text-muted">
                                        Enter date and time.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="extensionDuration">
                                    <Form.Label>Enter duration</Form.Label>
                                    <Form.Control placeholder="number of days" value={this.state.currentExtensionDuration} onChange={this.extensionDurationModificationHandler} />
                                    <Form.Text className="text-muted">
                                        Enter duration.
                                    </Form.Text>
                                </Form.Group>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type='submit' onClick={this.modalSubmit}>Submit</Button>
                            <Button variant="danger" onClick={this.closeModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>}
                <InfoModal
                    onHide={this.closeInfoModal}
                    show={this.state.infoModal}
                    modalTitle={<p><i className="fas fa-exclamation-triangle" style={{ 'color': '#f0ad4e' }}></i>Warning!</p>}
                    modalBody='Are you sure you want to delete this lock extension?'
                    modalFooter={
                        <>
                            <Button variant="primary" onClick={() => this.deleteExtension()}>Submit</Button>
                            <Button variant="danger" onClick={() => this.closeInfoModal()}>Quit</Button>
                        </>
                    }
                />
            </div>
        )
    }
}

Loan = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Loan)
