import React, {useState, useEffect} from "react";
import { Col, Row } from 'antd';
import "./Pricing.css";
import featuresList from './features.json';
import { padEnd } from "lodash";

function Pricing() {
    const [tabIndex, setTabIndex] = useState(0)
    const [plan, setPlan] = useState([])
    const tab = [
        {
            name: 'Monthly',
            tag: ''
        },
        {
            name: 'Yearly',
            tag: '15% off'
        }
    ]
    useEffect(() => {
        getPlan()
    }, [])
    const getPlan = () => {
        setTimeout(() => {
            const plan = [
                {
                    name: 'Starter',
                    des: '',
                    isFree: true,
                    popular: false,
                    freeTrial: false,
                    trialDay: 0,
                    monthPrice: '',
                    oldYearPrice: '',
                    discount: ''
                },
                {
                    name: 'Originator',
                    des: '',
                    isFree: false,
                    popular: true,
                    freeTrial: true,
                    trialDay: 7,
                    monthPrice: 150,
                    yearPrice: 1530,
                    oldYearPrice: 1800,
                    discount: 0.85
                },
                {
                    name: 'Business',
                    des: '',
                    isFree: false,
                    popular: false,
                    freeTrial: true,
                    trialDay: 7,
                    monthPrice: 650,
                    oldYearPrice: 7800,
                    discount: 0.85
                },
                {
                    name: 'Enterprise',
                    des: '',
                    isFree: false,
                    popular: false,
                    freeTrial: false,
                    trialDay: 7,
                    monthPrice: '',
                    oldYearPrice: '',
                    discount: 0
                }
            ]
            setPlan(plan)
        },200)
    }
    const handleTab = (index) => {
        setTabIndex(index)
    }
    const featuresValue = (value) => {
        if (value === 'Yes') {
            return <img src="/images/pricing-check.svg" />
        } else if (value === 'NO') {
            return ''
        } else {
            return value
        }
    }
    const formatAmount = (amount) => {
        return Number(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
    }
    return (
        <div className='pricing-page'>
            <div className='pricing-main-title'>Pricing</div>
            <div className='pricing-sub-title'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
            <div className="pricing-tab">
                {
                    tab.map((item, index) =>(
                        <div className={tabIndex === index ? 'tab active-tab' : 'tab'} key={index} onClick={() => handleTab(index)}>{ item.name }{item.tag !== '' && <span className="tab-tag">{ item.tag }</span>}</div>
                    ))
                }
            </div>
            <div style={{marginBottom: 70}}>
                <Row gutter={[16, 16]} className="pricing-plan-box">
                    {
                        plan.map((item) => (
                            <Col xl={6} lg={8} md={12} sm={24} xs={24}>
                                <div className="pricing-plan">
                                    <div className="plan-name">{item.name}</div>
                                    {item.name !== 'Enterprise' ? 
                                        (
                                            <>
                                                {tabIndex === 1 && <div className="old-price">{item.oldYearPrice !== '' ? formatAmount(item.oldYearPrice) : ''}</div>}
                                                <div className="plan-price" style={{marginTop: tabIndex === 0 ? 24 : 0}}><span>{item.isFree ? 'Free' : tabIndex === 0 ? formatAmount(item.monthPrice) : formatAmount(item.monthPrice * item.discount)}</span>{!item.isFree && <span className="plan-unit">/mo</span>}</div>
                                                <div className="free-day">{item.freeTrial ? `${item.trialDay}-day free trial` : ''}</div>
                                            </>
                                        ) :
                                        (
                                            <div className="enterprise-img" style={{margin: tabIndex === 0 ? '22px 0 39px' : '33px 0 44px'}}><img src="/images/pricing-enterprise.svg" /></div>
                                        )
                                    }
                                    <div className="plan-des">{item.des}</div>
                                    <div className={`plan-btn ${item.name === 'Enterprise' ? 'contact' : ''}`}>{item.name === 'Enterprise' ? 'Contact us' : 'Get start'}</div>
                                    {item.monthPrice !== '' && <div className="contact-us">Contact us</div>}
                                    {item.popular && <span className="plan-tag">Popular</span>}
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </div>
            <div className='pricing-main-title' style={{fontSize: 32}}>Compare features</div>
            <div className="features-list">
                <div className="features-list-title">
                    <div style={{flex: 1}}></div>
                    {plan.map((item, index) =>(
                        <div className="features-header" key={index}>
                            <div className="features-header-name">{item.name}</div>
                            <div className="features-header-price">{item.isFree ? 'Free': item.monthPrice !== '' ? `$${item.monthPrice}/mo` : ''}</div>
                            <div className={`features-header-btn ${item.name === 'Enterprise' ? 'contact' : ''}`}>{item.name === 'Enterprise' ? 'Contact us' : `Choose ${item.name}`}</div>
                        </div>
                    ))}
                </div>
                {featuresList.map((category, index) => (
                    <div key={index}>
                        <div className="service-name-box"><div className="service-name">{category.title}</div><div className="compare-features"></div><div className="compare-features"></div><div className="compare-features"></div><div className="compare-features"></div></div>
                        {category.compare.map((item, i) => (
                            <div className="compare" key={i}>
                            <div className="compare-name">{item.name}</div>
                            {item.options.map((option, j) => (
                                <div className="compare-features" key={j}>{featuresValue(option.value)}</div>
                            ))}
                            </div>
                        ))}
                    </div>
                ))}
                <div className="features-bottom">
                    <div className="features-bottom-title">Best for</div>
                    <div className="features-bottom-content"></div>
                    <div className="features-bottom-content"><img src="/images/lo-img.svg"/>1 LO</div>
                    <div className="features-bottom-content"><img src="/images/los-img.svg"/>2-24 LOs</div>
                    <div className="features-bottom-content"><img src="/images/los-img.svg"/>25+ LOs</div>
                </div>
            </div>
        </div>
    )
}

export default Pricing;