import {
    CHANGE_PROGRESS,
    CHANGE_PROGRESS_TYPE,
    CHANGE_PROGRESS_VISITED,
    CHANGE_PROGRESS_LASTVISITED,
    CHANGE_PROGRESS_LASTTOUCH,
    CHANGE_PROGRESS_STEP,
} from '../Defs/progressdefs'


import { progress } from '../State.js'

const initialProgress = new progress()
const initialProgressJSON = JSON.parse(initialProgress.toJson() )
export default function progressReducer(state = initialProgressJSON, action) {
    state = progress.fromJson(state)

    switch (action.type) {
        case CHANGE_PROGRESS_LASTVISITED: {
            let obj = state

            if (!obj.tasks) {
                obj.tasks = {}
            }
            obj.tasks.lastvisited = action.payload;
            break;
        }
        case CHANGE_PROGRESS_LASTTOUCH: {
            state.lasttouch = action.payload
            break;
        }
        case CHANGE_PROGRESS_STEP: {
            state.interview.step = action.payload
            break;
        }
        case CHANGE_PROGRESS: {
            let obj = state
            if(action.stage !== "max")
                obj.stage = action.stage
            switch (action.stage) {
                case "interview":
                    obj.interview.step = action.payload;
                    break;
                    case "max":
                    obj.interview.max = action.payload;
                    break;
                    case "loinput":
                    obj.loinput.step = action.payload;
                    break;

                default:

                    break;
            }
            break;
        }
        case CHANGE_PROGRESS_TYPE: {
            let obj = state
            obj.stage = action.stage
            break;
        }
        case CHANGE_PROGRESS_VISITED: {
            state.interview[action.verb] = true
            break
        }
        default:
            break;
    }
    return JSON.parse(state.toJson() );
}
