import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'

import * as com from "../Common"
import * as act from "../Store/actions"
import {calculateEmploymentIncome, calculateDividendIncome, calculateOtherIncome} from './Income'

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateApplicationAttribute: (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb))
  },
  updateSelectedLoanAttribute: (t, verb) => {
    dispatch(act.UpdateSelectedLoanAttribute(t, verb))
  },

});

export default class IncomeReview extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  showButton = (s) => {
    if(this.props.borrowerid != null) return ""
    return s
  }
  render() {
    let  getEmploymentStatus = (who) => {
      let tmp=[]
      let btn = <Button className="ml-4 my-1 py-0"
      href={"/app/interview/main/income"} size="sm" variant="outline-primary"> Edit</Button>
      switch(this.props.application[who].occupation.hasoccupation) {

        case  "unemployed": default:
          tmp.push(<span> is unemployed {this.showButton(btn)}</span>)
          break
        case  "retired":
          tmp.push(<span>  is retired {this.showButton(btn)}</span> )
          break

        case  "employed": 
          tmp.push(  <span>  is currently employed at <b>{this.props.application[who].occupation.employername}</b>, 
                {this.props.application[who].occupation.employeraddress} as <b>{this.props.application[who].occupation.positiontitle}</b> {this.showButton(btn)}</span> )
                
          if(this.props.application[who].otheroccupations !== null && this.props.application[who].otheroccupations.length > 0) {
            tmp.push(<div>Also employed at:</div>)
            for(let i = 0; i < this.props.application[who].otheroccupations.length; i++) {
              let o = this.props.application[who].otheroccupations[i]
              tmp.push(<div><b>{o.employername}</b>, at {o.employeraddress} as <b>{o.positiontitle}</b> {this.showButton(btn)}</div>)
            }
          }
          if(calculateEmploymentIncome(this.props.application, who) > 0) 
            tmp.push(<div>Employment gross <b>monthly</b> income: <b>${com.commaizeFloat(calculateEmploymentIncome(this.props.application, who).toFixed(2))}</b> (<b>yearly</b> income of <b>${com.commaizeFloat( (12*calculateEmploymentIncome(this.props.application, who)).toFixed(2) )})</b> {this.showButton(btn)}</div>)
         

          break

      }
      if(calculateDividendIncome(this.props.application, who) > 0) {
        tmp.push(<div>Gross <b>monthly</b> dividends: <b>${com.commaizeFloat(calculateDividendIncome(this.props.application, who).toFixed(2))}</b> (yearly dividends of  <b>${com.commaizeFloat( (12*calculateDividendIncome(this.props.application, who)).toFixed(2))}</b>){this.showButton(btn)}</div>)
      }              
      if(calculateOtherIncome(this.props.application, who) > 0) {
        tmp.push(<div>Gross <b>monthly</b> other income: <b>${com.commaizeFloat(calculateOtherIncome(this.props.application, who).toFixed(2))}</b> (yearly amount of  <b>${com.commaizeFloat( (12*calculateOtherIncome(this.props.application, who)).toFixed(2))}</b>){this.showButton(btn)}</div>)
      }              
      return (
          <div className="mt-4"><span> {this.props.application[who].firstname}  {this.props.application[who].lastname}</span>
          {tmp} </div>
           
      )
    }
    let getEmploymentStatuses = () => {
      let statuses = []
      statuses.push(getEmploymentStatus("borrower"))
      if (this.props.application.hascoborrower === "withcoborrower" ||
      this.props.application.hascoborrower === "multiple") {
        statuses.push(getEmploymentStatus("coborrower"))
      }      
      return statuses
    }


    let getAssets = () => {
      let btn = <Button className="ml-4 my-1 py-0"
      href={"/app/interview/main/assets"} size="sm" variant="outline-primary"> Edit</Button>      
      let out = []
      let totalbanks = [0, 0, 0]
      let totalbrokoerages = [0, 0, 0]
      let getname = (b) => {
        return this.props.application[b].firstname + " " + this.props.application[b].lastname
      } 
      

      this.props.application.assetsandliabilities.accounts && 
      this.props.application.assetsandliabilities.accounts.forEach(x => {
        let attribution = x.attribution == null ? 0 : x.attribution - 1
          if(x.balance !== "") {
            totalbanks[attribution] += parseFloat( x.balance)
          }
      })
      if(totalbanks[0] + totalbanks[1] + totalbanks[2] > 0) {
        out.push(<div className="mt-4 font-weight-bold">Bank balances:</div>)
        if(totalbanks[0] > 0) {
          out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat( totalbanks[0].toFixed(2) )}</b> {this.showButton(btn)}</div>)
        }
        if(totalbanks[1] > 0) {
          out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat( totalbanks[1].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
        if(totalbanks[2] > 0) {
          out.push(<div>Joint accounts: <b>${com.commaizeFloat( totalbanks[2].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
      }
      this.props.application.assetsandliabilities.brokerages && 
      this.props.application.assetsandliabilities.brokerages.forEach(x => {
          let attribution = x.attribution == null ? 0 : x.attribution - 1
          if(x.balance !== "") {
            totalbrokoerages[attribution] += parseFloat( x.balance)
          }
      })

      if(totalbrokoerages[0] + totalbrokoerages[1] + totalbrokoerages[2] > 0) {
        out.push(<div className="mt-4 font-weight-bold">Brokerage accounts balances:</div>)
        if(totalbrokoerages[0] > 0) {
          out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat( totalbrokoerages[0].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
        if(totalbrokoerages[1] > 0) {
          out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat( totalbrokoerages[1].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
        if(totalbrokoerages[2] > 0) {
          out.push(<div>Joint accounts: <b>${com.commaizeFloat( totalbrokoerages[2].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
      }

      btn = <Button className="ml-4 my-1 py-0"
      href={"/app/interview/main/22"} size="sm" variant="outline-primary"> Edit</Button>         
      if(this.props.application.assetsandliabilities.gifts != null && this.props.application.assetsandliabilities.gifts.length > 0) {
        
        out.push(<div className="mt-4 font-weight-bold">Gifts:</div>)   
        let gifts = this.props.application.assetsandliabilities.gifts
        let totalgifts = [0, 0, 0]
        for(let i = 0; i < gifts.length; i++) {
          let attribution = gifts[i].attribution == null ? 0 : gifts[i].attribution - 1
          totalgifts[attribution] += parseFloat(gifts[i].value)
        }     
        if(totalgifts[0] > 0) {
          out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat( totalgifts[0].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
        if(totalgifts[1] > 0) {
          out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat( totalgifts[1].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
        if(totalgifts[2] > 0) {
          out.push(<div>Joint ownership: <b>${com.commaizeFloat( totalgifts[2].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }        
      }
      if(this.props.application.assetsandliabilities.otherassets != null && this.props.application.assetsandliabilities.otherassets.length !== 0){
        out.push(<div className="mt-4 font-weight-bold">Other assets:</div>)   
        let other = this.props.application.assetsandliabilities.otherassets
        let totalother = [0, 0, 0]
        for(let i = 0; i < other.length; i++) {
          let attribution = other[i].attribution == null ? 0 : other[i].attribution - 1
          totalother[attribution] += parseFloat(other[i].value)
        }     
        if(totalother[0] > 0) {
          out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat( totalother[0].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
        if(totalother[1] > 0) {
          out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat( totalother[1].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }
        if(totalother[2] > 0) {
          out.push(<div>Joint ownership: <b>${com.commaizeFloat( totalother[2].toFixed(2) )}</b>{this.showButton(btn)}</div>)
        }        
      }
      return out
    }
    return (
      <div className="mb-5  text-left">
        <div className="text-left mt-2 mb-4 pt-2 ">
          <h2 className=" heading">Please Review Your Income and Assets </h2>
          <div className="viewport" >
            <div className="text-left sectionquestion">Income:</div>
            <div>
              {getEmploymentStatuses()} 
            </div>

          </div>

          <div className="viewport mt-3" >
            <div className="text-left sectionquestion">Assets:</div>
            <div>
            {getAssets()} 
            </div>

          </div>

          </div>
      </div>
    )
  }
}
IncomeReview = connect(mapStateToProps, mapDispatchToProps, null)(IncomeReview)
