import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";

import "./SubscriptionDashboard.css";
import SubscribersList from "./SubscribersList";
import LoanAmountChart from "./Chart";

const emptyStatistics = {
  purchase: 0,
  refinance: 0,
  primary: 0,
  secondhome:0,
  investment:0,
  lm1: 0,
  lm2: 0,
  lm3: 0,
  lm4: 0,
  lm5: 0,
}

const SubscriptionDashboard = (props) => {
  const [totalSubscribers, setTotalSubscribers] = useState(0);
  const [statistics, setStatistics] = useState(emptyStatistics);

  return (
    <div>
      <div className="featured">
        <div className="featuredStatistics">
          <span className="featuredTitle">Statistics</span>
          <div className="featuredContainer">
            <br />
            <Row className="featuredRow">Total: {totalSubscribers}</Row>
            <Row className="featuredRow">
              Purchase/Refinance: {statistics.purchase}%/{statistics.refinance}%
            </Row>
            <Row  className="featuredRow">
              Primary/2nd Home/Investment: {statistics.primary}%/{statistics.secondhome}%/{statistics.investment}%
            </Row>
          </div>
        </div>
        {/* <div className="featuredItem">
          <span className="featuredTitle">Insights</span>
          <div className="featuredContainer">
            <span>unsubsribed: 0%</span>
          </div>
        </div> */}
        <div className="featuredChart">
          <span className="featuredTitle">Loan Amounts Distribution</span>
          <div>
            <LoanAmountChart statistics={statistics} />
          </div>
        </div>
      </div>
      <div className="sub-list">
        <SubscribersList
          totalSubscribers={totalSubscribers}
          setTotalSubscribers={setTotalSubscribers}
          statistics={statistics}
          setStatistics={setStatistics}
        />
      </div>
    </div>
  );
};

export default SubscriptionDashboard;
