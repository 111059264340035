import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import PlanPricing from '../../../Management/planPricing'
export default function Features() {
    const dispatch = useDispatch()
    return (
        <>
            <div className='ModalTitle d-flex align-items-center' >
                <div>
                    Congratulations!
                </div><img style={{
                    width: 50, height: 55, marginBottom: 7, marginLeft: 15
                }} src='/images/congratulation.svg'></img>
            </div>
            <div style={{ marginTop: 15, fontSize: 16, fontFamily: "Inter", fontWeight: 400 }}>
                You’ve successfully set up your team. Explore our advanced plans to unlock more features.
            </div>
            <div style={{ overflowY: 'auto', height: window.innerHeight < 850 ? 'calc( 100vh - 10rem)' : 'calc( 100vh - 10rem)' }}>

                <PlanPricing ></PlanPricing>
            </div>

        </>

    )
}
