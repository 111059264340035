import React from 'react';
import * as com from '../Common';
import styles from './Spinner.module.css';

const Spinner = props => (
  <div id="spinner" style={{...props.style}} className={`${props.addSpinnerClass && styles.spinner} ${props.className}`}>
    <img alt="spinner" className={`${styles.loader}`} src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img>
  </div>
)


export default Spinner