const programs = [
    {
        title: "Low interest rates",
        review: "Unlock real-time interest rates using our convenient calculator for quick and transparent loan term evaluations.",
        img: "1"
    },
    {
        title: "Apply 100% online, on your schedule",
        review: "Benefit from the efficiency of our advanced affordability calculator to tailor loan options to your financial capacity.",
        img: '2'
    },
    {
        title: "100+ investor program selections",
        review: "We provide a variety of special programs to offer unique opportunities aligned with your financial goals.",
        img: '3'
    },
]

const normalPrograms = [
    {
        title: "Low interest rates",
        review: "Unlock real-time interest rates using our convenient calculator for quick and transparent loan term evaluations.",
        img: "1"
    },
    {
        title: "Apply 100% online, on your schedule",
        review: "Benefit from the efficiency of our advanced affordability calculator to tailor loan options to your financial capacity.",
        img: '2'
    },
    {
        title: "More investors, more choices",
        review: "We provide a variety of investor programs to cover all your mortgage needs.",
        img: '3'
    },
]

const faqData=[
    {
        key: '1',
        label: 'How long does loan approval take?',
        children: "Mortgage Loans process takes from several days to a few weeks. Pre-approval can be quick, but the final approval, which involves underwriting and appraisal, typically takes 30 to 45 days.",
    },
    {
        key: '2',
        label: 'What types of loans do I qualify for?',
        children: "By answering a few simple questions, you can find a program on my site that's right for you.",
    },
    {
        key: '3',
        label: 'What documents do I need to provide?',
        children: "The specific documents vary by loan type, but generally include proof of income, credit history, and personal identification. Your Loan Officer will provide a detailed checklist.",
    },

]
const moreFaqData=[
    {
        key: '4',
        label: 'What does this mortgage calculator show me?',
        children: "- The maximum amount you can borrow- The highest-priced property you can affordThe calculation for the maximum amount you can borrow is solely based on your income. To determine the value of the most expensive property you can afford, I add your deposit to the maximum borrowing amount. The size of your deposit influences how much you can borrow.",
    },
    {
        key: '5',
        label: 'How much can I borrow?',
        children: "To figure out how much you can borrow, we look at what you earn, your other loan payments, your credit score, and the value of the property you want. Let’s chat about your finances to give you a clear idea of what’s possible. It’s a simple process, and I’ll help you through every step to make sure you find a loan that fits your budget.",
    },
    {
        key: '6',
        label: 'Are there other factors that affect how much I can borrow? ',
        children: "Yes, besides your income, existing debts, credit score, and the property's value, there are a few other factors that can affect how much you can borrow:Interest Rates: Higher interest rates might reduce the amount you can borrow because they increase your monthly paymenLoan Term: The length of the loan can also impact your borrowing capacity. Longer terms can lower monthly payments, potentially allowing you to borrow more.Type of Loan: Different loan types have various borrowing limits. For example, government-backed loans might have different limits compared to conventional loaDown Payment: The amount you can put down upfront affects your borrowing amount. A larger down payment can reduce the loan amount needMarket Conditions: Economic factors and housing market conditions can influence lending standards and how much you can borrFinancial Stability: Lenders may consider your overall financial stability, including savings and investment balances, which can reflect your ability to manage financial emergencies without defaulting on your loan.Understanding these factors can give you a more comprehensive view of your borrowing capacity.",
    },
    {
        key: '7',
        label: 'Im self-employed. Does this make a difference to how much I can borrow?',
        children: "Being self-employed does add a twist to the borrowing process, but it’s nothing you can’t handle! Lenders just want to see a clear picture of your income, so you'll likely need to share a bit more paperwork, like tax returns or bank statements, for the past couple of years. Since your income might vary more than someone with a salary, having a strong credit score or a good chunk saved for a down payment can really work in your favor. Just think of it as gathering a few extra pieces of the puzzle to help lenders see the whole picture. If you’re organized and ready, you’ll be all set!",
    },
    {
        key: '8',
        label: 'I have a big income, but I can only borrow a little. Why is that? ',
        children: "Got a big income but can only borrow a little? It might be because of a few things:Lots of Debts? - If you've got other big debts, lenders might worry about adding more to your plCredit Score - Even with good income, a lower credit score can limit how much they'll lend Income Ups and Downs? - If your income bounces around, lenders might be cautiLoan Terms & Property Value - Sometimes, it's about the loan details or the property's value itsDifferent Lenders, Different Rules - Lenders have their own ways of deciding, so it can vChecking these areas and comparing lenders could help you borrow more. Let's find the best fit for you!",
    },
    {
        key: '9',
        label: "I have a big deposit. Why can't I borrow more?                        ",
        children: "A big deposit is awesome, but there might be a few reasons you can't borrow more: maybe it's about your monthly income and expenses balance, your credit score, or just the lender's own rules. Don’t worry, though—your big deposit still puts you in a great spot. If you have any questions or want to explore your options, I'm here to help!",
    },
    {
        key: '10',
        label: 'Is my personal information secure?                        ',
        children: "Keeping your data safe is my top concern. We make sure all data sent to and from our servers is secure. Also, we don't gather sensitive details like your social security number or financial information. Your personal info is never shared without your clear permission, and only lenders you choose to share with can see your data in our protected space.",
    },
    

]
export {
    normalPrograms,
    programs,
    faqData,
    moreFaqData

}