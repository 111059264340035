import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

const Form1120s = ({ doc, onFocus, onChangeTextInput }) => {
  return (
    <>
      <Row>
        <Form.Group as={Col} controlId="Year">
          <Form.Label>Year</Form.Label>
          <Form.Control defaultValue={doc['Year']['Value']} type="text" onFocus={() => onFocus(doc['Year']['BBox'], doc['Year']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['Year', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line4NetGainOrLoss">
          <Form.Label>Line 4: Net Gain Or Loss</Form.Label>
          <Form.Control defaultValue={doc['Line4NetGainOrLoss']['Value']} type="text" onFocus={() => onFocus(doc['Line4NetGainOrLoss']['BBox'], doc['Line4NetGainOrLoss']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['Line4NetGainOrLoss', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line5OtherIncomeOrLoss">
          <Form.Label>Line 5: Other Income Or Loss</Form.Label>
          <Form.Control defaultValue={doc['Line5OtherIncomeOrLoss']['Value']} type="text" onFocus={() => onFocus(doc['Line5OtherIncomeOrLoss']['BBox'], doc['Line5OtherIncomeOrLoss']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['Line5OtherIncomeOrLoss', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line14Depreciation">
          <Form.Label>Line 14: Depreciation</Form.Label>
          <Form.Control defaultValue={doc['Line14Depreciation']['Value']} type="text" onFocus={() => onFocus(doc['Line14Depreciation']['BBox'], doc['Line14Depreciation']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['Line14Depreciation', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line15Depletion">
          <Form.Label>Line 15: Depeletion</Form.Label>
          <Form.Control defaultValue={doc['Line15Depletion']['Value']} type="text" onFocus={() => onFocus(doc['Line15Depletion']['BBox'], doc['Line15Depletion']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['Line15Depletion', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line19OtherDeductions">
          <Form.Label>Line 19: Other Deductions</Form.Label>
          <Form.Control defaultValue={doc['Line19OtherDeductions']['Value']} type="text" onFocus={() => onFocus(doc['Line19OtherDeductions']['BBox'], doc['Line19OtherDeductions']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['Line19OtherDeductions', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="SchLLine17dMortgagesOrNotesPayableInLessThan1Year">
          <Form.Label>Schedule L Line 17d: Mortgages Or Notes Payable In Less Than 1 Year</Form.Label>
          <Form.Control defaultValue={doc['SchLLine17dMortgagesOrNotesPayableInLessThan1Year']['Value']} type="text" onFocus={() => onFocus(doc['SchLLine17dMortgagesOrNotesPayableInLessThan1Year']['BBox'], doc['SchLLine17dMortgagesOrNotesPayableInLessThan1Year']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['SchLLine17dMortgagesOrNotesPayableInLessThan1Year', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="SchM1Line3bTravelAndEntertainmen">
          <Form.Label>Schedule M1 Line 3b: Travel And Entertainmen</Form.Label>
          <Form.Control defaultValue={doc['SchM1Line3bTravelAndEntertainmen']['Value']} type="text" onFocus={() => onFocus(doc['SchM1Line3bTravelAndEntertainmen']['BBox'], doc['SchM1Line3bTravelAndEntertainmen']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['SchM1Line3bTravelAndEntertainmen', 'Value'])} />
        </Form.Group>
      </Row>
      {doc['SchK1'] && <>
          <h2>Schedule K1</h2>
          <Row>
            <Form.Group as={Col} controlId="SchK1Year">
              <Form.Label>Year</Form.Label>
              <Form.Control defaultValue={doc['SchK1']['Year']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['Year']['BBox'], doc['SchK1']['Year']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['SchK1', 'Year', 'Value'])} />
            </Form.Group>

            <Form.Group as={Col} controlId="SchK1Line1OrdinaryIncome">
              <Form.Label>Line 1: Ordinary Income</Form.Label>
              <Form.Control defaultValue={doc['SchK1']['Line1OrdinaryIncome']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['Line1OrdinaryIncome']['BBox'], doc['SchK1']['Line1OrdinaryIncome']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['SchK1', 'Line1OrdinaryIncome', 'Value'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="SchK1Line2NetRentalRealEstate">
              <Form.Label>Line 2: Net Rental Real Estate</Form.Label>
              <Form.Control defaultValue={doc['SchK1']['Line2NetRentalRealEstate']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['Line2NetRentalRealEstate']['BBox'], doc['SchK1']['Line2NetRentalRealEstate']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['SchK1', 'Line2NetRentalRealEstate', 'Value'])} />
            </Form.Group>

            <Form.Group as={Col} controlId="SchK1Line3OtherNetRentalIncome">
              <Form.Label>Line 3: Other Net Rental Income</Form.Label>
              <Form.Control defaultValue={doc['SchK1']['Line3OtherNetRentalIncome']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['Line3OtherNetRentalIncome']['BBox'], doc['SchK1']['Line3OtherNetRentalIncome']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['SchK1', 'Line3OtherNetRentalIncome', 'Value'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="SchK1PercentOwnership">
              <Form.Label>Percent Ownership</Form.Label>
              <Form.Control defaultValue={doc['SchK1']['PercentOwnership']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['PercentOwnership']['BBox'], doc['SchK1']['PercentOwnership']['Page'])} onChange={e => onChangeTextInput('form1120s.pdf', e.target.value, ['SchK1', 'PercentOwnership', 'Value'])} />
            </Form.Group>
          </Row>
        </>}
    </>
  );
}

export default Form1120s;