import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../Utils.css';

import PipelineForLoanProcessors from './PipelineForLoanProcessors';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Container, Row, Col, Form } from 'react-bootstrap'

const { SearchBar, ClearSearchButton } = Search;

class BorrowersForLoanProcessors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPast: false,
      urgentTasks: [],
    }
    this.urgentTasksColumns = [
      {
        dataField: 'firstname',
        text: 'First Name',
        sort: true,
      }, {
        dataField: 'lastname',
        text: 'Last Name',
        sort: true,
      }, {
        dataField: 'task',
        text: 'Task',
        sort: true,
      }, {
        dataField: 'deadline',
        text: 'Deadline',
        sort: true,
      }, {
        dataField: 'loanID',
        text: 'loanID',
        hidden: true,
      }
    ];
  }
  componentDidMount() {
    this.reload()
  }

  reload = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/urgentlotasks', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            let tasks_ = []
            for (let i = 0; i < js.Urgent.length; i++) {
              tasks_.push({
                firstName: js.Urgent[i].FirstName,
                lastName: js.Urgent[i].LastName,
                task: js.Urgent[i].Task,
                deadline: new Date(js.Urgent[i].Deadline).toLocaleDateString(),
                loanID: js.Urgent[i].LoanID,
                key: i
              })
            }
            tasks_.sort((e1, e2) => e1.deadline > e2.deadline ? 1 : -1)
            this.setState({ urgentTasks: tasks_ })
          }
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

  render() {

    return (
      <>
        <Container fluid className="text-left">
          <Row className="mb-3">
            <Col sm="auto" ><h4 className="mx-4">Clients:</h4></Col>
          </Row>
          <Row>
            <Col sm="auto" className='text-left mb-2'>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Show Past Customers"
                checked={this.state.showPast}
                onChange={() => this.setState(prev => ({ showPast: !prev.showPast }))}
                style={{display: 'inline-block'}}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.showPast && <PipelineForLoanProcessors past={true} />}
              {!this.state.showPast && <PipelineForLoanProcessors past={false} />}
            </Col>
          </Row>
          {/* <Row className="mx-1">
            <Col>
              <ToolkitProvider
                bootstrap4
                keyField='key'
                data={this.state.urgentTasks.map(document => {
                  return {
                    firstname: document.firstName,
                    lastname: document.lastName,
                    task: document.task,
                    deadline: document.deadline,
                    loanID: document.loanID,
                    key: document.key
                  }
                })}
                columns={this.urgentTasksColumns}
                search
              >
                {
                  props => (
                    <div>
                      <h4>Tasks:</h4>
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />

                      <BootstrapTable id="tasks"
                        striped bootstrap4 bordered={false}
                        {...props.baseProps}
                        wrapperClasses="table-responsive"
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
            </Col>
          </Row> */}
        </Container>
      </>
    )
  }
}

export default BorrowersForLoanProcessors