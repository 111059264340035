import React, { Component } from "react";
import { connect } from 'react-redux'

import Toast from 'react-bootstrap/Toast'

import * as com from './Common';
import * as ntf from "./Store/notifications"

const mapStateToProps = (state) => {
  return {
    borrower: state.borrower,
    notifications: state.notifications
  }
}

const mapDispatchToProps = (dispatch) => ({
  addAllNotificationsSpecific: (notifications) => {
    dispatch(ntf.AddAllNotificationsSpecific(notifications))
  },
  removeSpecificNotification: (notification_id) => {
    dispatch(ntf.RemoveSpecificNotification(notification_id))
  },
  addAllNotificationsGeneral: (notifications) => {
    dispatch(ntf.AddAllNotificationsGenaral(notifications))
  },
  removeGeneralNotification: (notification_id) => {
    dispatch(ntf.RemoveGeneralNotification(notification_id))
  },
});


class Notifications extends Component {

  constructor(props){
    super(props)
    this.interval = null
    this.interval2 = null
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.borrower.loan_id !== this.props.borrower.loan_id)
      this.getNotificationsSpecific(newProps.borrower.id)
  }

  getNotificationsSpecific = (id = this.props.borrower.id) => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch(`/los/getspecificnotifications/`, {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-CustomerID": id
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' + response.status);
        return;
      }
      response.json().then(js => {
        if (js.Notifications != null && js.Notifications.length !== 0) {
          this.props.addAllNotificationsSpecific(js.Notifications)
        } else {
          this.props.addAllNotificationsSpecific([])
        }
      })
    })
  }

  getNotificationsGeneral = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch(`/los/getgeneralnotifications/`, {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      response.json().then(js => {
        if (js.Notifications != null && js.Notifications.length !== 0) {
          this.props.addAllNotificationsGeneral([...js.Notifications])
        } else {
          this.props.addAllNotificationsGeneral([])
        }
      })
    })
  }

  handleCloseNotification = (notification, isGeneral) => {
    let body = {
      NotificationId: notification.id,
      Closed: new Date()
    }
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/archivenotification', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' + response.status);
        return;
      } else {
        if(isGeneral){
          this.props.removeGeneralNotification(notification.id)
        } else {
          this.props.removeSpecificNotification(notification.id)
        }
      }
    })
  }


  componentDidMount = () => {
    this.getNotificationsSpecific()
    this.getNotificationsGeneral()
    this.inteval = setInterval(this.getNotificationsSpecific, 30 * 1000)
    this.inteval2 = setInterval(this.getNotificationsGeneral, 30 * 1000)
  }

  componentWillUnmount = () => {
    clearInterval(this.inteval)
    clearInterval(this.inteval2)
  }

  componentWillUnmount = () => {
    clearInterval(this.inteval)
    clearInterval(this.inteval2)
  }

  render() {
    let closeNotification = (notification, isGeneral) => {
      return e => this.handleCloseNotification(notification, isGeneral)
    }

    return (
      <div className='notification-pannel-specific'>
        {this.props.notifications.specific.length !== 0 &&
          this.props.notifications.specific.map(n => (
            <Toast
              onClose={closeNotification(n, false)}
              className='pop-up-notification'
            >
              <Toast.Header>
                <p className="notification-header-text">Read me</p>
              </Toast.Header>
              <Toast.Body>{n.text}</Toast.Body>
            </Toast>
          ))
        }
        {this.props.notifications.general.length !== 0 &&
          this.props.notifications.general.map(n => (
            <Toast
              onClose={closeNotification(n, true)}
              className='pop-up-notification'
            >
              <Toast.Header>
                <p className="notification-header-text">Read me</p>
              </Toast.Header>
              <Toast.Body>{n.text}</Toast.Body>
            </Toast>
          ))
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)