import React, { useState, useRef } from 'react';
import { Carousel, Button } from 'antd';
import {Row, Col} from "react-bootstrap"
import { LeftOutlined } from '@ant-design/icons';

const ProgramInitialGuide = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showResult, setShowResult] = useState(null)
  const carouselRef = useRef(null);

  const goToNext = () => {
    setCurrentSlide(currentSlide + 1);
    carouselRef.current.next();
  };

  const goToPrev = () => {
    setCurrentSlide(currentSlide - 1);
    carouselRef.current.prev();
  };

  const navigateToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
    carouselRef.current.goTo(slideIndex, false);
  };

  const resetSlide = () => {
    setShowResult(null)
    setCurrentSlide(1)
  }

  const displayResult = () => {
    let res
    if (showResult === 1) {
      res =  <div className='programResults'>
              <div className='program' onClick={() => window.open(`https://foreigner.gmccloan.com`,"_blank")}>
                  <div className='aAsLink d-flex align-items-center mb-1 bold'><img src='/images/condo.png' /> Foreigner Nationals</div>
                  <div className='mt-1'>All Visa acceptable (F, B, J etc)</div>
              </div>
              <div className='program' onClick={() => props.handleClickCalculators('celebrityLargerAssets')}>
                  <div className='aAsLink d-flex align-items-center mb-1 bold'><img src='/images/condo.png' /> No Employment - Affluent</div>
                  <div className='mt-1'>Designed for affluent asset borrowers with limited taxable income, self-employment, or with no employment verification.</div>
              </div>
          </div>
    } else if (showResult === 2) {
      res = <div className='programResults'>
      <div className='program' onClick={() => props.handleClickCalculators('voe')}>
          <div className='aAsLink d-flex align-items-center mb-1 bold'><img src='/images/condo.png' /> Verbal VOE</div>
          <div className='mt-1'>Qualify borrowers using only verbal VOE.</div>
      </div>
  </div>
    } else if (showResult === 3) {
      res = <div className='programResults'>
      <div className='program' onClick={() => props.handleClickCalculators('celebrity15K')}>
          <div className='aAsLink d-flex align-items-center mb-1 bold'><img src='/images/condo.png' /> $5K-30K Grant</div>
          <div className='mt-1'>Designed for underserved borrowers and communities</div>
      </div>
      <div className='program' onClick={() => props.handleClickCalculators('cra')}>
          <div className='aAsLink d-flex align-items-center mb-1 bold'><img src='/images/condo.png' />Community Benefits</div>
          <div className='mt-1'>Accept downpayment as low as 0%, and no mortgage insurance.</div>
      </div>
  </div>
    } else if (showResult === 4) {
      res = <div className='programResults'>
      <div className='program' onClick={() => props.handleClickCalculators('celebrityLargerAssets')}>
          <div className='aAsLink d-flex align-items-center mb-1 bold'><img src='/images/condo.png' /> Employment optional - Affluent</div>
          <div className='mt-1'>Designed for affluent asset borrowers with limited taxable income, self-employment, or with no employment verification.</div>
      </div>
  </div>
    } else if (showResult === 5) {
      res = <div className='programResults'>
      <div className='program' onClick={() => props.handleClickCalculators('celebrityNoratio')}>
          <div className='aAsLink d-flex align-items-center mb-1 bold'><img src='/images/condo.png' /> Employment optional - 40% Down</div>
          <div className='mt-1'>Designed for borrower with no employment required, but willing to pay larger down payment </div>
      </div>
      <div className='program' onClick={() => props.handleClickCalculators('cra')}>
          <div className='aAsLink d-flex align-items-center mb-1 bold'><img src='/images/condo.png' />Verbal VOE</div>
          <div className='mt-1'>Qualify borrowers using only verbal VOE.</div>
      </div>
  </div>
    }
    

    return     <div className="programResultWrap"> 
    <div className='text-left bold align-items-center d-flex' style={{fontSize:15, cursor:"pointer"}} onClick={()=>resetSlide()}>
      <LeftOutlined className='mr-2 '/>Start again
    </div> 
    <div className='title-3'>We have the right programs for you!</div>
    <div>Please use the program quailifer to check your eligibility and contact your loan officer for more details.</div>
    {res}
    </div>
  }

  if (showResult !== null) return displayResult()

  return (
    <div className="programInitialGuideWrap">
      <Row>
        <Col md={6} xs={12}>        
          <div className='slideDotsWrap'>
              <div className='title-1' style={{color:"#6E6E70"}}>Find the right Programs </div>
              <div className='title-1'>for your needs</div>
              <div className='mt-3' style={{fontSize:"16px"}}>At GMCC, we offer a range of incentives and programs designed to meet the unique needs of our borrowers. Answer a few questions and we'll guide you to a tool that determines your program eligibility.</div>
          </div>
        </Col>
        <Col md={6} xs={12}>       
          <div className='carouselWrap'>
          <Carousel ref={carouselRef} afterChange={setCurrentSlide} dots={false}>
            <div className='slide'>
              <div className='title-3 pt-4'>Do you have income documentation like paystub and w2?</div>
              <div className='mt-3'>
                <Button type="outline" style={{width:100}} onClick={() => navigateToSlide(1)}>No</Button>
                <Button type="primary" style={{ width:100, marginLeft: 8 }} onClick={() => setShowResult(3)}>Yes</Button>
              </div>
            </div>
            <div className='slide'>
              <div className='text-left pt-3'><LeftOutlined onClick={()=>goToPrev()} /></div>
              <div className='title-3 '>Are you a foreigner?</div>
              <div className='mt-3'>
                <Button type="outline" style={{width:100}} onClick={() => navigateToSlide(2)}>No</Button>
                <Button type="primary" style={{ width:100, marginLeft: 8 }} onClick={() => setShowResult(1)}>Yes</Button>
              </div>
            </div>
            <div className='slide'>
              <div className='text-left pt-3'><LeftOutlined onClick={()=>goToPrev()} /></div>
              <div className='title-3 '>Got substantial assets? They can help you qualify for a mortgage loan.</div>
              <div className='mt-3'>
                <Button type="outline" style={{width:100}} onClick={() => setShowResult(5)}>No</Button>
                <Button type="primary" style={{ width:100, marginLeft: 8 }} onClick={() => setShowResult(4)}>Yes</Button>
              </div>
            </div>
          </Carousel>
        </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProgramInitialGuide;
