import React from 'react';
import Modal from 'react-bootstrap/Modal'

import classes from './InfoModal.module.css'

export default function InfoModal(props) {
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={classes["info-modal"]}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.modalBody}
      </Modal.Body>
      {props.modalFooter && <Modal.Footer>{props.modalFooter}</Modal.Footer>}
    </Modal>
  )
}