import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Chart from "react-apexcharts";

import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const mapStateToProps = (state) => {
    return {
        application: state.application,

    }
}

const mapDispatchToProps = (dispatch) => ({

    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changeBorrowerInfoCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },

    updatePreviousAddress: (event, who, verb, n) => {
        dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n))
    },
    addPreviousAddress: (event, who) => {
        dispatch(act.AddPreviousAddress(event.target.value, who,))
    },
    removePreviousAddress: (event, who, n) => {
        dispatch(act.RemovePreviousAddress(event.target.value, who, n))
    },
    clearPreviousAddress: (event, who) => {
        dispatch(act.ClearPreviousAddress(event.target.value, who))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    addSelectedLoanPrepaid: (service, chargename, period) => {
        dispatch(act.AddSelectedLoanPrepaid(service, chargename, period))
    },
    updatePaymentPeriod: (name, service, period) => {
        dispatch(act.UpdatePaymentPeriod(name, service, period))
    },
    updatePaymentAmount: (name, service, amount) => {
        dispatch(act.UpdatePaymentAmount(name, service, amount))
    },
    updatePrepaidDefault: (name, service, period, prepaidperiods, amount) => {
        dispatch(act.UpdatePrepaidDefault(name, service, period, prepaidperiods, amount))
    },
    addSelectedLoanEstimatedClosingCosts: (cc) => {
        dispatch(act.AddSelectedLoanEstimatedClosingCosts(cc))
    },


});

const colors = ['#003f5c', '#7a5195', '#ef5675', '#ffa600']
let floatize = x => {
    return "$" + com.commaizeFloat(x)
}
let percent = x => {
    return x.toFixed(2) + "%"
}
let lendername = x => {
    return <div style={{ fontWeight: 'bold' }}> {com.capitalize(x)}</div>
}

function getMonthlyPayment(rate, loansize, termmonths) {
    let monthlyrate = rate / 12.0
    let expn = Math.pow(1 + monthlyrate, termmonths)
    return monthlyrate*loansize*expn/(expn - 1)
}
function getClosedInterestPaid(rate, loansize, termmonths, spanmonths) {

    let monthlyrate = rate / 12.0
    let expn = Math.pow(1 + monthlyrate, termmonths)
    let payment = loansize * monthlyrate * expn / (expn - 1)
    payment = (Math.round(payment * 100) / 100)

    let accumulatedinterest = (loansize * monthlyrate - payment) *
        (Math.pow(1 + monthlyrate, spanmonths) - 1) / monthlyrate +
        spanmonths * payment
    return accumulatedinterest
}

class AmortizationChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getSeries = () => {  
        let series = []
        Object.keys(this.props.selection).forEach(key => {
            let s = {}
            s.type = "line"
            let t = ""
            if (this.props.selection[key][0].Rate.Arm == null) {
                t = "Fixed"
            } else {
                t = "ARM" + this.props.selection[key][0].Rate.Arm.fixedperiod + "/" + this.props.selection[key][0].Rate.Arm.adjustmentperiod
            }

            s.name = com.capitalize(this.props.selection[key][0].Lender) + ", " + com.capitalize(this.props.selection[key][0].Rate.Mtype.replace('_', ' ') ) + ", " +  this.props.selection[key][0].Rate.Term + "y " + t
            let data = []
            let termmonths = 12 * this.props.selection[key][0].Rate.Term
            let pmiaccumulated = 0
            for (let i = 1; i <= termmonths; i++) {
                var end = new Date() 
                end.setMonth(end.getMonth() + i);
                
                let interest = getClosedInterestPaid(this.props.selection[key][0].Rate.base_rate / 100.0, this.props.selection[key].loansize, termmonths, i)

                let cumulative = this.props.selection[key][0].Rate.closing_cost + interest

                let total = getMonthlyPayment(this.props.selection[key][0].Rate.base_rate / 100.0, this.props.selection[key].loansize, termmonths) * i
                let paidprincipal = total - interest

                if(this.props.selection[key][0].Rate.FHAMIP != null) {
                    cumulative += i*this.props.selection[key][0].Rate.FHAMIP.monthlyMIP + this.props.selection[key][0].Rate.FHAMIP.upfrontMIP
                }
                if(this.props.selection[key][0].Rate.PMICost) {
                    if( (this.props.selection[key].loansize - paidprincipal)/this.props.appraisal > 0.8 )
                        pmiaccumulated += this.props.selection[key][0].Rate.PMICost
                    else {

                    }
                    cumulative += pmiaccumulated
                }
                data.push( [end, cumulative])
            }

            s.data = data
            series.push(s)

        })
        return series
    }

    dateRange = () => {
        var dates = []

        for (var i = 1; i <= this.props.maxyears*12; i++) {
            var end = new Date()
            end.setMonth(end.getMonth() + i);
            dates.push(end)
        } 
        return dates;
    }
    render() {
        let series = this.getSeries()

        return (
            <div className="app " style={{ color: "black" }}>
                <Chart
                    options={{
                        title: { 
                            text: "Loan expenses (interest + insurance + closing costs)"
                    },
                        chart: {
                            type: "line",
                            toolbar: {
                                show: true,
                             
                            },
                            dropShadow: {
                                enabled: false,
                                color: "#000",
                                top: 2,
                                left: 2,
                                blur: 1,
                                opacity: 0.1,
                            },
                            zoom: {
                                type: 'x',
                                enabled: true,
                                autoScaleYaxis: true
                              },                            
                        },
                        colors,
                        tooltip: {
                            enabled: true,
                            followCursor: false,
                            shared: true,
                            y: {
                                formatter: (value) => {
                                    return "$" + com.commaizeFloat(Math.round(value));
                                },
                            },
                            x: {
                                formatter: function (value) {
                                    let d = new Date(value)
                                    return d.toLocaleDateString('en-us', { year: 'numeric', month: 'long' });
                                },
                            }
                        },
                        legend: {
                            show: false,
                            position: "top",
                        },
                        toolbar: {
                            show: true,
                            autoSelected: 'zoom'
                          },                           
                        stroke: {
                            curve: "straight",
                            width: 2
                        },
    
                        xaxis: {
                            type: "datetime",
                            //categories: this.dateRange(),
                            //tickAmount: 10,
                            /*
                            labels: {
                                datetimeFormatter: {
                                  year: 'yyyy',
                                  month: 'MMM yy',                                  
                                }
                              }                            
                            
                            labels: {
                                formatter: function (value, timestamp, opts) {
                                    return opts.dateFormatter(new Date(timestamp), "yyyy");
                                },
                            }, */
                        },
                        yaxis: {
                            labels: {
                                formatter: (value) => {
                                    return "$" + com.commaizeFloat(Math.round(value));
                                },
                            },
                        },
                    }}
                    series={series}
                //   width="700"
                />
            </div>
        );
    }
}
class Comparison extends Component {

    constructor(props) {
        super(props);
        this.state = {
            properties: [],
            maxyears: 30
        }

    }
    recalculate = () => {
        let props = this.props
        let lenders = [x => x, <div style={{fontWeight: '800'}}>Lender:</div>]
        let properties = []
        let index=0
        Object.keys(props.selection).forEach(key => {
            lenders.push(<div style={{color: colors[index], fontWeight: '800'}}>{com.capitalize(props.selection[key][0].Lender)}</div>)
            index += 1
        })
        properties.push(lenders)

        let loansizes = [floatize, "Loan Size:"]
        Object.keys(props.selection).forEach(key => {
            loansizes.push(props.selection[key].loansize)
        })
        properties.push(loansizes)

        switch (props.application.property.purpose) {
            case "purchase": {
                let downpayment = [floatize, "Downpayment:"]
                Object.keys(props.selection).forEach(key => {
                    downpayment.push(props.selection[key].downpayment)
                })
                properties.push(downpayment)
                break;
            }
            case "refinance": {
                let paydown = [floatize, "Pay down:"]
                Object.keys(props.selection).forEach(key => {
                    paydown.push(props.selection[key].paydown)
                })
                properties.push(paydown)
                break;
            }
            case "cashoutrefinance": {
                let cashout = [floatize, "Cash out:"]
                Object.keys(props.selection).forEach(key => {
                    cashout.push(props.selection[key].cashout)
                })
                properties.push(cashout)

                break;
            }
            default:
                alert("should not see this!")
                break;
        }
        let name = [lendername, "Type:"]
        Object.keys(props.selection).forEach(key => {
            name.push(props.selection[key][0].Rate.Mtype)
        })
        properties.push(name)

        let term = [x => { return x + " years" }, "Term:"]
        Object.keys(props.selection).forEach(key => {
            term.push(props.selection[key][0].Rate.Term)
        })
        properties.push(term)

        let amor = [x => { return x }, "Amortization:"]
        Object.keys(props.selection).forEach(key => {
            if (props.selection[key][0].Rate.Arm == null) {
                amor.push("Fixed")
            } else {
                amor.push("ARM" + props.selection[key][0].Rate.Arm.fixedperiod + "/" + props.selection[key][0].Rate.Arm.adjustmentperiod)
            }
        })
        properties.push(amor)

        let mopayment = [floatize, "Mo. Payment:"]
        Object.keys(props.selection).forEach(key => {
            mopayment.push(props.selection[key][0].Rate.monthly_payment)
        })
        properties.push(mopayment)

        let baserate = [percent, "Base Rate:"]
        Object.keys(props.selection).forEach(key => {
            baserate.push(props.selection[key][0].Rate.base_rate)
        })
        properties.push(baserate)

        let apr = [percent, "APR:"]
        Object.keys(props.selection).forEach(key => {
            apr.push(props.selection[key][0].Rate.APR)
        })
        properties.push(apr)

        let cc = [floatize, "Closing Cost:"]
        Object.keys(props.selection).forEach(key => {
            cc.push(props.selection[key][0].Rate.closing_cost >= 0 ? Math.ceil(props.selection[key][0].Rate.closing_cost) : 0)
        })
        properties.push(cc)

        let lc = [floatize, "Remaining Credits:"]
        Object.keys(props.selection).forEach(key => {
            lc.push(props.selection[key][0].Rate.closing_cost < 0 ? -Math.ceil(props.selection[key][0].Rate.closing_cost) : 0)
        })
        properties.push(lc)

        let cash2close = [floatize, "Cash to close:"]
        switch (props.application.property.purpose) {
            case "purchase": {
                Object.keys(props.selection).forEach(key => {
                    let c2c = props.selection[key].downpayment
                    c2c += (props.selection[key][0].Rate.closing_cost >= 0 ? Math.ceil(props.selection[key][0].Rate.closing_cost) : 0)
                    // add FHA stuff!                    
                    cash2close.push(c2c)
                })
                properties.push(cash2close)
                break;
            }
            case "refinance": {
                Object.keys(props.selection).forEach(key => {
                    let c2c = props.selection[key].paydown
                    c2c += (props.selection[key][0].Rate.closing_cost >= 0 ? Math.ceil(props.selection[key][0].Rate.closing_cost) : 0)
                    // add FHA stuff!                    
                    cash2close.push(c2c)
                })
                properties.push(cash2close)
                break;
            }
            case "cashoutrefinance": {
                Object.keys(props.selection).forEach(key => {
                    let c2c = Math.min(0, props.selection[key][0].Rate.closing_cost)
                    // add FHA stuff!                    
                    cash2close.push(c2c)
                })
                // skip this for cashout for now
                //        this.state.properties.push(cash2close)      
                break;
            }
            default:
                alert("should not see this!")
                break;
        }


        let tc = [floatize, "Total Cost Over Term:"]
        Object.keys(props.selection).forEach(key => {
            let c = Math.ceil(props.selection[key][0].Rate.closing_cost +
                com.getClosedInterestPaid(0.01 * props.selection[key][0].Rate.base_rate, props.selection[key].loansize,
                    props.selection[key][0].Rate.Term, props.selection[key][0].Rate.Term))

            tc.push(c)
        })
        properties.push(tc)

        let yearstohold = parseInt(this.props.application.keepinghousefor)
        let llc = [floatize, "Cost over " + yearstohold + " years :"]
        Object.keys(props.selection).forEach(key => {
            let c = Math.ceil(props.selection[key][0].Rate.closing_cost +
                com.getClosedInterestPaid(0.01 * props.selection[key][0].Rate.base_rate, props.selection[key].loansize,
                    props.selection[key][0].Rate.Term, Math.min(yearstohold, props.selection[key][0].Rate.Term)))

            llc.push(c)
        })
        properties.push(llc)

        let mm = [floatize, "Reserves required:"]
        Object.keys(props.selection).forEach(key => {
            mm.push(props.selection[key][0].Rate.min_months_reserves * props.selection[key][0].Rate.monthly_payment)
        })
        properties.push(mm)
        let haspmi = false
        let pm = [x => { return x }, "Needs PMI?"]
        Object.keys(props.selection).forEach(key => {
            pm.push(props.selection[key][0].Rate.PMICost === 0 ? "no" : "yes")
            if(props.selection[key][0].Rate.PMICost !== 0) {  
                haspmi = true
              }
        })
        properties.push(pm)

        if(haspmi) {
            let pm = [floatize, "Mortgage insurance:"]
            Object.keys(props.selection).forEach(key => {
                pm.push(props.selection[key][0].Rate.PMICost )
            })
            properties.push(pm)

        }
        let hasfha = false
        Object.keys(props.selection).forEach(key => {
            if(props.selection[key][0].Rate.FHAMIP != null ) {
                hasfha = true
            }
        })
        if(hasfha) {
            let prepi = [floatize, "FHA mo. premium:"]
            Object.keys(props.selection).forEach(key => {
                if( props.selection[key][0].Rate.FHAMIP != null )
                    prepi.push(props.selection[key][0].Rate.FHAMIP.monthlyMIP)
                else
                    prepi.push(0)

            })
            properties.push(prepi)            

            let ini = [floatize, "FHA upfront payment:"]
            Object.keys(props.selection).forEach(key => {
                if( props.selection[key][0].Rate.FHAMIP != null )
                    ini.push(props.selection[key][0].Rate.FHAMIP.upfrontMIP)
                else
                    ini.push(0)

            })
            properties.push(ini)                
        }

        let prepi = [floatize, "Prepaid Interest:"]
        Object.keys(props.selection).forEach(key => {
            prepi.push(props.selection[key][0].Rate.prepaid_interest)
        })
        properties.push(prepi)

        let fingerclass = "fas fa-hand-point-right mx-1"
        let choose = [(x) => x, <>Choose this loan <i className={fingerclass}></i><b style={{ color: '#030', fontWeight: 'bold' }}></b></>]
        Object.keys(props.selection).forEach(key => {
            let oncheckbox = (e) => {
                props.callselect(key)
            }
            let ch =
                <Form.Check type="radio"
                    defaultChecked={key === props.application.selection.selectedloan.hash}
                    key={key}
                    name="chooser"
                    onChange={oncheckbox}
                    style={{ textAlign: 'left', paddingRight: '0px', paddingLeft: '26px' }}
                    label=""
                ></Form.Check>

            choose.push(ch)
        })
        properties.push(choose)

        let deselect = [(x) => x, "Remove selection:"]
        Object.keys(props.selection).forEach(key => {
            let click = e => {
                this.props.calldelete(key)
                setTimeout(() => {

                    if (Object.keys(props.selection).length === 0)
                        this.props.hide()
                    this.recalculate()

                }, 200)
            }
            let b = <Button size="sm" variant="link" onClick={click}
                style={{
                    textAlign: 'left', marginTop: '4px', marginBottom: '0px', paddingTop: '0px',
                    paddingBottom: '0px', paddingLeft: '8px'
                }} className='primary-color-pale'>
                <i className="fas fa-trash-alt"></i></Button>
            deselect.push(b)
        })
        properties.push(deselect)

        let maxyears = 0
        Object.keys(props.selection).forEach(key => {
            if(maxyears < props.selection[key][0].Rate.Term)
                maxyears = props.selection[key][0].Rate.Term
        })
        this.setState({ properties, maxyears })
    }
    componentDidMount() {
        this.recalculate()

    }

    componentWillUnmount() { }


    handleSubmit = e => {
        return true
    }

    render() {
        let displayRow = (t, d) => {
            let out = []
            out.push(
                <td style={{ fontWeight: 'bold' }} className="py-1">
                    {d[0]}
                </td>
            )
            for (let i = 1; i < d.length; i++) {
                out.push(
                    <td className="py-1">
                        {t(d[i])}
                    </td>
                )
            }

            return out
        }
        let display = z => {
            let out = []

            for (let i = 0; i < z.length; i++) {
                let cl = ""
                if (i === 0) {
                    cl = "blackongray py-0"
                } else {
                    if (i % 2 !== 0) {
                        cl = "bluishbackground py-0"
                    }
                }
                out.push(
                    <tr className={cl}>{displayRow(z[i][0], z[i].slice(1))}</tr>
                )
            }
            return out
        }
        return (
            <div>
                <div style={{ background: 'white', marginLeft: 'auto', marginRight: 'auto', fontSize: '0.8em' }} className="py-5">
                    <Row>

                        <Col md="auto" style={{ border: 'solid lightgray 1px' }} className="px-0 mx-2">
                            <Table className="p-0 m-0">
                                {
                                    display(this.state.properties)
                                }
                            </Table>
                        </Col>
                        <Col className="mt-3" style={{ minWidth: '400px' }}>
                            <AmortizationChart appraisal={this.props.application.property.appraisal} maxyears={this.state.maxyears} selection={this.props.selection} /></Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Comparison)

