import React, { useState, useEffect } from 'react';

import { ButtonGroup, Button, Alert, Form } from 'react-bootstrap';
import { companyName } from '../../../Common';
import classes from './Communication.module.css';
import { SMSSP_AmazonSNS10DLC } from '../../../State';
import InfoModal from '../../../CommonUIComponents/InfoModal';
import { SMSK_RateLock, SMSK_CustomerTaskNotification } from '../../../State';
import { unCamelCase } from '../../../Common';

const SMS = () => {

  const initial = {
    [SMSK_RateLock]: `{{.CustomerFullName}} ({{.CustomerEmail}}) requested to lock the rate for his selected loan product. Yours ${companyName}.`,
    [SMSK_CustomerTaskNotification]: `Dear {{.CustomerFullName}} your have a request from your loan officer for {{.TaskDescription}}. Sign into your account to complete task. Yours ${companyName}. {{.Host}}/d/{{.Hash}}/{{.CustomerID}}`,
  };

  const [options, setOptions] = useState({ ...initial })
  const [selected, setSelected] = useState('');
  const [buttonStyles, setButtonStyles] = useState({});
  const [provider, setProvider] = useState('');
  const [infoModal, setInfoModal] = useState({ show: false, modalTitle: null, modalBody: null, modalFooter: null });
  const [smsNewValue, setSmsNewValue] = useState('');

  const copyToClipboard = (id) => {
    let ev = document.getElementById(id)
    navigator.clipboard.writeText(ev.textContent)
  }

  useEffect(() => {
    const token = sessionStorage.getItem("ZeitroA")

    fetch('/los/getsmstemplates', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      response.json().then(js => {
        setProvider(js.SMSProvider);
        let templates = JSON.parse(js.SMS);
        let tmp = {};
        for (let key in templates) {
          setButtonStyles(prev => ({ ...prev, [key]: true }));
          tmp[key] = templates[key];
        }

        setOptions(prev => ({ ...prev, ...tmp }));
      })
    })
  }, [])


  const showButton = (key) => {

    const clickHandler = () => {
      setSelected(key);
      setSmsNewValue(options[key]);
    }

    return <Button key={key} variant={buttonStyles[key] ? "success" : "light"} onClick={clickHandler}>{unCamelCase(key)}</Button>
  };

  const smsTextChange = e => {
    setSmsNewValue(e.target.value);
  }

  const hideInfoModal = () => {
    setInfoModal({ show: false, modalTitle: null, modalBody: null, modalFooter: null })
  }

  const submitChange = () => {

    let initialContent = initial[selected];
    let currentContent = smsNewValue;


    if (initialContent === currentContent) {
      let modalTitle = <p><i className={`fas fa-exclamation-triangle ${classes.orange}`} ></i> SMS text has not been changed.</p>
      let modalBody = 'Are you sure you want to proceed without any changes?';
      let modalFooter = <>
        <Button variant="primary" onClick={() => saveTemplate()}>Save</Button>
        <Button variant="danger" onClick={() => hideInfoModal()}>Cancel</Button>
      </>;
      setInfoModal({ show: true, modalTitle, modalBody, modalFooter })
    } else {
      saveTemplate();
    }
  }

  const saveTemplate = () => {
    const token = sessionStorage.getItem("ZeitroA")

    const body = JSON.stringify({
      Key: selected,
      Content: options[selected],
      ClientSide: options[selected].clientSide,
    });

    fetch('/los/updatesmsemplate', {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body,
    }).then(response => {
      if (response.status !== 200) {
        alert('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      } else {
        setButtonStyles(prev => ({ ...prev, [selected]: true }));
        setInfoModal({ show: false });
        alert('SMS template updated successfully');
      }

    })
  }


  return (
    <div className='p-4'>
      <ButtonGroup aria-label="Emails" className={`my-4 ${classes['button-group']}`}>
        {Object.keys(options).map(el => showButton(el))}
      </ButtonGroup>
      <p className={classes.p}>This SMS will be sent by server automatically. You can use templates below to substitute them with real values during execution. Copy the values
        (including dot and curly braces) and paste them in SMS below.</p>
      <div className={classes['flex-row']}>
        {["{{.CustomerFullName}}", "{{.CustomerPhone}}", "{{.CustomerEmail}}", "{{.CoborrowerFullName}}", "{{.LoanOfficerFullName}}", "{{.LoanOfficerPhone}}", "{{.LoanOfficerEmail}}", "{{.LoanOfficerNmls}}"].map(el => {
          return <Alert key={el} id={el} variant='dark'>{el}<Button onClick={() => copyToClipboard(el)} variant="link"><i className=" fa fa-copy " ></i></Button></Alert>
        })}
      </div>
      {selected === SMSK_RateLock && <Alert variant="info">This SMS will be sent to loan officer, to notify them.</Alert>}
      {selected === SMSK_CustomerTaskNotification && <Alert variant="info">{`{{.Host}}/d/{{.Hash}}/{{.CustomerID}} will take customer to the application, don't modify it. Either remove it completely, or leave it as it is.`}</Alert>}
      <Form.Group className="mb-3" controlId="sms">
        <Form.Label>SMS text</Form.Label>
        <Form.Control as="textarea" rows={3} value={smsNewValue} onChange={smsTextChange} />
      </Form.Group>
      {provider === SMSSP_AmazonSNS10DLC && <Alert variant="info">Since your SMS provider is Amazon, you can add text notifying customers that they can get help by replying "HELP" or they can opt out by replying "STOP".</Alert>}
      {selected !== '' &&
        <Button onClick={submitChange}>Submit</Button>
      }
      <InfoModal
        onHide={hideInfoModal}
        show={infoModal.show}
        modalTitle={infoModal.modalTitle}
        modalBody={infoModal.modalBody}
        modalFooter={infoModal.modalFooter}
      />
    </div>
  )
}

export default SMS;