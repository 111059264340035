import * as com from "../../Common.js";
/**
 * Calculates the down payment, loan size, and total based on the sales price and loan-to-value ratio.
 *
 * @param {string | number} salesPrice - The sales price of the property.
 * @param {number} ltv - The loan-to-value ratio in percentage, for example if ltv is 60%, the ltv value here
 * will be 60.
 * @return {{ downPayment: number; loanSize: number; total: number; }} - An object containing the down payment, loan size, and total.
 */
export const calculateDownPayAndLoanSize = (
  salesPrice: string | number,
  ltv: number
): {
  downPayment: number;
  loanSize: number;
  total: number;
} => {
  const total = com.safeParseInt(salesPrice);
  const loanSize = Math.round((total * ltv) / 100);
  const downPayment = total - loanSize;
  return { downPayment, loanSize, total };
};

/**
 * Rearranges a list of products based on the provided property type.
 *
 * This function filters products to only include those with a term of 30 years
 * (unless the property type is 'mobilehome') and a lock day of 30 days. It then
 * combines the product and rate information into a single object and returns
 * the rearranged list of products.
 *
 * @param {Array} products - The list of products to rearrange.
 * @param {string} propertytype - The type of property (e.g. 'mobilehome').
 * @return {Array} The rearranged list of products.
 */
export const getRearrangeProducts = (products, propertytype: string) => {
  const rearranged = [];
  products.forEach((product) => {
    let lender = product.Lender;

    product.ProductRatesCosts.forEach((pr) => {
      let commonproduct = pr.product;
      let ausengine = pr.AUSEngine;

      if (!(propertytype === "mobilehome")) {
        if (commonproduct.Term !== 30) return;
      }
      pr.rateCosts.forEach((rate) => {
        let typ = commonproduct.Arm;
        if (typ === null || typeof typ === "undefined") typ = 0;
        else typ = typ.fixedperiod;
        // filter out non-30 day locks
        if (rate.lockDay !== 30) return;

        let pdkt = {
          Lender: lender,
          ...commonproduct,
          Aus: ausengine,
          ...rate,
        };
        rearranged.push(pdkt);
      });
    });
  });
  return rearranged;
};

/**
 * Returns the best loan option from a rearranged list of loans.
 *
 * The best loan is determined by the lowest APR and closing cost. If no loan meets these criteria,
 * the function returns the first loan with a positive closing cost. If all else fails, it returns the first loan in the list.
 *
 * @param {Array} rearranged - A list of loan objects with APR and closing cost properties.
 * @return {Object} The best loan object from the rearranged list.
 */
export const getBest = (rearranged) => {
  let minAPR = 99.9;
  let maxClosingCost = 10000;
  let loan = null;
  for (let ln of rearranged) {
    if (ln.closing_cost < 0) continue;
    if (ln.closing_cost < maxClosingCost && ln.APR < minAPR) {
      minAPR = ln.APR;
      loan = ln;
    }
  }

  if (loan !== null) {
    return loan;
  }

  for (let ln of rearranged) {
    if (ln.closing_cost > 0) {
      return ln;
    }
  }
  return rearranged[0];
};

export const getProductType = (card: any) => {
  if (card.Arm === null || card.Arm === 0) return "Fixed rate";
  return "ARM " + card.Arm.fixedperiod + "/6";
};
