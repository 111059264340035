import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Redirect, Route } from 'react-router-dom'
import {  connect } from 'react-redux'

const sessionTimeoutMin = 20

export let userRoles = sessionStorage.getItem("ZeitroA") ? JSON.parse(atob(sessionStorage.getItem("ZeitroA").split('.')[1])).roles : []

export function checkAccess(roles) {
    const intersection = userRoles.filter(value => roles.includes(value))
    let access = intersection.length !== 0
    return access
}
export function checkAloneAccess(roles) {
    let intersection = userRoles.filter(value => roles.includes(value))
    let access = intersection.length === userRoles.length
    return access
}

export function checkAccessByToken(token, roles) {
    let ur = []
    ur = JSON.parse(atob(token.split('.')[1])).roles
    const intersection = ur.filter(value => roles.includes(value))
    let access = intersection.length !== 0
    return access
}


export function RouteWrapper({ Component, ...props }) {

    let access = checkAccess(props.roles)

    if (!access) {
        return <Redirect to="/error/401" />
    }

    return (
        <Route
            {...props}
            render={routeProps => <Component {...routeProps} />}
        />
    )
};

export function RBAC({ allowedRoles, children }) {
    let access = checkAccess(allowedRoles)
    return access && children
};
const mapStateToProps = (state) => {

    return {
        subscriptionReducer: state.subscriptionReducer.currentInfo,
        manager: state.manager
    }
}
const mapDispatchToProps = (dispatch) => ({
    updateChangeOpen: (application) => {
        dispatch({
            type: "OPEN",
            data: application,
        })
    },

})
class Auth extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            lastActivity: 0,
            active: false,

        }
        //console.log("Auth created " + JSON.stringify(props))
        this.state.lastActivity = Date.now() / 1000
        this.lastSessionUpdate = Date.now() / 1000
        this.showtimer = null
        window.addEventListener("mousemove", this.onActivity.bind(this), { passive: true })
        window.addEventListener("keydown", this.onActivity.bind(this), { passive: true })
        window.addEventListener("wheel", this.onActivity.bind(this), { passive: true })
        window.addEventListener("DOMMouseScroll", this.onActivity.bind(this), { passive: true })
        window.addEventListener("mouseWheel", this.onActivity.bind(this), { passive: true })
        window.addEventListener("mousedown", this.onActivity.bind(this), { passive: true })
        window.addEventListener("touchstart", this.onActivity.bind(this), { passive: true })
        window.addEventListener("touchmove", this.onActivity.bind(this), { passive: true })
        window.addEventListener("MSPointerDown", this.onActivity.bind(this), { passive: true })
        window.addEventListener("MSPointerMove", this.onActivity.bind(this), { passive: true })
        window.addEventListener("visibilitychange", this.onActivity.bind(this), { passive: true })

    }
    onTimeout = () => {
        //let d = new Date()
        //console.log("in onTimeout, revalidate possible expiration, %s", d.toLocaleString() )
        //console.log("Elapsed: " + (Date.now()/1000 - this.lastSessionUpdate))
        this.revalidate()
    }
    onActivity = (e) => {
        //let d = new Date()
        let now = Date.now() / 1000 // in seconds
        if (now - this.lastSessionUpdate >= 30) {

            this.lastSessionUpdate = now

            this.revalidate()
        }
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.lastActivity = now
        clearTimeout(this.timeoutId)
        // trigger failed revalidation in sessionTimeToLive + 5 sec
        // eslint-disable-next-line react/no-direct-mutation-state
        this.timeoutId = setTimeout(this.onTimeout, 1000 * (sessionTimeoutMin * 60 + 5))

    }
    revalidate = () => {
        if (!this.active)
            return
        let token = window.sessionStorage.getItem("ZeitroA")
        //let d = new Date()
        //console.log("entering revalidate, at: " + d.toLocaleString())
        this.lastSessionUpdate = Date.now() / 1000
        if (token === null) {
            console.log("not authenticated")
            this.props.updateChangeOpen(false)

            window.location.href = "/#home"
            return
        }
        fetch(window.location.origin + "/los/refresh", {
            cache: 'no-cache',
            method: 'POST',
            body: "",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(response => {
            let d = new Date()
            //console.log(JSON.stringify(response))
            if (!response.ok) {
                console.log("Auth fetch error, at: " + d.toLocaleString())
                sessionStorage.removeItem("ZeitroA")
                this.setState({ show: true })
            } else {

                response.json().then(js => {

                    if (js.Status !== "OK") {
                        this.props.updateChangeOpen(false)
                        console.log("Status not OK,  " + JSON.stringify(js) + ", at: " + d.toLocaleString())
                        sessionStorage.removeItem("ZeitroA")
                        console.log("Pop the message")
                        this.showtimer = setTimeout(t => {
                            console.log("Don't wait until popup shows, close the view")
                            window.location.href = "/#home"
                        }, 30000)
                        this.setState({ show: true })
                    } else {
                        let userInfo = JSON.parse(atob(js.Token.split('.')[1]))
                        userRoles = userInfo.roles
                        //console.log("Revalidated ok, at:" + d.toLocaleString())
                        sessionStorage.setItem("ZeitroA", js.Token)
                    }
                })
            }
        }).catch(error => {
            let d = new Date()
            console.log("Revalidate catch error " + error + ", at: " + d.toLocaleString())
        })
    }

    componentDidMount = () => {
        if (this.timeoutId)
            clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(this.onTimeout, 1000 * (sessionTimeoutMin * 60 + 5))
        this.revalidate()

        this.handleShow()
        this.active = true
        //console.log("Auth mounted")
    }
    componentWillUnmount = () => {
        if (this.timeoutId)
            clearTimeout(this.timeoutId)
        this.timeoutId = null
        this.active = false
    }
    onShow = () => {
        this.props.updateChangeOpen(false)

        if (this.showtimer)
            clearTimeout(this.showtimer)
        this.showtimer = null
        setTimeout(t => {
            window.location.href = "/#home"
        }, 5000)
    }
    handleClose = () => {
        this.props.updateChangeOpen(false)
        this.setState({ show: true }); window.location.href = "/#home"
    }
    handleShow = () => this.setState({ show: false });
    render() {
        return (
            <div className="mx-0 px-0">
                <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Session Expired</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>This session timed out because of inactivity</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Auth)
