import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import Swiper from 'react-id-swiper';
import "./CustomerReview.css";
export default function CustomerReview({ reviewList, mode }) {
    const swiperRef = useRef(null)
    const [currentIndex, updateCurrentIndex] = useState(0)
    const [isEnd, setIsEnd] = useState(false)
    const [showNextArrow, setShowNextArrow] = useState(true)    
    const params = {
        on: { 
            slideChange: index => { updateCurrentIndex(index.realIndex); setIsEnd(index.isEnd) },
            paginationRender: () => {
                if (swiperRef.current && swiperRef.current.swiper) {
                    const bullets = swiperRef.current.swiper.pagination.bullets
                    setShowNextArrow(bullets.length > 1)
                }
            }
        },
        centeredSlides: false,
        spaceBetween: 76,
        pagination: {
            el: '.voice-swiper-pagination',
            clickable: true
        },
        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 76
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 46
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 24
          }
        }
    }
    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.update()
        }
    }, [reviewList])
    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slideNext()
        }
      }
    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slidePrev()
        }
    }
    const validReviews = reviewList.filter(item => item.review !== '' && item.firstname !== '');
    return (
        <>
            {
                !reviewList.every(val => val.review ==='' || val.firstname ==='') && (
                    <div className="customer-review">
                        <div>
                                { mode !== 'setup' && <div className='customer-review-title'>Voice from customers</div> }
                                <div className='review-list'>
                                    {currentIndex !== 0 && <div className='prev swiper-btn' onClick={goPrev}><img src='/images/banner-arrow.svg' /></div>}
                                    <Swiper ref={swiperRef} {...params}>
                                        {
                                            validReviews.map((item, reviewIndex) =>
                                                <div className='review-item' key={reviewIndex}>
                                                    <div className='review-content'>
                                                        <div className='star'>
                                                            {
                                                                [...Array(item.star ? item.star : 5)].map((star, index) =>
                                                                    <img src='/images/star.svg' key={index} />
                                                                )
                                                            }
                                                        </div>
                                                        <Tooltip placement="bottom" title={item.review}>
                                                            <div className='content'>{ item.review }</div>
                                                        </Tooltip>
                                                    </div>
                                                    <div className='customer'>{`${item.firstname} ${item.lastname}`}</div>
                                                </div>
                                            )
                                        }
                                    </Swiper>
                                    {showNextArrow && !isEnd && <div className='next swiper-btn' onClick={goNext}><img src='/images/banner-arrow.svg' /></div>}
                                </div>
                                {/* <Row className='more-review'>
                                    <Col className='lebal-box'>
                                        <span className='more-review-lebal'>Learn more from the customer review:</span>
                                    </Col>
                                    <Col style={{display: 'flex'}}>
                                        <div className='google-review'><img src='/images/google.svg' alt='google' /><span>Google Review</span></div>
                                        <div className='yelp-review'><img src='/images/yelp.svg' alt='yelp' /><span>Yelp Review</span></div>
                                    </Col>
                                </Row> */}
                        </div>
                    </div>
                )
            }
        </>
    );
}