import React, { useState, useEffect, useRef } from 'react';

const AUTO_INTERVAL = 200;
const STEP_BUCKETS = [
  [30, 0.05], 
  [70, 0.03], 
  [96, 0.01], 
];

export default function usePercent(percent) {
  const [mockPercent, setMockPercent] = useState(0);
  const mockIntervalRef = useRef();

  const isAuto = percent === 'auto';

  useEffect(() => {
    if (isAuto) {
      setMockPercent(0);

      mockIntervalRef.current = setInterval(() => {
        setMockPercent((prev) => {
          const restPTG = 100 - prev;

          for (let i = 0; i < STEP_BUCKETS.length; i += 1) {
            const [limit, stepPtg] = STEP_BUCKETS[i];

            if (prev <= limit) {
              return prev + restPTG * stepPtg;
            }
          }

          return prev;
        });
      }, AUTO_INTERVAL);
    }

    return () => {
      clearInterval(mockIntervalRef.current);
    };
  }, [isAuto]);

  return isAuto ? mockPercent : percent;
}