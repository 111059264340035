import React, { Component } from "react";
import { connect } from 'react-redux'
import '../App.css';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Dropzone from "./Dropzone";
import "./Upload.css";
import Progress from "./Progress";
const defBanner = `Please drag the documents from your computer, drop them into the circle, and click Upload. All your documents will be validated.`
const loadingBanner = "Please wait for the upload to complete before closing the window."
const loadMore = "Your upload is done. You can drag'n'drop and upload additional files."


const mapStateToProps = (state) => {
  return {
    borrower: state.borrower,
    application: state.application
  }
}

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      banner: defBanner,
      validated: false,
      forwhom: "borrower",
      taskid: "other",
      uploadFor: "lender"
    };
    this.form = React.createRef();
    this.comments = []
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {

    if (this.state.successfullUploaded) {
      this.setState({ files: [], successfullUploaded: false })
    }
    let cleanfiles = []

    files.forEach(f => {
      if (this.state.files.every((x) => x.name !== f.name)) {
        cleanfiles.push(f)
      }
    })
    cleanfiles.forEach(f => this.comments[f.name] = f.name.replaceAll(/\.[a-z]+$/g, ""))
    this.setState(prevState => ({
      files: prevState.files.concat(cleanfiles)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    this.setState({ banner: loadingBanner })
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
      this.setState({ banner: loadMore })
      this.props.uploadSuccess()
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
      this.setState({ banner: defBanner })
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file, file.name);

      let token = window.sessionStorage.getItem("ZeitroA");
      if (token === null) {
        console.log("not authenticated");
        window.location.href = "/#home";
        return;
      }
      req.open("POST", "/los/docupload");
      req.setRequestHeader("Authorization", "Bearer " + token)
      if ("undefined" === typeof this.props.taskid) {
        req.setRequestHeader("X-Comment", this.comments[file.name])
        req.setRequestHeader("X-ForWhom", this.state.forwhom)
        req.setRequestHeader("X-Taskid", this.state.taskid)
        req.setRequestHeader("X-CustomerID", this.props.borrower.id)
        req.setRequestHeader("X-WhoseDocuments", this.state.uploadFor)
        req.setRequestHeader("X-Id", 0)
        req.setRequestHeader("X-LoanID", this.props.loanid)

      } else {
        req.setRequestHeader("X-Comment", this.props.name)
        req.setRequestHeader("X-ForWhom", this.props.forwhom)
        req.setRequestHeader("X-Taskid", this.props.taskid)
        req.setRequestHeader("X-CustomerID", this.props.borrower.id)
        req.setRequestHeader("X-WhoseDocuments", this.state.uploadFor)
        req.setRequestHeader("X-Id", this.props.id)
        req.setRequestHeader("X-LoanID", this.props.loanid)
      }

      req.setRequestHeader("Cache", "no-cache")

      req.send(formData);
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div style={{display: 'flex', alignItems: 'center'}} className="ProgressWrapper p-0 m-0">

          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <Button type="submit" variant="primary" style={{height: 'auto'}}
        >
          Clear
        </Button>
      );
    } else {
      return (
        <Button type="submit" variant="primary" style={{height: 'auto'}}
          disabled={this.state.files.length <= 0 || this.state.uploading}
        >
          Upload
        </Button>
      );
    }
  }
  isValidated() {
    if (!this.form.current.reportValidity()) {
      this.setState({ validated: true })
      return false
    };
    return true;
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.successfullUploaded) {
      this.setState({ files: [], successfullUploaded: false });
      this.setState({ banner: defBanner })      
    } else {
      if (this.form.current.reportValidity() === false) { 
        this.setState({ validated: true })
        return false
      }
      this.setState({ validated: false })
      event.stopPropagation();
      if (this.state.successfullUploaded) {
        this.setState({ files: [], successfullUploaded: false });
        this.setState({ banner: defBanner })
      } else {
        this.uploadFiles()
      }
      return true
    }
  }
  changeForWhom = (e) => {
    this.setState({ forwhom: e.target.value })
  }
  uploadForHandler = (e) => {
    this.setState({uploadFor: e.target.id})
  }
  render() {
    let updateFilename = (name) => {
      return event => {
        return this.comments[name] = event.target.value
      }
    }
    return (
      <div style={{padding: '1.5rem'}} className="m-4">
        <Form ref={this.form} id="personalform" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
          <div className="text-dark mt-1">{this.state.banner}</div>
          <div className="Content px-2">
            <div className="mx-4 dropzone-wrap">
              <Dropzone
                onFilesAdded={this.onFilesAdded}
                disabled={this.state.uploading || this.state.successfullUploaded}
              />
            </div>
            <div className="text-dark d-flex align-left w-100">
              <Container className="mt-4">
                <Row className="w-100 border-bottom border-dark mb-3 pb-2">
                  {
                    (typeof this.props.name === "undefined") ?
                      <Col lg="auto" className="text-left" style={{ width: "330px" }} >
                        Describe Document:
                    </Col> : ""
                  }
                  <Col sm="auto" className="d-flex position-relative p-0 m-0 mr-1 pr-2 pt-1 align-center ">
                  </Col>
                  <Col lg="auto" className="d-block text-nowrap text-left">
                    File Name
                    </Col>
                </Row>

                {this.state.files.map(file => {
                  return (
                    <Row key={file.name} style={{marginBottom: 12}} className="w-100">
                      {(typeof this.props.name === "undefined") ?
                        (
                          <Col lg="auto" className="text-left">
                            <Form.Group controlId={"comment" + file.name} className="text-left" style={{marginBottom: 0}} >
                              <Form.Control isValid={false} size="sm"
                                required
                                type="text"
                                style={{ width: "300px", maxWidth: '100%'}}
                                onChange={updateFilename(file.name)}
                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                defaultValue={this.comments[file.name]}
                              />
                              <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid" >
                                Provide description of the document.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        ) : ""
                      }
                      <Col lg="auto" className="d-flex position-relative p-0 m-0 mr-1 pr-2 pt-1 align-center ">
                        {this.renderProgress(file)}
                      </Col>
                      <Col lg="auto" style={{alignItems: 'center'}} className="d-flex text-left">
                        {(file.name.length > 64) ? file.name.substring(0, 64) + "..." : file.name}
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </div>
          </div>
          <div className="w-100 text-center">{this.renderActions()}</div>
        </Form>
      </div>

    );
  }
}

export default connect(mapStateToProps)(Upload);
