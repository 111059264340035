import React, { useState, useEffect, useRef } from "react";

import InnerHTML from "dangerously-set-html-content";
import { Col, Row, Card, Container } from "react-bootstrap";

const CalcGoogain = (props) => {

   let html = `<script id="zeitrotag" src=http://localhost:3000/api/zeitrotag.js?customerid=zeitro&loid=&pageid=calcgoogain> </script>`


  return (
    <div>

      <div className="mt-5">
        <Container>
        <InnerHTML html={html} />

        </Container>
      </div>
    </div>
  );
};

export default CalcGoogain;
