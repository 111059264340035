import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';

export default function Company({ aboutInfo, fromSetup }) {

    return (
        <>
            {<div className={!fromSetup?"companyContainer":"companyContainerFromSetup"}>
                <div className="index_right">
                    <div className="index_right_title">
                        {aboutInfo.companyWebsite}
                    </div>
                    <div className='index_right_companybanner'>
                        {aboutInfo.banner}
                    </div>
                    <div className="index_right_companyWebsite">
                        {aboutInfo.companyIntroduction}
                    </div>
                    { aboutInfo.companyLink &&<div className="about-navList">
                        <Button className='contact-btn apply-now-btn  about-apply-now-btn' onClick={() => {
                            window.open(aboutInfo.companyLink.includes('http') ? aboutInfo.companyLink : 'https://' + aboutInfo.companyLink, '_blank')

                        }}>View our company</Button>
                    </div>}
                </div>

            </div>}
            {/* {aboutInfo.tagline && <div className='tagline'>{aboutInfo.tagline}</div>} */}
        </>
    );
}