import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

const GMCCDismond = (props) => {
    const src = "https://hub.collateralanalytics.com/correspondentsearch";
    return <div className="p-5 gmccDiamond">
        <div className="title-3">GMCC Diamond Community Lending</div>
        <a className="aAsLink mt-3" target="_blank" href={src}>https://hub.collateralanalytics.com/correspondentsearch</a>
        <div className="title-5 mt-3">GMCC Diamond Community Lending area, subject to change without advance notice. Result are Estimate only.</div>
        {/* <iframe src={iframeSrc} style={{width: '100%'}} title="GMCC Diamond Community Lending" sandbox='allow-scripts allow-modal' loading='eager'></iframe> */}
    </div>
}
export default withRouter(GMCCDismond);
