import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'


import NumericalInput, {PennyInput} from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"

import {FutureExpenses} from '../State'
let addIncome = (period, value) => {
    switch (period) {
        case "monthly": {
            let v = parseFloat(value)
            if (!isNaN(v))
                return v
            break
        }
        case "yearly": {
            let v = parseFloat(value)
            if (!isNaN(v))
                return v / 12
            break
        }
        default:
            break
    }
    return 0
}
const mapStateToProps = (state) => {
    return {
        expenses: state.application.expenses,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event.target.value, who, verb))
    },
    changeMainProperty: (t, verb) => {
        dispatch(act.ChangeMainProperty(t, verb))
    },    
    updateSelectedLoanField: (t, verb)  => {
        dispatch(act.UpdateSelectedLoanField(t, verb))
      },       
    updateApplicationAttribute: (t, verb) => {
        dispatch(act.UpdateApplicationAttribute(t, verb))
    },
});

export default class Proposed extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pitia: 0
        }

        this.props.updateExpenses({target: {value: this.props.application.selection.selectedloan.monthly_payment}}, 
            props.who, "futurefirstmortgage")
    }
    componentDidMount() { 
        if(this.props.application.property.expenses === null) {
            this.loadExpenses()
            
        } else {
            this.getFutureExpenses()
        }
    }
    loadExpenses = () => {
        let token = sessionStorage.getItem("ZeitroA")
        
        fetch('/data/getexpences', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
                "X-Borrower": this.props.borrowerid,
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    alert("Bad response, should not happen")
                    return;
                }
                response.json().then(fees => {
                    let ex = new FutureExpenses();
                    ex.otherfinancing = fees.otherfinancing
                    ex.homeownersinsurance =  fees.hazardinsurance
                    ex.realestatetaxes = fees.realestatetaxes
                    ex.hoa = fees.dues
                    ex.floodinsurance = fees.other
                    this.props.changeMainProperty(ex, "expenses")
                    this.getFutureExpenses()
                })
            }
    
        ).catch((err) => {
    
            this.setState({ loading: false })
            alert("Network error")
        });        
    }    
    handleSubmit = (e) => {
        if(this.props.application.selection.selectedloan === null || this.props.application.selection.selectedloan.loansize === 0) {
            alert("Loan product is not selected yet by the customer!")
            return false
        }
        return true
    }
    title = () => {

        if (this.props.application.hascoborrower !== "alone" && this.props.application.cohabiting) {
            return (<h2>
                {this.props.application.borrower.firstname} {this.props.application.borrower.lastname} and&nbsp;
                 {this.props.application.coborrower.firstname} {this.props.application.coborrower.lastname}'s proposed housing expenses</h2>
            )
        }
        return (
            <h2>
                {this.props.application[this.props.who].firstname} {this.props.application[this.props.who].lastname}'s proposed
                housing expenses</h2>

        )
    }
    getFutureExpenses = () => {
        let ret = 0

        ret += parseFloat(this.props.application.selection.selectedloan.monthly_payment)
        ret += addIncome( this.props.application.property.expenses.otherfinancingperiod, parseFloat(this.props.application.property.expenses.otherfinancing) )

        ret += addIncome(this.props.application.property.expenses.homeownersinsuranceperiod , 
            this.props.application.property.expenses.homeownersinsurance)

        ret += addIncome(this.props.application.property.expenses.realestatetaxesperiod , 
            this.props.application.property.expenses.realestatetaxes)

        ret += addIncome(this.props.application.property.expenses.hoaperiod , 
            this.props.application.property.expenses.hoa)

        ret += addIncome(this.props.application.property.expenses.otherperiod , 
            this.props.application.property.expenses.other)

        ret += addIncome(this.props.application.property.expenses.floodinsuranceperiod , 
            this.props.application.property.expenses.floodinsurance)

        this.setState({pitia: ret}) 
        
    }       
    getSnapshotBeforeUpdate = () => {
        setTimeout(() => {this.getFutureExpenses()}, 200)
    }
    render() {
        let updateExpenses = ( verb) => {
            return (event) => {                 
                this.props.changeMainProperty(event.target.value, "expenses|"+verb)
                this.getFutureExpenses()

            }
        }
        let  showMortgageInsurance = () => {
            
            let selection = this.props.application.selection.selectedloan
            if(selection.pmi !== 0) {
                return selection.pmi.toFixed(2)
            } else {
                if(selection.FHAMIP !== null ) {
                    if(selection.FHAMIP.monthlyMIP === "" || selection.FHAMIP.monthlyMIP == null)
                        return "0"
                    return selection.FHAMIP.monthlyMIP.toFixed(2)
                }
            }            
            return "0"
        }
        let updateMortgageInsurance = e => {
            let val = e.target.value
            
            let selection = this.props.application.selection.selectedloan
            if(null === selection) {
                return 0
            }
            if(selection.pmi !== 0) {
                this.props.updateSelectedLoanField(val, "pmi")
            } else {
                if(selection.FHAMIP !== null ) {
                    let FHAMIP = selection.FHAMIP
                    FHAMIP.monthlyMIP = parseFloat(val)
                    this.props.updateSelectedLoanField(FHAMIP, "FHAMIP")
                }
            }
            this.getFutureExpenses()
            return 0
        }

        let options = () => {
            let out;
            let interview = [];
            let origination = [];
            let underwriting = [];
            let closing = [];
            let paused = [];
            let other = [];
            let printOptions = (o, index) => {
                if(['interviewstarted', 'productselected', 'interviewfinished'].includes(o)){
                    interview.push(<option key={o+index} value={o}>{com.splitStatusByWords(o)}</option>)
                } else if(['documentationrequested', 'loansubmitted'].includes(o)){
                    origination.push(<option key={o+index} value={o}>{com.splitStatusByWords(o)}</option>)
                } else if(['conditionallyapproved', 'loanafinalapproved', 'submittedtoescrow'].includes(o)){
                    underwriting.push(<option key={o+index} value={o}>{com.splitStatusByWords(o)}</option>)
                } else if(['finaldocssigned', 'fundsreleased'].includes(o)){
                    closing.push(<option key={o+index} value={o}>{com.splitStatusByWords(o)}</option>)
                } else if(['applicationwithdrawn', 'applicationabandoned','aplicationrejected', "borrowerwithdrew", "loancanceled", "loandenied"].includes(o)){
                    paused.push(<option key={o+index} value={o}>{com.splitStatusByWords(o)}</option>)
                } else {
                    other.push(<option key={o+index} value={o}>{com.splitStatusByWords(o)}</option>)
                }
            }

            com.loanstatuses.map(printOptions)
            
            out = <>
                <optgroup label='Interview'>
                    {interview.map(el => el)}
                </optgroup>
                <optgroup label='Origination'>
                    {origination.map(el => el)}
                </optgroup>
                <optgroup label='Underwriting'>
                    {underwriting.map(el => el)}
                </optgroup>
                <optgroup label='Closing'>
                    {closing.map(el => el)}
                </optgroup>
                <optgroup label='Paused'>
                    {paused.map(el => el)}
                </optgroup>
                {other.length !== 0 &&
                    <optgroup label='Other'>
                        {other.map(el => el)}
                    </optgroup>
                }
            </>
            return out
        }
        let changeStatus = e => {
            this.props.updateApplicationAttribute(e.target.value, "status")
        }


        let getPastExpenses = () => {
            let ret = 0
            if(this.props.application[this.props.who].presentaddressownership === "rent") {
                return 0
            }
            ret += addIncome(this.props.application[this.props.who].expenses.currentfirstmortgageperiod , 
                this.props.application[this.props.who].expenses.currentfirstmortgage)
            ret += addIncome(this.props.application[this.props.who].expenses.otherfinancingperiod , 
                this.props.application[this.props.who].expenses.otherfinancing)
            ret += addIncome(this.props.application[this.props.who].expenses.currenthazardinsuranceperiod , 
                this.props.application[this.props.who].expenses.currenthazardinsurance)
            ret += addIncome(this.props.application[this.props.who].expenses.currentrealestatetaxesperiod , 
                this.props.application[this.props.who].expenses.currentrealestatetaxes)
            ret += addIncome(this.props.application[this.props.who].expenses.hoaperiod , 
                this.props.application[this.props.who].expenses.hoa)
            ret += addIncome(this.props.application[this.props.who].expenses.currentotherperiod , 
                this.props.application[this.props.who].expenses.currentother)
            ret += addIncome(this.props.application[this.props.who].expenses.floodinsuranceperiod , 
                this.props.application[this.props.who].expenses.floodinsurance)
            return ret
        }
 
        return (
            <div className="mx-5  mb-4 text-left border-dark ">
                {this.title()}
                <div className="mx-5text-center mt-4 border-top border-dark" >

                    <Table style={{tableLayout: 'auto'}} className="align-middle m-0 p-1 border-dark " >
                        <thead >
                            <tr className="m-1 p-1 sm" >
                                <th></th>
                                <th className="sm m-1 p-1" > {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                "Your Housing Expenses" : ""}</th>
                                <th className="sm  m-1 p-1" ></th>
                                <th className="sm  m-1 p-1" >Current Amounts</th>
                                <th className="sm  m-1 p-1" ></th>
                                <th className="sm  m-1 p-1" >Future Amounts</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>&nbsp;</td>
                                <td className="m-0 px-2 py-0 align-middle">First Mortgage (Principal and Interest) </td>

                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    this.props.application[this.props.who].expenses.currentfirstmortgageperiod : ""}
                         
                                </td>                                
                                <td className="m-0 px-2 py-0 align-middle">
                                  
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    "$" + com.commaize(this.props.application[this.props.who].expenses.currentfirstmortgage) : "" }
                                </td>

                                <td className="sm m-0 px-4 py-0 align-middle" >
                                    Monthly
                                </td>                                
                                <td className="m-0 px-4 py-0"> 
                                ${com.commaizeFloat(parseFloat(this.props.application.selection.selectedloan.monthly_payment))}              
                                </td>
                                <td>&nbsp;</td>

                            </tr>
                            <tr className="m-0 p-0" >
                                <td>&nbsp;</td>

                                <td className="m-0 px-2 py-0 align-middle">Other Financing (Principal and Interest)  </td>

                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    this.props.application[this.props.who].expenses.currentotherfinancingperiod : ""}
                         
                                </td>                                
                                <td className="m-0 px-2 py-0 align-middle">
                                  
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    "$" + com.commaize(this.props.application[this.props.who].expenses.currentotherfinancing): "" }
                                </td>                                
                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    <Form.Group controlId="otherfinancingperiod" className="text-left   m-2 p-1" >
                                        <Form.Control as="select" size="sm"
                                            //defaultValue={this.props.firsttime}
                                            type="text"
                                            value={this.props.application.property.expenses !== null ? 
                                                this.props.application.property.expenses.otherfinancingperiod : "monthly"}
                                            onChange={updateExpenses("otherfinancingperiod")}

                                        >
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                        </Form.Control>
                                    </Form.Group>                                    
                                </td>                                
                                <td className="m-0 px-2 py-0">
                                    <Form.Group controlId="Other" className="text-left  m-2 p-1" >
                                        <NumericalInput
                                             size="sm"
                                            name="other"
                                            defaultValue={this.props.application.property.expenses !== null ?
                                                this.props.application.property.expenses.otherfinancing : "" }
                                            onChange={updateExpenses("otherfinancing")}
                                            
                                            min={0}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide your estimated liabilities.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>&nbsp;</td>

                            </tr>
                            <tr  >
                                <td>&nbsp;</td>

                                <td className=" m-0 px-2 py-0  align-middle">Homeowner's Insurance </td>

                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    this.props.application[this.props.who].expenses.currenthazardinsuranceperiod : ""}
                         
                                </td>                                
                                <td className="m-0 px-2 py-0 align-middle">
                                  
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    "$" + com.commaize(this.props.application[this.props.who].expenses.currenthazardinsurance): "" }
                                </td>                                         
                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    <Form.Group controlId="firsttime" className="text-left  m-2 p-1" >
                                        <Form.Control as="select" size="sm"
                                            type="text"
                                            value={this.props.application.property.expenses !== null ? 
                                                this.props.application.property.expenses.homeownersinsuranceperiod : "monthly"}
                                            onChange={updateExpenses("homeownersinsuranceperiod")}

                                        >
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                        </Form.Control>
                                    </Form.Group>                                    
                                </td>                                
                                <td className=" m-0 px-2 py-0">
                                    <Form.Group controlId="Hazard" className="text-left  m-2 p-1" >
                                        <NumericalInput
                                         size="sm"
                                            required={this.props.application[this.props.who].presentaddressownership !== "rent"}
                                            name="hazard"
                                            defaultValue={this.props.application.property.expenses !== null ? 
                                                this.props.application.property.expenses.homeownersinsurance : ""}
                                            onChange={updateExpenses("homeownersinsurance")}
                                            min={0}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide your homeowner's insurance.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr  >
                                <td>&nbsp;</td>

                                <td className=" m-0 px-2 py-0  align-middle">Mortgage Insurance </td>

                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    this.props.application[this.props.who].expenses.currentmortgageinsuranceperiod : ""}
                         
                                </td>                                
                                <td className="m-0 px-2 py-0 align-middle">
                                  
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    "$" + com.commaize(this.props.application[this.props.who].expenses.currentmortgageinsurance): "" }
                                </td>   

                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    <Form.Group controlId="firsttime" className="text-left  m-2 p-1" >
                                                Monthly
                                    </Form.Group>                                    
                                </td>                                
                                <td className=" m-0 px-2 py-0">
                                    <Form.Group controlId="Mortgage" className="text-left  m-2 p-1" >
                                        {this.props.application.selection.selectedloan.pmi === 0 && this.props.application.selection.selectedloan.FHAMIP === null ? 0 :
                                    <PennyInput
                                            required
                                            size="sm"
                                            name="other"
                                            defaultValue={this.props.application.selection.selectedloan !== null ? 
                                                showMortgageInsurance() : "0" }
                                            onChange={updateMortgageInsurance}                                                
                                            min={0}
                                        />
                                       }
                                    </Form.Group>
                                </td>
                                <td>&nbsp;</td>
                            </tr>                       
                            <tr >
                                <td>&nbsp;</td>

                                <td className="m-0 px-2 py-0  align-middle">Real Estate Taxes  </td>
                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    this.props.application[this.props.who].expenses.currentrealestatetaxesperiod : ""}
                         
                                </td>                                
                                <td className="m-0 px-2 py-0 align-middle">
                                  
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    "$" + com.commaize(this.props.application[this.props.who].expenses.currentrealestatetaxes): "" }
                                </td>   

                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    <Form.Group controlId="firsttime" className="text-left  m-2 p-1" >
                                        <Form.Control as="select" size="sm"
                                            //defaultValue={this.props.firsttime}
                                            type="text"
                                            value={this.props.application.property.expenses !== null ? 
                                                this.props.application.property.expenses.realestatetaxesperiod : "monthly"}
                                            onChange={updateExpenses( "realestatetaxesperiod")}

                                        >
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                        </Form.Control>
                                    </Form.Group>                                    
                                </td>                                
                                <td className="m-0 px-2 py-0">
                                    <Form.Group controlId="Taxes" className="text-left m-2 p-1" >
                                            <NumericalInput
                                             size="sm"
                                                name="taxes"
                                                defaultValue={this.props.application.property.expenses == null ? "" : this.props.application.property.expenses.realestatetaxes}
                                                onChange={updateExpenses( "realestatetaxes")}                                                
                                                min={0}
                                            />

                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide your estimated liabilities.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>&nbsp;</td>

                            </tr>
                            <tr >
                                <td>&nbsp;</td>
                                <td className="m-0 px-2 py-0  align-middle">Homeowner Association Dues  </td>
                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    this.props.application[this.props.who].expenses.currentduesperiod : ""}
                         
                                </td>                                
                                <td className="m-0 px-2 py-0 align-middle">
                                  
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    "$" + com.commaize(this.props.application[this.props.who].expenses.currentdues): "" }
                                </td>   

                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    <Form.Group controlId="firsttime" className="text-left  m-2 p-1" >
                                        <Form.Control as="select" size="sm"
                                            type="text"
                                            value={this.props.application.property.expenses !== null ? 
                                                this.props.application.property.expenses.hoaperiod : "monthly"}
                                            onChange={updateExpenses("hoaperiod")}
                                        >
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                        </Form.Control>
                                    </Form.Group>                                    
                                </td>                                
                                <td className="m-0 px-2 py-0">
                                    <Form.Group controlId="Dues" className="text-left m-2 p-1" >
                                            <NumericalInput
                                                 size="sm"
                                                name="dues"
                                                defaultValue={this.props.application.property.expenses !== null ? 
                                                    this.props.application.property.expenses.hoa : ""}
                                                onChange={updateExpenses("hoa")}
                                                min={0}
                                            />

                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide your estimated liabilities.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr >
                                <td>&nbsp;</td>
                                <td className="m-0 px-2 py-0  align-middle">Flood Insurance</td>
                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    this.props.application[this.props.who].expenses.currentotherperiod : ""}
                         
                                </td>                                
                                <td className="m-0 px-2 py-0 align-middle">
                                  
                                    {this.props.application[this.props.who].presentaddressownership !== "rent" ? 
                                    "$" + com.commaize(this.props.application[this.props.who].expenses.currentother): "" }
                                </td>   

                                <td className="sm m-0 px-2 py-0 align-middle" >
                                    <Form.Group controlId="firsttime" className="text-left  m-2 p-1" >
                                        <Form.Control as="select" size="sm"
                                            type="text"
                                            value={this.props.application.property.expenses !== null ? 
                                                this.props.application.property.expenses.floodinsuranceperiod : "monthly" }
                                            onChange={updateExpenses("floodinsuranceperiod")}
                                    >
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                        </Form.Control>
                                    </Form.Group>                                    
                                </td>                                
                                <td className="m-0 px-2 py-0">
                                    <Form.Group controlId="Other" className="text-left m-2 p-1" >

                                        <NumericalInput
                                            size="sm"
                                            name="other"
                                            defaultValue={this.props.application.property.expenses !== null ? 
                                                this.props.application.property.expenses.floodinsurance : "monthly" }
                                            onChange={updateExpenses("floodinsurance")}                                                
                                            min={0}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide your estimated liabilities.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>&nbsp;</td>
                            </tr>             
                        <tr >
                           <td className="border-top border-dark lightblue">&nbsp;</td>
                           <td className="border-top border-dark lightblue">&nbsp;</td>
                           <td className="border-top border-dark lightblue">&nbsp;</td>
                           <td className="border-top border-dark lightblue">${getPastExpenses()}</td>
                           <td className="border-top border-dark lightblue">&nbsp;</td>
                           <td className="border-top border-dark lightblue">${this.state.pitia}</td>
                           <td className="border-top border-dark lightblue">&nbsp;</td>
                        
                        </tr>
                        </tbody>
                    </Table>
                    <br />
                    <Form.Group controlId="status" >
                        <Form.Label className="text-left" >Customer Status:</Form.Label>
                        <Form.Control name="status" as="select" size="sm"
                          defaultValue={this.props.application.status}
                            onChange={changeStatus}
                        >
                            {options()}
                        </Form.Control>
                      </Form.Group>

                    {/*
                    <div className="mt-3 mb-5 text-center">
                        <Button onClick={this.copy}> Copy =&gt;</Button>
                        </div>
                    */}
                </div>
            </div >
        )
    }
}

Proposed = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true } )(Proposed)
