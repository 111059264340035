import React, { Component } from 'react';
import '../App.css';
import * as com from '../Common'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { Container, Row, Col, Button } from 'react-bootstrap'


const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;


const purchase = { color: 'white', backgroundColor: '#468565', text: 'purchase' }
const refinance = { color: 'white', backgroundColor: '#445c83', text: 'refinance' }
const jumboPurchase = { color: 'white', backgroundColor: '#36674e', text: 'jumbo purchase' }
const jumboRefinance = { color: 'white', backgroundColor: '#2f405b', text: 'jumbo refinance' }
const FHA = { color: 'white', backgroundColor: '#8F7034', text: 'FHA' }
const VA = { color: 'white', backgroundColor: '#CA5010', text: 'VA' }
const USDA = { color: 'white', backgroundColor: '#A4262C', text: 'USDA' }

class PipelineInner extends Component {

  constructor(props) {
    super(props);
    this.headerStyle = { width: '100px' }

    this.dateTimeSortFunction = (a, b, order, dataField) => {
      if (a === '') {
        a = '01/01/1000'
      }
      if (b === '') {
        b = '01/01/1000'
      }
      let at = new Date(a)
      let bt = new Date(b)
      let ret = (at.getTime() - bt.getTime()) < 0 ? -1 : 1
      ret = order === 'asc' ? ret : ret * -1;
      return ret
    }

    this.dateSortFunction = (a, b, order, dataField) => {
      if (a === '') {
        a = '01/01/1000'
      }
      if (b === '') {
        b = '01/01/1000'
      }
      if (a === '01/01/1000' && b === '01/01/1000') {
        b = '01/02/1000'
      }

      let A = a.split('/')
      let aMonth = A[0]
      let aDate = A[1]
      let aYear = A[2]

      let B = b.split('/')
      let bMonth = B[0]
      let bDate = B[1]
      let bYear = B[2]

      A = parseInt(aYear + aMonth + aDate)
      B = parseInt(bYear + bMonth + bDate)

      if (order === 'asc') {
        return (A - B) < 0 ? -1 : 1
      } else {
        return (A - B) < 0 ? 1 : -1
      }
    }

    this.state = {
      borrowers: [],
      finished: [],
      dropped: [],
      showFilters: false,
      borrowersColumns: [
        {
          dataField: 'customerID',
          text: 'CustomerID',
          hidden: true,
          csvExport: false
        }, {
          dataField: 'borrowersName',
          text: "Borrower's name",
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            let A = a.split(' ')
            let aFirst = A[0]
            let aLast = A[1]

            let B = b.split(' ')
            let bFirst = B[0]
            let bLast = B[1]
            if (order === 'asc') {
              if (bLast < aLast) {
                return -1
              } else if (bLast > aLast) {
                return 1
              } else if (bLast === aLast) {
                if (bFirst < aFirst) {
                  return -1
                } else if (bFirst > aFirst) {
                  return 1
                }
              }
            } else {
              if (bLast < aLast) {
                return 1
              } else if (bLast > aLast) {
                return -1
              } else if (bLast === aLast) {
                if (bFirst < aFirst) {
                  return 1
                } else if (bFirst > aFirst) {
                  return -1
                }
              }
            }

          },
          editable: false,
          headerStyle: this.headerStyle,
          style: (cell, row) => {
            let backgroundColor
            let color
            if (row.typeOfMortgage === "conventional" & row.loanPurpose === "purchase" & row.loanSize <= 548250) {
              backgroundColor = purchase.backgroundColor
              color = purchase.color
            } else if (row.typeOfMortgage === "conventional" &
              (row.loanPurpose === "refinance" || row.loanPurpose === "cashoutrefinance" || row.loanPurpose === "improvement") &
              row.loanSize <= 548250) {
              backgroundColor = refinance.backgroundColor
              color = refinance.color
            } else if (row.typeOfMortgage === "conventional" & row.loanPurpose === "refinance" & row.loanSize > 548250) {
              backgroundColor = jumboRefinance.backgroundColor
              color = jumboRefinance.color

            } else if (row.typeOfMortgage === "conventional" & row.loanPurpose === "purchase" & row.loanSize > 548250) {
              backgroundColor = jumboPurchase.backgroundColor
              color = jumboPurchase.color
            } else if (row.typeOfMortgage === "va") {
              backgroundColor = VA.backgroundColor
              color = VA.color
            } else if (row.typeOfMortgage === "fha") {
              backgroundColor = FHA.backgroundColor
              color = FHA.color
            } else if (row.typeOfMortgage === "usda") {
              backgroundColor = USDA.backgroundColor
              color = USDA.color
            } else {
              backgroundColor = 'white'
              color = 'black'
            }
            return {
              overflowY: 'auto',
              backgroundColor,
              color,
              //position: '-webkit-sticky',
              position: 'sticky',
              left: 0,
              top: 0
            };
          },
          formatter: (cell, row) => {
            return <span>{cell}</span>
          }
        }, {
          dataField: 'accountCreated',
          text: "Account created",
          editable: false,
          sort: true,
          sortFunc: this.dateSortFunction,
          headerStyle: this.headerStyle,
          hidden: !this.props.pipelineConfiguration['accountCreated']
        }, {
          dataField: 'lastTouch',
          text: "Last change",
          editable: false,
          sort: true,
          sortFunc: this.dateSortFunction,
          headerStyle: this.headerStyle,
          hidden: !this.props.pipelineConfiguration['accountCreated']
        }, {
          dataField: 'stageReached',
          text: "Interview Stage",
          style: { overflowX: 'scroll' },
          headerStyle: this.headerStyle,
          editable: false,
          sort: false,
          hidden: !this.props.pipelineConfiguration['accountCreated']
        }, {
          dataField: 'lender',
          text: "Lender",
          editable: false,
          sort: true,
          headerStyle: this.headerStyle,
          style: { overflowY: 'auto' },
          hidden: !this.props.pipelineConfiguration['lender']
        }, {
          dataField: 'interviewSubmission',
          text: "Date of interview submission",
          sort: true,
          sortFunc: this.dateTimeSortFunction,
          editable: false,
          headerStyle: this.headerStyle,
          hidden: !this.props.pipelineConfiguration['interviewSubmission']
        }, {
          dataField: 'lockRequested',
          text: "Lock Requested",
          sort: true,
          sortFunc: this.dateTimeSortFunction,
          editable: false,
          headerStyle: this.headerStyle,
          style: { color: 'blue', fontWeight: 'bolder' },
          hidden: !this.props.pipelineConfiguration['lockRequested']
        }, {
          dataField: 'openedEscrow',
          text: "Opened escrow",
          sort: true,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          editable: false,
          hidden: !this.props.pipelineConfiguration['openedEscrow']
        }, {
          dataField: 'estimatedClosingDate',
          text: "Estimated closing date",
          sort: true,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          editable: false,
          hidden: !this.props.pipelineConfiguration['estimatedClosingDate']
        }, {
          dataField: 'loanFunded',
          text: "Loan funded",
          sort: true,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          hidden: !this.props.pipelineConfiguration['loanFunded'],
          editable: false
        }, {
          dataField: 'loanAmount',
          text: "Loan amount",
          sort: true,
          headerStyle: this.headerStyle,
          editable: false,
          hidden: !this.props.pipelineConfiguration['loanAmount']
        }, {
          dataField: 'totalBrokerCompensation',
          text: "Total broker compensation",
          sort: true,
          headerStyle: this.headerStyle,
          editable: false,
          hidden: !this.props.pipelineConfiguration['totalBrokerCompensation'],
          formatter: (cell, row) => {
            return row.loanAmount && <span>{Number.parseFloat(row.loanAmount / 100 + 500).toFixed(2)}</span>
          }
        }, {
          dataField: 'estimatedLoanAmount',
          text: "Estimated loan amount",
          sort: true,
          headerStyle: this.headerStyle,
          editable: false,
          hidden: !this.props.pipelineConfiguration['estimatedLoanAmount']
        }, {
          dataField: 'Currentrate',
          text: "Current rate",
          sort: true,
          headerStyle: this.headerStyle,
          editable: false,
          hidden: !this.props.pipelineConfiguration['Currentrate']
        }, {
          dataField: 'NewRate',
          text: "New rate",
          sort: true,
          headerStyle: this.headerStyle,
          editable: false,
          hidden: !this.props.pipelineConfiguration['NewRate']
        }]
    }
  }

  componentDidMount = () => {
    this.reload()
  }

  transformDateRepresentation = (dateString) => {
    let date_ = new Date(dateString)
    let month = date_.getMonth() + 1
    if (month < 10) {
      month = '0' + month
    }
    let date = date_.getDate()
    if (date < 10) {
      date = '0' + date
    }
    return `${month}/${date}/${date_.getFullYear()}`
  }

  reload = () => {

    let transform = (borrower) => {
      let lender = ''
      let openedEscrow = ''
      let loanFunded = ''
      let interviewSubmission = ''
      let loanAmount = ''
      let totalBrokerCompensation = ''
      let lockRequested = ''
      let estimatedClosingDate = ''
      let estimatedLoanAmount = ''
      let Currentrate = ''
      let NewRate = ''

      try {
        let pipe = JSON.parse(borrower.PipelineJSON)
        openedEscrow = pipe.openedEscrow || ''
        loanFunded = pipe.loanFunded || ''
        interviewSubmission = pipe.interviewSubmission || ''
        loanAmount = pipe.loanAmount || ''
        totalBrokerCompensation = pipe.totalBrokerCompensation || ''
        estimatedClosingDate = pipe.estimatedClosingDate || ''
        lockRequested = pipe.lockRequested || ''
        estimatedLoanAmount = borrower.LoanAmount
        Currentrate = borrower.Currentrate
        NewRate = borrower.NewRate

      } catch (e) {
        // nothing
      }

      if (borrower.Lender !== '' && borrower.Lender !== '[]') {
        let tmp = JSON.parse(borrower.Lender)
        if (tmp.length > 1) {
          lender = tmp.map(l => l.Lender).join(', ')
        } else {
          lender = tmp[0].Lender
        }
      }


      let AccountCreated = this.transformDateRepresentation(borrower.AccountCreated)

      let lockRequestedDate
      if (lockRequested !== '') {
        lockRequestedDate = new Date(lockRequested)
        lockRequested = lockRequestedDate.toLocaleString()
      }

      let interviewSubmissionDate
      if (interviewSubmission !== '') {
        interviewSubmissionDate = new Date(interviewSubmission)
        interviewSubmission = interviewSubmissionDate.toLocaleString()
      }

      return {
        customerID: borrower.ID, borrowersName: borrower.FirstName + " " + borrower.LastName,
        accountCreated: AccountCreated, loanPurpose: borrower.LoanPurpose,
        typeOfMortgage: borrower.TypeOfMortgage, loanSize: borrower.LoanSize,
        interviewSubmission, lender, openedEscrow, loanAmount, totalBrokerCompensation,
        estimatedClosingDate, lockRequested, loanFunded, estimatedLoanAmount, Currentrate, NewRate,
        lastTouch: borrower.LastTouch, stageReached: com.interviewStepToSubstatus(parseInt(borrower.ReachedStage))
      }
    }

    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/managementpipeline', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            js.Loans.forEach(l => console.log(l.ID))
            let finished = js.Loans.filter(l => l.LoanStatus === 'fundsreleased')
            let current = js.Loans.filter(l => ['interviewstarted', 'productselected', 'interviewfinished', 'documentationrequested', 'loansubmitted', 'conditionallyapproved', 'loanafinalapproved', 'submittedtoescrow', 'finaldocssigned'].includes(l.LoanStatus))
            let dropped = js.Loans.filter(l => ['applicationwithdrawn', 'applicationabandoned', 'aplicationrejected', 'borrowerwithdrew', 'loancanceled', 'loandenied'].includes(l.LoanStatus))
            let docs = current.map(s => transform(s))
            let docs2 = finished.map(s => transform(s))
            let docs3 = dropped.map(s => transform(s))
            console.log(docs3)
            this.setState({ borrowers: docs, finished: docs2, dropped: docs3 })
          }
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });

  }

  columnToggle = (df) => {
    this.state.borrowersColumns.map((val) => {
      if (val.dataField === df) {
        let newValue = !val.hidden
        val.hidden = newValue
        this.props.toogleColumn(df, !newValue)
      }
      return val;
    });
  }

  showHideAllColumns = (showHide) => {
    let newValue
    if (showHide === 'hide') {
      newValue = true
    } else if (showHide === 'show') {
      newValue = false
    }
    this.state.borrowersColumns.map((val) => {
      if (!['loanID', 'customerID', 'borrowersName'].includes(val.dataField)) {
        val.hidden = newValue
        this.props.toogleColumn(val.dataField, !newValue)
      }
      return val;
    });
  }

  render() {
    let result = []
    let tmp = []
    let counter = 1
    for (let i = 0; i < this.state.borrowersColumns.length; i++) {
      if (!['customerID', 'borrowersName'].includes(this.state.borrowersColumns[i].dataField)) {
        tmp.push(this.state.borrowersColumns[i])
        if (counter % 5 === 0) {
          result.push(tmp)
          tmp = []
        }
        counter++
        if (i === this.state.borrowersColumns.length - 1) {
          tmp.push({})
          result.push(tmp)
        }
      }
    }
    return <Container fluid className="text-left">
      <Row>
        <Col md="auto" ><h4 className="mx-4">Clients:</h4></Col>
        <Col md="auto" style={{ top: '8px' }} className='text-left'><div style={{ width: '18px', height: '18px', display: 'inline-block', color: purchase.color, backgroundColor: purchase.backgroundColor }} /> {purchase.text}</Col>
        <Col md="auto" style={{ top: '8px' }} className='text-left'><div style={{ width: '18px', height: '18px', display: 'inline-block', color: `${jumboPurchase.color}`, backgroundColor: `${jumboPurchase.backgroundColor}` }} /> {jumboPurchase.text}</Col>
        <Col md="auto" style={{ top: '8px' }} className='text-left'><div style={{ width: '18px', height: '18px', display: 'inline-block', color: `${refinance.color}`, backgroundColor: `${refinance.backgroundColor}` }} /> {refinance.text}</Col>
        <Col md="auto" style={{ top: '8px' }} className='text-left'><div style={{ width: '18px', height: '18px', display: 'inline-block', color: `${jumboRefinance.color}`, backgroundColor: `${jumboRefinance.backgroundColor}` }} /> {jumboRefinance.text}</Col>
        <Col md="auto" style={{ top: '8px' }} className='text-left'><div style={{ width: '18px', height: '18px', display: 'inline-block', color: `${VA.color}`, backgroundColor: `${VA.backgroundColor}` }} /> {VA.text}</Col>
        <Col md="auto" style={{ top: '8px' }} className='text-left'><div style={{ width: '18px', height: '18px', display: 'inline-block', color: `${FHA.color}`, backgroundColor: `${FHA.backgroundColor}` }} /> {FHA.text}</Col>
        <Col md="auto" style={{ top: '8px' }} className='text-left'><div style={{ width: '18px', height: '18px', display: 'inline-block', color: `${USDA.color}`, backgroundColor: `${USDA.backgroundColor}` }} /> {USDA.text}</Col>
        <Col style={{ top: '2px' }} className="text-right"> <Button onClick={
          () => { this.setState({ showFilters: !this.state.showFilters }) }
        }
          variant="link" size="sm">{this.state.showFilters ? "Hide filters" : "Show filters"}</Button></Col>
      </Row>
      <Row>
        <Col>
          <br />
          <h2 style={{ textAlign: 'center' }}>Active customers</h2>
          <ToolkitProvider
            bootstrap4
            keyField='borrowersName'
            data={this.state.borrowers}
            columns={this.state.borrowersColumns}
            search
            exportCSV={{
              fileName: 'Management pipeline(Active customers).csv'
            }}
          >
            {
              props => {
                return (
                  <div>
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />
                    <ExportCSVButton style={{ display: 'inline-block', color: 'white', marginLeft: '0', backgroundColor: 'rgb(36, 44, 66)', borderColor: 'rgb(36, 44, 66)' }} {...props.csvProps}>Export CSV</ExportCSVButton>
                    {this.state.showFilters &&
                      result.map(rows => (
                        <Row>
                          {rows.map(columns => ({
                            ...columns
                          }))
                            .map((column, i) => {
                              if (typeof column.text !== 'undefined') {
                                return (
                                  <Col key={i}>
                                    <input onChange={() => this.columnToggle(column.dataField)} type="checkbox" id={column.dataField} name={column.dataField} checked={this.props.pipelineConfiguration[column.dataField]} />
                                    <label htmlFor={column.dataField} style={{ marginLeft: '3px' }}>{column.text}</label>
                                  </Col>
                                )
                              } else {
                                return (
                                  <>
                                    <Col key={i}>
                                    </Col>
                                    <Col key={i + 1}>
                                      <Button className='button-dark' onClick={() => this.showHideAllColumns('hide')}>Hide all</Button>
                                    </Col>
                                    <Col key={i + 2}>
                                      <Button className='button-dark' onClick={() => this.showHideAllColumns('show')}>Show all</Button>
                                    </Col>
                                  </>
                                )
                              }
                            })}
                        </Row>
                      ))}
                    <BootstrapTable id="dashboard1"
                      striped bootstrap4 bordered={false}
                      {...props.baseProps}
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory()}
                      autoscale
                    />
                  </div>
                )
              }
            }
          </ToolkitProvider>
        </Col>
      </Row>
      <br />
      <hr />
      <br />
      <Row>
        <Col>
          <h2 style={{ textAlign: 'center' }}>Successfully completed customers</h2>
          <ToolkitProvider
            bootstrap4
            keyField='borrowersName'
            data={this.state.finished}
            columns={this.state.borrowersColumns}
            search
            exportCSV={{
              fileName: 'Management pipeline(Successfully completed customers).csv'
            }}
          >
            {
              props => {
                return (
                  <div>
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />
                    <ExportCSVButton style={{ display: 'inline-block', color: 'white', marginLeft: '0', backgroundColor: 'rgb(36, 44, 66)', borderColor: 'rgb(36, 44, 66)' }} {...props.csvProps}>Export CSV</ExportCSVButton>
                    <BootstrapTable id="dashboard2"
                      striped bootstrap4 bordered={false}
                      {...props.baseProps}
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory()}
                    />
                  </div>
                )
              }
            }
          </ToolkitProvider>
        </Col>
      </Row>
      <br />
      <hr />
      <br />
      <Row>
        <Col>
          <h2 style={{ textAlign: 'center' }}>Dropped out customers</h2>
          <ToolkitProvider
            bootstrap4
            keyField='borrowersName'
            data={this.state.dropped}
            columns={this.state.borrowersColumns}
            search
            exportCSV={{
              fileName: 'Management pipeline(Dropped out customers).csv'
            }}
          >
            {
              props => {
                return (
                  <div>
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />
                    <ExportCSVButton style={{ display: 'inline-block', color: 'white', marginLeft: '0', backgroundColor: 'rgb(36, 44, 66)', borderColor: 'rgb(36, 44, 66)' }} {...props.csvProps}>Export CSV</ExportCSVButton>
                    <BootstrapTable id="dashboard3"
                      striped bootstrap4 bordered={false}
                      {...props.baseProps}
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory()}
                    />
                  </div>
                )
              }
            }
          </ToolkitProvider>
        </Col>
      </Row>
    </Container>
  }
}

export default class Pipeline extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pipelineConfiguration: {
        borrowersName: true,
        accountCreated: true,
        lender: true,
        interviewSubmission: true,
        lockRequested: true,
        openedEscrow: true,
        estimatedClosingDate: true,
        loanFunded: true,
        loanAmount: true,
        totalBrokerCompensation: true,
        estimatedLoanAmount: true,
        Currentrate: true,
        NewRate: true
      }
    }
  }

  toogleColumn = (column, value) => {
    this.setState(prev => {
      return { pipelineConfiguration: { ...prev.pipelineConfiguration, [column]: value } }
    })
  }

  render() {
    return <PipelineInner pipelineConfiguration={this.state.pipelineConfiguration} toogleColumn={this.toogleColumn} />
  }
}