import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar, ClearSearchButton } = Search;

class ContactInformation extends Component{
  constructor(props){
    super(props)

    this.columns = [
      {
        dataField: 'firstName',
        text: 'First Name',
        sort: true,
      }, {
        dataField: 'lastName',
        text: 'Last Name',
        sort: true,
      }, {
      dataField: 'phone',
        text: 'Phone',
        sort: true,
      }, {
        dataField: 'email',
        text: 'Email',
        sort: true
      }
    ]
  }

  documentsJSON = (document, index) => {
    return { 
        firstName: document.FirstName,
        lastName:  document.LastName,
        phone:  document.ContactPhone,
        email: document.Email,
    }
  }

  render(){
    return (
      <div className="text-left mt-3 mx-2 ">
        <ToolkitProvider
          bootstrap4
          keyField='email'
          data={this.props.data.map(this.documentsJSON)}
          columns={this.columns}
          search >
          {
            props => (
              <div>
                <h2 className="heading">Customers:</h2>
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
                <BootstrapTable id="scaledtable"
                    striped bootstrap4 bordered={false}
                    {...props.baseProps}
                    wrapperClasses="table-responsive"
                />
              </div>
            )
          }
          </ToolkitProvider>
        </div>
    );
  }
}

export default ContactInformation