import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { PieChart } from 'react-minimal-pie-chart';
import * as com from "../Common.js"
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import NumericalInput from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import { tooltip } from '../Common/Tooltip'
import { getCurrentState } from '../Store'


const judgements = "judgements"
const bancrupt = "bancrupt"
const propertyforeclosure = "propertyforeclosure"
const lawsuit = "lawsuit"
const loanforeclosure = "loanforeclosure"
const delinquentondebt = "delinquentondebt"
const comakeronnote = "comakeronnote"
const loansinforebearance = "loansinforebearance"


export function getassets()  {
    let total = 0
    
    this.props.application.assetsandliabilities.accounts && 
    this.props.application.assetsandliabilities.accounts.forEach(x => {
        if(x.balance !== "")
            total += parseFloat( x.balance)
    })
    this.props.application.assetsandliabilities.brokerages && 
    this.props.application.assetsandliabilities.brokerages.forEach(x => {
        if(x.balance !== "")
            total += parseFloat( x.balance)
    })
    this.props.application.assetsandliabilities.lifeinsurance && 
    this.props.application.assetsandliabilities.lifeinsurance.forEach(x => {
        if(x.netvalue !== "")
            total += parseFloat( x.netvalue)
    })
    this.props.application.assetsandliabilities.gifts &&
    this.props.application.assetsandliabilities.gifts.forEach(x => {
        if(x.value !== "")
            total += parseFloat( x.value)
    })
    this.props.application.assetsandliabilities.otherassets &&
    this.props.application.assetsandliabilities.otherassets.forEach(x => {
        if(x.value !== "")
            total += parseFloat( x.value)
    })

    return  com.commaizeFloat(total)
}

const mapStateToPropsA = (state) => {
    return {
        application: state.application
    }
}

const mapDispatchToPropsA = (dispatch) => ({

})
class Allocation extends Component {
    constructor(props) {
        super(props)
        let [stocks, bonds, cash] = props.allocation
        this.props = props
        this.state = {
            show: false,
            stocks,
            bonds,
            cash,
            input: (stocks === 0 && bonds === 0 && cash === 0) ? "" : "set"
        }

    }
    componentDidMount = () => {
        let e = document.getElementById("allocation" + this.props.index)

        e.checkValidity = function () {
            alert()
        }
        e.reportValidity = function () {
            alert()
        }
    }
    apply = e => {
        let val = [parseFloat(this.state.stocks), parseFloat(this.state.bonds), parseFloat(this.state.cash)]
        this.props.onChange(val)
        this.setState({ show: false })
    }

    render = () => {
        let onStocks = e => {
            let val = e.target.value
            if (val.length > 1 && val[0] === '0') {
                val = val.substr(1, val.length)
            }
            let v = parseFloat(val)
            if (isNaN(v)) {
                v = 0
                val = 0
            }

            v = Math.max(0, Math.min(100, v))
            if (val[val.length - 1] === '.')
                val = v.toString() + "."
            else
                val = v.toString()

            this.setState({ stocks: val })
            if (v + parseFloat(this.state.bonds) > 100)
                this.setState({ bonds: Math.max(100 - v), cash: 0, input: "set" })
            else {
                this.setState({ cash: parseFloat((100 - v - parseFloat(this.state.bonds).toFixed(2))), input: "set" })
            }
        }
        let onBonds = e => {
            let val = e.target.value
            if (val.length > 1 && val[0] === '0') {
                val = val.substr(1, val.length)
            }
            let v = parseFloat(val)
            if (isNaN(v)) {
                v = 0
                val = 0
            }

            v = Math.max(0, Math.min(100, v))
            if (val[val.length - 1] === '.')
                val = v.toString() + "."
            else
                val = v.toString()

            this.setState({ bonds: val })
            if (v + parseFloat(this.state.stocks) > 100)
                this.setState({ stocks: Math.max(0, 100 - v), cash: 0, input: "set" })
            else {
                this.setState({ cash: parseFloat((100 - v - parseFloat(this.state.stocks).toFixed(2))), input: "set" })

            }
        }

        return (
            <div className="p-0 m-0" >
                <Modal dialogClassName=" align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show} onHide={() => this.setState({ show: false })} >
                    <Modal.Header closeButton>
                        <Modal.Title>Please specify asset allocation in your portfolio:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <Row>
                            <Col>
                                <Form.Group controlId="Broker" className="text-left" size="sm">
                                    <Form.Label className="text-left" ><i style={{ color: '#EC6B56' }} className="fas fa-square"></i>Stocks:</Form.Label>
                                    <InputGroup>
                                        <Form.Control isValid={false} size="sm"
                                            required
                                            name={"stocks"}
                                            autoFocus
                                            type="text"
                                            pattern="^[0-9.]+$"
                                            onChange={onStocks}
                                            value={this.state.stocks}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className="pl-3" style={{ fontSize: '0.9em', lineHeight: '1.3' }} size="sm">%</InputGroup.Text>

                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="Broker" className="text-left" size="sm">
                                    <Form.Label className="text-left" ><i style={{ color: '#FFC154' }} classNme="fas fa-square"></i>Bonds:</Form.Label>
                                    <InputGroup>
                                        <Form.Control isValid={false} size="sm"
                                            required
                                            name={"bonds"}
                                            pattern="^[0-9.]+$"
                                            type="text"

                                            onChange={onBonds}
                                            value={this.state.bonds}

                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className="pl-3" style={{ fontSize: '0.9em', lineHeight: '1.3' }} size="sm">%</InputGroup.Text>

                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="Broker" className="text-left" size="sm">
                                    <Form.Label className="text-left" ><i style={{ color: '#47B39C' }} classNme="fas fa-square"></i>Cash:</Form.Label>
                                    <br /><Form.Label className="text-left" > {this.state.cash.toFixed(2)}%</Form.Label>

                                </Form.Group>
                            </Col>
                        </Row>
                     
                        <Row>
                            <Col className="text-center">
                                <PieChart className="mr-3"

                                    style={{ height: '256px', width: '256px' }}

                                    data={[
                                        { title: 'Stocks', value: parseFloat(this.state.stocks), color: '#EC6B56' },
                                        { title: 'Bonds', value: parseFloat(this.state.bonds), color: '#FFC154' },
                                        { title: 'Cash', value: 100 - parseFloat(this.state.stocks) - parseFloat(this.state.bonds), color: '#47B39C' },
                                    ]}
                                />
                            </Col>
                        </Row> 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.apply}>Apply</Button>
                    </Modal.Footer>
                </Modal>

                <input hidden value={this.state.input} required type="text" id={"allocation" + this.props.index} className="form-control " />
                <Button style={{ marginTop: '9px' }} size="sm" onClick={e => this.setState({ show: true })}
                    required
                    className="zeitro-radio obutton text-left pr-3" variant="outline-primary">
                    <PieChart className="mr-3"

                        style={{ height: '32px', width: '32px' }}

                        data={this.state.stocks === 0 && this.state.bonds === 0 && this.state.cash === 0 ? [{ title: "Not set", value: 100, color: '#AAAAAA' }] : [
                            { title: 'Stocks:' + parseFloat(this.state.stocks).toFixed(1) + "%", value: parseFloat(this.state.stocks), color: '#EC6B56' },
                            { title: 'Bonds:' + parseFloat(this.state.bonds).toFixed(1) + "%", value: parseFloat(this.state.bonds), color: '#FFC154' },
                            { title: 'Cash:' + parseFloat(this.state.cash).toFixed(1) + "%", value: 100 - parseFloat(this.state.stocks) - parseFloat(this.state.bonds), color: '#47B39C' },
                        ]}
                    /> Allocation
                </Button>
            </div>
        )
    }
}
Allocation = connect(mapStateToPropsA, mapDispatchToPropsA, null, { forwardRef: true })(Allocation)

const mapStateToProps = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application,
        borrower: state.application.borrower.declarations,
        coborrower: state.application.coborrower.declarations,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateJointly: (event) => {
        dispatch(act.UpdateJointly(event.target.checked))
    },

    updateFinances: (event, who, verb) => {
        dispatch(act.UpdateFinances(event.target.value, who, verb))
    },
    clearBorrowerBankAccounts: (index, who) => {
        dispatch(act.ClearBorrowerBankAccounts(index, who))
    },
    addBorrowerBankAccount: (i, who) => {
        dispatch(act.AddBorrowerBankAccount(i, who))
    },
    removeBorrowerBankAccount: (index, who) => {
        dispatch(act.RemoveBorrowerBankAccount(index, who))
    },

    changeBorrowerBankName: (event, index, who) => {
        dispatch(act.ChangeBorrowerBankName(event.target.value, index, who))
    },
    changeBorrowerBankAccount: (event, index, who) => {
        dispatch(act.ChangeBorrowerBankAccount(event.target.value, index, who))
    },
    changeBorrowerBankBalance: (event, index, who) => {
        dispatch(act.ChangeBorrowerBankBalance(event.target.value, index, who))
    },
    changeBorrowerBankAttribution: (event, index, who) => {
        dispatch(act.ChangeBorrowerBankAttribution(event.target.value, index, who))
    },
    changeBorrowerBankAccountType: (event, index, who) => {
        dispatch(act.ChangeBorrowerBankAccountType(event.target.value, index, who))
    },

    clearBorrowerBrokerAccounts: (index, who) => {
        dispatch(act.ClearBorrowerBrokerAccounts(index, who))
    },
    addBorrowerBrokerAccount: (i, who) => {
        dispatch(act.AddBorrowerBrokerAccount(i, who))
    },
    removeBorrowerBrokerAccount: (index, who) => {
        dispatch(act.RemoveBorrowerBrokerAccount(index, who))
    },

    changeBorrowerBrokerName: (event, index, who) => {
        dispatch(act.ChangeBorrowerBrokerName(event.target.value, index, who))
    },
    changeBorrowerBrokerAccount: (event, index, who) => {
        dispatch(act.ChangeBorrowerBrokerAccount(event.target.value, index, who))
    },
    changeBorrowerBrokerBalance: (event, index, who) => {
        dispatch(act.ChangeBorrowerBrokerBalance(event.target.value, index, who))
    },
    changeBorrowerBrokerAttribution: (event, index, who) => {
        dispatch(act.ChangeBorrowerBrokerAttribution(event.target.value, index, who))
    },
    changeBorrowerBrokerTaxStatus: (event, index, who) => {
        dispatch(act.ChangeBorrowerBrokerTaxStatus(event.target.value, index, who))
    },
    changeBorrowerBrokerAllocation: (value, index, who) => {
        dispatch(act.ChangeBorrowerBrokerAllocation(value, index, who))
    },
    changeDeclaration: (event, who, verb) => {
        dispatch(act.ChangeDeclaration(event.target.value, who, verb))
    },
});

class Finances extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            totalAssets: 0,
        }
        this.isValidated = this.isValidated.bind(this);
        if ("undefined" === typeof this.props.application.assetsandliabilities.brokerages)
            this.props.application.assetsandliabilities.brokerages = []
    }
    recalcAssets = () => {
        //setTimeout( () => this.setState({totalAssets: this.calculateAssets()}), 100)
        this.calculateAssets()
    }
    componentDidMount() { 
        this.recalcAssets()
    }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }

    processBrokerages = (val, index) => {
        if (null === val)
            return ""

        let bankname = val.bankname;
        let account = val.account;
        let key = val.key
        let balance = val.balance
        let attribution = val.attribution
        let isretirement = val.isretirement
        return this.generateBrokerAccount(index, bankname, account, balance, attribution, isretirement, key)
    }

    generateBrokerAccount = (index, brokername, account, balance, attribution, isretirement, key) => {
        index = parseInt(index)
        let removeBorrowerBrokerAccount = (event) => {
            com.touch()
            this.props.removeBorrowerBrokerAccount(index, this.props.who)
        }
        let updateBroker = (event) => {
            com.touch()
            this.props.changeBorrowerBrokerName(event, index, this.props.who)
        }
        let updateAccount = (event) => {
            com.touch()
            this.props.changeBorrowerBrokerAccount(event, index, this.props.who)
        }
        let changeBorrowerBrokerBalance = (event) => {
            com.touch()
            this.props.changeBorrowerBrokerBalance(event, index, this.props.who)
        }
        let addBorrowerBrokerAccount = (event) => {
            com.touch()
            return this.props.addBorrowerBrokerAccount(event, this.props.who)
        }
        let changeAttribution = event => {
            com.touch()
            this.props.changeBorrowerBrokerAttribution(event, index, this.props.who)

        }
        let changeTaxStatus = event => {
            com.touch()
            let val = event.target.checked
            this.props.changeBorrowerBrokerTaxStatus({ target: { value: val } }, index, this.props.who)

        }
        let amountName = () => {
            return isretirement ? tooltip("Vested Amount", `
            Vested amount refers to the part of the retirement account that can be potentially withdrawn. 
            Employer matching contribution typically has a vesting schedule, and only belongs to you after it vests.
            `, "auto", "") : <div>Balance:</div>
        }
        let onChange = val => {
            com.touch()
            this.props.changeBorrowerBrokerAllocation(val, index, this.props.who)

        }

        return (
            <div key={key} className="incard m-0 mb-3 px-1">
                <Row>
                    <Col className="p-0 pl-2">
                        <Form.Row >
                            <Col className="">
                                <Form.Row>
                                    <Col xs="auto" className="">
                                        <Form.Group controlId="Broker" className="text-left" size="sm">
                                            <Form.Label className="text-left" >Institution:</Form.Label>
                                            <Form.Control isValid={false} size="sm"
                                                required
                                                name={"brokername" + index}
                                                autoFocus
                                                type="text"
                                                defaultValue={brokername}
                                                onChange={updateBroker}

                                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide the name of the bank/credit union.
                                </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="auto"  >
                                        <Form.Group controlId="BrokerAccount" className="text-left" size="sm" >
                                            {tooltip("Account", "Only 4 last digits of the account number are required")}
                                            <Form.Control isValid={false} size="sm"
                                                required
                                                name={"brookeraccount" + index}
                                                type="text"
                                                defaultValue={account}
                                                onChange={updateAccount}
                                                placeholder="XXXX (Last Four Digits)"
                                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide account number.
                                </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                </Form.Row >
                            </Col>

                        </Form.Row >
                        <Form.Row >
                            <Col xs="auto"  >
                                <Form.Group controlId="MarketValue" className="text-left" size="sm" >
                                    {amountName()}
                                    <NumericalInput isValid={false} size="sm"
                                        required
                                        name={"marketvalue" + index}
                                        defaultValue={balance}
                                        onBlur={this.recalcAssets()}
                                        onChange={changeBorrowerBrokerBalance}
                                        min={1}
                                    />

                                    <Form.Control.Feedback >
                                        Looks good!
                                </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide account balance.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="pl-2">
                                {tooltip("Retirement", "If this is a retirement account (401K, IRA, SEP, Keogh, Annuities) choose Retirement, and specify only the vested account. Otherwise, choose Regular",
                                    "auto", "")}
                                <div style={{ marginTop: '4px' }}>
                                    <input type="checkbox" id={"401k" + index} checked={isretirement} onChange={changeTaxStatus} ></input>
                                    <label id={"401label" + index} style={{ textDecoration: "underline" }}
                                        className="divlink mr-2" htmlFor={"401k" + index} >Check if retirement account</label>
                                </div>
                            </Col>
                            {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                                <Col xs="auto" className="pl-2">
                                    <div>Ownership</div>
                                    <Form.Group controlId="employment" >

                                        <Form.Control name="employment" as="select" size="sm"
                                            defaultValue={attribution}
                                            onChange={changeAttribution}
                                            required
                                        >
                                            <option value="">...</option>
                                            <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                            <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                            <option value="3">Joint</option>

                                        </Form.Control>
                                    </Form.Group>
                                </Col> : ""

                            }
                            <Col xs="auto" className="align-top pl-2" >
                                <Allocation id={"allocation" + index} required onChange={onChange} who={this.props.who} index={this.props.index}
                                    allocation={[this.props.application.assetsandliabilities.brokerages[index].allocation.stocks,
                                    this.props.application.assetsandliabilities.brokerages[index].allocation.bonds,
                                    this.props.application.assetsandliabilities.brokerages[index].allocation.cash]} />

                            </Col>
                            <Col>
                            </Col>
                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top px-0">
                        <i hidden={index !== this.props.application.assetsandliabilities.brokerages.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addBorrowerBrokerAccount} ></i>
                        <i hidden={index === this.props.application.assetsandliabilities.brokerages.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>

                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeBorrowerBrokerAccount} ></i>
                    </Col>
                </Row>
            </div>
        )
    }

    process = (val, index) => {
        if (null === val)
            return ""

        let bankname = val.bankname;
        let account = val.account;
        let key = val.key
        let balance = val.balance
        let attribution = val.attribution
        let accounttype = val.accounttype
        return this.generateAccount(index, bankname, account, balance, attribution, accounttype, key)
    }

    generateAccount = (index, bankname, account, balance, attribution, accounttype, key) => {
        index = parseInt(index)
        let removeBorrowerBankAccount = (event) => {
            com.touch()
            this.props.removeBorrowerBankAccount(index, this.props.who)
        }
        let updateBank = (event) => {
            com.touch()
            this.props.changeBorrowerBankName(event, index, this.props.who)
        }
        let updateAccount = (event) => {
            com.touch()
            this.props.changeBorrowerBankAccount(event, index, this.props.who)
        }
        let changeBorrowerBankBalance = (event) => {
            com.touch()
            this.props.changeBorrowerBankBalance(event, index, this.props.who)
        }

        let addBorrowerBankAccount = (event) => {
            com.touch()
            return this.props.addBorrowerBankAccount(event, this.props.who)
        }
        let changeAttribution = event => {
            com.touch()
            this.props.changeBorrowerBankAttribution(event, index, this.props.who)

        }
        let changeType = event => {
            com.touch()
            this.props.changeBorrowerBankAccountType(event, index, this.props.who)

        }
        let onSelect = e => {
            com.touch()
            this.props.changeBorrowerBankAccountType({ target: { value: e } }, index, this.props.who)
        }
        let at = undefined
        com.bankaccounttypes.forEach(x => {
            if (x[0] === accounttype)
                at = x[1]
        })

        if (undefined !== at)
            accounttype = at
        return (
            <div key={key} className="incard m-0 mb-3  px-1">
                <Row className="p-0">
                    <Col className="p-0 pl-2">
                        <Form.Row >
                            <Col xs="auto">
                                <Form.Group controlId="Bank" className="text-left" size="sm">
                                    <Form.Label className="text-left" >Bank/S&amp;L/Credit Union:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"bankname" + index}
                                        autoFocus
                                        type="text"
                                        defaultValue={bankname}
                                        onChange={updateBank}

                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId={"type" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Account Type:</Form.Label>
                                    <InputGroup>
                                        <Form.Control isValid={false} size="sm"
                                            required
                                            name={"description" + index}
                                            type="text"
                                            value={accounttype}
                                            onChange={changeType}
                                        />
                                        <DropdownButton
                                            as={InputGroup.Append}
                                            className="incdropdown"
                                            title=""
                                            id="dropdown-income"
                                            size="sm"
                                            variant="dropdown"
                                            onSelect={onSelect}
                                        >   {com.bankaccounttypes.map(([name, st]) =>
                                        (
                                            <Dropdown.Item key={name} eventKey={name} href="#">{st}</Dropdown.Item>

                                        )
                                        )}
                                        </DropdownButton>
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please describe the source of income.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto"  >
                                <Form.Group controlId="Account" className="text-left" size="sm" >
                                    {tooltip("Account", "Only 4 last digits of the account number are required")}
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"account" + index}
                                        type="text"
                                        defaultValue={account}
                                        onChange={updateAccount}
                                        placeholder="XXXX (Last Four Digits)"
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide account number.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="Balance" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Balance:</Form.Label>
                                    <NumericalInput isValid={false} size="sm"
                                        required
                                        name={"balance" + index}
                                        defaultValue={balance}
                                        onBlur={this.recalcAssets()}
                                        onChange={changeBorrowerBankBalance}
                                        min={1}
                                    />
                                    <Form.Control.Feedback >
                                        Looks good!
                                </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide account balance.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                                <Col xs="auto">
                                    <Form.Label className="text-left" >Ownership</Form.Label>
                                    <Form.Group controlId="employment" >

                                        <Form.Control name="employment" as="select" size="sm"
                                            defaultValue={attribution}
                                            onChange={changeAttribution}
                                            required
                                        >
                                            <option value="">...</option>
                                            <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                            <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                            <option value="3">Joint</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col> : ""
                            }
                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top m-0 p-0">
                        <i hidden={index !== this.props.application.assetsandliabilities.accounts.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addBorrowerBankAccount} ></i>
                        <i hidden={index === this.props.application.assetsandliabilities.accounts.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeBorrowerBankAccount} ></i>
                    </Col>
                </Row>
            </div>
        )
    }

    addOtherSources = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerBankAccounts(0, this.props.who)
        } else {
            this.props.addBorrowerBankAccount(0, this.props.who)
        }
    }
    updateJointly = (e) => {
        com.touch()
        let f = e.target.value === "true"
        this.props.updateJointly({ target: { checked: f } })
    }

    addOtherBrokerages = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerBrokerAccounts(0, this.props.who)
        } else {
            this.props.addBorrowerBrokerAccount(0, this.props.who)
        }
    }


    addOtherProperties = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerAdditionalProperty(0, this.props.who)
        } else {
            this.props.addBorrowerAdditionalProperty(0, this.props.who)
        }
    }
    calculateAssets = getassets.bind(this)

    updateFinances = (verb) => {
        return e => this.props.updateFinances(e.target.value, this.props.who, verb)
    }
    showInvestment = () => {
        return null !== this.props.application.assetsandliabilities.accounts
    }

    render = () => {
        let iwe = (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
            `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname} and ${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}` :
            `${this.props.application[this.props.who].firstname} ${this.props.application[this.props.who].lastname}`


        //let label = `Do ${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname} and 
        // ${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} report assets and liabilities jointly?`

        let meus = (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ? 'we' : 'I'
        
        let changeDeclaration = (verb, who) => {
            return ((event) => {
                com.touch()
                this.props.changeDeclaration(event, who, verb)
            }
            )
        }
        let updateBankrupcy = (who, verb) => {
            return event => {
                com.touch()
                this.props.changeDeclaration({target: {value: event.target.checked}}, who, verb)                
            }
        }
        return (
            <div className=" text-left">
                <h2 className=" heading">
                    Tell us about {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                        `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname} and ${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}` :
                        `${this.props.application[this.props.who].firstname} ${this.props.application[this.props.who].lastname}`}'s  finances


                     {/*   <span className="hideonphone700" style={{ marginTop: "4px", fontSize: "0.7em", fontWeight: "normal", position: "absolute", right: "4em" }}> assets: ${this.state.totalAssets}</span>
                  */}
                </h2>

                <div className="text-left mt-3 mb-4 ">


                    <div className="viewport mt-3">
                        <Form.Row>
                            <Col><div className="sectionquestion">

                                {tooltip( (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                                    "Would you like " + iwe + " bank accounts to be used for income calculation?" :
                                    "Would you like " + iwe + " bank accounts to be used for income calculation?",
                                            <div>
                                                This is only necessary for asset depletion mortgages, and to ensure you have enough reserves for certain loans
                                            </div>, "auto" )
                                }
                            </div>
                                <Form.Group>
                                    <fieldset>
                                        <div className="d-flex mt-1">

                                            <div className="mr-3 ">
                                                <input required onChange={this.addOtherSources} value={true} checked={null !== this.props.application.assetsandliabilities.accounts && this.props.application.assetsandliabilities.accounts.length !== 0} type="radio" id="bankaccounts" name="bankaccounts"></input>
                                                <label className="zeitro-radio divlink" htmlFor="bankaccounts">Yes, {meus} do</label></div>
                                            <div><input required onChange={this.addOtherSources} value={false} checked={null === this.props.application.assetsandliabilities.accounts || this.props.application.assetsandliabilities.accounts.length === 0} type="radio" id="bankaccountsnot" name="bankaccounts"></input>
                                                <label className="zeitro-radio divlink" htmlFor="bankaccountsnot">No, {meus} don't</label></div>
                                        </div>
                                    </fieldset>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please specify if you have bank accounts
                                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>



                        {null !== this.props.application.assetsandliabilities.accounts ? this.props.application.assetsandliabilities.accounts.map(this.process) : ""}
                    </div>
                    {!this.showInvestment() ? "" :
                        <div className="viewport mt-3">
                            <Form.Row className="mt-1 mb-1 k">

                                <Col className="text-left nowrap d-block">
                                    <div className="sectionquestion">

                                      { tooltip(  (this.props.application.hascoborrower === "alone" && this.props.application.married) ?
                                            "Would you like " + iwe + " investment accounts to be used for income calculation?" :
                                            "Would you like " + iwe + " investment accounts to be used for income calculation?",
                                            <div>
                                                This is only necessary for asset depletion mortgages, and to ensure you have enough reserves for certain loans
                                            </div>, "auto" )
                                      }
                                    </div>
                                    <Form.Group>
                                        <fieldset>
                                            <div className="d-flex mt-1">

                                                <div className="mr-3 ">
                                                    <input required onChange={this.addOtherBrokerages} value={true} checked={null !== this.props.application.assetsandliabilities.brokerages && this.props.application.assetsandliabilities.brokerages.length !== 0} type="radio" id="brokaccounts" name="brokaccounts"></input>
                                                    <label className="zeitro-radio divlink" htmlFor="brokaccounts">Yes, {meus} do</label></div>
                                                <div><input required onChange={this.addOtherBrokerages} value={false} checked={null !== this.props.application.assetsandliabilities.brokerages && this.props.application.assetsandliabilities.brokerages.length === 0} type="radio" id="brokaccountsnot" name="brokaccounts"></input>
                                                    <label className="zeitro-radio divlink" htmlFor="brokaccountsnot">No, {meus} don't</label></div>
                                            </div>
                                        </fieldset>
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please specify if you have bank accounts
                                                </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Form.Row>

                            {this.props.application.assetsandliabilities.brokerages === null ? "" : this.props.application.assetsandliabilities.brokerages.map(this.processBrokerages)}
                        </div>
                    }
                    <div className="text-left mt-4 mb-3 viewport">
                    <Row><Col md="auto" className="sectionquestion ml-3 roundedtab">About your finances</Col>
                        <Col className="tabajacent"></Col></Row>                    

                        <Form.Row>
                            <Col>
                                <Table className="text-left m-1 p-1">
                                {this.props.hascoborrower !== "alone" ? (

                                <thead>
                                    <tr className="m-1 p-1" style={{backgroundColor: "white"}}>
                                        <th className=" m-1 p-1" >&nbsp;</th>
                                        <th className=" m-1 p-1" >Borrower</th>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <th className=" m-1 p-1" >Co-applicant</th>
                                        ) : ""
                                        }
                                    </tr>
                                </thead>
                                ) : ""
                                }
                                <tbody>
                                    <tr>
                                        <td className="m-1 p-1" >Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?</td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="comakeronnote" className="m-0 p-0 text-left" >
                                                <Form.Control required name="comakeronnote" as="select" size="sm"
                                                    defaultValue={this.props.borrower.comakeronnote}
                                                    onChange={changeDeclaration(comakeronnote, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group controlId="cocomakeronnote" className="m-0 p-0 text-left" >
                                                    <Form.Control required name="cocomakeronnote" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.comakeronnote}
                                                        onChange={changeDeclaration(comakeronnote, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>

                                    <tr>
                                        <td className="m-1 p-1" > Are there any outstanding judgments against you?</td>
                                        <td className="m-1 p-1" yesno>
                                            <Form.Group controlId="judgement" className="text-left m-0 p-0" >
                                                <Form.Control required name="judgement" as="select" size="sm"
                                                    defaultValue={this.props.borrower.judgements}
                                                    onChange={changeDeclaration(judgements, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group required controlId="cojudgement" className="text-left m-0 p-0" >
                                                    <Form.Control name="judgement" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.judgements}
                                                        onChange={changeDeclaration(judgements, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>
                                    <tr>
                                        <td className="m-1 p-1 text-wrap" >Are you currently delinquent or in default on a Federal debt? </td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="delinquentondebt" className=" m-0 p-0 text-left" >
                                                <Form.Control required name="delinquentondebt" as="select" size="sm"
                                                    defaultValue={this.props.borrower.delinquentondebt}
                                                    onChange={changeDeclaration(delinquentondebt, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group controlId="codelinquentondebt" className="m-0 p-0 text-left" >
                                                    <Form.Control required name="codelinquentondebt" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.delinquentondebt}
                                                        onChange={changeDeclaration(delinquentondebt, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>
                                    <tr>
                                        <td className="m-1 p-1" >Are you a party to a lawsuit in which you potentially have any personal financial liability?</td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="lawsuit" className="text-left m-0 p-0" >
                                                <Form.Control required name="lawsuit" as="select" size="sm"
                                                    defaultValue={this.props.borrower.lawsuit}
                                                    onChange={changeDeclaration(lawsuit, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group controlId="colawsuit" className="text-left m-0 p-0" >
                                                    <Form.Control required name="colawsuit" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.lawsuit}
                                                        onChange={changeDeclaration(lawsuit, com.coborrower)}
                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>


                                    <tr>
                                        <td className="m-1 p-1 text-wrap" >Have you conveyed title to any property in lieu of foreclosure in the past 7 years?</td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="conveyedtitle" className="text-left m-0 p-0" >
                                                <Form.Control required name="conveyedtitle" as="select" size="sm"
                                                    defaultValue={this.props.borrower.conveyedtitle}
                                                    onChange={changeDeclaration("conveyedtitle", com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group controlId="coconveyedtitle" className="text-left m-0 p-0" >
                                                    <Form.Control name="coconveyedtitle" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.conveyedtitle}
                                                        onChange={changeDeclaration("conveyedtitle", com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>


                                    <tr>
                                        <td className="m-1 p-1 text-wrap" >{tooltip("Within the past 7 years, have you completed a pre-foreclosure sale or short sale",
                                            <div>whereby the property was sold to a
                                    third party and the Lender agreed to accept less than the outstanding mortgage balance due?</div>,
                                            "auto", "", "")}</td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="loanforeclosure" className="text-left m-0 p-0" >
                                                <Form.Control required name="loanforeclosure" as="select" size="sm"
                                                    defaultValue={this.props.borrower.loanforeclosure}
                                                    onChange={changeDeclaration(loanforeclosure, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group controlId="coloanforeclosure" className="text-left m-0 p-0" >
                                                    <Form.Control name="coloanforeclosure" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.loanforeclosure}
                                                        onChange={changeDeclaration(loanforeclosure, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>


                                    <tr>
                                        <td className="m-1 p-1" >Have you had property foreclosed upon in the last 7 years?</td>
                                        <td className="m-1 p-1">
                                            <Form.Group controlId="propertyforeclosure" className="text-left m-0 p-0" >
                                                <Form.Control required name="propertyforeclosure" as="select" size="sm"
                                                    defaultValue={this.props.borrower.propertyforeclosure}
                                                    onChange={changeDeclaration(propertyforeclosure, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group controlId="copropertyforeclosure" className="text-left m-0 p-0" >
                                                    <Form.Control required name="copropertyforeclosure" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.propertyforeclosure}
                                                        onChange={changeDeclaration(propertyforeclosure, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }

                                    </tr>
                                    <tr>
                                        <td className="m-1 p-1" >{tooltip("Do you currently have any loans in forebearance",
                                        <div>Forbearance is when your mortgage servicer or lender allows you to pause or reduce your mortgage payments for a limited time while you build back your finances.</div>
                                        , "auto" )}</td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="loansinforebearance" className="text-left m-0 p-0" >
                                                <Form.Control required name="loansinforebearance" as="select" size="sm"
                                                    defaultValue={this.props.borrower.loansinforebearance}
                                                    onChange={changeDeclaration(loansinforebearance, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group controlId="coloansinforebearance" className="text-left m-0 p-0" >
                                                    <Form.Control required name="coloansinforebearance" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.loansinforebearance}
                                                        onChange={changeDeclaration(loansinforebearance, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>                                
                                    <tr>
                                        <td className="m-1 p-1" >Have you been declared bankrupt within the past 7 years?</td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="bancrupt" className="text-left m-0 p-0" >
                                                <Form.Control required name="bancrupt" as="select" size="sm"
                                                    defaultValue={this.props.borrower.bancrupt}
                                                    onChange={changeDeclaration(bancrupt, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1 yesno">
                                                <Form.Group controlId="cobancrupt" className="text-left m-0 p-0" >
                                                    <Form.Control required name="cobancrupt" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.bancrupt}
                                                        onChange={changeDeclaration(bancrupt, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>

                                    {false === (this.props.borrower.bancrupt !== "yes" && this.props.coborrower.bancrupt !== "yes") ?
                                        <tr><td className="m-0 p-0"><Form.Row className="m-0 p-0">
                                            <Col className="m-0 p-0">
                                                <Table variant="info" className="m-0 p-0" hidden={!(this.props.borrower.ownershipinterest !== "no" || (this.props.hascoborrower !== "alone" && this.props.coborrower.ownershipinterest !== "no"))}>
                                                    <thead>
                                                        <tr className="m-1 p-1" >
                                                            <th className=" m-1 p-1 borrowercolumn" ></th>
                                                            <th className=" m-1 p-1" > Identify the type(s) of bankruptcy::</th>
                                                            <th className=" m-1 p-1" >&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  >
                                                        <tr hidden={this.props.borrower.ownershipinterest === "no"}>
                                                            <td className="borrowercolumn"> Borrower </td>
                                                            <td  >
                                                                <Row>
                                                                    <Col md="auto">
                                                                        <input type="checkbox" id="chapter7" name="chapter7" defaultChecked={this.props.borrower.chapter7} onChange={updateBankrupcy(com.borrower, "chapter7")} ></input>
                                                                        <label id="chapter7label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="chapter7">Chapter 7</label>
                                                                    </Col>
                                                                    <Col md="auto">
                                                                        <input type="checkbox" id="chapter11" name="chapter11" defaultChecked={this.props.borrower.chapter11} onChange={updateBankrupcy(com.borrower, "chapter11")} ></input>
                                                                        <label id="chapter11label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="chapter11">Chapter 11</label>
                                                                    </Col>
                                                                    <Col md="auto">
                                                                        <input type="checkbox" id="chapter12" name="chapter12" defaultChecked={this.props.borrower.chapter12} onChange={updateBankrupcy(com.borrower, "chapter12")} ></input>
                                                                        <label id="chapter12label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="chapter12">Chapter 12</label>
                                                                    </Col>
                                                                    <Col md="auto">
                                                                        <input type="checkbox" id="chapter13" name="chapter13" defaultChecked={this.props.borrower.chapter13} onChange={updateBankrupcy(com.borrower, "chapter13")} ></input>
                                                                        <label id="chapter13label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="chapter13">Chapter 13</label>
                                                                    </Col>
                                                                    <Col></Col>
                                                                </Row>

                                                            </td>
                                                            <td>
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                        {this.props.hascoborrower !== "alone" ? (
                                                            <tr hidden={this.props.coborrower.ownershipinterest === "no"}>
                                                                <td className="borrowercolumn"> Coborrower </td>
                                                                <td >
                                                                    <Row>
                                                                        <Col md="auto">
                                                                            <input type="checkbox" id="cochapter7" name="cochapter7" defaultChecked={this.props.coborrower.chapter7} onChange={updateBankrupcy(com.coborrower, "chapter7")} ></input>
                                                                            <label id="cochapter7label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="cochapter7">Chapter 7</label>
                                                                        </Col>
                                                                        <Col md="auto">
                                                                            <input type="checkbox" id="cochapter11" name="cochapter11" defaultChecked={this.props.coborrower.chapter11} onChange={updateBankrupcy(com.coborrower, "chapter11")} ></input>
                                                                            <label id="cochapter11label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="cochapter11">Chapter 11</label>
                                                                        </Col>
                                                                        <Col md="auto">
                                                                            <input type="checkbox" id="cochapter12" name="cochapter12" defaultChecked={this.props.coborrower.chapter12} onChange={updateBankrupcy(com.coborrower, "chapter12")} ></input>
                                                                            <label id="cochapter12label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="cochapter12">Chapter 12</label>
                                                                        </Col>
                                                                        <Col md="auto">
                                                                            <input type="checkbox" id="cochapter13" name="cochapter13" defaultChecked={this.props.coborrower.chapter13} onChange={updateBankrupcy(com.coborrower, "chapter13")} ></input>
                                                                            <label id="cochapter13label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="cochapter13">Chapter 13</label>
                                                                        </Col>
                                                                        <Col></Col>
                                                                    </Row>
                                                                </td>
                                                                <td>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        ) : ""
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Form.Row>
                                        </td>
                                        </tr> : ""

                                    }
                                </tbody>
                                </Table>
                            </Col>
                        </Form.Row>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Finances)

const mapStateToPropsStocks = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application
    }
}
const mapDispatchToPropsStocks = (dispatch) => ({
    updateFinances: (event, who, verb) => {
        dispatch(act.UpdateFinances(event.target.value, who, verb))
    },


    addBorrowerOtherAsset: (event, who) => {
        dispatch(act.AddBorrowerOtherAsset(event, who))
    },
    removeBorrowerOtherAsset: (event, index, who) => {
        dispatch(act.RemoveBorrowerOtherAsset(index, who))
    },
    clearBorrowerOtherAssets: (event, who) => {
        dispatch(act.ClearBorrowerOtherAssets(event, who))
    },
    changeBorrowerOtherAssetName: (event, index, who) => {
        dispatch(act.ChangeBorrowerOtherAssetName(event.target.value, index, who))
    },
    changeBorrowerOtherAssetValue: (event, index, who) => {
        dispatch(act.ChangeBorrowerOtherAssetValue(event.target.value, index, who))
    },
    changeBorrowerOtherAssetAttribution: (event, index) => {
        dispatch(act.ChangeBorrowerOtherAssetAttribution(event.target.value, index))
    },
    changeBorrowerOtherAsset: (value, verb, index) => {
        dispatch(act.ChangeBorrowerOtherAsset(value, verb, index))
    },

    changeBorrowerInsuranceValue: (event, index, verb) => {
        dispatch(act.ChangeBorrowerInsuranceValue(event.target.value, index, verb))
    },
    clearBorrowerInsurance: () => {
        dispatch(act.ClearBorrowerInsurance())
    },
    removeBorrowerInsurance: (index) => {
        dispatch(act.RemoveBorrowerInsurance(index))
    },
    addBorrowerInsurance: () => {
        dispatch(act.AddBorrowerInsurance())
    },

    addBorrowerGift: () => {
        dispatch(act.AddBorrowerGift())
    },
    removeBorrowerGift: (index) => {
        dispatch(act.RemoveBorrowerGift(index))
    },
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    updateBorrowerGift: (value, verb, index) => {
        dispatch(act.UpdateBorrowerGift(value, verb, index))
    },

});

export class MoreFinances extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            totalAssets: 0,
        }

        this.isValidated = this.isValidated.bind(this);
        this.form = React.createRef();
    }
    recalcAssets = () => {
        setTimeout( () => this.setState({totalAssets: this.calculateAssets()}), 100)
    }
    componentDidMount() { 
        this.recalcAssets()
    }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }

    updateInsurance = e => {
        com.touch()
        let val = e.target.value === "true"

        this.props.updateFinances({ target: { value: val } }, this.props.who, "havelifeinsurance")
    }

    calculateAssets = getassets.bind(this)

    processInsurance = (val, index) => {
        if (null === val)
            return ""

        let issuer = val.issuer
        let netvalue = val.netvalue
        let facevalue = val.facevalue
        let key = val.key
        let attribution = val.attribution


        return this.renderInsurance(index, issuer, netvalue, facevalue, attribution, key)
    }

    renderInsurance(index, issuer, netvalue, facevalue, attribution, key) {

        index = parseInt(index)

        let changeBorrowerInsuranceValue = verb => {
            return (event) => {
                com.touch()
                this.props.changeBorrowerInsuranceValue(event, index, verb)
            }
        }

        let removeBorrowerInsurance = (event) => {
            com.touch()
            this.props.removeBorrowerInsurance(index)
        }
        let addBorrowerInsurance = (event) => {
            com.touch()
            this.props.addBorrowerInsurance()
        }

        return (
            <div className="incard m-0 mb-3  px-1" key={key}>
                <Row>
                    <Col className="pl-2">
                        <Form.Row className="" >
                            <Col xs="auto" className="mr-2 p-0">
                                <Form.Group controlId={"CompanyName" + index} className="text-left  m-0 p-0" >
                                    <Form.Label className="text-left" >Issuer:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"issuer" + index}
                                        type="text"
                                        defaultValue={this.props.application.assetsandliabilities.lifeinsurance[index].issuer}
                                        onChange={changeBorrowerInsuranceValue("issuer")}

                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide issuer.
                            </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="text-left  mr-2  p-0">
                                <Form.Group controlId={"face" + index} className="text-left  m-0 p-0" >
                                    <Form.Label className="text-left" >Net Value:</Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        required
                                        onBlur={this.recalcAssets}
                                        name={"amount" + index}
                                        defaultValue={this.props.application.assetsandliabilities.lifeinsurance[index].netvalue}
                                        onChange={changeBorrowerInsuranceValue("netvalue")}
                                        min={1}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your annual income.
                            </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="text-left  p-0">
                                <Form.Group controlId={"face" + index} className="text-left  m-0 p-0" >
                                    <Form.Label className="text-left" >Face Value:</Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        required
                                        name={"amount" + index}
                                        defaultValue={this.props.application.assetsandliabilities.lifeinsurance[index].facevalue}
                                        onChange={changeBorrowerInsuranceValue("facevalue")}
                                        min={1}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your annual income.
                            </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                                <Col xs="auto" >
                                    <Form.Label className="text-left" >Ownership</Form.Label>
                                    <Form.Group controlId="employment" >

                                        <Form.Control name="employment" as="select" size="sm"
                                            defaultValue={attribution}
                                            required
                                            onChange={changeBorrowerInsuranceValue("attribution")}
                                        >
                                            <option value="">...</option>
                                            <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                            <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                            <option value="3">Joint</option>

                                        </Form.Control>
                                    </Form.Group>
                                </Col> : ""
                            }


                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top  m-0 p-0">
                        <i hidden={index !== this.props.application.assetsandliabilities.lifeinsurance.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addBorrowerInsurance} ></i>
                        <i hidden={index === this.props.application.assetsandliabilities.lifeinsurance.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeBorrowerInsurance} ></i>
                    </Col>
                </Row>
            </div>
        )
    }
    processGifts = (val, index) => {
        if (null === val)
            return ""

        let assettype = val.assettype;
        let deposited = val.deposited;
        let source = val.source;
        let attribution = val.attribution;
        let value = val.value;
        let key = val.key


        return this.renderGifts(index, assettype, deposited, source, attribution, value, key)
    }

    renderGifts = (index, assettype, deposited, source, attribution, value, key) => {
        let removeGift = (e) => {
            this.props.removeBorrowerGift(index)

        }
        let addGift = (e) => {
            this.props.addBorrowerGift()
        }
        let changeGiftValue = (verb) => {
            return e => {
                this.props.updateBorrowerGift(e.target.value, verb, index)
            }
        }
        let changeGiftAmount = e => {
            this.props.updateBorrowerGift(e.target.value, "value", index)
        }
        let getSourceDescription = () => {
            let t = this.props.application.assetsandliabilities.gifts[index].source
            if (t === "Other")
                return "Description:"
            let help = ""
            com.fundssourcetype.forEach(st => {
                if (t === st[0]) {
                    help = st[2]
                }
            })

            if (help === "")
                return "Description"
            return tooltip("Description", help, "auto", "")
        }
        let onSourceDescription = (e) => {
            this.props.updateBorrowerGift(e.target.value, "sourcedescription", index)
        }
        let changeAttribution = (e) => {
            this.props.updateBorrowerGift(e.target.value, "attribution", index)
        }        
        let changeGiftSource = src => {

            this.props.updateBorrowerGift(src, "source", index)
            let desc = 0
            com.fundssourcetype.forEach(
                st => {
                    if (st[0] === src) {
                        desc = st[1]
                        if (desc === "")
                            desc = com.unCamelCase(st[0])
                    }
                }
            )
            if (src === "Other")
                desc = 0

            this.props.updateBorrowerGift(desc, "sourcedescription", index)
        }
        let getDeposited = () => {
            if (true === this.props.application.assetsandliabilities.gifts[index].deposited)
                return "deposited"
            if (false === this.props.application.assetsandliabilities.gifts[index].deposited)
                return "notdeposited"
            return ""
        }
        let changeDeposited = event => {
            let e = event.target.value
            if (e === "deposited") {
                this.props.updateBorrowerGift(true, "deposited", index)
            } else if (e === "notdeposited") {
                this.props.updateBorrowerGift(false, "deposited", index)
            } else
                this.props.updateBorrowerGift(null, "deposited", index)

        }
        return <div key={"gift" + key} className="incard m-0 mb-3  px-1">
            <Row className="p-0">
                <Col className="p-0 px-1">
                    <Form.Row>
                        <Col xs="auto">
                            <Form.Label className="text-left" >Asset Type:</Form.Label>
                            <Form.Group controlId={"assettype" + key} >

                                <Form.Control name={"assettype" + key} as="select" size="sm"
                                    defaultValue={assettype}
                                    onChange={changeGiftValue("assettype")}
                                >
                                    <option value="GiftOfCash">Gift of Cash</option>
                                    <option value="GiftOfPropertyEquity">Gift Of Equity</option>
                                    <option value="Grant">Grant</option>

                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Label className="text-left" >{tooltip( "Is Deposited?", "Is it already depposited in your bank account", "auto", "")}</Form.Label>

                            <Form.Group controlId={"deposited" + key} >

                                <Form.Control as="select" size="sm"
                                    defaultValue={getDeposited()}
                                    onChange={changeDeposited}
                                    required
                                >
                                    <option value="">...</option>
                                    <option value="deposited">Deposited</option>
                                    <option value="notdeposited">Not Deposited</option>

                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">


                            <Form.Group controlId={"source" + key}  >
                                <Form.Label className="text-left" > {getSourceDescription()}</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        required
                                        size="sm"
                                        autoFocus
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Select gift type"
                                        value={this.props.application.assetsandliabilities.gifts[index].sourcedescription}
                                        readOnly={this.props.application.assetsandliabilities.gifts[index].source !== "Other"}
                                        onChange={onSourceDescription}
                                        pattern="^.+$"

                                    />
                                    <DropdownButton
                                        as={InputGroup.Append}
                                        variant="dropdown"
                                        title=""
                                        className="state-dropdown-menu"
                                        id={"dropdown-gift" + index}

                                        size="sm"
                                    >   {com.fundssourcetype.map(st => {
                                        let desc = st[1]
                                        if ("" === desc) {
                                            desc = com.unCamelCase(st[0])
                                        }
                                        return (<Dropdown.Item onSelect={changeGiftSource} selected={st[1] === "Other"} key={st[0]} eventKey={st[0]} href="#">{desc}</Dropdown.Item>)

                                    })}


                                    </DropdownButton>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please enter the source of gift!
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId={"GiftAmount" + key} className="text-left  m-0 p-0" >
                                <Form.Label className="text-left" >Market Value:</Form.Label>
                                <NumericalInput
                                    size="sm"
                                    required
                                    onBlur={this.recalcAssets}
                                    defaultValue={this.props.application.assetsandliabilities.gifts[index].value}
                                    onChange={changeGiftAmount}

                                    min={1}
                                />

                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide gift value.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Col>
                {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                                <Col xs="auto" className="pl-2">
                                    <Form.Label className="text-left" >Ownership</Form.Label>
                                    <Form.Group controlId="employment" >

                                        <Form.Control name="employment" as="select" size="sm"
                                            defaultValue={attribution}
                                            onChange={changeAttribution}
                                            required
                                        >
                                            <option value="">...</option>
                                            <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                            <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                            <option value="3">Joint</option>

                                        </Form.Control>
                                    </Form.Group>
                                </Col> : ""

                            }                
                <Col xs="auto" as="div" className="text-right aligh-top  m-0 p-0">
                    <i hidden={index !== this.props.application.assetsandliabilities.gifts.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addGift} ></i>
                    <i hidden={index === this.props.application.assetsandliabilities.gifts.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                    <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeGift} ></i>
                </Col>

            </Row>
        </div>
    }




    processAssets = (val, index) => {
        if (null === val)
            return ""

        let description = val.description;
        let value = val.value;
        let attribution = val.attribution
        let key = val.key

        return this.listOtherAssets(index, description, value, attribution, key)
    }
    addOtherAssets = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerOtherAssets(0, this.props.who)
        } else {
            this.props.addBorrowerOtherAsset(0, this.props.who)
        }
    }

    listOtherAssets = (index, description, value, attribution, key) => {
        index = parseInt(index)
        let addBorrowerOtherAsset = (event) => {
            com.touch()
            this.props.addBorrowerOtherAsset(event, this.props.who)
        }
        let removeBorrowerOtherAsset = (event) => {
            com.touch()
            this.props.removeBorrowerOtherAsset(event, index, this.props.who)
        }

        let changeBorrowerOtherAssetValue = (event) => {
            com.touch()
            this.props.changeBorrowerOtherAssetValue(event, index, this.props.who)
        }
        let changeBorrowerOtherAssetAttribution = (event) => {
            com.touch()
            this.props.changeBorrowerOtherAssetAttribution(event, index)
        }
        let otherAssetDescriptionChange = event => {
            com.touch()
            this.props.changeBorrowerOtherAsset(event.target.value, "description", index)
        }
        let otherAssetSelect = event => {
            com.touch()
            this.props.changeBorrowerOtherAsset(event, "assettype", index)
            let description = ""
            com.otherassetsandcredits.forEach(st => {
                if (event === st[0]) {
                    description = st[1]
                }
            })
            if (event === "Other")
                this.props.changeBorrowerOtherAsset("", "description", index)
            else
                this.props.changeBorrowerOtherAsset(description, "description", index)
            getCurrentState()

        }
        let getDescription = () => {
            let t = this.props.application.assetsandliabilities.otherassets[index].assettype
            if (t === "Other")
                return "Description:"
            let help = ""
            com.otherassetsandcredits.forEach(st => {
                if (t === st[0]) {
                    help = st[2]
                }
            })

            if (help === "")
                return "Description:"
            return tooltip("Description", help, "auto", "")
        }
        return (
            <div className="incard  m-0 mb-3  px-1" key={"other" + key}>
                <Row className="p-0">
                    <Col className="p-0 px-1">
                        <Form.Row >
                            <Col xs="auto">
                                <Form.Group controlId="unmarriedtype"  >
                                    <Form.Label className="text-left" > {getDescription()}</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            required
                                            size="sm"
                                            autoFocus
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Choose asset type"
                                            value={this.props.application.assetsandliabilities.otherassets[index].description}
                                            readOnly={this.props.application.assetsandliabilities.otherassets[index].assettype !== "Other"}
                                            onChange={otherAssetDescriptionChange}
                                            pattern="^.+$"

                                        />
                                        <DropdownButton
                                            as={InputGroup.Append}
                                            variant="dropdown"
                                            title=""
                                            className="state-dropdown-menu"
                                            id="dropdown-relationship"

                                            size="sm"
                                        >   {com.otherassetsandcredits.map(st => {
                                            return (<Dropdown.Item onSelect={otherAssetSelect} selected={st[1] === "Other"} key={st[0]} eventKey={st[0]} href="#">{st[1]}</Dropdown.Item>)

                                        })}


                                        </DropdownButton>
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please enter asset description!
                </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto"  >
                                <Form.Group controlId="Account" className="text-left" >
                                    <Form.Label className="text-left" >Value:</Form.Label>
                                    <NumericalInput isValid={false}
                                        size="sm"
                                        required
                                        name={"value" + index}
                                        defaultValue={this.props.application.assetsandliabilities.otherassets[index].value}
                                        onChange={changeBorrowerOtherAssetValue}
                                        onBlur={this.recalcAssets}
                                        min={1}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your middle name.
                            </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                                <Col xs="2 ml-2">
                                    <Form.Label className="text-left" >Ownership</Form.Label>
                                    <Form.Group controlId="employment" >

                                        <Form.Control name="employment" as="select" size="sm"
                                            defaultValue={attribution}
                                            onChange={changeBorrowerOtherAssetAttribution}
                                            required
                                        >
                                            <option value="">...</option>
                                            <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                            <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                            <option value="3">Joint</option>

                                        </Form.Control>
                                    </Form.Group>
                                </Col> : ""
                            }
                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top  m-0 p-0">
                        <i hidden={index !== this.props.application.assetsandliabilities.otherassets.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addBorrowerOtherAsset} ></i>
                        <i hidden={index === this.props.application.assetsandliabilities.otherassets.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeBorrowerOtherAsset} ></i>
                    </Col>
                </Row>
            </div>
        )
    }


    render() {
        let labelJoint = `More about ${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname} and 
        ${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}'s finances`
        let labelOne = `More about ${this.props.application[this.props.who].firstname} ${this.props.application[this.props.who].lastname}'s finances`
        let iwe = (this.props.application.hascoborrower === "borrower" && this.props.application.married) ?
            "we" : "I"

        let addBorrowerGift = () => {
            this.props.addBorrowerGift()
        }
        let clearBorrowerGift = () => {
            this.props.clearBorrowerGift()
        }
        return (
            <div className="text-left">
                <h2 className=" heading">
                    {(this.props.application.hascoborrower === "alone" && this.props.application.married) ?
                        labelJoint : labelOne}
{/*
<span className="hideonphone700" style={{ marginTop: "4px", fontSize: "0.7em", fontWeight: "normal", position: "absolute", right: "4em" }}> assets: ${this.calculateAssets()}</span>
        */}
                </h2>

                {/*
                    <div className="viewport mt-3">
                        <Form.Row>
                            <Col><div className="sectionquestion">Do you have life insurance?</div>
                                <Form.Group>
                                    <fieldset>
                                        <div className="d-flex mt-1">

                                            <div className="mr-3 ">
                                                <input required onChange={addBorrowerInsurance} value={true} checked={this.props.application.assetsandliabilities.lifeinsurance !== null && this.props.application.assetsandliabilities.lifeinsurance.length > 0} type="radio" id="havelifeinsurance" name="havelifeinsurance"></input>
                                                <label className="zeitro-radio divlink" htmlFor="havelifeinsurance">Yes, {iwe} do</label></div>
                                            <div><input required onChange={clearBorrowerInsurance} value={false} checked={this.props.application.assetsandliabilities.lifeinsurance !== null && this.props.application.assetsandliabilities.lifeinsurance.length === 0} type="radio" id="havelifeinsurancenot" name="havelifeinsurance"></input>
                                                <label className="zeitro-radio divlink" htmlFor="havelifeinsurancenot">No, {iwe} don't</label></div>
                                        </div>
                                    </fieldset>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please specify if you have life insurance
                                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        {this.props.application.assetsandliabilities.lifeinsurance === null ? "" : this.props.application.assetsandliabilities.lifeinsurance.map(this.processInsurance)}
                    </div>
                */}
                <div className="viewport mt-3">
                    <Form.Row>
                        <Col><div className="sectionquestion">Have you received any gifts within the last 2 months, or do you expect to receive gifts to help with obtaining this loan?</div>
                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mt-1">

                                        <div className="mr-3 ">
                                            <input required onChange={addBorrowerGift} value={true} checked={this.props.application.assetsandliabilities.gifts !== null && this.props.application.assetsandliabilities.gifts.length > 0} type="radio" id="havegifts" name="havegifts"></input>
                                            <label className="zeitro-radio divlink" htmlFor="havegifts">Yes, {iwe} have</label></div>
                                        <div><input required onChange={clearBorrowerGift} value={false} checked={this.props.application.assetsandliabilities.gifts !== null && this.props.application.assetsandliabilities.gifts.length === 0} type="radio" id="havegiftsnot" name="havegifts"></input>
                                            <label className="zeitro-radio divlink" htmlFor="havegiftsnot">No, {iwe} haven't</label></div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please specify if you received gifts for this loan
                                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    {this.props.application.assetsandliabilities.gifts === null ? "" : this.props.application.assetsandliabilities.gifts.map(this.processGifts)}
                </div>

                <div className="viewport mt-3">
                    <Form.Row>
                        <Col><div className="sectionquestion">Do you have other assets or credits that you'd like to be used to qualify for this loan?</div>
                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mt-1">

                                        <div className="mr-3 ">
                                            <input required onChange={this.addOtherAssets} value={true} defaultChecked={null !== this.props.application.assetsandliabilities.otherassets && this.props.application.assetsandliabilities.otherassets.length !== 0} type="radio" id="haveother" name="haveother"></input>
                                            <label className="zeitro-radio divlink" htmlFor="haveother">Yes, {iwe} do</label>
                                        </div>
                                        <div>
                                            <input required onChange={this.addOtherAssets} value={false} defaultChecked={null !== this.props.application.assetsandliabilities.otherassets && this.props.application.assetsandliabilities.otherassets.length === 0} type="radio" id="haveothernot" name="haveother"></input>
                                            <label className="zeitro-radio divlink" htmlFor="haveothernot">No, {iwe} don't</label>
                                        </div>

                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please specify if you have other assets
                                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    {null === this.props.application.assetsandliabilities.otherassets ? "" : this.props.application.assetsandliabilities.otherassets.map(this.processAssets)}

                </div>
                <div className="mb-3">
                    &nbsp;
                    </div>
            </div>
        )
    }
}
MoreFinances = connect(mapStateToPropsStocks, mapDispatchToPropsStocks, null, { forwardRef: true })(MoreFinances)


