

import "../../App.css";
import "./CheckGroup.css"
import React, { useState } from 'react';
import { Form } from "react-bootstrap";

export default function SecondaryButton(props) {
    const { mode, type, value, data, onChangeSelect, style } = props;
    const [checkValue, setCheckValue] = useState([]);

    const achange = ev => {
        // multi select
        if (type === 'checkbox') {
            const chooseArr = value.map(res => res.value)
            setCheckValue(chooseArr)
            if (chooseArr.includes(ev.value)) {
                let result = chooseArr.findIndex(item => {
                    return item === ev.value
                });
                value.splice(result, 1);
            } else {
                value.push({ label: ev.label, value: ev.value })
            }
            onChangeSelect(value)
        } else {
            // single select
            onChangeSelect(ev.value)
        }
    }

    let clsname = ""
    if (props.direction == "horizontal") {
        clsname = `check-group-wrap ${mode === 'document-checkGroup' ? 'document-checkGroup' : ''}`
    }
    return (
        <div className={clsname} style={style}>
            {type === 'checkbox' ? (
                data.map(ele => (
                    <div key={ele.value} className='check-group-box' style={{ border: value.some(e => e.value === ele.value) ? '1px solid #325CEB' : '' }} onClick={() => achange(ele)}>
                        <div className="circle" style={{ background: value.some(e => e.value === ele.value) ? '#325CEB' : '' }}></div>
                        <div className="dec">{ele.label}</div>
                        <Form.Control required/>
                    </div>
                ))
            ) : (
                data.map(ele => (
                    <div key={ele.value} className='check-group-box' style={{ border: value === ele.value ? '1px solid #325CEB' : '' }} onClick={() => achange(ele)}>
                        <div className="circle" style={{ background: value === ele.value ? '#325CEB' : '' }}></div>
                        <div className="dec">{ele.label}</div>
                    </div>
                ))
            )
            }
        </div>
    )
}

export function CheckGroupWithImage(props) {
    const { type, value, data, onChangeSelect, style, imageWidth, imageHeight } = props;
    const [checkValue, setCheckValue] = useState([]);

    const achange = ev => {
        // multi-select
        if (type === 'checkbox') {
            const chooseArr = value.map(res => res.value)
            setCheckValue(chooseArr)
            if (chooseArr.includes(ev.value)) {
                let result = chooseArr.findIndex(item => {
                    return item === ev.value
                });
                value.splice(result, 1);
            } else {
                value.push({ label: ev.label, value: ev.value })
            }
            onChangeSelect(value)
        } else {
            // single-select
            onChangeSelect(ev.value)
        }
    }

    let clsname = ""
    if (props.direction == "horizontal") {
        clsname = "check-group-wrap"
    }
    return (
        <div className={clsname} style={style}>
            {type === 'checkbox' ? (
                data.map(ele => (
                    <div key={ele.value} className='check-group-box' style={{ border: value.some(e => e.value === ele.value) ? '1px solid #325CEB' : '' }} onClick={() => achange(ele)}>
                        <div className="circle" style={{ background: value.some(e => e.value === ele.value) ? '#325CEB' : '' }}></div>
                        <div className="dec">{ele.label}</div>
                    </div>
                ))
            ) : (
                data.map(ele => (
                    <div key={ele.value} className='check-group-box-with-image' style={{ border: value === ele.value ? '1px solid #325CEB' : '' }} onClick={() => achange(ele)}>
                        <div style={{display:"flex"}}>
                            <div className="circle" style={{ background: value === ele.value ? '#325CEB' : '' }}></div>
                            <div className="dec">{ele.label}</div>
                        </div>
                        <div><img src={ele.image} style={{width:typeof imageWidth!== "undefined" ? imageWidth: "52px",height:typeof imageHeight!== "undefined" ? imageHeight: "45px", marginLeft:"39px", marginTop:"10px"}}/></div>
                    </div>
                ))
            )
            }
        </div>
    )
}
