import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

const Form1040 = ({ doc, onFocus, onChangeTextInput }) => {
  return (
    <>
      <h2>Form 1040</h2>
      <Row>
        <Form.Group as={Col} controlId="Year">
          <Form.Label>Year</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['Year']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['Year']['BBox'], doc['Form1040']['Year']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'Year', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="FillingStatus">
          <Form.Label>Filling status</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['FillingStatus']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['FillingStatus']['BBox'], doc['Form1040']['FillingStatus']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'FillingStatus', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="FirstName">
          <Form.Label>First name</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['FirstName']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['FirstName']['BBox'], doc['Form1040']['FirstName']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'FirstName', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="LastName">
          <Form.Label>Last name</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['LastName']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['LastName']['BBox'], doc['Form1040']['LastName']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'LastName', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="SSN">
          <Form.Label>SSN</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['SSN']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['SSN']['BBox'], doc['Form1040']['SSN']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'SSN', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="SpouseFirstName">
          <Form.Label>Spouse first name</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['SpouseFirstName']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['SpouseFirstName']['BBox'], doc['Form1040']['SpouseFirstName']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'SpouseFirstName', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="SpouseLastName">
          <Form.Label>Spouse last name</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['SpouseLastName']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['SpouseLastName']['BBox'], doc['Form1040']['SpouseLastName']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'SpouseLastName', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="SpouseSSN">
          <Form.Label>Spouse SSN</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['SpouseSSN']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['SpouseSSN']['BBox'], doc['Form1040']['SpouseSSN']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'SpouseSSN', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="AddressStreet">
          <Form.Label>Address street</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['AddressStreet']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['AddressStreet']['BBox'], doc['Form1040']['AddressStreet']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'AddressStreet', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="AddressApt">
          <Form.Label>Apartment</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['AddressApt']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['AddressApt']['BBox'], doc['Form1040']['AddressApt']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'AddressApt', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="AddressCityStateZip">
          <Form.Label>City, State and Zip</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['AddressCityStateZip']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['AddressCityStateZip']['BBox'], doc['Form1040']['AddressCityStateZip']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'AddressCityStateZip', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Occupation">
          <Form.Label>Occupation</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['Occupation']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['Occupation']['BBox'], doc['Form1040']['Occupation']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'Occupation', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="OtherIncome">
          <Form.Label>Other income</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['OtherIncome']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['OtherIncome']['BBox'], doc['Form1040']['OtherIncome']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'OtherIncome', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="PensionsAnnuities">
          <Form.Label>Pensions and annuities</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['PensionsAnnuities']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['PensionsAnnuities']['BBox'], doc['Form1040']['PensionsAnnuities']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'PensionsAnnuities', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="PhoneNumber">
          <Form.Label>Phone number</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['PhoneNumber']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['PhoneNumber']['BBox'], doc['Form1040']['PhoneNumber']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'PhoneNumber', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="CapitalGain">
          <Form.Label>Capital gain</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['CapitalGain']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['CapitalGain']['BBox'], doc['Form1040']['CapitalGain']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'CapitalGain', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="SocialSecurityBenefits">
          <Form.Label>Social security benefits</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['SocialSecurityBenefits']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['SocialSecurityBenefits']['BBox'], doc['Form1040']['SocialSecurityBenefits']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'SocialSecurityBenefits', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="TotalIncome">
          <Form.Label>Total income</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['TotalIncome']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['TotalIncome']['BBox'], doc['Form1040']['TotalIncome']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'TotalIncome', 'Value'])} />
        </Form.Group>
        <Form.Group as={Col} controlId="WagesSalariesTips">
          <Form.Label>Wages, salaries, tips, etc.</Form.Label>
          <Form.Control defaultValue={doc['Form1040']['WagesSalariesTips']['Value']} type="text" onFocus={() => onFocus(doc['Form1040']['WagesSalariesTips']['BBox'], doc['Form1040']['WagesSalariesTips']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['Form1040', 'WagesSalariesTips', 'Value'])} />
        </Form.Group>
      </Row>
      {doc['ScheduleC'] &&
        <>
          <h2>Schedule C</h2>
          <Row>
            <Form.Group as={Col} controlId="ProprietorName">
              <Form.Label>Proprietor name</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['ProprietorName']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['ProprietorName']['BBox'], doc['ScheduleC']['ProprietorName']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'ProprietorName', 'Value'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="EmployerIdentificationNumber">
              <Form.Label>Employer identification number</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['EmployerIdentificationNumber']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['EmployerIdentificationNumber']['BBox'], doc['ScheduleC']['EmployerIdentificationNumber']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'EmployerIdentificationNumber', 'Value'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="Wages">
              <Form.Label>Wages</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['Wages']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['Wages']['BBox'], doc['ScheduleC']['Wages']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'Wages', 'Value'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="SocialSecurityNumber">
              <Form.Label>SSN</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['SocialSecurityNumber']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['SocialSecurityNumber']['BBox'], doc['ScheduleC']['SocialSecurityNumber']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'SocialSecurityNumber', 'Value'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="BusinessName">
              <Form.Label>Business name</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['BusinessName']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['BusinessName']['BBox'], doc['ScheduleC']['BusinessName']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'BusinessName', 'Value'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="BusinessAddress">
              <Form.Label>Business address</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['BusinessAddress']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['BusinessAddress']['BBox'], doc['ScheduleC']['BusinessAddress']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'BusinessAddress', 'Value'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="OtherIncomeScheduleC">
              <Form.Label>Other income</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['OtherIncome']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['OtherIncome']['BBox'], doc['ScheduleC']['OtherIncome']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'OtherIncome', 'Value'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="Depletion">
              <Form.Label>Depletion</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['Depletion']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['Depletion']['BBox'], doc['ScheduleC']['Depletion']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'Depletion', 'Value'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="DepreciationAndSection179ExpenseDeduction">
              <Form.Label>Depreciation and section 179 expense deduction</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['DepreciationAndSection179ExpenseDeduction']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['DepreciationAndSection179ExpenseDeduction']['BBox'], doc['ScheduleC']['DepreciationAndSection179ExpenseDeduction']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'DepreciationAndSection179ExpenseDeduction', 'Value'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="DeductibleMeals">
              <Form.Label>Deductible meals</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['DeductibleMeals']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['DeductibleMeals']['BBox'], doc['ScheduleC']['DeductibleMeals']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'DeductibleMeals', 'Value'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="ExpensesForBusinessUseOfYourHome">
              <Form.Label>Expenses for business use of your home</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['ExpensesForBusinessUseOfYourHome']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['ExpensesForBusinessUseOfYourHome']['BBox'], doc['ScheduleC']['ExpensesForBusinessUseOfYourHome']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'ExpensesForBusinessUseOfYourHome', 'Value'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="NetProfitOrLoss">
              <Form.Label>Net profit or loss</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['NetProfitOrLoss']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['NetProfitOrLoss']['BBox'], doc['ScheduleC']['NetProfitOrLoss']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'NetProfitOrLoss', 'Value'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="TotalMilesDrivenWithVehicleForBusiness">
              <Form.Label>Total miles driven with vehicle for business</Form.Label>
              <Form.Control defaultValue={doc['ScheduleC']['TotalMilesDrivenWithVehicleForBusiness']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleC']['TotalMilesDrivenWithVehicleForBusiness']['BBox'], doc['ScheduleC']['TotalMilesDrivenWithVehicleForBusiness']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleC', 'TotalMilesDrivenWithVehicleForBusiness', 'Value'])} />
            </Form.Group>
          </Row>
        </>
      }
      {doc['ScheduleE'] && <>
        <h2>Schedule E</h2>
        <Row>
          <Form.Group as={Col} controlId="NamesShownOnReturn">
            <Form.Label>Names shown on return</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['NamesShownOnReturn']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['NamesShownOnReturn']['BBox'], doc['ScheduleE']['NamesShownOnReturn']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'NamesShownOnReturn', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="YourSocialSecurityNumber">
            <Form.Label>Your social security number</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['YourSocialSecurityNumber']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['YourSocialSecurityNumber']['BBox'], doc['ScheduleE']['YourSocialSecurityNumber']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'YourSocialSecurityNumber', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="PhysicalAddressOfEachProperty_A">
            <Form.Label>Physical address of each property (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['PhysicalAddressOfEachProperty_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['PhysicalAddressOfEachProperty_A']['BBox'], doc['ScheduleE']['PhysicalAddressOfEachProperty_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'PhysicalAddressOfEachProperty_A', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="PhysicalAddressOfEachProperty_B">
            <Form.Label>Physical address of each property (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['PhysicalAddressOfEachProperty_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['PhysicalAddressOfEachProperty_B']['BBox'], doc['ScheduleE']['PhysicalAddressOfEachProperty_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'PhysicalAddressOfEachProperty_B', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="PhysicalAddressOfEachProperty_C">
            <Form.Label>Physical address of each property (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['PhysicalAddressOfEachProperty_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['PhysicalAddressOfEachProperty_C']['BBox'], doc['ScheduleE']['PhysicalAddressOfEachProperty_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'PhysicalAddressOfEachProperty_C', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="TypeOfProperty_A">
            <Form.Label>Type of property (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['TypeOfProperty_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['TypeOfProperty_A']['BBox'], doc['ScheduleE']['TypeOfProperty_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'TypeOfProperty_A', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="FairRentalDays_A">
            <Form.Label>Fair rental days (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['FairRentalDays_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['FairRentalDays_A']['BBox'], doc['ScheduleE']['FairRentalDays_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'FairRentalDays_A', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="PersonalUseDays_A">
            <Form.Label>Personal use days (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['PersonalUseDays_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['PersonalUseDays_A']['BBox'], doc['ScheduleE']['PersonalUseDays_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'PersonalUseDays_A', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="TypeOfProperty_B">
            <Form.Label>Type of property (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['TypeOfProperty_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['TypeOfProperty_B']['BBox'], doc['ScheduleE']['TypeOfProperty_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'TypeOfProperty_B', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="FairRentalDays_B">
            <Form.Label>Fair rental days (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['FairRentalDays_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['FairRentalDays_B']['BBox'], doc['ScheduleE']['FairRentalDays_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'FairRentalDays_B', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="PersonalUseDays_B">
            <Form.Label>Personal use days (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['PersonalUseDays_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['PersonalUseDays_B']['BBox'], doc['ScheduleE']['PersonalUseDays_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'PersonalUseDays_B', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="TypeOfProperty_C">
            <Form.Label>Type of property (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['TypeOfProperty_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['TypeOfProperty_C']['BBox'], doc['ScheduleE']['TypeOfProperty_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'TypeOfProperty_C', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="FairRentalDays_C">
            <Form.Label>Fair rental days (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['FairRentalDays_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['FairRentalDays_C']['BBox'], doc['ScheduleE']['FairRentalDays_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'FairRentalDays_C', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="PersonalUseDays_C">
            <Form.Label>Personal use days (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['PersonalUseDays_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['PersonalUseDays_C']['BBox'], doc['ScheduleE']['PersonalUseDays_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'PersonalUseDays_C', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="RentsReceived_A">
            <Form.Label>Rents received (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['RentsReceived_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['RentsReceived_A']['BBox'], doc['ScheduleE']['RentsReceived_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'RentsReceived_A', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="RoyaltiesReceived_A">
            <Form.Label>Royalties received (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['RoyaltiesReceived_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['RoyaltiesReceived_A']['BBox'], doc['ScheduleE']['RoyaltiesReceived_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'RoyaltiesReceived_A', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Insurance_A">
            <Form.Label>Insurance (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Insurance_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Insurance_A']['BBox'], doc['ScheduleE']['Insurance_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Insurance_A', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="RentsReceived_B">
            <Form.Label>Rents received (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['RentsReceived_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['RentsReceived_B']['BBox'], doc['ScheduleE']['RentsReceived_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'RentsReceived_B', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="RoyaltiesReceived_B">
            <Form.Label>Royalties received (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['RoyaltiesReceived_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['RoyaltiesReceived_B']['BBox'], doc['ScheduleE']['RoyaltiesReceived_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'RoyaltiesReceived_B', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Insurance_B">
            <Form.Label>Insurance (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Insurance_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Insurance_B']['BBox'], doc['ScheduleE']['Insurance_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Insurance_B', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="RentsReceived_C">
            <Form.Label>Rents received (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['RentsReceived_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['RentsReceived_C']['BBox'], doc['ScheduleE']['RentsReceived_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'RentsReceived_C', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="RoyaltiesReceived_C">
            <Form.Label>Royalties received (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['RoyaltiesReceived_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['RoyaltiesReceived_C']['BBox'], doc['ScheduleE']['RoyaltiesReceived_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'RoyaltiesReceived_C', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Insurance_C">
            <Form.Label>Insurance (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Insurance_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Insurance_C']['BBox'], doc['ScheduleE']['Insurance_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Insurance_C', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="MortgageInterest_A">
            <Form.Label>Mortgage interest (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['MortgageInterest_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['MortgageInterest_A']['BBox'], doc['ScheduleE']['MortgageInterest_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'MortgageInterest_A', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Taxes_A">
            <Form.Label>Taxes (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Taxes_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Taxes_A']['BBox'], doc['ScheduleE']['Taxes_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Taxes_A', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Depletion_A">
            <Form.Label>Depletion (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Depletion_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Depletion_A']['BBox'], doc['ScheduleE']['Depletion_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Depletion_A', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="MortgageInterest_B">
            <Form.Label>Mortgage interest (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['MortgageInterest_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['MortgageInterest_B']['BBox'], doc['ScheduleE']['MortgageInterest_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'MortgageInterest_B', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Taxes_B">
            <Form.Label>Taxes (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Taxes_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Taxes_B']['BBox'], doc['ScheduleE']['Taxes_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Taxes_B', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Depletion_B">
            <Form.Label>Depletion (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Depletion_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Depletion_B']['BBox'], doc['ScheduleE']['Depletion_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Depletion_B', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="MortgageInterest_C">
            <Form.Label>Mortgage interest (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['MortgageInterest_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['MortgageInterest_C']['BBox'], doc['ScheduleE']['MortgageInterest_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'MortgageInterest_C', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Taxes_C">
            <Form.Label>Taxes (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Taxes_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Taxes_C']['BBox'], doc['ScheduleE']['Taxes_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Taxes_C', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Depletion_C">
            <Form.Label>Depletion (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['Depletion_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['Depletion_C']['BBox'], doc['ScheduleE']['Depletion_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'Depletion_C', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="OtherExpenses_A">
            <Form.Label>Other expenses (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['OtherExpenses_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['OtherExpenses_A']['BBox'], doc['ScheduleE']['OtherExpenses_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'OtherExpenses_A', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="TotalExpenses_A">
            <Form.Label>Total expenses (A)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['TotalExpenses_A']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['TotalExpenses_A']['BBox'], doc['ScheduleE']['TotalExpenses_A']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'TotalExpenses_A', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="OtherExpenses_B">
            <Form.Label>Other expenses (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['OtherExpenses_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['OtherExpenses_B']['BBox'], doc['ScheduleE']['OtherExpenses_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'OtherExpenses_B', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="TotalExpenses_B">
            <Form.Label>Total expenses (B)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['TotalExpenses_B']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['TotalExpenses_B']['BBox'], doc['ScheduleE']['TotalExpenses_B']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'TotalExpenses_B', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="OtherExpenses_C">
            <Form.Label>Other expenses (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['OtherExpenses_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['OtherExpenses_C']['BBox'], doc['ScheduleE']['OtherExpenses_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'OtherExpenses_C', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="TotalExpenses_C">
            <Form.Label>Total expenses (C)</Form.Label>
            <Form.Control defaultValue={doc['ScheduleE']['TotalExpenses_C']['Value']} type="text" onFocus={() => onFocus(doc['ScheduleE']['TotalExpenses_C']['BBox'], doc['ScheduleE']['TotalExpenses_C']['Page'])} onChange={e => onChangeTextInput('form1040.pdf', e.target.value, ['ScheduleE', 'TotalExpenses_C', 'Value'])} />
          </Form.Group>
        </Row>
      </>}
    </>
  );
}

export default Form1040;