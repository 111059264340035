import React, { useState } from 'react';
import { Spin, Image } from 'antd';
import { Form, Col, Row, Button, InputGroup, Container } from "react-bootstrap";

import * as com from "../../../Common"
import "./request.css";
import DividerWithText from "../../../Common/components/DividerLineWithText";
export default function Request(props) {
    const [borrowerName, setBorrowerName] = useState("")
    const [borrowerPhone, setBorrowerPhone] = useState("")
    const [borrowerEmail, setBorrowerEmail] = useState("")
    const [borrowerPreferredTime, setBorrowerPreferredTime] = useState("")
    const [borrowerPreferredTimeZone, setBorrowerPreferredTimeZone] = useState("")
    const [loadingContact, setLoadingContact] = useState(false)
    const loName = props.basicInfo.loName
    const loEmail = props.aboutInfo.loEmail
    const loNMLS = props.basicInfo.nmls
    const loPhone = props.basicInfo.loPhone
    const email = props.aboutInfo.email
    const phone = props.aboutInfo.phone
    // const img=props.aboutInfo.file[0]
    // console.log(props.basicInfo);
    let avatarSrc = "/images/avatar.png"

    const program = props.basicInfo.program ? props.basicInfo.program : "celebrity15K"
    const submitContact = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setLoadingContact(true)
        let url = "/borrower/contactme"
        if (props.loid === "gmcc") {
            url = "/borrower/gmcc/contactme"
        }
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                BorrowerName: borrowerName,
                BorrowerPhone: borrowerPhone,
                BorrowerEmail: borrowerEmail,
                BorrowerPreferredTimeZone: borrowerPreferredTimeZone,
                BorrowerPreferredTime: borrowerPreferredTime,

                LOID: props.loid,
                CustomerID: props.customerid,
            })
        })
            .then((res) => {
                if (!res.ok) {
                    setLoadingContact(false)
                    console.log(res);
                    props.handleSubmit('error')
                    return
                }
                setLoadingContact(false)
                props.handleSubmit('success')
            })
            .catch((err) => {
                console.error(err);
                setLoadingContact(false)
                props.handleSubmit('error')
            });

    }
    const contactMe = <Row className={props.withoutBanner ? '' : 'mt-5 mb-5'}>
        <Col></Col>
        <Col md={props.withoutBanner ? 10 : 12} xs={12} className="text-left">
            <div style={props.withoutBanner && {fontSize: 32, fontWeight: 600, color: '#222', lineHeight: 'normal', marginBottom: 38, fontFamily: 'Playfair Display'}} className={`contactmeForm-title ${props.withoutBanner ? '' : 'contactmeForm-title-mb'}`}>Request a call back </div>
            {/* {loEmail !== null &&
        <div className="callback-lo">
          <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{ width: 30 }} />Your GMCC Loan Officer</div>
          <div className="callback-loinfo">
            {props.loid !== "gmcc" && <div className="callbackTitle-4">{loName}</div>}
            <div className="callbackTitle-4">{loEmail}</div>
            {props.loid !== "gmcc" && <div className="callbackTitle-4">{loPhone !== null ? com.fixPhoneInput(loPhone) : ""}</div>}
            <div className="callbackTitle-4">NMLS #{loNMLS}</div>
          </div>
        </div>} */}
            <Form onSubmit={submitContact} className={`${props.withoutBanner ? 'contactmeModalForm' : ''} contactmeForm`}>
                <Form.Group controlId="name">
                    <Form.Label className="callbackTitle-2">Your name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" onChange={e => setBorrowerName(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="phone">
                    <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
                    <Form.Control type="text" placeholder="Enter your phone number" onChange={e => setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required />
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Label className="callbackTitle-2">Your email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" onChange={e => setBorrowerEmail(e.target.value)} required />
                </Form.Group>
                <div className="d-flex w-100 contactmeTime-mobileAdjuster">
                    <Form.Group controlId="email" className="w-100 mr-2">
                        <Form.Label className="callbackTitle-2">Preferred contact time</Form.Label>
                        <Form.Control
                            as="select"
                            size="sm"
                            value={borrowerPreferredTime}
                            onChange={(e) => setBorrowerPreferredTime(e.target.value)}
                            type="select"
                            required
                        >
                            <option value="">Select your preferred time</option>
                            <option value="8am - 9am">8am - 9am</option>
                            <option value="9am - 10am">9am - 10am</option>
                            <option value="10am - 11am">10am - 11am</option>
                            <option value="11am - 12pm">11am - 12pm</option>
                            <option value="12pm - 1pm">12pm - 1pm</option>
                            <option value="1pm - 2pm">1pm - 2pm</option>
                            <option value="2pm - 3pm">2pm - 3pm</option>
                            <option value="3pm - 4pm">3pm - 4pm</option>
                            <option value="4pm - 5pm">4pm - 5pm</option>
                            <option value="5pm - 6pm">5pm - 6pm</option>
                            <option value="6pm - 7pm">6pm - 7pm</option>
                            <option value="7pm - 8pm">7pm - 8pm</option>
                            <option value="8pm - 9pm">8pm - 9pm</option>

                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="email" className=" w-100">
                        <Form.Label className="callbackTitle-2">Time zone</Form.Label>
                        <Form.Control
                            as="select"
                            size="sm"
                            value={borrowerPreferredTimeZone}
                            onChange={(e) => setBorrowerPreferredTimeZone(e.target.value)}
                            type="select"
                            required
                        >
                            <option value="">Select your time zone</option>
                            <option value="Eastern">Eastern UTC-5</option>
                            <option value="Central">Central UTC-6</option>
                            <option value="Mountain">Mountain UTC-7</option>
                            <option value="Pacific">Pacific UTC-8</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <Button style={props.withoutBanner ? {height: 54, marginTop: 16} : {marginTop: 22}} variant="zeitro-primary" type="submit" className="w-100" >
                    {loadingContact ? <div className="gmccSpin"><Spin /></div> : "Submit"}
                </Button>
                <DividerWithText text={"Or"} />
                <Button style={props.withoutBanner ? {height: 54} : {}} variant="zeitro-primary" className="w-100" onClick={() => window.open(getPreQualificationLink(), '_blank')}>
                    Apply Now
                </Button>
            </Form>
        </Col>
        <Col></Col>
    </Row>

    const getPreQualificationLink = () => {
        if (props.customerid === "gmcc") {
            if(props.basicInfo.appLink !== ""){
                return props.basicInfo.appLink
            } else {
                return props.aboutInfo.companyLink
            }
        }
        if (props.basicInfo.appLink !== "") {
        return props.basicInfo.appLink
        }
        if (window.location.host.includes("zeitro.us")) {
        return props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
        }
        if (window.location.host.includes("localhost")) {
        return props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
        }
        return props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    }

    return (
        <div className="request">
            <div style={props.withoutBanner ? {paddingTop: 0 } : {}} className='request_left'>
                {contactMe}
            </div>
            {(props.withoutBanner === undefined || !props.withoutBanner) && <div className='request_right'>
                <img className='green' src="/images/landingpage-dream-icon.png "/>
                <div className='Dream'>Make a Dream.</div>
                <div className='request_info'>
                    <div className='line' />

                    <div className='touch'>

                        <div className='width_line' />
                        <div className='touch_text'>We will be in touch shortly!</div>
                    </div>
                    <div className='information'>

                    <div >
                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                              <img className='info_img' src={typeof props.basicInfo.file !== "undefined" && props.basicInfo.file.length > 0 ? props.basicInfo.file[0].url : avatarSrc}></img>
                            <div className='info_span'>
                                <div>{props.basicInfo.firstname} {props.basicInfo.lastname}</div>
                            </div>
                        </div>
                        <div>{email}</div>

                    </div>


                    <div className='information-right'>
                        <div style={{lineHeight: '40px'}}>NMLS {loNMLS}</div> <div > {phone}</div>
                    </div>


                </div>
                </div>
                {/* <div className='information '>

                    <div >
                        <div style={{ display: 'flex' }}> 
                              <img className='info_img' src={typeof props.basicInfo.file !== "undefined" && props.basicInfo.file.length > 0 ? props.basicInfo.file[0].url : avatarSrc}></img>
                            <div className='info_span'>
                                <div>{props.basicInfo.firstname} {props.basicInfo.lastname}</div>
                            </div>
                        </div>
                        <div>{email}</div>

                    </div>


                    <div >
                        <div>NMLS {loNMLS}</div> <div > {phone}</div>
                    </div>


                </div> */}
            </div>
            }
        </div>
    );
}