import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Multiselect from '../Common/Multiselect';
import NumericalInput from '../NumericalInput'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import * as com from "../Common.js"
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import InputGroup from 'react-bootstrap/InputGroup'
import { tooltip } from '../Common/Tooltip'
import Autocomplete from '../Autocomplete'

const customstyle = {
    multiselectContainer: { // To change css for multiselect (Width,height,etc..)

        fontSize: '14px',
    },
    searchBox: { // To change search box element look
        fontSize: '.875rem',
        minHeight: 'calc(1.5em + .5rem + 2px)',
        padding: '.25rem .5rem',
        marginBottom: '0px !important',

    },
    inputField: { // To change input field position or margin
        margin: '0px'
    },
    chips: { // To change css chips(Selected options)
        background: 'rgba(0,48,116,1)',
        marginBottom: '2px !important',
        fontSize: '12px',
        padding: '2px 10px',
    },
    optionContainer: { // To change css for option container 
        fontSize: '12px',
    }
}
const options = (() => {
    let out = []
    com.downpaymentsources.forEach(x => out.push(x.id))
    return out
}) ()
const mapStateToProps = (state) => {

    return {
        property: state.application.property,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeMainPropertyDescription: (event) => {
        dispatch(act.ChangeMainPropertyDescription(event.target.value))
    },
    changeMainPropertyAddress: (event) => {
        dispatch(act.ChangeMainPropertyAddress(event.target.value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyInWhatName: (event) => {
        dispatch(act.ChangeMainPropertyInWhatName(event.target.value))
    },
    changeMainPropertyEstateHeldIn: (event) => {
        dispatch(act.ChangeMainPropertyEstateHeldIn(event.target.value))
    },
    changeMainPropertyInWhatManner: (event) => {
        dispatch(act.ChangeMainPropertyInWhatManner(event.target.value))
    },
    changeMainPropertySources: (event) => {
        dispatch(act.ChangeMainPropertySources(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {

        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event.target.value))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event.target.value))
    },
    changeMainPropertyRefinancePurpose: (event) => {
        dispatch(act.ChangeMainPropertyRefinancePurpose(event.target.value))
    },
    changeMainPropertyRefinanceImprovementsDesc: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsDesc(event.target.value))
    },
    changeMainPropertyRefinanceExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExistingLiens(event.target.value))
    },

    changeMainPropertyRefinanceImprovementsStatus: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsStatus(event.target.value))
    },
    changeMainPropertyRefinanceCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceCost(event.target.value))
    },
    changeMainPropertyConstructionYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyConstructionYearAcquired(event.target.value))
    },
    changeMainPropertyConstructionOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyConstructionOriginalCost(event.target.value))
    },
    changeMainPropertyConstructionExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyConstructionExistingLiens(event.target.value))
    },
    changeMainPropertyConstructionPresentValue: (event) => {
        dispatch(act.ChangeMainPropertyConstructionPresentValue(event.target.value))
    },
    changeMainPropertyConstructionCostOfImprovements: (event) => {
        dispatch(act.ChangeMainPropertyConstructionCostOfImprovements(event.target.value))
    },
    changeMainPropertyExpirationDate: (event) => {
        dispatch(act.ChangeMainPropertyExpirationDate(event.target.value))
    },

    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
});
let caCounties = (()=>{
    let keys = Object.keys(com.counties)
    let ret = []
    keys.forEach(x => {
        ret.push(com.capitalize(x))
    })
    return ret
})()
let txCounties = (()=>{
    let ret = []
    com.texasCounties.forEach(x => {
        ret.push(com.capitalize(x))
    })
    return ret
})()
const allStates = ["California", "Texas"]
class Property extends Component {

    constructor(props) {
        super(props);

        this.state = {
            validated: false
        }
        this.isValidated = this.isValidated.bind(this);
        this.lastcompletion = 0
        this.countyref = React.createRef()
        this.stateref = React.createRef()
    }
    componentDidMount() { 
        if (this.isoccupancyHidden()) {
            let addr = this.sameaddress()
            this.changeMainPropertyAddress({ target: { value: addr } })
            this.onAddressBlur()
        }

        if (this.props.property.units === "")
            this.props.changeMainPropertyUnits({ target: { value: "1" } })
        // if(!this.propertyKnown()) {
        //     // TODO: need to add state selector later
        //     this.props.changeMainProperty("California", "state")
        // }
    }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }

    changeMainPropertyPropertyType = e => {
        com.touch()
        if (this.props.property.purpose !== st.POL_Purchase && this.props.financingsameproperty)
            this.props.changeBorrowerInfo(e, com.borrower, "propertytype")
        this.props.changeMainPropertyPropertyType(e)
    }
    lookupProperty = (num, prefix, short, sfx, city, zip) => {
        let body = {
            number: num, prefix: prefix, street: short, suffix: sfx, city: city, zip: zip
        }

        let token = sessionStorage.getItem("ZeitroA")
        this.props.changeMainProperty(false, "lookupsuccessful")
        fetch('/borrower/findproperty', {
            method: 'POST',
            headers: {
                Cache: "no-cache",
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json',
                "X-Borrower": this.props.borrowerid,
            },
            body: JSON.stringify(body)
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (this.props.property.yearbuilt === "" && js.Yearbuilt !== "" && !this.props.property.lookupsuccessful) {
                        this.props.changeMainPropertyYearBuilt({ target: { value: js.Yearbuilt } })
                        this.props.changeMainPropertyUnits({ target: { value: js.Units } })
                        this.props.changeMainProperty(true, "lookupsuccessful")
                        if (this.props.application.property.purpose !== st.POL_Purchase) {
                            let dt = js.Lastsale
                            let dat = dt.split('T')[0].split('-')
                            let d = parseInt(dat[0])
                            this.props.changeMainPropertyRefinanceYearAcquired({ target: { value: d } })
                            let cost = js.Lastsaleprice.toString()
                            this.props.changeMainPropertyRefinanceOriginalCost({ target: { value: cost } })
                        }

                    }

                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    onStreetAddressCompletion = address => {
        this.lastcompletion = Date.now()

        if("undefined" !== typeof this.timer)
            clearTimeout(this.timer)
        let num = com.getSubaddress(address, "street_number", "long_name")

        let city = com.getSubaddress(address, "locality", "long_name")
        let zip = com.getSubaddress(address, "postal_code", "long_name")
        let street = com.getSubaddress(address, "route", "short_name")

        let county = com.getSubaddress(address, "administrative_area_level_2", "long_name")
        let longstate = com.getSubaddress(address, "administrative_area_level_1", "long_name")
        if (county !== "")
            this.props.changeMainProperty(county, "county")
        this.props.changeMainProperty(longstate, "state")
        let addr = num + " " + street + ", " + city + ", " +
            com.getSubaddress(address, "administrative_area_level_1", "short_name") +
            " " + zip
        if (num !== "")
            this.props.changeMainPropertyAddress({ target: { value: addr } })
        let sfx = ""
        let short = com.getSubaddress(address, "route", "short_name")
        let shorts = short.split(' ')

        if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
            sfx = shorts.pop()

            if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
                sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase()

            short = shorts.join(' ')
        }
        let prefix = ""
        let s = shorts[0].toUpperCase()

        if (com.prefixes.includes(s)) {

            prefix = shorts[0]
            shorts = short.split(' ')
            short = shorts.slice(1, shorts.length).join(' ')
        }
    
        this.props.changeMainPropertyAddress({ target: { value: addr } })


        this.lookupProperty(num, prefix, short, sfx, city, zip)

    }
    lookupAddress = () => {
        let now = Date.now()
        
        if (now - this.lastcompletion < 500)
            return

        let addr = this.props.application.property.address
   
        if (this.isoccupancyHidden()) {
            addr = this.sameaddress()
            this.props.changeMainPropertyAddress({ target: { value: addr } })

        }
        if("" === addr)
            return            
        let target = encodeURI("https://maps.googleapis.com/maps/api/geocode/json?address=" + addr + "&key=AIzaSyBQFO5uQtyZ5EsbTsNEK7HOhEA00KS3le8")

        fetch(target, {
            method: 'GET',
        }).then(
            response => {

                if (response.status !== 200) {
                    //alert('Looks like there was a problem. Status Code: ' + response.status)
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.status === "OK") {

                        this.onStreetAddressCompletion(js.results[0].address_components)
                    }
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    onAddressBlur = (e) => {
        this.timer = setTimeout(this.lookupAddress, 200)
    }



    isoccupancyHidden = () => {
        return this.props.application.financingsameproperty && (this.props.application.property.purpose !== st.POL_Purchase)
    }
    sameaddress = () => {
        let addr = com.formatAddress(this.props.application.borrower)


        return addr
    }
    changeMainPropertyAddress = e => {
        //this.onStreetAddressCompletion(address)
        let address = e.target.value
        if (address === "") {
            this.props.changeMainProperty(false, "lookupsuccessful")
        }
  
        this.props.changeMainPropertyAddress(e)
    }

    propertyKnown = () => {
        return !(this.props.property.purpose === st.POL_Purchase  && false === this.props.application.property.hascontract)
    }
    handleSubmit = e => {
        if((this.props.property.purpose !== "purchase" && this.props.financingsameproperty)) {
            this.props.changeMainPropertyAddress({ target: { value: com.formatAddress(this.props.application.borrower) } })
        }
        if(this.countyref.current != null) {
            if (this.props.application.property.state=="California") {
                if(!caCounties.includes(com.capitalize(this.props.application.property.county)) ) {
                    this.countyref.current.setCustomValidity(this.props.application.property.county + " is not a valid Caniforlia county")
                    return false
                }
            }
            if (this.props.application.property.state=="Texas") {
                if(!txCounties.includes(com.capitalize(this.props.application.property.county)) ) {
                    this.countyref.current.setCustomValidity(this.props.application.property.county + " is not a valid Texas county")
                    return false
                }
            }
        }
        if(this.stateref.current != null) {
            if(!allStates.includes(com.capitalize(this.props.application.property.state)) ) {
                this.stateref.current.setCustomValidity(this.props.application.property.state + " is not a valid state")
                return false
            }
        }
        return true        
    }
    render = () => {

        let onFHAProperty = event => {
            com.touch()
            let value = event.target.value === "true"
            this.props.changeMainProperty(value, "qualifiesForFHAsecondary")
        }
        let onManufacturedHome = event => {
            com.touch()
            let value = event.target.value === "true"      
            this.props.changeMainProperty(value, "isManufacturedHome")                  
        }
        let onisMixedUse = event => {
            com.touch()
            let value = event.target.value === "true"      
            this.props.changeMainProperty(value, "isMixedUse")              
        }
        let legalDescription = () => {
            if (this.props.property.purpose !== "construction" && this.props.property.purpose !== "construction-permanent")
                return ""
            else
                return (
                    <Form.Row>
                        <Col   >

                            <Form.Group controlId="Description" className="text-left" >
                                <Form.Label className="text-left" >{tooltip("Legal Description",
                                    <div>If you do not know the legal description of the property, please leave this area blank.
                                  </div>)}
                                </Form.Label>
                                <Form.Control isValid={false} size="sm"

                                    type="text"
                                    name="description"
                                    defaultValue={this.props.property.description}

                                    onChange={this.props.changeMainPropertyDescription}

                                    pattern="^([\w\s-@.,/#&+]*)$"

                                />
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Enter the legal description (lot, block and subdivision; metes and bounds; or registered land survey)
                        </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                    </Form.Row>
                )
        }

        let propertyAddress = () => {
            if (this.isoccupancyHidden()) {
                return (
                    <Form.Row className="mb-2 pb-2">
                        <Col xs="auto" className="text-left">
                            Address:<p className="mb-2" />
                            {this.sameaddress()}
                        </Col>
                    </Form.Row>
                )
            } else
                return (
                    <Form.Row >
                        <Col xs="auto" style={{width: '28em'}}>
                            <Form.Group controlId="Adadaddress" className="text-left" >
                                <Form.Label>Property Address:</Form.Label>
                                <AddressAutocomplete id="xyzuit" label=""
                                    autoComplete="off"
                                    onBlur={this.onAddressBlur}
                                    onCompletion={this.onStreetAddressCompletion}
                                    placeholder="Address starting with the house number"
                                    required={true} name={"xyzuit"}
                                    defaultValue={this.props.property.address}
                                    onChange={this.changeMainPropertyAddress}
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    goodfeedback="Looks good!"
                                    badfeedback="Please provide address of the property"
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                )

        }

        let changeMainPropertyOccupancy = e => {
            com.touch()
            return this.props.changeMainPropertyOccupancy(e)
        }
        let changeMainPropertyYearBuilt = e => {
            com.touch()
            this.props.changeMainPropertyYearBuilt(e)
        }
        let changeMainPropertyUnits = e => {
            com.touch()
            this.props.changeMainPropertyUnits(e)
        }
        let changeMainPropertyRefinanceYearAcquired = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceYearAcquired(e)
        }
        let changeMainPropertyRefinanceOriginalCost = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceOriginalCost(e)
        }
        let changeMainPropertyExpectedRentalIncome = e => {
            com.touch()
            this.props.changeMainProperty(e.target.value, "purchase|expectedgrossmonthlyrentalincome")            
        }

        let changeMainPropertyCounty = e => {
            this.countyref.current.setCustomValidity("")
            this.props.changeMainProperty(e.target.value, "county")
        }
        let changeMainPropertyState= e => {
            this.stateref.current.setCustomValidity("")
            this.props.changeMainProperty(e.target.value, "state")
        }
        return (
            <div className=" text-left  mb-4 bg-">
                <h2 className=" heading">
                    {this.propertyKnown()? <div>Let's enter the subject property information:</div> :
                    <div>Let's specify what kind of property you're looking for:</div> }
                </h2>
                <div className="viewport text-center mt-4 ">

                    {this.propertyKnown() ? propertyAddress() : ""}
                    {legalDescription()}


                    <Form.Row>

                        <Col xs="auto" hidden={this.isoccupancyHidden()} >
                            <Form.Group controlId="occupancy" className="text-left" >
                                <Form.Label className="text-left " >{tooltip( this.propertyKnown() ? "Occupancy" : "Expected Occupancy", 
                                <div>
                                    There are three types of occupancy:
                                    <ul>
                                        <li>
                                    Primary Residence: The subject property will be a Primary Residence inhabited by the borrower. This occupancy type represents the least amount of risk for the lender, and the morgage rates are the lowest.
                                    </li><li>
                                    Second Home: The subject property will be occupied by the borrower for some portion of the year. Typically, this would be a used as a Summer or Vacation Home. This occupancy type represents a higher risk for the lender.
                                    </li><li>
                                    Investment: An investment property is owned but not occupied by the borrower. These properties are usually rented out to others by the borrower or purchased for use by child attending university in another state. This occupancy type represents the highest level of risk.
                                    </li></ul>
                                    The interest rate on your mortgage is partially based on the level of risk associated with each occupancy type. This means that your interest rate will be lowest for Primary Residences and highest for Investment Properties.                                    
                                </div>,
                                "auto", "")}</Form.Label>
                                <Form.Control name="occupancy" as="select" size="sm"
                                    defaultValue={this.props.property.occupancy}
                                    onChange={changeMainPropertyOccupancy} >
                                    <option value="principal">Primary Residence</option>
                                    <option value="secondhome">Second Home</option>
                                    <option value="investment">Investment</option>
                                </Form.Control>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide valid occupancy.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="propertytype" className="text-left" >
                                {tooltip(this.propertyKnown() ?  "Subject Property Type" : "Expected Property Type", <div>
                                    <ul>
                                        <li>
                                            <b>Detached</b> is when the property sits on its own lot without sharing any walls with another house or building. <br />
                                        </li><li className="mb-3">
                                            <b>Attached</b> housing, on the other hand, shares walls on both sides with another home.
                                        </li>
                                        <li>
                                            <b>Single Family Residence:</b>  A single dwelling unit that has no shared property and is built on its own parcel of land.
                                        </li><li>
                                            <b>Planned Unit Development (PUD):</b>A community of homes comprised predominantly of single-family residences that could be attached or detached and it is surrounded by common areas jointly owned and managed by a homeowners association (HOA).
                                        </li><li>
                                            <b>Condominium</b>:	Building structure divided into several units that are each individually and privately owned, surrounded by common areas jointly owned and managed by a homeowners association (HOA).
                                        </li></ul>


                                </div>, "bottom")}

                                <Form.Control name="propertytype" as="select" size="sm"
                                    type="text" required
                                    defaultValue={this.props.application.property.propertytype}
                                    onChange={this.changeMainPropertyPropertyType} >
                                        <option value="">...</option>
                                    <option value="singlefamilyattached">Single Family Attached</option>
                                    <option value="singlefamilydetached">Single Family Detached</option>
                                    <option value="condominium">Condominium Attached</option>
                                    <option value="condominiumdetached">Condominium Detached</option>
                                    <option value="pudattached">PUD attached</option>
                                    <option value="puddetached">PUD detached</option>
                                    <option value="twotofourfamily">2 to 4 Unit Multifamily</option>
                                   {/*} <option value="manufacturedhome">Manufactured Home</option> */}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        {!this.propertyKnown() && <Col xs="auto">
                                <Autocomplete
                                suggestions={allStates}
                                required
                                label="State:"
                                name="state" 
                                id="state"
                                type="text"
                                valid="Looks good!"
                                invalid="Please enter the state"
                                reference = {this.stateref}
                                value={this.props.property.state}
                                onChange={changeMainPropertyState}

                                pattern="^[a-z A-Z]+$"
                            />

                        </Col>}
                        {this.propertyKnown() ? 
           
                        <Col xs="auto">
                            <Form.Group /* hidden={this.props.property.lookupsuccessful} */ controlId="Yearbuilt" className="text-left" >
                                <Form.Label className="text-left" >{tooltip("Year built",
                                    <div>Leave blank if you not sure when the property was built.
                                </div>, 'bottom', ''
                                )}

                                </Form.Label>
                                <Form.Control

                                    size="sm"
                                    name="yearbuilt"
                                    type="text"
                                    value={this.props.property.yearbuilt}
                                    onChange={changeMainPropertyYearBuilt}

                                    pattern="^(|[12][0-9]{3})$"
                                />
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide your estimated liabilities.
                                        </Form.Control.Feedback>
                            </Form.Group>
                        </Col> 
       
                        : 
                        <Col xs="auto">
                                <Autocomplete
                                suggestions={[...caCounties, ...txCounties]}
                                required
                                label="County:"
                                name="county" 
                                id="county"
                                type="text"
                                valid="Looks good!"
                                invalid="Please enter the correct county"
                                reference = {this.countyref}
                                value={this.props.property.county}
                                onChange={changeMainPropertyCounty}

                                pattern="^[a-z A-Z]+$"
                            />

                        </Col>                          
                        }
                        <Col xs="auto">
                           
                            <Form.Group  hidden={this.props.application.property.propertytype !== "twotofourfamily"}  controlId="units" className="text-left" >
                                <Form.Label className="text-left" >Number of Units:</Form.Label>
                                <Form.Control name="units" as="select" size="sm"
                                    value={this.props.property.units}
                                    onChange={changeMainPropertyUnits} >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    
                                </Form.Control>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide number of units.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                    </Form.Row>
                    {/* {this.props.property.occupancy === "principal" && !this.isoccupancyHidden()
                     ?
                    <Form.Row>
                        <Col style={{fontStyle: 'italic'}} className="mb-3 text-wrap text-left"> Primary residences tend to qualify for the lowest mortgage rates. For your home to qualify as your primary property, 

you must live there most of the year, and 
it must be a convenient distance from your place of employment.  
{this.props.property.purpose === st.POL_Purchase ?
<span style={{fontWeight: '600'}}>Once you’ve bought the property, you must occupy it as a principal residence within 60 days following closing. </span> : "" }
                        </Col>
                    </Form.Row>
                    :""} */}
                    {this.props.property.occupancy === "secondhome" ?
                    <Form.Row  className="mb-1">
                    <Col md="auto ">
                    {tooltip("Do you intend to use the second home for work related purposes?", 
                    <div>This is only important for FHA loans that have a separate category of FHA second home. 
                        If you apply for FHA loan, you'll have to provide documentation that proves that your workplace 
                        is within 100 miles from your second home.</div>, "auto", "", "")}
                    </Col> <Col>
                    <div className="d-flex mt-0 mb-0" style={{paddingTop: '2px'}}>
                                        <div className="mr-3 mt-0" >
                                            <input required onChange={onFHAProperty} value="false"
                                                checked={this.props.application.property.qualifiesForFHAsecondary === false}
                                                type="radio" id="qualifiesForFHAsecondary" name="qualifiesForFHAsecondary"></input>
                                            <label className="zeitro-radio divlink" htmlFor="qualifiesForFHAsecondary">No, I don't</label></div>
                                        <div className="mr-3  mt-0">
                                            <input required onChange={onFHAProperty} value="true"
                                                checked={this.props.application.property.qualifiesForFHAsecondary === true}
                                                type="radio" id="qualifiesForFHAsecondarynot" name="qualifiesForFHAsecondary"></input>
                                            <label className="zeitro-radio divlink" htmlFor="qualifiesForFHAsecondarynot">Yes, I do</label></div>
                                    </div>
                    </Col></Form.Row>
                    :""
                    }
                    
                        <Form.Row className="mb-1">
                        <Col md="auto text-left">
                    {tooltip("Is this a manufactured home?",
                    <div><div>“A “manufactured home” is a dwelling of at least 400 square feet and at least 12 feet wide, constructed to the “HUD Code” for manufactured housing, that is built on a permanent chassis, installed on a permanent foundation system, and titled as real estate.</div>
                    <div className="mt-3">Zeitro currently does not process loans for manufactored homes.</div></div>, "auto", "", "")}
                    </Col>     
                            <Col >
                            <div className="d-flex mt-0 mb-0" style={{paddingTop: '2px'}}>
                                     <div className="mr-3 mt-0" >
                                            <input required onChange={onManufacturedHome} value="false"
                                                checked={this.props.application.property.isManufacturedHome === false}
                                                type="radio" id="isManufacturedHome" name="isManufacturedHome"></input>
                                            <label className="zeitro-radio divlink" htmlFor="isManufacturedHome">No, it isn't</label></div>
                                        <div className="mr-3  mt-0">
                                            <input required onChange={onManufacturedHome} value="true"
                                                checked={this.props.application.property.isManufacturedHome === true}
                                                type="radio" id="isManufacturedHomenot" name="isManufacturedHome"></input>
                                            <label className="zeitro-radio divlink" htmlFor="isManufacturedHomenot">Yes, it is</label></div>
                                    </div>                            
                            </Col>  
                        </Form.Row> 
                    {this.props.property.occupancy === "principal" ?
                        <Form.Row className="mb-1  text-left">
                        <Col md="auto Is this a manufactured home?">
                    {tooltip("Is part of this property going to be used for business (mixed use)?", 
                    <div>In an mixed use property you set aside space within the property to operate
                    your own business. (e.g., daycare facility, medical office, beauty/barber shop)
                    The following is required for property to be considered mixed use:
                    <ul>
                        <li>
                    The property must be a one-unit dwelling that the borrower occupies as a principal residence.
                    </li><li>
The borrower must be both the owner and the operator of the business.
</li><li>
The property must be primarily residential in nature.
</li><li>
The dwelling may not be modified in a manner that has an adverse impact on its marketability as a residential property.
</li>
                   </ul> 
                    </div>, "auto", "", "")}
                    </Col>     
                            <Col >
                            <div className="d-flex mt-0 mb-0" style={{paddingTop: '2px'}}>
                                     <div className="mr-3 mt-0" >
                                            <input required onChange={onisMixedUse} value="false"
                                                checked={this.props.application.property.isMixedUse === false}
                                                type="radio" id="isMixedUsenot" name="isMixedUse"></input>
                                            <label className="zeitro-radio divlink" htmlFor="isMixedUsenot">No, it isn't</label></div>
                                        <div className="mr-3  mt-0">
                                            <input required onChange={onisMixedUse} value="true"
                                                checked={this.props.application.property.isMixedUse === true}
                                                type="radio" id="isMixedUse" name="isMixedUse"></input>
                                            <label className="zeitro-radio divlink" htmlFor="isMixedUse">Yes, it is</label></div>
                                    </div>                            
                            </Col>  
                        </Form.Row>  : "" }                          
                    { (this.props.property.occupancy === "investment" || (this.props.property.occupancy ===  "principal" && 
                    this.props.application.property.propertytype === "twotofourfamily") ) ?
                        <Form.Row>
                            <Col md="auto">
                            <Form.Group controlId="expectedgrossmonthlyrentalincome" className="text-left" >
                                    <Form.Label className="text-left" >Expected monthly gross rental income/mo:

                                </Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        name="expectedgrossmonthlyrentalincome"
                                        type="text"
                                        value={this.props.property.purchase.expectedgrossmonthlyrentalincome}
                                        onChange={changeMainPropertyExpectedRentalIncome}

                                        pattern="^(|[12][0-9]{3})$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide monthly gross rental income.
                                        </Form.Control.Feedback>
                                </Form.Group>                                
                                

                            </Col>    
                            <Col>
                            </Col>
                        </Form.Row> : "" }
                                                  
                    {this.props.property.purpose !== st.POL_Purchase ?
                        <Form.Row>
                            <Col md="2">
                                <Form.Group /* hidden={this.props.property.lookupsuccessful} */ controlId="Yearbacquired" className="text-left" >
                                    <Form.Label className="text-left" >Year acquired:

                                </Form.Label>
                                    <Form.Control
                                        size="sm"
                                        name="yearacquired"
                                        type="text"
                                        value={this.props.property.refinance.yearacquired}
                                        onChange={changeMainPropertyRefinanceYearAcquired}

                                        pattern="^(|[12][0-9]{3})$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your estimated liabilities.
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="2">
                                <Form.Group /* hidden={this.props.property.lookupsuccessful} */ controlId="Originalcost" className="text-left" >
                                    <Form.Label className="text-left" >Original Cost:

                                </Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        name="originalcost"
                                        type="text"
                                        value={this.props.property.refinance.originalcost}
                                        onChange={changeMainPropertyRefinanceOriginalCost}

                                        min="1"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your estimated liabilities.
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row> : ""
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Property)

const mapStateToPropsMore = (state) => {

    return {
        property: state.application.property,
        application: state.application,
    }
}

const mapDispatchToPropsMore = (dispatch) => ({
    changeMainPropertyDescription: (event) => {
        dispatch(act.ChangeMainPropertyDescription(event.target.value))
    },
    changeMainPropertyAddress: (event) => {
        dispatch(act.ChangeMainPropertyAddress(event.target.value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyInWhatName: (event) => {
        dispatch(act.ChangeMainPropertyInWhatName(event.target.value))
    },
    changeMainPropertyEstateHeldIn: (event) => {
        dispatch(act.ChangeMainPropertyEstateHeldIn(event.target.value))
    },
    changeMainPropertyInWhatManner: (event) => {
        dispatch(act.ChangeMainPropertyInWhatManner(event.target.value))
    },
    changeMainPropertySources: (event) => {
        dispatch(act.ChangeMainPropertySources(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event.target.value))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event.target.value))
    },
    changeMainPropertyRefinancePurpose: (event) => {
        dispatch(act.ChangeMainPropertyRefinancePurpose(event.target.value))
    },
    changeMainPropertyRefinanceImprovementsDesc: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsDesc(event.target.value))
    },
    changeMainPropertyRefinanceExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExistingLiens(event.target.value))
    },

    changeMainPropertyRefinanceImprovementsStatus: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsStatus(event.target.value))
    },
    changeMainPropertyRefinanceCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceCost(event.target.value))
    },
    changeMainPropertyConstructionYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyConstructionYearAcquired(event.target.value))
    },
    changeMainPropertyConstructionOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyConstructionOriginalCost(event.target.value))
    },
    changeMainPropertyConstructionExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyConstructionExistingLiens(event.target.value))
    },
    changeMainPropertyConstructionPresentValue: (event) => {
        dispatch(act.ChangeMainPropertyConstructionPresentValue(event.target.value))
    },
    changeMainPropertyConstructionCostOfImprovements: (event) => {
        dispatch(act.ChangeMainPropertyConstructionCostOfImprovements(event.target.value))
    },
    changeMainPropertyExpirationDate: (event) => {
        dispatch(act.ChangeMainPropertyExpirationDate(event.target.value))
    },
    changeMainPropertyRefinanceExplainOther: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExplainOther(event.target.value))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    updateTransaction: (event, verb) => {
        dispatch(act.UpdateTransaction(event.target.value, verb))
    },
    changeMainPropertyRefinanceAttribute: (value, verb) => {
        dispatch(act.ChangeMainPropertyRefinanceAttribute(value, verb))
    },
});

export class MoreProperty extends Component {

    constructor(props) {
        super(props);

        this.state = {
            validated: false,
        }
        this.isValidated = this.isValidated.bind(this);
        this.inwhat = React.createRef()
        this.selected = this.parseBareSources(this.props.property.sources)
        this.other = this.getOther()
        if(this.props.property.inwhatname === "") {
            let name = this.props.application["borrower"].firstname + " " + this.props.application["borrower"].lastname
            if (this.props.application.hascoborrower === "withcoborrower" ||
            (this.props.application.hascoborrower === "multiple" && this.props.application.married) ) {
                name += ", " +  this.props.application["coborrower"].firstname + " " + this.props.application["coborrower"].lastname
            }
            this.props.changeMainPropertyInWhatName({target: {value: name}})
        }
    }
    handleSubmit = (e) => {

        let inw = this.props.property.inwhatname
        if(inw.toUpperCase().indexOf(this.props.application["borrower"].firstname.toUpperCase()) < 0 || inw.toUpperCase().indexOf(this.props.application["borrower"].lastname.toUpperCase()) < 0  ) {
            this.inwhat.current.setCustomValidity("Borrower must be on the title!")
            this.setState({ validated: true })
            return false
        }
        return true
    }
    componentDidMount() { }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    changeMainPropertyPropertyType = e => {
        com.touch()
        if (this.props.property.purpose !== "purchase" && this.props.financingsameproperty)
            this.props.changeBorrowerInfo(e, "borrower", "propertytype")
        this.props.changeMainPropertyPropertyType(e)
    }
    renderRefinance = () => {
        let changeMainPropertyRefinancePurpose = e => {
            com.touch()
            this.props.changeMainPropertyRefinancePurpose(e)
        }
        let changeMainPropertyRefinanceImprovementsStatus = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceImprovementsStatus(e)
        }
        let changeMainPropertyRefinanceCost = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceCost(e)
        }
        let changeMainPropertyRefinanceExplainOther = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceExplainOther(e)
        }
        let changeMainPropertyRefinanceImprovementsDesc = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceImprovementsDesc(e)
        }
        return (
            <div className="">
                <Form.Row>
                    <Col>
                        <h4 className="ml-0 heading"><div style={{ fontSize: '0.9em' }} >Cash Out Refinance Loan Details:</div></h4>
                    </Col>
                </Form.Row>
                <Form.Row>

                    <Col md="auto">
                        <Form.Group controlId="purpose" className="text-left" >
                            <Form.Label className="text-left" >Purpose of Refinance:</Form.Label>
                            <Form.Control name="purpose" as="select" size="sm"
                                defaultValue={this.props.property.refinance.purpose}
                                onChange={changeMainPropertyRefinancePurpose}
                            >
                                <option value="debtconsolidation">Debt consolidation by paying consumer debts such as credit cards or installment loans</option>
                                <option value="homeimprovement">Home Improvement</option>
                                <option value="paytorelatives">Paying off part of the equity to relatives</option>
                                <option value="cashforreserves">Obtain cash for reserves</option>
                                <option value="paidcashforsubjectproperty">Paid cash for subject property and want to replenish reserves</option>
                                <option value="payingoffheloc">Paying off second mortgage or a HELOC balance</option>
                                <option value="payingotherproperties">Paying off balances on other owned properties</option>
                                <option value="payingstudentloans">Paying off student loans only</option>
                                <option value="other">Other</option>

                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Form.Row>
                <Form.Row>
                    <Col >
                        <Form.Group hidden={this.props.property.refinance.purpose !== "homeimprovement"} controlId="improv" className="text-left" >
                            <Form.Label className="text-left" >Improvements:</Form.Label>
                            <Form.Control name="improve" as="select" size="sm"
                                defaultValue={this.props.property.refinance.improvementsstatus}
                                onChange={changeMainPropertyRefinanceImprovementsStatus}
                            >
                                <option value="made">Made</option>
                                <option value="tobemade">To be made</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col >
                        {(this.props.property.refinance.improvementsstatus !== "noimprovements" && this.props.property.refinance.purpose === "homeimprovement") ?
                            <Form.Group controlId="refcost" className="text-left" hidden={this.props.property.refinance.improvementsstatus === "none"}>
                                <Form.Label className="text-left" >Their Cost:</Form.Label>

                                <NumericalInput
                                    required={this.props.property.refinance.improvementsstatus !== "noimprovements"}
                                    name="refcost"
                                    size="sm"
                                    defaultValue={this.props.property.refinance.cost}
                                    onChange={changeMainPropertyRefinanceCost}

                                    min={0}
                                />

                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide cost of improvements.
                            </Form.Control.Feedback>
                            </Form.Group> : " "}
                    </Col>
                    <Col>
                    </Col>
                </Form.Row>
                { this.props.property.refinance.purpose === "other" ?
                    <Form.Row hidden={this.props.property.refinance.purpose !== "other"}>
                        <Col xs="auto">
                            <Form.Group controlId="explain" className="text-left" >
                                <Form.Label className="text-left" >Please write an explanation:</Form.Label>
                                <Form.Control
                                    required
                                    name="explain"
                                    type="text"
                                    as="textarea"
                                    defaultValue={this.props.property.refinance.explainotherpurpose}
                                    onChange={changeMainPropertyRefinanceExplainOther}

                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide your estimated liabilities.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row> : ""}
                <Form.Row hidden={this.props.property.refinance.purpose !== "homeimprovement" || this.props.property.refinance.improvementsstatus === "noimprovements"}>
                    <Col xs="auto">
                        <Form.Group controlId="descimp" className="text-left" >
                            <Form.Label className="text-left" >Describe Improvements:</Form.Label>
                            <Form.Control

                                name="descimp"
                                type="text"
                                as="textarea"
                                defaultValue={this.props.property.refinance.improvementsdesc}
                                onChange={changeMainPropertyRefinanceImprovementsDesc}

                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide your estimated liabilities.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </div>
        )
    }
    onSelect = e => {
        let ar = []
        let adj = []

        e.forEach(x => { 
            if(x.id !== "Other") {
                ar.push(x.id)
                adj.push(x)
            } else {
                let other = " " + this.other
                ar.push(other)
                adj.push({id: other, name: other})
            }

        })
        let out = ar.join(",")
        this.selected = adj
        com.touch()
        this.props.changeMainPropertySources({target: {value: out}})
    }
    parseSources = v => {
        let s = []
        if(v === "") { 
            return s
        }
        let ar = v.split(',')
        ar.forEach(x => { 
            if(options.includes(x)) {
                s.push( {id: x, name: x} ) 
            } else {

                if(x === '') return
                s.push( {id: "Other", name: "Other"} )
            }
        })

        return s
    }
    parseBareSources = v => {
        let s = []
        if(v === "") return s
        let ar = v.split(',')
        ar.forEach(x => {             

            s.push( {id: x, name: x} ) 
        })
        return s
    }
    getOther = () => {
        let ar = this.props.property.sources.split(',')
        let o = ""
        ar.forEach(x => { 
            if(x === "") return
            if(!options.includes(x)) {
                o = x.slice(1, x.length)
                o = o.replaceAll(';', ',') 

            }
        })
        return o
    }    
    render = () => {
        let updateTransaction = (verb) => {
            return e => {
                com.touch()
                this.props.updateTransaction(e, verb)
            }
        }

        let onSelect = e => {
            com.touch()
            this.props.changeMainPropertyInWhatManner({ target: { value: e } })
        }


        let changeMainPropertyInWhatName = e => {
            com.touch()
            this.props.changeMainPropertyInWhatName(e)
        }
        let changeMainPropertyInWhatManner = e => {
            com.touch()
            this.props.changeMainPropertyInWhatManner(e)
        }


        let hasOther = () => {
            let ar = this.props.property.sources.split(',')
            let has = false
            ar.forEach(x => { 
                if(x === '') return

                if(!options.includes(x)) {
                    has = true
                }
            })

            return has         
        }
        let changeOther = e => {
            let ar = []
            let adj = []
            
            this.selected.forEach(x => { 
                if(!options.includes(x.id)) {
                    let r =  " " + e.target.value.replaceAll(',',';')
                    ar.push(r)
                    adj.push({id: r, name: r })
                } else {
                    ar.push(x.id,)

                    adj.push({id: x.id, name: x.name })
                }
            })
            let out = ar.join(",")
            this.selected = adj
        
            com.touch()
            this.other =  e.target.value
            this.props.changeMainPropertySources({target: {value: out}})            
           
        }
        this.parseSources(this.props.property.sources)
        return (
            <div className="text-left  mb-4 bg-">
                <h2 className=" heading">
                    We're almost done. Just some extra details are needed:
                </h2>
                <div className="viewport text-center mt-4 ">

                    <Form.Row >
                        <Col xs="auto">
                            <Form.Group controlId="InName" className="text-left" >
                                <Form.Label className="text-left" >{tooltip("Title will be held in what name(s)",
                                    <div>Specify the names of individual, or individuals, or legal entities that will hold the title. For example, husband and wife in case of joint tenancy.
                    <div className="mt-2">Borrower <b>must</b> be on the title</div></div>)}
                                </Form.Label>

                                <Form.Control isValid={false} size="sm"
                                    ref={this.inwhat}
                                    required
                                    name="inname"
                                    defaultValue={this.props.property.inwhatname}
                                    onChange={changeMainPropertyInWhatName}

                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />

                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please specify in what name the title will be held.
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="manner" className="text-left" >
                                <Form.Label className="text-left" >{tooltip("Manner in which title will be held",
                                    <div>
                                        There are several ways that people can take title to a property. Some of the common forms are:
                                        <ul>
                                            <li><b>Joint Tenancy:</b> Each person has an undivided interest in the property.</li>
                                            <li><b>Tenants in Common:</b> Indicates the percentage of ownership in the property. It is not an undivided interest as in Joint Tenancy; for example, one party may own 30% and another party may own 70%.</li>
                                            <li><b>Sole and Separate:</b> Allows an individual to have 100% title to the property. A single or unmarried person may have this type of title.</li>
                                        </ul>
                                    It is recommended that borrowers seek legal advice before choosing an appropriate form of ownership.
                                    </div>)}

                                </Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={false}                           
                                        required
                                        name="manner"
                                        value={this.props.property.inwhatmanner}
                                        onChange={changeMainPropertyInWhatManner}
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <DropdownButton
                                        as={InputGroup.Append}
                                        variant="dropdown"
                                        className="incdropdown"
                                        title=""
                                        id="dropdown-income"
                                        onSelect={onSelect}
                                    >   {com.mannerthetitleheld.map((st) =>
                                        (
                                            <Dropdown.Item key={st} eventKey={st} href="#">{st}</Dropdown.Item>

                                        )
                                    )}
                                    </DropdownButton>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the manner in which the title will be held.
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>

                        <Col   >
                            <Form.Group controlId="Sources" className="text-left" >
                                <Form.Label className="text-left" >{tooltip("Sources of Downpayment,  Settlement Charges, and/or Subordinate Financing (check all that apply)",
                                    <div>If you are purchasing a property, there will generally be a down payment and various costs to close the loan. The funds may come from a savings account, sale of stocks or bonds, proceeds from the sale of another property, etc. Please indicate the source of the funds. If there will be other financing or subordinate financing on the property, indicate the source of funds. For example, subordinate financing may be a loan from a seller or from a 3rd party source such as a realtor.
                         </div>
                                )}


                                </Form.Label>
                                <Multiselect
                                            showCheckbox={true}
                                            required={this.parseSources(this.props.property.sources).length === 0}

                                            closeIcon="close"
                                            style={customstyle}
                                            options={com.downpaymentsources} // Options to display in the dropdown
                                            selectedValues={this.parseSources(this.props.property.sources)} // Preselected value to persist in dropdown
                                            onSelect={this.onSelect} // Function will trigger on select event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />

                                {hasOther()?
                                <InputGroup className="pl-5">
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="sources"
                                        value={this.getOther()}
                                        onChange={changeOther}
                                        placeholder="List other sources"

                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide other sources of downpayment, settlement charges, and/or subordinate financing.
                                </Form.Control.Feedback>
                                </InputGroup> : ""
                            }
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </div>
                <div className="viewport mt-3">
                    {(this.props.property.purpose === "cashoutrefinance") ? this.renderRefinance() : ""}
                    {this.props.property.purpose === "purchase" ?
                        <Form.Row className="mt-2 pt-1 mb-1 "
                        >
                            <Col xs="auto">&nbsp;<br />
                            Cash deposit towards purchase
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="Heldby" className="text-left" size="sm">
                                    <Form.Label className="text-left" >{tooltip("Held by",
                                        <div>Write the name of the escrow or title company to whom you made the earnest money deposit check that was submitted along with your offer.<br /> If not sure, write "I don't know yet"
                    </div>, "auto", "", "")}
                                    </Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="heldby"
                                        type="text"
                                        defaultValue={this.props.application.transaction.cashdepositedby}
                                        onChange={updateTransaction("cashdepositedby")}

                                        pattern="^[a-zA-Z0-9 !-:&()'?#+/@]+$" 
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Provide name of the escrow or title company.
                                </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="Amount" className="text-left" size="sm">
                                    <Form.Label className="text-left" >Amount:</Form.Label>

                                    <NumericalInput isValid={false} 
                                        required={(this.props.property.purpose === "purchase")}
                                        name="amount"
                                        defaultValue={this.props.application.transaction.depositamount}
                                        onChange={updateTransaction("depositamount")}
                                        size="sm"
                                        min={1}

                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the deposit amount.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row> : ""}

                </div>

            </div>
        )
    }
}

MoreProperty = connect(mapStateToPropsMore, mapDispatchToPropsMore, null, { forwardRef: true })(MoreProperty)

