import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Document, Page } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';
import classes from './ImagePreview.module.css';
import { Skeleton, message } from 'antd';

export default function DocumentPreview({ fileName, fileID, magnifier, boxConfig, scaleFactor, changeMagnifierAccessibility, pageNumber, onLoadSuccess }) {
  //component's state
  const [bboxStyle, setBboxStyle] = useState({});
  const [pdf, setPdf] = useState(null);
  const [pageWidth, setPageWidth] = useState(600);
  //redux state
  const borrower = useSelector(state => state.borrower);

  const prefix = "data:image/png;base64,"

  useEffect(() => {
    /* using closure, we can prevent unnecessary updates
     * shouldUpdate is set in the closure, update is happening, only if shouldUpdate is true
     * function we return, where shouldUpdate is set to false, is executed, when props.fileName is changed
     * meaning that state update for old value of props.fileName will not happen
     * Ex. if we change props.fileName twice in 2 seconds, first request will be sent, but the response
     * (let's assume) will not be received yet, but shouldUpdate will already be false for that response
    */
    setBboxStyle({})
    changeMagnifierAccessibility(false)
    if (fileName !== '') {
      downloadAll()

    }
  }, [fileName, borrower.id, changeMagnifierAccessibility]);


  useEffect(() => {
    if (pdf) {
      setStyle()
    }
  }, [pdf, magnifier, boxConfig, scaleFactor])

  const base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
  
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i)
    }
  
    return bytes.buffer
  }

  const setStyle = async () => {
    const pdfDoc = await PDFDocument.load(pdf)
    if (boxConfig.page) {
      const page = pdfDoc.getPage(boxConfig.page - 1)
      let multiplier = scaleFactor
      let imgWidth = pageWidth
      let imgHeight = page.getHeight()
      let left = imgWidth * boxConfig.bbox.Left * multiplier
      let top = imgHeight * boxConfig.bbox.Top  * multiplier - 8
      let width = imgWidth * boxConfig.bbox.Width * multiplier
      let height = imgHeight * boxConfig.bbox.Height * multiplier + 4
      setBboxStyle(prev => ({ ...prev, display: 'block', left: `${left}px`, top: `${top}px`, width: `${width}px`, height: `${height}px` }));
    }
  }
  
  const downloadAll = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/downloadalldocs', {
        method: 'POST',
        body: JSON.stringify({ BorrowerID: borrower.id, DocumentIDs: [fileID] }),
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
    }).then(async response => {
        if (response.status !== 200) {
            message.error('something went wrong please try later')
            return;
        }

        await response.json().then(js => {
            let files = js.Documents
            let file = base64ToArrayBuffer(files[0].Content)
            setPdf(file)
            changeMagnifierAccessibility(true)
        })
    }).catch(() => {
        message.error('something went wrong please try later')
    })
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    onLoadSuccess(numPages)
  }

  return (
    <div style={{overflow: 'auto', display: 'flex', height: '100%'}}>
      <Document style={{width: pageWidth * scaleFactor, margin: '0 auto'}} className={classes.pdfDocuments} file={pdf} onLoadSuccess={onDocumentLoadSuccess} >
        <Page pageNumber={pageNumber} width={pageWidth * scaleFactor}>
          {boxConfig.page  === pageNumber && <div className={classes.bbox} style={bboxStyle} />}
        </Page>
      </Document>
    </div>
  )
}