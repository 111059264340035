import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Button, Container } from "react-bootstrap";
import { Col, Row, Tabs, Drawer, QRCode, Skeleton } from 'antd';
import BasicInfo from "./BasicInfo";
import AboutMe from "./AboutMe";
import CustomerReview from "./CustomerReview"
import Tools, { LandingAffordability, LandingRateQuoter } from "./Tools";
import CallBack from "./CallBack";
import Helmet from 'react-helmet'
import "./NewLandingPage.css";
import { GMCCDisclaimer } from "../GMCCCalculator/Disclaimer";
import {GMCCSpecials} from "../GMCCCalculator/GMCCCalculator";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { checkAccess } from "../Auth";
import { UR_Owner } from "../State";

const backgroundColor = "#F7F8FC";

const LandingPage = (props) => {
  return  <LoLandingPage {...props} />;
};

const LoLandingPage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nmls, setNMLS] = useState("");
  const [phone, setPhone] = useState("");
  const [basicInfo, setBasicInfo] = useState({});
  const [aboutInfo, setAboutInfo] = useState({});
  const [reviewList, setReviewList] = useState([]);
  const [loading, setLoading] = useState(true)
  const [showContactmeModal, setShowContactmeModal] = useState(false);

  const refAffordability = useRef(null);
  const refRateQuoter = useRef(null);
  const refCalculators = useRef(null);
  const isOwner = checkAccess([UR_Owner]);
  const handleClickCalculators = (tab) => {
    if (tab === "ratequoter") {
      if (refRateQuoter.current)
        setNavDrawer(false)
        refRateQuoter.current.scrollIntoView({ behavior: "smooth" });
    } else if (tab === "affordability") {
      if (refAffordability.current)
        setNavDrawer(false)
        refAffordability.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  const [landingUrl, setLandingUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    getProfile()
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  
  }, []);

  const getContactmeModal = () => {
    return (
      <Modal
        show={showContactmeModal}
        onHide={() => setShowContactmeModal(false)}
      >
        <Modal.Body>
          <CallBack id={props.id} name={name} email={email} phone={phone} forHeader={true} />
        </Modal.Body>
      </Modal>
    );
  };

  const [navDrawer, setNavDrawer] = useState(false);
  const showDrawer = () => {
    setNavDrawer(!navDrawer)
  }
  const onClose = () => {
    setNavDrawer(false)
  }
  const dynamicExternalLink = (href) => {
    if (!/^https?:\/\//i.test(href)) {
        return `https://${href}`
    }
    return href
  }
  const getPreQualificationLink = () => {

    if (window.location.host.includes("zeitro.us")) {
      return props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    } 
    if (window.location.host.includes("localhost")) {
      return  props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    }
    return props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
  }
  const getProfile = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "X-CustomerID": props.customerid,
          "X-LoID": props.loid,
      },
  }).then(response => {
      if (!response.ok) {
          console.log(response)
          setLoading(false);
      } else {
          response.json().then(js => {
          
              let lo = js
              let su = lo.LandingPageSetUp
              let name = lo.FirstName + " " + lo.LastName
              setBasicInfo({
                file: lo.Image === '' ? [] : [
                  {
                    url: lo.Image
                  }
                ],
                firstname: lo.FirstName,
                lastname: lo.LastName,
                nmls: lo.NMLS,
                states: typeof su.States === "undefined" ? [] : su.States,
                tagsList: typeof su.TagsList === "undefined" ? [] : su.TagsList,
                popularTags: ['I speak Mandarin', 'Top 10 Loan offier', "Bay Area for 10+ years"]
              })
              setName(name)
              setEmail(lo.Email)
              setPhone(lo.Phone)
              setNMLS(lo.NMLS)
              setAboutInfo({
                introduction: lo.Introduction,
                phone: lo.Phone,
                email: lo.Email,
                companyWebsite: su.CompanyWebsite === undefined ? '' : su.CompanyWebsite,
                google: su.Google === undefined ?'':su.Google ,
                yelp: su.Yelp === undefined ?'':su.Yelp ,
                linkedin: su.Linkedin === undefined ?'':su.Linkedin ,
                twitter: su.Twitter === undefined ?"":su.Twitter ,
              })
              setReviewList( su.ReviewsList===undefined ? []: su.ReviewsList)
              setLandingUrl( window.location.hostname.includes("localhost") ? "http://localhost:3001"+js.LandingUrl :"https://" + window.location.hostname + js.LandingUrl)
              setLoading(false);
              
          })
      }
  }).catch(error => {
      console.log("Revalidate catch error " + error)
  })
  };
  if (loading) return <div className="p-4">
    <Skeleton active/>
    <Skeleton active/>
    <Skeleton active/>
  </div>
  const sc = `window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '09b3763f18ea03a3078dade87fcf17e136934224', { region: 'eu' });
    smartlook('record', { forms: true, numbers: true, emails: true, ips: true });`

  return (
    <div className="landing-page">
      <Helmet>
        <title>{name}'s Loan Services </title>
        {!window.location.hostname.includes("localhost") && <script type='text/javascript'>
          {sc}
        </script>}
      </Helmet>
      <div className="landing-nav">
        <div className="menu-left">
          {props.customerid === "gmcc" && <img src='/images/gmccLogo.png' style={{height:40, marginRight:15}}/>}
          {props.customerid === "siliconcitymortgage" && <img src='/images/scmLogo.png' style={{height:70, marginRight:15}}/>}
          {JSON.parse(props.toolstoDisplay).includes('ratequoter') && <span onClick={() => handleClickCalculators('ratequoter')}>Rate Quote</span>}
          {JSON.parse(props.toolstoDisplay).includes('affordability') && <span onClick={() => handleClickCalculators('affordability')}>Affordability</span>}
          <span onClick={()=> window.open(getPreQualificationLink(),"_blank")}>Get Prequalified</span>
          {props.customerid === "gmcc" && <span onClick={() => window.location.href = landingUrl + "/special"}>Special Programs</span>}
          {props.customerid === "gmcc" && <span onClick={() => window.open(`https://app.gmccloan.com/resources/calhfa/gmcc?loid=${props.loid}`,"_blank")}>CalDFA</span>}
          {props.customerid === "siliconcitymortgage" && <span onClick={() => window.open(`https://app.zeitro.com/resources/calhfa/siliconcitymortgage?loid=${props.loid}`,"_blank")}>CalDFA</span>}

        </div>
        <div className="landing-navList">
          <Button className='contact-btn apply-now-btn' onClick={() => window.location.href=getPreQualificationLink()}>Apply Now</Button>
          <Button className='contact-btn' onClick={() => setShowContactmeModal(true)}>Contact me today</Button>
        </div>
        <img className="landingMeneButton" src='/images/menu.svg' onClick={showDrawer} />
      </div>
      <div>
        <div>
          <div className="landingProfile">
            <Row gutter={20}>
              <Col className="gutter-row" span={24} lg={10}>
                <BasicInfo appurl={props.appurl} customerid={props.customerid} loid={props.loid} basicInfo={basicInfo} contact={() => setShowContactmeModal(true)} />
              </Col>
              <Col className="gutter-row" span={24} lg={14}>
                <AboutMe basicInfo={basicInfo} aboutInfo={aboutInfo} />
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div
        style={{ backgroundColor: backgroundColor }}
        className="landingRateQuoter mt-5"
        id="landingRateQuoter"
        ref={refRateQuoter}
      >
        <LandingRateQuoter customerid={props.customerid} id={props.loid} width={width} height={height} {...props}/>
      </div>
        <div
            style={{ backgroundColor: "white" }}
            className="landingTools pb-2"
            id="landingTools"
            ref={refAffordability}
          >
            <LandingAffordability customerid={props.customerid} loid={props.loid} width={width} height={height} />
      </div>
      {props.customerid==="gmcc" && <div ref={refCalculators} className="calculators gmccspecials">
        {/* <div className="calculators-title">Special Program Qualifiers</div> */}
        <GMCCSpecials fromLanding={true} customerid={props.customerid} loid={props.loid} url={props.Url}/>
      </div>}
   
      <CustomerReview reviewList={reviewList} />
      <div className="landingFooter">
        <Row>
          <Col span={12} lg={4} className="sitemap">
            <div className="rowTitle">Sitemap</div>
            <div className="rowContent">
              <div>Home</div>
              {props.customerid !== "gmcc" && <div onClick={() => handleClickCalculators('ratequoter')}>Check Current Rate</div>}
              <div onClick={() => handleClickCalculators('affordability')}>Affordability</div>
              <div onClick={()=> window.open(getPreQualificationLink(),"_blank")}>Get Prequalified</div>
              {props.customerid === "gmcc" && <div onClick={() => window.location.href = landingUrl + "/special"}>Special Programs</div>}
              {props.customerid === "gmcc" && <div onClick={() => window.open(`https://app.gmccloan.com/resources/calhfa/gmcc?loid=${props.loid}`,"_blank") }>CalDFA</div>}
              {props.customerid === "siliconcitymortgage" && <div onClick={() => window.open(`https://app.zeitro.com/resources/calhfa/siliconcitymortgage?loid=${props.loid}`,"_blank") }>CalDFA</div>}
              <div onClick={() => window.location.href=getPreQualificationLink()}>Apply now</div>
            </div>
          </Col>
          <Col span={12} lg={4} className="contact">
            <div className="rowTitle">Contact</div>
            <div className="rowContent">
              <div>{ aboutInfo.phone }</div>
              <div>{ aboutInfo.email }</div>
              {props.customerid === "gmcc" && <div>1350 Old Bayshore Hwy #740, Burlingame, CA 94010, United States.</div>}
            </div>
          </Col>
          <Col span={24} lg={6} className="socialMedia">
            <div className="rowTitle">Social media</div>
            <div className="rowContent">
              { basicInfo.google && <img src='/images/google.svg' alt='google' onClick={() => window.location.href = dynamicExternalLink(basicInfo.google)} /> }
              { basicInfo.linkedin && <img src='/images/linkedln.svg' alt='linkedln' onClick={() => window.location.href = dynamicExternalLink(basicInfo.linkedin)} /> }
              { basicInfo.twitter && <img src='/images/twitter.svg' alt='twitter' onClick={() => window.location.href = dynamicExternalLink(basicInfo.twitter)} /> }
              { basicInfo.yelp && <img src='/images/yelp.svg' alt='yelp' onClick={() => window.location.href = dynamicExternalLink(basicInfo.yelp)} /> }
            </div>
          </Col>
          <Col span={24} lg={6} className="security">
            <div className="rowTitle">Security</div>
            <div className="rowContent">
              The Security of your personal information is our highest priority. We use bank-level encryption (256-bit AEE) to ensure it is never compromised.
            </div>
          </Col>
          <Col span={24} lg={4} className="QRCode">
            <div>
              <div className="rowTitle" style={{marginBottom: '4px'}}>Scan my QR code</div>
              <div className="rowContent">
                <QRCode
                  className="landing-qrcode"
                  errorLevel="H"
                  value={landingUrl}
                  size={110}
                  bordered={false}
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        {props.customerid !=="gmcc"?(
          <div className="d-flex" style={{flexDirection:"row", fontSize:"10px"}}>
            <img src='/images/equal_housing_oppotunity.png' style={{marginRight:"10px", width:"10%", heigh:"10%"}}/>
            <div >
              Interest rates and annual percentage rates (APRs) are based on current market rates, are for informational purposes only, are subject to change without notice and may be subject to pricing add-ons related to property type, loan amount, loan-to-value, credit score and other variables—call for details. This is not a credit decision or a commitment to lend. Depending on loan guidelines, mortgage insurance may be required. If mortgage insurance is required, the mortgage insurance premium could increase the APR and the monthly mortgage payment. Additional loan programs may be available. APR reflects the effective cost of your loan on a yearly basis, taking into account such items as interest, most closing costs, discount points (also referred to as “points”) and loan-origination fees. One point is 1% of the mortgage amount (e.g., $1,000 on a $100,000 loan). Your monthly payment is not based on APR, but instead on the interest rate on your note. Adjustable-rate mortgage (ARM) rates assume no increase in the financial index after the initial fixed period. ARM rates and monthly payments are subject to increase after the fixed period: ARMs assume 30-year term.
            </div>
          </div>
        ):(
          <GMCCDisclaimer />
        )}
        {/* <Row>
          <Col span={24} className="copyright">Powered by Zeitro, Inc. All Rights Reserved {new Date().getFullYear()}.</Col>
        </Row> */}
      </div>
      {showContactmeModal && getContactmeModal()}
      <Drawer className="drawer-nav" placement="top" onClose={onClose} open={navDrawer}>
        {props.customerid !== "gmcc" && <div className="nav" onClick={() => handleClickCalculators('ratequoter')}>Check Current Rate</div>}
        <div className="nav" onClick={() => handleClickCalculators('affordability')}>Check Affordability</div>
        <div className="nav" onClick={()=> window.open(getPreQualificationLink(),"_blank")}>Get Prequalified</div>
        {props.customerid === "gmcc" && <div className="nav" onClick={() => window.location.href = landingUrl + "/special"}>Special Programs</div>}
        {props.customerid === "gmcc" && <div className="nav" onClick={() => window.open(`https://app.gmccloan.com/resources/calhfa/gmcc?loid=${props.loid}`,"_blank")}>CalDFA</div>}
        {props.customerid === "siliconcitymortgage" && <div onClick={() => window.open(`https://app.zeitro.com/resources/calhfa/siliconcitymortgage?loid=${props.loid}`,"_blank") }>CalDFA</div>}
        <div style={{textAlign: 'center'}}><Button className='contact-btn' onClick={() => setShowContactmeModal(true)}>Contact me today</Button></div>
      </Drawer>
    </div>
  );
};

export default withRouter(LandingPage);
