

import * as brwr from '../borrowerdefs'

export function ChangeBorrowerId(t) {
    return { type: brwr.CHANGE_BORROWERID, payload: t  };
}
export function ChangeBorrowerLoanId(t) {
    return { type: brwr.CHANGE_BORROWERLOANID, payload: t  };
}
export function ChangeBorrowerName(t) {
    return { type: brwr.CHANGE_BORROWERNAME, payload: t  };
}

