import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Helmet } from 'react-helmet'

import { Redirect } from 'react-router-dom'


export default class Customer extends Component {
    constructor(props) {
        //console.log("in Login")
        super(props);
        this.form = React.createRef();

        this.local = window.location.hostname === "localhost"

        this.state = {
            customerid: "",
            validated: false,
            show: false,
            redirect: "",
        };

        this.pattern = "^.+$"

    }



    componentDidMount() {
        let customerid = localStorage.getItem("customerid")
        if (customerid != null) {
            this.setState({ redirect: <Redirect to={"/login/" + customerid} /> })
        }

    }
    componentWillUnmount() {

    }
    handleChange = event => {
        this.setState({ customerid: event.target.value })
    }

    handleSubmit = event => {

        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();
        this.setState({ redirect: <Redirect to={"/login/" + this.state.customerid} /> })
        return true
    }



    render() {

        return (
            <div className="Login " >
                {this.state.redirect}
                <Row className="mb-5">
                    <Col xs="auto" className="mt-3"><img src="bigLogo.png" width="64" height="64" alt='logo'></img></Col>
                </Row>
                <div className="mt-5">&nbsp;</div>
                <div style={{ maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <h1 style={{ paddingLeft: '15px' }}>Sign in</h1>
                    <Helmet>
                        <title> 'Zeitro Loan Officer Portal' </title>
                    </Helmet>

                    <Form style={{ margin: 'initial', maxWidth: 'none', width: 'initial' }} onSubmit={this.handleSubmit} validated={this.state.validated}>
                        <Row className="text-left text-nowrap">
                            <Col className="text-left">
                                <Form.Group controlId="customerid"  >
                                    <Form.Label className="text-left" >Please enter Company ID:</Form.Label>

                                    <Form.Control
                                        required
                                        name="customerid"
                                        size="sm"
                                        autoFocus
                                        autoComplete="customerid"
                                        type="text"
                                        defaultValue=""
                                        onChange={this.handleChange}
                                        pattern="^.+$"
                                        placeholder="company ID"

                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please enter a valid username!
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="py-3 mb-3 text-center">
                            <Col >
                                <Button
                                    style={{ width: '100%' }}
                                    type="submit"
                                    variant="primary"
                                >
                                    Next
                                </Button>
                            </Col>


                        </Row>
                        <Row className="mt-3">
                        </Row>


                    </Form>
                </div>
            </div>
        );
    }
}

