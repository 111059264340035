import React, { useState, useEffect } from 'react';

import { Form, Button, Container } from "react-bootstrap";
import "./AboutMe.css";
import ReadMore from './TextWithReadMore';


export default function AboutMe({handleClickCalculators, basicInfo, aboutInfo, customerid, loid, appurl, contact }) {
    const [checked, setChecked] = useState(false);
    const getPreQualificationLink = () => {

        if (window.location.host.includes("zeitro.us")) {
            return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        if (window.location.host.includes("localhost")) {
            return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
    }
 
    const contactMe = () => {
        contact()
    }
    let avatarSrc = "/images/avatar.png"
  
    return (
        <div className="about-me-new">
            {/* <div id="google_translate_element"></div>
                <div className='switch' onClick={() => {
                    setChecked(!checked)
                }

                }>
                    <div className={checked ? 'text checked' : "text"}>中</div>
                    <div className={!checked ? 'text checked' : "text"}>EN</div>
                </div> */}
            {/* <div className='about_me_banner'>
                {aboutInfo.banner}
        

            </div> */}
            <div className='basicInfoTexts'>
                <div className='mb-4'>
                    {basicInfo.nmls && <div className='introduction-headings'>Verified Loan officer (NMLS ID: {basicInfo.nmls})</div>}
                    <div className='landing-name'>{`${basicInfo.firstname} ${basicInfo.lastname}`}</div>
                    <div className='landing-mainText'>{`License State: ${basicInfo.states}`}</div>
                </div>
                <div className="about-navList mb-2">
                    {/* {customerid === "gmcc" && <Button className='contact-btn apply-now-btn-gmcc' onClick={() => handleClickCalculators('special')}>Check special programs</Button>}
                    {customerid === "gmcc" && <Button className='contact-btn apply-now-btn-gmcc' onClick={() => handleClickCalculators('ratequoter')}>Explore rates and apply now</Button>} */}
                    {/* {customerid === "gmcc" && <Button className='contact-btn apply-now-btn mr-0' onClick={() => window.location.href = getPreQualificationLink()}>Get prequalified</Button>} */}
                    <Button className='mt-2 contact-btn  apply-now-btn' onClick={contactMe}>Contact me today</Button>
                    {loid === "6502d405-7e12-4a7e-8e7e-8ffe960a267d" && <Button className='mt-2 contact-btn  apply-now-btn' onClick={() => window.open("https://calendly.com/mtsay/q-a-for-loan-application","_blank")}>Mortgage Consultation</Button>}
                </div>
                <div className='d-flex'>
                    <div className='quoteMark mr-2'>"</div>
                    { aboutInfo.introduction !== undefined ? <ReadMore maxCharacterCount={200}>{aboutInfo.introduction}</ReadMore> : '' }
                    <div className='quoteMark ml-2'>"</div>
                </div>
            </div>
       
            {

                basicInfo.tagsList.length > 0 && (
                    <div className='tag-list-new'>
                        {
                            basicInfo.tagsList.map((tag, index) =>
                                <div className='tag' key={index}>{tag}</div>
                            )
                        }
                    </div>
                )
            }

        </div>
    );
}