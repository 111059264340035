import React, {  useEffect, useState } from "react";
import { Button, Input, Space, Table, Skeleton, Tabs, Form, Tag, Dropdown, Menu, Modal } from 'antd';
import * as com from '../../Common';
import { clone, cloneDeep, set} from "lodash";
import { report } from "process";
import {CaretDownOutlined} from '@ant-design/icons';
import ProspectLoans from "../ProspectLoans";

const quarterMap={
    q1: [1,2,3],
    q2: [4,5,6],
    q3: [7,8,9],
    q4: [10,11,12],
}
const notreflectedRows = [
    "AC030","AC060","AC062","AC064","AC110","AC120","AC130","AC310","AC400",
    "AC500","AC510","AC520","AC700","AC710","AC720","AC800","AC810","AC920",
    "AC930","AC940","AC1000"
]
const boldrows=[
    "AC066","AC090","AC190","AC290","AC390",
    "AC590","AC790","AC890","AC990"
]
const rowsLabel= {
    applicationdata: [
        "AC010","AC020","AC030","AC040","AC050","AC060","AC062",
        "AC064","AC065","AC066","AC070","AC080","AC090"
    ],
    closedloandata: [
        'AC100','AC110','AC120','AC130','AC190','AC200','AC210',
        'AC220','AC290','AC300','AC310','AC320','AC390','AC400',
        'AC500','AC510','AC520','AC590','AC600','AC610','AC620',
        "AC630",
        'AC700','AC710','AC720','AC790','AC800','AC810','AC890',
        'AC900','AC910','AC920','AC930','AC940','AC990','AC1000'
    ],
    revenuedata: [
        'AC1100'
    ],
    mlodata: []
}

const closedLoanTotals = [
    "AC190","AC290","AC390","AC590","AC790","AC890","AC990"
]

const currentLoanStatuses = [
    'interviewstarted','interviewfinished', 'documentationrequested',
    'loansubmitted', 'conditionallyapproved', 'applicationwithdrawn', 'applicationdenied',
    "pendingrateselection", "readyforsubmit", "indocs", "fundsreleased"
  ]
  const manualupdateStatuses = [
    "loansubmitted", "conditionallyapproved", "applicationwithdrawn", "applicationdenied",
    "indocs","fundsreleased"
  ]

const CallReportTable = (props) => {
    const [form] = Form.useForm();

    const handleApplicationDataChange = (event, record,columnkey) => {
        props.setReportSaved(false);
        props.setNewChange(true);
        let tempdata = cloneDeep(props.datatoSave);
        if (columnkey === "brokeredamount" || columnkey === "thirdpartyamount") {
            let tempkey = columnkey.replace("amount", "count");        
            let amount = Number(event.target.value.replace(/,/g, ''));
            let count = 0;
            if (record[tempkey]){
                count = Number(record[tempkey].toString().replace(/,/g, ''));
            }
        
            let updatedAverage = count !== 0 ? amount / count : 0;
            updatedAverage = Math.round(updatedAverage);
        
            tempkey = tempkey.replace("count", "average");
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata[record.datapoint]][columnkey] = event.target.value;
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata[record.datapoint]][tempkey] = updatedAverage.toString();
        }
        if (columnkey === "brokeredcount" || columnkey === "thirdpartycount"){
            //remove leading 0 if event.target.value have leading 0
            if (event.target.value.startsWith('0')){
                event.target.value = event.target.value.slice(1);
            }
            let tempkey = columnkey.replace("count","amount")
            let amount = 0;
            if (record[tempkey]){
                amount = Number(record[tempkey].toString().replace(/,/g, ''));
            }
            let count = Number(event.target.value.replace(/,/g, ''))
            let updatedAverage = count !== 0 ? amount / count : 0;
            updatedAverage = Math.round(updatedAverage);

            tempkey = tempkey.replace("amount","average")
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata[record.datapoint]][columnkey] = event.target.value
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata[record.datapoint]][tempkey] = updatedAverage.toString()
        }

        // let AC066_amount = 0
        // let AC066_count = 0
        // let AC066_arr = ["AC010","AC020","AC030","AC040","AC050","AC060","AC062","AC064","AC065"]
        // if input change is on amount column, then update ac066_amount to be the sumb of the amount of AC070 AND AC080
        if (columnkey === "brokeredamount" || columnkey === "thirdpartyamount"){
            // tempdata.data[props.selectedState].applicationdata.forEach(row => {
            //     if (AC066_arr.includes(row.datapoint)){
            //         AC066_amount += Number(row[columnkey].toString().replace(/,/g, ''))
            //     }
            // })
            // tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][columnkey] = AC066_amount.toString()
            // let tempkey = columnkey.replace("amount","count")
            // let count = tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][tempkey].toString().replace(/,/g, '')
            // let average = AC066_amount / count
            // average = Math.round(average * 100) / 100;
            // tempkey = tempkey.replace("count","average")
            // tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][tempkey] = average.toString()
            // if (record.datapoint !== "AC080"){
            //     let AC070_amount = tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC070"]][columnkey].toString().replace(/,/g, '')
            //     let AC080_amount = AC066_amount - AC070_amount
            //     tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][columnkey] = AC080_amount.toString()
            //     tempkey = tempkey.replace("average","count")
            //     let AC080_count = tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][tempkey].toString().replace(/,/g, '')
            //     let AC080_average = AC080_amount / AC080_count
            //     AC080_average = Math.round(AC080_average * 100) / 100;
            //     tempkey = tempkey.replace("count","average")
            //     tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][tempkey] = AC080_average.toString()
            // }        
            let tempkey = columnkey
            let AC066_amount = Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC010"]][columnkey])
                            + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC020"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC030"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC040"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC050"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC060"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC062"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC064"]][columnkey])
                            + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC065"]][columnkey])
            let AC090_amount = Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][columnkey]) + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC070"]][columnkey].toString().replace(/,/g, ''))
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][columnkey] = AC066_amount.toString()
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC090"]][columnkey] = AC090_amount.toString()
            tempkey = tempkey.replace("amount","count")
            let AC066_count = Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC010"]][tempkey])
                            + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC020"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC030"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC040"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC050"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC060"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC062"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC064"]][tempkey])
            let AC090_count = Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][tempkey]) + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC070"]][tempkey].toString().replace(/,/g, ''))
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC090"]][tempkey] = AC090_count.toString()
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][tempkey] = AC066_count.toString()
            tempkey = tempkey.replace("count","average")
            let AC066_average = AC066_amount / AC066_count
            AC066_average = Math.round(AC066_average);
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][tempkey] = AC066_average.toString()
            let AC090_average = AC090_amount / AC090_count
            AC090_average = Math.round(AC090_average);
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC090"]][tempkey] = AC090_average.toString()
        }

        if (columnkey === "brokeredcount" || columnkey === "thirdpartycount"){
            // tempdata.data[props.selectedState].applicationdata.forEach(row => {
            //     if (AC066_arr.includes(row.datapoint)){
            //         AC066_count += Number(row[columnkey].toString().replace(/,/g, ''))
            //     }
            // })
            // tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][columnkey] = AC066_count.toString()
            // let tempkey = columnkey.replace("count","amount")
            // let amount = tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][tempkey].toString().replace(/,/g, '')
            // let average = amount / AC066_count
            // average = Math.round(average * 100) / 100;
            // tempkey = tempkey.replace("amount","average")
            // tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][tempkey] = average.toString()
            // if (record.datapoint !== "AC080"){
            //     let AC070_count = tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC070"]][columnkey].toString().replace(/,/g, '')
            //     let AC080_count = AC066_count - AC070_count
            //     tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][columnkey] = AC080_count.toString()
            //     tempkey = tempkey.replace("average","amount")
            //     let AC080_amount = tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][tempkey].toString().replace(/,/g, '')
            //     let AC080_average = AC080_amount / AC080_count
            //     AC080_average = Math.round(AC080_average * 100) / 100;
            //     tempkey = tempkey.replace("amount","average")
            //     tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][tempkey] = AC080_average.toString()
            // }
            let tempkey = columnkey
            let AC066_count = Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC010"]][columnkey])
                            + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC020"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC030"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC040"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC050"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC060"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC062"]][columnkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC064"]][columnkey])
            let AC090_count = Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][columnkey]) + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC070"]][columnkey].toString().replace(/,/g, ''))
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC090"]][columnkey] = AC090_count.toString()
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][columnkey] = AC066_count.toString()
            tempkey = tempkey.replace("count","amount")
            let AC066_amount = Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC010"]][tempkey])
                            + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC020"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC030"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC040"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC050"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC060"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC062"]][tempkey])
                            - Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC064"]][tempkey])
                            + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC065"]][tempkey])
            let AC090_amount = Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC080"]][tempkey]) + Number(tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC070"]][tempkey].toString().replace(/,/g, ''))
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC090"]][tempkey] = AC090_amount.toString()
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][tempkey] = AC066_amount.toString()
            tempkey = tempkey.replace("amount","average")
            let AC066_average = AC066_amount / AC066_count
            AC066_average = Math.round(AC066_average);
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC066"]][tempkey] = AC066_average.toString()
            let AC090_average = AC090_amount / AC090_count
            AC090_average = Math.round(AC090_average);
            tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata["AC090"]][tempkey] = AC090_average.toString()
        }
   
        props.setDatatoSave(tempdata)
        props.setCallReportDatatoSave(tempdata)
    }
    const handleClosedLoanDataChange = (event, record,columnkey) => {
        props.setReportSaved(false);
        props.setNewChange(true);
        let tempdata = cloneDeep(props.datatoSave);
        if (columnkey === "brokeredamount" || columnkey === "NDCamount"){
            let tempkey = columnkey.replace("amount","count")
            let amount = Number(event.target.value.replace(/,/g, ''));
            let count = 0;
            if (record[tempkey]){
                count = Number(record[tempkey].toString().replace(/,/g, ''));
            }
            let updatedAverage = count !== 0 ? amount / count : 0;
            updatedAverage = Math.round(updatedAverage);
            tempkey = tempkey.replace("count","average")
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata[record.datapoint]][columnkey] = event.target.value
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata[record.datapoint]][tempkey] = updatedAverage.toString()
        }
        if (columnkey === "brokeredcount" || columnkey === "NDCcount"){
            if (event.target.value.startsWith('0')){
                event.target.value = event.target.value.slice(1);
            }
            let tempkey = columnkey.replace("count","amount")
            let amount = 0;
            if (record[tempkey]){
                amount = Number(record[tempkey].toString().replace(/,/g, ''));
            }
            let count = Number(event.target.value.replace(/,/g, ''))
            let updatedAverage = count !== 0 ? amount / count : 0;
            updatedAverage = Math.round(updatedAverage);
            tempkey = tempkey.replace("amount","average")
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata[record.datapoint]][columnkey] = event.target.value
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata[record.datapoint]][tempkey] = updatedAverage.toString()
        }


        let AC190_arr= ["AC100","AC110","AC120","AC130"]
        let AC290_arr= ["AC200","AC210","AC220"]
        let AC390_arr= ["AC300","AC310","AC320"]
        let AC590_arr= ["AC500","AC510","AC520"]
        let AC790_arr= ["AC700","AC710","AC720"]
        let AC890_arr= ["AC800","AC810"]
        let AC990_arr= ["AC920", "AC930", "AC940"]
        let AC190_amount = 0
        let AC290_amount = 0
        let AC390_amount = 0
        let AC590_amount = 0
        let AC790_amount = 0
        let AC890_amount = 0
        let AC990_amount = 0
        let AC190_count = 0
        let AC290_count = 0
        let AC390_count = 0
        let AC590_count = 0
        let AC790_count = 0
        let AC890_count = 0
        let AC990_count = 0

        if (columnkey === "brokeredamount" || columnkey === "NDCamount"){
            tempdata.data[props.selectedState].closedloandata.forEach(row => {
                if (AC190_arr.includes(row.datapoint)){
                    AC190_amount += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC290_arr.includes(row.datapoint)){
                    AC290_amount += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC390_arr.includes(row.datapoint)){
                    AC390_amount += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC590_arr.includes(row.datapoint)){
                    AC590_amount += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC790_arr.includes(row.datapoint)){
                    AC790_amount += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC890_arr.includes(row.datapoint)){
                    AC890_amount += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC990_arr.includes(row.datapoint)){
                    AC990_amount += Number(row[columnkey].toString().replace(/,/g, ''))
                }
            })
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC190"]][columnkey] = AC190_amount.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC290"]][columnkey] = AC290_amount.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC390"]][columnkey] = AC390_amount.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC590"]][columnkey] = AC590_amount.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC790"]][columnkey] = AC790_amount.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC890"]][columnkey] = AC890_amount.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC990"]][columnkey] = AC990_amount.toString()
            let tempkey = columnkey.replace("amount","count")
            AC190_count = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC190"]][tempkey].toString().replace(/,/g, '')
            AC290_count = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC290"]][tempkey].toString().replace(/,/g, '')
            AC390_count = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC390"]][tempkey].toString().replace(/,/g, '')
            AC590_count = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC590"]][tempkey].toString().replace(/,/g, '')
            AC790_count = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC790"]][tempkey].toString().replace(/,/g, '')
            AC890_count = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC890"]][tempkey].toString().replace(/,/g, '')
            AC990_count = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC990"]][tempkey].toString().replace(/,/g, '')
            let AC190_average = AC190_amount / AC190_count
            AC190_average = Math.round(AC190_average);
            let AC290_average = AC290_amount / AC290_count
            AC290_average = Math.round(AC290_average);
            let AC390_average = AC390_amount / AC390_count
            AC390_average = Math.round(AC390_average);
            let AC590_average = AC590_amount / AC590_count
            AC590_average = Math.round(AC590_average);
            let AC790_average = AC790_amount / AC790_count
            AC790_average = Math.round(AC790_average);
            let AC890_average = AC890_amount / AC890_count
            AC890_average = Math.round(AC890_average);
            let AC990_average = AC990_amount / AC990_count
            AC990_average = Math.round(AC990_average);
            tempkey = tempkey.replace("count","average")
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC190"]][tempkey] = AC190_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC290"]][tempkey] = AC290_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC390"]][tempkey] = AC390_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC590"]][tempkey] = AC590_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC790"]][tempkey] = AC790_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC890"]][tempkey] = AC890_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC990"]][tempkey] = AC990_average.toString()
        }
        if (columnkey === "brokeredcount" || columnkey === "NDCcount"){
            tempdata.data[props.selectedState].closedloandata.forEach(row => {
                if (AC190_arr.includes(row.datapoint)){
                    AC190_count += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC290_arr.includes(row.datapoint)){
                    AC290_count += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC390_arr.includes(row.datapoint)){
                    AC390_count += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC590_arr.includes(row.datapoint)){
                    AC590_count += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC790_arr.includes(row.datapoint)){
                    AC790_count += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC890_arr.includes(row.datapoint)){
                    AC890_count += Number(row[columnkey].toString().replace(/,/g, ''))
                }
                if (AC990_arr.includes(row.datapoint)){
                    AC990_count += Number(row[columnkey].toString().replace(/,/g, ''))
                }
            })
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC190"]][columnkey] = AC190_count.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC290"]][columnkey] = AC290_count.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC390"]][columnkey] = AC390_count.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC590"]][columnkey] = AC590_count.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC790"]][columnkey] = AC790_count.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC890"]][columnkey] = AC890_count.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC990"]][columnkey] = AC990_count.toString()
            let tempkey = columnkey.replace("count","amount")
            AC190_amount = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC190"]][tempkey].toString().replace(/,/g, '')
            AC290_amount = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC290"]][tempkey].toString().replace(/,/g, '')
            AC390_amount = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC390"]][tempkey].toString().replace(/,/g, '')
            AC590_amount = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC590"]][tempkey].toString().replace(/,/g, '')
            AC790_amount = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC790"]][tempkey].toString().replace(/,/g, '')
            AC890_amount = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC890"]][tempkey].toString().replace(/,/g, '')
            AC990_amount = tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC990"]][tempkey].toString().replace(/,/g, '')
            let AC190_average = AC190_amount / AC190_count
            AC190_average = Math.round(AC190_average);
            let AC290_average = AC290_amount / AC290_count
            AC290_average = Math.round(AC290_average);
            let AC390_average = AC390_amount / AC390_count
            AC390_average = Math.round(AC390_average);
            let AC590_average = AC590_amount / AC590_count
            AC590_average = Math.round(AC590_average);
            let AC790_average = AC790_amount / AC790_count
            AC790_average = Math.round(AC790_average);
            let AC890_average = AC890_amount / AC890_count
            AC890_average = Math.round(AC890_average);
            let AC990_average = AC990_amount / AC990_count
            AC990_average = Math.round(AC990_average);
            tempkey = tempkey.replace("amount","average")
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC190"]][tempkey] = AC190_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC290"]][tempkey] = AC290_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC390"]][tempkey] = AC390_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC590"]][tempkey] = AC590_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC790"]][tempkey] = AC790_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC890"]][tempkey] = AC890_average.toString()
            tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata["AC990"]][tempkey] = AC990_average.toString()
        }
        props.setDatatoSave(tempdata)
        props.setCallReportDatatoSave(tempdata)

    }
    const handleRevenueDataChange = (event, record,columnkey) => {
        props.setReportSaved(false);
        props.setNewChange(true);
        let tempdata = cloneDeep(props.datatoSave);
        if (columnkey === "amount"){
            let tempkey = columnkey.replace("amount","count")
            let amount = Number(event.target.value.replace(/,/g, ''));
            let count = 0;
            if (record[tempkey]){
                count = Number(record[tempkey].toString().replace(/,/g, ''));
            }
            let updatedAverage = count !== 0 ? amount / count : 0;
            updatedAverage = Math.round(updatedAverage);
            tempkey = tempkey.replace("count","average")
            tempdata.data[props.selectedState].revenuedata[com.callReportFieldIndex.revenuedata[record.datapoint]][columnkey] = event.target.value
            tempdata.data[props.selectedState].revenuedata[com.callReportFieldIndex.revenuedata[record.datapoint]][tempkey] = updatedAverage.toString()
        }
        if (columnkey === "count"){
            let tempkey = columnkey.replace("count","amount")
            let amount = 0;
            if (record[tempkey]){
                amount = Number(record[tempkey].toString().replace(/,/g, ''));
            }
            let count = Number(event.target.value.replace(/,/g, ''))
            let updatedAverage = count !== 0 ? amount / count : 0;
            updatedAverage = Math.round(updatedAverage);
            tempkey = tempkey.replace("amount","average")
            tempdata.data[props.selectedState].revenuedata[com.callReportFieldIndex.revenuedata[record.datapoint]][columnkey] = event.target.value
            tempdata.data[props.selectedState].revenuedata[com.callReportFieldIndex.revenuedata[record.datapoint]][tempkey] = updatedAverage.toString()
        }
        props.setDatatoSave(tempdata)
        props.setCallReportDatatoSave(tempdata)

    }
    const handleMLODataChange = (event, record,columnkey) => {
        props.setReportSaved(false);
        props.setNewChange(true);
        let tempdata = cloneDeep(props.datatoSave);
        if (columnkey === "amount"){
            let tempkey = columnkey.replace("amount","count")
            let amount = Number(event.target.value.replace(/,/g, ''));
            let count = 0;
            if (record[tempkey]){
                count = Number(record[tempkey].toString().replace(/,/g, ''));
            }
            let updatedAverage = count !== 0 ? amount / count : 0;
            updatedAverage = Math.round(updatedAverage);
            tempkey = tempkey.replace("count","average")
            tempdata.data[props.selectedState].mlodata[props.loMap[record.parameters+"_"+record.nmlsid]][columnkey] = event.target.value
            tempdata.data[props.selectedState].mlodata[props.loMap[record.parameters+"_"+record.nmlsid]][tempkey] = updatedAverage.toString()
        }
        if (columnkey === "count"){
            if (event.target.value.startsWith('0')){
                event.target.value = event.target.value.slice(1);
            }
            let tempkey = columnkey.replace("count","amount")
            let amount = 0;
            if (record[tempkey]){
                amount = Number(record[tempkey].toString().replace(/,/g, ''));
            }
            let count = Number(event.target.value.replace(/,/g, ''))
            let updatedAverage = count !== 0 ? amount / count : 0;
            updatedAverage = Math.round(updatedAverage);
            tempkey = tempkey.replace("amount","average")
            if (record.parameters === "Unassigned"){
                tempdata.data[props.selectedState].mlodata[props.loMap["Unassigned_-"]][columnkey] = event.target.value
                tempdata.data[props.selectedState].mlodata[props.loMap["Unassigned_-"]][tempkey] = updatedAverage.toString()
            }else{
                tempdata.data[props.selectedState].mlodata[props.loMap[record.parameters+"_"+record.nmlsid]][columnkey] = event.target.value
                tempdata.data[props.selectedState].mlodata[props.loMap[record.parameters+"_"+record.nmlsid]][tempkey] = updatedAverage.toString()
            }
        }
        props.setDatatoSave(tempdata)
        props.setCallReportDatatoSave(tempdata)

    }
    const applicationColumns = [
        {
          title: 'Data Points',
          dataIndex: 'datapoint',
          key: 'datapoint',
          width: "150px",
          render: (text, record) => (
            <div className={boldrows.includes(record.datapoint)?"boldParameters":""}>{notreflectedRows.includes(record.datapoint)?`*${record.datapoint}`:` ${record.datapoint}`}</div>
        )
        },
        {
            title: 'Application Pipeline',
            dataIndex: 'parameters',
            key: 'parameters',
            width: "250px",
            render: (text, record) => (
                <div className={boldrows.includes(record.datapoint)?"boldParameters":""}>{record.parameters}</div>
            )
          },
        {
          title: 'Brokered',
          children: [
            {
              title: 'Amount',
              dataIndex: 'brokeredamount',
              key: 'brokeredamount',
              render: (text, record) => (
                (record.datapoint!=="AC066" && record.datapoint!=="AC090" && !props.openingGeneratedReport)?(<div className="tableInputWithPrefix">
                    $
                    <Input
                        style={{marginLeft:"5px"}}
                        value={com.commaize(record.brokeredamount)}
                        onChange={event => handleApplicationDataChange(event, record, "brokeredamount")}
                    />
                </div>
                ):(
                    <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.brokeredamount)}</div>
                    </div>
                )
              )
            },
            {
              title: 'Count',
              dataIndex: 'brokeredcount',
              key: 'brokeredcount',
              render: (text, record) => (
                (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix">
                    <Input
                        style={{marginLeft:"5px"}}
                        value={record.brokeredcount}
                        onChange={event => handleApplicationDataChange(event, record, "brokeredcount")}
                    />
                </div>
                ):(
                    <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}></div>
                        <div>{record.brokeredcount}</div>
                    </div>
                )
              )
            },
            {
                title: 'Average',
                dataIndex: 'brokeredaverage',
                key: 'brokeredaverage',
                render: (text, record) => (
                    (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix disabledInputColor">
                        
                        <Input
                            style={{marginLeft:"5px"}}
                            value={record.brokeredaverage === 0? "-": "$ "+com.commaize(record.brokeredaverage)}
                            onChange={event => handleApplicationDataChange(event, record, "brokeredaverage")}
                            disabled={true}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            {/* <div style={{marginRight:"15px"}}>$</div> */}
                            <div>{record.brokeredaverage === 0? "-": "$ "+com.commaize(record.brokeredaverage)}</div>
                        </div>
                    )
                  )
              },
          ],
        },
        {
          title: 'Third Party',
          children: [
            {
              title: 'Amount',
              dataIndex: 'thirdpartyamount',
              key: 'thirdpartyamount',
              render: (text, record) => (
                (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix">
                    $
                    <Input
                        style={{marginLeft:"5px"}}
                        value={com.commaize(record.thirdpartyamount)}
                        onChange={event => handleApplicationDataChange(event, record, "thirdpartyamount")}
                    />
                </div>
                ):(
                    <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.thirdpartyamount)}</div>
                    </div>
                )
              )
            },
            {
              title: 'Count',
              dataIndex: 'thirdpartycount',
              key: 'thirdpartycount',
              render: (text, record) => (
                (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix">
                    <Input
                        style={{marginLeft:"5px"}}
                        value={record.thirdpartycount}
                        onChange={event => handleApplicationDataChange(event, record, "thirdpartycount")}
                    />
                </div>
                ):(
                    <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}></div>
                        <div>{record.thirdpartycount}</div>
                    </div>
                )
              )
            },
            {
                title: 'Average',
                dataIndex: 'thirdpartyaverage',
                key: 'thirdpartyaverage',
                render: (text, record) => (
                    (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix disabledInputColor">
                        
                        <Input
                            style={{marginLeft:"5px"}}
                            value={record.thirdpartyaverage === 0? "-": "$ "+com.commaize(record.thirdpartyaverage)}
                            onChange={event => handleApplicationDataChange(event, record, "thirdpartyaverage")}
                            disabled={true}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            {/* <div style={{marginRight:"15px"}}>$</div> */}
                            <div>{record.thirdpartyaverage === 0? "-": "$ "+com.commaize(record.thirdpartyaverage)}</div>
                        </div>
                    )
                  )
              },
          ],
        },
      ];
    const closedloanColumns = [
        {
            title: 'Data Points',
            dataIndex: 'datapoint',
            key: 'datapoint',
            width: "150px",
            render: (text, record) => (
                <div className={boldrows.includes(record.datapoint)?"boldParameters":""}>{notreflectedRows.includes(record.datapoint)?`*${record.datapoint}`: record.datapoint==="type"?"":` ${record.datapoint}`}</div>
            ),
            onCell: (record) => {
                if (record.datapoint === "type") {  // Add any datapoint values you want to merge
                  return {
                    colSpan: 1  // Merge all columns
                  };
                }
                return {};
              }
          },
          {
              title: 'Parameters',
              dataIndex: 'parameters',
              key: 'parameters',
              width: "250px",
              render: (text, record) => (
                <div className={boldrows.includes(record.datapoint)?"boldParameters": record.datapoint==="type"?"closedLoanTypeLabel":""}>{record.parameters}</div>
            ),
            onCell: (record) => {
                if (record.datapoint === "type") {  // Add any datapoint values you want to merge
                  return {
                    colSpan: 6  // Merge all columns
                  };
                }
                return {};
            }

            },
          {
            title: 'Brokered',
            children: [
              {
                title: 'Amount',
                dataIndex: 'brokeredamount',
                key: 'brokeredamount',
                render: (text, record) => (
                    record.datapoint==="type"?(<div></div>):
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix">
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.brokeredamount)}
                            onChange={event => handleClosedLoanDataChange(event, record, "brokeredamount")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{com.commaize(record.brokeredamount)}</div>
                        </div>
                    )
                ),
                onCell: (record) => {
                    if (record.datapoint === "type") {  // Add any datapoint values you want to merge
                      return {
                        colSpan: 0  // Merge all columns
                      };
                    }
                    return {};
                  }
              },
              {
                title: 'Count',
                dataIndex: 'brokeredcount',
                key: 'brokeredcount',
                render: (text, record) => (
                    record.datapoint==="type"?(<div></div>):
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix">
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.brokeredcount}
                            onChange={event => handleClosedLoanDataChange(event, record, "brokeredcount")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}></div>
                            <div>{record.brokeredcount}</div>
                        </div>
                    )
                ),
                onCell: (record) => {
                    if (record.datapoint === "type") {  // Add any datapoint values you want to merge
                      return {
                        colSpan: 0  // Merge all columns
                      };
                    }
                    return {};
                  }
              },
              {
                  title: 'Average',
                  dataIndex: 'brokeredaverage',
                  key: 'brokeredaverage',
                  render: (text, record) => (
                    record.datapoint==="type"?(<div></div>):
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix disabledInputColor">
                        
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.brokeredaverage === 0? "-": "$ "+com.commaize(record.brokeredaverage)}
                            onChange={event => handleClosedLoanDataChange(event, record, "brokeredaverage")}
                            disabled={true}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            {/* <div style={{marginRight:"15px"}}>$</div> */}
                            <div>{record.brokeredaverage === 0? "-": "$ "+com.commaize(record.brokeredaverage)}</div>
                        </div>
                    )
                ),
                onCell: (record) => {
                    if (record.datapoint === "type") {  // Add any datapoint values you want to merge
                      return {
                        colSpan: 0  // Merge all columns
                      };
                    }
                    return {};
                  }
                },
            ],
          },
          {
            title: 'Closed-Retail Application',
            children: [
              {
                title: 'Amount',
                dataIndex: 'NDCamount',
                key: 'NDCamount',
                render: (text, record) => (
                    record.datapoint==="type"?(<div></div>):
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix">
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.NDCamount)}
                            onChange={event => handleClosedLoanDataChange(event, record, "NDCamount")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{com.commaize(record.NDCamount)}</div>
                        </div>
                    )
                ),
                onCell: (record) => {
                    if (record.datapoint === "type") {  // Add any datapoint values you want to merge
                      return {
                        colSpan: 0  // Merge all columns
                      };
                    }
                    return {};
                  }
              },
              {
                title: 'Count',
                dataIndex: 'NDCcount',
                key: 'NDCcount',
                render: (text, record) => (
                    record.datapoint==="type"?(<div></div>):
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix">
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.NDCcount}
                            onChange={event => handleClosedLoanDataChange(event, record, "NDCcount")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}></div>
                            <div>{record.NDCcount}</div>
                        </div>
                    )
                ),
                onCell: (record) => {
                    if (record.datapoint === "type") {  // Add any datapoint values you want to merge
                      return {
                        colSpan: 0  // Merge all columns
                      };
                    }
                    return {};
                  }
              },
              {
                  title: 'Average',
                  dataIndex: 'NDCaverage',
                  key: 'NDCaverage',
                  render: (text, record) => (
                    record.datapoint==="type"?(<div></div>):
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div className="tableInputWithPrefix disabledInputColor">
                        
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.NDCaverage === 0? "-": "$ "+com.commaize(record.NDCaverage)}
                            onChange={event => handleClosedLoanDataChange(event, record, "NDCaverage")}
                            disabled={true}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            {/* <div style={{marginRight:"15px"}}>$</div> */}
                            <div>{record.NDCaverage === 0? "-": "$ "+com.commaize(record.NDCaverage)}</div>
                        </div>
                    )
                ),
                onCell: (record) => {
                    if (record.datapoint === "type") {  // Add any datapoint values you want to merge
                      return {
                        colSpan: 0  // Merge all columns
                      };
                    }
                    return {};
                  }
                },
            ],
          },
    ]
    const revenueColumns = [
        {
            title: 'Data Points',
            dataIndex: 'datapoint',
            key: 'datapoint',
          },
          {
              title: 'Parameters',
              dataIndex: 'parameters',
              key: 'parameters',
            },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div className="tableInputWithPrefix">
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.amount)}
                            onChange={event => handleRevenueDataChange(event, record, "amount")}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.amount)}</div>
                    </div>
                )
            )
        },
        // {
        //     title: 'Count',
        //     dataIndex: 'count',
        //     key: 'count',
        //     render: (text, record) => (
        //         (!props.openingGeneratedReport)?(
        //             <div className="tableInputWithPrefix">
        //                 <Input
        //                     // defaultValue={record.brokeredamount}
        //                     style={{marginLeft:"5px"}}
        //                     value={record.count}
        //                     onChange={event => handleRevenueDataChange(event, record, "count")}
        //                 />
        //             </div>
        //         ):(
        //             <div style={{display:"flex"}}>
        //                 <div style={{marginRight:"15px"}}></div>
        //                 <div>{record.count}</div>
        //             </div>
        //         )
        //     )
        // },
        // {
        //     title: 'Average',
        //     dataIndex: 'average',
        //     key: 'average',
        //     render: (text, record) => (
        //         (!props.openingGeneratedReport)?(
        //             <div className="tableInputWithPrefix disabledInputColor">
        //                 $
        //                 <Input
        //                     // defaultValue={record.brokeredamount}
        //                     style={{marginLeft:"5px"}}
        //                     value={com.commaize(record.average)}
        //                     onChange={event => handleRevenueDataChange(event, record, "average")}
        //                     disabled={true}
        //                 />
        //             </div>
        //         ):(
        //             <div style={{display:"flex"}}>
        //                 <div style={{marginRight:"15px"}}>$</div>
        //                 <div>{com.commaize(record.average)}</div>
        //             </div>
        //         )
        //     )
        // },
    ]
    const mloColumns = [
        {
            title: 'Data Points',
            dataIndex: 'datapoint',
            key: 'datapoint',
            width: "150px",
        },
        {
            title: 'Parameters',
            dataIndex: 'parameters',
            key: 'parameters',
        },
        {
            title: 'MLO NMLS ID',
            dataIndex: 'nmlsid',
            key: 'nmlsid',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div className="tableInputWithPrefix">
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.nmlsid}
                            onChange={event => handleMLODataChange(event, record, "nmlsid")}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{record.nmlsid}</div>
                    </div>
                )
            )        
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div className="tableInputWithPrefix">
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.amount)}
                            onChange={event => handleMLODataChange(event, record, "amount")}
                        />
                    </div>
                 ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.amount)}</div>
                    </div>
                )
            )
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div className="tableInputWithPrefix">
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.count}
                            onChange={event => handleMLODataChange(event, record, "count")}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}></div>
                        <div>{record.count}</div>
                    </div>
                )
            )
        },
        {
            title: 'Average',
            dataIndex: 'average',
            key: 'average',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div className="tableInputWithPrefix disabledInputColor">
                        
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.average === 0? "-": "$ "+com.commaize(record.average)}
                            onChange={event => handleMLODataChange(event, record, "average")}
                            disabled={true}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        {/* <div style={{marginRight:"15px"}}>$</div> */}
                        <div>{record.average === 0? "-": "$ "+com.commaize(record.average)}</div>
                    </div>
                )
            )
        },
    ]
    const loansPreviewColumns = [
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Loan Amount',
            dataIndex: 'loanamount',
            key: 'loanamount',
            render: (text, record) => (
                <div>{record.loanamount == 0 ? "-" : "$" + com.commaize(record.loanamount)}</div>
            )
        },
        {
            title: 'Start Date',
            dataIndex: 'appintake',
            key: 'appintake',
            sorter: (a, b) => new Date(a.appintake) - new Date(b.appintake),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Loan Officer',
            dataIndex: 'loanofficer',
            key: 'loanofficer',
            render: (text, record) => (
                <div>{record.loanofficer === "Unassigned" ? "Unassigned" : record.loanofficer.split("_")[0]}</div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <div 
                    className="loansViewButton" 
                    onClick={() => {
                        getLoanDetailsData(record)
                        props.setShowLoanDetails(true)
                    }}
                >
                    View
                </div>
            )
        }
    ]
    
    const columnsMap = {
        applicationdata: applicationColumns,
        closedloandata: closedloanColumns,
        revenuedata: revenueColumns,
        mlodata: mloColumns,
        loanspipeline: loansPreviewColumns,

    }

    const termMap = {
        "year30": "30 year",
        "year25": "25 year",
        "year20": "20 year",
        "year15": "15 year",
        "year10": "10 year",
        "arm56": "5 year",
        "arm76": "7 year",
        "arm106": "10 year",
    }

    const LoanDetailsJSON = (document, index) => {
        return {
            "Primary Borrower": document.name ? document.name : "N/A",
            "Loan Amount": document.loanamount ? "$" + com.commaize(document.loanamount) : "N/A",
            "Change in Loan Amount": "N/A",
            "Loan Purpose": document.purpose ? document.purpose : "N/A",
            "App Intake": document.appintake ? document.appintake : "N/A",
            "Refinance Primary Purpose Type": document.purpose === "refinance" || document.purpose === "cashoutrefinance" ? document.purpose : "N/A",
            "Refinance Cash Out Amount": "N/A",
            "Interest Rate": document.baserate && document.baserate !== 0 ? document.baserate : "N/A",
            "Loan Product": termMap[document.loanterm] && document.amortization ? document.mortgageapplied + " " + termMap[document.loanterm] + " " + document.amortization : "N/A",
            "Lender": document.lender ? document.lender : "N/A",
            "Occupancy": document.occupancy ? com.getOccupancy(document.occupancy) : "N/A",
            "Current Stage": "N/A",
            "Property Address": document.address ? document.address : "N/A",
            "Property State": document.state ? document.state : "N/A",
            "Loan Officer Name": document.loanofficer.split("_")[0],
            "Loan Officer NMLS": document.loanofficer.split("_")[1],
            "Lien Position": "First lien",
            "Mortgage Type": document.mtype ? document.mtype : "N/A",
            "Housing Type": document.propertytype ? com.getPropertyTypeName(document.propertytype) : "N/A",
            "Non-QM": "False",
            "Compensation Amount": "N/A",
            "Pass-Through Fees": "N/A",
            "Compensation Payer": "N/A",
            "Broker Fee Amount": "N/A",
            "Final Points Amount": "N/A",
            "Net Loan Revenue": "N/A",
            "Gross Loan Revenue": "N/A",
            "Investor Holdback": "N/A",
            "Other Adjustments": "N/A",
            "Pre-Approved": "N/A",
            "Qualification": "N/A",
            "Loan Setup": "N/A",
            "Submitted to UW": "N/A",
            "Approved w/ Conditions": "N/A",
            "Disclosed": "N/A",
            "Re-submittal": "N/A",
            "Clear to Close": "N/A",
            "Docs Out": "N/A",
            "Docs Signed": "N/A",
            "Loan Funded": "N/A",
            "Loan Finalized": "N/A",
            "Broker Check Received": "N/A",
            "Adverse": "N/A",
            "Suspended": "N/A",
            "Adverse Reason": "N/A",
            "MCR Application Date": "N/A"
        }
    }

    const getLoanDetailsData = (record) => {
        let loanDetailsData = []
        let temp = cloneDeep(record)
        let result = LoanDetailsJSON(temp)
        Object.entries(result).forEach(([key, value]) => {
            loanDetailsData.push({
                attribute: key,
                data: value
            })
        })
        props.setLoanDetailsData(loanDetailsData)
    }

    return(
        <div className="callReportTableWrapper">
            {props.selectedType !=="loanspipeline" || props.selectedType !== "revenuedata" && <div className="text-left" style={{marginLeft:"5px",marginBottom:"5px"}}>* = currently not reflected by our system</div>}
            <Table
                style={{border:"1px solid #ECECEC",borderRadius:"8px"}}
                className="callreportTable"
                columns={columnsMap[props.selectedType]}
                dataSource={props.datatoSave.data[props.selectedState][props.selectedType]}
                pagination={false}
                showSorterTooltip={false}
                scroll={{ y: 640}}  // scroll to be 100% parent height
                rowClassName={(record) => boldrows.includes(record.datapoint)?"totalrowsStyle":""}
            />
        </div>
    )
}

const CallReportPipeline = (props) => {

    const [editingCell, setEditingCell] = useState({ loanID: null, columnKey: null });

    const columns=[
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 100,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            width: 100,
        },
        {
            title: 'App Intake',
            dataIndex: 'appintake',
            key: 'appintake',
            width: 100,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 180,
            render: (text, record) => {
                const items = Object.entries(com.loanStatusMap).map(([key, val]) => {
                  if (manualupdateStatuses.includes(key)) {
                    return {
                      key: key,
                      label: (
                        <a onClick={() => handleStatusChange(record.loanid, key)}>
                          {val}
                        </a>
                      ),
                    }
                  }
              })
      
                return <div className="loansStatus">
                  <Tag
                    bordered={false}
                    color={com.mapStatusTagColor(record.status)}
                    className="loansStatusTag" >
                    {com.splitStatusByWords(record.status)}
                    
                  </Tag>
                  <Dropdown
                      menu={{items,}}
                      placement="bottomLeft"
                      arrow
                    >
                      <Button className="loanStatusSelect" onClick={() => toggleEdit(record.loanid, 'status')} >
                        <CaretDownOutlined style={{fontSize:11}}/>
                      </Button>
                  </Dropdown>
                </div>
              },
        },
        {
            title: 'Loan Purpose',
            dataIndex: 'purpose',
            key: 'purpose',
            width: 150,
        },
        {
            title: 'Loan Price',
            dataIndex: 'loanprice',
            key: 'loanprice',
            width: 100,
        },
        {
            title: 'Loan Amount',
            dataIndex: 'loanamount',
            key: 'loanamount',
            width: 100,
        },
        {
            title: 'Mortgage Type',
            dataIndex: 'mortgageapplied',
            key: 'mortgageapplied',
            width: 150,
        },
        {
            title: 'Product Type',
            dataIndex: 'loantype',
            key: 'loantype',
            width: 100,
        },
        {
            title: 'Amortization',
            dataIndex: 'amortization',
            key: 'amortization',
            width: 100,
        },
        {
            title: 'Loan Term',
            dataIndex: 'loanterm',
            key: 'loanterm',
            width: 100,
        },
        {
            title: 'APR',
            dataIndex: 'apr',
            key: 'apr',
            width: 100,
        },
        {
            title: 'Base Rate',
            dataIndex: 'baserate',
            key: 'baserate',
            width: 100,
        },
        {
            title: 'Monthly Payment',
            dataIndex: 'monthlypay',
            key: 'monthlypay',
            width: 100,
        },
        {
            title: 'Lender Fee',
            dataIndex: 'lenderfee',
            key: 'lenderfee',
            width: 100,
        },
        {
            title: 'Property Address',
            dataIndex: 'address',
            key: 'address',
            width: 150,
        },
        {
            title: 'Property Type',
            dataIndex: 'propertytype',
            key: 'propertytype',
            width: 150,
        },
        {
            title: 'Occupancy',
            dataIndex: 'occupancy',
            key: 'occupancy',
            width: 150,
        },
        {
            title: 'Loan Officer',
            dataIndex: 'loanofficer',
            key: 'loanofficer',
            width: 150,
        },
      
    ]

    const toggleEdit = (loanID, columnKey) => {
        setEditingCell(prevState => ({
          loanID: prevState.loanID === loanID && prevState.columnKey === columnKey ? null : loanID,
          columnKey: prevState.columnKey === columnKey && prevState.loanID === loanID ? null : columnKey
        }));
      };

    const handleStatusChange = (loanID, newStatus) => {
        // this.setState({ loadingStatus: true })
        let token = sessionStorage.getItem("ZeitroA")
        let body = { LoanID: loanID, Key: "status", Value: newStatus, IsAdmin: true}
        fetch('/los/updatepipeline', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(response => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
              response.status);
            return;
          }
        //   this.toggleEdit(null, null);
          const updatedloans = props.allloans.map(loan => {
            if (loan.loanid === loanID) {
              return { ...loan, status: newStatus };
            }
            return loan;
          });
          props.setAllloans(updatedloans);
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        })
      }

    return(
        <div style={{marginTop:"20px",width:"100%"}}>
            <Table
                style={{border:"1px solid #ECECEC",borderRadius:"8px",width:"100%"}}
                className="callreportPipeline"
                columns={columns}
                dataSource={props.data}
                pagination={true}
                showSorterTooltip={false}
                scroll={{  y: 800 }}
            />
        </div>
    )
}


const CallReportPreview= (props) => {
    const [datatoSave, setDatatoSave] = useState(cloneDeep(props.reportdata));
    const [loading, setLoading] = useState(false);
    const [loNameMap, setLoNameMap] = useState({});
    const [allloans, setAllloans] = useState(props.Loans);
    const [selectedState, setSelectedState] = useState(props.selectedStates[0]);
    const [selectedType, setSelectedType] = useState("applicationdata");
    const [loanDetailsData, setLoanDetailsData] = useState([])
    const [showLoanDetails, setShowLoanDetails] = useState(false)
    useEffect(() => {
        const result = cloneDeep(props.reportdata);
        if(!props.openingGeneratedReport){
            props.selectedStates.forEach((state) => {
                let data_afterApplication = applicationDataCalculation(state, datatoSave, allloans);
                let data_afterClosedLoan = closedloanDataCalculation(state, data_afterApplication, allloans);
                let data_afterRevenue = revenueDataCalculation(state, data_afterClosedLoan, allloans);
                let data_afterMlo = mloDataCalculation(state, data_afterRevenue, allloans);
                let resultdata = loansDataCalculation(state, data_afterMlo, allloans);
                result.data[state] = resultdata.data[state];
            });          
        }
        props.setCallReportDatatoSave(result)
        setDatatoSave(result)
    }, [allloans]);

    const calculateGrossRevenue = (term,monthlypay) => {
        let revenue = 0
        if (term !== "" && monthlypay !== ""){
            revenue = parseFloat(monthlypay)* parseFloat(term) * 12
        }
        return revenue
    }
    const applicationDataCalculation =(state,initialdata,allloans)=>{
        let tempdata = cloneDeep(initialdata);

        rowsLabel.applicationdata.forEach(row => {
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredamount = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredcount = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredaverage = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyamount = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartycount = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyaverage = 0

        })

        allloans.forEach(s => {
            if (s.state === com.RuleSet.State.values[state.toUpperCase()]) {
                if (s.loanamount===""){
                    s.loanamount=0
                }
                //application in process at the beginning
                if (s.status !== "applicationwithdrawn" && 
                s.status !== "fundsreleased" && 
                s.status !== "applicationabandoned" && 
                s.status !== "applicationrejected" && 
                s.status !== "borrowerwithdrew" && 
                s.status !== "loancancelled" && 
                s.status !== "loandenied" &&
                new Date(com.timeGoToJS(s.started)) < new Date(props.selectedYear, quarterMap[props.selectedQuarter][0]-1, 1)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC010']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC010']].brokeredamount += parseInt(s.loanamount)
                }
                //application received
                if (quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredamount += parseInt(s.loanamount)
                }
                //application withdrawn
                if (s.status === "applicationwithdrawn" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredamount += parseInt(s.loanamount)
                }
                //application denied
                if (s.status === "applicationdenied" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredamount += parseInt(s.loanamount)
                }

                //loans closed and funded
                if (s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredamount += parseInt(s.loanamount)
                }
            }
        })
        // //net changes
        // tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC065']].brokeredcount += tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredcount-tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredcount-tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredcount
        // tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC065']].brokeredamount += tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredamount-tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredamount-tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredamount
        //total application pipeline
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC066']].brokeredcount = 
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC010']].brokeredcount 
                + tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredcount
                - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC030']].brokeredcount
                - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredcount
                - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredcount
                - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC060']].brokeredcount
                - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC062']].brokeredcount
                - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC064']].brokeredcount
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC066']].brokeredamount =
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC010']].brokeredamount 
            + tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredamount
            - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC030']].brokeredamount
            - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredamount
            - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredamount
            - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC060']].brokeredamount
            - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC062']].brokeredamount
            - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC064']].brokeredamount
            + tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC065']].brokeredamount
        //applications at the end of period
        // tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC080']].brokeredcount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC066']].brokeredcount - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredcount
        // tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC080']].brokeredamount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC066']].brokeredamount - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredamount
        //total application pipeline result
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC090']].brokeredcount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC080']].brokeredcount + tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredcount
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC090']].brokeredamount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC080']].brokeredamount + tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredamount

        rowsLabel.applicationdata.forEach(row => {
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredaverage= Math.round(tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredamount/tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredcount)
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyaverage= Math.round(tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyamount/tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartycount)
            if(tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredamount===0){
                tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredaverage=0
            }
            if(tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyamount===0){
                tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyaverage=0
            }
        })
        
        return tempdata
    }
    const closedloanDataCalculation =(state,initialdata,allloans)=>{
        let tempdata = cloneDeep(initialdata);

        rowsLabel.closedloandata.forEach(row => {
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredamount = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredcount = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredaverage = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCamount = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCcount = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCaverage = 0

        })

        allloans.forEach(s => {
            if (s.state === com.RuleSet.State.values[state.toUpperCase()]) {
                if (s.loanamount===""){
                    s.loanamount=0
                }
                //conventional
                if (s.mortgageapplied === "conventional" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear) ) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC100']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC100']].brokeredamount += parseInt(s.loanamount)
                }
                if ((s.propertytype === "singlefamilydetached"|| s.propertytype==="singlefamilyattached") && s.status === "fundsreleased" && 
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC200']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC200']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.propertytype === "manufacturedhome" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC210']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC210']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.propertytype === "twotofourfamily" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC220']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC220']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.purpose === "Purchase" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC300']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC300']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.purpose === "Rate/Term Refinance" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC320']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC320']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.status === "fundsreleased" && quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC900']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC900']].brokeredamount += parseInt(s.loanamount)
                }
            }
        })

        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC190']].brokeredamount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC100']].brokeredamount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC190']].brokeredcount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC100']].brokeredcount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC290']].brokeredamount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC200']].brokeredamount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC210']].brokeredamount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC220']].brokeredamount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC290']].brokeredcount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC200']].brokeredcount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC210']].brokeredcount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC220']].brokeredcount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC390']].brokeredamount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC300']].brokeredamount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC320']].brokeredamount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC390']].brokeredcount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC300']].brokeredcount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC320']].brokeredcount  
      
        rowsLabel.closedloandata.forEach(row => {
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredaverage= Math.round(tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredamount/tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredcount)
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCaverage= Math.round(tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].thirdpartyamount/tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].thirdpartycount)
            if(tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredamount===0){
                tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredaverage=0
            }
            if(tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCamount===0){
                tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCaverage=0
            }
        })

        return tempdata
    }
    const revenueDataCalculation =(state,initialdata,allloans)=>{
        let tempdata = cloneDeep(initialdata);
        let revenue = 0
        tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].amount = 0
        tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].count = 0
        tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].average = 0
        allloans.forEach(s => {
            if (s.state === com.RuleSet.State.values[state.toUpperCase()]) {
                if (s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    revenue = calculateGrossRevenue(s.loanterm,s.monthlypay)
                    tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].amount += revenue
                    tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].count += 1
                }
            }
        })
        tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].average = Math.round(tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].amount/tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].count)
        if(tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].amount===0){
            tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].average=0
        }
        return tempdata
    }
    const mloDataCalculation =(state,initialdata,allloans)=>{
        let tempdata = cloneDeep(initialdata);
        let index=0
        let loNameMapTemp = {}
        Object.values(props.loanofficers).forEach(loanofficer => {
            loNameMapTemp[loanofficer] = index
            index += 1
        })
        loNameMapTemp["Unassigned_-"] = index
        setLoNameMap(loNameMapTemp)
        Object.entries(loNameMapTemp).forEach(([key, value]) => {
            tempdata.data[state].mlodata.push(
                {
                    datapoint: `ACMLO${value + 1}`,
                    parameters: key.split("_")[0],
                    amount: 0,
                    count: 0,
                    average: 0,
                    nmlsid: key.split("_")[1]
                }
            )
        })
        Object.keys(loNameMapTemp).forEach(lo => {
            let index = loNameMapTemp[lo];
            tempdata.data[state].mlodata[index].amount = 0
            tempdata.data[state].mlodata[index].count = 0
            tempdata.data[state].mlodata[index].average = 0
        });

        allloans.forEach(s => {
            if (s.state === com.RuleSet.State.values[state.toUpperCase()]) {
                if (quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)){
                    if (s.loanofficer === "Unassigned"){
                        tempdata.data[state].mlodata[loNameMapTemp["Unassigned_-"]].amount += parseInt(s.loanamount)
                        tempdata.data[state].mlodata[loNameMapTemp["Unassigned_-"]].count += 1
                    } else {
                        tempdata.data[state].mlodata[loNameMapTemp[s.loanofficer]].amount += parseInt(s.loanamount)
                        tempdata.data[state].mlodata[loNameMapTemp[s.loanofficer]].count += 1
                    }
                }
            }
        })

        Object.keys(loNameMapTemp).forEach(lo => {
            let index = loNameMapTemp[lo];
            tempdata.data[state].mlodata[index].average = Math.round(tempdata.data[state].mlodata[index].amount / tempdata.data[state].mlodata[index].count);
            if(tempdata.data[state].mlodata[index].amount === 0){
                tempdata.data[state].mlodata[index].average = 0;
            }
        });
        return tempdata
    }

    const getPurpose = (purpose, hascontract, unknownpurpose) => {
        if (purpose === "refinance") return "Rate/Term Refinance"
        if (purpose === "cashoutrefinance") return "Cash-out Refinance"
        if (purpose === "purchase") {
          if (unknownpurpose) return "TBD"
          if (!hascontract) return "Pre-qualification"
          return "Purchase"
        }
        return "TBD"
    }

    const LoansJSON = (document, index) => {
        let dt = new Date(com.timeGoToJS(document.started))
        return {
            address: document.address,
            state: document.state,
            name: document.firstname + " " + document.lastname,
            loanid: document.loanid,
            countystate: document.county+", "+ com.convertStateToAbbr(document.state),
            loanofficer: document.loanofficer,
            status: document.status,
            appintake: dt.toLocaleDateString(),
            // loid: document.lo_id,
            loanamount: document.loanamount,
            loanprice: document.price,
            loantype: document.mtype,
            mortgageapplied: document.mortgageapplied,
            amortization: document.amortization,
            mtype: document.mtype,
            loanterm: document.loanterm,
            apr: document.apr,
            baserate: document.baserate,
            monthlypay: document.monthlypay,
            lenderfee: document.lenderfee,
            propertytype: document.propertytype,
            occupancy: document.occupancy,
            purpose: getPurpose(document.purpose, document.hascontract, document.unknownpurpose),
        }
    }

    const loansDataCalculation = (state,initialdata,allloans) => {
        let tempdata = cloneDeep(initialdata);
        let temploans = cloneDeep(allloans);
        let filteredloans = temploans.map(LoansJSON).filter(s => {
            return(
                s.state.includes(com.RuleSet.State.values[state.toUpperCase()]) &&
                quarterMap[props.selectedQuarter].includes(new Date(s.appintake).getMonth()+1) &&
                new Date(s.appintake).getFullYear() === parseInt(props.selectedYear)
            )
        })
        //records of filteredloans by appintake date descending order
        filteredloans.sort((a, b) => new Date(a.appintake) - new Date(b.appintake))
        tempdata.data[state]["loanspipeline"] = filteredloans;
        
        return tempdata
    }

    const getMenuItems = () => {
        return props.selectedStates.map(state => {
            return {
                key: state,
                label: com.RuleSet.State.values[state.toUpperCase()].toUpperCase(),
                children: [
                    {
                        key: `${state}_applicationdata`,
                        label: "Application Data",
                    },
                    {
                        key: `${state}_closedloandata`,
                        label: "Closed Loan Data",
                    },
                    {
                        key: `${state}_revenuedata`,
                        label: "Revenue Data",
                    },
                    {
                        key: `${state}_mlodata`,
                        label: "MLO Data",
                    },
                    {
                        key: `${state}_loanspipeline`,
                        label: `Loans in ${props.selectedQuarter.toUpperCase()} ${props.selectedYear} (${state.toUpperCase()})`,
                    }
                ]
            }
        })
    }

    const renderLoanDetails = () => {
        const loanDetailsColumns = [
            {
                title: '',
                dataIndex: 'attribute',
                key: 'attribute',
                width: "280px",
                render: (text, record) => {
                    return <div style={{color: "#586570"}}>{text}</div>
                }
            },
            {
                title: 'Loan information',
                dataIndex: 'data',
                key: 'data' 
            }
        ]
        return(
            <Modal
                open={showLoanDetails}
                onCancel={() => setShowLoanDetails(false)}
                footer={null}
                title="Loan Details"
                rootClassName="loanDetailsModal"
            > 
                <div style={{paddingRight:"10px"}}>
                    <div className="loanDetailsModalSub">Please review loan details and if you spot any errors, please update the information directly in the Loans section.</div>
                    <Table 
                        columns={loanDetailsColumns}
                        dataSource={loanDetailsData}
                        pagination={false}
                        scroll={{y: "calc(100vh - 200px)"}}
                    />
                </div>
            </Modal>
        )
    }
    
    return(
        <div className="callReportContentWrapper">
            <Menu 
                style={{width: "250px", height: "100%", overflowY: "auto"}}
                defaultSelectedKeys={[props.selectedStates[0]+"_applicationdata"]}
                defaultOpenKeys={[props.selectedStates[0]]}
                mode="inline"
                items={getMenuItems()}
                onClick={(e) => {
                    setSelectedState(e.key.split("_")[0])
                    setSelectedType(e.key.split("_")[1])
                }}
            />
            <CallReportTable
                {...props}
                selectedQuarter={props.selectedQuarter}
                selectedYear={props.selectedYear}
                loMap={loNameMap}
                allloans={allloans}
                setAllloans={setAllloans}
                datatoSave={datatoSave}
                setDatatoSave={setDatatoSave}
                selectedState={selectedState}
                selectedType={selectedType}
                setCallReportDatatoSave={props.setCallReportDatatoSave}
                setLoanDetailsData={setLoanDetailsData}
                setShowLoanDetails={setShowLoanDetails}
                setReportSaved={props.setReportSaved}
                setNewChange={props.setNewChange}
            />
            {renderLoanDetails()}
        </div>
    )
}

export default CallReportPreview;