import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

const Form1065 = ({ doc, onFocus, onChangeTextInput }) => {
  return (
    <>
      <Row>
        <Form.Group as={Col} controlId="Year">
          <Form.Label>Year</Form.Label>
          <Form.Control defaultValue={doc['Year']['Value']} type="text" onFocus={() => onFocus(doc['Year']['BBox'], doc['Year']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Year', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line4OrdinaryIncome">
          <Form.Label>Line 4: Ordinary Income</Form.Label>
          <Form.Control defaultValue={doc['Line4OrdinaryIncome']['Value']} type="text" onFocus={() => onFocus(doc['Line4OrdinaryIncome']['BBox'], doc['Line4OrdinaryIncome']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Line4OrdinaryIncome', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line5NetFarmProfit">
          <Form.Label>Line 5: Net Farm Profit</Form.Label>
          <Form.Control defaultValue={doc['Line5NetFarmProfit']['Value']} type="text" onFocus={() => onFocus(doc['Line5NetFarmProfit']['BBox'], doc['Line5NetFarmProfit']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Line5NetFarmProfit', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line6NetGain">
          <Form.Label>Line 6: Net Gain</Form.Label>
          <Form.Control defaultValue={doc['Line6NetGain']['Value']} type="text" onFocus={() => onFocus(doc['Line6NetGain']['BBox'], doc['Line6NetGain']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Line6NetGain', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line7OtherIncome">
          <Form.Label>Line 7: Other Income</Form.Label>
          <Form.Control defaultValue={doc['Line7OtherIncome']['Value']} type="text" onFocus={() => onFocus(doc['Line7OtherIncome']['BBox'], doc['Line7OtherIncome']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Line7OtherIncome', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line16cDepreciation">
          <Form.Label>Line 16c: Depreciation</Form.Label>
          <Form.Control defaultValue={doc['Line16cDepreciation']['Value']} type="text" onFocus={() => onFocus(doc['Line16cDepreciation']['BBox'], doc['Line16cDepreciation']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Line16cDepreciation', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line17Depletion">
          <Form.Label>Line 17: Depletion</Form.Label>
          <Form.Control defaultValue={doc['Line17Depletion']['Value']} type="text" onFocus={() => onFocus(doc['Line17Depletion']['BBox'], doc['Line17Depletion']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Line17Depletion', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line20Other">
          <Form.Label>Line 20: Other</Form.Label>
          <Form.Control defaultValue={doc['Line20Other']['Value']} type="text" onFocus={() => onFocus(doc['Line20Other']['BBox'], doc['Line20Other']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Line20Other', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="SchLLine16dMortgagesorNotesPayableinLessThan1Year">
          <Form.Label>Schedule L Line 16d: Mortgages or Notes Payable in Less Than 1 Year</Form.Label>
          <Form.Control defaultValue={doc['SchLLine16dMortgagesorNotesPayableinLessThan1Year']['Value']} type="text" onFocus={() => onFocus(doc['SchLLine16dMortgagesorNotesPayableinLessThan1Year']['BBox'], doc['SchLLine16dMortgagesorNotesPayableinLessThan1Year']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['SchLLine16dMortgagesorNotesPayableinLessThan1Year', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="SchM1Line4bTravelAndEntertainment">
          <Form.Label>Schedule M1 Line 4b: Travel And Entertainment</Form.Label>
          <Form.Control defaultValue={doc['SchM1Line4bTravelAndEntertainment']['Value']} type="text" onFocus={() => onFocus(doc['SchM1Line4bTravelAndEntertainment']['BBox'], doc['SchM1Line4bTravelAndEntertainment']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['SchM1Line4bTravelAndEntertainment', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Form8825Line14Depreciation">
          <Form.Label>Form 8825 Line 14: Depreciation</Form.Label>
          <Form.Control defaultValue={doc['Form8825Line14Depreciation']['Value']} type="text" onFocus={() => onFocus(doc['Form8825Line14Depreciation']['BBox'], doc['Form8825Line14Depreciation']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['Form8825Line14Depreciation', 'Value'])} />
        </Form.Group>
      </Row>
      {doc['SchK1'] && <>
        <h2>Schedule K1</h2>
        <Row>
          <Form.Group as={Col} controlId="SchK1Year">
            <Form.Label>Year</Form.Label>
            <Form.Control defaultValue={doc['SchK1']['Year']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['Year']['BBox'], doc['SchK1']['Year']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['SchK1', 'Year', 'Value'])} />
          </Form.Group>

          <Form.Group as={Col} controlId="SchK1Line1OrdinaryIncome">
            <Form.Label>Line 1: Ordinary Income</Form.Label>
            <Form.Control defaultValue={doc['SchK1']['Line1OrdinaryIncome']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['Line1OrdinaryIncome']['BBox'], doc['SchK1']['Line1OrdinaryIncome']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['SchK1', 'Line1OrdinaryIncome', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="SchK1Line2NetRentalRealEstate">
            <Form.Label>Line 2: Net Rental Real Estate</Form.Label>
            <Form.Control defaultValue={doc['SchK1']['Line2NetRentalRealEstate']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['Line2NetRentalRealEstate']['BBox'], doc['SchK1']['Line2NetRentalRealEstate']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['SchK1', 'Line2NetRentalRealEstate', 'Value'])} />
          </Form.Group>

          <Form.Group as={Col} controlId="SchK1Line3OtherNetRentalIncome">
            <Form.Label>Line 3: Other Net Rental Income</Form.Label>
            <Form.Control defaultValue={doc['SchK1']['Line3OtherNetRentalIncome']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['Line3OtherNetRentalIncome']['BBox'], doc['SchK1']['Line3OtherNetRentalIncome']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['SchK1', 'Line3OtherNetRentalIncome', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="SchK1PercentOwnership">
            <Form.Label>Percent Ownership</Form.Label>
            <Form.Control defaultValue={doc['SchK1']['PercentOwnership']['Value']} type="text" onFocus={() => onFocus(doc['SchK1']['PercentOwnership']['BBox'], doc['SchK1']['PercentOwnership']['Page'])} onChange={e => onChangeTextInput('form1065.pdf', e.target.value, ['SchK1', 'PercentOwnership', 'Value'])} />
          </Form.Group>
        </Row>
      </>}
    </>
  );
}

export default Form1065;