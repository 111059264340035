import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Swiper from "react-id-swiper";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { commaize } from "../Common";
import "./LandingPage.css";

SwiperCore.use([Pagination]);

const Properties = (props) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  const getProperties = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getproperties", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-LO": props.id,
      },
    }).then((response) => {
      if (response.status !== 200) {
        setLoading(false);
        return;
      }
      response.json().then((r) => {
        if (r) {
          setProperties(r);
        }
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    getProperties();
  }, []);

  useEffect(() => {
    console.log(properties);
  }, [properties]);

  const params = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: (index, className) => {
        return '<span class="' + className + '" >' + (index + 1) + "</span>";
      },
    },
  };

  const getPropertyCard = (data) => {
    return (
      <div className="propertyInfo">
        <div className="propertyImage">
          <img src={data.ImageSRCs[0]} />
        </div>
        <div className="propertyAddress1">{data.Address}</div>
        <div className="propertyAddress2">
          {data.City + ", " + data.PropertyState + " " + data.Zipcode}
        </div>
        <div className="propertyRooms">
          {data.NumOfBeds + " bed, " + data.NumOfBaths + " bath"}
        </div>
        <div className="propertyPrice">{"$" + commaize(data.ListingPrice)}</div>
      </div>
    );
  };

  const showProperties = () => {
    if (properties.length === 0) {
      return "";
    }
    let out = [];
    let slideCount = Math.floor(properties.length / 4);
    if (properties.length % 4 > 0) {
      slideCount += 1;
    }
    for (let i = 0; i < slideCount; i++) {
      out.push(
        <div>
          <Container>
            <Row>
              <Col xs={6} lg={3}>
                {getPropertyCard(properties[i * 4 + 0])}
              </Col>
              <Col xs={6} lg={3}>
                {i * 4 + 1 <= properties.length - 1
                  ? getPropertyCard(properties[i * 4 + 1])
                  : ""}
              </Col>
              <Col xs={6} lg={3}>
                {i * 4 + 2 <= properties.length - 1
                  ? getPropertyCard(properties[i * 4 + 2])
                  : ""}
              </Col>
              <Col xs={6} lg={3}>
                {i * 4 + 3 <= properties.length - 1
                  ? getPropertyCard(properties[i * 4 + 3])
                  : ""}
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return <Swiper {...params}>{out}</Swiper>;
  };

  return (properties.length > 0 || props.fromPreview) ? (
    <Container className="personalinfo ">
      <Row>
        <Col className="propertiesTitle1">PROPERTIES FOR SALE</Col>
      </Row>
      <Row>
        <Col className="propertiesTitle2">
          <a style={{ color: "#6E6E70" }}>Some great homes</a> for you
        </Col>
      </Row>
      {showProperties()}
    </Container>
  ) : (
    <div></div>
  );
};

export default Properties;
