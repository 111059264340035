import React, {useState, useEffect} from "react";
import {withRouter, Redirect, useHistory} from "react-router-dom";
import {Elements, CardElement, PaymentElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {Spin, Skeleton, Button} from "antd";
import "./Billing.css";
import * as com from "../Common";
import StripePaymentForm from "./Subscribe";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(
  getStripePublicKey()
);

export function getStripePublicKey() {
  if (window.location.hostname.includes('localhost') || window.location.hostname.includes('zeitro.us'))  return "pk_test_51OEzY9I66V2JvOwpc9hJAIZo8RxLTiPO33iv1Takv419F9AhTAxX35VfJEvyJZX02d68Uqm6WyvErE84MyNVrQzR000acdv6i1"
  return "pk_live_51OEzY9I66V2JvOwp5hPwHHCHd2bzwgK9KKIBwHsVqgyzKjCbJCnMJdfJxhdcoH2kurI1M9qm6TYYlBHQtq1NRKhM00HstjtkWY"
}

export default function Prices() {
  const [loadingPrices, setLoadingPrices] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingSetupIntent, setLoadingSetupIntent] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currentPaymentMethods, setCurrentPaymentMethods] = useState(null);
  const [currentUsage, setCurrentUsage] = useState(null);
  const [currentNumberOfLOs, setCurrentNumberOfLOs] = useState(null);
  const [currentCreditReportUsage, setCurrentCreditReportUsage] = useState(null);
  const [prices, setPrices] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [selectedPriceId, setSelectedPriceId] = useState(null);

  const [name, setName] = useState("");
  const history = useHistory();
  useEffect(() => {
    createCustomer();
  }, []);

  const createCustomer = async () => {
    setLoadingPrices(true);
    try {
      let token = sessionStorage.getItem("ZeitroA");
      const response = await fetch("/los/stripe/createorgetcustomer", {
        cache: "no-cache",
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
      });

      if (!response.ok) {
        setLoadingPrices(false);
        console.error("Auth fetch error, at /data/getprofile: ");
      } else {
        const js = await response.json();

        // Fetch prices and wait for it to complete
        const priceResponse = await fetch("/los/stripe/config");
        if (priceResponse.ok) {
          const {prices} = await priceResponse.json();
          prices.sort((a, b) => a.unit_amount - b.unit_amount);

          setPrices(prices);
        }

        setCustomer(js.customer);
        setCurrentSubscription(js.subscriptions);
        setCurrentPaymentMethods(js.paymentMethods);
        setCurrentUsage(js.usageRecord);
        setCurrentNumberOfLOs(js.numberOfActiveLOs);
        setCurrentCreditReportUsage(js.creditReportUsage)
        setLoadingPrices(false);
      }
    } catch (error) {
      setLoadingPrices(false);
      console.error(error);
    }
  };

  const handleSelectPrice = async (priceId) => {
    setLoadingSetupIntent(true);
    setSelectedPriceId(priceId);
    try {
      const response = await fetch("/los/stripe/getorcreatesetupintent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: customer.id,
        }),
      });

      const data = await response.json();

      setClientSecret(data.client_secret);
      setLoadingSetupIntent(false);

      history.push({
        pathname: "/app/billing/subscribe",
        state: {
          clientSecret: data.client_secret,
          selectedPriceId: priceId,
          customer: customer,
          paymentMethods: currentPaymentMethods,
        },
      });
    } catch (error) {
      setLoadingSetupIntent(false);
      console.error("Error fetching setup intent:", error);
    }
  };

  const handleCancelPrice = async () => {
    setLoadingCancel(true);
    try {
      const response = await fetch("/los/stripe/cancelsubscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: currentSubscription[0].id,
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.subscription.status === "canceled") {
        alert("Subscription canceled successfully");
        window.location.reload();
      }
      setLoadingCancel(false);
    } catch (error) {
      setLoadingCancel(false);
      console.error("Error fetching setup intent:", error);
    }
  };

  const showPrice = (price) => {
    let isCurrentPlan =
      (currentSubscription === null && price.unit_amount === 0) ||
      (currentSubscription !== null && price.id === currentSubscription[0].items.data[0].plan.id);
    return (
      <div className={isCurrentPlan ? "billingPlanSelectedWrap mx-2" : "billingPlanWrap mx-2"}>
        <div className="title-3">{price.metadata.displayed_name}</div>
        <div className="billingPlanPrice">${price.unit_amount / 100} / LO / month</div>
        <div className="billingPlanButtonWrap">
          <Button
            disabled={isCurrentPlan}
            loading={loadingSetupIntent}
            className="billingPlanButton"
            onClick={() => handleSelectPrice(price.id)}>
            Select
          </Button>
          {isCurrentPlan && price.unit_amount !== 0 && (
            <Button
              onClick={() => handleCancelPrice()}
              loading={loadingCancel}>
              Cancel
            </Button>
          )}
        </div>
      </div>
    );
  };

  const displayCurrentUsage = () => {
    return <div className="m-3">
      <div className="title-2">Current usage</div>
      {currentUsage !== null && <div className="d-flex align-items-center justify-content-center">
          <div>Active number of loan officers/seats: {currentNumberOfLOs}/{currentUsage.data[0].total_usage}</div>
          <Button type="link" className="ml-1">Add seats</Button>
        </div>}
      <div>Credit report usage: {currentCreditReportUsage}</div>
    </div>
  }

  if (loadingPrices) {
    return (
      <div className="p-5">
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    );
  }
  return (
    <div className="p-3">
      <div className="title-2">Select a plan</div>

      <div className="d-flex justify-content-center px-5 mt-3">{prices.map((price) => showPrice(price))}</div>

      {displayCurrentUsage( )}
    </div>
  );
}

Prices = withRouter(Prices);
