import React, { useState, useEffect, useContext } from "react";
import { useInView } from "react-intersection-observer";
import { Form, Col, Button, Row, Container, Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Affordability from "../Tools/Afordability/Affordability";
// import RateQuoteRealtorVersion from "../Tools/MortgageCalculators/RateQuoteRealtorVersion";
import RateQuoteBorrowerVersion from "../Tools/MortgageCalculators/RateQuoteBorrowerVersion";
import "./LandingPage.css";

const Tools = (props) => {
  const isMobile = props.width <= 480;

  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  function ContextAwareToggle({ children, eventKey, callback, text }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div className="toolAccordionTitle" onClick={decoratedOnClick}>
        <div className="toolAccordionText">{text}</div>
        <div>{isCurrentEventKey ? "﹀" : "︿"}</div>
      </div>
    );
  }

  return (
    <Container className="tools " ref={ref}>
      <Row>
        <Col className="toolsTitle1">MORTGAGE TOOLS</Col>
      </Row>
      <Row>
        <Col className="toolsTitle2">
          <a style={{ color: "#6E6E70" }}>Get help with </a> mortgage
        </Col>
      </Row>
      {inView && (
        <Row className="mt-5">
          <Col>
            <Accordion defaultActiveKey={isMobile ? "-1" : "0"}>
              <Card className="toolsCard">
                <Card.Header className="toolsCardHeader">
                  <ContextAwareToggle eventKey="0" text="Check affordability" />
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="landingPageCardBody">
                    <Affordability
                      className="toolsAffordability"
                      isMobile={isMobile}
                      isFromLanding={true}
                      customerid={props.customerid} loid={props.loid}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="toolsCard">
                <Card.Header className="toolsCardHeader">
                  <ContextAwareToggle eventKey="1" text="Check rates" />
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="landingPageCardBody">
                    {/* <RateQuoteRealtorVersion
                      id={props.id}
                      isFromLanding={true}
                      isMobile={isMobile}
                      {...props}
                    /> */}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Tools;

export const LandingAffordability = (props) => {
  const isMobile = props.width <= 480;

  return (
    <Container className={`${props.isLandingPage ? 'landingPage-container' : ''} tools pt-0`}>
      <Row>
        <Col className="toolsTitle1">AFFORDABILITY CALCULATOR</Col>
      </Row>
      <Row>
        <Col>
          <div  className="toolsTitle2">Find the right home </div> 
          <div className="toolsTitle2" style={{ color: "#222", fontWeight: 700 }}>for your budget</div> 
        </Col>
      </Row>
      <Row style={{marginTop: '10px'}}>
        <Col>
          <div  className="toolsTitle3">Use our tool to plan your home purchase or refinance by inputting your income, liabilities,</div> 
          <div className="toolsTitle3">and down payment preference to see your rate options.</div> 
        </Col>
      </Row>
      <Row className={isMobile ? '' : 'mt-5'}>
        <Col className="landingPage-col">
          <Affordability
            className="toolsAffordability"
            isMobile={isMobile}
            isFromLanding={true}
            customerid={props.customerid} loid={props.loid}
            appLink={props.appLink}
            companyLink={props.companyLink}
          />
        </Col>
      </Row>
    </Container>
  );
};

export const LandingRateQuoter = (props) => {
  const isMobile = props.width <= 480;

  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Container className={`${props.isLandingPage ? 'landingPage-container' : ''} tools pt-0`} ref={ref}>
      <Row>
        <Col className="toolsTitle1">CURRENT RATES</Col>
      </Row>
      <Row>
        <Col>
          <div  className="toolsTitle2">Find the rate that's</div> 
          <div className="toolsTitle2" style={{ color: "#222", fontWeight: 700 }} >right for you</div> 
        </Col>
      </Row>
      <Row style={{marginTop: '10px'}}>
        <Col>
          <div  className="toolsTitle3">Use our tool to plan your home purchase or refinance by inputting your income,</div> 
          <div className="toolsTitle3">liabilities, and down payment preference to see your rate options.</div> 
        </Col>
      </Row>
      {inView && <Row className={isMobile ? '' : 'mt-5'}>
        <Col className="landingPage-col">
          <RateQuoteBorrowerVersion
            className="toolsAffordability"
            isLandingPage={props.isLandingPage}
            isMobile={isMobile}
            isFromLanding={true}
            appLink={props.appLink}
            companyLink={props.companyLink}
            {...props}
          />
        </Col>
      </Row>}
    </Container>
  );
};
