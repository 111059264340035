import * as ntf from '../Defs/notificationsdefs'

export function AddAllNotificationsGenaral(o) {
    return { type: ntf.ADD_ALL_NOTIFICATIONS_GENERAL, payload: o};
}

export function RemoveGeneralNotification(notification_id) {
    return { type: ntf.REMOVE_GENERAL_NOTIFICATION, payload: notification_id};
}

export function AddAllNotificationsSpecific(o) {
    return { type: ntf.ADD_ALL_NOTIFICATIONS_SPECIFIC, payload: o};
}

export function RemoveSpecificNotification(notification_id) {
    return { type: ntf.REMOVE_SPECIFIC_NOTIFICATION, payload: notification_id};
}

