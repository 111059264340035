import React, { Component, useRef, useEffect, useState } from "react";
import "../App.css";
import * as com from "../Common";
import { Slider, Handles, Tracks, Rail, Ticks } from "react-compound-slider";


function Tick({ tick, count, maxval }) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 16,
          marginLeft: -0.5,
          width: 1,
          height: 8,
          backgroundColor: "silver",
          left: `${tick.percent}%`,
        }}
      />
      <div
        className={maxval > 1000000 ? "font_rotate" : ""}
        style={{
          position: "absolute",
          marginTop: 22,
          fontSize: 10,
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {com.commaize(tick.value)}
      </div>
    </div>
  );
}

const RADIUS = 10;
export function Handle({ handle: { id, value, percent }, getHandleProps }) {
  const myInput = useRef();
  useEffect(() => {
    // Update the document title using the browser API

    let knobCtx = myInput.current.getContext("2d");
    let color2 = "rgba(0,48,116,1)";
    let color1 = "#E9F2FF";
    let r = RADIUS;
    knobCtx.save();
    knobCtx.setTransform();
    knobCtx.fillStyle = color2;
    knobCtx.beginPath();
    knobCtx.arc(r, r, r, 0, Math.PI * 2);
    knobCtx.fill();
    knobCtx.fillStyle = color1;
    knobCtx.beginPath();
    knobCtx.arc(r, r, r - 1, 0, Math.PI * 2);
    knobCtx.fill();
    knobCtx.fillStyle = color2;
    knobCtx.beginPath();
    knobCtx.arc(r, r, r / 4 + 1, 0, Math.PI * 2);
    knobCtx.fill();
    knobCtx.globalCompositeOperation = "destination-out";
    knobCtx.beginPath();
    knobCtx.arc(r, r, r / 4, 0, Math.PI * 2);
    knobCtx.fill();
    knobCtx.restore();
  }, []);

  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: -10,
        marginTop: 0,
        zIndex: 1001,
        textAlign: "center",
        cursor: "pointer",
      }}
      {...getHandleProps(id)}
    >
      <canvas
        style={{ position: "relative" }}
        ref={myInput}
        width={RADIUS * 2}
        height={RADIUS * 2}
      ></canvas>
      {/*
   <div style={{ fontFamily: 'Inter', fontWeight: 'bold',fontSize: 11, marginTop: (id ==='$$-0') ? -45 : 10 }}>
      {value}
    </div>
          */}
    </div>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 5,
        backgroundColor: "rgba(0,48,116,1)",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
}
const sliderStyle = {
  // Give the slider some width
  position: "relative",
  height: 40,
  touchAction: "none",
};

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 10,
  marginTop: 5,
  borderRadius: 5,
};

export default class HorizontalSlider extends Component {
  constructor(props) {
    super(props);
    this.id = Math.floor(Math.random() * 10000000);
  }

  componentDidMount() {}
  isDisabled = () => {
    let fs = window.document.getElementsByTagName("fieldset");
    return fs.length > 0 && fs[0].disabled;
  };
  onUpdate = (e) => {
    if (this.isDisabled()) return;

    this.props.onUpdate(e);
  };
  onChange = (e) => {
    let fs = window.document.getElementsByTagName("fieldset");
    if (fs.length > 0 && fs[0].disabled) return;

    this.props.onChange(e);
  };
  render() {
    return (
      <div id={this.props.id} style={{ width: this.props.width }}>
        <Slider
          rootStyle={sliderStyle}
          domain={this.props.domain}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          step={parseInt(this.props.step)}
          mode={1}
          disabled={this.isDisabled()}
          values={this.props.values}
        >
          <div style={railStyle} className="pale-background" />

          <Rail>
            {({ getRailProps }) => (
              <div
                style={railStyle}
                className="pale-background"
                {...getRailProps()}
              />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles pale-background">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false} left={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          {this.props.domain[1] !== 0 ? (
            <Ticks count={4}>
              {({ ticks }) => (
                <div className="slider-ticks" style={{ fontSize: "0.8em" }}>
                  {ticks.map((tick) => (
                    <Tick
                      key={tick.id}
                      tick={tick}
                      count={ticks.length}
                      maxval={this.props.domain[1]}
                    />
                  ))}
                </div>
              )}
            </Ticks>
          ) : (
            ""
          )}
        </Slider>
      </div>
    );
  }
}

function AffordabilityTick({ tick, count, maxval }) {
  return (
    <div>
      <div
        className={maxval > 1000000 ? "font_rotate" : ""}
        style={{
          position: "absolute",
          marginTop: 18,
          fontSize: '0.75rem',
          color: '#6E6E72',
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {com.commaize(tick.value)}
      </div>
    </div>
  );
}

export function AffordabilityHandle({ handle: { id, value, percent }, getHandleProps, domain }) {
  const myInput = useRef();
  const [num, setNum] = useState()
  let splitVal1 = Math.ceil(0.74 * domain[1])
  let splitVal2 = Math.ceil(0.88 * domain[1])
  useEffect(() => {
    // Update the document title using the browser API
    if (myInput.current) {
      let knobCtx = myInput.current.getContext("2d");
      let color2 = "rgba(0,48,116,1)";
      let color1 = "#E9F2FF";
      let r = RADIUS;
      if (knobCtx) {
        knobCtx.save();
        knobCtx.setTransform();
        knobCtx.fillStyle = color2;
        knobCtx.beginPath();
        knobCtx.arc(r, r, r, 0, Math.PI * 2);
        knobCtx.fill();
        knobCtx.fillStyle = color1;
        knobCtx.beginPath();
        knobCtx.arc(r, r, r - 1, 0, Math.PI * 2);
        knobCtx.fill();
        knobCtx.fillStyle = color2;
        knobCtx.beginPath();
        knobCtx.arc(r, r, r / 4 + 1, 0, Math.PI * 2);
        knobCtx.fill();
        knobCtx.globalCompositeOperation = "destination-out";
        knobCtx.beginPath();
        knobCtx.arc(r, r, r / 4, 0, Math.PI * 2);
        knobCtx.fill();
        knobCtx.restore();
      }
    }

    if (value <= splitVal1) {
      setNum('Affordable')
    } else if ((value <= splitVal2) && (value > splitVal1)) {
      setNum('Moderate')
    } else {
      setNum('Aggressive')
    }
  }, [value]);

  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: -12,
        marginTop: -3,
        zIndex: 1001,
        textAlign: "center",
        cursor: "pointer",
      }}
      {...getHandleProps(id)}
    >
      <div style={{marginLeft: (percent == 100) ? '-35px' : (percent >=93) ? '-18px' : '0px', color: value <= splitVal1 ? '#00BE63' : ((value <= splitVal2) && (value > splitVal1)) ? ' #FFAA29' : '#FF2929', fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, marginTop: (id === '$$-0') ? -18 : 10 }}>
        {num}
      </div>
      <div style={{ position: "relative", width: '1.5rem', height: '1.5rem', borderRadius: '50%', border: '2px solid #325CEB', background: '#FFF'}}></div>
      {/* <canvas
        style={{ position: "relative" }}
        ref={myInput}
        width={RADIUS * 2}
        height={RADIUS * 2}
      ></canvas> */}
    </div>
  );
}

function AffordabilityTrack({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 5,
        backgroundColor: "rgba(0,48,116,1)",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
      ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
}
const AffordabilitySliderStyle = {
  // Give the slider some width
  position: "relative",
  height: 40,
  touchAction: "none",
};

const AffordabilityRailStyle = {
  position: "absolute",
  width: "100%",
  height: 8,
  marginTop: 5,
  borderRadius: 5,
};

export class AffordabilityHorizontalSlider extends Component {
  constructor(props) {
    super(props);
    this.id = Math.floor(Math.random() * 10000000);
  }

  componentDidMount() { }
  isDisabled = () => {
    let fs = window.document.getElementsByTagName("fieldset");
    return fs.length > 0 && fs[0].disabled;
  };
  onUpdate = (e) => {
    if (this.isDisabled()) return;

    this.props.onUpdate(e);
  };
  onChange = (e) => {
    let fs = window.document.getElementsByTagName("fieldset");
    if (fs.length > 0 && fs[0].disabled) return;

    this.props.onChange(e);
  };
  render() {
    return (
      <div id={this.props.id} style={{ width: this.props.width }}>
        <Slider
          rootStyle={AffordabilitySliderStyle}
          domain={this.props.domain}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          step={parseInt(this.props.step)}
          mode={1}
          disabled={this.isDisabled()}
          values={this.props.values}
        >
          <div style={AffordabilityRailStyle} className="affordability-pale-background" />

          <Rail>
            {({ getRailProps }) => (
              <div
                style={AffordabilityRailStyle}
                className="affordability-pale-background"
                {...getRailProps()}
              />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles affordability-pale-background">
                {handles.map((handle) => (
                  <AffordabilityHandle
                    domain={this.props.domain}
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false} left={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <AffordabilityTrack
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          {this.props.domain[1] !== 0 ? (
            <Ticks values={[20, Math.ceil(this.props.domain[1] * 0.74), Math.ceil(this.props.domain[1] * 0.88), this.props.domain[1]]}>
              {({ ticks }) => (
                <div className="slider-ticks" style={{ fontSize: "0.8em" }}>
                  {ticks.map((tick) => (
                    <AffordabilityTick
                      key={tick.id}
                      tick={tick}
                      count={ticks.length}
                      maxval={this.props.domain[1]}
                    />
                  ))}
                </div>
              )}
            </Ticks>
          ) : (
            ""
          )}
        </Slider>
      </div>
    );
  }
}

export function HandleSimple({
  handle: { id, value, percent },
  getHandleProps,
}) {
  const myInput = useRef();
  let r = 8;
  useEffect(() => {
    let knobCtx = myInput.current.getContext("2d");
    let color2 = "rgb(64, 100, 215)";
    let color1 = "rgb(64, 100, 215)";
    let r = 8;
    knobCtx.save();
    knobCtx.setTransform();
    knobCtx.fillStyle = color2;
    knobCtx.beginPath();
    knobCtx.arc(r, r, r, 0, Math.PI * 2);
    knobCtx.fill();
    knobCtx.fillStyle = color1;
    knobCtx.beginPath();
    knobCtx.arc(r, r, r - 1, 0, Math.PI * 2);
    knobCtx.fill();
    knobCtx.fillStyle = color2;
    knobCtx.beginPath();
    knobCtx.arc(r, r, r / 4 + 1, 0, Math.PI * 2);
    knobCtx.fill();
    knobCtx.globalCompositeOperation = "destination-out";
    knobCtx.beginPath();
    knobCtx.arc(r, r, r / 4, 0, Math.PI * 2);
    knobCtx.fill();
    knobCtx.restore();
  }, []);

  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: -1,
        marginTop: 0,
        zIndex: 1001,
        textAlign: "center",
        cursor: "pointer",
      }}
      {...getHandleProps(id)}
    >
      <canvas
        style={{ position: "relative" }}
        ref={myInput}
        width={r * 2}
        height={r * 2}
      ></canvas>
      {/*
     <div style={{ fontFamily: 'Inter', fontWeight: 'bold',fontSize: 11, marginTop: (id ==='$$-0') ? -45 : 10 }}>
        {value}
      </div>
            */}
    </div>
  );
}
function TrackSimple({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 5,
        zIndex: 1,
        marginTop: 5,
        backgroundColor: "rgba(0,48,116,1)",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
      ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
}
const sliderSimpleStyle = {
  position: "relative",
  height: 10,
  touchAction: "none",
};

const railSimpleStyle = {
  position: "absolute",
  width: "100%",
  height: 5,
  marginTop: 7,
  borderRadius: 5,
  backgroundColor: "black",
};
export class HorizontalSimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.id = Math.floor(Math.random() * 10000000);
  }

  componentDidMount() { }
  isDisabled = () => {
    let fs = window.document.getElementsByTagName("fieldset");
    return fs.length > 0 && fs[0].disabled;
  };
  onUpdate = (e) => {
    if (this.isDisabled()) return;

    this.props.onUpdate(e);
  };
  onChange = (e) => {
    let fs = window.document.getElementsByTagName("fieldset");
    if (fs.length > 0 && fs[0].disabled) return;

    this.props.onChange(e);
  };
  render() {
    return (
      <div id={this.props.id} style={{ width: this.props.width }}>
        <Slider
          rootStyle={sliderSimpleStyle}
          domain={this.props.domain}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          step={parseInt(this.props.step)}
          mode={1}
          disabled={this.isDisabled()}
          values={this.props.values}
        >
          <div style={railSimpleStyle} className="pale-background" />

          <Rail>
            {({ getRailProps }) => (
              <div
                style={railSimpleStyle}
                className="pale-background"
                {...getRailProps()}
              />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles pale-background">
                {handles.map((handle) => (
                  <HandleSimple
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false} left={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <TrackSimple
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    );
  }
}
