import * as com from "../../Common.js";
import { calculateDownPayAndLoanSize } from "./utils_loans";

const createCommonInputBody = (prequalData: {
  salesPrice: string | number;
  ltv: number;
  loanID: string;
}): {
  price: string;
  loan: string;
  loanID: string;
} => {
  const { salesPrice, ltv } = prequalData;
  const { loanSize } = calculateDownPayAndLoanSize(salesPrice, ltv);
  return {
    price: salesPrice.toString(),
    loan: loanSize.toString(),
    loanID: prequalData.loanID,
  };
};

/**
 * Generates a pre-qualification PDF blob and its corresponding download content based on the provided pre-qualification data.
 *
 * @param {Object} prequalData - An object containing pre-qualification data, including sales price, loan-to-value ratio, loan type, FHA status, borrower ID, APR, rates, term type, and PDF template.
 * @return {Promise<Object>} A promise resolving to an object containing the generated PDF blob and its corresponding download content.
 */
export const getPrequalPDFBlobAndContent = async (prequalData: {
  salesPrice: string | number;
  ltv: number;
  borrowerID: string;
  loanID: string;
}): Promise<{ blob: Blob; download: any }> => {
  let token = com.getUserToken();
  let disposition;

  const inputBodyForPDFGeneration = createCommonInputBody(prequalData);
  const response = await fetch("/borrower/getprequal_v2", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      Cache: "no-cache",
      "X-Borrower": prequalData.borrowerID,
      FromLO: "true",
    },
    body: JSON.stringify(inputBodyForPDFGeneration),
  });
  disposition = response.headers.get("Content-Disposition").split("=");

  const body = response.body;
  const download = disposition.slice(1).join().replace(/"/g, "");
  const reader = body.getReader();
  const stream = new ReadableStream({
    start(controller) {
      return pump();

      function pump() {
        return reader.read().then(({ done, value: value_1 }) => {
          // When no more data needs to be consumed, close the stream
          if (done) {
            controller.close();
            return;
          }

          // Enqueue the next data chunk into our target stream
          controller.enqueue(value_1);
          return pump();
        });
      }
    },
  });
  const streamResponse = new Response(stream);
  const blob = await streamResponse.blob();
  return { blob, download };
};

/**
 * Generates a pre-qualification PDF blob for preview in iframe.
 */
export const getPrequalPDFBlobURL = async (prequalData: {
  salesPrice: string | number;
  ltv: number;
  borrowerID: string;
  loanID: string;
}): Promise<string> => {
  let token = com.getUserToken();

  const inputBodyForPDFGeneration = createCommonInputBody(prequalData);
  const response = await fetch("/borrower/getprequal_v2", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      Cache: "no-cache",
      "X-Borrower": prequalData.borrowerID,
      FromLO: "true",
    },
    body: JSON.stringify(inputBodyForPDFGeneration),
  });

  const blob = await response.blob();
  const blobURL = URL.createObjectURL(blob);
  return blobURL;
};

/**
 * Send the prequalification letter to the borrower, and save the pdf file
 * to db.
 */
export const sendPrequalPDFToBorrowerAndSaveToDB = async (prequalData: {
  salesPrice: string | number;
  ltv: number;
  borrowerID: string;
  email: string;
  loanID: string;
  borrowerName: string;
}): Promise<void> => {
  let token = com.getUserToken();

  const inputBodyForSendingPDF = {
    ...createCommonInputBody(prequalData),
    email: prequalData.email,
    name: prequalData.borrowerName,
  };
  const response = await fetch("/borrower/save_prequal", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      Cache: "no-cache",
      "X-Borrower": prequalData.borrowerID,
      FromLO: "true",
    },
    body: JSON.stringify(inputBodyForSendingPDF),
  });
};
