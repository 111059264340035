import React,{ useState, useEffect } from 'react';
import { 
    Dropdown,
    Space,
    Spin
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "./Common.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import { updateStatus } from "../../Store/status"
import "./ViewMode.css";

const mapStateToProps = (state) => {
    return {
        property: state.application.property,
        application: state.application,
        progress: state.progress,
        status: state.updateStatus.status,
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    updateStatus: (status) => {
        dispatch(updateStatus(status))
    },
    updateHardMoneyLoanAttribute: (t, verb) => {
      dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
      dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
      dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
    changeDeclaration: (val, verb) => {
        dispatch(act.ChangeDeclaration(val, "borrower", verb))
    },
  });


export default function ViewMode(props) {
    const menuItem = [
        {
          label: (
            <a
              rel="noopener noreferrer"
              onClick={() => {props.changeMode(true)}}
            >
              editing
            </a>
          ),
          key: "0",
        },
        {
          label: (
            <a
              rel="noopener noreferrer"
              onClick={() => {props.changeMode(false)}}
            >
              viewing
            </a>
          ),
          key: "1",
        },
    ]

    const showSavingStatus = () => {
      switch (props.status) {
          case "saving":
              return <div className="mr-3"><Spin active className="mr-1" />Saving</div>
              break
          case "saved":
              return <div className="mr-3">Change saved</div>
              break
          default:
              return ""
      }
  }

    useEffect(() => {
      props.updateStatus("initial")
    }, [])

    return (
        <div className="interview-mode">
            <div className='interview-mode-title'>Hard Money Loan Application</div>
            <div className='d-flex'>
              {showSavingStatus()}
              <div>
                  You are{" "}
                  <Dropdown
                      menu={{
                          items : menuItem,
                      }}
                  >
                      <a onClick={(e) => e.preventDefault()}>
                          <Space style={{ color: "#325CEB" }}>
                              {props.editMode ? "editing" : "viewing"}
                              <DownOutlined style={{ color: "#325CEB" }} />
                          </Space>
                      </a>
                  </Dropdown>
              </div>
            </div>
        </div>
    )
}

ViewMode = connect(mapStateToProps, mapDispatchToProps)(ViewMode);