import React, { useState, useEffect, useRef } from "react";
import "./SubscribersList.css";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const emptyStatistics = {
  purchase: 0,
  refinance: 0,
  primary: 0,
  secondhome:0,
  investment:0,
  lm1: 0,
  lm2: 0,
  lm3: 0,
  lm4: 0,
  lm5: 0,
}

const { SearchBar, ClearSearchButton } = Search;

const SubscribersList = (props) => {
  const [subscribers, setSubscribers] = useState([]);
  const [settings, setSettings] = useState({});
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [toRemoveEmail, setToRemoveEmail] = useState("");
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [toEditEmail, setToEditEmail] = useState("");
  const [counts, setCounts] = useState(emptyStatistics);
  const handleCloseEditModal = () => setShowEditModal(false);

  const actionsFormatter = (cell, row) => (
    <div>
      {/* onClick={() => onEdit(row.email)} */}
      <Button variant="remove">Details</Button>{" "}
      <Button variant="remove" onClick={() => onRemove(row.email)}>
        Remove
      </Button>
    </div>
  );

  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "solid 2px",
    fontSize: "120%",
  };
  let columns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "fname",
      text: "First Name",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "lname",
      text: "Last Name",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "frequency",
      text: "Email Frequency",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "remove",
      text: "",
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: headerStyle,
    },
  ];

  const calcStatistics = (subscribers) => {
    let counts = {...emptyStatistics}
    for (let sub of subscribers) {
      if (sub.settings.Purpose === "Purchase") {
        counts.purchase += 1;
      } else {
        counts.refinance += 1;
      }
      if (sub.settings.Occupancy === "PRIMARY") {
        counts.primary += 1;
      } else if (sub.settings.Occupancy === "SECOND_HOME"){
        counts.secondhome += 1;
      } else if (sub.settings.Occupancy === "INVESTMENT"){
        counts.investment += 1;
      }
      if (sub.settings.LoanAmount <= 400000) {
        counts.lm1 += 1;
      } else if (400000 < sub.settings.LoanAmount && sub.settings.LoanAmount <= 800000) {
        counts.lm2 += 1;
      } else if (800000 < sub.settings.LoanAmount && sub.settings.LoanAmount <= 1200000) {
        counts.lm3 += 1;
      } else if (1200000 <sub.settings.LoanAmount &&  sub.settings.LoanAmount <= 2000000) {
        counts.lm4 += 1;
      } else {
        counts.lm5 += 1;
      }
    }
    return {
      purchase: Math.round((100 * counts.purchase) / subscribers.length),
      refinance: Math.round((100 * counts.refinance) / subscribers.length),
      primary: Math.round((100 * counts.primary) / subscribers.length),
      secondhome: Math.round((100 * counts.secondhome) / subscribers.length),
      investment: Math.round((100 * counts.investment) / subscribers.length),
      lm1: counts.lm1,
      lm2: counts.lm2,
      lm3: counts.lm3,
      lm4: counts.lm4,
      lm5: counts.lm5,
    }
  }

  const getsubscribers = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getsubscribers", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return;
      }
      response.json().then((r) => {
        let data = [];
        let set = {};
        if (r.Subscribers) {
          for (let sub of r.Subscribers) {
            console.log(sub);
            data.push({
              email: sub.email,
              fname: sub.firstname,
              lname: sub.lastname,
              frequency: sub.settings.Frequency,
            });
            set[sub.email] = sub.settings;
          }
        }

        setSubscribers(data);
        setSettings(set);
        props.setTotalSubscribers(r.Subscribers.length);
        if (data.length > 0) {
          props.setStatistics(calcStatistics(r.Subscribers));
        }
      });
    });
  };

  useEffect(() => {
    getsubscribers();
    
  }, []);


  const showSetting = (email) => {
    console.log(settings[email]);
    return (
      <ListGroup variant="subdetail">
        <ListGroup.Item>Purpose: {settings[email].Purpose}</ListGroup.Item>
        <ListGroup.Item>
          Loan Amount: {settings[email].LoanAmount} / {settings[email].LTV}% LTV
        </ListGroup.Item>
        <ListGroup.Item>Fico: {settings[email].Fico}</ListGroup.Item>
        <ListGroup.Item>Occupancy: {settings[email].Occupancy}</ListGroup.Item>
        <ListGroup.Item>
          Monthly Income: {settings[email].MonthlyIncome}
        </ListGroup.Item>
        <ListGroup.Item>
          Monthly Debt: {settings[email].MonthlyDebt}
        </ListGroup.Item>
        {/* <ListGroup.Item>
          <Link to={{pathname: "/app/purchasecalculator", state:settings[email]}}>See Prices</Link>
        </ListGroup.Item> */}
      </ListGroup>
    );
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => showSetting(row.email),
  };

  const onRemove = (email) => {
    setToRemoveEmail(email);
    setShowRemoveModal(true);
  };
  const onEdit = (email) => {
    setToEditEmail(email);
    setShowEditModal(true);
  };
  const removeSubscriber = () => {
    console.log("removing", toRemoveEmail);

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/removesubscriber/" + toRemoveEmail, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return;
      }
      getsubscribers();
    });
    setShowRemoveModal(false);
  };
  const editSubscriber = () => {
    setShowEditModal(false);
  };
  const getRemoveModal = () => {
    return (
      <Modal show={showRemoveModal} onHide={handleCloseRemoveModal}>
        <Modal.Header closeButton>
          <Modal.Title>Removing {toRemoveEmail}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove {toRemoveEmail}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseRemoveModal}>
            Close
          </Button>
          <Button onClick={removeSubscriber}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleEdit = (f, v) => {
    console.log("editing", settings[toEditEmail]);
    let newS = settings;
    newS[toEditEmail][f] = v;
    setSettings(newS);
    console.log("edited", settings[toEditEmail][f]);
  };


  return (
    <div>
      <ToolkitProvider
        bootstrap4
        keyField="email"
        data={subscribers}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <SearchBar {...props.searchProps} />
            <ClearSearchButton {...props.searchProps} />

            <BootstrapTable
              id="subscribers"
              bootstrap4
              bordered={false}
              pagination={paginationFactory({ hidePageListOnlyOnePage:true })}
              {...props.baseProps}
              filter={filterFactory()}
              expandRow={expandRow}
            />
          </div>
        )}
      </ToolkitProvider>
      {showRemoveModal ? getRemoveModal() : ""}
      {/* {showEditModal ? getEditModal() : ""} */}
    </div>
  );
};
export default SubscribersList;
