const errorCodeToTextMap = {
  // 0 is also the default case
  "0": "Unfortunately, the borrower is not prequalified for a loan at this time.",
  "1": "Unfortunately, it looks like the borrower's FICO score is too low to qualify for a loan.",
  "2": "Unfortunately, the borrower's debt to income ratio is too high to qualify for a loan.",
  "3": "Unfortunately, the borrower's loan amount to property value ratio is too high to qualify for a loan. You may want to consider taking a smaller loan.",
  "4": "Unfortunately, the borrower's income is too low to qualify for a loan.",
  "5": "Unfortunately, we could not find a loan product for the borrower's particular situation. You may want to consider taking a smaller loan.",
  "6": "Unfortunately, the property is not qualified for a loan.",
};

export const getErrorMessage = (errorCode: string, errorText: string) => {
  const msg =
    errorCodeToTextMap[errorCode] || errorText || errorCodeToTextMap["0"];
  return msg;
};
