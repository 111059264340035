import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import _ from 'lodash';
import { Link, withRouter } from "react-router-dom";
import * as com from "./Common.js"
import Tasks from './Tasks/Tasks'
import DocumentChecklist from './DocumentChecklist/DocumentChecklist'
import { connect } from 'react-redux'
import * as act from "./Store/actions"
import * as brwr from "./Store/borrower"
import * as prgr from "./Store/progress"
import { mergeState } from "./Store"
import DocumentManager from './Documents/DocumentManager.js'
import FormPreview from './FormPreview'
import CreditReport from './newCreditReport'
import LoanSummary from "./LoanSummary/LoanSummary";
import UnderwritingChecklist from "./UnderwritingChecklist/UnderwritingChecklist"
import Comment from "./Comment/Comment"
import DocumentsVerification from './DocumentVerification/DocumentsVerification'
import { application, State, UR_LoanProcessor, UR_Owner } from './State.js'
import { UpdateChangeLastTouch } from "./Store/progress"
import { UpdateApplicationAttribute } from "./Store/actions";
import Interview1003View from "./Interview1003View/Interview1003View";
import PrequalV2 from "./Interview/Prequal/PrequalV2.tsx";
import Prequal from "./Interview/Prequal";
import ProductPicker from "./Interview/ProductPicker";
import "./Borrower.css"
import { HomeFilled, RightOutlined, DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Radio, Dropdown, Space, Menu, Divider, Spin, Select, Skeleton } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import HardMoneyLoanOverview, { getLoanAmount as getHardMoneyLoanLoanAmount, getLTV as getHardMoneyLoanLTV } from "./HardMoneyLoan/HardMoneyLoan/Overview";
import HardMoneyLoanBorrower from "./HardMoneyLoan/HardMoneyLoan/Borrower";
import HardMoneyLoanPropertyAndLoan from "./HardMoneyLoan/HardMoneyLoan/PropertyAndLoan";
import HardMoneyLoanExperience from "./HardMoneyLoan/HardMoneyLoan/Experience";
import HardMoneyLoanDeclarations from "./HardMoneyLoan/HardMoneyLoan/Declarations";
import HardMoneyLoanViewMode from "./HardMoneyLoan/HardMoneyLoan/ViewMode"
import { checkAccess } from "./Auth";
import InterviewBorrowerView from "./InterviewBorrowerView";
import PlanModal from "./planModal";
const merge = require('deepmerge')
const deepcopy = require('deepcopy');
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        status: state.application.status,
        progress: state.progress,
        borrower: state.borrower,
        subscriptionReducer: state.subscriptionReducer.currentInfo
    }
}
const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    changeBorrowerId: (id) => {
        dispatch(brwr.ChangeBorrowerId(id))
    },
    changeBorrowerLoanId: (id) => {
        dispatch(brwr.ChangeBorrowerLoanId(id))
    },

    changeBorrowerName: (name) => {
        dispatch(brwr.ChangeBorrowerName(name))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateApplicationAttribute: (t, verb) => {
        dispatch(UpdateApplicationAttribute(t, verb))
    },
});
const StatusSelect = Object.entries(com.loanStatusMap).map(([key, value]) => ({ label: value, value: key }));
class Borrower extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            firstname: "",
            lastname: "",
            id: "",
            isLP: false,
            render: this.renderEmpty,
            remoteCoborrowers: [],
            selectedTabKey: "loanSummary",
            isPrequal: false,
            showRates: false,
            loadingPdf: false,
            url: "",
            download: "",
            declarationsWarning: false,
            editMode: true,
            monthlyIncome: 0,
            monthlyExpense: 0,
            loading: false,
        }
        if (this.props.progress.stage === "")
            this.props.updateChangeProgress(0, "interview")
        this.aref = React.createRef();
        this.handleSelectedTabKeyChange = this.handleSelectedTabKeyChange.bind(this);
        const queryParams = new URLSearchParams(this.props.location.search);
        this.handingOff = queryParams.get('handingoff');
        this.handingOffType = queryParams.get('type');
    }

    renderEmpty = () => (<div></div>)


    checkIfIsLP() {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        return fetch('/los/checkifislp', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-LoanID": this.props.match.params["id"],
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log("Error post /los/checkifislp:", response.status)
                    this.setState({ loading: false })
                    alert("Something went wrong, please try again later.")
                }
                return response.json();
            }
        ).then(pr => {
            if (pr.Error !== "")     {
                console.log(pr.Error)
                alert("Something went wrong, please try again later.")
            } else {
                this.setState({ isLP: pr.IsLP }, () => {
                    this.reload();
                    this.getRemoteCoborrowers();
                    this.calcDTI();
                })
            }
            this.setState({ loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
            console.error("CheckIfIsLP error:", err);
            alert("Something went wrong, please try again later.")
        });
    }

    componentDidMount = () => {
        const selectedKeys = sessionStorage.getItem('borrowermenu')
        if (selectedKeys) {
            this.setState({
                selectedTabKey: selectedKeys,
            })
        }
        if (this.handingOff !== null) {
            this.setState({ selectedTabKey: "1003View" })
        }

        // Wait for checkIfIsLP to complete before proceeding
        this.checkIfIsLP().then(() => {
            const id = this.props.match.params["id"];
            // id is loan id      
            this.setState({
                id: id,
            declarationsWarning: Object.values(this.props.application.borrower.declarations).includes('yes')
        }, () => {
                this.checkIfIsLP();
            });
        });
    }


    componentDidUpdate(prevProps) {
        // listen to the change of income and call preDTI calculation
        if (!_.isEqual(this.props.application.borrower.occupation.income, prevProps.application.borrower.occupation.income)) {
            console.log('Income has changed:', this.props.application.borrower.occupation.income.base, prevProps.application.borrower.occupation.income.base);
            setTimeout(() => {
                this.calcDTI()
            }, 2000);
        }
    }

    calcDTI() {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/data/getPreDTI', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
                "X-Borrower": this.props.borrower.id,
                "X-Loan": this.state.id,
            },

        }).then(
            response => {
                if (response.status !== 200) {
                    console.log("Error post /data/getPreDTI:", response.status)
                    return;
                }
                response.json().then(pr => {
                    if (typeof pr["Error"] !== "undefined") {
                        console.log(pr.Error)
                    } else {
                        if (pr.dtiData.length > 0) {
                            this.setState({ monthlyIncome: pr.dtiData[0].monthly_income, monthlyExpense: pr.dtiData[0].monthly_payment })
                        }
                    }
                })
            }

        ).catch((err) => {

            this.setState({ loading: false })
            alert("Network error")
        });
    }



    handleSelectedTabKeyChange(val) {
        this.setState({ selectedTabKey: val });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            const newid = this.props.match.params.id;
            this.forceStateSynchronization();
            this.setState({ id: newid, remoteCoborrowers: [] }, () => {
                this.checkIfIsLP();
            });
        }
    }

    handleSubtabTabSelect = (customerID) => {
        this.forceStateSynchronization().then(() => {
            this.reload(customerID)
        })
    }

    reload = (customer_id) => {
        if (typeof customer_id === 'undefined') {
            customer_id = ""
            this.setState({ render: this.renderEmpty })
        }
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.state.id, CustomerID: customer_id, isOwner: checkAccess([UR_Owner]) }
        let url = '/los/borrower'
        if (this.state.isLP) {
            url = '/los/lp/borrower'
            body = { LoanID: this.state.id, CustomerID: customer_id }
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        let state = JSON.parse(js.Borrower.State)

                        let serstate
                        if ("{}" === js.Borrower.State) {
                            state = JSON.parse((() => { let l = new State(); return l.toJson() })())
                        }
                        try {
                            let applicationInitialState = JSON.parse((() => { let l = new application(); return l.toJson() })())
                            let app = merge(applicationInitialState, state.application, { arrayMerge: overwriteMerge })
                            state.application = app
                        } catch (x) {
                            // just keep it
                        }
                        sessionStorage.removeItem("state")
                        sessionStorage.removeItem("originalstate")
                        serstate = JSON.stringify(state)
                        sessionStorage.setItem("state", serstate)
                        sessionStorage.setItem("originalstate", serstate)
                        this.props.updateChangeApplication({ ...state.application })
                        this.setState({
                            firstname: js.Borrower.FirstName,
                            lastname: js.Borrower.LastName,
                            isPrequal: state.application.property.purpose === "purchase" && state.application.property.hascontract === false,
                            isHardMoneyLoan: state.application.product.mortgageapplied === "hardmoneyloan",
                            showRates: state.application.scre !== 0 ? true : false,
                        })
                        this.props.changeBorrowerId(js.Borrower.ID)
                        this.props.changeBorrowerLoanId(js.Borrower.LoanID)
                        this.props.changeBorrowerName(js.Borrower.FirstName + " " + js.Borrower.LastName)
                        if (state.progress != null) {
                            this.props.updateChangeProgress(state.progress.interview.max, "max")
                            this.props.updateChangeProgress(state.progress.interview.step, "interview")
                            this.props.updateChangeLastTouch(state.progress.lasttouch)
                        } else {

                            this.props.updateChangeProgress(0, "max")
                            this.props.updateChangeProgress(0, "interview")
                        }
                        sessionStorage.removeItem("edit")

                        let index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.Borrower.ID)
                        if (index === -1) {
                            let { ID, FirstName, LastName, ContactPhone, Email } = js.Borrower
                            this.setState({ remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: true }], render: this.renderTabs })
                        }
                    }
                });
            }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
    }

    getRemoteCoborrowers = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.state.id }
        fetch('/los/remotecoborrowers', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        if (js.RemoteCoborrowers !== null) {
                            for (let i = 0; i < js.RemoteCoborrowers.length; i++) {
                                let index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.RemoteCoborrowers[i])
                                if (index === -1) {
                                    let { ID, LastName, FirstName, ContactPhone, Email } = js.RemoteCoborrowers[i]
                                    this.setState({ remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: false }] })
                                }
                            }
                            this.setState({ render: this.renderTabs })
                        }
                    }
                });
            }
        )
    }

    forceStateSynchronization = () => {
        let state = deepcopy({ borrower: this.props.borrower, application: this.props.application })
        let previousState = JSON.parse(sessionStorage.getItem("originalstate"))
        let token = window.sessionStorage.getItem("ZeitroA");
        let { id } = state.borrower
        if (id === "")
            return
        return new Promise((resolve, reject) => {
            fetch("/los/borrowerstate/" + id, {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                },
            }).then(response => {
                if (!response.ok) {
                    console.log("Auth fetch error")
                    sessionStorage.removeItem("ZeitroA")
                    window.location.href = "/"
                } else {
                    response.json().then(js => {
                        if (js.Status !== "OK") {
                            console.log("State Update Error: " + js.Status)
                            reject('rejected')
                        } else {
                            //console.log("State Update Success: " + js.Status)
                            //debugger
                            mergeState(id, js.Text, state, previousState)
                            resolve('resolved')
                        }
                    })
                }
            }).catch(error => {
                console.log("error")
            })
        })
    }

    onselect = (key, e) => {
        let mainBorrower = this.state.remoteCoborrowers.find(person => person.isMain)
        if (mainBorrower.ID !== this.props.borrower.id) {
            this.forceStateSynchronization().then(() => {
                this.reload(mainBorrower.ID)
            })
        }
        this.props.updateChangeProgressType(key)
    }
    renderTabs = () => {
        let ID = ""
        try {
            ID = this.state.remoteCoborrowers[0].ID
        } catch (x) {

        }

        const getItem = (label, key, icon, children, type, disabled) => {
            return {
                key,
                icon,
                children,
                label,
                type,
                disabled,
            };
        }


        let firstItems = [
            getItem('Loan summary', 'loanSummary'),
            getItem('1003', '1003View'),
            getItem('Interview recordings', 'borrowerView'),
            this.props.application.property.purpose === "purchase" && getItem('Pre-qual letter', 'prequalLetter', null, null, null, !this.state.showRates)
        ]



        firstItems.push(getItem('Rates', 'rates', null, null, null, !this.state.showRates))


        let items = [
            ...firstItems,
            getItem('Documents', 'documentChecklist'),
            // getItem('Documents', 'documents', null, [
            //     getItem('Uploaded by borrower', 'uploadedByBorrower'),
            //     getItem('Uploaded by you', 'uploadedByYou'),
            // ]),
            this.props.application.property.propertytype === "mobilehome" && getItem('Underwriting checklist', 'UWList'),
            getItem('Tasks', 'tasks'),
            getItem('Communications', 'comment'),
            getItem('Credit report', 'creditReport'),
            getItem('FNM 3.4', 'mismo'),
            // getItem('Doc Verification', 'docVerification'),
        ];
        const hasIcon = () => {
            if (this.state.declarationsWarning) {
                return <ExclamationCircleOutlined style={{ color: '#ff4d4f', marginLeft: '-24px !important' }} />;
            }
            return null
        }
        if (this.state.isHardMoneyLoan) {
            items = [

                getItem('Loan summary', 'loanSummary'),
                getItem('Loan interview', 'sub3', null, [
                    getItem('Borrower', 'hardMoneyLoanBorrower'),
                    getItem('Property and loan', 'hardMoneyLoanProperty'),
                    getItem('Experience', 'hardMoneyLoanExperience'),
                    getItem('Declarations', 'hardMoneyLoanDeclarations', hasIcon()),
                ]),
                getItem('Documents', 'documentChecklist'),
                // getItem('Documents', 'documents', null, [
                //     getItem('Uploaded by borrower', 'uploadedByBorrower'),
                //     getItem('Uploaded by you', 'uploadedByYou'),
                // ]),
                getItem('Tasks', 'tasks'),
                getItem('Underwriting checklist', 'UWList'),
                getItem('Communications', 'comment')
            ]
        }
        const changeTab = (key) => {
            this.setState({ selectedTabKey: key })
            sessionStorage.setItem("borrowermenu", key)
        }
        const renderTab = () => {
            if (this.state.isHardMoneyLoan) {
                const getStep = () => {
                    if (this.props.status === "interviewfinished") {
                        return 2
                    }
                    return 1
                }
                switch (this.state.selectedTabKey) {
                    case "loanSummary":
                        return <HardMoneyLoanOverview step={getStep()} checkInterview={() => this.handleSelectedTabKeyChange("hardMoneyLoanBorrower")} />;
                    case "hardMoneyLoanBorrower":
                        return <div><HardMoneyLoanViewMode editMode={this.state.editMode} changeMode={changeMode} /><div className="py-4 loan-interView-wrap"><HardMoneyLoanBorrower finish={borrowerFinish} editMode={this.state.editMode} /></div></div>;
                    case "hardMoneyLoanProperty":
                        return <div><HardMoneyLoanViewMode editMode={this.state.editMode} changeMode={changeMode} /><div className="py-4 loan-interView-wrap"><HardMoneyLoanPropertyAndLoan finish={propertyFinish} editMode={this.state.editMode} /></div></div>;
                    case "hardMoneyLoanExperience":
                        return <div><HardMoneyLoanViewMode editMode={this.state.editMode} changeMode={changeMode} /><div className="py-4 loan-interView-wrap"><HardMoneyLoanExperience finish={experienceFinish} editMode={this.state.editMode} /></div></div>;
                    case "hardMoneyLoanDeclarations":
                        return <div><HardMoneyLoanViewMode editMode={this.state.editMode} changeMode={changeMode} /><div className="py-4 loan-interView-wrap"><HardMoneyLoanDeclarations finish={declarationsFinish} editMode={this.state.editMode} onSelect={onChangeSelect} /></div></div>;
                    case "documentChecklist":
                        return <DocumentChecklist key={this.state.id} loanid={this.props.borrower.loan_id} />;
                    case "tasks":
                        return <Tasks key={this.state.id} loanid={this.props.borrower.loan_id} changeTab={changeTab} />;
                    case "UWList":
                        return <UnderwritingChecklist loanid={this.props.borrower.loan_id} handleReject={() => handleStatusChange(this.state.id, "loandenied")} handleApprove={() => handleStatusChange(this.state.id, "conditionallyapproved")} />;
                    case "comment":
                        return <Comment loanid={this.props.borrower.loan_id} />;
                    default:
                        return <HardMoneyLoanOverview step={getStep()} checkInterview={() => this.handleSelectedTabKeyChange("hardMoneyLoanBorrower")} />;
                }
            } else {
                switch (this.state.selectedTabKey) {
                    case "loanSummary":
                        return <LoanSummary key={ID} borrowerid={this.props.borrower.id} />;
                    case "1003View":
                        return <Interview1003View key={this.state.id} borrowerid={this.props.borrower.id} handingOff={this.handingOff} handingOffType={this.handingOffType}/>;
                    case "borrowerView":
                        return <InterviewBorrowerView />;
                    case "prequalLetter":
                        let ref = React.createRef();
                        return <div className="m-4"><PrequalV2 borrowerid={this.props.borrower.id} loanid={this.props.borrower.loan_id} ref={ref} /></div>;
                    case "rates":
                        let ratesRef = React.createRef();
                        return <div className="m-4"><ProductPicker borrowerid={this.props.borrower.id} loanid={this.props.borrower.loan_id} ref={ratesRef} /></div>;
                    case "documentChecklist":
                        return <DocumentChecklist key={this.state.id} loanid={this.props.borrower.loan_id} />;
                    case "tasks":
                        return <Tasks key={this.state.id} loanid={this.props.borrower.loan_id} changeTab={changeTab} />;
                    case "creditReport":
                        return <CreditReport />;
                    case "mismo":
                        return <FormPreview form='mismo34' />;
                    case "UWList":
                        return <UnderwritingChecklist loanid={this.props.borrower.loan_id} handleReject={() => handleStatusChange(this.state.id, "loandenied")} handleApprove={() => handleStatusChange(this.state.id, "conditionallyapproved")} />;
                    case "comment":
                        return <Comment loanid={this.props.borrower.loan_id} />;
                    default:
                        return <div>Please select a tab</div>;
                }
            }
        }
        const borrowerFinish = (val) => {
            let d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            this.handleSelectedTabKeyChange("hardMoneyLoanProperty")
        };
        const propertyFinish = (val) => {
            let d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            this.handleSelectedTabKeyChange("hardMoneyLoanExperience")
        };
        const experienceFinish = (val) => {
            let d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            this.handleSelectedTabKeyChange("hardMoneyLoanDeclarations")
        };
        const declarationsFinish = (val) => {
            let d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            // submit
            this.props.updateApplicationAttribute("interviewfinished", "status")

        };
        const onChangeSelect = (val) => {
            console.log(val)
            let declarationsWarning = val.some(item => item.select === 'yes')
            this.setState({ declarationsWarning: declarationsWarning })
        }
        const getLoanPurpose = () => {
            if (this.state.isHardMoneyLoan) {
                return "Hard money loan"
            }
            if (this.state.isPrequal) {
                return "Pre-qualification"
            }
            if (this.props.application.property.purpose === "purchase") {
                return "Purchase"
            } else if (this.props.application.property.purpose === "cashoutrefinance") {
                return "Cash-out refinance"
            } else {
                return "Refinance"
            }
        }

        const getLTV = () => {
            if (this.state.isHardMoneyLoan) {
                return getHardMoneyLoanLTV(this.props)
            }
            if (this.props.application.selection.loansize === "") {
                return "-"
            }
            return Math.floor(100 * com.safeParseInt(this.props.application.selection.loansize) / parseFloat(this.props.application.property.salesprice)) + "%"
        }

        const getDTI = () => {
            if (this.state.monthlyIncome !== 0 && this.state.monthlyExpense !== 0) {
                return Math.round(this.state.monthlyIncome / this.state.monthlyExpense) + "%"
            }
            return "-"
        }
        const handleStatusChange = (loanID, newStatus) => {
            let token = sessionStorage.getItem("ZeitroA")
            let body = { LoanID: loanID, Key: "status", Value: newStatus, IsAdmin: checkAccess([UR_Owner]) ? true : false }
            fetch('/los/updatepipeline', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            }).then(response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    // this.setState({ showErrorModal: true})
                    return;
                }
                console.log(newStatus)
                this.props.updateApplicationAttribute(newStatus, "status");
                //   handleStatus(loanID, newStatus)
            })
                .catch(function (err) {
                    console.log('Fetch Error :-S', err);
                })
        }
        const getLoanAmount = () => {
            if (this.state.isHardMoneyLoan) {
                return getHardMoneyLoanLoanAmount(this.props)
            }

            if (this.props.application.selection.loansize === "") {
                return "-"
            }
            return "$" + com.commaize(this.props.application.selection.loansize)
        }
        const changeMode = (val) => {
            this.setState({ editMode: val })
        }
        const dataItems = [
            {
                label: <div className="" onClick={() => {
                    gen1003Form()
                }} >
                    {this.state.loadingPdf ? <Spin indicator={<LoadingOutlined
                        style={{
                            fontSize: 20,
                        }}
                        spin
                    />} /> : "PDF 1003"}
                </div>,
                key: '1',
            },
            {
                label: <div onClick={() => {
                    this.setState({ selectedTabKey: "mismo" })
                    sessionStorage.setItem("borrowermenu", "mismo")
                }} >
                    FNM 3.4
                </div>,
                key: '2',

            }

        ]
        const genExtra = (type, suffix) =>{
            let idx = 1
            if (type === "extraRE"){
                idx = 3
            }
            let boCount = this.props.application.borrower[suffix]?.length || 0
            let coCount = this.props.application.coborrower[suffix]?.length || 0
            let maxCount = boCount > coCount ? boCount : coCount
            for(let i = idx; i < maxCount; i++) {
                this.getForm1003(type, i)
            }
        }

        const gen1003Form = () => {
            if (this.props.application.hascoborrower === "withcoborrower") {
                Promise.all([
                    this.getForm1003("borrower"),
                    this.getForm1003("coborrower"),
                    (this.props.application.borrower.ownedproperties.length > 3 || this.props.application.coborrower.ownedproperties.length > 3) && genExtra("extraRE", "ownedproperties"),
                    (this.props.application.borrower.otheroccupations?.length > 1 || this.props.application.coborrower.otheroccupations?.length > 1) && genExtra("extraAdditionalEmp", "otheroccupations"),
                    (this.props.application.borrower.previousoccupations?.length > 1 || this.props.application.coborrower.previousoccupations?.length > 1) && genExtra("extraPrevEmp", "previousoccupations"),
                ]).then(() => {
                    this.setState({ loadingPdf: false });
                }).catch((error) => {
                    alert("Something went wrong, please contact support.");
                    console.error(error);
                    this.setState({ loadingPdf: false });
                });
            } else {
                Promise.all([
                    this.getForm1003("borrower"),
                    this.props.application.borrower.ownedproperties.length > 3 && genExtra("extraRE", "ownedproperties"),
                    this.props.application.borrower.otheroccupations?.length > 1 && genExtra("extraAdditionalEmp", "otheroccupations"),
                    this.props.application.borrower.previousoccupations?.length > 1 && genExtra("extraPrevEmp", "previousoccupations"),
                ]).then(() => {
                    this.setState({ loadingPdf: false });
                }).catch((error) => {
                    alert("Something went wrong, please contact support.");
                    console.error(error);
                    this.setState({ loadingPdf: false });
                });
            }
        }
        const borrowerTopBar = () => {
            let st = { fontSize: 18, color: "#6E6E70", marginRight: 10 }
            return <div className="borrowerHeader">
                <div style={{ gridColumn: 1, display: "flex", alignItems: "center" }}>
                    <Link className="borrowerHeader-link" to={"/app/borrowers"}><div style={st}>Borrowers</div> </Link>
                    <RightOutlined className="borrowerHeader-link" style={st} />
                    <Link className="borrowerHeader-link" to={"/app/borrowers/" + this.props.borrower.id}><div className="" style={st}>{this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname}</div></Link>
                    <RightOutlined className="borrowerHeader-link" style={st} />
                    <div className="borrowerTopBarLoanInfo" >
                        <HomeFilled style={{ fontSize: 23, marginRight: 20 }} />
                        {this.state.isPrequal ? <div className="d-flex"><div className="text-left mr" >
                            <div className="subHeader">Loan purpose</div>
                            <div className="title-5-bold">
                                {getLoanPurpose()}
                            </div>
                        </div>
                            <div className="text-left mr" >
                                <div className="subHeader">Loan Amount</div>
                                <div className="title-5-bold">
                                    {getLoanAmount()}
                                </div>
                            </div>
                            <div className="text-left mr" >
                                <div className="subHeader">LTV</div>
                                <div className="title-5-bold">
                                    {getLTV()}
                                </div>
                            </div></div> : <div className="text-left">
                            <div className="bold ">{this.props.application.property.address}</div>
                            <div className="flex-dots-between" style={{ fontSize: 12 }}>
                                <div className="d-flex">
                                    <div>Loan Purpose</div>
                                    <div className="bold ml-2">{getLoanPurpose()}</div>
                                </div>
                                <div className="d-flex">
                                    <div>Loan Amount</div>
                                    <div className="bold ml-2">{getLoanAmount()}</div>
                                </div>
                                <div className="d-flex">
                                    <div>LTV</div>
                                    <div className="bold ml-2">{getLTV()}</div>
                                </div>
                                <div className="d-flex">
                                    <div>DTI</div>
                                    <div className="bold ml-2">{getDTI()}</div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <a hidden ref={this.aref} href={this.state.url} download={this.state.download}>&nbsp;</a>
                <div style={{ display: 'flex' }}>

                    <Select
                        onChange={(e) => {

                            handleStatusChange(this.state.id, e)


                        }}
                        className="borrowerHeaderStatus"
                        defaultValue={this.props.status}
                        value={this.props.status}
                        options={StatusSelect}
                    ></Select>
                    {!this.state.isHardMoneyLoan && <div style={{ gridColumn: 2, marginRight: 20, display: "flex" }}>
                        <Dropdown menu={{ items: dataItems }}>
                            <Button type='primary' style={{ height: 38 }}>

                                {this.state.loadingPdf ? <Spin indicator={<LoadingOutlined
                                    style={{ fontSize: 20, color: "white", marginLeft: 18, marginRight: 18 }}
                                    spin
                                />} /> : "Export"}
                                <DownOutlined />

                            </Button>
                        </Dropdown>

                        {/* <Button className="mr-3 pdfButton" onClick={()=> {
                    this.getForm1003()
                }} >
                    {this.state.loadingPdf ? <Spin indicator={<LoadingOutlined
                            style={{
                            fontSize: 20,
                            }}
                            spin
                        />} /> : "PDF 1003"}
                </Button>
                <Button type="primary"  onClick={()=> {
                    this.setState({selectedTabKey: "mismo"})
                    sessionStorage.setItem("borrowermenu", "mismo")
                }} >
                    FNM 3.4
                </Button> */}

                    </div>}
                </div>
            </div>
        }
        return (
            <div>
                <div className="" style={{ position: 'sticky', top: 0, backgroundColor: '#FFF', borderBottom: '1px solid #ECECEC', zIndex: 999 }}>
                    {borrowerTopBar()}
                </div>
                <div className="d-flex borrow-content-wrap">
                    <Menu
                        onClick={(e) => {  
                            this.setState({ selectedTabKey: e.key })
                            sessionStorage.setItem("borrowermenu", e.key)
                        }}
                        selectedKeys={[this.state.selectedTabKey]}
                        defaultOpenKeys={['sub1', 'sub2', "sub3", "documents", "loanInterview"]}
                        mode="inline"
                        items={items}
                        className="text-left borrow-menu"
                    />
                    <div style={{ width: '100%' }}>
                        <div className="interview-tabpane flex-grow-1">{renderTab()}</div>
                    </div>
                </div>
                <PlanModal isModalOpen={this.state.isModalOpen} closeModal={() => {

                    this.setState({ isModalOpen: false })

                }} ></PlanModal>
            </div>
        )
    }

    getForm1003 = (forwhom, idx) => {
        return new Promise((resolve, reject) => { // Wrap the fetch logic in a promise
            let disposition;
            let token = com.getUserToken();
            this.setState({ loadingPdf: true });
            fetch('/borrower/getform1003', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-Borrower": this.props.borrower.id,
                    "X-Loan": this.props.borrower.loan_id,
                    "X-Forwhom": forwhom,
                    "X-ExtraIdx": forwhom === "borrower" || forwhom === "coborrower" ? "" : idx
                },
            }).then(response => {
                disposition = response.headers.get("Content-Disposition").split('=');
                return response.body;
            })
                .then(body => {
                    const reader = body.getReader();
                    return new ReadableStream({
                        start(controller) {
                            function pump() {
                                return reader.read().then(({ done, value }) => {
                                    if (done) {
                                        controller.close();
                                        return;
                                    }
                                    controller.enqueue(value);
                                    return pump();
                                });
                            }
                            return pump();
                        }
                    });
                })
                .then(stream => new Response(stream))
                .then(r => r.blob())
                .then(blob => {
                    let download = disposition.slice(1).join().replace(/"/g, '');
                    const url = window.URL.createObjectURL(blob);
                    this.setState({ url: url, download: download });
                    this.aref.current.click();
                    resolve(); // Resolve the promise here
                })
                .catch((err) => {
                    console.log("Error in getForm1003:", err);
                    reject(err); // Reject the promise here
                });
        });
    };

    render() {
        if (this.state.loading) {
            return <div className="p-5">
                <Skeleton active />
            </div>
        }

        return (
            <div>
                {this.state.render()}
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Borrower))
