import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import './DocumentManager.css'
import Spinner from 'react-bootstrap/Spinner'
import { Modal, Table, Button, Input, Tooltip, Dropdown, Spin, DatePicker, Form, message, Switch } from 'antd';
import { CloseOutlined, SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { connect } from 'react-redux'
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import DocumentsVerification from "../DocumentVerification/DocumentsVerification";
import PreviewModal from "./PreviewModal";
import PlanModal from "../planModal";
import Upload from './Upload'
import * as com from "../Common"
import { parse } from "date-fns";
const mapStateToProps = (state) => {
    return {
        application: state.application,
        borrower: state.borrower,
        subscriptionReducer: state.subscriptionReducer.currentInfo

    }
}

class DocumentManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            documents: [],
            initDocuments: [],
            url: "",
            download: "",
            zipGeneration: false,
            parsedDocs: [],
            parsedOptions: [],
            docTobeBeVerified: "",
            showDocVerificationModal: false,
            showPreviewModal: false,
            selectedRowKeys: [],
            operate: "",
            operateFileName: "",
            handleRecord: null,
            showOperateModal: false,
            loading: false,
            saveLoading: false,
            pdf: "",
            numPages: 1,
            pageNumber: 1,
            scale: 1,
            pageWidth: 660,
            editingCommetIndex: -1,
            showVerifiableDoc: true,
            showTooltip: false
        }
        this.aref = React.createRef();
        this.formRef = React.createRef()
        this.maxDate = new Date()
        this.maxDate.setFullYear(this.maxDate.getFullYear() + 10)

        this.tableColumns = [
            {
                title: "Name",
                dataIndex: "filename",
                key: "filename",
                width: 250,
                fixed: 'left',
                sorter: (a, b) => {
                    if (a.filename < b.filename) {
                        return -1;
                    }
                    if (a.filename > b.filename) {
                        return 1;
                    }
                    return 0;
                },
                render: (text, record, i) =>{
                    return  <div className="document-filename"><img src={record.mime === "application/pdf" ? "/images/document-pdf.svg" : "/images/document-zip.svg"} /><div className="filename"><div>{this.fileNameFormat(text)}</div></div>{this.getVerifiedSign(record)}</div>
                },
            },
            {
                title: 'Comment',
                dataIndex: "comment",
                key: "comment",
                render: (text, record, index) => {
                    if (index === this.state.editingCommetIndex) {
                        return (
                            <Input
                                type="text"
                                defaultValue={text}
                                autoFocus
                                onPressEnter={(e) => this.handleDocument({ key: 'comment' }, record, e.target.value)}
                                onBlur={(e) => this.handleDocument({ key: 'comment' }, record, e.target.value)}
                            />
                        )
                    } else if (text === '') {
                        return <div className='comment-add' onClick={() => this.setState({ editingCommetIndex: index })}>Add</div>
                    } else {
                        return <div className='table-comment'><div className='comment-text'>{text}</div><span className='comment-edit' onClick={() => this.setState({ editingCommetIndex: index })}>Edit</span></div>
                    }
                }
            },
            {
                title: 'Uploaded at',
                dataIndex: "created",
                key: "created",
                sorter: (a, b) => {
                    if (a.created < b.created) {
                        return -1;
                    }
                    if (a.created > b.created) {
                        return 1;
                    }
                    return 0;
                },
                render: (text) => <span className="document-created">{text}</span>,
            }
        ]

        this.dropdownItems = [
            {
                label: 'Preview',
                key: 'preview',
            },
            {
                type: 'divider',
            },
            {
                label: 'Rename',
                key: 'rename',
            },
            {
                label: 'Download',
                key: 'download',
            },
            // {
            //     label: 'Document type',
            //     key: 'documentType',
            // },
            // {
            //     label: 'Share',
            //     key: 'share',
            // },
            {
                type: 'divider',
            },
            {
                label: 'Delete',
                key: 'delete',
                danger: true,
            },
        ]
    }

    getVerifiedSign = (record) => {
        if (!record.parsedData && !record.verifiedData) return ""
        if (record.verifiedData) return <Tooltip placement="bottom" title={'Document is verified'} arrow={false}><img src="/images/document-verified.svg" /></Tooltip>
        return <Tooltip
            placement="bottom" 
            color="#FFF"
            open={this.state.showTooltip}
            onOpenChange={(visible) => {
                this.setState({showTooltip: visible})
            }}
            overlayClassName="unverified-tooltip"
            title={
                <div>
                    <div style={{fontSize: 14, color: '#222', lineHeight: '18px'}}>This document is compatible with our AI-powered document verification feature.</div>
                    <div style={{fontSize: 14, fontWeight: 500, color: '#325CEB', lineHeight: '18px', marginTop: 12, cursor: 'pointer'}} onClick={() => {this.setState({showTooltip: false}); this.verifyDocument(record)}} >Verify document</div>
                    <CloseOutlined style={{position: 'absolute', top: 12, right: 12, fontSize: 16, cursor: 'pointer', color: '#6E6E70'}} onClick={() => this.setState({showTooltip: false})} />
                </div>
            } 
            arrow={false}>
                <img src="/images/document-unverified.svg" />
            </Tooltip>
    }

    documentsJSON = (document, index) => {
        let result = {
            progress: <Spinner className="position-relative" size="sm" variant="success"
                animation={((document.progress > 0) && (document.progress < 100)) ? "border" : ""} />,
            filename: document.name,
            comment: document.comment,
            created: document.created,
            delete: "Delete",
            id: document.id,
            index: index,
            parsedData: document.parsedData,
            verifiedData: document.verifiedData,
            expiryDate: document.expiryDate,
            forlosonly: document.forlosonly,
            mime: document.mime,
        }
        return result
    }

    deleteDoc = (id) => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.props.borrower.id }
        this.setState({ loading: true })
        fetch('/los/deletedoc/' + id, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                this.setState({ loading: false })
                if (response.status !== 200) {
                    message.error('something went wrong please try later')
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        this.setState({ loading: false })
                        message.error('something went wrong please try later')
                    } else {
                        this.reload()
                    }
                })
            }
        ).catch((err) => {
            message.error('something went wrong please try later')
            this.setState({ loading: false })
        })
    }
    deleteAll = (ids) => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { DocumentIDs: ids }
        this.setState({ loading: true })
        fetch('/los/deletedocs', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                this.setState({ loading: false })
                if (response.status !== 200) {
                    message.error('something went wrong please try later')
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        this.setState({ loading: false })
                        message.error('something went wrong please try later')
                    } else {
                        this.setState({ selectedRowKeys: [] })
                        this.reload()
                    }
                })
            }
        ).catch((err) => {
            message.error('something went wrong please try later')
            this.setState({ loading: false })
        })
    }
    handleDocument = (e, record, value) => {
        const ids = [record.id]
        this.setState({ operate: e.key, handleRecord: record })
        switch (e.key) {
            case 'preview':
                this.downloadAll(ids, 'preview')
                break;
            case 'rename':
                this.setState({ showOperateModal: true }, () => {
                    setTimeout(() => {
                        if (this.formRef.current) {
                            this.formRef.current.setFieldsValue({
                                filename: this.fileNameFormat(record.filename),
                                comment: '',
                                expiryDate: ''
                            })
                        }
                    }, 0)
                })
                break;
            case 'download':
                this.downloadAll(ids, 'downLoad')
                break;
            case 'documentType':
                this.setState({ showOperateModal: true })
                break;
            case 'share':

                break;
            case 'delete':
                this.deleteDoc(record.id)
                break;
            case 'setDate':
                this.setState({ showOperateModal: true }, () => {
                    setTimeout(() => {
                        if (this.formRef.current) {
                            this.formRef.current.setFieldsValue({
                                filename: '',
                                comment: '',
                                expiryDate: record.expiryDate !== '' ? dayjs(record.expiryDate, 'MM/DD/YYYY') : null
                            })
                        }
                    }, 0)
                })
                break;
            case 'comment':
                this.setState({ editingCommetIndex: -1 }, () => {
                    if (record.comment !== value) {
                        let values = {
                            comment: value
                        }
                        this.editdocument(values)
                    }
                })
                break;
            default:
                break;
        }
    }
    verifyDocument = (record) => {
        // const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
        // if (status === undefined) return
        // if ((this.props.subscriptionReducer.plan === 'starter') || (this.props.subscriptionReducer.plan === 'business' && status === 'free')) {
        //     this.setState({ isModalOpen: true })
        //     return
        // }

        this.setState({ docTobeBeVerified: record, showDocVerificationModal: true })
    }
    handleAllLosOnly = () => {
        let ids = this.state.selectedRowKeys
        let checkDoc = this.state.documents.filter(doc => ids.includes(doc.id))
        let allForLosOnly = checkDoc.every(doc => !doc.forlosonly)
        let body = checkDoc.map((item) => ({ DocumentID: item.id, ForLosOnly: allForLosOnly }))
        this.setdocumentforlosonly(body)
    }
    handleLosOnly = (record, checked) => {
        // console.log(record, checked)
        let body = [
            {
                DocumentID: record.id,
                ForLosOnly: !checked
            }
        ]
        this.setdocumentforlosonly(body)
    }
    setdocumentforlosonly = (body) => {
        let token = sessionStorage.getItem("ZeitroA")
        this.setState({ loading: true })
        fetch('/los/setdocumentforlosonly', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                this.setState({ loading: false })
                if (response.status !== 200) {
                    message.error('something went wrong please try later')
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        this.setState({ loading: false })
                        message.error('something went wrong please try later')
                    } else {
                        this.reload()
                    }
                })
            }
        ).catch((err) => {
            message.error('something went wrong please try later')
            this.setState({ loading: false })
        })
    }
    componentDidMount() {
        if (this.props.whosedocuments === 'customer') {
            this.tableColumns.push(
                {
                    title: 'Doc expires',
                    dataIndex: "expiryDate",
                    key: "expiryDate",
                    sorter: (a, b) => {
                        if (a.expiryDate < b.expiryDate) {
                            return -1;
                        }
                        if (a.expiryDate > b.expiryDate) {
                            return 1;
                        }
                        return 0;
                    },
                    render: (text, record) => <span style={{ textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => this.handleDocument({ key: 'setDate' }, record)}>{text === '' ? 'Set expiry date' : text}</span>,
                }
            )
        } else {
            this.tableColumns.push(
                {
                    title: 'Visible to borrower',
                    dataIndex: "visible",
                    key: "visible",
                    width: 200,
                    render: (text, record) => <Switch checked={!record.forlosonly} onChange={(checked) => this.handleLosOnly(record, checked)} />,
                }
            )
        }
        this.tableColumns.push(
            {
                title: "",
                dataIndex: "",
                key: "",
                fixed: 'right',
                width: 200,
                render: (_, record) => (
                    <div className="table-action">
                        {
                            record.parsedData && <div style={{ 'textAlign': 'center' }}>
                                <Button type="default-zeitro" onClick={() => this.verifyDocument(record)}>{record.verifiedData ? "Reverify" : "Verify"}</Button>
                            </div>
                        }
                        <img className="document-download" src="/images/document-visible.svg" onClick={() => this.handleDocument({key:"preview"}, record)} />
                        <img className="document-download" src="/images/document-download.svg" onClick={() => this.downloadAll([record.id], 'downLoad')} />
                        <Dropdown
                            className="document-dropdown"
                            menu={{
                                items: this.dropdownItems,
                                onClick: (e) => this.handleDocument(e, record)
                            }}
                            trigger={['click']}
                        >
                            <img className="document-more" src="/images/document-more.svg" />
                        </Dropdown>
                    </div>
                ),
            }
        )

        this.reload()
    }
    reload = () => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.documents = []
        this.setState({ documents: [], loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.props.borrower.id, LoanID: this.props.borrower.loan_id, WhoseDocuments: this.props.whosedocuments === 'customer' ? this.props.whosedocuments : "lender" }
        this.setState({ loading: true })
        fetch('/los/documents', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                this.setState({ loading: false })
                if (response.status !== 200) {
                    message.error('something went wrong please try later')
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        this.setState({ loading: false })
                        message.error('something went wrong please try later')
                        return
                    } else {
                        let parsedoptions = []
                        let parseddocs = {}
                        let docs = js.Docs.filter(d => d.Mime !== "application/xml").map(s => {
                            if (s.ParsedData) {
                                let d = JSON.parse(s.ParsedData)
                                parsedoptions.push({ title: s.FileName, verified: false });
                                parseddocs[s.FileName] = d
                            }

                            let formattedDate = "-"
                            let dateMatch = s.Uploaded.match(/^(\d{4})-(\d{2})-(\d{2})/);
                            if (dateMatch) {
                                let year = dateMatch[1];
                                let month = dateMatch[2];
                                let day = dateMatch[3];
                            
                                // Convert to mm/dd/yyyy format
                                formattedDate = `${month}/${day}/${year}`;
                            } else {
                                console.log("Invalid date format", s.Uploaded);
                            }


                            let doctype = com.getDocType(s.FileName, s.Comment, s.ParsedData)

                            let parseddata = s.ParsedData
                            if (doctype==="") {
                                parseddata = ""
                            }

                            let tmp = s.ExpiryDate.split(' ')[0]
                            tmp = tmp.split('-')
                            let expDate = s.ExpiryDate === '' ? '' : `${tmp[1]}/${tmp[2]}/${tmp[0]}`
                            let result = { rand: Math.random(), id: s.ID, name: s.FileName, comment: s.Comment, mime: s.Mime, created: formattedDate, progress: 0, expiryDate: expDate, forlosonly: s.ForLosOnly, parsedData: parseddata, verifiedData: s.VerifiedData }
                            
                            return result
                        })
                        let docsCheck = {}
                        if (this.props.whosedocuments === 'customer') {
                            for (let i = 0; i < js.Docs.length; i++) {
                                docsCheck[js.Docs[i].FileName] = js.Docs[i].ForLosOnly
                            }
                        }
                        this.setState({ documents: docs, initDocuments: docs, documentsCheckbox: docsCheck, parsedDocs: parseddocs, parsedOptions: parsedoptions, loading: false })
                    }

                });
            }
        ).catch((err) => {
            message.error('something went wrong please try later')
            this.setState({ loading: false })
        })
    }

    downloadAll = (ids, type) => {

        let token = sessionStorage.getItem("ZeitroA")
        this.setState({ loading: true })

        fetch('/los/downloadalldocs', {
            method: 'POST',
            body: JSON.stringify({ BorrowerID: this.props.borrower.id, DocumentIDs: ids }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(async response => {
            this.setState({ loading: false })
            if (response.status !== 200) {
                message.error('something went wrong please try later')
                return;
            }

            await response.json().then(js => {
                let files = js.Documents;
                if (type === 'preview') {
                    let file = this.base64ToArrayBuffer(files[0].Content)
                    this.setState({ pdf: file, scale: 1, showPreviewModal: true })
                } else {
                    if (files.length === 1) {
                        let file = this.base64ToArrayBuffer(files[0].Content)
                        const modifiedPdfBlob = new Blob([file], { type: files[0].Mime })
                        const downloadLink = document.createElement('a')
                        downloadLink.href = URL.createObjectURL(modifiedPdfBlob)
                        downloadLink.download = files[0].Name
                        downloadLink.click()
                    } else {
                        let zip = new JSZip()
                        const mimeToExtension = {
                            'application/pdf': 'pdf',
                            'application/zip': 'zip',
                        };
                        let filesFolder = zip.folder(`${this.props.borrower.fullname} documents`)
                        for (let i = 0; i < files.length; i++) {
                            let { Name, Content, Mime } = files[i];
                            let extension = mimeToExtension[Mime] || ''; // Default to an empty string if the MIME type is not in the mapping
                            let fileName = extension ? `${Name}.${extension}` : Name; // Append the extension if it exists
                            filesFolder.file(fileName, Content, { base64: true });
                        }
                        zip.generateAsync({ type: 'blob' }).then(blob => saveAs(blob, `${this.props.borrower.fullname} documents.zip`))
                    }
                }
            })
        }).catch(() => {
            message.error('something went wrong please try later')
            this.setState({ loading: false })
        })
    }

    base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)

        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i)
        }

        return bytes.buffer
    }
    fileNameFormat = (filename) => {
        return filename.substring(0, filename.lastIndexOf('.')) ? filename.substring(0, filename.lastIndexOf('.')) : filename
    }
    disabledDate = (current) => {
        return current && current < dayjs().endOf('day')
    }
    handleOperate = (key) => {
        let ids = this.state.selectedRowKeys
        switch (key) {
            case 'date':

                break;
            case 'visible':
                this.handleAllLosOnly()
                break;
            case 'download':
                this.downloadAll(ids, 'downLoad')
                break;
            case 'share':

                break;
            case 'delete':
                this.deleteAll(ids)
                break;
            default:
                break;
        }
    }
    operateModalContent = () => {
        switch (this.state.operate) {
            case 'rename':
                return (
                    <Form.Item
                        name="filename"
                        rules={[
                            {
                                required: true,
                                message: `filename is required.`,
                            }
                        ]}
                    >
                        <Input style={{ height: 46 }} size="large" />
                    </Form.Item>
                )
            case 'documentType':
                return null
            case 'setDate':
                return (
                    <Form.Item
                        name="expiryDate"
                        rules={[
                            {
                                required: true,
                                message: `expiry date is required.`,
                            }
                        ]}
                    >
                        <DatePicker
                            style={{ width: '100%', height: 46 }}
                            size="large"
                            disabledDate={this.disabledDate}
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            suffixIcon={null}
                        />
                    </Form.Item>
                )
        }
    }
    operateCancel = () => {
        this.setState({ showOperateModal: false })
    }
    previewCancel = () => {
        this.setState({ pageNumber: 1, showPreviewModal: false })
    }
    operateSave = async () => {
        const form = this.formRef.current
        try {
            const values = await form.validateFields()
            this.editdocument(values)
        } catch (err) {
            console.log(err)
        }
    }
    editdocument = (values) => {
        console.log(values, this.state.handleRecord, this.state.operate)
        let token = sessionStorage.getItem("ZeitroA")
        let body = {
            BorrowerID: this.props.borrower.id,
            DocumentName: this.state.operate === 'rename' ? `${values.filename}.pdf` : this.state.handleRecord.filename,
            DocumentID: this.state.handleRecord.id,
            Comment: this.state.operate === 'comment' ? values.comment : this.state.handleRecord.comment,
            ExpiryDate: this.state.operate === 'setDate' ? dayjs(values.expiryDate).format('MM/DD/YYYY') : this.state.handleRecord.expiryDate
        }
        this.setState({ saveLoading: true })
        fetch('/los/editdocument', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                console.log(response)
                this.setState({ saveLoading: false })
                if (response.status !== 200) {
                    message.error('something went wrong please try later')
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        this.setState({ saveLoading: false })
                        message.error('something went wrong please try later')
                    } else {
                        this.setState({ showOperateModal: false })
                        this.reload()
                    }
                })
            }
        ).catch((err) => {
            message.error('something went wrong please try later')
            this.setState({ saveLoading: false })
        })
    }
    modalTitleMaps = (key) => {
        let map = {
            'rename': 'Rename',
            'setDate': 'Set expiry date',
            'documentType': 'Document type',
            'upload': 'Upload Documents'
        }
        return map[key]
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages: numPages
        })
    }
    handleZoom = (val) => {
        this.setState({
            scale: val
        })
    }
    onChangePage = (page, pageSize) => {
        this.setState({ pageNumber: page })
    }
    searchFileName = (value) => {
        let filterDoc = this.state.initDocuments.filter(doc => doc.name.toLowerCase().includes(value.toLowerCase()))
        this.setState({ documents: filterDoc })
    }
    previewDownload = (record) => {
        let ids = [record?.id]
        this.downloadAll(ids, 'downLoad')
    }
    displayVerifiableChange = (checked) => {
        this.setState({showVerifiableDoc: !checked})
    }
    getFilteredData = () => {
        const { showVerifiableDoc, documents } = this.state
        return showVerifiableDoc ? documents.map(this.documentsJSON) : documents.map(this.documentsJSON).filter(record => record.parsedData)
    }
    uploadDocuments = () => {
        this.setState({ operate: 'upload', showOperateModal: true })
    }
    uploadSuccess = () => {
        this.operateCancel()
        this.reload()
    }
    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys })
            }
        }
        let operateBtn = [
            // {
            //     key: 'date',
            //     tips: 'Set document expiration date'
            // },
            {
                key: 'visible',
                tips: 'Visible to borrower'
            },
            {
                key: 'download',
                tips: 'Download selected documents'
            },
            // {
            //     key: 'share',
            //     tips: 'share'
            // },
            {
                key: 'delete',
                tips: 'Delete selected documents'
            }
        ]
        let footer = [
            <Button className="document-btn-cancel" type="link" onClick={this.operateCancel}>Cancel</Button>,
            <Button className="document-btn-save" type="primary" loading={this.state.saveLoading} onClick={this.operateSave}>
                Save
            </Button>
        ]
        let upoloadFooter = [
            <Button className="document-btn-cancel" type="link" onClick={this.operateCancel}>Cancel</Button>
        ]
        return (
            <div className="loan-overview-document text-left mt-3">
                <a hidden ref={this.aref} href={this.state.url} download={this.state.download}> </a>
                <Spin spinning={this.state.loading}>
                    {
                        this.state.selectedRowKeys.length > 0 ? (
                            <div className="multiple-bar">
                                <div className="document-total"><CloseOutlined onClick={() => this.setState({ selectedRowKeys: [] })} style={{ fontSize: 12, marginRight: 12, cursor: 'pointer' }} /> <span className="document-num">{this.state.selectedRowKeys.length} selected</span></div>
                                <div className="document-operate">
                                    {
                                        operateBtn.map(item => {
                                            if (item.key === 'visible' && this.props.whosedocuments === 'customer') {
                                                return null
                                            }
                                            return (
                                                <Tooltip placement="bottom" title={item.tips} arrow={false}>
                                                    <div className="document-operate-btn">
                                                        <img src={`/images/document-${item.key}.svg`} onClick={() => this.handleOperate(item.key)} />
                                                    </div>
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="document-topbar">
                                <div><Input style={{ width: 234 }} size="large" onChange={e => this.searchFileName(e.target.value)} placeholder="Search by any field" prefix={<SearchOutlined />} /></div>
                                {
                                    this.props.whosedocuments === 'customer' ? (
                                        <div className="verifiable-switch"><span style={{fontSize: 16, fontWeight: 500, color: '#222'}}>Display verifiable documents only</span><Tooltip placement="bottom" title={'Display verifiable documents only'} arrow={false}><QuestionCircleOutlined style={{color: '#8A8C90', margin: '0 12px 0 4px'}} /></Tooltip><Switch checked={!this.state.showVerifiableDoc} onChange={this.displayVerifiableChange} /></div>
                                    ) : (
                                        <div className="upload-btn" onClick={() => this.uploadDocuments()}>Upload</div>
                                    )
                                }
                            </div>
                        )
                    }
                    <Modal
                        className={this.state.operate === 'upload' ? "document-operate-modal upload-modal": "document-operate-modal" }
                        destroyOnClose
                        width={this.state.operate === 'upload' ? 'auto' : 436}
                        centered={true}
                        open={this.state.showOperateModal}
                        title=""
                        onCancel={this.operateCancel}
                        footer={this.state.operate === 'upload' ? upoloadFooter : footer}
                    >
                        <div className="document-operate-modal-content">
                            <div className="operate-title">{this.modalTitleMaps(this.state.operate)}</div>
                            <div className="operate-content">
                                {
                                    this.state.operate === 'upload' ? <Upload uploadSuccess={() => this.uploadSuccess()} loanid={this.props.loanid}/> : (
                                        <Form
                                            className='task-form'
                                            ref={this.formRef}
                                        >
                                            {this.operateModalContent()}
                                        </Form>
                                    )
                                }
                            </div>
                        </div>
                    </Modal>
                    <PreviewModal
                        open={this.state.showPreviewModal}
                        data={this.state.handleRecord}
                        pdf={this.state.pdf}
                        pageNumber={this.state.pageNumber}
                        numPages={this.state.numPages}
                        width={this.state.pageWidth}
                        scale={this.state.scale}
                        showVerify={this.state.handleRecord?.parsedData}
                        onCancel={this.previewCancel}
                        handleZoom={(val) => this.handleZoom(val)}
                        download={(data) => this.previewDownload(data)}
                        handlePage={(page, pageSize) => this.onChangePage(page, pageSize)}
                        verify={(record) => this.verifyDocument(record)}
                        loadSuccess={(page) => this.onDocumentLoadSuccess(page)}
                    >

                    </PreviewModal>
                    <Modal open={this.state.showDocVerificationModal}
                        footer={null}
                        onCancel={() => this.setState({ showDocVerificationModal: false })}
                        title={"Document Verification | " + this.state.docTobeBeVerified.filename}
                        className="docVerificationModal"
                        wrapClassName="docVerificationModalWrap">
                        <DocumentsVerification currentDoc={this.state.docTobeBeVerified} docs={this.state.parsedDocs} options={this.state.parsedOptions} onCancel={() => {
                            this.setState({ showDocVerificationModal: false })
                            this.reload()
                        }} />

                    </Modal>
                    <PlanModal isModalOpen={this.state.isModalOpen} closeModal={() => {

                        this.setState({ isModalOpen: false })

                    }} ></PlanModal>
                    <Table
                        className="document-table"
                        scroll={{ x: 910 }}
                        // pagination={false} 
                        columns={this.tableColumns}
                        dataSource={this.getFilteredData()}
                        rowKey={record => record.id}
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                    />
                </Spin>
            </div>
        )
    }
}
export default connect(mapStateToProps, null)(DocumentManager)
