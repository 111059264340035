import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import "./programs.css";
import { programs } from '../commonData'
export default function Programs({ paramsInfo, appurl, customerid, loid, appLink, companyLink }) {
    const [currentData, setCurrentData] = useState(0)
    const [list, setList] = useState([
       
    ])

    useEffect(() => {
        if (paramsInfo.paramsList.length === 0) {
            setList(programs)


        } else {
            setList(paramsInfo.paramsList)


        }
    }, [paramsInfo])
    const getPreQualificationLink = () => {
        if (customerid === 'gmcc') {
            if (appLink !== "") {
                return appLink
            } else {
                return companyLink
            }
        }
        if (appLink !== "") {
            return appLink
        }
        if (!appurl) return
        if (window.location.host.includes("zeitro.us")) {
            return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        if (window.location.host.includes("localhost")) {
            return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
    }
    return (
        <Row gutter={20} className="programs">
            <Col lg={10} span={24} className='programs_left'>
                <div className='Financial'>
                    {paramsInfo.title}
                </div>

                <div className='programs-btn' onClick={() => {

                    window.open(getPreQualificationLink(),"_blank")
                }}>
                    Apply Now
                </div>

                {/* add this button specifically for GMCC Margaret Tsay */}
                {loid === "6502d405-7e12-4a7e-8e7e-8ffe960a267d" &&<div className='programs-btn narrorScreenAdjustor' onClick={() => {
                    window.open("https://calendly.com/mtsay/q-a-for-loan-application", "_blank")
                }}>
                    Mortgage Consultation
                </div>}


            </Col>
            <Col lg={14} span={24} className='programs_right'>
                {
                    list.map((item, index) => {

                        return (
                            <div className='right-list' onClick={() => setCurrentData(index)}>
                                <div style={{ width: '36px', height: '36px' }}>
                                    {
                                        index === 0 && <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                            <path d="M9.52046 3.98242H6.52632C5.13105 3.98242 4 5.11347 4 6.50874V29.4912C4 30.8865 5.13105 32.0175 6.52632 32.0175H9.52046C10.9157 32.0175 12.0468 30.8865 12.0468 29.4912V6.50874C12.0468 5.11347 10.9157 3.98242 9.52046 3.98242ZM9.52046 6.50874V29.4912H6.52632V6.50874H9.52046ZM19.4971 11.2456H16.5029C15.1077 11.2456 13.9766 12.3766 13.9766 13.7719V29.4912C13.9766 30.8865 15.1077 32.0175 16.5029 32.0175H19.4971C20.8923 32.0175 22.0234 30.8865 22.0234 29.4912V13.7719C22.0234 12.3766 20.8923 11.2456 19.4971 11.2456ZM19.4971 13.7719V29.4912H16.5029V13.7719H19.4971ZM29.4737 19.1052H26.4795C25.0843 19.1052 23.9532 20.2363 23.9532 21.6315V29.4912C23.9532 30.8865 25.0843 32.0175 26.4795 32.0175H29.4737C30.8689 32.0175 32 30.8865 32 29.4912V21.6315C32 20.2363 30.8689 19.1052 29.4737 19.1052ZM29.4737 21.6315V29.4912H26.4795V21.6315H29.4737Z" fill="black" />
                                        </svg>

                                    }
                                    {
                                        index === 1 && <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                            <path d="M32.3193 2.24609H3.63184C2.8584 2.24609 2.22559 2.87891 2.22559 3.65234V23.3398C2.22559 24.1133 2.8584 24.7461 3.63184 24.7461H14.1084V31.5559H9.53809C8.95449 31.5559 8.4834 32.027 8.4834 32.6105C8.4834 33.1941 8.95449 33.6652 9.53809 33.6652H26.4131C26.9967 33.6652 27.4678 33.1941 27.4678 32.6105C27.4678 32.027 26.9967 31.5559 26.4131 31.5559H21.8428V24.7461H32.3193C33.0928 24.7461 33.7256 24.1133 33.7256 23.3398V3.65234C33.7256 2.87891 33.0928 2.24609 32.3193 2.24609ZM31.6162 4.35547V16.2594H4.33496V4.35547H31.6162ZM19.7334 31.5559H16.2213V24.7461H19.7334V31.5559ZM4.33496 22.6367V18.3687H31.6162V22.6367H4.33496Z" fill="black" />
                                        </svg>

                                    }
                                    {
                                        index === 2 && <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                            <g clip-path="url(#clip0_562_833)">
                                                <path d="M31.5 31.5L29.9025 29.9137C30.1116 29.7029 30.3605 29.5356 30.6347 29.4217C30.9089 29.3078 31.203 29.2494 31.5 29.25C32.0967 29.25 32.669 29.0129 33.091 28.591C33.5129 28.169 33.75 27.5967 33.75 27V4.5C33.75 3.90326 33.5129 3.33097 33.091 2.90901C32.669 2.48705 32.0967 2.25 31.5 2.25H9C8.40326 2.25 7.83097 2.48705 7.40901 2.90901C6.98705 3.33097 6.75 3.90326 6.75 4.5V4.69125C6.75 5.31 6.5025 5.87025 6.0975 6.2775L4.5 4.69125V4.5C4.5 3.30653 4.97411 2.16193 5.81802 1.31802C6.66193 0.474106 7.80653 0 9 0L31.5 0C32.6935 0 33.8381 0.474106 34.682 1.31802C35.5259 2.16193 36 3.30653 36 4.5V27C36 28.1935 35.5259 29.3381 34.682 30.182C33.8381 31.0259 32.6935 31.5 31.5 31.5ZM2.25 9V31.5C2.25 32.0967 2.48705 32.669 2.90901 33.091C3.33097 33.5129 3.90326 33.75 4.5 33.75H27C27.5967 33.75 28.169 33.5129 28.591 33.091C29.0129 32.669 29.25 32.0967 29.25 31.5V9C29.25 8.40326 29.0129 7.83097 28.591 7.40901C28.169 6.98705 27.5967 6.75 27 6.75H4.5C3.90326 6.75 3.33097 6.98705 2.90901 7.40901C2.48705 7.83097 2.25 8.40326 2.25 9ZM27 4.5C28.1935 4.5 29.3381 4.97411 30.182 5.81802C31.0259 6.66193 31.5 7.80653 31.5 9V31.5C31.5 32.6935 31.0259 33.8381 30.182 34.682C29.3381 35.5259 28.1935 36 27 36H4.5C3.30653 36 2.16193 35.5259 1.31802 34.682C0.474106 33.8381 0 32.6935 0 31.5V9C0 7.80653 0.474106 6.66193 1.31802 5.81802C2.16193 4.97411 3.30653 4.5 4.5 4.5H27Z" fill="black" />
                                                <path d="M5.625 9H19.125C19.4234 9 19.7095 9.11853 19.9205 9.32951C20.1315 9.54048 20.25 9.82663 20.25 10.125C20.25 10.4234 20.1315 10.7095 19.9205 10.9205C19.7095 11.1315 19.4234 11.25 19.125 11.25H5.625C5.32663 11.25 5.04048 11.1315 4.8295 10.9205C4.61853 10.7095 4.5 10.4234 4.5 10.125C4.5 9.82663 4.61853 9.54048 4.8295 9.32951C5.04048 9.11853 5.32663 9 5.625 9ZM25.875 11.25C25.5766 11.25 25.2905 11.1315 25.0795 10.9205C24.8685 10.7095 24.75 10.4234 24.75 10.125C24.75 9.82663 24.8685 9.54048 25.0795 9.32951C25.2905 9.11853 25.5766 9 25.875 9C26.1734 9 26.4595 9.11853 26.6705 9.32951C26.8815 9.54048 27 9.82663 27 10.125C27 10.4234 26.8815 10.7095 26.6705 10.9205C26.4595 11.1315 26.1734 11.25 25.875 11.25ZM2.25 13.5H29.25V15.75H2.25V13.5Z" fill="black" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_562_833">
                                                    <rect width="36" height="36" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    }
                                </div>
                                <div style={{ marginLeft: "23px" }}>
                                    <div className='right_title'>
                                        {item.title}
                                    </div>
                                    <div className='right_content'>
                                        {item.review}
                                    </div>

                                </div>
                            </div>

                        )
                    })

                }

            </Col>
        </Row>
    );
}