import React, { useState, useEffect, useRef } from 'react';
import { useImmer } from "use-immer";
import { Form, Input, Button, Col, Row, Modal, Space, Popover } from 'antd';
import { checkAccess } from "./Auth";
import { connect } from 'react-redux'
import { capitalize } from 'lodash';

const mapStateToProps = (state) => {
    return {
      subscriptionReducer: state.subscriptionReducer.currentInfo
    }
  }

const PlanModal = (props) => {
    const { isModalOpen, closeModal, isMismo } = props
    const msg =  props.subscriptionReducer.plan === 'starter'? "upgrade to Zeitro Business Plan" : "upgrade your account"
    
    const getMessages = () => {
        if (isMismo) {
            if (checkAccess(["Owner",'LoanOfficerManager'])) {
                return <p>
                You have reached the maximum number of MISMO downloads for this month. Please upgrade your account to download more MISMO files.
            </p>
            }
            return <p>
                You have reached the maximum number of MISMO downloads for this month. Please contact your account admin to upgrade your account to download more MISMO files.
            </p>
        }
        if (checkAccess(["Owner",'LoanOfficerManager'])) {
            return <><p style={{ color: '#222', fontSize: '26px'}}>{capitalize(msg)} </p>
            <p style={{ color: '#6E6E70', fontSize: '14px', marginTop: "10px" }}>to enjoy this feature</p>
            </>
        }
        return <><p style={{ color: '#6E6E70', fontSize: '14px'}}>Please contact your admin to</p>
        <p style={{ color: '#222', fontSize: '26px' }}>{msg} </p>
        <p style={{ color: '#6E6E70', fontSize: '14px', marginTop: "10px" }}>to enjoy this feature</p>
        </>        
    }

    
    return (
        <>

            <Modal open={isModalOpen} footer={null} onCancel={() => {
                closeModal()
            }}>
                <div style={{ textAlign: 'center', marginTop: '80px', fontSize: '18px', fontWeight: '600' }}>
                    <img src='/images/crown.svg' className='mb-3'></img>
                    {getMessages()}
                </div>
                {checkAccess(["Owner",'LoanOfficerManager']) && <div style={{ textAlign: 'center', marginTop: '40px', }}>

                    <Button style={{ width: '100%', height: '44px' }} type='primary' onClick={() => {
                        sessionStorage.setItem('path', 'Pricing')
                        closeModal()
                        window.open('/app/management',"_self")
                    }}>Upgrade now</Button>

                </div>}

            </Modal>


        </>
    )
}

export default connect(mapStateToProps)(PlanModal);