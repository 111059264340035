import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'
import { withRouter } from "react-router-dom";
import InfoModal from '../../CommonUIComponents/InfoModal';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.password = React.createRef();
        this.password1 = React.createRef();
        this.captcha = React.createRef();
        this.timer = null;
        this.buttonpressed = false;
        this.customerToken = null;

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            show: false,
            message: "",
            validated: false,
            passwordCheckReady: false,
            text: "",
            bgcolor: "rgb(232,235,239)",
            text2: "",
            bgcolor2: "rgb(232,235,239)",
            signingup: false,
            loid: "",
            infoModal: false,
        };
        let params = new URLSearchParams(window.location.search);
        let loid = params.get("loid")

        if (null != loid) {
            this.state.loid = loid
            localStorage.setItem("loid", loid)
        } else {
            let l = localStorage.getItem("loid")
            if (null != l)
                this.state.loid = l
        }
        this.local = window.location.hostname === "localhost"

        this.pattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        if (this.state.signin)
            this.pattern = "^.+$"

    }

    validateForm() {
        if (this.state.signin)
            return this.state.email.length > 0 && this.state.password.length > 8;
        else
            return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0 && this.state.password.length > 8
                && this.state.password === this.state.password1;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        window.clearInterval(this.timer)
    }

    handleClose = () => { this.setState({ show: false }) }

    signup = (event, data) => {
        let token = sessionStorage.getItem("ZeitroA")

        let tosend =
            JSON.stringify({
                email: data.get("email").toLowerCase(), firstName: data.get("firstName"),
                lastName: data.get("lastName"), leadSource: data.get("leadSource")
            })
        this.setState({ signingup: true })
        fetch('/los/bosignup', {
            method: 'POST',
            body: tosend,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({
                        message: 'Looks like there was a problem. Status Code: ' +
                            response.status, show: true
                    })
                    if (!this.local)
                        window.grecaptcha.reset()
                    return;
                }
                // Examine the text in the response
                this.setState({ signingup: false })

                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert(js.Text)
                    } else {
                        this.customerToken = js.Token;
                        this.setState({infoModal: true});
                    }
                });
            }
        ).catch((err) => {
            this.setState({ signingup: false })
            console.log('Fetch Error :', err);
            this.setState({ message: "Our servers are experiencing problems. Please check later.", show: true })
            if (!this.local)
                window.grecaptcha.reset()

        });
    }
    handleSubmit = event => {
        this.buttonpressed = true;


        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();

        const data = new FormData(event.target);
        if (this.state.signin) {
            this.signin(event, data)
        } else {
            this.signup(event, data)

        }
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }

    onInvite = e => {

    }

    hideInfoModal = e => {
        this.setState({infoModal: false});
        window.location.href = "/app/borrower/" + this.customerToken;
    }

    render() {

        return (
            <div id="loginbox" className="Login loginbox mt-5 pt-5" ref={this.ref}>
                <InfoModal
                    onHide={this.hideInfoModal}
                    show={this.state.infoModal}
                    modalTitle={'Success'}
                    modalBody={'Customer account was created successfully.'}
                />

                <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.signin ? 'Authentication Failed' : 'Sign Up Failed'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.message}</Modal.Body>
                    <Modal.Footer>
                        <Button id="Close1" variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                Create an account on borrower's behalf
                <div className="text-center px-5 pb-5 ">
                    {this.state.signingup ?
                        <div id="spinner" className="pt-5 mt-5">

                            <img alt="spinner" className="loader" src="/logo.png"></img>
                        </div>
                        :
                        <Form style={{ fontSize: '0.8em' }} ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                            <Row className="mt-0 pt-4 ">
                                <Col className="text-left">
                                    <Form.Group controlId="email"  >
                                        <Form.Label className="text-left" >Email:</Form.Label>
                                        <Form.Control
                                            required
                                            name="email"
                                            size="sm"
                                            autoFocus
                                            autoComplete="email"
                                            type="email"
                                            defaultValue={this.state.email}
                                            onChange={this.handleChange}
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please enter a valid email address!
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Col>
                            </Row>

                            <div>
                                <Row className="  text-right">

                                    <Col className="text-left">
                                        <Form.Group controlId="firstName"  >
                                            <Form.Label className="text-left" >First Name:</Form.Label>

                                            <Form.Control
                                                name="firstName"
                                                autoComplete="given-name"
                                                required
                                                size="sm"
                                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                                value={this.state.firstName}
                                                onChange={this.handleChange}
                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please enter your first name!
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col className="text-left">
                                        <Form.Group controlId="lastName"  >
                                            <Form.Label className="text-left" >Last Name:</Form.Label>

                                            <Form.Control
                                                size="sm"
                                                name="lastName"
                                                autoComplete="family-name"
                                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                                required
                                                value={this.state.lastName}
                                                onChange={this.handleChange}
                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please enter your last name!
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row className="  ">
                                    <Col className="text-left" >
                                        <Form.Group controlId="leadSource" >
                                            <Form.Label className="text-left" >How did borrower hear about us?</Form.Label>

                                            <InputGroup size="sm" >

                                                <Form.Control as="select" size="sm"
                                                    name="leadSource"
                                                    required
                                                    type="text"
                                                    defaultValue=""
                                                    onChange={this.handleChange}
                                                >
                                                    <option value=""></option>
                                                    <option value="Facebook Ads">Facebook Ads</option>
                                                    <option value="LinkedIn">LinkedIn</option>
                                                    <option value="Instagram">Instagram</option>
                                                    <option value="Newsletter">Newsletter</option>
                                                    <option value="Post cards">Post cards</option>
                                                    <option value="Friends or Family">Friends or Family</option>
                                                    <option value="Internet Search">Internet Search</option>
                                                    <option value="Open House Flyer">Open House Flyer</option>
                                                    <option value="Loan Officer Lead">Loan Officer Lead</option>
                                                    <option value="Other">Other</option>
                                                </Form.Control>

                                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" >
                                                    Please tell us how you've heard about us
                                                </Form.Control.Feedback>
                                            </InputGroup >

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div> Use this account to prepopulate information on the basis of personal or phone interview. It is not exposed to the borrower until the hand off </div>
                            </div>

                            <Row className="pt-3 ">
                                <Col >
                                    <Button id="loginorsignup"
                                        type="submit" className="lpbutton" variant="primary"
                                    >{this.state.signin ? 'Login' : 'Sign Up'} </Button>
                                </Col>
                            </Row>
                            <Row className="mt-3 ">
                            </Row>

                        </Form>}
                </div>
            </div>

        );
    }
}





Login = withRouter(Login)