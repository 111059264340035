import React, { useState, useEffect } from 'react';
import { 
  Input,
  InputNumber,
  Select,
  message,
  Switch,
  Row,
  Col,
  Spin
} from 'antd';
import "./Common.css";
import "./Overview.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import * as com from "../../Common"
import { Link } from 'react-router-dom'


const eligibleCities = ["Fremont", "Newark", "Union City", "Pleasanton", "Dublin", "Livermore", "San Ramon", "Danville"]
const eligibleCounties = ["Santa Clara County", "San Mateo County"]


const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
        borrower: state.borrower
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
      dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    }
  });

  export function CalcHardMoneyLoanRate(props)  {
    // to get max loan amount:
    // if it's a purchase loan (+rehab)，the max loan amount is the lesser of 85% LTV or 70% LTARV. 
    // if it's a rate-term refi loan, the max loan amount is the lesser of 70% LTV or 70% LTARV. 
    // If it's a cash-out refi loan, the max is the lesser of 65% LTV or 65% LTARV.
    
      let result = {
        unqualified: false,
        unqualifiedReason: "", 
        floorRate: 0,
        point: 0,
        monthlyPayment: 0,
        closingCost: 0,
        maxLoanAmount: 0,
    
      }
      const {
        property,
        hardmoneyloan,
      } = props.application;
    
      let propertyType = property.propertytype
      let propertyCounty = property.county
      let propertyCity = property.city.trim()
      let rehabAdded = hardmoneyloan.rehabloan
      let scre = hardmoneyloan.estimatedfico
      let borrowerExperience = hardmoneyloan.timesofflippingaproperty + hardmoneyloan.timesofpurchasingarentalproperty
      let loanTermMonths = hardmoneyloan.mortgagetermmonths
      let loanamount = ""
      let maxLoanAmount = ""
      let loanAmountLimit = ""
      let totalLoanAmount = ""
      let ltv = 0
      let ltarv = 0
      let ltc
      let floorRate = 8.99
      let point = 1
      let maxLTV = 0
      let maxLTARV = 0
      let maxLTC = 0
      let minFICO = 0
      let minExp = 3
      let minTermMonths = 3
      let dayOneEquity = 0
      let cashoutAmount = 0
      let cashoutPercentage = 0
    
    
        //find min of purPrice * maxltv, cost * maxltc, arv * maxltarv
      const calcMaxLoanAmount = (purPrice, cost, arv ,maxltv, maxltc, maxltarv) => {
        let maxLoanAmount = 0
        let purPriceMax = purPrice * maxltv
        let costMax = cost * maxltc
        let arvMax = arv * maxltarv
        if (!rehabAdded) {
          arvMax = purPriceMax
          costMax = purPriceMax
        }
        console.log("purPriceMax: " + purPrice+ "*" + maxltv + "="+purPriceMax)
        console.log("costMax: " + cost+ "*" + maxltc + "="+costMax)
        console.log("arvMax: " + arv+ "*" + maxltarv + "="+arvMax)
        console.log("maxLoanAmount: " + Math.min(purPriceMax, costMax, arvMax))
    
        maxLoanAmount = Math.min(purPriceMax, costMax, arvMax)
        return maxLoanAmount
      }
    
    
      let salesPrice = com.safeParseInt(property.salesprice)
      let purpose = property.purpose
      let initialAdvance = com.safeParseInt(hardmoneyloan.initialadvancerequested)
      let rehabLoanAmount = com.safeParseInt(hardmoneyloan.rehabloanamount)
      let projectAfterRepairValue = com.safeParseInt(hardmoneyloan.projectedafterrepairvalue)
      let estimatedCurrentValue = com.safeParseInt(hardmoneyloan.refinancedetails.estimatedCurrentValue)
      let constructionBudget = com.safeParseInt(hardmoneyloan.constructionbudget)
      let constructionLoanAmount = com.safeParseInt(hardmoneyloan.constructionloanamount)
      let refinanceLoanAmount = com.safeParseInt(hardmoneyloan.refinanceloanamount)
      let existingLoanAmount = com.safeParseInt(hardmoneyloan.refinancedetails.existingLoanAmount)
    
      if (salesPrice==="") {
        ltv = 0
      } else {
        switch (purpose) {
          case "purchaserehab":
            loanAmountLimit = 5000000
            point = 1
            maxLTV = 80
            maxLTARV = 70
            maxLTC = 85
            minFICO = 650
            minExp = 3
            minTermMonths = 3
    
            loanamount = initialAdvance
            dayOneEquity = salesPrice - loanamount
            ltv = 100 * loanamount / salesPrice
            if (rehabAdded) {
              totalLoanAmount = loanamount + rehabLoanAmount
              ltarv = 100 * rehabLoanAmount / projectAfterRepairValue
            } else {
              totalLoanAmount = loanamount
              ltarv = ltv
            }
            ltc = 100 * totalLoanAmount / (salesPrice + rehabLoanAmount)
            maxLoanAmount = calcMaxLoanAmount(salesPrice, salesPrice + rehabLoanAmount, projectAfterRepairValue, 0.80, 0.85, 0.7)
            if (maxLoanAmount > loanAmountLimit) {
              maxLoanAmount = loanAmountLimit
            }
            if(totalLoanAmount > maxLoanAmount) {
              totalLoanAmount = maxLoanAmount
              if (rehabAdded) {
                dayOneEquity = salesPrice - (maxLoanAmount - rehabLoanAmount)
                ltv = 100 * (maxLoanAmount - rehabLoanAmount) / salesPrice
                ltarv = 100 * maxLoanAmount / projectAfterRepairValue
              } else{
                dayOneEquity = salesPrice - maxLoanAmount
                ltv = 100 * maxLoanAmount / salesPrice
                ltarv = ltv
              }
              ltc = 100 * maxLoanAmount / (salesPrice + rehabLoanAmount)
            }
            break;
          case "refinance":
            loanamount = refinanceLoanAmount
            loanAmountLimit = 2000000
            totalLoanAmount = loanamount
            point = 1.5
            maxLTV = 70
            maxLTARV = 70
            maxLTC = 70
            minFICO = 700
            minExp = 3
            minTermMonths = 3
            dayOneEquity = estimatedCurrentValue - loanamount
            ltv = 100 * loanamount / estimatedCurrentValue
            ltarv = 100 * totalLoanAmount / estimatedCurrentValue
            ltc = 100 * totalLoanAmount / estimatedCurrentValue
            maxLoanAmount = calcMaxLoanAmount(estimatedCurrentValue, estimatedCurrentValue, estimatedCurrentValue, 0.7, 0.7, 0.7)
            if (maxLoanAmount > loanAmountLimit) {
              maxLoanAmount = loanAmountLimit
            }
            if(totalLoanAmount > maxLoanAmount) {
              totalLoanAmount = maxLoanAmount
              dayOneEquity = estimatedCurrentValue - maxLoanAmount
              ltv = 100 * maxLoanAmount / estimatedCurrentValue
              ltarv = 100 * maxLoanAmount / estimatedCurrentValue
              ltc = 100 * maxLoanAmount / estimatedCurrentValue
            }
            break;
          case "cashoutrefinance":
            loanamount = refinanceLoanAmount
            loanAmountLimit = 2000000
            totalLoanAmount = loanamount
            point = 1.5
            maxLTV = 65
            maxLTARV = 65
            maxLTC = 65
            minFICO = 700
            minExp = 10
            minTermMonths = 3
            dayOneEquity = estimatedCurrentValue - loanamount
            cashoutAmount = loanamount - existingLoanAmount
            cashoutPercentage = 100* cashoutAmount / estimatedCurrentValue
            ltv = 100 * loanamount / estimatedCurrentValue
            ltarv = 100 * totalLoanAmount / estimatedCurrentValue
            ltc = 100 * totalLoanAmount / estimatedCurrentValue
            maxLoanAmount = calcMaxLoanAmount(estimatedCurrentValue, estimatedCurrentValue, estimatedCurrentValue, 0.65, 0.65, 0.65)
            if (maxLoanAmount > loanAmountLimit) {
              maxLoanAmount = loanAmountLimit
            }
            if(totalLoanAmount > maxLoanAmount) {
              totalLoanAmount = maxLoanAmount
              dayOneEquity = estimatedCurrentValue - maxLoanAmount
              cashoutAmount = maxLoanAmount - existingLoanAmount
              cashoutPercentage = 100* cashoutAmount / estimatedCurrentValue
              ltv = 100 * maxLoanAmount / estimatedCurrentValue
              ltarv = 100 * maxLoanAmount / estimatedCurrentValue
              ltc = 100 * maxLoanAmount / estimatedCurrentValue
            }
            break;
          case "construction":
            loanamount = constructionLoanAmount
            loanAmountLimit = 5000000
            totalLoanAmount = loanamount
            point = 2
            maxLTV = 70
            maxLTARV = 70
            maxLTC = 85
            minFICO = 700
            minExp = 20
            minTermMonths = 3
            dayOneEquity = constructionBudget - loanamount
            ltv = 100 * totalLoanAmount / constructionBudget
            ltarv = 100 * totalLoanAmount / projectAfterRepairValue
            ltc = 100 * totalLoanAmount / constructionBudget
            maxLoanAmount = calcMaxLoanAmount(constructionBudget, constructionBudget, projectAfterRepairValue, 0.7, 0.85, 0.7)
            if (maxLoanAmount > loanAmountLimit) {
              maxLoanAmount = loanAmountLimit
            }
            if(totalLoanAmount > maxLoanAmount) {
              totalLoanAmount = maxLoanAmount
              dayOneEquity = constructionBudget - maxLoanAmount
              ltv = 100 * maxLoanAmount / constructionBudget
              ltarv = 100 * maxLoanAmount / projectAfterRepairValue
              ltc = 100 * maxLoanAmount / constructionBudget
            }
            break;
        }
    
      }
    
      console.log("******start parsing rate-point-cost********")
      // check lending area 
      if (!eligibleCities.includes(propertyCity) && !eligibleCounties.includes(propertyCounty)) {
        console.log("not in lending area")
        result.unqualified = true
        result.unqualifiedReason = "The subject property address is not in the lending area of this program."
      }
      // check multifamily or mixeduse, rates for these are subject to pre-approval
      if (propertyType === "multifamily" || propertyType === "mixeduse") {
        console.log("is multifamily or mixeduse, need pre-approval")
        result.unqualified = true
        result.unqualifiedReason = "Program's rate and cost is subject to pre-approval for multifamily or mixed used property."
      }
    
      // check loan amount limit
      if (totalLoanAmount > loanAmountLimit) {
        console.log("loan amount exceed: " + loanAmountLimit)
        result.unqualified = true
        result.unqualifiedReason = "The maximum loan amount is $"+ loanAmountLimit/1000000 + "M, while the borrower's current loan amount is $"+ totalLoanAmount + "."
      }
    
      // check fico score above minFICO
      if (scre < minFICO) {
        console.log("fico score below minFICO: " + minFICO)
        result.unqualified = true
        result.unqualifiedReason = "The minimum FICO score is "+ minFICO +", whereas the FICO score borrower have provided is " + scre
      }
    
      // check borrower experience above minExp
      if (borrowerExperience < minExp) {
        console.log("borrower experience below minExp: " + minExp)
        result.unqualified = true
        result.unqualifiedReason = "The program requires a minimum total experience of "+ minExp + " for the last 3 years, whereas the number of experience borrower have declared is " + borrowerExperience
      }
    
      // make sure purchase price entered is valid
      if (property.salesprice === "" || property.salesprice == 0) {
        console.log("purchase price is empty or zero")
        result.unqualified = true
        result.unqualifiedReason = "Invalid value of purchase price/as-is-value, can't be empty or zero"
      }
    
      // make sure projected after repair value is entered valid if rehab added
      if (rehabAdded && (hardmoneyloan.projectedafterrepairvalue === "" || hardmoneyloan.projectedafterrepairvalue === 0)) {
        console.log("projected after repair value is empty or zero")
        result.unqualified = true
        result.unqualifiedReason = "Invalid projected after repair value, can't be empty or zero"
      }
    
          // console.log("ltarv > 70, rate and cost is subject to pre-approval")
          // result.unqualified = true
          // result.unqualifiedReason = "For single family residence and loan to after repair value is above 70%, rate and cost is subject to pre-approval. Please procced to the applicaton and discuss it with your loan officer."
    
      switch (purpose) {
        case "purchaserehab":
          floorRate = 9
          console.log("in purchase rehab, init floorRate: " + floorRate)
          break;
        case "refinance":
          floorRate = 10
          console.log("in refi, init floorRate: " + floorRate)
          break;
        case "cashoutrefinance":
          floorRate = 10
          console.log("in cashout, init floorRate: " + floorRate)
          break;
        case "construction":
          floorRate = 12
          console.log("in construct, init floorRate: " + floorRate)
          break;
      }
    
      switch (ltarv) {
        case ltarv <= 55:
          floorRate = floorRate
          console.log("ltarv <= 55, nothing added")
          break;
        case ltarv > 55 && ltarv <= 60:
          floorRate += 0.25
          console.log("ltarv > 55 && ltarv <= 60, rate +0.25")
          break;
        case ltarv > 60 && ltarv <= 65:
          floorRate += 0.5
          console.log("ltarv > 60 && ltarv <= 65, rate +0.5")
          break;
        case ltarv > 65 && ltarv <= 70:
          floorRate += 0.75
          console.log("ltarv > 65 && ltarv <= 70, rate +0.75")
          break;
        case ltarv > 70 && ltarv <= 80:
          floorRate += 9
          console.log("ltarv > 70 && ltarv <= 80, rate is 9")
      }
    
    
      //   } else {
      //     console.log("ltarv > 65, rate and cost is subject to pre-approval")
      //     result.unqualified = true
      //     result.unqualifiedReason = "For 2-4 unit residential property and loan to after repair value is above 65%, rate and cost is subject to pre-approval. Please procced to the applicaton and discuss it with your loan officer."
      //   }
      // }
    
      // rate adjustment when day one equity is less than 85k
      if (dayOneEquity <= 85000) {
        console.log("dayOneEquity <= 85000, rate +0.25")
        floorRate += 0.25
      }
    
      // rate adjustment based on cashout percentage
      if (cashoutPercentage>=1.5 && cashoutPercentage <=5) {
        console.log("cashoutPercentage>=1.5 && cashoutPercentage <=5, rate +0.125")
        floorRate += 0.125
      }
      if (cashoutPercentage>5) {
        console.log("cashoutPercentage>5, rate +0.5")
        floorRate += 0.5
      }
    
      // rate adjustment based on ltv
      if (ltv > 60 && ltv <= 70){
        console.log("in ltv 60-70, rate +0.5")
        floorRate += 0.5
      }
      else if (ltv > 70 && ltv <= maxLTV){
        console.log("in ltv 70-ceiling, rate +1")
        floorRate += 1
      }
    
      // rate and point adjustment based on fico score
      if (scre >= minFICO) {
        if ( scre >= 700 && scre <= 719) {
          console.log("in scre 700-719, rate +0.25")
          floorRate += 0.25
        }
        if ( scre >=680 && scre <= 699) {
          console.log("in scre 680-699, rate +0.5, point +0.5")
          floorRate += 0.5
          point += 0.5
        }
        if ( scre >= 650 && scre <= 679) {
          console.log("in scre 650-679, rate +0.75, point +1")
          floorRate += 0.75
          point += 1
        }
      } 
    
    
      //rate adjustment based on borrower experience
      if (borrowerExperience >= 10 && borrowerExperience <= 20) {
        console.log("in experience 10-20, rate +0.25")
        floorRate += 0.25
      }
      if (borrowerExperience >= 3 && borrowerExperience < 10) {
        console.log("in experience 3-10, rate +0.5")
        floorRate += 0.5
      }
      
      // rate and point adjustment based on loan term
      if (com.safeParseInt(loanTermMonths) > 12){
        console.log("in term > 12, rate +0.5, point +0.5")
        floorRate += 0.5
        point += 0.5
      }
      // addtional fee based on loan amount
      let extraFee = 0
      if (totalLoanAmount >= 85000 && totalLoanAmount <= 125000) {
        extraFee = 1000
        console.log("in loan amount 85000-125000, have $" + extraFee + " as extra fee")
      }
      console.log("check type!!!!!!", propertyType)
      // rate and point adjustment based on property type
      switch (propertyType) {
        case "singlefamilydetached":
          break;
        case "mixeduse":
          console.log("property type is mixeduse, rate +2, point +1")
          floorRate += 2
          point += 1
          break;
        case "multistory":
          console.log("property type is commercial, rate +3, point +1")
          floorRate += 3
          point += 1
          break;
        case "twotofourunit":
          console.log("property type is 2-4 unit, rate +1, point +0.25")
          floorRate += 0.25
          break;
        case "commercial":
          console.log("property type is commercial, rate +3, point +1")
          floorRate += 3
          point += 1
          break;
      }
      console.log("final total loan amount: " + totalLoanAmount)
      // calc final monthly payment and closing cost
      let mp = Math.round(0.01*(floorRate)*com.safeParseInt(totalLoanAmount)/com.safeParseInt(props.application.hardmoneyloan.mortgagetermmonths))
      let cc = Math.round(com.safeParseInt(totalLoanAmount) * point * 0.01 + extraFee)
    
    
      console.log("******end parsing rate-point-cost********")
      console.log("ltarv: " + ltarv)
      console.log("ltc: " + ltc)
      console.log("ltarv: " + ltarv)
      console.log("final total loan amount: " + totalLoanAmount)
      console.log("final max loan amount: " + maxLoanAmount)
    
      result.floorRate = floorRate
      result.point = point
    
      result.monthlyPayment = mp
      result.closingCost = cc
      result.maxLoanAmount = maxLoanAmount
      return result
    }

export function calcLoanAmount(props)  {
    let lm = ""
    if (props.property.purpose === "purchase") {
        lm = props.hardmoney.initialloanamount
    }
    if (props.property.purpose === "purchaserehab") {
        lm = props.hardmoney.initialloanamount
    }
    if (props.property.purpose === "refinance") {
        lm = props.hardmoney.refinanceloanamount
    }   
    if (props.property.purpose === "cashoutrefinance") {
        lm = props.hardmoney.refinanceloanamount
    }
    if (props.property.purpose === "construction") {
        lm = props.hardmoney.constructionloanamount
    }        
    return lm
}
export function getLoanAmount(props)  {
    let lm = calcLoanAmount(props)
    if (lm !== "") {
        return com.commaize(lm)
    }
    return "-"
}

export function getOriginationPoint(props)  {
    let lm = calcLoanAmount(props)
    if (lm !== "") {
        return com.commaize(parseInt(lm) / 100)
    }
    return "-"
}

export function getLTV(props) {
    if (props.property.purpose === "purchaserehab" && props.property.salesprice !== "") {
        let sp = com.safeParseInt(props.property.salesprice)
        let lm = com.safeParseInt(calcLoanAmount(props))
        return Math.round(100 * lm/sp)
    }
    if (props.property.purpose !== "purchaserehab") {
        let sp = com.safeParseInt(props.hardmoney.refinancedetails.estimatedCurrentValue)
        let lm = com.safeParseInt(calcLoanAmount(props))
        return Math.round(100 * lm/sp)
    }
    return "-"
}

export default function Overview(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [showContact, setShowContact] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [contactMessage, setContactMessage] = useState("")
    const [teamList, setTeamList] = useState([])
    const { TextArea } = Input;
    // const statusList = [
    //     {
    //         value: 'Application Draft Started',
    //         label: 'Application Draft Started',
    //         operate: [<a onClick={()=>props.checkInterview()}>Edit application draft</a>]
    //     },
    //     {
    //         value: 'Initial Application Submitted',
    //         label: 'Initial Application Submitted',
    //         operate: []
    //     },
    //     {
    //         value: 'Processing',
    //         label: 'Processing',
    //         operate: []
    //     },
    //     {
    //         value: 'Underwriting',
    //         label: 'Underwriting',
    //         operate: []
    //     },
    //     {
    //         value: 'UW Approved',
    //         label: 'UW Approved',
    //         operate: []
    //     },
    //     {
    //         value: 'Docs drawn/out',
    //         label: 'Docs drawn/out',
    //         operate: []
    //     },
    //     {
    //         value: 'Funded',
    //         label: 'Funded',
    //         operate: []
    //     },
    //     {
    //         value: 'Recorded',
    //         label: 'Recorded',
    //         operate: []
    //     }
    // ]

    useEffect(()=>{
        getLoanProcessor()
    }, [])

    const statusList = () => {
        const options = Object.entries(com.loanStatusMap).map(([value, label]) => ({ value, label }))
        return options
    }

    const getAsIsValue = () => {
        if (props.property.purpose === "purchaserehab" && props.property.salesprice !== "") {
            return com.commaize(props.property.salesprice)
        }
        if (props.property.purpose !== "purchaserehab") {
            return com.commaize(props.hardmoney.refinancedetails.estimatedCurrentValue)
        }
        return "-"
    }

    const formItemMode = (formItem, fields, type) => {
        if (formItem && editMode) {
            return formItem
        } else {
            if (fields && fields !== '') {
                return <span>{type === 'money' && <span>$</span>}{fields}{type === 'months' && <span> Months</span>}{type === 'percentage' && <span>%</span>}</span>
            } else {
                return '--'
            }
        }
    }

    let rateResult = CalcHardMoneyLoanRate(props)

    const loanTermOptions = [
        {
          value: 12,
          label: '12 Months'
        },
        {
          value: 24,
          label: '24 Months'
        },
        {
          value: 36,
          label: '36 Months'
        },
        {
          value: 48,
          label: '48 Months'
        },
        {
          value: 60,
          label: '60 Months'
        }
    ]
    const modeChange = (checked) => {
        console.log(`switch to ${checked}`);
        setEditMode(checked)
    }
    const getLoanPurpose = () => {
        let state = JSON.parse(sessionStorage.getItem('state'))
        if (state.application.product.mortgageapplied === "hardmoneyloan") {
            return "Hard money loan"
        }
        if (state.application.property.purpose === "purchase" && state.application.property.hascontract===false) {
            return "Pre-qualification"
        }
        if (this.props.application.property.purpose === "purchase") {
            return "Purchase"
        } else if (this.props.application.property.purpose === "cashoutrefinance") {
            return "Cash-out refinance"
        } else {
            return "Refinance"
        }
    }
    const loanType = (type) => {
        const typeMap = {
            'fixflip': 'Fix / Flip',
            'fixrent': 'Fix / Rent',
            'bridge': 'Bridge',
            'groundup': 'Ground Up',
            'rental': 'Rental'
        }
        return typeMap[type]
    }
    const PropertyType = (type) => {
        const typeMap = {
            'singlefamilyattached': 'Single Family Residence (Attached)',
            'singlefamilydetached': 'Single Family Residence',
            'twofamily': '2 Unit',
            'threefamily': '3 Unit',
            'fourfamily': '4 Unit',
            'multistory': '5+ Multi',
            'condominium': 'Condo / PUD',
            'townhouse': 'Townhouse',
            'commercial': 'Commercial',
            'mixeduse': 'Mixed Use',
        }
        return typeMap[type]
    }
    
    const getLoanProcessor = () => {
        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/');
        fetch('/los/getloanprocessor', {
          body: JSON.stringify({LoanID: paths[paths.length - 1]}),
          method: 'POST',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                setLoading(false)
              return;
            }
            response.json().then(js => {
                setLoading(false)
                let data = js
                let team = [
                    {
                        name: `${data.CurrentLO.FirstName} ${data.CurrentLO.LastName}`,
                        role: 'Loan Officer',
                        avatar: ''
                    },
                    {
                        name: `${data.CurrentLP.FirstName} ${data.CurrentLP.LastName}`,
                        role: 'Processor',
                        avatar: ''
                    },
                    {
                        name: `${data.CurrentUnderwriter.FirstName} ${data.CurrentUnderwriter.LastName}`,
                        role: 'Underwriter',
                        avatar: ''
                    },
                    {
                        name: `${data.CurrentCloser.FirstName} ${data.CurrentCloser.LastName}`,
                        role: 'Closer',
                        avatar: ''
                    }
                ]
                console.log(team)
                setTeamList(team)
            })
          }
        )
          .catch(function (err) {
            setLoading(false)
            console.log('Fetch Error :-S', err)
          })
    }
    const handleStatusChange = (e) => {
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/');
        let body = { LoanID: paths[paths.length - 1], Key: "status", Value: e, IsAdmin: true }
        fetch('/los/updatepipeline', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
        }).then(response => {
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            return;
        }
        })
        .catch(function (err) {
            console.log('Fetch Error :-S', err)
        })
    }
    return (
        <div className="over-view text-left">
            <Spin spinning={loading}>
                <div className='over-view-address'>{props.property.address}</div>
                <Row className='over-view-topInfo'>
                    {/* <Col><span>Loan ID</span><span>0124-12-0035</span></Col>
                    <Col><span>Target Closing Date</span><span>08/01/2023</span></Col> */}
                    <Col>
                        <Select
                            className='status-select'
                            defaultValue={props.application.status}
                            onChange={e => handleStatusChange(e)}
                            options={statusList()}
                        />
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col xl={12} span={24}>
                        <div className='general'>
                            <div className='module-title'>General</div>
                            <div>
                                <div className='general-row'>
                                    <div>
                                        <div>Loan Purpose</div>
                                        <div>{getLoanPurpose()}</div>
                                    </div>
                                    <div>
                                        <div>Loan Type</div>
                                        <div>{loanType(props.application.hardmoneyloan.loantype)}</div>
                                    </div>
                                </div>
                                <div className='general-row'>
                                    <div>
                                        <div>Property Type</div>
                                        <div>{PropertyType(props.application.property.propertytype)}</div>
                                    </div>
                                    <div>
                                        <div>Rehab Loan</div>
                                        <div>{props.application.hardmoneyloan.rehabloan ? 'Yes' : 'No'}</div>
                                    </div>
                                </div>
                                <div className='general-row'>
                                    <div>
                                        <div>Primary Borrower</div>
                                        <div style={{color: '#326EEB', fontWeight: 600}}>{props.borrower.fullname}</div>
                                    </div>
                                    <div>
                                        <div>Co-Borrower</div>
                                        <div>{props.application.coborrower.firstname !== "" ? `${props.application.coborrower.firstname} ${props.application.coborrower.lastname}` : '-'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='team'>
                            <div className='module-title'>Yeelee Capital Team</div>
                            <div className='role-list'>
                                {
                                    teamList.map((item, index) => (
                                        <div className='role-list-item'>
                                            <div className='role-avatar'><img src='/images/default-avatar.svg' /></div>
                                            <div><div className='role'>{item.role}</div><div className='name'>{item.name !== ' ' ? item.name : 'Unassigned'}</div></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xl={10} span={24}>
                        <div className='loan-terms'>
                            <div className='confirm-loan-title'><span>Loan Terms</span><span className='status'>Confirmed</span></div>
                            <div className='loan-terms-card'>
                                <div className='loan-terms-item top'>
                                    <div>
                                        <span>Loan Amount</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        prefix="$"
                                                        defaultValue={getLoanAmount(props)}
                                                        min={0}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , getLoanAmount(props), 'money')
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <span>{props.property.purpose === "purchaserehab" ? "As-Is-Value" : "Current property value"}</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        prefix="$"
                                                        defaultValue={getAsIsValue()}
                                                        min={0}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , getAsIsValue(), 'money')
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <span>Loan-to-Value</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        defaultValue={getLTV(props)}
                                                        min={0}
                                                        max={100}
                                                        formatter={(value) => `${value}%`}
                                                        parser={(value) => value.replace('%', '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , getLTV(props), 'percentage')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='loan-terms-item middle'>
                                    <div>
                                        <span>Interest Rate</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        defaultValue={rateResult.floorRate}
                                                        min={0}
                                                        max={100}
                                                        formatter={(value) => `${value}%`}
                                                        parser={(value) => value.replace('%', '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , rateResult.floorRate, 'percentage')
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <span>Monthly Payment</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        prefix="$"
                                                        defaultValue={com.commaize(rateResult.monthlyPayment)}
                                                        min={0}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , com.commaize(rateResult.monthlyPayment), 'money')
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <span>Loan Term</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <Select
                                                        defaultValue={props.hardmoney.mortgagetermmonths}
                                                        allowClear
                                                        onChange={e => console.log(e)}
                                                        options={loanTermOptions}
                                                        size="small"
                                                        style={{width: '90px'}}
                                                    />
                                                , props.hardmoney.mortgagetermmonths, 'months')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='loan-terms-item bottom'>
                                    <div>
                                        <span>Processing Fee</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        prefix="$"
                                                        defaultValue={1000}
                                                        min={0}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , 1000, 'money')
                                            }
                                        </div>
                                    </div>
                                    <div><span>Origination Point</span><div><span>${(rateResult.point * 0.01 * calcLoanAmount(props)).toFixed(2) }</span></div></div>
                                </div>
                            </div>
                            <div className='loan-terms-mode'><Switch checked={editMode} onChange={modeChange} /><span style={{marginLeft: 10}}>Edit mode</span></div>
                        </div>
                    </Col>
                </Row>
                {contextHolder}
                {/* <Modal centered title="Contact my loan officer" 
                    open={showContact}  
                    onCancel={()=>setShowContact(false)}
                    footer={[
                        <Button key="back" onClick={()=>setShowContact(false)}>
                        Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={sendMail}>
                        Send
                        </Button>,
                    ]} >
                <div>
                    <TextArea rows={4} placeholder="Leave your message here" onChange={e=>setContactMessage(e.target.value)}/>
                </div>
                </Modal> */}
                {/* <div className='over-view-content'>
                    <div className='application-status'>
                        <div className='status-title' onClick={()=>setShowContact(true)}>Application Status </div>
                        <div className='status-list'>
                            {
                                statusList.map((status, statusIndex) => 
                                    <div className='status-item' key={statusIndex}> 
                                        <div style={{color: props.step > statusIndex ? '#1F3988' : '#8895BC'}}>{status.name}</div>
                                        {
                                            status.operate.length > 0 && (
                                                <div className='operate' style={{color: props.step > statusIndex ? '#0F62FE' : ''}}>
                                                    {
                                                        status.operate.map((o, index) => o)
                                                    }
                                                </div>
                                            )
                                        }
                                        <div className='status-tag' style={{backgroundColor: props.step > statusIndex ? '#0F62FE' : '#DDE1E6'}}></div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='my-loan-terms'>
                        <div className='loan-terms-title'><span>My Loan Terms</span><span>Estimated {props.hardmoney.mortgagetermmonths} months</span></div>
                        <div className='loan-terms'>
                            <div className='loan-terms-item'><span>Loan Amount</span><span>{getLoanAmount(props)}</span></div>
                            <div className='loan-terms-item'><span>As-Is-Value</span><span>{getAsIsValue()}</span></div>
                            <div className='loan-terms-item'><span>Loan-to-Value</span><span>{getLTV(props)}</span></div>
                        </div>
                        <div className='loan-terms'>
                            <div className='loan-terms-item'><span>Estimated Interest Rate</span><span>{rateResult.floorRate}%</span></div>
                            <div className='loan-terms-item'><span>Estimated Monthly Payment</span><span>${com.commaize(rateResult.monthlyPayment)}</span></div>
                            <div className='loan-terms-item'><span>Estimated Closing Costs</span><span>${com.commaize(rateResult.closingCost)}</span></div>
                        </div>
                        <div className='loan-terms'>
                            <div className='loan-terms-item'><span>Target Closing Date</span><span>{props.hardmoney.targetclosingdate!=="" ? props.hardmoney.targetclosingdate : "-"}</span></div>

                        </div>
                    </div>
                </div> */}
            </Spin>
        </div>
    )
}

Overview = connect(mapStateToProps, mapDispatchToProps)(Overview);
