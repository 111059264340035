import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import HorizontalSliderV2 from "../../Common/HorizontalSliderV2";
import "../../App.css";
import "./RateQuote.css";
import * as com from "../../Common.js";
import {CommaizedInput} from "../../NumericalInput";
import { withRouter } from "react-router-dom";

import Spinner from 'react-bootstrap/Spinner'
import { Tips } from "../../Common/Tooltip";
import { Divider, Input } from 'antd';
import Warning from "../../Common/components/Warning";
import { calculateMonthlyPayment } from "./common";

const GMCCUniverseCalculator = (props) => {
  const [loanLimits, setLoanLimits] = useState({});
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");
  const [units, setUnits] = useState("1");
  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(200000);
  const [county, setCounty] = useState("SANTA CLARA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [enoughReserves, setEnoughReserves] = useState(false);
  const [docSignOutside, setDocSignOutside] = useState(false);
  const [shortSale, setShortSale] = useState(false);
  const [adjPoint, setAdjPoint] = useState(0);

  const [loading, setLoading] = useState(false);
  const [fico, setFico] = useState(780);
  const [currentMtype, setCurrentMtype] = useState("fixed30")


  const [message, setMessage] = useState("");

  const qualifiedCounties = {
    "CALIFORNIA": ["SAN FRANCISCO", "ALAMEDA", "SAN MATEO", "SANTA CLARA", "SACRAMENTO", "CONTRA COSTA","LOS ANGELES", "ORANGE", "RIVERSIDE", "SAN BERNARDINO", "SAN DIEGO"],
    "GEORGIA": ["BARROW", "BARTOW", "CHEROKEE", "CLAYTON", "COBB", "DEKALB", "DOUGLAS", "FAYETTE", "FORSYTH", "FULTON", "GWINNETT HENRY", "PAULDING", "ROCKDALE", "SPALDING"],
    "MASSACHUSETTS": ["MIDDLESEX", "NORFOLK", "SUFFOLK"],
    "NEVADA": ["CLARK"],
    "TEXAS": ["COLLIN", "DALLAS", "FORT BEND", "HARRIS"],
    "WASHINGTON": ["KING", "PIERCE", "SNOHOMISH"],
    "NEW YORK": ["YORK BRONX", "KINGS", "NEW YORK", "QUEENS", "RICHMOND"]
  }
  
  const form = useRef();

  let getLtv = () => {
    let ltv = Math.ceil((getLoanAmount() / parseInt(propertyValue)) * 100);
    return [ltv, ltv, ltv];
  };


  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };


  const getLoanLimits = () => {
    setLoading(true);
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    };

  const ref = useRef();
  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });

  useEffect(() => {
    const controller = new AbortController()

    getLoanLimits(controller);

  }, []);


  let showCounties = () => {
    let options = [<option value=""></option>];
    if (state in loanLimits) {
      let keys = Object.keys(loanLimits[state]);
      
      keys.forEach((x) => {
        options.push(<option value={x}> {com.capitalize(x)}</option>);
      });
    }
    return options;
  };

  let showStates=() =>{
    let options = [<option value=""></option>];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  };


  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }

    if (typeof qualifiedCounties[state] === "undefined" || !qualifiedCounties[state].includes(county.replace("COUNTY", "").trim())) {
      setMessage(<div>
        <div className="title-3 mb-3">The program is not available in {state}, {county}</div>
        <div>The program is only available in the following areas:</div>
              {
                Object.keys(qualifiedCounties).map((state, i) => {
                    return <div key={i}>{com.capitalize(state)}: {com.capitalize(qualifiedCounties[state].join(", "))}</div>
                  }
                )
              }
      </div>);
      return;
    } else {
      setMessage("");
    }

    if (fico<680) {
      setMessage("The program is not available for credit score below 680");
      return;
    } else {
      setMessage("");
    }

    let ltv = getLtv();
    if (ltv[0] > 60) {
      setMessage("The program is not available for LTV above 60%");
      return;
    } else {
      setMessage("");
    }

    let loanAmount = getLoanAmount()
    if (loanAmount > 2000000) {
      setMessage("The maximum loan amount is $2,000,000");
      return;
    } else {
      setMessage("");
    }    

    return false;
  };




  let showButtons = () => {
    let buttons = []
    let termMap = {
      fixed30:"30 Year",
      fixed15:"15 Year",
      arm56:"ARM 5/1",
      arm106:"ARM 10/1",
    }
    for (let mtype in termMap) {
      const buttonStyle = mtype === currentMtype ? "rate-quoter-mtype-selected" : "rate-quoter-mtype-unselected";
      buttons.push(<Button variant={buttonStyle} id={mtype} onClick={()=>setCurrentMtype(mtype)}>{termMap[mtype]}</Button>)
    }


    return (<div className="rate-quoter-term-selector pt-2 pb-2">
      <div className="rate-quoter-term-rates" >Rates as of October 21th, 2024</div>
      <div className="rate-quoter-term-buttons">
        {buttons}
      </div>
    </div>)
  };

  let generateTableData = () => {
    let fixedBasePrice = [
      [6.625, 1.375],
      [6.75, 1.125],
      [6.875, 0.875],
      [7, 0.625],
      [7.125, 0.25],
    ]
    let armBasePrice = [
      [6.375, 1.375],
      [6.5, 1.125],
      [6.625, 0.875],
      [6.75, 0.625],
      [6.875, 0.25],
    ]
    let basePrice = [...fixedBasePrice]
    if (currentMtype === "fixed15") {
      basePrice = basePrice.map((d) => {
          return [d[0]-0.25, d[1]]
        }
      )
    } else if (currentMtype === "arm56" || currentMtype === "arm106") {
      basePrice = armBasePrice
    }
    if (adjPoint!==0) {
      basePrice = basePrice.map((d) => {
          return [d[0], d[1]+adjPoint]
        }
      )
    }

    let data = basePrice.map((d) => {
      return {
        rate: d[0].toFixed(3) + "%",
        // apr: d[0].toFixed(3) + "%",
        monthlyPayment: "$" + com.commaize(calculateMonthlyPayment(getLoanAmount(), d[0], 30).toFixed(0)),
        points: d[1]+"%",
      }
    })


    return data

  }

  let showRatesForMtype = () => {


    let columns = [
      {
        dataField: "rate",
        text: "Rate",
        formatter: (cell, row) => {return <span><strong>{cell}</strong></span>}
      },
      // {
      //   dataField: "apr",
      //   text: "APR",
      // },
      {
        dataField: "points",
        text: "Point Cost",
        headerFormatter: (column, index) => {
          return <div className="d-flex justify-content-center">
          <div >{column.text}</div>
          <Tips
          placement="top"
          title=""
          content={
            <div>
              A positive number means you have to pay lender to get the lower rates. A negative number means lender will give you a credit to help you with the closing costs.
            </div>
          }
        /></div>
        }
      },
      {
        dataField: "monthlyPayment",
        text: "Monthly Pay",
      },
    ]
    let data = generateTableData()
    return (
      <div className="rate-quoter-mtype-table">
        {data.length === 0 ? <div className="ratesWarning">
          <Row className="justify-content-md-center">
            <Col xs={1}>
              <img
                src="/images/warningSign.png"
                style={{ maxWidth: "20px" }}
              />
            </Col>
            <Col xs={11}>No qualified products for this mortgage type.</Col>
          </Row>
        </div> : <BootstrapTable
          bootstrap4
          keyField="rate"
          columns={columns}
          data={data}
          bordered={false}
          striped={false}
          // rowStyle={rowStyle}
          headerClasses={props.isFromLanding?"landing-rate-quoter-header":"rate-quoter-header"}
          rowClasses='rate-quoter-row'
          hover
          condensed
        />}
        
      </div>
    )
  }

  const updateProperValue = (e) => {
    let val = e.target.value
    if (val==="") {
      setPropertyValue(100000)
    } else {
      setPropertyValue(parseInt(val))
    }
  }

  const updateDownpayment = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(0)
    } else {
      setDownpayment(parseInt(val))
    }
  }

  const updateLTV = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(0)
    } else {
      let p = parseInt(val)
      setDownpayment(Math.round(propertyValue * 0.01 * p))
    }
  }

  const getDownpaymentDomain = () => {
    if (purpose === "Purchase") {
      return [propertyValue*0.03, propertyValue]
    } else {
      return [0, propertyValue*0.97]
    }
  }


  return (
    <div
      ref={ref}
      style={{ color: "black" }}
      className="w-100 text-center rateQuoteBorrowerVersion"
    >
{     props.hideTitle !== true && <div style={{padding:"12px 70px 0px 70px"}} className="text-left mt-3">
        <div style={{fontWeight:"600",textAlign:"left",fontSize:"16px"}}>Rates & Pricing</div>
        <div style={{textAlign:"left",fontSize:"16px",fontWeight:"400"}}>Explore rates and pricing of GMCC Universe program.</div>
        <Divider />
      </div>}
      <Container  className="py-1">
        <Col className="text-center" md="auto">
          <Form
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Col md xs={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>State</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    id="state"
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                    required
                  >
                    {showStates()}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md xs={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    County
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    id="country"
                    name="country"
                    onChange={(e) => setCounty(e.target.value)}
                    value={county}
                    required
                  >
                    {showCounties()}
                  </Form.Control>
                </Form.Group>
              </Col>
              
              <Col md xs={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Credit Score
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="input"
                    onChange={(e) =>{
                      let f = parseInt(e.target.value === "" ? 0 : e.target.value)
                      if (fico>=620 && f < 620) {
                        setAdjPoint(adjPoint + 1)
                      } else if (fico<620 && f >= 620) {
                        setAdjPoint(adjPoint - 1)
                      } else if (fico>679 && f <= 679) {
                        setAdjPoint(adjPoint + 0.5)
                      } else if (fico<=679 && f > 679) {
                        setAdjPoint(adjPoint - 0.5)
                      }

                      setFico(f)
                    }}
                    value={fico}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>

            </Form.Row>  
            <Form.Row>

              <Col md={4} xs={12}>
                <Form.Group controlId="units" className="text-left">
                  <Form.Label style={{ marginBottom: "0" }}>Number of units</Form.Label>
                  <Form.Control
                      required
                      name="units"
                      as="select"
                      size="md"
                      defaultValue={units}
                      onChange={(e) => setUnits(e.target.value)}
                  >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                  </Form.Control>
                  </Form.Group>
              </Col>
              <Col md={4} xs={12}>
              <Form.Group controlId="occupancy" className="text-left ">
                    <Form.Label className="text-left " style={{ marginBottom: "0" }}>Occupancy</Form.Label>
                    <Form.Control
                      name="occupancy"
                      as="select"
                      size="sm"
                      defaultValue={occupancy}
                      onChange={(e) => {
                        onOccupancy(e.target.value);
                      }}
                    >
                      <option value="principal">Primary Residence</option>
                      <option value="secondhome">Second Home</option>
                      <option value="investment">Investment</option>
                    </Form.Control>
                  </Form.Group>
              </Col>
              <Col md={4} xs={12}>
              <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Loan Purpose
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    onChange={(e) => setPurpose(e.target.value)}
                    required
                  >
                    <option value="Purchase">Purchase</option>
                    <option value="RateTermRefi">Refinance</option>
                    <option value="CashOutRefi">Cash Out Refinance</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>

              <Col xs={12} md={4} lg={4}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    {purpose === "Purchase"
                      ? "Purchase Price"
                      : "Home Value"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign" style={{fontSize:"16px", marginTop:"10px"}}>$</div>
                    {/* <div className="rateQuoterFormText">{"$ " + com.commaize(propertyValue)}</div> */}
                    <CommaizedInput style={{border:"none", fontSize:"16px", marginTop:"2px", maxHeight:"40px"}} value={propertyValue} onChange={updateProperValue} />
                  </div>
                </Form.Group>
              </Col>

              <Col xs={11} md={4} lg={4} style={{display:"-webkit-inline-box"}}>
                <Form.Group className="text-left rate-quoter-downpay">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0" }}
                  >
                    {purpose === "Purchase"
                      ? "Down Payment"
                      : "Mortgage Balance"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign" style={{fontSize:"16px", marginTop:"10px"}}>$</div>
                    {/* <div className="rateQuoterFormText">{"$ " + com.commaize(downpayment)}</div> */}
                    <CommaizedInput style={{border:"none", fontSize:"16px",marginTop:"2px", maxHeight:"40px"}} value={downpayment} onChange={updateDownpayment} />
                    </div>
                  </Form.Group>
                  {purpose === "Purchase" ?  <Input className="rate-quoter-ltv" suffix="%" defaultValue={100-getLtv()[0]} value={100-getLtv()[0]} onChange={e => updateLTV(e)} type="number" style={{width:90,borderRadius: "4px"}}/> :
                    <Input className="rate-quoter-ltv" suffix="%" defaultValue={getLtv()[0]} value={getLtv()[0]} onChange={e => updateLTV(e)} type="number" style={{width:90,borderRadius: "4px"}}/>
                  }
              </Col>
              </Form.Row>
              <Form.Row>
              <Col className="text-left">
                <Form.Check type="checkbox" className="mb-1"
                  checked={enoughReserves} 
                  onChange={(e)=>{
                    if (enoughReserves === false && e.target.checked === true) {
                      setAdjPoint(adjPoint - 0.125)
                    }
                    if (enoughReserves === true && e.target.checked === false) {
                      setAdjPoint(adjPoint + 0.125)
                    }
                    setEnoughReserves(e.target.checked)
                  }} 
                  label="Have more 6+ months reserves deposit into investor bank with ACH autopay" />
                <Form.Check type="checkbox" className="mb-1"
                  checked={docSignOutside} 
                  onChange={(e)=>{
                    if (docSignOutside === false && e.target.checked === true) {
                      setAdjPoint(adjPoint + 0.25)
                    }
                    if (docSignOutside === true && e.target.checked === false) {
                      setAdjPoint(adjPoint - 0.25)
                    }
                    setDocSignOutside(e.target.checked)
                  }} 
                  label="Doc signing outside U.S." />
                <Form.Check type="checkbox" 
                  checked={shortSale} 
                  onChange={(e)=>{
                    if (shortSale === false && e.target.checked === true) {
                      setAdjPoint(adjPoint + 0.5)
                    }
                    if (shortSale === true && e.target.checked === false) {
                      setAdjPoint(adjPoint - 0.5)
                    }
                    setShortSale(e.target.checked)
                  }} 
                  label="Short sale or foreclosure within last 5 years" />
              </Col>
            </Form.Row>
              <Form.Row>
                <Col>
                  <Button type="submit" variant="rate-quoter-update" style={{marginTop:"10px"}}>
                      Check rates
                  </Button>
                </Col>
              </Form.Row>
           
          </Form>
        </Col>
      </Container>
  
      {loading ? (
        <Row>
          <div className="text-center w100 justify-content-center  align-content-center">
            <div id="spinner" className="pt-5 mt-4 ml-5 text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        </Row>
      ) : (
        <Container className="pl-0 pr-0">
          { message === "" ? (
            <div>
              <div>{showButtons()}</div>
              <div>{showRatesForMtype()}</div>
            </div>
            

          ) : (
            <div>
              <Warning content={message} />
            </div>
          )}
        </Container>
      )}


    </div>
  );
};
export default withRouter(GMCCUniverseCalculator);
