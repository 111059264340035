import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import './ApplicationInterview.css'
import './EmailVerification.css'
import CodeVerification from "../Common/components/CodeVerification"
import { connect } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import { checkAccessByToken } from "../Auth"
import { UR_PriceEngineUser } from "../State"
import { Modal, Button } from "antd"
import { RightOutlined } from "@ant-design/icons"

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeFirst: (application) => {

        dispatch({
            type: "OPEN",
            data: application,
        })
    },
})

class EmailVerification extends Component {

    constructor(props) {
        super(props)
        this.state = {
            code: "",
            verified: false,
            showResult: false,
            verifingCode: false,
            sendingCode: false,
            selectedLoanId: "",
            currentLoanId: "",
            loading: false,
            showChooseCompany: false,
            loCompanies: {},
        }
        this.isPrequal = this.props.application.property.purpose === "purchase" && !this.props.application.property.hascontract
        this.isHardMoneyLoan = this.props.application.product.mortgageapplied === "hardmoneyloan"
    }

    sendcode = () => {
        this.setState({ sendingCode: true })
        fetch("/los/generateonetimepasscode", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                Email: this.props.email,
                customerID: this.props.customerid,
            }),
            headers: {
                'Content-Type': 'application/json',
                Cache: "no-cache"
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                this.setState({ sendingCode: false })
                return
            }
            this.setState({ sendingCode: false })

        }).catch(error => {
            console.log(error)
            this.setState({ sendingCode: false })
        })
    }

    loginAndRedirect = (token) => {
        sessionStorage.setItem("ZeitroA", token)
        sessionStorage.removeItem("state")
        sessionStorage.removeItem("originalstate")
      
        let path = "/app/welcome?signUp=1"
        if (checkAccessByToken(token, [UR_PriceEngineUser])) {
            path = "/app/purchasecalculator"
        }
        this.setState({ showResult: true, verified: true, verifingCode: false, code: "" })
        window.location.href = path
        // this.props.updateChangeFirst(true)
        // setTimeout(() => {
         
        // }, 500)
    }

    verifycode = (code) => {
        this.setState({ verifingCode: true })

        let url = "/los/verifycodeandsignin"
        fetch(url, {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                Email: this.props.email,
                Code: code,
                CustomerID: this.props.customerid,
            }),
            headers: {
                Cache: "no-cache"
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                this.setState({ showResult: true, verified: false, verifingCode: false, code: "" })
                return
            }
            response.json().then(js => {

                if (js.Status !== "OK") {
                    console.log(js)
                    this.setState({ showResult: true, verified: false, verifingCode: false, code: "" })

                } else {

                    if (js.Token === '' && js.State === "{}") {
                        this.editEmail(2)
                    } else if (js.Token !== '' && js.State === "{}") {
                        this.loginAndRedirect(js.Token)
                    } else if (js.Token === '' && js.State !== "{}") {
                        this.setState({ showChooseCompany: true, loCompanies: JSON.parse(js.State) })
                    }
                }
            })

        }).catch(error => {
            console.log(error)
            this.setState({ code: "" })
        })
    }

    componentDidMount() {
        this.sendcode()
    }


    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    handleSubmit = e => {
        if (!this.state.verified) {
            return false
        }
        return true

    }
    editEmail = (type) => {
        this.props.back(type)
    }
    render() {
        const showErrorMeesage = () => {
            return (
                <div className="d-flex justify-content-center">

                    <div style={{ backgroundColor: "#FFE6BF", borderRadius: "4px", padding: "10px", marginTop: "15px", maxWidth: "350px" }}>
                        <img src="/images/warningSignYellow.png" style={{ width: "18px", height: "18px", marginRight: "10px" }} />
                        <a>The code is incorrect or expired.</a>
                    </div>
                </div>
            )
        }


        if (this.state.showResult && this.state.verified) {
            return (
                <div className="text-center mb-3">
                    <img src="/images/emailSuccess.png" style={{ width: "80px", height: "78px" }} />
                    <div className="prequalBigText">Succeed!</div>
                    <div className="prequalSmallText pt-2">
                        <div>Your verification was successful.</div>
                        <div>Continue to your application.</div>

                    </div>

                </div>
            )
        }
        return (
            <div className="text-center mb-3">


                <div><img src="/images/checkEmail2.png" style={{ width: 66, height: "auto", marginBottom: '23px' }} /></div>
                <div style={{ color: '#222', fontSize: 26, fontFamily: 'Playfair Display', lineHeight: '35px', fontWeight: 700 }} className="prequalBigText prequalCheckText">Check your email</div>
                {/* <div className="prequalSmallText pt-2 text-wrap">Please enter the 4 digit code sent to {this.props.email}</div> */}
                {
                    this.props.email && (
                        <div style={{ margin: '6px auto 18px', color: '#6E6E70', lineHeight: '20px' }}>
                            <div>We sent a code to <span style={{ fontWeight: 600, color: '#222' }}>{this.props.email}</span><a style={{ color: '#325CEB', marginLeft: 8, textDecorationLine: 'underline' }} className="prequalEditText" onClick={() => { this.editEmail('') }}>Edit</a></div>
                            <div>Enter the code to log in your account</div>
                        </div>
                    )
                }
                <div style={{ display: "flex", justifyContent: "center", padding: "20px 0 0 10px" }}>
                    {(this.state.verifingCode || this.state.sendingCode) ? <Spinner animation="border" size="lg" /> : <CodeVerification value={this.state.code} onChange={(e) => {
                        this.setState({ code: e })
                        if (e.length === 4) {
                            this.verifycode(e)
                        }
                    }} />}

                </div>
                {(this.state.showResult && !this.state.verified) && showErrorMeesage()}
                <div style={{ fontSize: 16 }} className="p-2">Didn’t receive the email? <a style={{ color: "#325CEB", textDecorationLine: "underline" }} onClick={() => {
                    this.sendcode()
                }}>Resend</a></div>
                {/* <div style={{fontSize: 16}} className="pb-2">or <a style={{color:"#325CEB", textDecorationLine: "underline"}} onClick={()=>{this.editEmail(2)}}>Sign in with password</a></div> */}

                <Modal title="Join the following orginizations" open={this.state.showChooseCompany}
                    footer={
                        <Button type="primary" onClick={() => {
                            this.editEmail('')
                            this.setState({ showChooseCompany: false })
                        }
                        }>Re-enter email</Button>
                    }
                    cancelText="Re-enter email"
                    onCancel={() => {
                        this.editEmail('')
                        this.setState({ showChooseCompany: false })
                    }
                    }>
                    <div className="ft-500">{this.props.email} is associated with accounts at the following orginizations, please select one to log in.</div>
                    {Object.keys(this.state.loCompanies).map((key) => {
                        if (this.state.loCompanies[key].token === "") return ""
                        return <div className="chooseCompany" onClick={() => {
                            this.loginAndRedirect(this.state.loCompanies[key].token)
                        }}>
                            <div>{this.state.loCompanies[key].customerName}</div>
                            <RightOutlined />
                        </div>
                    }
                    )}

                </Modal>
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EmailVerification)
