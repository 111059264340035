import { TOGGLE_COLUMN, INITIALIZE_LAYOUT } from '../Defs/pipelinecolumnsdefs'

// doesn't make sense to create a yaml definition

const defaultState = { // keys match dataFields from Borrowers.js
  errors: false,
  comments: false,
  accountCreated: true,
  lender: true,
  interviewSubmission: true,
  loanRegisteredWithLender: false,
  LERequested: false,
  threeDeathDays: false,
  initialDisclosresIntentProceedBack: false,
  loanRunThroughDO: false,
  openedEscrow: false,
  escrowFeesReceived: false,
  prelimReceived: false,
  documentsRequested: false,
  documentsReceived: false,
  loanProcessed: false,
  rateLocked: false,
  lockExpires: false,
  confirmedLockWithBorrower: false,
  loanPackageSubmitted: false,
  loanDecisioned: false,
  piwAce: false,
  appraisalOrdered: false,
  ptdsSignedOff: false,
  CDOrdered: false,
  CDOut: false,
  docsOrdered: false,
  docsOut: false,
  docsScheduled: false,
  docsSigned: false,
  docsBack: false,
  ptfsSubmitted: false,
  ptfsSignedOff: false,
  loanFunded: false,
  doLpa: false,
  loanAmount: true,
  totalBrokerCompensation: false,
  appraisalReceived: false,
  ptdsSubmitted: false,
  estimatedClosingDate: false,
  submittedToUW: false,
  lockRequested: false,
  lenderCaseNumber: false,
  clearToClose: false,
  CDAcknowledged: false,
  appraisalBack: false,
  conditionsRequested: false,
  conditionsSubmitted: false,
  disbursementDate: false,
  scheduledDisbursementDate: false,
  status: true,
  substatus: true
}

export default function pipelineColumnsReducer(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_COLUMN: {
      state[action.column] = action.payload
      break;
    }
    case INITIALIZE_LAYOUT: {
      state = action.configuration
      break;
    }
    default:
      break;
  }


  return JSON.parse(JSON.stringify(state))
}