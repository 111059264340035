import React from 'react';
import CompanyInfo from './Company/Company';


const Customization = () => {
  return (
    <div >
        <CompanyInfo />
    </div>
  );
}

export default Customization;