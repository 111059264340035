import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import PopUpCompany from '../../../Management/Customization/Company/PopUpCompany'
export default function StepThree(props) {

    return (
        <>
            <div className='ModalTitle'>
                Company information
            </div>
            <div style={{ marginTop: 15, fontSize: 16, fontFamily: "Inter", fontWeight: 400 }}>
                Enter your company information here to display it in various feature outputs. All fields are <span style={{

                    fontWeight: 600
                }}>
                    optional

                </span>  for now and can be added later under <span style={{

                    fontWeight: 600
                }}>
                    Management {'>'}  Company Profile.

                </span>
            </div>
            <div style={{ maxHeight: 500, overflowY: 'auto', overflowX: "hidden" }}>
                <PopUpCompany></PopUpCompany>
            </div></>

    )
}
