import React, { Component } from "react";
import '../App.css';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';

import { connect } from 'react-redux'
import { UpdateChangeProgress } from "../Store/progress"
import { getCurrentState } from '../Store'
import * as com from "../Common.js"
import Loan from './Loan'
import Proposed from './Proposed'
import Fees from './Fees'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import LoanOverview from "./LoanOverview";
import LoanComments from "../LoanComments";

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower        
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
});
class LoanSummary extends Component {
    returnTrue = () => true

    constructor(props) {
        super(props);
        this.wizard = []
        let r = React.createRef()
        this.wizard.push([<LoanOverview ref={r} />, this.returnTrue, r])
        // r = React.createRef()
        // this.wizard.push([<Proposed  borrowerid={this.props.borrowerid}  ref={r} who={com.borrower} />, this.returnTrue, r ])
        r = React.createRef()
        this.wizard.push([<Loan  ref={r} />, this.returnTrue, r])

        if (this.props.application.selection.selectedloan.hash !== "") {   
            r = React.createRef()
            this.wizard.push([<Fees  ref={r} who={com.borrower} />, this.returnTrue, r])
        }
 
        this.form = React.createRef();
        let index = 0;

        if (this.props.progress.stage === "loinput") {
            index = Math.min(this.wizard.length - 1, parseInt(this.props.progress.loinput.step))
        }


        this.state = {
            currentIndex: index,
            currentPane: this.wizard[index][0],
            hiddenPrevious: (index === 0),
            hiddenNext: (index === this.wizard.length - 1),
            validated: false
        }
    }
    oninit = (callback) => {
        this.submit = callback
    }
    previous = () => {

        let newindex = Math.max(this.state.currentIndex - 1, 0);
        for (; ;) {
            let [, check] = this.wizard[newindex]
            if (check())
                break;
            newindex -= 1
            if (newindex === 0) //end of line
                break;
        }

        this.setState({ currentIndex: newindex });
        this.setState({ validated: false });
        this.setState({ currentPane: this.wizard[newindex][0], hiddenNext: (newindex === this.wizard.length - 1) });
        this.props.updateChangeProgress(newindex, "loinput")
        if (newindex === 0)
            this.setState({ hiddenPrevious: true });
        getCurrentState({application: this.props.application, borrower: this.props.borrower}, sessionStorage.getItem("originalstate"))            
    }
    next = () => {
        let newindex = Math.min(this.state.currentIndex + 1, this.wizard.length - 1);
        for (; ;) {
            let [, check] = this.wizard[newindex]
            if (check())
                break;
            newindex += 1

            if (newindex === this.wizard.length - 1) //end of line
                break;
        }
        console.log("IN NEXT")
        this.setState({ currentIndex: newindex, hiddenNext: (newindex === this.wizard.length - 1) });
        this.setState({ currentPane: this.wizard[newindex][0] });

        this.props.updateChangeProgress(newindex, "loinput")

        if (newindex !== 0)
            this.setState({ hiddenPrevious: false });
     
        getCurrentState({application: this.props.application, borrower: this.props.borrower}, sessionStorage.getItem("originalstate"))            

    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    handleSubmit = (event) => {

        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        
        let child = this.wizard[this.state.currentIndex][2].current
        if (child && child.handleSubmit) {
            if (!child.handleSubmit(event)) {
                event.preventDefault();
                event.stopPropagation();
                this.setState({ validated: true })
                return false;
            }
        }


        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();
        this.next()
        return true
    }
    rollback = () => {
        this.setState({ currentIndex: 0 });
        this.setState({ currentPane: this.wizard[0][0], validated: false, hiddenPrevious: true, hiddenNext: false, });
        this.props.updateChangeProgress(0, "loinput")
        getCurrentState({application: this.props.application, borrower: this.props.borrower}, sessionStorage.getItem("originalstate"))            

    }
    componentDidMount() {
        // sessionStorage.setItem("edit", true)
    }
    render() {
        return (
                <div className="text-center mt-0 pb-5 text-nowrap blackish">
                    <Form ref={this.form} id="personalform" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                        <Row >
                            <Col className="text-center pt-4">
                                {this.state.currentPane}
                            </Col>
                        </Row>
                        <Row className="text-center ">
                            <Col xs lg="1" className="text-left ml-5 pl-5">
                                <div hidden={this.state.currentIndex === 0} className="ml-2" onClick={this.rollback}><Button variant="primary" >&lt;&lt;</Button></div>
                            </Col>
                            <Col className="text-right" md-auto="true">
                                <Button variant="primary" hidden={this.state.hiddenPrevious} className="mr-3" onClick={this.previous} > Previous</Button>
                            </Col>
                            <Col className="text-left" md-auto="true">
                                <Button variant="primary" type="submit" hidden={this.state.hiddenNext} className="text-center ml-3"  >&nbsp;&nbsp;Next&nbsp;&nbsp;</Button>
                            </Col>
                            <Col xs lg="1" className="text-left mr-5 pr-5">
                                <div hidden={this.state.currentIndex === 0}>&nbsp;</div>
                            </Col>
                        </Row>
                    </Form>
                </div>

        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoanSummary)
