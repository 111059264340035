import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import NumericalInput from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"
import {tooltip} from "../Common/Tooltip"

export function getTotalExpenses(who) {
    let total = 0
    let getExpence = (value, monthly) => {
        value = parseFloat(value)
        if(monthly === "monthly")   
            return value
        else
            
    return value/12
    }
    
    if( this.props.application.property.purpose === "purchase" && 
        this.props.application[who].presentaddressownership === "rent" ) {
            total += getExpence(this.props.application[who].expenses.currentrent, this.props.application[who].expenses.currentrentperiod)
    }

    if( (this.props.application[who].presentaddressownership === "own" && 
        this.props.application.property.purpose === "purchase" ) || 
        this.props.application.property.purpose !== "purchase") {

            total += getExpence(this.props.application[who].expenses.currentfirstmortgage, 
                this.props.application[who].expenses.currentfirstmortgageperiod)

            total += getExpence(this.props.application[who].expenses.currentotherfinancing, 
                this.props.application[who].expenses.currentotherfinancingperiod)

            total += getExpence(this.props.application[who].expenses.currenthazardinsurance, 
                this.props.application[who].expenses.currenthazardinsuranceperiod)

            total += getExpence(this.props.application[who].expenses.currentmortgageinsurance, 
                this.props.application[who].expenses.currentmortgageinsuranceperiod)

            total += getExpence(this.props.application[who].expenses.currentrealestatetaxes, 
                this.props.application[who].expenses.currentrealestatetaxesperiod)

            total += getExpence(this.props.application[who].expenses.currentdues, 
                this.props.application[who].expenses.currentduesperiod)

            total += getExpence(this.props.application[who].expenses.currentother, 
                this.props.application[who].expenses.currentotherperiod)
                    
        }
    return com.commaizeFloat(total.toFixed(2))
}
const mapStateToProps = (state) => {
    return {
        application: state.application,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event.target.value, who, verb))
    },
});

class Expenses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validated: false,
        }
        this.isValidated = this.isValidated.bind(this);
        // see if you need to seed the defaults for 
        let firstmortgage = 0
        let otherfinancing = 0
    
        if(this.props.who === com.borrower) {
      
            for(let index = 0; index < this.props.application.assetsandliabilities.loans.length; index ++) {
                let loan = this.props.application.assetsandliabilities.loans[index]
                // don't want to do this for a coborrower until we see it
                //if( (loan.attribution & 1) === 0)
                //    continue
                if (loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0) {
                    if(loan.forsubjectproperty === true ) {
                        if(firstmortgage === 0)
                            firstmortgage += parseInt(loan.monthlypayment)
                        else
                            otherfinancing += parseInt(loan.monthlypayment)
                    }
                } else {

                    if ( loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                        loan.creditloantype.toLowerCase().indexOf("realestate") >= 0 ) {
                            if(loan.forsubjectproperty === true)
                                otherfinancing += parseInt(loan.monthlypayment)
                    }

                }
            }

            for(let i = 0; (this.props.application.otherloans != null) && (i <  this.props.application.otherloans.length); i++) {
                let loan = this.props.application.otherliens[i]
                if(loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                    if(firstmortgage === 0)
                        firstmortgage += parseInt(loan.monthlypayment)
                    else
                        otherfinancing += parseInt(loan.monthlypayment)
                }                    
            }


            setTimeout( () => {
            if(this.props.application[this.props.who].expenses.currentfirstmortgage === "") {

                this.props.updateExpenses({target: {value: firstmortgage.toString()}}, com.borrower, "currentfirstmortgage")
                this.props.updateExpenses({target: {value: "monthly"}}, com.borrower, "currentfirstmortgageperiod")
                if(this.props.application[this.props.who].expenses.currentotherfinancing === "") {
                    this.props.updateExpenses({target: {value: otherfinancing.toString()}}, com.borrower, "currentotherfinancing")
                    this.props.updateExpenses({target: {value: "monthly"}}, com.borrower, "currentotherfinancingperiod")
                }
            } }, 100 )


        }
    }
    componentDidMount() { 

    }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }

    showCoborrower = () => {
        if( this.props.hascoborrower === "withcoborrower" && this.props.application.married )
            return true
        if( this.props.hascoborrower === "multiple" && this.props.application.married )
            return true
        return false
    }  
    title = () => {

        if (this.showCoborrower()) {
            return (<h2  className=" heading"> 
                {this.props.application.borrower.firstname} {this.props.application.borrower.lastname} and&nbsp;
                 {this.props.application.coborrower.firstname} {this.props.application.coborrower.lastname}'s current housing expenses for the primary residence</h2>
            )
        }
        return (
            <h2  className=" heading">
                {this.props.application[this.props.who].firstname} {this.props.application[this.props.who].lastname}'s
                housing expenses for the primary residence</h2>

        )
    }
    totalExpenses = getTotalExpenses.bind(this)
    render() {
        let updateExpenses = (who, verb) => {
           
            return (event) => {
               com.touch()
               return this.props.updateExpenses(event, who, verb)
            }
        }


        return (
            <div className=" mb-4 text-left  ">
                {this.title()}

                
                <div className="text-left mt-3 viewport" >
                <div className="sectionquestion mb-2" >Enter zeros where not applicable:</div>
                { this.props.application.property.purpose === "purchase" && this.props.application[this.props.who].presentaddressownership === "rent" ?
                    <Form.Row >
                        <Col md="4" className="mr-3">

                            <Form.Group controlId="firsttime" className="text-left" >
                                <Form.Label>Rent:</Form.Label>
                                <Form.Control as="select"
                                    //defaultValue={this.props.firsttime}
                                    type="text"
                                    size="sm"
                                    defaultValue={this.props.application[this.props.who].expenses.currentrentperiod}
                                    onChange={updateExpenses(this.props.who, "currentrentperiod")}

                                >
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </Form.Control>
                            </Form.Group>                                    
                        </Col>                                
                        <Col md="auto" className="">
                            <Form.Group controlId="rent" className="text-left " >
                            <Form.Label>Amount:</Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        required={this.props.application[this.props.who].presentaddressownership !== "own"}
                                        name="rent"
                                        defaultValue={this.props.application[this.props.who].expenses.currentrent}
                                        onChange={updateExpenses(this.props.who, "currentrent")}
                                        
                                        min={0}
                                    />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    : "" }

                { (this.props.application[this.props.who].presentaddressownership === "own" && this.props.application.property.purpose === "purchase" ) || 
                    this.props.application.property.purpose !== "purchase" ?
                    <div>
                        <Form.Row  >
                            <Col className="mr-3" md="4">
                                <Form.Group controlId="firsttime" className="text-left " >
                                    {tooltip("First Mortgage (Principal and Interest)",
                                    `
                                    Can be obtained from your current First Mortgage Statement, only include Principal and Interest payment, do not include the escrow amount. For Purchase Transactions will be generated by Zeitro based on the result of the product selection.
                                    `
                                    )}                                   
                                    <Form.Control as="select" size="sm"
                                        type="text"
                                        value={this.props.application[this.props.who].expenses.currentfirstmortgageperiod}
                                        onChange={updateExpenses(this.props.who, "currentfirstmortgageperiod")}
                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Control>
                                </Form.Group>                                    
                                </Col>                                
                            <Col className="" md="auto">
                                <Form.Group controlId="Currentmortgage" className="text-left " >
                                    <Form.Label>Amount:</Form.Label>

                                        <NumericalInput
                                            size="sm"
                                            required={this.props.application[this.props.who].presentaddressownership !== "rent"}
                                            name="currentmortgage"
                                            value={this.props.application[this.props.who].expenses.currentfirstmortgage}
                                            onChange={updateExpenses(this.props.who, "currentfirstmortgage")}

                                            
                                            min={0}
                                        />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your estimated liabilities.
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>
                        </Form.Row>

                        <Form.Row  >
                            <Col className="mr-3" md="4">
                                <Form.Group controlId="firsttime" className="text-left   " >
                                {tooltip("Other Financing (Principal and Interest)",
                                    `
                                    Can be obtained from your Second Mortgage Statement, such as HELOC account, purchase money second, or a standalone second.
                                    `
                                    )}     
                                    <Form.Control as="select" size="sm"
                                        //defaultValue={this.props.firsttime}
                                        type="text"
                                        defaultValue={this.props.application[this.props.who].expenses.currentotherfinancingperiod}
                                        onChange={updateExpenses(this.props.who, "currentotherfinancingperiod")}

                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Control>
                                </Form.Group>                                    
                            </Col>                                
                            <Col md="auto" className="">
                                <Form.Group controlId="OtherFinancing" className="text-left " >
                                    <Form.Label>Amount:</Form.Label>

                                    <NumericalInput
                                        size="sm"
                                        required={this.props.application[this.props.who].presentaddressownership !== "rent"}
                                        name="other"
                                        defaultValue={this.props.application[this.props.who].expenses.currentotherfinancing}
                                        onChange={updateExpenses(this.props.who, "currentotherfinancing")}
                                        
                                        min={0}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your estimated liabilities.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row   >
                            <Col md="4" className="mr-3" >
                            <Form.Group controlId="firsttime" className="text-left  " >
                                    {tooltip("Homeowner's Insurance ",
                                    `
                                    Can be obtained from your current Homeowner’s Insurance, you may contact your Insurance Agent and obtain the declaration page that will show the premium amount. 
                                    `
                                    )}                                     
                                   
                                    <Form.Control as="select" size="sm"
                                        type="text"
                                        defaultValue={this.props.application[this.props.who].expenses.currenthazardinsuranceperiod}
                                        onChange={updateExpenses(this.props.who, "currenthazardinsuranceperiod")}

                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Control>
                                </Form.Group>                                        
                            </Col>                                
                            <Col md="auto" className="">
                                <Form.Group controlId="Amount" className="text-left  " >
                                    <Form.Label>Amount:</Form.Label>
                                    <NumericalInput
                                    size="sm"
                                        required={this.props.application[this.props.who].presentaddressownership !== "rent"}
                                        name="hazard"
                                        defaultValue={this.props.application[this.props.who].expenses.currenthazardinsurance}
                                        onChange={updateExpenses(this.props.who, "currenthazardinsurance")}
                                        min={0}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your hazard insurance.
                                    </Form.Control.Feedback>
                            
                                </Form.Group>
                        </Col> 
                        </Form.Row>
                        <Form.Row   >
                            <Col md="4" className="mr-3" >
                                <Form.Group controlId="firsttime" className="text-left " >
                                {tooltip("Mortgage Insurance ",
                                    `
                                    Can be obtained from your current Mortgage Insurance, you may contact your Insurance Agent and obtain the declaration page that will show the premium amount. 
                                    `
                                    )}                                                       

                                    <Form.Control as="select" size="sm"
                                        //defaultValue={this.props.firsttime}
                                        type="text"
                                        defaultValue={this.props.application[this.props.who].expenses.currentmortgageinsuranceperiod}
                                        onChange={updateExpenses(this.props.who, "currentmortgageinsuranceperiod")}

                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Control>
                                </Form.Group>                                    
                            </Col>                                
                            <Col md="auto" className="">
                                <Form.Group controlId="Mortgage" className="text-left  " >
                                <Form.Label>Amount:</Form.Label>

                                    <NumericalInput
                                        size="sm"
                                        required={this.props.application[this.props.who].presentaddressownership !== "rent"}
                                        name="mortgage"
                                        defaultValue={this.props.application[this.props.who].expenses.currentmortgageinsurance}
                                        onChange={updateExpenses(this.props.who, "currentmortgageinsurance")}
                                        min={0}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your mortgage insurance.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>    

                        <Form.Row  >
                            <Col md="4" className="mr-3" >
                                <Form.Group controlId="firsttime" className="text-left  " >
                                {tooltip("Real Estate Taxes ",
                                    `
                                    Can be obtained from County Records or current Property Tax Bill. For Purchase Transactions use Purchase Price multiplied by 1.25% divided by 12 Months.
                                    `
                                    )}                                               

                                    <Form.Control as="select" size="sm"
                                        //defaultValue={this.props.firsttime}
                                        type="text"
                                        defaultValue={this.props.application[this.props.who].expenses.currentrealestatetaxesperiod}
                                        onChange={updateExpenses(this.props.who, "currentrealestatetaxesperiod")}

                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Control>
                                </Form.Group>                                    
                            </Col>                                
                            <Col md="auto" className="">
                                <Form.Group controlId="Taxes" className="text-left  " >
                                <Form.Label>Amount:</Form.Label>

                                        <NumericalInput size="sm"
                                            required={this.props.application[this.props.who].presentaddressownership !== "rent"}
                                            name="taxes"
                                            defaultValue={this.props.application[this.props.who].expenses.currentrealestatetaxes}
                                            onChange={updateExpenses(this.props.who, "currentrealestatetaxes")}                                                
                                            min={0}
                                        />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your estimated liabilities.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Form.Row>


                        <Form.Row   >
                            <Col md="4" className="mr-3" >
                                <Form.Group controlId="firsttime" className="text-left " >
                                {tooltip("Homeowner Association Dues",
                                    `
                                    Can be obtained from your Homeowners Association invoice or statement. For Purchase Transaction please consult your Real Estate Agent.
                                    `
                                    )}     
   
                                    <Form.Control as="select" size="sm"
                                        type="text"
                                        defaultValue={this.props.application[this.props.who].expenses.currentduesperiod}
                                        onChange={updateExpenses(this.props.who, "currentduesperiod")}
                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Control>
                                </Form.Group>                                    
                                </Col>                                
                            <Col md="auto" className="">
                                <Form.Group controlId="Dues" className="text-left " >
                                <Form.Label>Amount:</Form.Label>
                                        <NumericalInput size="sm"
                                            required={this.props.application[this.props.who].presentaddressownership !== "rent"}
                                            name="dues"
                                            defaultValue={this.props.application[this.props.who].expenses.currentdues}
                                            onChange={updateExpenses(this.props.who, "currentdues")}
                                            min={0}
                                        />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your estimated liabilities.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </Col>

                            </Form.Row>
                            <Form.Row     >
                            <Col md="4" className="mr-3" >
                                    <Form.Group controlId="firsttime" className="text-left " >
                                    {tooltip("Flood Insurance",
                                    `
                                    Can be obtained from your current Homeowner’s Insurance, you may contact your Insurance Agent and obtain the declaration page that will show the premium amount.
                                    `
                                    )}     

                                        <Form.Control as="select" size="sm"
                                            type="text"
                                            defaultValue={this.props.application[this.props.who].expenses.currentotherperiod}
                                            onChange={updateExpenses(this.props.who, "currentotherperiod")}
                                    >
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                        </Form.Control>
                                    </Form.Group>                                    
                                    </Col>                                
                                <Col md="auto" className="">
                                    <Form.Group controlId="ExpenseAmount" className="text-left " >
                                    <Form.Label>Amount:</Form.Label>
                                        <NumericalInput
                                            size="sm"
                                            required
                                            name="other"
                                            defaultValue={this.props.application[this.props.who].expenses.currentother}
                                            onChange={updateExpenses(this.props.who, "currentother")}                                                
                                            min={0}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide your estimated liabilities.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </Col>

                        </Form.Row>
                              
                        </div>
                        : "" }
 

                         <Form.Row     className=" border-top border-dark pt-2" >
                            <Col md="4" className="mr-3 text-right " >
                            <div className="sectionquestion mb-2" >Total Monthly Expenses:</div>
                            </Col>
                            <Col md="auto"  className="sectionquestion mb-2">
                                ${this.totalExpenses(this.props.who)}
                            </Col>
                        </Form.Row>                            
                </div>
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(Expenses)

