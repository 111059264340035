import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Row, Col, Nav, Button } from 'react-bootstrap';
import { Modal} from 'antd';
import "../../App.css";
import "./RateQuote.css";
import { withRouter } from "react-router-dom";
import RateQuoteLOVersion from "./RateQuoteLOVersion";
import RateQuoteLONewVersion from "./RateQuoteLONewVersion"
import RateQuoteBorrowerVersion from "./RateQuoteBorrowerVersion"
import { checkAccess } from "../../Auth";
import { UR_PriceEngineUser, UR_Owner } from "../../State";
import Lenders from "../../Management/Lenders";

const RateQuoter = (props) => {
  const [showAddLenderModal, setShowAddLendersModal] = useState(false);
  const [switchkey, setSwitchkey] = useState("loVersion");
  const isMobile = window.innerWidth <576
  if (checkAccess([UR_PriceEngineUser])) {
    return  <RateQuoteLONewVersion />
  }

  var currentUrl = window.location.href;
  const isGMCCCelebrity = currentUrl.includes("gmcccelebrityrates") || props.isGMCCCelebrity
  const specificProgram = (isGMCCCelebrity || props.isGMCCCelebrity) ? "GMCCCelebrities" : undefined


  let content = 
    <Tab.Container defaultActiveKey="loVersion" onSelect = {(activekey)=>setSwitchkey(activekey)}>
    <Row style={{borderBottom:"1px solid #ECECEC",height:"8%", alignItems:"center"}}>
      <Col xs={6} style={{paddingLeft:"30px"}}>
        {!isMobile && <div style={{fontWeight:"600",textAlign:"left",fontSize:"14px"}}>Rates & Pricing</div>}
        {!isMobile && <div style={{textAlign:"left",fontSize:"16px",fontWeight:"400"}}>Explore rates and pricing{isGMCCCelebrity ? " of GMCC Celebrity program" :"." }</div>}
      </Col>
      {!isGMCCCelebrity &&       <Col xs={6}>
        <Nav style={{justifyContent:"right",paddingRight: isMobile?"0":"20px"}} >
          <Nav.Item className="d-flex">
            {switchkey === "borrowerVersion" && 
              <Nav.Link style={{backgroundColor:"#F6F6F6",display:"flex",paddingLeft:"15px",alignItems:"center", border:"1px solid #F6F6F6",borderRadius:"4px",width:"240px",height:"40px"}} eventKey="loVersion">
                <div style={{ fontSize:"14px",fontWeight:"500",color:"#222222",whiteSpace:"nowrap",marginLeft:"5px"}}>LO version</div>
                <div style={{backgroundColor:"#FFFFFF",padding:"5px 5px 5px 5px",borderRadius:"4px",marginLeft:"15px",fontSize:"14px",fontWeight:"500",color:"#222222",whiteSpace:"nowrap"}}>Borrower version</div>
              </Nav.Link>
            }
            {switchkey === "loVersion" && 
              <Nav.Link style={{backgroundColor:"#F6F6F6",display:"flex",paddingLeft:"5px",alignItems:"center", border:"1px solid #F6F6F6",borderRadius:"4px",width:"240px",height:"40px"}} eventKey="borrowerVersion">
                <div style={{backgroundColor:"#FFFFFF",padding:"5px 15px 5px 15px",borderRadius:"4px",fontSize:"14px",fontWeight:"500",color:"#222222",whiteSpace:"nowrap"}}>LO version</div>
                <div style={{marginLeft:"5px",fontSize:"14px",fontWeight:"500",color:"#222222",whiteSpace:"nowrap"}}>Borrower version</div>
              </Nav.Link>
            }
            {checkAccess([UR_Owner]) && <Button variant="addLenders" className="addLendersBtn"  onClick={()=>setShowAddLendersModal(true)}>Add lenders</Button>}
          </Nav.Item>
        </Nav>  
      </Col>}

    </Row>
    
    <Tab.Content style={{height:"92%"}}>
          <Tab.Pane className="h-100" eventKey="loVersion"><RateQuoteLONewVersion isMobile={isMobile} specificProgram={specificProgram} /></Tab.Pane>
          <Tab.Pane className="h-100" eventKey="borrowerVersion"><RateQuoteBorrowerVersion fromLOS={true}/></Tab.Pane>
    </Tab.Content>
    
    <Modal
      open={showAddLenderModal}
      onCancel={()=>setShowAddLendersModal(false)}
      centered
      footer={[
        <Button key="back" onClick={()=>setShowAddLendersModal(false)}>
          Cancel
        </Button>,
      ]}
      width={1000}
    >
      <Lenders />
    </Modal>
    </Tab.Container>

  return content
}

export default withRouter(RateQuoter);
