import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import {
    Button,
    Form,
    Input,
    Col,
    Row,
    Rate
} from 'antd'
import "./CustomerReviewSetup.css"
import { set } from 'date-fns';
const { TextArea } = Input
const EditParams = forwardRef(({ reviewList, finish }, ref) => {
    const [form] = Form.useForm();
    const [headerForm] = Form.useForm();
    const [headerTitle, setheaderTitle] = useState('')
    console.log(reviewList, 77);
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const onFinish = (val) => {

    }
    useEffect(()=>{
        console.log(reviewList);
        setheaderTitle(reviewList?.title)
    },[

        reviewList
    ])
    const formInitial = {
        customerReview: [...reviewList.paramsList]
    }
   
    useImperativeHandle(ref, () => ({
        validateForm() {

            form.validateFields().then((values) => {
                console.log(headerTitle);
                finish({ ...values, header:headerTitle })
            })
                .catch((errorInfo) => {
                    console.log('Validation failed:', errorInfo);
                });


        },
        resetForm() {
            form.resetFields()
        }
    }))
    return (
        <div className="customer-review-setup">

            <div className="form-wrap">
                <div className="theme-title">Header</div>
                <Form form={headerForm}>
                    <Col style={{ marginBottom: 16 }} span={22}>
                        <Row gutter={60}>
                            <Col span={24} sm={24} >
                                <Form.Item

                                    name='header'
                                >
                                    <Input placeholder="Enter your powerful slogan" defaultValue={reviewList.title} onChange={(e) => {
                                        console.log(e.target.value);
                                        setheaderTitle(e.target.value)
                                    }} />
                                </Form.Item>
                            </Col>

                        </Row>

                    </Col>
                </Form>
                {/* <div className="theme-title">Customer review</div> */}
                <Form
                    className="customer-review-form"
                    layout="vertical"
                    form={form}
                    initialValues={formInitial}
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    size="large"
                >
                    <Form.List name="customerReview">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (

                                    <Row
                                        key={key}
                                        gutter={20}
                                    >
                                        <div className='bullet'>Bullet Point {index + 1}:</div>
                                        <Col style={{ marginBottom: 16 }} span={22}>
                                            <Row gutter={60}>

                                                <Col span={24} sm={12} style={{ marginBottom: '12px' }}>
                                                    <Form.Item
                                                        {...restField}
                                                        label="Title"
                                                        name={[name, "title"]}
                                                    >
                                                        <Input placeholder={`Enter your  advantage`} />
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        {...restField}
                                                        label="Description"
                                                        name={[name, "review"]}
                                                    >
                                                        <TextArea
                                                            autoSize={{
                                                                minRows: 4,
                                                                maxRows: 6
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col style={{ marginBottom: 16 }}>
                                            <img className='remove-photo' src='/images/remove.svg' onClick={() => remove(name)} />
                                        </Col>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <span className='add-review' onClick={() => add(null, fields.length+1)}>Add more</span>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        </div >
    );
})
export default EditParams;