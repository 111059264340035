import React, { useEffect, useState } from 'react';



const ParsedData = ({ parsedData }) => {

    


    const countNonEmptyValues = (obj) => {
      let count = 0;
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              const value = obj[key];
              if (typeof value === 'object' && value !== null) {
                  count += countNonEmptyValues(value);
              } else if (value !== null && value !== undefined && value !== "") {
                  count++;
              }
          }
      }
      return count;
  }
  
    const findObjectWithMostInformation = (data) => {
        let maxCount = 0;
        let bestObject = null;
    
        data.ExtractedData.forEach((item) => {
            const count = countNonEmptyValues(item);
            if (count > maxCount) {
                maxCount = count;
                bestObject = item;
            }
        });
    
        return bestObject;
    }

    const [data, setData] = useState(findObjectWithMostInformation(JSON.parse(parsedData)))

    const renderObject = (obj) => {
      return Object.keys(obj).map((key) => {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          return (
            <div key={key} style={{ marginLeft: '20px' }}>
              <strong>{key}:</strong>
              <div>{renderObject(value)}</div>
            </div>
          );
        }
        return (
          <div key={key} style={{ marginLeft: '20px' }}>
            <strong>{key}:</strong> {String(value)}
          </div>
        );
      });
    };

    useEffect(() => {
      console.log(data)
    }, [data])

  return (
    <div style={{padding:20, lineHeight:2}}>
      <div>{renderObject(data.extractedData)}</div>
    </div>
  );
}

export default ParsedData;