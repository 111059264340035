const initState = {
    showPdf: true,
    content: "fanieMaeDocName"
}

export default function Common(state = initState, action) {
    const { type, data } = action

    switch (type) {
        case 'SHOW_PDF': {
            state.showPdf = data
            break
        }
        case "CHANGE_PDF": {
            state.content = data
            break
        }
        default:
            break
    }

    return state
}