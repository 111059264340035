import React, { useState, useEffect, useRef } from 'react';
import { useImmer } from "use-immer";
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover } from 'antd';
import { SearchOutlined, CheckOutlined, FormOutlined } from '@ant-design/icons';
export default function UserModal(props) {
    const { isModalOpen, closeModal, loanOfficers } = props
    const [dataSource, setDataSource] = useImmer([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const getStatus = (item) => {
        const statusDom = {
            active: <Tag color="success">Active</Tag>,
            disabled: <Tag color="default">Disabled</Tag>,
            invited: <Tag color="warning">Invited</Tag>,

        }
        return statusDom[item]


    }
    let columns = [
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            fixed: 'left',

            render: (text) => <>{text}</>,

            onFilter: (value, record) => {
                return (
                    String(record.Email).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.FullName).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Nmls).toLowerCase().includes(value.toLowerCase())

                );
            },
        },
        {
            title: 'Full Name',
            dataIndex: 'FullName',
            key: 'FullName',
            fixed: 'left',
            sorter: (a, b) => {
                if (a.Email < b.Email) {
                    return -1;
                }
                if (a.Email > b.Email) {
                    return 1;
                }
                return 0;
            },
            render: (text) => <>{text}</>,
        },
        {
            title: 'Role',
            dataIndex: 'Roles',
            key: 'Roles',
            width: 200,
            render: (text) => <div title={text?.join(',')} style={{
                width: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }}>{text?.join(',')}</div>,

        },
        {
            title: 'NMLS #',
            dataIndex: 'Nmls',
            key: 'Nmls',
            render: (text) => <>{text}</>,
            sorter: (a, b) => {
                if (a.Nmls < b.Nmls) {
                    return -1;
                }
                if (a.Nmls > b.Nmls) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Account Tier',
            dataIndex: 'AccountTier',
            width: 100,
            key: 'AccountTier',
            render: (text) => <>{text}</>,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',

            render: (text, _) => <>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    {getStatus(text)}
                </div>
            </>,
        },
    ];
    useEffect(() => {
        if (loanOfficers === undefined) return

        getList()
    }, [isModalOpen])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => (
            {
                disabled: record.Roles.includes('Owner'), // Column configuration not to be checked
                name: record.ID,


            }),
    };
    const getRowClassName = (record) => {

        if (record.Roles.includes('Owner')) {
            return 'highlight-row'; // 当条件满足时，返回高亮类名
        }
        return ''; // 条件不满足时，返回空字符串（不添加额外样式）
    };
    // classNames={{
    //     content: 'modal_content',
    //     body: 'modal_body'
    // }}
    return (<>
        <Modal okText='Upgrade'
            wrapClassName='modal_warp'
            styles={{
                content:{height:"100vh"},
                body:{height: '90vh'}
            }}
            width={1300} style={{ top: 0 }} open={isModalOpen} onCancel={() => {
                closeModal(false)

            }
            } onOk={() => {
                closeModal(selectedRowKeys)

            }}>
               
            <div >

                <div>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>

                        <div style={{ fontSize: "36px", fontWeight: "bold" }}>Select users to upgrade</div>
                        <div style={{ fontSize: "16px" }}>There are {dataSource.length} active users that can be upgraded to Paid User tier.
                            Please review the list of users and deselect anyone that you don’t wish to upgrade.</div>
                    </div>

                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            ...rowSelection,
                            renderCell: (checked, record, index, originNode) => {
                                if (record.Roles.includes('Owner')) {
                                    return <Popover content={<><div style={{ "box-shadow": "0px 2px 8px 0px rgba(0, 25, 76, .5)",background: '#fff', padding: "15px", borderRadius: '10px' }}>The admin must to be paid user.</div></>}
                                        trigger="hover"
                                        placement="bottomLeft"
                                    >
                                        {originNode}

                                    </Popover>
                                } else {

                                    return originNode
                                }



                            }
                        }}
                        rowClassName={getRowClassName}
                        rowKey='ID'

                        columns={columns}
                        dataSource={dataSource}
                        showSorterTooltip={false}
                        pagination={false}
                        scroll={{
                            x: true
                            , y: 500
                        }}
                    />
                </div>

            </div>

        </Modal >

    </>)
    async function getList() {
        try {
            let token = sessionStorage.getItem("ZeitroA")

            const response = await fetch('/los/loanofficerlist', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-Image-Free": "true",
                }
            })
            const { LoanOfficers } = await response.json()
            let docs = LoanOfficers.map(s => {
                return {
                    ...s,
                    FullName: s.FirstName + " " + s.LastName
                }
            }).filter((e)=>e.Status !== 'disabled').sort((a,b)=>{
               
                const aValue = a.Status === 'disabled';
                const bValue = b.Status === 'disabled';
            
                if (aValue === bValue) return 0; // 如果两者value属性都是（或都不是）'disable'，保持原有顺序
            
                // 将value为'disable'的元素排在后面
                return aValue ? 1 : -1;

            })
            const owner = docs.find(s => s.Roles.includes('Owner'))
            setSelectedRowKeys(loanOfficers.map((item) => item.ID ? item.ID : item))
            console.log(owner, 9);
            setDataSource([...docs])

        } catch (error) {
            // setLoading(false)
        }


    }
}