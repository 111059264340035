import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

import classes from '../DocumentVerification.module.css'

const Greencard = ({ doc, onFocus, onChangeTextInput }) => {

  return (
    Array.isArray(doc) ? // support of old parseddata format
      doc.map((item, i) =>
        <div key={`greencard.pdf${i}`}>
          <Row>
            <Form.Group as={Col} controlId="Lastname">
              <Form.Label>Last name</Form.Label>
              <Form.Control defaultValue={item['Lastname']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'Lastname'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="FirstName">
              <Form.Label>First name</Form.Label>
              <Form.Control defaultValue={item['FirstName']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'FirstName'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="USCISNumber">
              <Form.Label>USCIS number</Form.Label>
              <Form.Control defaultValue={item['USCISNumber']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'USCISNumber'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="VisaCategory">
              <Form.Label>Visa category</Form.Label>
              <Form.Control defaultValue={item['VisaCategory']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'VisaCategory'])} />
            </Form.Group>
          </Row>
          <Form.Group className={classes['p-x-1']} controlId="CountryOfBirth">
            <Form.Label>Country of birth</Form.Label>
            <Form.Control defaultValue={item['CountryOfBirth']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'CountryOfBirth'])} />
          </Form.Group>
          <Row>
            <Form.Group as={Col} controlId="DateOfBirth">
              <Form.Label>Date of birth</Form.Label>
              <Form.Control defaultValue={item['DateOfBirth']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'DateOfBirth'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="Sex">
              <Form.Label>Sex</Form.Label>
              <Form.Control defaultValue={item['Sex']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'Sex'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="ExpirationDate">
              <Form.Label>Expiration date</Form.Label>
              <Form.Control defaultValue={item['ExpirationDate']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'ExpirationDate'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="ResidentSince">
              <Form.Label>Resident since</Form.Label>
              <Form.Control defaultValue={item['ResidentSince']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'ResidentSince'])} />
            </Form.Group>
          </Row>
          <hr style={{ borderWidth: '4px', margin: '0 10px' }} />
          <p>TODO: inputs below are in json/yaml definition, but are not on the document</p>
          <Row>
            <Form.Group as={Col} controlId="IssueDate">
              <Form.Label>Issue date</Form.Label>
              <Form.Control defaultValue={item['IssueDate']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'IssueDate'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="AlienNumber">
              <Form.Label>Alien number</Form.Label>
              <Form.Control defaultValue={item['AlienNumber']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'AlienNumber'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="GreenCardNumber">
              <Form.Label>Green card number</Form.Label>
              <Form.Control defaultValue={item['GreenCardNumber']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'GreenCardNumber'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="CountryOfResidence">
              <Form.Label>Country of residence</Form.Label>
              <Form.Control defaultValue={item['CountryOfResidence']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'CountryOfResidence'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="GreenCardHolderType">
              <Form.Label>Green card holder type</Form.Label>
              <Form.Control defaultValue={item['GreenCardHolderType']} type="text" onChange={e => onChangeTextInput('greencard.pdf', e.target.value, [i, 'GreenCardHolderType'])} />
            </Form.Group>
          </Row>
        </div>) :
      <div>
        <Row>
          <Form.Group as={Col} controlId="LastName">
            <Form.Label>Last name</Form.Label>
            <Form.Control defaultValue={doc['LastName']['Value']} type="text" onFocus={() => onFocus(doc['LastName']['BBox'], doc['LastName']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['LastName', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="FirstName">
            <Form.Label>First name</Form.Label>
            <Form.Control defaultValue={doc['FirstName']['Value']} type="text" onFocus={() => onFocus(doc['FirstName']['BBox'], doc['FirstName']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['FirstName', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="USCISNumber">
            <Form.Label>USCIS number</Form.Label>
            <Form.Control defaultValue={doc['USCISNumber']['Value']} type="text" onFocus={() => onFocus(doc['USCISNumber']['BBox'], doc['USCISNumber']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['USCISNumber', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="VisaCategory">
            <Form.Label>Visa category</Form.Label>
            <Form.Control defaultValue={doc['VisaCategory']['Value']} type="text" onFocus={() => onFocus(doc['VisaCategory']['BBox'], doc['VisaCategory']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['VisaCategory', 'Value'])} />
          </Form.Group>
        </Row>
        <Form.Group className={classes['p-x-1']} controlId="CountryOfBirth">
          <Form.Label>Country of birth</Form.Label>
          <Form.Control defaultValue={doc['CountryOfBirth']['Value']} type="text" onFocus={() => onFocus(doc['CountryOfBirth']['BBox'], doc['CountryOfBirth']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['CountryOfBirth', 'Value'])} />
        </Form.Group>
        <Row>
          <Form.Group as={Col} controlId="DateOfBirth">
            <Form.Label>Date of birth</Form.Label>
            <Form.Control defaultValue={doc['DateOfBirth']['Value']} type="text" onFocus={() => onFocus(doc['DateOfBirth']['BBox'], doc['DateOfBirth']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['DateOfBirth', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Sex">
            <Form.Label>Sex</Form.Label>
            <Form.Control defaultValue={doc['Sex']['Value']} type="text" onFocus={() => onFocus(doc['Sex']['BBox'], doc['Sex']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['Sex', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="ExpirationDate">
            <Form.Label>Expiration date</Form.Label>
            <Form.Control defaultValue={doc['ExpirationDate']['Value']} type="text" onFocus={() => onFocus(doc['ExpirationDate']['BBox'], doc['ExpirationDate']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['ExpirationDate', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="ResidentSince">
            <Form.Label>Resident since</Form.Label>
            <Form.Control defaultValue={doc['ResidentSince']['Value']} type="text" onFocus={() => onFocus(doc['ResidentSince']['BBox'], doc['ResidentSince']['Page'])} onChange={e => onChangeTextInput('greencard.pdf', e.target.value, ['ResidentSince', 'Value'])} />
          </Form.Group>
        </Row>
      </div>
  );
}

export default Greencard;