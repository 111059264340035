import React, { useCallback } from 'react'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './Chat.css'

// ReferenceLink component
const ReferenceLink = ({ referenceIndex, referenceFunc }) => {
    return (
        <span
            onClick={() => referenceFunc(referenceIndex)}
            style={{ cursor: 'pointer', color: 'blue' }}
        >
            [{referenceIndex}]
        </span>
    )
}

// MarkdownRenderer component
const MarkdownRenderer = ({ answer, referenceFunc }) => {
    const preprocessMarkdown = useCallback((markdown) => {
        // Remove specific unicode characters
        markdown = markdown.replace(/\u3010.*?\u3011/g, '')

        // Add a newline after the colon to ensure line breaks
        markdown = markdown.replace(/:\s*$/gm, ':\n')

        // Add a newline before `a)`, `b)`, `c)`
        markdown = markdown.replace(/(\n\s*)([a-z]\))\s+/g, '\n$1$2 ')

        // Convert h3 to h6 and ensure bold
        markdown = markdown.replace(
            /(^|\n)###\s+(.*?)($|\n)/g,
            (match, start, p1, end) => {
                p1 = p1.replace(/\*\*/g, '')
                return `${start}<span style="font-size: 16px; font-weight: bold;">${p1.trim()}</span>${end}`
            }
        )

        return markdown
    }, [])

    // Define custom renderers for processing text
    const renderers = {
        text: (node) => {
            const parts = node.value.split(/\[([0-9]+)\]/g);
            return parts.map((part, index) => {
                const match = part.match(/^\d+$/);
                if (match) {
                    return (
                        <ReferenceLink
                            key={index}
                            referenceIndex={match[0]}
                            referenceFunc={referenceFunc}
                        />
                    );
                }
                return part; // Return non-reference parts as is
            });
        },
    }

    // Render the markdown content
    return (
        <ReactMarkdown className="markdown-body" rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} components={renderers}>
            {preprocessMarkdown(answer)}
        </ReactMarkdown>
    )
}

export default MarkdownRenderer;
