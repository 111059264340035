import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Image } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

export default function StepFive({ openAddLender }) {
    const dispatch = useDispatch()
    return (
        <>
            <div className='ModalTitle'>
                Integrate Zeitro widgets in your website
            </div>
            <div style={{ marginTop: 15, fontSize: 16, fontFamily: "Inter", fontWeight: 400 }}>
                If you want to embed our platform into your existing website for a seamless exprience for your borrowers, Please set this up later in <span
                    style={{

                        fontWeight: 600
                    }}
                >
                    Management {'>'} Publish site integration.
                </span>
            </div>
            <div style={{ marginTop: 10, color: '#222', fontWeight: 600, fontSize: 16 }}>Example</div>
            <div className='stepFiveImg' >
                <Image


                    src="/images/Example.png"
                />
            </div>


        </>

    )
}
