export const  CHANGE_PRODUCT = "CHANGE_PRODUCT";
export const  CHANGE_TRANSACTION = "CHANGE_TRANSACTION";

// probably this is not needed
export const  CHANGE_LENDERNAME = "CHANGE_LENDERNAME";
export const  CHANGE_PRODUCTNAME = "CHANGE_PRODUCTNAME";
export const  CHANGE_MORTGAGEAPPLIED = "CHANGE_MORTGAGEAPPLIED";
export const  CHANGE_OTHERMORTGGE = "CHANGE_OTHERMORTGGE";
export const  CHANGE_AGENCYCASENUMBER = "CHANGE_AGENCYCASENUMBER";
export const  CHANGE_LENDERCASENUMBER = "CHANGE_LENDERCASENUMBER";
export const  CHANGE_INTERESTRATE = "CHANGE_INTERESTRATE";
export const  CHANGE_MORTGAGETERM = "CHANGE_MORTGAGETERM";
export const  CHANGE_MORTGAGETERMMONTHS = "CHANGE_MORTGAGETERMMONTHS";
export const  CHANGE_AMORTIZATION = "CHANGE_AMORTIZATION";
export const  CHANGE_OTHERAMORTIZATION = "CHANGE_OTHERAMORTIZATION";


export const  CHANGE_ALTERATIONSIMPROVEMENTS = "CHANGE_ALTERATIONSIMPROVEMENTS";
export const  CHANGE_LANDIFACQUIREDSEPARATELY = "CHANGE_LANDIFACQUIREDSEPARATELY";
export const  CHANGE_REFINANCEINCLDEBTS = "CHANGE_REFINANCEINCLDEBTS";
export const  CHANGE_ESTIMATEDPREPAIDITEMS = "CHANGE_ESTIMATEDPREPAIDITEMS";
export const  CHANGE_ESTIMATEDCLOSINGCOSTS = "CHANGE_ESTIMATEDCLOSINGCOSTS";
export const  CHANGE_PMIFUNDINGFEE = "CHANGE_PMIFUNDINGFEE";
export const  CHANGE_DISCOUNTIFBORROWERPAYS = "CHANGE_DISCOUNTIFBORROWERPAYS";
export const  CHANGE_TOTALCOSTS = "CHANGE_TOTALCOSTS";
export const  CHANGE_SUBORDINATEFINANCING = "CHANGE_SUBORDINATEFINANCING";

export const  CHANGE_BORROWERCLOSINGCOSTSPAIDBYSELLER = "CHANGE_BORROWERCLOSINGCOSTSPAIDBYSELLER";
export const  CHANGE_OTHERCREDITS = "CHANGE_OTHERCREDITS";
export const  CHANGE_OTHERCREDITSEXPLANATION = "CHANGE_OTHERCREDITSEXPLANATION";

export const  CHANGE_LOANAMOUNTEXCLUDEFEES = "CHANGE_LOANAMOUNTEXCLUDEFEES";
export const  CHANGE_PMIFUNDINGFEEFINANCED = "CHANGE_PMIFUNDINGFEEFINANCED";

export const  CHANGE_LOANAMOOUNT = "CHANGE_LOANAMOOUNT";
export const  CHANGE_CASHFROMTOBORROWER = "CHANGE_CASHFROMTOBORROWER";

