import {
    CHANGE_BORROWERID,
    CHANGE_BORROWERNAME,
    CHANGE_BORROWERLOANID
} from '../borrowerdefs'


import { borrowerinternal } from '../State.js'

const borrowerInitialState = new borrowerinternal()
const borrowerInitialStateJSON = JSON.parse(borrowerInitialState.toJson() )

export default function borrowerReducer(state = borrowerInitialStateJSON, action) {
    state = borrowerinternal.fromJson(state)

    switch (action.type) {
        case CHANGE_BORROWERID: {
            state.id = action.payload;
            break;
        }
        case CHANGE_BORROWERLOANID: {
            state.loan_id = action.payload;
            break;
        }
        
        case CHANGE_BORROWERNAME: {
            state.fullname = action.payload;
            break;
        }
        default:
            break;
    }
    return JSON.parse(state.toJson() );
}
     