
import applicationReducer from './application'
import progressReducer from './progress'
import borrowerReducer from './borrower'
import notificationsReducer from './notifications'
import pipelineColumnsReducer from './pipelineColumns'
import subscriptionReducer from './subscription'
import updateStatusReducer from './status'
import Common from './common'
import Manager from './manager'

import { combineReducers } from 'redux'

let rootReducer = combineReducers({
    application: applicationReducer,
    progress: progressReducer,
    borrower: borrowerReducer,
    notifications: notificationsReducer,
    subscriptionReducer: subscriptionReducer,
    pipelineConfiguration: pipelineColumnsReducer,
    updateStatus: updateStatusReducer,
    common: Common,
    manager: Manager,
})

export default rootReducer