import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "../App.css";
import { connect } from "react-redux";
import { UpdateChangeProgress } from "../Store/progress";
import * as com from "../Common";
import * as act from "../Store/actions";
import { tooltipFlat } from "../Common/Tooltip";
import {
  getFees,
  getDTI,
  getCitizenship,
  getOccupancy,
  getPropertyType,
  productReload,
} from "./ProductPicker";

var hash = require("object-hash");

/*
Basic Information needed to submit for Pre-Approval & Approval or Refi –
·      Collect fees to run credit Report – soft or hard – usually <30$
·      W-2 forms from the past two years
Pay stubs from the past 30 days – usually 2 paystubs
Federal tax returns from the past two years
Proof of other sources of income
Recent bank statements – 2 latest months 
Details on long-term debts such as car or student loans, 
ID and Social Security number
Mortgage payment stubs if they apply 
Home tax and insurance statements if applies  (2nd time home buyers and Refis) 
·      There may be other kinds of documentation required, depending on the type of mortgage getting.
If self-employed :
·      2 years Tax Returns for both Self including all schedules and the Business Tax Returns if more than 25% owner.
·      Articles of incorporation.
·      Business Registeration
*/

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
    property: state.application.property,
    selection: state.application.selection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateChangeProgress: (step, stage) => {
    dispatch(UpdateChangeProgress(step, stage));
  },
  updateApplicationAttribute: (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb));
  },
  updateSelectedLoanAttribute: (t, verb) => {
    dispatch(act.UpdateSelectedLoanAttribute(t, verb));
  },
  updateSelectField: (input, verb) => {
    dispatch(act.UpdateSelectField(input, verb));
  },
});

export default class Loan extends Component {
  getFees = getFees.bind(this);
  getDTI = getDTI.bind(this);
  getCitizenship = getCitizenship.bind(this);
  getOccupancy = getOccupancy.bind(this);
  getPropertyType = getPropertyType.bind(this);
  productReload = productReload.bind(this);

  constructor(props) {
    super(props);
    this.state = {
      loimage: "/avatar.png",
      loname: "",
      lophone: "",
      loemail: "",
      lonmls: "",
      loaded: false,
      showprogress: false,
      loading: true,
      loanhaschanged: false,
      loansize: this.props.selection.selectedloan.loansize,
    };
    this.loadLoanOfficer();
    if (com.getNumericalStatus(this.props.application.status) < 2)
      this.props.updateApplicationAttribute("interviewfinished", "status");
  }
  processProducts = (products) => {
    if ("undefined" === typeof products || products.length === 0) return;
    this.rawproducts = products;
    let rearranged = [];

    products.forEach((product) => {
      let lender = product.Lender;
      // if (this.props.application.property.hascontract === false && this.props.property.purpose === "purchase") {
      //   // filter out
      //   if (!["HomePoint", "Plaza", "RocketPro"].includes(lender))
      //     return
      // }

      product.ProductRatesCosts.forEach((pr) => {
        let commonproduct = pr.product;
        let ausengine = pr.AUSEngine;
        pr.rateCosts.forEach((rate) => {
          let pdkt = {
            Lender: lender,
            ...commonproduct,
            Aus: ausengine,
            ...rate,
          };

          rearranged.push(pdkt);
        });
      });
    });

    // Lender, Name
    let plainproducts = [];
    this.referi = {};
    rearranged.forEach((pr) => {
      if (pr.lockDay !== 30) return;
      const {
        Lender,
        Name,
        Aus,
        lenderinfo,
        features,
        DTI,
        high_cost_loan,
        high_priced_loan,
        ...partialObject
      } = pr;

      let hsh = hash(partialObject);
      if (typeof this.referi[hsh] === "undefined") {
        plainproducts.push({ hash: hsh, ...partialObject });
        this.referi[hsh] = [
          {
            Lender: Lender,
            Name: Name,
            Aus: Aus,
            LenderInfo: lenderinfo,
            Rate: partialObject,
          },
        ];
      } else {
        this.referi[hsh].push({
          Lender: Lender,
          Name: Name,
          Aus: Aus,
          LenderInfo: lenderinfo,
          Rate: partialObject,
        });
      }
    });
    if (
      this.referi[this.props.application.selection.selectedloan.hash] == null
    ) {
      this.setState({ loanhaschanged: true });
    }
  };
  isSubmitted = () => {
    let current = com.getNumericalStatus(this.props.application.status);
    let loansubmitted = com.getNumericalStatus("interviewfinished");
    return current >= loansubmitted;
  };
  componentDidMount() {
    let current = com.getNumericalStatus(this.props.application.status);
    let loansubmitted = com.getNumericalStatus("interviewfinished");
    if (current < loansubmitted) this.getFees();
    else {
      this.setState({ loading: false });
    }
  }
  askedForAssistance = () => {
    return false;
  };
  getLTV = function () {
    let ltv =
      (com.safeParseInt(
        this.props.application.selection.selectedloan.loansize
      ) *
        100) /
      com.safeParseInt(this.props.application.property.appraisal);
    let cltv =
      ((com.safeParseInt(
        this.props.application.selection.selectedloan.loansize
      ) +
        com.safeParseInt(this.cltv)) *
        100) /
      com.safeParseInt(this.props.application.property.appraisal);
    let hcltv =
      ((com.safeParseInt(
        this.props.application.selection.selectedloan.loansize
      ) +
        com.safeParseInt(this.hcltv)) *
        100) /
      com.safeParseInt(this.props.application.property.appraisal);
    return [ltv, cltv, hcltv];
  };
  loadLoanOfficer = () => {
    let token = com.getUserToken();

    fetch("/borrower/getloanofficer", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": this.props.borrowerid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          this.setState({ loaded: false });
          window.document.dispatchEvent(new Event("reauthenticate"), "");

          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Status);
          } else {
            this.setState({
              loimage: js.Image,
              loname: js.Name,
              loemail: js.Email,
              lophone: js.Phone,
              lonmls: js.Nmls,
              loaded: true,
            });
          }
          this.setState({ loaded: true });
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :", err);
        this.setState({ loaded: false });
      });
  };
  lock = () => {
    let token = sessionStorage.getItem("ZeitroA");
    this.setState({ showprogress: true });
    let dat = new Date();
    fetch("/data/lock", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": this.props.borrowerid,
      },
      body: JSON.stringify({ LockDate: dat.toString() }),
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          window.document.dispatchEvent(new Event("reauthenticate"), "");
          this.setState({ showprogress: false });
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          this.setState({ showprogress: false });
          if (js.Status !== "OK") {
            alert(js.Text);
            this.props.updateSelectedLoanAttribute("requested", "lockstatus");
          } else {
            this.props.updateSelectedLoanAttribute("requested", "lockstatus");
          }
          this.setState({ showprogress: false });
        });
      })
      .catch((err) => {
        alert("Fetch Error :", err);
        this.setState({ showprogress: false });
      });
  };

  submitApplication = () => {
    let token = sessionStorage.getItem("ZeitroA");
    this.setState({ showprogress: true });
    fetch("/data/submit", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": this.props.borrowerid,
      },
      body: JSON.stringify({ SubmissionDate: Date() }),
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          window.document.dispatchEvent(new Event("reauthenticate"), "");
          this.setState({ showprogress: false });
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          this.setState({ showprogress: false });
          if (js.Status !== "OK") {
            alert(js.Text);
            this.props.updateApplicationAttribute("interviewfinished", "status");
          } else {
            this.props.updateApplicationAttribute("interviewfinished", "status");
          }
          this.setState({ showprogress: false });
        });
      })
      .catch((err) => {
        alert("Fetch Error :", err);
        this.setState({ showprogress: false });
      });
  };
  loanChanged = () => {
    return (
      <div>
        {" "}
        Your selected loan has changed, or is no longer available. Please go to Loan Choice page to re-select a product.{" "}
        {/* {this.isSubmitted() ? (
          ""
        ) : (
          <Button
            className="ml-4 my-1 py-0"
            href="/app/interview/main/choice"
            size="sm"
            variant="outline-primary"
          >
            {" "}
            Edit
          </Button>
        )} */}
      </div>
    );
  };
  displayLoan = () => {
    let getLoanType = () => {
      let card = this.props.application.selection.selectedloan;
      let tp = "";
      if (card.Arm === null || card.Arm === 0) tp = "fixed rate";
      else tp = "ARM " + card.Arm.fixedperiod + "/1";

      return ` ${tp} ${card.Term} year`;
    };
    let isPreapproval = () => {
      return (
        false === this.props.application.property.hascontract &&
        this.props.application.property.purpose === "purchase"
      );
    };
    let getAddress = () => {
      let st = this.props.application.property.address;
      if (isPreapproval()) return "TBD";
      return st;
    };

    let purpose;
    switch (this.props.application.property.purpose) {
      case "refinance": {
        purpose = "refinance " + getLoanType() + " loan";
        break;
      }
      case "cashoutrefinance": {
        purpose = "cash out refinance " + getLoanType() + " loan";
        break;
      }
      default:
      case "purchase": {
        purpose = "purchase " + getLoanType() + " loan";
        break;
      }
    }
    let getPropertyPage = () => {
      if (
        this.props.application.property.purpose !== "purchase" &&
        true === this.props.application.financingsameproperty
      ) {
        return "/app/interview/main/3";
      }
      return "/app/interview/main/7";
    };
    if (isPreapproval()) {
      purpose = "preapproval application";
    }

    let getClosingCost = () => {
      let cc = this.props.application.selection.selectedloan.closing_cost;
      if (cc >= 0)
        return (
          <div>
            Estimated <b>closing costs</b> are{" "}
            <b>${com.commaizeFloat(cc.toFixed(2))}</b>{" "}
            {/* {this.isSubmitted() ? (
              ""
            ) : (
              <Button
                className="ml-4 my-1 py-0"
                href="/app/interview/main/choice"
                size="sm"
                variant="outline-primary"
              >
                {" "}
                Edit
              </Button>
            )} */}
          </div>
        );
      else
        return (
          <div>
            Estimated <b>remaining lender credits</b> are{" "}
            <b>${com.commaizeFloat(-cc.toFixed(2))}</b>{" "}
            {/* {this.isSubmitted() ? (
              ""
            ) : (
              <Button
                className="ml-4 my-1 py-0"
                href="/app/interview/main/choice"
                size="sm"
                variant="outline-primary"
              >
                {" "}
                Edit
              </Button>
            )} */}
          </div>
        );
    };
    let getLoanAmount = () => {
      let loansize;
      let downpayment;

      switch (this.props.application.property.purpose) {
        case "purchase":
          loansize = this.props.application.selection.selectedloan.loansize;
          downpayment =
            com.safeParseInt(this.props.application.property.salesprice) -
            loansize;
          break;
        case "refinance":
          loansize = this.props.application.selection.selectedloan.loansize;
          downpayment =
            com.safeParseInt(this.props.application.loanbalance) - loansize;
          break;
        case "cashoutrefinance":
          loansize = this.props.application.selection.selectedloan.loansize;
          downpayment =
            loansize - com.safeParseInt(this.props.application.loanbalance);
          break;
        default:
          alert("should not see this!");
          break;
      }
      return [loansize, downpayment];
    };
    let lockStatus = () => {
      switch (this.props.application.selection.selectedloan.lockstatus) {
        case "none":
        default:
          return " floating ";
        case "requested":
          return " requested ";
        case "locked":
          return " locked ";
        case "expired":
          return " expired lock ";
      }
    };
    let displayFHA = () => {
      return (
        <div className="mt-2">
          This is an <b>FHA loan</b>, it requires monthly{" "}
          <b>insurance payments</b> of
          <b>
            $
            {com.commaizeFloat(
              this.props.application.selection.selectedloan.FHAMIP.monthlyMIP
            )}
          </b>
          , and an <b>upfront payment</b> of{" "}
          <b>
            $
            {com.commaizeFloat(
              this.props.application.selection.selectedloan.FHAMIP.upfrontMIP
            )}
          </b>
          {/* {this.isSubmitted() ? (
            ""
          ) : (
            <Button
              className="ml-4 my-1 py-0"
              href="/app/interview/main/choice"
              size="sm"
              variant="outline-primary"
            >
              {" "}
              Edit
            </Button>
          )} */}
        </div>
      );
    };
    let displayReserves = () => {
      return (
        <div className="mt-2">
          This loan requires provable reserves of{" "}
          {this.props.application.selection.selectedloan.min_months_reserves}{" "}
          months of payments.
        </div>
      );
    };
    let displayPMI = () => {
      return (
        <div className="mt-2">
          This loan required private mortgage insurance. Estimated monthly
          payments are $
          {com.commaizeFloat(
            this.props.application.selection.selectedloan.pmi.toFixed(2)
          )}
        </div>
      );
    };
    let getDownpayment = () => {
      switch (this.props.application.property.purpose) {
        case "purpose":
        default:
          return (
            <span>
              {" "}
              and downpayment of $
              {com.commaizeFloat(
                this.props.application.selection.selectedloan.downpayment
              )}
            </span>
          );

        case "refinance": {
          if (
            this.props.application.selection.selectedloan.paydown === 0 ||
            this.props.application.selection.selectedloan.paydown === ""
          )
            return "";
          return (
            <span>
              {" "}
              and paydown of $
              {com.commaizeFloat(
                this.props.application.selection.selectedloan.paydown
              )}
            </span>
          );
        }

        case "cashoutrefinance":
          return (
            <span>
              {" "}
              and cashout of $
              {com.commaizeFloat(
                this.props.application.selection.selectedloan.cashout
              )}
            </span>
          );
      }
    };
    let dispplayLockDate = () => {
      if (this.props.application.selection.selectedloan.lockdate !== "")
        return (
          "starting from " +
          this.props.application.selection.selectedloan.lockdate
        );
    };
    return (
      <div>
        <div className="mt-4">
          You are doing a <b>{purpose}</b>
          {/* {this.isSubmitted() ? (
            ""
          ) : (
            <Button
              className="mx-3 my-1 py-0"
              href={"/app/interview/main/1"}
              size="sm"
              variant="outline-primary"
            >
              {" "}
              Edit
            </Button>
          )}{" "} */}
          <div>
            {" "}
            with the{" "}
            {this.props.application.property.purpose === "purchase"
              ? ""
              : " new "}{" "}
            amount of{" "}
            <b>
              ${com.commaizeFloat(getLoanAmount()[0])} {getDownpayment()}
            </b>
            {/* {this.isSubmitted() ? (
              ""
            ) : (
              <Button
                className="mx-3 my-1 py-0"
                href={"/app/interview/main/choice"}
                size="sm"
                variant="outline-primary"
              >
                {" "}
                Edit
              </Button>
            )} */}
          </div>{" "}
        </div>
        <div>
          for the property located at {getAddress()}{" "}
          {/* {this.isSubmitted() ? (
            ""
          ) : (
            <Button
              className="ml-4 my-1 py-0"
              href={getPropertyPage()}
              size="sm"
              variant="outline-primary"
            >
              {" "}
              Edit
            </Button>
          )} */}
        </div>
        {getClosingCost()}

        <div className="mt-1">
          {" "}
          Your {lockStatus()}{" "}
          <b>
            rate is {this.props.application.selection.selectedloan.base_rate}%,
            APR {this.props.application.selection.selectedloan.APR}%, monthly
            payment $
            {com.commaizeFloat(
              this.props.application.selection.selectedloan.monthly_payment.toFixed(
                2
              )
            )}{" "}
          </b>
        </div>
        {this.props.application.selection.selectedloan.FHAMIP != null
          ? displayFHA()
          : ""}
        {this.props.application.selection.selectedloan.pmi > 0
          ? displayPMI()
          : ""}
        <div>
          {this.props.application.selection.selectedloan.min_months_reserves > 0
            ? displayReserves()
            : ""}
        </div>
        <div className="mt-2">
          {" "}
          The rate will be{" "}
          <b>
            locked for {this.props.application.selection.selectedloan.lockDay}{" "}
            days
          </b>{" "}
          {dispplayLockDate()}
        </div>
      </div>
    );
  };
  render() {
    let current = com.getNumericalStatus(this.props.application.status);
    let loansubmitted = com.getNumericalStatus("interviewfinished");
    return (
      <div className="mb-5  text-left">
        <h2 className=" heading">
          The initial interview is {current < loansubmitted ? "almost" : ""}{" "}
          complete!
        </h2>
        <div className="viewport" style={{ fontSize: "1.2em" }}>
          <div className="text-left sectionquestion">
            {this.isSubmitted()
              ? "Your loan:"
              : "Let's review the loan you selected:"}
          </div>
          {this.state.loading ? (
            <div className="w-100 text-center">
              {" "}
              <img
                className="loader"
                alt="spinner"
                src={
                  com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"
                }
              ></img>
            </div>
          ) : this.state.loanhaschanged ? (
            this.loanChanged()
          ) : (
            this.displayLoan()
          )}
        </div>
        {this.state.loading || this.state.loanhaschanged ? (
          ""
        ) : (
          <div
            className="viewport mt-3 text-wrap"
            style={{ fontSize: "1.2em" }}
          >
            {current < loansubmitted ? (
              <div>
                <div className="mt-3">
                  <span className="font-weight-bold">
                    Now you are ready to send the application to your loan
                    officer.{" "}
                  </span>
                  <div className="mt-3">
                    By clicking on Submit, you confirm that all the information
                    you've provided, including occupancy, disclosure of your
                    existing loans and properties, is true and correct to the
                    best of your knowledge.
                    <br />
                    You also authorize Zeitro to execute a{" "}
                    {tooltipFlat(
                      "hard credit pull",
                      <div>
                        A hard inquiry, or a{" "}
                        <span style={{ fontWeight: "500" }}>hard pull</span>,
                        occurs when you apply for a new line of credit, such as
                        a credit card or loan. It means that a creditor has
                        requested to look at your credit file to determine how
                        much risk you pose as a borrower. Hard inquiries show up
                        on your credit report and can affect your credit score.
                      </div>,
                      "auto",
                      "font-weight-bold",
                      true,
                      true
                    )}{" "}
                    before submitting your application to the wholesale lender.
                  </div>
                </div>
                <div className="mt-3">
                  After you submit, the Zeitro Team will package and send your
                  loan application package directly to the Lender that offers
                  the product you have chosen.
                  <div className="my-3">
                    You will also be required to furnish additional supporting
                    documentation.
                  </div>
                  {this.props.application.property.purpose === "purchase" &&
                  false === this.props.application.property.hascontract ? (
                    <div>
                      The Lender will underwrite your loan and provide a
                      preapproval letter, we will be with you every step of the
                      way.
                    </div>
                  ) : (
                    <div>
                      The Lender will underwrite and fund your loan, we will be
                      with you every step of the way.
                    </div>
                  )}
                </div>
                <div className="text-center mt-3 mb-4">
                  <Button
                    className="ml-4 "
                    variant="success"
                    onClick={this.submitApplication}
                    size="sm"
                  >
                    <Spinner
                      className="mb-1 mr-1 position-relative"
                      size="sm"
                      variant="primary"
                      animation={this.state.showprogress ? "border" : ""}
                    />{" "}
                    Submit
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
            {current >= loansubmitted ? (
              <div>
                <div className="sectionquestion">
                  Congratulations on taking the biggest steps to a superior
                  Mortgage Loan, done your way!
                </div>

                {this.props.application.selection.selectedloan.lockstatus ===
                  "none" &&
                !(
                  this.props.application.property.purpose === "purchase" &&
                  false === this.props.application.property.hascontract
                ) ? (
                  <div className="mt-3">
                    Please be advised your interest rate is currently floating
                    which means it can change, if you want to lock your interest
                    rate please press this button
                    <div className="my-2">
                      <Button
                        size="sm"
                        onClick={this.lock}
                        className="lpbutton"
                      >
                        Lock my rate at{" "}
                        {
                          this.props.application.selection.selectedloan
                            .base_rate
                        }
                        %
                      </Button>
                    </div>
                    or{" "}
                    <Link to="/app/contact">
                      contact your Loan Officer {this.state.loname}
                    </Link>
                    .
                  </div>
                ) : (
                  ""
                )}

                <div className="mt-4">
                  In order to expedite your loan process, you may continue by
                  going to Action Requests and follow the prompts to uploading
                  your supporting documentation.
                </div>
                <div className="mt-3">
                  Paper documents can also be photographed and uploaded from
                  your cell phone at https://mobile.zeitro.com
                </div>

                <div className="mt-3">
                  {this.props.borrowerid ? (
                    <Link
                      onClick={() =>
                        document
                          .getElementById("documenttabs-tab-tasks")
                          .click()
                      }
                    >
                      Go to Action Requests
                    </Link>
                  ) : (
                    <Link to="/app/tasks">Go to Action Requests</Link>
                  )}
                </div>

                <div></div>
                <div className="mt-3">
                  Thank you for your business and trust.
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}
Loan = connect(mapStateToProps, mapDispatchToProps, null)(Loan);
