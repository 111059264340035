import React, { useState, useEffect } from 'react';
import { Collapse } from 'antd';
import "./Faq.css";
import { faqData, moreFaqData } from '../commonData'
export default function Faq({ faqInfo }) {
    const [items, setItems] = useState([])
    const [showMore, setShowMore] = useState(false)
    useEffect(() => {
        if (faqInfo.length === 0) {
            setItems([])

        } else {
            if (faqInfo.length > 3) {
                setItems(faqInfo.slice(0, 3))

            } else {
                setItems(faqInfo)

            }

        }


    }, [faqInfo])
    return (
        <div className="faq">
            <div className='faq_title'>
                Frequently Asked Questions
            </div>
            <div className='faq_Collapse'>
                <Collapse
                    bordered={false}
                    expandIconPosition='end'
                    items={items}
                />
            </div>
            <div className='faq_more' onClick={() => {
                if (faqInfo.length == undefined) {
                    if (!showMore){
                        setItems([...faqData, ...moreFaqData])
                        setShowMore(!showMore)
                    }
                    else{
                        setItems(faqData)
                        setShowMore(!showMore)
                    }                   

                } else {
                    if(!showMore){
                        setItems(faqInfo)
                        setShowMore(!showMore)
                    }
                    else{
                        setItems(faqInfo.slice(0, 3))
                        setShowMore(!showMore)
                    }
                }

            }}>
                {!showMore? "More FAQs" : "Close"}
            </div>
        </div>
    );
}