import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Interview from './Interview/Interview'
import Tasks from './Tasks/Tasks'
import EmployeeBoard from './EmployeeBoard/EmployeeBoard';
import { connect } from 'react-redux'
import * as act from "./Store/actions"
import * as brwr from "./Store/borrower"
import * as prgr from "./Store/progress"
import { mergeState } from "./Store"
import Documents from './Documents/Documents.js'
import FormPreview from './FormPreview'
import ContactInformation from './ContactInformation'
import CreditReport from './newCreditReport'
import LOInput from './LOInput/LOInput'
import LoanSummary from "./LoanSummary/LoanSummary";
import DocumentsVerification from './DocumentVerification/DocumentsVerification'
import { application, UR_LoanProcessor } from './State.js'
import { UpdateChangeLastTouch } from "./Store/progress"
import LoanComments from "./LoanComments";

const merge = require('deepmerge')
const deepcopy = require('deepcopy');
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    changeBorrowerId: (id) => {
        dispatch(brwr.ChangeBorrowerId(id))
    },
    changeBorrowerLoanId: (id) => {
        dispatch(brwr.ChangeBorrowerLoanId(id))
    },

    changeBorrowerName: (name) => {
        dispatch(brwr.ChangeBorrowerName(name))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
});

class Borrower extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstname: "",
            lastname: "",
            id: "",
            render: this.renderEmpty,
            remoteCoborrowers: [],
        }

        if (this.props.progress.stage === "")
            this.props.updateChangeProgress(0, "interview")
        this.aref = React.createRef();
    }
    renderEmpty = () => (<div></div>)
    componentDidMount = () => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.id = this.props.match.params["id"]
        this.setState({ id: this.props.match.params["id"] })
        this.reload()
        this.getRemoteCoborrowers()
    }

    componentWillReceiveProps = (newProps) => {
        //alert( this.props.match.params["id"] )  
        let newid = newProps.match.params["id"]
        if (this.state.id === newid)
            return
        this.forceStateSynchronization()
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.id = newid
        this.setState({ id: newid, remoteCoborrowers: [] }, () => {
            this.reload()
            this.getRemoteCoborrowers()
        })
    }

    handleSubtabTabSelect = (customerID) => {
        this.forceStateSynchronization().then(() => {
            this.reload(customerID)
        })
    }

    reload = (customer_id) => {
        if (typeof customer_id === 'undefined') {
            customer_id = ""
            this.setState({ render: this.renderEmpty })
        }
        let token = sessionStorage.getItem("ZeitroA")
        let body = { LoanID: this.state.id, CustomerID: customer_id }
        fetch('/los/lp/borrower', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        let state = JSON.parse(js.Borrower.State)

                        let serstate
                        //if ("{}" !== js.Borrower.State) {
                        try {
                            let applicationInitialState = JSON.parse((() => { let l = new application(); return l.toJson() })())
                            let app = merge(applicationInitialState, state.application, { arrayMerge: overwriteMerge })
                            state.application = app
                        } catch (x) {
                            // just keep it
                        }
                        serstate = JSON.stringify(state)
                        sessionStorage.setItem("state", serstate)
                        sessionStorage.setItem("originalstate", serstate)
                        this.props.updateChangeApplication({...state.application,a:11})
                        this.setState({ firstname: js.Borrower.FirstName, lastname: js.Borrower.LastName })
                        this.props.changeBorrowerId(js.Borrower.ID)
                        this.props.changeBorrowerLoanId(js.Borrower.LoanID)
                        this.props.changeBorrowerName(js.Borrower.FirstName + " " + js.Borrower.LastName)
                        if (state.progress != null) {
                            this.props.updateChangeProgress(state.progress.interview.max, "max")
                            this.props.updateChangeProgress(state.progress.interview.step, "interview")
                            this.props.updateChangeLastTouch(state.progress.lasttouch)
                        } else {
                            //debugger
                            this.props.updateChangeProgress(0, "max")
                            this.props.updateChangeProgress(0, "interview")
                        }
                        sessionStorage.removeItem("edit")
                        //} else {
                        //    alert("The borrower " + js.Borrower.FirstName + " " + js.Borrower.LastName + " did not input any information yet!")
                        //}
                        let index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.Borrower.ID)
                        if (index === -1) {
                            let { ID, FirstName, LastName, ContactPhone, Email } = js.Borrower
                            this.setState({ remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: true }], render: this.renderTabs })
                        }
                    }
                });
            }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
    }

    getRemoteCoborrowers = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.state.id }
        fetch('/los/remotecoborrowers', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        if (js.RemoteCoborrowers !== null) {
                            for (let i = 0; i < js.RemoteCoborrowers.length; i++) {
                                let index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.RemoteCoborrowers[i])
                                if (index === -1) {
                                    let { ID, LastName, FirstName, ContactPhone, Email } = js.RemoteCoborrowers[i]
                                    this.setState({ remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: false }] })
                                }
                            }
                            this.setState({ render: this.renderTabs })
                        }
                    }
                });
            }
        )
    }

    forceStateSynchronization = () => {
        let state = deepcopy({ borrower: this.props.borrower, application: this.props.application })
        let previousState = JSON.parse(sessionStorage.getItem("originalstate"))
        let token = window.sessionStorage.getItem("ZeitroA");
        let { id } = state.borrower
        if (id === "")
            return
        return new Promise((resolve, reject) => {
            fetch("/los/borrowerstate/" + id, {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                },
            }).then(response => {
                if (!response.ok) {
                    console.log("Auth fetch error")
                    sessionStorage.removeItem("ZeitroA")
                    window.location.href = "/"
                } else {
                    response.json().then(js => {
                        if (js.Status !== "OK") {
                            console.log("State Update Error: " + js.Status)
                            reject('rejected')
                        } else {
                            //console.log("State Update Success: " + js.Status)
                            //debugger
                            mergeState(id, js.Text, state, previousState)
                            resolve('resolved')
                        }
                    })
                }
            }).catch(error => {
                console.log("error")
            })
        })
    }

    onselect = (key, e) => {
        let mainBorrower = this.state.remoteCoborrowers.find(person => person.isMain)
        if (mainBorrower.ID !== this.props.borrower.id) {
            this.forceStateSynchronization().then(() => {
                this.reload(mainBorrower.ID)
            })
        }
        this.props.updateChangeProgressType(key)
    }
    renderTabs = () => {
        let ID = ""
        try {
            ID = this.state.remoteCoborrowers[0].ID
        } catch (x) {

        }

        return (
            <div key={this.state.id} className="p-0 m-0 mb-5">
                <Tabs unmountOnExit={true} onSelect={this.onselect} className="tabfont" variant="tabs" defaultActiveKey="loansummary" id="documenttabs">
                    <Tab eventKey="loansummary" title="Loan Summary">
                        <div>
                            <LoanSummary key={ID} borrowerid={this.props.borrower.id} /> 
                        </div>
                    </Tab>
                    <Tab eventKey="interview" title={this.state.remoteCoborrowers.length === 1 ? `${this.state.firstname} ${this.state.lastname}'s Interview` : 'Interviews'}>
                        {this.state.remoteCoborrowers.length > 1 ?
                            <Tabs unmountOnExit={true} onSelect={this.handleSubtabTabSelect} className="tabfont" variant="tabs">
                                {this.state.remoteCoborrowers.map((person, index) => (
                                    <Tab key={person.ID} eventKey={`${person.ID}`} title={`${person.FirstName} ${person.LastName}'s Interview`}>
                                        <div className="text-dark pt-5 border-left border-right">
                                            <Interview key={person.ID} borrowerid={person.ID} isDisabled={!person.isMain} />
                                        </div>
                                    </Tab>
                                ))}

                            </Tabs>
                            :
                            <div className="text-dark pt-1 border-left border-right">
                                <Interview key={this.state.id} borrowerid={this.props.borrower.id} role={UR_LoanProcessor}/>
                            </div>}
                    </Tab>
                    <Tab eventKey="contactInfo" title="Contact Information">
                        <ContactInformation data={this.state.remoteCoborrowers} />
                    </Tab>
                    <Tab eventKey="documents" title="Documents">
                        <Documents key={this.state.id} />
                    </Tab>

                    <Tab eventKey="tasks" title="Borrower Tasks">
                        <div>
                            <Tasks key={this.state.id} />
                        </div>
                    </Tab>

                    <Tab eventKey="loinput" title="Loan Officer Input">
                        <div>
                            <LOInput key={ID} borrowerid={ID} />
                        </div>
                    </Tab>
                    {/* keep this hidden, do not remove
                    <Tab eventKey="f1003" title={this.state.remoteCoborrowers.length === 1 ? '1003' : '1003s'}>
                        {this.state.remoteCoborrowers.length > 1 ?
                        <Tabs unmountOnExit="true" onSelect={this.handleSubtabTabSelect} className="tabfont" variant="tabs">
                            {this.state.remoteCoborrowers.map((person, index) => (
                                <Tab key={person.ID} eventKey={`${person.ID}`} title={`${person.FirstName} ${person.LastName}'s 1003`}>
                                    <div className="text-dark border-left border-right">
                                        <FormPreview form='1003' />
                                    </div>
                                </Tab>
                            ))}

                        </Tabs>
                        :
                        <div className="text-dark border-left border-right">
                            <FormPreview form='1003' />
                        </div>}
                    </Tab>
                    {this.props.application.selection.selectedloan !== null && this.props.application.selection.selectedloan.aus[0] === 'DU' && 
                    <Tab eventKey="f1008" title='1008'>
                        <div className="text-dark border-left border-right">
                            <FormPreview form='1008' />
                        </div>
                    </Tab>}
                    {this.props.application.selection.selectedloan !== null && this.props.application.selection.selectedloan.aus[0] === 'LP' && 
                    <Tab eventKey="f1077" title='1008'>
                        <div className="text-dark border-left border-right">
                            <FormPreview form='1077' />
                        </div>
                    </Tab> }
                    {this.props.application.selection.selectedloan !== null && this.props.application.selection.selectedloan.aus.length === 0 && 
                    <Tab eventKey="f1008" title='1008'>
                        <div className="text-dark border-left border-right">
                            <FormPreview form='undefined' />
                        </div>
                    </Tab>}
*/}
                    <Tab eventKey="creditReport" title={this.state.remoteCoborrowers.length === 1 ? 'Credit Report' : 'Credit Reports'}>

                        {this.state.remoteCoborrowers.length > 1 ?
                            <Tabs unmountOnExit="true" onSelect={this.handleSubtabTabSelect} className="tabfont" variant="tabs">
                                {this.state.remoteCoborrowers.map((person, index) => (
                                    <Tab key={person.ID} eventKey={`${person.ID}`} title={`${person.FirstName} ${person.LastName}'s Credit Report`}>
                                        <div className="text-dark pt-5 border-left border-right">
                                            <CreditReport />
                                        </div>
                                    </Tab>
                                ))}

                            </Tabs>
                            :
                            <div className="text-dark pt-5 border-left border-right">
                                <CreditReport />
                            </div>}
                    </Tab>
                    <Tab eventKey="mismo34" title='FNM 3.4'>
                        <div className="text-dark border-left border-right">
                            <FormPreview form='mismo34' />
                        </div>
                    </Tab>
                    <Tab eventKey="docVerification" title='Documents verification'>
                        <DocumentsVerification />
                    </Tab>
                    {/* <Tab eventKey="LOBoard" title="Kanban board">
                        <EmployeeBoard />
                    </Tab> */}
                </Tabs>
            </div>
        )
    }
    render() {
        return (
            <div>
                {this.state.render()}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Borrower)
