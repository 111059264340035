import React, { Component, useState } from "react";
import { Button, message, Steps, Select, Checkbox, Row, Col, Form, Table, Tabs, Dropdown, Skeleton, Space, Modal } from 'antd';
import "./CallReports.css";
import CallReportPreview from "./CallReportPreview";
import * as com from "../../Common"
import { initial, cloneDeep } from "lodash";
import {decode, Base64} from 'js-base64';
import { UR_LoanOfficer, UR_Owner,  } from "../../State";
import moment from 'moment';

class CallReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: '2023',
            selectedQuarter: this.getQuarter(),
            selectedStates: ["ca","tx"],
            currentstep: 0,
            callreportdata: undefined,
            callreportdatatoSave: undefined,
            openingGeneratedReport: false,
            loanofficers: {},
            reportSaved: false,
            showConfirmBackModal: false,
            newChange: false,
        };
    }

    componentDidMount() {
        this.getloans();
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.callreportdata !== this.state.callreportdata) {
        }
      }

    getLoanofficers = () => {
        let token = sessionStorage.getItem("ZeitroA")
        this.setState({loading: true})
        fetch('/los/loanofficerlist', {
          method: 'GET',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
              this.setState({ loading: false })
              return;
            }
            // Examine the text in the response
            response.json().then(js => {
    
              if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
                this.setState({ loading: false })
              } else {
    
                let docs = js.LoanOfficers.filter(el => el.Roles.includes(UR_LoanOfficer));
                docs = docs.map(s => {
                  let lo = cloneDeep(this.state.loanofficers)
                  lo[s.ID] = s.FirstName + " " + s.LastName + "_" + s.Nmls
                  this.setState({ loanofficers: lo })
    
                  return {
                    rand: Math.random(), id: s.ID, email: s.Email, nmlsid: s.Nmls,
                    firstname: s.FirstName, lastname: s.LastName, phone: s.Phone, image: s.Image === "" ? "/avatar.png" : s.Image
                  }
                })
                this.setState({ loanofficers_full: docs })
              }
              let loans = this.state.Loans.map(s => {
                let lo = "Unassigned"
                if (this.state.loanofficers[s.lo_id])
                  lo = this.state.loanofficers[s.lo_id]
    
                return { ...s, loanofficer: lo }
              }
    
    
              )
              
              this.setState({ Loans: loans})
              this.getAllReports();
            });
          }
        )
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
            this.setState({ loading: false })
    
          });
      }
    
    getAllReports = () => {
        let token=sessionStorage.getItem("ZeitroA")
        fetch('/los/getallcallreport', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
            }).then(
            response => {
                if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status);
                this.setState({loading: false})
                return;
                }
                response.json().then(js => {
                if (js.Status !== "OK") {
                    console.log("Error: " + js.Text)
                    this.setState({loading: false})
                } else {
                    if (js.Records) {
                        let records = js.Records.map(s => {
                            return {
                                name: s.Name,
                                quarter: s.Quarter,
                                year: s.Year,
                                state: s.State,
                                generateddate: s.GeneratedDate,
                                data: JSON.parse(decode(s.Data)),
                            }
                        })
                        this.setState({Records: records})
                    }
                    this.setState({loading: false})
                }
                });
            }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
                this.setState({loading: false})
            });
    }

    getloans = () => {
        this.setState({loading: true})
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/loansforcallreport', {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
        }).then(
        response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                this.setState({loading: false})
            return;
            }
            response.json().then(js => {

            if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
                this.setState({loading: false})
            } else {
                if (js.Loans) {
                    let docs = js.Loans.map(s => {
                        return {
                        firstname: s.FirstName,
                        lastname: s.LastName,
                        loanid: s.LoanID,
                        status: s.Status,
                        purpose:s.LoanPurpose,
                        price:s.LoanPrice,
                        started: s.Started,  
                        loanamount:s.LoanSize,
                        lo_id: s.LoanOfficerID,
                        address: s.PropertyAddressStreet,
                        county: s.PropertyAddressCounty,
                        state: s.PropertyAddressState,
                        mtype: s.TypeOfMortgage,
                        mortgageapplied: s.MortgageApplied,
                        amortization: s.Amortization,
                        loanterm: s.Term,
                        apr: s.APR,
                        baserate: s.BaseRate,
                        monthlypay: s.MonthlyPay,
                        lenderfee: s.LenderFee,
                        propertytype: s.PropertyType,
                        occupancy: s.Occupancy,
                        hascontract: s.HasContract,
                        unknownpurpose: s.UnknownPurpose,
                        }
                    })
                    this.setState({Loans: docs}, () => {
                        this.getLoanofficers();
                    })
                }
            }
            });
        }
        )
        .catch(function (err) {
            console.log('Fetch Error :-S', err);
            this.setState({loading: false})
        });
    }

    getYears = () => {
        let currentYear = new Date().getFullYear();
        let nextYear = currentYear + 1;
        let years = Array.from({length: 3}, (_, i) => nextYear - i);
        let yearsOptions = years.map((year) => {
            let yearString = year.toString();
            return <option value={yearString}>{yearString}</option>
        })
        return yearsOptions;
    }

    getQuarter = () => {
        let month = new Date().getMonth();
        switch (true) {
            case month < 3:
                return 'q1';
            case month < 6:
                return 'q2';
            case month < 9:
                return 'q3';
            default:
                return 'q4';
        }
    }

    viewGeneratedReport = (record) => {
        this.setState({
            openingGeneratedReport: true,
            callreportdata: this.state.Records.find((r) => r.name === record.name),
            currentstep: 1, 
        })
    }

    handelGoBack = () => {
        this.setState({currentstep: 0, reportSaved: false})
        if(this.state.openingGeneratedReport) {
            this.setState({openingGeneratedReport: false})
        }
    }

    renderConfirmBackModal = () => {
        return <Modal
            title={null}
            open={this.state.showConfirmBackModal}
            onCancel={() => this.setState({showConfirmBackModal: false})}
            rootClassName="confirmBackModal"
            footer={
                <div className="confirmModalFooter">
                    <Button 
                        className="noSaveButton"
                        onClick={() => {
                            this.setState({showConfirmBackModal: false})
                            this.handelGoBack(); 
                        }}
                    >
                        Exit without saving
                    </Button>
                    <Button 
                        className="saveAndGoButton"
                        onClick={() => {
                            this.handleSaveReport(); 
                            this.setState({showConfirmBackModal: false})
                            this.handelGoBack();
                        }}
                    >
                        Save and exit
                    </Button>
                </div>
            }
            >
                <div className="contentBodyWrap">
                    <div className="BodyTitle">Unsaved changes</div>
                    <div className="BodyText">Would you like to save them before leaving this page?</div>
                </div>
            </Modal>
    }

    handleUpdateReport = () => {
        console.log("check updating data", this.state.callreportdatatoSave)
        let body = {
            name: this.state.callreportdatatoSave.name,
            state: this.state.callreportdatatoSave.state,
            quarter: this.state.callreportdatatoSave.quarter,
            year: this.state.callreportdatatoSave.year,
            generateddate: this.state.callreportdatatoSave.generateddate,
            data: com.ascii2hex(JSON.stringify(this.state.callreportdatatoSave.data))
        }
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/updatecallreport', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }
            response.json().then(js => {
                if (js.Status !== "OK") {
                    console.log("Error: " + js.Text)
                }
                else {
                    this.getAllReports();
                    this.setState({reportSaved: true})
                }
            })
        })
    }

    handleSaveReport = () => {
        let body = {
            name: this.state.callreportdatatoSave.name,
            state: this.state.callreportdatatoSave.state,
            quarter: this.state.callreportdatatoSave.quarter,
            year: this.state.callreportdatatoSave.year,
            generateddate: this.state.callreportdatatoSave.generateddate,
            data: com.ascii2hex(JSON.stringify(this.state.callreportdatatoSave.data))
        }
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/savecallreport', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        },
        body: JSON.stringify(body)
        })
        .then(
        response => {
            if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
                response.status);
            return;
            }
            response.json().then(js => {

            if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
            } else {
                // alert("Report Saved Successfully")
                // this.setState({currentstep: 0}, () => {
                //     this.getAllReports();
                // })
                // if(this.state.openingGeneratedReport) {
                //     this.setState({openingGeneratedReport: false})
                // }
                this.getAllReports();
                this.setState({reportSaved: true})
            }
            });
        }
        )
        .catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
    }

    clearStateFormat = (states) => {
        let result = states.map((state) => {
            return state.replace("{", "").replace("}", "")
        })
        return result;
    }

    exportCallReport = (type) => {
        let body = {
            type: type,
            data: com.ascii2hex(JSON.stringify(this.state.callreportdatatoSave.data)),
            name: this.state.callreportdatatoSave.name,
            state: this.state.openingGeneratedReport ? this.clearStateFormat(this.state.callreportdatatoSave.state) : this.state.callreportdatatoSave.state,
            quarter: this.state.callreportdatatoSave.quarter,
            year: this.state.callreportdatatoSave.year,
        };
        console.log("checking export data", this.state.callreportdatatoSave)
        let token = sessionStorage.getItem("ZeitroA");
    
        fetch('/los/exportcallreport', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.status !== 200) {
                console.log('Error: Status Code ' + response.status);
                return;
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            if (type === "xml") {
                a.download = `${this.state.callreportdatatoSave.name}.xml`;
            } else {
                a.download = `${this.state.callreportdatatoSave.name}.xlsx`;
            }
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        })
        .catch(err => {
            console.log('Fetch Error :-S', err);
        });
    }

    createReport = (values) => {
        let initialdata = {
            // companyid: sessionStorage.getItem("ZeitroA"),
            name: "MCR_"+values.quarter.toUpperCase()+"_"+values.year,
            generateddate: new Date().toLocaleDateString('en-US'),
            state: values.states,
            quarter: values.quarter,
            year: values.year,
            data:{
                ca: {},
                tx: {}
            }
        }
        values.states.forEach((state) => {
            initialdata.data[state] = cloneDeep(com.callReportInitValues)
        })
        this.setState({callreportdata: cloneDeep(initialdata), callreportdatatoSave: cloneDeep(initialdata), currentstep: 1, reportSaved: false, newChange: false})
    }

    updateCurrentStep = (step) => {
        this.setState({currentstep: step});
    }

    render() {
    
        const allReportCols = [
            {
                title: 'File Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Quarter',
                dataIndex: 'quarter',
                key: 'quarter',
                render: (text, record) => (
                    <span>{text.toUpperCase()}</span>
                )
            },
            {
                title: 'Year',
                dataIndex: 'year',
                key: 'year',
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                render: (text, record) => (
                    <span>{String(record.state).toUpperCase().replace(/{|}/g,"")}</span>
                )
            },
            {
                title: 'Generated Date',
                dataIndex: 'generateddate',
                key: 'generateddate',
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.generateddate).unix() - moment(b.generateddate).unix(),
                render: (text, record) => (
                    <span>{moment(record.generateddate).format("MM/DD/YYYY")}</span>
                )
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record) => (
                    <Button type="link" onClick={()=>this.viewGeneratedReport(record)}>View</Button>
                )
            },
        ]

        const handleChooseState = (checkedValues) => {
            this.setState({selectedStates: checkedValues});
        }

        const handleStepsChange = (current) => {
            this.setState({currentstep: current});
        }

        // const stateOptions = [
        //     { 
        //         label: 'California',
        //         value: "ca",
        //         abbr: "CA"
        //     },
        //     { 
        //         label: 'Texas', 
        //         value: "tx",
        //         abbr: "TX"
        //     }
        // ]

        const stateOptions = Object.entries(com.mapAbbrFullName).map(([abbr, fullName]) => ({
            label: fullName,
            value: abbr.toLowerCase(),
            abbr: abbr
        }))
        const quarterOptions = [
            { label: 'Q1', value: 'q1' },
            { label: 'Q2', value: 'q2' },
            { label: 'Q3', value: 'q3' },
            { label: 'Q4', value: 'q4' }
        ]
 
       
        const steps = [
            {
              title: 'Select specifications',
              content: 
                <div style={{marginBottom: "70px", marginTop: "30px"}}>
                    <Form 
                        labelAlign="left"
                        onFinish={this.createReport}
                        initialValues={{
                            year: this.state.selectedYear,
                            quarter: this.state.selectedQuarter,
                            states: this.state.selectedStates
                          }}
                    >
                        <Row span={24} className="SpecificationInputs">
                            <Col span={4} className="pr-2">
                                <Form.Item
                                    label="Select quarter"
                                    name="quarter"
                                    rules={[{ required: true}]}
                                >  
                                    <Select className="selectFields" onChange={(value) => this.setState({selectedQuarter: value})} options={quarterOptions}/>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="pr-2">
                                <Form.Item
                                    label="Select year"
                                    name="year"
                                    rules={[{ required: true}]}
                                >    
                                    <Select className="selectFields" onChange={(value) => this.setState({selectedYear: value})}>
                                        {this.getYears()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className="text-left"
                                    label="Select states"
                                    name="states"
                                    rules={[{ required: true}]} >      
                                    <Select 
                                        mode="multiple" 
                                        className="multiselectFields" 
                                        onChange={handleChooseState} 
                                        options={stateOptions}
                                        optionRender={(option) => (
                                            <div>
                                                {option.data.label}
                                            </div>
                                        )}
                                        maxTagCount={4}
                                        tagRender={(props) => {
                                            const { label, value, closable, onClose } = props;
                                            const onPreventMouseDown = (event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            };
                                            return (
                                                <span
                                                    onMouseDown={onPreventMouseDown}
                                                    style={{ marginRight: 3 }}
                                                    className="statesTags"
                                                >
                                                    {stateOptions.find(option => option.value === value).abbr}
                                                    {closable && (
                                                        <span style={{ marginLeft: 5 }} className="statesTagsClose" onClick={onClose}>
                                                            x
                                                        </span>
                                                    )}
                                                </span>
                                            );
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                >   
                                    <Button className="generateButton" type="primary" htmlType="submit">Generate Report</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            },
            {
              title: 'Review, edit, and save data',
              content: 
                <CallReportPreview 
                    selectedStates={this.state.selectedStates}
                    selectedQuarter={this.state.selectedQuarter}
                    selectedYear={this.state.selectedYear} 
                    updateCurrentStep={this.updateCurrentStep}
                    reportdata={this.state.callreportdata}
                    openingGeneratedReport={this.state.openingGeneratedReport}
                    Loans={this.state.Loans}
                    callreportdatatoSave={this.state.callreportdatatoSave}
                    setCallReportDatatoSave={(data) => this.setState({callreportdatatoSave: data})}
                    loanofficers={this.state.loanofficers}
                    setReportSaved={(data) => this.setState({reportSaved: data})}
                    setNewChange={(data) => this.setState({newChange: data})}
                />
            },
        ]

        const exportOptions = [
            {
                label: <div onClick={() => this.exportCallReport("excel")}>
                    Export as Excel
                </div>,
                key: "1",
            },
            {
                label: <div onClick={() => this.exportCallReport("xml")}>
                    Export as XML
                </div>,
                key: "2",
            }
        ]

        const items = [
            {
                key: "select specifications",
                title: "Select specifications",
            },
            {
                key: "review and edit data",
                title: "Review, edit, and save data",
            },
        ]

        return (
            <div>
                {this.state.loading ?
                    <>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </>
                :
                    <div>
                        { this.state.currentstep === 0 && <div className="callReportStepOneWrap">
                            <div className="callReportMainHeader">Mortgage Call Reports</div>
                            <div style={{border:"2px solid #ECECEC",borderRadius:"8px", padding:"20px 20px 20px 40px", marginBottom:"20px"}}>
                            <div className="text-left" style={{fontSize:"16px", fontWeight:"600",marginBottom:"15px"}}>Generate New Report</div>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <Steps style={{width:"40%", marginBottom:"20px"}} current={this.state.currentstep}  items={items}></Steps>
                                    {this.state.currentstep === 1 && <div>
                                    <Button style={{width:"120px"}} type="primary" onClick={this.handelGoBack}>Go Back</Button>
                                    <Dropdown menu={{items: exportOptions}}>
                                        <Button>
                                            Export Report
                                        </Button>
                                    </Dropdown>
                                    {!this.state.openingGeneratedReport &&<Button style={{marginLeft:"10px",width:"120px"}} type="primary" onClick={this.handleSaveReport} >Save Report</Button>}
                                    </div>}
                                </div>
                                <div>{steps[this.state.currentstep].content}</div>
                            </div>
                            {!this.state.currentstep && <div style={{border:"2px solid #ECECEC",borderRadius:"8px", padding:"20px 20px 20px 40px "}} className="reportsTable">
                                <div className="text-left" style={{fontSize:"16px", fontWeight:"600",marginBottom:"10px"}}>Historical Reports</div>
                                <div className="descriptionText">View and download generated mortgage call reports below</div>
                                {/* make the table to display at most 5 rows in a page, add pagination */}
                                <Table 
                                    columns={allReportCols} 
                                    dataSource={this.state.Records} 
                                    bordered 
                                    pagination={{ pageSize: 4 }}
                                />
                            </div>}
                        </div>}
                        {this.state.currentstep === 1 && <div className="mt-3">
                            <div className="callReportStepWrapper">
                                <Button 
                                    className="backButton" 
                                    type="primary" 
                                    onClick={() => {
                                        if (this.state.openingGeneratedReport) {
                                            this.handelGoBack()
                                        } else if (!this.state.reportSaved) {
                                            this.setState({showConfirmBackModal: true})
                                        } else {
                                            this.handelGoBack()
                                        }
                                    }}
                                >
                                    Back
                                </Button>
                                <Steps className="stepper" current={this.state.currentstep}  items={items}></Steps>
                            </div>
                            <div className="callReportSubHeader">
                                <div className="subHeaderLeftBox">
                                    <div className="subHeaderTitle">Generate New Report</div>
                                    <div className="versionTip">{!this.state.openingGeneratedReport?this.state.selectedQuarter.toUpperCase():this.state.callreportdata.quarter.toUpperCase()}{this.state.openingGeneratedReport?"  "+this.state.callreportdata.year:("  " + this.state.selectedYear)}</div>
                                </div>
                                <div className="subHeaderRightBox">
                                    {/* {!this.state.openingGeneratedReport ? 
                                        (!this.state.reportSaved ? 
                                            <Button className="saveEditButton" type="primary" onClick={this.handleSaveReport} >Save edit</Button>
                                        : 
                                            (
                                                this.state.newChange ?
                                                <Button className="saveEditButton" type="primary" onClick={this.handleSaveReport} >Save edit</Button>
                                                :
                                                <div className="savedSuccessWrapper">
                                                    Changes saved
                                                    <img src="/images/check-circle.svg" alt="savedSuccess"/>
                                                </div>
                                            )
                                        )
                                    :
                                        ""
                                    } */}
                                    {
                                        !this.state.openingGeneratedReport ?
                                            (!this.state.reportSaved ?
                                                ( this.state.newChange ?
                                                    <Button className="saveEditButton" type="primary" onClick={this.handleUpdateReport} >Save edit</Button>
                                                    :
                                                    <Button className="saveEditButton" type="primary" onClick={this.handleSaveReport} >Save edit</Button>
                                                )
                                            :
                                                    <div className="savedSuccessWrapper">
                                                        Changes saved
                                                        <img src="/images/check-circle.svg" alt="savedSuccess"/>
                                                    </div>
                                            )    
                                        :
                                            ""
                                    }
                                    <Dropdown menu={{items: exportOptions}}>
                                        <Button type="primary" className="exportAllButton">
                                            Export
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                            <div>{steps[this.state.currentstep].content}</div>
                        </div>}
                        {this.renderConfirmBackModal()}
                    </div>
                }
            </div>
        );
    } 
}

export default CallReports;