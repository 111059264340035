import React, { useState, useEffect, useRef } from "react";
import { Input, Result } from "antd";
import { Form, Col, Row, InputGroup, Button, Container } from "react-bootstrap";
import { Spin, Alert, Divider, Select  } from "antd";
import * as com from "../Common"
import "./GMCCCalculator.css";
import DividerWithText from "../Common/components/DividerLineWithText";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import { CommaizedInput } from "../NumericalInput";

export function AffluentQualifier(props) {
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [units, setUnits] = useState("1");
  const [fico, setFico] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [purpose, setPurpose] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [visa, setVisa] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState("");
  const [requiredReserve, setRequiredReserve] = useState(null)

  const [qualified, setQualified] = useState(null)
  const [unqualifiedReason, setUnqualifiedReason] = useState("")
    
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [medianIncome, setMedianIncome] = useState(null);

  const [borrowerName, setBorrowerName] = useState("")
  const [borrowerPhone, setBorrowerPhone] = useState("")
  const [borrowerEmail, setBorrowerEmail] = useState("")
  const [borrowerPreferredTimeZone, setBorrowerPreferredTimeZone] = useState("")
  const [borrowerPreferredTime, setBorrowerPreferredTime] = useState("")
  const [loadingContact, setLoadingContact] = useState(false)
  const [language, setLanguage] = useState("english")
  const [loanLimits, setLoanLimits] = useState({})
  const loName = props.loName
  const loEmail = props.loEmail
  const loNMLS = props.loNMLS
  const loPhone = props.loPhone
  let avatarSrc = "/images/avatar.png"
  const email = props?.aboutInfo?.email
  const phone = props?.aboutInfo?.phone
  const handleLanguageChange = (value) => {
    setLanguage(value)
  }

  const fetchLoanLimits = () =>
  fetch("/data/loanlimits")
    .then((res) => {
      if (!res.ok) {
        console.log(res);
        throw Error(res.statusText);
      }
      return res.json();
    })
    .then((res) => {
      setLoanLimits(res)
    })
    .catch((err) => {
      console.error(err);
    });

  useEffect(()=> {
    fetchLoanLimits()
  }, [])

  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);
    options.push(<option value=""></option>);
    keys.forEach((x) => {
      options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];
    options.push(<option value=""></option>);
    try {
        if (loanLimits[propertyState.toUpperCase()]) {
            let keys = Object.keys(loanLimits[propertyState.toUpperCase()]);
            keys.forEach((x) => {
                options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
            });
        }
    } catch (error) {
        console.log(error)
    }

    return options;
  };

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "chinese", label: "Chinese" },
  ]

  const qualifiedCounties = ["California", "Massachusetts", "North Carolina", "South Carolina", "Virginia"]

  const visas = ["E", "G", "H", "L", "NATO", "O", "TN1", "TN2", "Other"]

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    
    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
      );
      console.log(longstate)
    setPropertyState(longstate)

    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
  };

  const primary = [
    [["singlefamily", "pud"], 2000000, 80, 720, 9],
    [["singlefamily", "pud"], 2000000, 80, 680, 12],
    [["singlefamily", "pud"], 2000000, 60, 740, 0],

    [["singlefamily", "pud"], 3000000, 80, 740, 24],
    [["singlefamily", "pud"], 3000000, 75, 720, 24],
    [["singlefamily", "pud"], 3000000, 70, 680, 24],

    [["condo", "2"], 1000000, 80, 720, 9],
    [["condo", "2"], 1000000, 75, 680, 12],
    [["condo", "2"], 1000000, 60, 740, 0],

    [["condo", "2"], 2000000, 80, 720, 12],
    [["condo", "2"], 2000000, 70, 680, 18],

    [["condo", "2"], 3000000, 80, 740, 24],
    [["condo", "2"], 3000000, 75, 720, 24],
    [["condo", "2"], 3000000, 65, 680, 24],

    [["3", "3"], 1000000, 80, 720, 9],
    [["3", "3"], 1000000, 75, 700, 12],

    [["3", "3"], 2000000, 80, 720, 12],
    [["3", "3"], 2000000, 70, 700, 18],

    [["3", "3"], 3000000, 75, 720, 24],
    [["3", "3"], 3000000, 65, 700, 24],
  ]

  const primaryCashout = [
    [["singlefamily", "pud"], 1000000, 80, 720, 9],
    [["singlefamily", "pud"], 1000000, 70, 680, 9],

    [["singlefamily", "pud"], 2000000, 75, 720, 12],
    [["singlefamily", "pud"], 2000000, 70, 680, 12],
    
    [["singlefamily", "pud"], 3000000, 70, 720, 12],

    [["condo"], 1000000, 80, 720, 9],
    [["condo"], 1000000, 65, 680, 9],
    [["condo"], 2000000, 75, 720, 12],
    [["condo"], 2000000, 65, 680, 12],
    [["condo"], 3000000, 70, 720, 12],

    [["2", "3", "4"], 1000000, 75, 740, 9],
    [["2", "3", "4"], 2000000, 75, 740, 12],
    [["2", "3", "4"], 3000000, 70, 740, 12],
  ]

  const nonOwnerPurchase = [
    [["singlefamily", "pud"], 1000000, 70, 720, 9],
    [["singlefamily", "pud"], 1000000, 65, 680, 12],

    [["singlefamily", "pud"], 2000000, 65, 720, 12],
    [["singlefamily", "pud"], 2000000, 60, 680, 18],
    
    [["singlefamily", "pud"], 3000000, 60, 720, 24],
    [["singlefamily", "pud"], 3000000, 55, 680, 24],

    [["condo"], 1000000, 70, 720, 9],
    [["condo"], 1000000, 60, 680, 12],
    [["condo"], 2000000, 65, 720, 12],
    [["condo"], 2000000, 55, 680, 18],
    [["condo"], 3000000, 60, 720, 24],
    [["condo"], 3000000, 50, 680, 24],

    [["2", "3", "4"], 1000000, 65, 720, 9],
    [["2", "3", "4"], 1000000, 60, 700, 12],
    [["2", "3", "4"], 2000000, 65, 740, 12],
    [["2", "3", "4"], 2000000, 55, 700, 18],
    [["2", "3", "4"], 3000000, 60, 740, 24],
    [["2", "3", "4"], 3000000, 50, 700, 24],
  ]


  const checkMatrices = () => {
    let reserve = null
    if (purchasePrice !== "" && downPayment !== "")  {
      let dp = com.safeParseInt(downPayment)
      let pp = com.safeParseInt(purchasePrice)
  
      let loanAmount = purpose === "purchase" ? pp - dp : dp
      let currentLTV = purpose === "purchase" ? (pp - dp)/pp : dp/pp
      currentLTV = currentLTV * 100
      if (purpose === "cashOutRefinance") loanAmount += com.safeParseInt(cashOutAmount)  

      let matrices
      if (occupancy !== "primary") {
        matrices = nonOwnerPurchase
      } else {
        if (purpose === "purchase" || purchasePrice === "rateTermRefinance") {
          matrices = primary
        } else {
          matrices = primaryCashout
        }
      }
      let found = null 
      console.log(loanAmount, com.safeParseInt(fico), propertyType, currentLTV)
      for (let m of matrices) {
        let pt = m[0]
        let maxLL = m[1]
        let maxLTV = m[2]
        let minFico = m[3]
        if (pt.includes(propertyType) && loanAmount <= maxLL && currentLTV <= maxLTV && com.safeParseInt(fico) >= minFico) {
          found = m
          console.log(found)
        }
      }
      if (found) {
        reserve = found[4]
      } 
    }
    return reserve
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setShowError(false)
    setQualified(null)
    setUnqualifiedReason("")
    setMedianIncome(null)
    setRequiredReserve(null)

    if (visa === "Other") {
      setQualified(false)
      setUnqualifiedReason("The following Visa types are eligible: " + visas.slice(0, -1).join(", "))
      return
    }


    if (qualifiedCounties.includes(propertyState)) {
      let reserve = checkMatrices()
      if (reserve === null) {
        setQualified(false)
        setUnqualifiedReason("Based on your loan amount, FICO score and LTV, you are not eligible for this program.")
        return
      }
      setRequiredReserve(reserve)
      setQualified(true)
    } else {
      setQualified(false)
      setUnqualifiedReason("The program is not available in your area.")
    }
  }
  const submitContact = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setLoadingContact(true)
    fetch("/borrower/gmcc/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
        Units: units,
        From:"Universe",
        PropertyValue: purchasePrice,
        Downpayment: downPayment,
        FICO: fico === "" ? "-":fico,
        Citizenship: citizenship,
        Visa: visa==="" ? "-":visa,
        BorrowerName: borrowerName,
        BorrowerPhone: borrowerPhone,
        BorrowerEmail: borrowerEmail,
        BorrowerPreferredTimeZone: borrowerPreferredTimeZone,
        BorrowerPreferredTime: borrowerPreferredTime,

        LOID: props.loid,
      })
    })
    .then((res) => {
      if (!res.ok) {
        setLoadingContact(false)
        console.log(res);
        return
      }

      setLoadingContact(false)
      alert("Thanks for your submission. We will contact you shortly.")

    })
    .catch((err) => {
      console.error(err);
      setLoadingContact(false)
      alert("Some internal error happened. Please try again later.")
    });

  }
  const applyUrl = props.loid==="gmcc" ? "https://app.gmccloan.com/services/preapp?customerid=gmcc&loid=83ab3e1e-c696-4bd5-b8ca-91f567925b99":  "https://app.gmccloan.com/services/preapp?customerid=gmcc&loid="+props.loid

  const contactMe = <Row className="mt-5">
  
  <Col md={6} xs={12} className="text-left">
    <div className="callbackTitle-1 mb-2">Request a call back from your loan officer</div>
    {loEmail !== null &&
      <div className="callback-lo">
        <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{width:30}}/>Your GMCC Loan Officer</div>
        <div className="callback-loinfo">
         {props.loid !== "gmcc" &&  <div className="callbackTitle-4">{loName}</div>}
          <div className="callbackTitle-4">{loEmail}</div>
          {props.loid !== "gmcc" && <div className="callbackTitle-4">{loPhone!==null? com.fixPhoneInput(loPhone):""}</div>}
          <div className="callbackTitle-4">NMLS #{loNMLS}</div>
        </div>
      </div>}
    <Form onSubmit={submitContact}>
      <Form.Group controlId="name">
        <Form.Label className="callbackTitle-2">Your name</Form.Label>
        <Form.Control type="text" placeholder="Enter your name" onChange={e=>setBorrowerName(e.target.value)} required/>
      </Form.Group>
      <Form.Group controlId="phone">
        <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
        <Form.Control type="text" placeholder="Enter your phone number" onChange={e=>setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required/>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label className="callbackTitle-2">Your email</Form.Label>
        <Form.Control type="email" placeholder="Enter your email" onChange={e=>setBorrowerEmail(e.target.value)} required/>
      </Form.Group>
      <div className="d-flex w-100">
      <Form.Group controlId="email" className="w-100 mr-2">
        <Form.Label className="callbackTitle-2">Preferred contact time</Form.Label>
        <Form.Control
                as="select"
                size="sm"
                value={borrowerPreferredTime}
                onChange={(e) => setBorrowerPreferredTime(e.target.value)}
                type="select"
                required
              >
                <option value="">Select your preferred time</option>
                <option value="8am - 9am">8am - 9am</option>
                <option value="9am - 10am">9am - 10am</option>
                <option value="10am - 11am">10am - 11am</option>
                <option value="11am - 12pm">11am - 12pm</option>
                <option value="12pm - 1pm">12pm - 1pm</option>
                <option value="1pm - 2pm">1pm - 2pm</option>
                <option value="2pm - 3pm">2pm - 3pm</option>
                <option value="3pm - 4pm">3pm - 4pm</option>
                <option value="4pm - 5pm">4pm - 5pm</option>
                <option value="5pm - 6pm">5pm - 6pm</option>
                <option value="6pm - 7pm">6pm - 7pm</option>
                <option value="7pm - 8pm">7pm - 8pm</option>
                <option value="8pm - 9pm">8pm - 9pm</option>

              </Form.Control>      
      </Form.Group>
      <Form.Group controlId="email" className=" w-100 mr-2">
        <Form.Label className="callbackTitle-2">Time zone</Form.Label>
        <Form.Control
                as="select"
                size="sm"
                value={borrowerPreferredTimeZone}
                onChange={(e) => setBorrowerPreferredTimeZone(e.target.value)}
                type="select"
                required
              >
                <option value="">Select your time zone</option>
                <option value="Eastern">Eastern UTC-5</option>
                <option value="Central">Central UTC-6</option>
                <option value="Mountain">Mountain UTC-7</option>
                <option value="Pacific">Pacific UTC-8</option>
              </Form.Control>      
      </Form.Group>
      </div>
      <Button variant="zeitro-primary" type="submit" className="w-100" >
        {loadingContact ? <div className="gmccSpin"><Spin  /></div> : "Submit"}
      </Button>
      <DividerWithText text={"Or"}/>
      <Button variant="zeitro-primary" className="w-100" onClick={()=>window.open(applyUrl, '_blank', 'noopener,noreferrer')}>
        Apply Now
      </Button>
    </Form>
  </Col>
  <Col>
  <div className='request_right  contactMeRequest'>
        <div style={{ position: 'relative' }} className='green'>
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <circle cx="28" cy="28" r="28" fill="#E3F5E3" />
          </svg>
          <div className='quote'>
            “
          </div>
        </div>
        <div className='Dream'>
          Make a Dream.

        </div>
        <div className='request_info'>
          <div className='line' />

          <div className='touch'>

            <div className='width_line' />
            <div className='touch_text'>We’ll be in touch.Shortly!</div>
          </div>
        </div>
        <div className='information '>

          <div>
          <div style={{ display: 'flex' }}> 
                              <img className='info_img' src={typeof props.basicInfo.file !== "undefined" ? props.basicInfo.file[0].url : avatarSrc}></img>
                            <div className='info_span'>
                                <div>{props.basicInfo.firstname}</div>
                                <div>
                                    {props.basicInfo.lastname}
                                </div>
                            </div>
                        </div>
            <div>{email}</div>

          </div>


          <div >
            <div>NMLS {loNMLS}</div> <div > {phone}</div>
          </div>


        </div>
      </div>
  </Col>
  </Row>

  const showAddressMessage = () => {
    if (unqualifiedReason.includes("not available in your area")) {
      return <div>
        <div>The program is only available in the following areas:</div>
          <div>{qualifiedCounties.join(", ")}</div>
      </div>
    }
  }

  const displayedLoanAmount = () => {
    if (purchasePrice === "" || downPayment === "") return ""
    let dp = com.safeParseInt(downPayment)
    let pp = com.safeParseInt(purchasePrice)

    let loanAmount = purpose === "purchase" ? pp - dp : dp
    if (purpose === "cashOutRefinance") loanAmount += com.safeParseInt(cashOutAmount)

    return <Row className="blueTips bold mb-2">
    <Col>
      <div>Your loan amount is ${com.commaize(loanAmount)}</div>
    </Col>
    </Row>
  }

  const displayFlyer = () => {

    if (language === "english") return <img src="/images/gmccflyers/GMCC_AffluentFlyer_zeitro.png" className="w-100"/>
    if (language === "chinese") return <img src="/images/gmccflyers/GMCC_AffluentFlyer_zeitroChinese.png" className="w-100"/>
  }

  return (
    <>
    <Row className="qualifierWrap">
      <Col md={6} className="text-center">
        <div className="d-flex align-items-center mt-3" style={{justifyContent:"center", marginBottom:"10px"}}>
          <div className="mr-2">Change language to</div>
          <Select defaultValue="english" onChange={handleLanguageChange} options={languageOptions}/>
        </div>
        {displayFlyer()}

      </Col>
      <Col md={6} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start"}}>
          <div className="title-3 text-center mt-3 mb-3">GMCC - Affluent</div>
          <div  className="programHighlights">
            <div className="bold"><img src="/images/bulb.svg" style={{width:28}} /> Program highlights</div>
            <ul>
              <li>No employment income necessary.</li>
              <li>Minimum Credit Score 680</li>
              <li>Loan amount up to $3M</li>
              <li>Currently available for selected counties in CA, MA, NC, SC, VA </li>
            </ul>
          </div> 

        <div className="hfaSubTitle text-center mb-4 ">
        Check your eligibility using assets as income for special incentive programs with attractive prime jumbo rates and start your homeownership journey!
        </div>


        <div className="qualifierFormWrap">   
          <Form onSubmit={handleSubmit} className="qualifierForm">
          <Row>
            <Col  md={6} >
              <AddressAutocomplete
              id={"affluentaddress"}
              label="Property address"
              required={true}
              name="addessline"
              defaultValue={displayedAddress}
              onChange={(e) => setDisplayedAddress(e.target.value)}
              pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
              placeholder="Please type your home address and select from the dropdown addresses"
              goodfeedback="Looks good!"
              badfeedback="Please provide your street address."
              onCompletion={e=>onStreetAddressCompletion(e)}
              size="md"
              />
            </Col>
            <Col md={6}>
              <Form.Group controlId="purpose" className="">
                <Form.Label className="text-left">Property type</Form.Label>
                <Form.Control
                    required
                    name="propertyType"
                    as="select"
                    size="md"
                    defaultValue={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="singlefamily">Single family</option>
                    <option value="condo">Condo</option>
                    <option value="pud">PUD</option>
                    <option value="2">2 units property</option>
                    <option value="3">3 units property</option>
                    <option value="4">4 units property</option>
                </Form.Control>
                </Form.Group>
            </Col>
          </Row>
          <Row className=" ">
              <Col xs={6}>
                  <Form.Label className="text-left">Property state</Form.Label>
                  <Form.Group >
                      <Form.Control
                      required
                      size="sm"
                      as="select"
                      placeholder="State"
                      onChange={(e) => {
                          setPropertyState(e.target.value, 'borrower');
                      }}
                      value={propertyState}
                      >
                      {getStates()}
                      </Form.Control>
                  </Form.Group>
              </Col>
              <Col xs={6}>
                  <Form.Group >
                  <Form.Label className="text-left">Property county</Form.Label>
                      <Form.Control
                      required
                      size="sm"
                      as="select"
                      onChange={(e) => {
                          setPropertyCounty(e.target.value, 'borrower');
                      }}
                      value={propertyCounty}
                      >
                      {getCounties()}
                      </Form.Control>
                  </Form.Group>
              </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="purpose" className="">
                <Form.Label className="text-left">Loan purpose</Form.Label>
                <Form.Control
                    required
                    name="purpose"
                    as="select"
                    size="md"
                    defaultValue={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="purchase">Purchase</option>
                    <option value="rateTermRefinance">Rate/Term refinance</option>
                    <option value="cashOutRefinance">Cash out refinance</option>
                </Form.Control>
                </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="purpose" className="">
                <Form.Label className="text-left">Occupancy</Form.Label>
                <Form.Control
                    required
                    name="occupancy"
                    as="select"
                    size="md"
                    defaultValue={occupancy}
                    onChange={(e) => setOccupancy(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="primary">Primary</option>
                    <option value="secondHome">Second home</option>
                    <option value="investment">Investment(rental)</option>
                </Form.Control>
                </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col  md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
              <Form.Group>
                <Form.Label>Property price</Form.Label>
                <CommaizedInput value={purchasePrice} onChange={e=>setPurchasePrice(e.target.value)} withDollarSign/>
              </Form.Group>
            </Col>
            <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
              <Form.Group>
                <Form.Label>{purpose === "purchase"? "Down payment": "Current balance"}</Form.Label>
                <CommaizedInput className="gmccCalculatorInput" value={downPayment} onChange={e=>setDownPayment(e.target.value)} withDollarSign/>
              </Form.Group>
            </Col>
            {purpose === "cashOutRefinance" && <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Cash out amount</Form.Label>
                <CommaizedInput className="gmccCalculatorInput" value={cashOutAmount} onChange={e=>setCashOutAmount(e.target.value)} withDollarSign/>
              </Form.Group>
            </Col>
            }
          </Row>
          {displayedLoanAmount()}
          <Row className="">
            <Col md={6} >
                <Form.Group controlId="citizenship" className="">
                <Form.Label className="text-left">Citizenship</Form.Label>
                <Form.Control
                    name="citizenship"
                    as="select"
                    size="md"
                    defaultValue={citizenship}
                    onChange={(e) => setCitizenship(e.target.value)}
                >
                    <option value="">...</option>
                    {props.type !== "foreigners" && <option value="citizen">US Citizen</option>}
                    <option value="permanent">US Permanent Resident</option>
                    <option value="alien">Non-US Citizen</option>
                </Form.Control>
                </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>FICO </Form.Label>
                <Form.Control type="number" placeholder="" onChange={e=>setFico(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          {citizenship === "alien" &&    <Col >
                <Form.Group controlId="visa">
                    <Form.Label className="text-left">Visa</Form.Label>
                    <Form.Control
                    name="visa"
                    as="select"
                    size="md"
                    defaultValue={visa}
                    onChange={(e) => setVisa(e.target.value)}
                    >
                      {visas.map((v, i) => {
                        return <option key={i} value={v}>{v}</option>
                      }
                      )}
                    </Form.Control>
                </Form.Group>
            </Col>}

          <Row className="ml-2">
            <Col className="text-center" >
              <Button type="submit" variant="zeitro-primary">{loading? <div className="gmccSpin"><Spin  /></div>: "Check my eligibility"}</Button>
            </Col>
          </Row>

          </Form>
          
          {!loading && showError && <div className="mx-2 mt-3">
            <Alert message="Sorry, we're unable to process the information provided. Please verify your address and select an option from the dropdown list." type="warning" showIcon/>
            </div>}
          {!loading && qualified ===true &&   <Result
              status="success"
              title="You may be qualified for the Affluent program!"
              subTitle="Additional restriction required and subject to underwriting approval. The Debt to Asset qualifier will require 1.2 – 2 times more in assets since there is no income qualification. Please contact your GMCC agent for more details."
          />}
          {!loading && qualified === false &&   <Result
              status="warning"
              title="You're not qualified for the program."
              subTitle={<div>
                <div>{unqualifiedReason}</div>
                {showAddressMessage()}
                <div>You might be qualified for other programs, please contact the loan officer for more information.</div>
              </div>}
          />}
          <Divider />
          <Row className="qualifierDisclaimerWrap">
            <div className="disclaimerContainer">
              <div className="title-6 mb-2">
                Disclaimer:
              </div>
              {props.type === "celebrityLargerAssets" && <div className="title-6 mb-2">
                The Debt-to-Asset qualifier will require 1.2 – 2 times more assets, as there is no income qualification. Contact a GMCC Loan Officer for more details.
              </div>}
              <div className="title-6">
                All loans are subject to credit approval. Interest rates are subject to change daily and without notice. Current interest rates shown our indicative of market conditions and individual qualifications and will vary upon your lock-in period, loan type, credit score, purpose and loan to value.
              </div>
            </div>
          </Row>
        </div>
      </Col>
    </Row>
    <Divider />
    <div className="mb-5">
    {contactMe}
    </div>
    </>
  );
};