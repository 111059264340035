import { ADD_ALL_NOTIFICATIONS_GENERAL, REMOVE_GENERAL_NOTIFICATION, ADD_ALL_NOTIFICATIONS_SPECIFIC, REMOVE_SPECIFIC_NOTIFICATION } from '../Defs/notificationsdefs'
import { notifications } from '../State.js'

const initialNotifications = new notifications()
const initialNotificationsJSON = JSON.parse(initialNotifications.toJson())
export default function notificationsReducer(state = initialNotificationsJSON, action) {
    state = notifications.fromJson(state)
    switch(action.type){
      case ADD_ALL_NOTIFICATIONS_GENERAL:{
        state.general = action.payload
        break;
      }
      case REMOVE_GENERAL_NOTIFICATION:{
        let index = state.general.findIndex(element => element.id === action.id)
        state.general.splice(index, 1)
        break;
      }
      case ADD_ALL_NOTIFICATIONS_SPECIFIC:{
        state.specific = action.payload
        break;
      }
      case REMOVE_SPECIFIC_NOTIFICATION:{
        let notificationIndex = state.specific.findIndex(n => n.id === action.payload)
        state.specific.splice(notificationIndex, 1)
        break;
      }
      
      default:
        break;
    }
    return JSON.parse(state.toJson());
}