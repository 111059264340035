import React from 'react';
import './DividerWithText.css';

const DividerWithText = ({ text }) => {
  return (
    <div className="divider-container">
      <div className="divider-line"></div>
      <span className="divider-text">{text}</span>
      <div className="divider-line"></div>
    </div>
  );
};

export default DividerWithText;
