import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Slider, Row, Col, Upload } from 'antd';
import { PlusOutlined, MinusOutlined, RotateLeftOutlined, RotateRightOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './CropperModal.css'
const CropperModal = ({ visible, type = 'normal', aspectRatio = null, imageSrc, onClose, onSave }) => {
  const [croppedImage, setCroppedImage] = useState(imageSrc);
  const [zoomValue, setZoomValue] = useState(1);
  const cropperRef = useRef(null);
  const MIN_ZOOM = 0.1;
  const MAX_ZOOM = 3;
  useEffect(() => {
    setCroppedImage(imageSrc);
    setZoomValue(1);
  }, [imageSrc, visible]);

  const handleZoom = (value) => {
    if (value < MIN_ZOOM) value = MIN_ZOOM;
    if (value > MAX_ZOOM) value = MAX_ZOOM;

    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.zoomTo(value);
      setZoomValue(value);
    }
  };

  const handleRotate = (degree) => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.rotate(degree);
    }
  };

  const handleReplace = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setCroppedImage(reader.result);
      setZoomValue(1);
    };
  };

  const handleCancel = () => {
    // setCroppedImage(null);
    onClose();
  };

  const handleSave = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      onSave(cropper.getCroppedCanvas().toDataURL());
      onClose();
    }
  };

  const handleWheel = (event) => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const newZoomValue = zoomValue + (event.deltaY > 0 ? -0.1 : 0.1);
      handleZoom(newZoomValue);
    }
  }

  return (
    <Modal
        className='photo-cropper-modal'
        maskClosable={false}
        width={950}
        closable={false}
        open={visible}
        onCancel={onClose}
        destroyOnClose
        footer={[
            <Button className='cropper-modal-btn ghost-btn' key="delete" onClick={handleCancel}>Cancel</Button>,
            <div>
                <Upload key="replace" showUploadList={false} beforeUpload={handleReplace}>
                    <Button className='cropper-modal-btn ghost-btn'>Replace Photo</Button>
                </Upload>
                <Button style={{marginLeft: 14}} className='cropper-modal-btn' key="save" type="primary" onClick={handleSave}>Save</Button>
            </div>
        ]}
    >
      {croppedImage && (
          <div style={{flex: 1}} onWheel={handleWheel}>
              <Cropper
                  className={type === 'circle' ? 'circle-cropper' : ''}
                  src={croppedImage}
                  style={{ height: 'calc(100vh - 170px)', width: '100%', marginBottom: 24 }}
                  aspectRatio={aspectRatio}
                  guides={false}
                  rotatable={true}
                  ref={cropperRef}
                  zoomTo={zoomValue}
                  viewMode={1}
              />
          </div>
        )}
        <Row className='cropper-setting'>
          <Col span={24}>
              <div style={{color: '#000', fontWeight: 600, lineHeight: '20px'}}>Zoom</div>
              <div className='zoom-slider-wrap'>
                  <MinusOutlined onClick={() => handleZoom(zoomValue - 0.1)} />
                  <Slider
                      className='zoom-slider'
                      min={0.1}
                      max={3}
                      step={0.1}
                      value={zoomValue}
                      onChange={handleZoom}
                  />
                  <PlusOutlined onClick={() => handleZoom(zoomValue + 0.1)} />
              </div>
          </Col>
        </Row>
    </Modal>
  );
};

export default CropperModal;