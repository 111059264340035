import React from "react";
import { Row, Col } from "react-bootstrap";

export const YellowWarning = (props) => {

    return  <div className="ratesWarning">
    <Row className="justify-content-md-center">
      <Col xs={1}>
        <img
          src="/images/warningSign.png"
          style={{ maxWidth: "20px" }}
        />
      </Col>
      <Col xs={11}>
        {props.text}
      </Col>
    </Row>
  </div>
  }