import React, { useState, useEffect } from "react"
import { Switch, Col, Row, Tooltip, message, Modal, Spin } from 'antd'
import AutoReminderModal from "../../../src/DocumentChecklist/AutoReminderModal";
import './index.css'
import { checkAccess } from "../../Auth"
import { set } from "lodash";



const Feature = () => {
    const [isSwitch, setSwitch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAutomatedPreQualLetter, setAutomatedPreQualLetter] = useState(false);
    const [switchLoading, setSwitchLoading] = useState(false)
    const [creditAccountNotSet, setCreditAccountNotSet] = useState(true)
    const [reminderMode, setReminderMode] = useState('')
    const [showAutoReminderModal, setShowAutoReminderModal] = useState(false)
    const [reminderSettings, setReminderSettings] = useState({
        TurnedOn: true,
        ReminderFrequency: 'every2Days',
        EndWhen: {
            WhenLoanFunded: true,
            AfterNumberOfDays: null,
            WhenDate: null
        }
    })
    const [disabledReminderOpen, setDisabledReminderOpen] = useState(false)
    useEffect(() => {
        getFeatureCustomization();
    }, [])
    const isConfigured = (data) => {
        const { ReminderFrequency, EndWhen } = data
        const isReminderFrequencyEmpty = ReminderFrequency === ''
        const isEndWhenValuesNull = Object.values(EndWhen).every(value => value === null)
        if (isReminderFrequencyEmpty && isEndWhenValuesNull) {
            return false
        }
        return true
    }
    const getList = async (value) => {
        let token = sessionStorage.getItem("ZeitroA")
        const response = await fetch('/los/getcreditaccountconfig', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const js = await response.json()
        setLoading(false)
        const isNotSet = js?.Accounts?.findIndex(x => x.AutomatedSoftCreditPull) !== -1 ? false : true
        setCreditAccountNotSet(isNotSet)
        if (isNotSet || !isConfigured(value.DocumentsAutoReminder)) {
            if (isNotSet) {
                setSwitch(false)
            }
            let data = { 
                AutomatedCreditPull: isNotSet ? false : value.AutomatedCreditPull,
                DocumentsAutoReminder: isConfigured(value.DocumentsAutoReminder) ? value.DocumentsAutoReminder : reminderSettings
            }
            const res = await fetch(`/los/setfeaturecustomization`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
        }
        if (isConfigured(value.DocumentsAutoReminder)) {
            setReminderSettings(value.DocumentsAutoReminder)
        }
    }
    const getFeatureCustomization = async () => {
        setLoading(true)
        try {
            let token = sessionStorage.getItem("ZeitroA")
            const response = await fetch(`/los/getfeaturecustomization`, {
                method: 'get',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
            const data = await response.json()
            const { AutomatedCreditPull, AutomatedPreQualLetter } = data
            setSwitch(AutomatedCreditPull)
            setAutomatedPreQualLetter(AutomatedPreQualLetter)
            getList(data)
        } catch (error) {
            setSwitch(false)
            setAutomatedPreQualLetter(false)
            setLoading(false)
            alert('An unexpected error occurred. Please try again later.')
        }

    }

    const handleChangeAutomatedPreQualLetter = async (e) => {
        setAutomatedPreQualLetter(e);
        let token = sessionStorage.getItem("ZeitroA")
        const response = await fetch(`/los/setfeaturecustomization`, {
            method: 'POST',
            body: JSON.stringify({
                AutomatedPreQualLetter: e,
            }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            },
        });
        if (response.status !== 200) {
            alert("An unexpected error occurred. Please try again later.");
        }
    }
    const handleReminder = (checked) => {
        setReminderSettings((prevSettings) => ({
            ...prevSettings,
            TurnedOn: checked
        }))
        setReminderMode('switch')
        if (checked) {
            setSwitchLoading(true)
            setTimeout(() =>{
                setShowAutoReminderModal(true)
                setSwitchLoading(false)
            }, 1000)
        } else {
            let data = {
                ...reminderSettings,
                TurnedOn: false
            }
            ReminderSave(data)
        }
    }
    const ReminderCancel = () => {
        setShowAutoReminderModal(false)
        getFeatureCustomization()
    }
    const ReminderSave = async (data) => {
        let bodyData = {
            AutomatedCreditPull: isSwitch,
            DocumentsAutoReminder: {
                ...data,
                EndWhen: {
                    ...data.EndWhen,
                    WhenDate: null,
                    AfterNumberOfDays: data.EndWhen.WhenLoanFunded === true ? null : data.EndWhen.AfterNumberOfDays,
                }
            }
        }
        let token = sessionStorage.getItem("ZeitroA")
        fetch(`/los/setfeaturecustomization`, {
            method: 'POST',
            body: JSON.stringify(bodyData),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
    
        }).then(
            response => {
                if (response.status !== 200) {
                    return
                }
                setShowAutoReminderModal(false)
                getFeatureCustomization()
                if (reminderMode === 'edit') { 
                    message.open({
                        icon: null,
                        duration: 2,
                        content: <div style={{display: 'flex', alignItems: 'center', gap: 10}}><span>Default auto-reminder setting saved</span><img src='/images/document-remind-open-white.svg' /></div>,
                        className: 'auto-reminder-off',
                    })
                } else {
                    setDisabledReminderOpen(true)
                    setTimeout(() => {
                        setDisabledReminderOpen(false)
                    }, 10000)
                }
            }
    
        ).catch((err) => {
   
        })
    }
    const frequencyText = (text) => {
        switch (text) {
            case 'daily':
                return 'daily'
            case 'every2Days':
                return 'every 2 days'
            case 'every3Days':
                return 'every 3 days'
            case 'weekly':
                return 'weekly'
            case 'biWeekly':
                return 'bi-weekly'
            default:
                return ''
        }
    }
    const dataText = (status) => {
        if (status === true) {
            return 'until loan status becomes “Loan funded”.'
        } else if (status === false) {
            return `for up to ${reminderSettings.EndWhen.AfterNumberOfDays} days.`
        } else {
            return ''
        }
    }
    const reminderText = () => {
        if (reminderSettings.TurnedOn) {
            return <div>This setting applies to future loans and automatically sends email reminders for pending document requests to borrowers <span style={{color: '#325CEB', fontWeight: 500}}>{frequencyText(reminderSettings.ReminderFrequency)} {dataText(reminderSettings.EndWhen.WhenLoanFunded)}</span></div>
        } else {
            return 'Set up automatic email reminders for pending document requests to borrowers for all of your future loans.'
        }
    }
    const editReminder = () => {
        setReminderMode('edit')
        setShowAutoReminderModal(true)
    }
    const disabledReminderCancel = () => {
        setDisabledReminderOpen(false)
    }
    return (
        <div className='mx-3 ' style={{ padding: '0px 20px 0px 20px' }}>

            <div className='User_title mt-4 mb-3 mr-5 Support'>
                <div>
                    Feature Customization
                </div>
            </div>
            <div className='Customize'>Customize your borrowers' application experience</div>
            <Row className="feature_row">
                <Col span={24}>
                    <Spin spinning={loading}>
                        <div className="usage">
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                                <div className="usage_title">Automated Soft Credit Pulls</div>
                                <div>
                                    {
                                        creditAccountNotSet ? <div> <Tooltip placement="left" title={checkAccess(["Owner"]) ? 'This feature requires a credit account. Please configure your credit account in Management to unlock it.' : 'This feature requires a credit account. Please contact your account admin to configure your credit account in Management and unlock it.'}>
                                            <Switch checked={isSwitch} onChange={async (e) => {

                                                setSwitch(false)


                                            }}></Switch>
                                        </Tooltip>
                                        </div> : <Switch checked={isSwitch} onChange={async (e) => {
                                            setSwitch(e)
                                            let token = sessionStorage.getItem("ZeitroA")
                                            const response = await fetch(`/los/setfeaturecustomization`, {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    AutomatedCreditPull: e,
                                                    DocumentsAutoReminder: reminderSettings
                                                }),
                                                headers: {
                                                    Authorization: "Bearer " + token,
                                                    Cache: "no-cache"
                                                }
                                            })
                                            if (response.status !== 200) {
                                                alert('An unexpected error occurred. Please try again later.')
                                            }




                                        }}></Switch>

                                    }


                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%", marginTop: "15px" }}>
                                <div className="usage_content">When you enable this feature, it allows your borrowers to opt-in for automated soft credit checks. Once activated, a soft credit pull is conducted when your borrower provides their Social Security Number (SSN) and consents to the credit check in the "Liability" section of the application process.</div>

                            </div>

                        </div>
                    </Spin>
                </Col>
                <Col span={24}>
                    <div className="usage">
                        <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                            <div className="usage_title">Auto Generate Pre-Qual Letter</div>
                            <div>
                                <Switch 
                                    checked={isAutomatedPreQualLetter} 
                                    onChange={handleChangeAutomatedPreQualLetter}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: 'space-between', width: "100%", marginTop: "15px" }}>
                            <div className="usage_content">When activated, your borrower will automatically receive a pre-qualification letter.</div>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <Spin spinning={loading}>
                        <div className="usage">
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                                <div className="usage_title">Auto-Reminder for Documents</div>
                                <div><Switch checked={reminderSettings.TurnedOn} loading={switchLoading} onChange={handleReminder}></Switch></div>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%", marginTop: "15px", alignItems: "end" }}>
                                <div className="usage_content">{reminderText()}</div>
                                <div className="auto-reminder-edit" onClick={editReminder}>Edit</div>
                            </div>

                        </div>
                    </Spin>
                </Col>
            </Row>
            <AutoReminderModal
                open={showAutoReminderModal}
                settings={reminderSettings}
                type="futureLoans"
                save={ReminderSave}
                onCancel={ReminderCancel}
            >
            </AutoReminderModal>
            <Modal
                className='document-reminder-modal'
                centered
                zIndex={1001}
                width={500}
                open={disabledReminderOpen}
                onCancel={disabledReminderCancel}
                footer={null}
            >   
                <div style={{margin: '46px 0 0'}} className='document-reminder-wrap'>
                    {reminderSettings.TurnedOn && <img src='/images/document-send-reminder.svg' />}
                    <div style={{fontSize: 20, fontWeight: 600, margin: reminderSettings.TurnedOn ? '40px 0 44px' : '19px 0 28px', color: '#222', lineHeight: '24px'}}>Default Auto-reminder {reminderSettings.TurnedOn ? 'On' : 'Disabled'}</div>
                    <div style={{maxWidth: 410, fontSize: 16, margin: '0 0 20px', color: '#222', lineHeight: '24px', textAlign: 'center'}}>We will {reminderSettings.TurnedOn ? '' : 'not'} send automatic email reminder of pending documents for your future loans.</div>
                    <div style={{maxWidth: 410, fontSize: 14, margin: reminderSettings.TurnedOn ? '0 0 22px' : '0 0 62px', color: '#6E6E70', lineHeight: '18px', textAlign: 'center'}}>This does not change the setting for existing loans.</div>
                </div>
            </Modal>
        </div>
    )

}
export default Feature