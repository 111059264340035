import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import "./App.css";

const timedelta = 200;

export default class NumericalInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noarrow: this.props.noarrow ? this.props.noarrow : false,
      value: this.props.defaultValue,
      minimum: this.props.min,
      maximum: this.props.max,
      step: this.props.step,
      size: this.props.size,
    };
    if (typeof this.state.minimum === "undefined") this.state.minimum = 0;
    if (typeof this.state.size === "undefined") this.state.size = "sm";
    if (typeof this.state.step === "undefined") this.state.step = 100;
    this.intervalid = 0;
    this.intervaldirecton = 0;

    this.ref = React.createRef();
  }
  componentDidMount() {
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }
  }

  componentWillUnmount() {}
  decommaizeNumeric = (v) => {
    let n = v.replace(/[^0-9-]/g, "");
    if (n === "") return n;
    return parseInt(n);
  };
  commaize = (v) => {
    if (null === v || typeof v === "undefined") {
      if (typeof this.state.minimum !== "undefined")
        return this.state.minimum.toString();
      return "";
    }

    v = v.toString();
    let input = v.replace(/[^0-9-]/g, "");

    if (input === "") {
      return "";
    }

    input = parseInt(input);
    input = input.toString();
    let output = "";
    for (;;) {
      if (input.length <= 3) {
        output = input + output;
        break;
      }
      let right = input.substr(-3);
      let left = input.substr(0, input.length - 3);
      input = left;
      output = "," + right + output;
    }
    return output;
  };
  onChange = (e) => {
    let v = e.target.value;
    e.target.value = this.commaize(v);
    let value = this.decommaizeNumeric(v).toString();

    this.props.onChange({ target: { value: value } });
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }
  };
  onBlur = (e) => {
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }

    let v = this.decommaizeNumeric(e.target.value);
    if (isNaN(v) || e.target.value === "") {
      v = "";
      if ("undefined" !== typeof this.props.onBlur)
        this.props.onBlur({ target: { value: "" } });
      return;
    }

    v = this.limit(v).toString();

    e.target.value = this.commaize(v);
    let value = this.decommaizeNumeric(v);

    if (!this.props.onBlur) return;

    this.props.onBlur({ target: { value: value } });
  };
  limit = (v) => {
    if (typeof this.state.minimum !== "undefined" && v < this.state.minimum)
      v = this.state.minimum;
    if (typeof this.state.maximum !== "undefined" && v > this.state.maximum)
      v = this.state.maximum;
    return v;
  };
  up = (e) => {
    let fs = window.document.getElementsByTagName("fieldset");

    if (fs.length > 0 && fs[0].disabled) return;

    let val = this.limit(
      this.decommaizeNumeric(this.ref.current.value) + this.state.step
    );
    this.ref.current.value = this.commaize(val);

    if (this.ref.current.value === "") {
      if (typeof this.state.minimum !== "undefined")
        this.ref.current.value = this.state.minimum;
      else this.ref.current.value = 0;
    }
    this.props.onChange({ target: { value: val.toString() } });
  };
  down = (e) => {
    let fs = window.document.getElementsByTagName("fieldset");
    if (fs.length > 0 && fs[0].disabled) return;

    let val = this.limit(
      this.decommaizeNumeric(this.ref.current.value) - this.state.step
    );
    this.ref.current.value = this.commaize(val);
    if (this.ref.current.value === "") {
      if (typeof this.state.maximum !== "undefined")
        this.ref.current.value = this.state.maximum;
      else this.ref.current.value = 0;
    }

    this.props.onChange({ target: { value: val.toString() } });
  };
  downUp = (e) => {
    this.intervalid = window.setInterval(this.up, timedelta);
  };
  upUp = (e) => {
    window.clearInterval(this.intervalid);
  };
  downDown = (e) => {
    this.intervalid = window.setInterval(this.down, timedelta);
  };
  upDown = (e) => {
    window.clearInterval(this.intervalid);
  };
  render() {
    let toexpand = {};
    if ("undefined" !== typeof this.props.defaultValue) {
      toexpand["defaultValue"] = this.commaize(this.props.defaultValue);
    }
    if ("undefined" !== typeof this.props.value) {
      toexpand["value"] = this.commaize(this.props.value);
    }

    return (
      <span
        data-reactroot=""
        className="react-numeric-input w-100"
        style={this.state.wrap}
      >
        <InputGroup size={this.state.size}>
          <InputGroup.Prepend >
            <InputGroup.Text  >
              $
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Form.Control
            isValid={this.props.isValid}
            name={this.props.name}
            id={this.props.id}
            // size={this.state.size}
            {...toexpand}
            onChange={this.onChange}
            onBlur={this.onBlur}
            type="text"
            ref={this.ref}
            required={this.props.required}
            pattern="^[0-9,]+$"
          />
          {/* {!this.props.noarrow && <InputGroup.Append>
            <div>
              <b
                onClick={this.up}
                onMouseDown={this.downUp}
                onMouseUp={this.upUp}
                onMouseLeave={this.upUp}
                className="spinbtn spinbtnUp"
              >
                <i
                  style={{ fontFamily: "Helvetica,Arial" }}
                  className="spinarrowUp"
                ></i>
              </b>
              <b
                onClick={this.down}
                onMouseDown={this.downDown}
                onMouseUp={this.upDown}
                onMouseLeave={this.upDown}
                className="spinbtn spinbtnDown"
              >
                <i
                  style={{ fontFamily: "Helvetica,Arial" }}
                  className="spinarrowDown"
                ></i>
              </b>
            </div>
          </InputGroup.Append>} */}
        </InputGroup>
      </span>
    );
  }
}

export class PennyInput extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }
  dotdecommaizeNumeric = (v) => {
    let n = v.replace(/[^0-9-.]/g, "");
    if (n === "") return n;
    let ret = parseFloat(n);
    return ret;
  };
  dotcommaize = (v) => {
    if (typeof v === "undefined" || v === null) return ""
    v = v.toString();
    let input = v.replace(/[^0-9.]/g, "");

    if (input === "") {
      return "";
    }

    let postdot = "";
    if (input.indexOf(".") >= 0) {
      let sp = input.split(".");
      input = sp[0];
      postdot = sp[1];
    }

    input = parseInt(input);
    input = input.toString();
    let output = "";
    for (;;) {
      if (input.length <= 3) {
        output = input + output;
        break;
      }
      let right = input.substr(-3);
      let left = input.substr(0, input.length - 3);
      input = left;
      output = "," + right + output;
    }

    if (postdot.length > 2) {
      postdot = postdot.substr(0, 2);
    }
    if (v.indexOf(".") < 0) return output;
    return output + "." + postdot;
  };

  onChange = (e) => {
    let v = e.target.value;

    e.target.value = this.dotcommaize(v);

    let value =
      e.target.value !== ""
        ? this.dotdecommaizeNumeric(e.target.value).toFixed(2).toString()
        : "";

    this.props.onChange({ target: { value: value } });
  };

  onBlur = (e) => {
    let v = this.dotdecommaizeNumeric(e.target.value);
    if (isNaN(v)) {
      v = "";
      if (!this.props.onBlur) this.props.onBlur({ target: { value: "" } });
    }

    e.target.value = this.dotcommaize(v);

    let value = this.dotdecommaizeNumeric(e.target.value);
    if (value !== "")
      value = this.dotdecommaizeNumeric(e.target.value).toFixed(2).toString();
    this.ref.current.value = value;

    if (!this.props.onBlur) return;

    this.props.onBlur({ target: { value: value } });
  };

  render() {
    return (
      <span data-reactroot="" className="react-numeric-input w-100">
        <InputGroup size="sm">
          <InputGroup.Prepend>
            <InputGroup.Text style={{ fontSize: "0.9em" }} size="sm">
              $
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Form.Control
            isValid={this.props.isValid}
            name={this.props.name}
            id={this.props.id}
            size={this.props.size}
            defaultValue={this.dotcommaize(this.props.defaultValue)}
            onChange={this.onChange}
            onBlur={this.onBlur}
            type="text"
            ref={this.ref}
            required={this.props.required}
            pattern="^[0-9,.]+$"
          />
        </InputGroup>
      </span>
    );
  }
}

export class NumericalInputSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
      minimum: this.props.min,
      maximum: this.props.max,
      step: this.props.step,
    };
    if (typeof this.state.minimum === "undefined") this.state.minimum = 0;
    if (typeof this.state.step === "undefined") this.state.step = 100;
    this.intervalid = 0;
    this.intervaldirecton = 0;

    this.ref = React.createRef();
  }
  componentDidMount() {
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }
  }

  componentWillUnmount() {}
  decommaizeNumeric = (v) => {
    let n = v.replace(/[^0-9-]/g, "");
    if (n === "") return n;
    return parseInt(n);
  };
  commaize = (v) => {
    if (null === v || typeof v === "undefined") {
      if (typeof this.state.minimum !== "undefined")
        return this.state.minimum.toString();
      return "";
    }

    v = v.toString();
    let input = v.replace(/[^0-9-]/g, "");

    if (input === "") {
      return "";
    }

    input = parseInt(input);
    input = input.toString();
    let output = "";
    for (;;) {
      if (input.length <= 3) {
        output = input + output;
        break;
      }
      let right = input.substr(-3);
      let left = input.substr(0, input.length - 3);
      input = left;
      output = "," + right + output;
    }
    return output;
  };
  onChange = (e) => {
    let v = e.target.value;
    e.target.value = this.commaize(v);
    let value = this.decommaizeNumeric(v).toString();

    this.props.onChange({ target: { value: value } });
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }
  };
  onBlur = (e) => {
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }

    let v = this.decommaizeNumeric(e.target.value);
    if (isNaN(v) || e.target.value === "") {
      v = "";
      if ("undefined" !== typeof this.props.onBlur)
        this.props.onBlur({ target: { value: "" } });
      return;
    }

    v = this.limit(v).toString();

    e.target.value = this.commaize(v);
    let value = this.decommaizeNumeric(v);

    if (!this.props.onBlur) return;

    this.props.onBlur({ target: { value: value } });
  };
  limit = (v) => {
    if (typeof this.state.minimum !== "undefined" && v < this.state.minimum)
      v = this.state.minimum;
    if (typeof this.state.maximum !== "undefined" && v > this.state.maximum)
      v = this.state.maximum;
    return v;
  };
  render() {
    let toexpand = {};
    if ("undefined" !== typeof this.props.defaultValue) {
      toexpand["defaultValue"] = this.commaize(this.props.defaultValue);
    }
    if ("undefined" !== typeof this.props.value) {
      toexpand["value"] = this.commaize(this.props.value);
    }

    return (
      <span
        data-reactroot=""
        className="react-numeric-input w-100"
        style={this.state.wrap}
      >
        <InputGroup size="sm">
          <InputGroup.Prepend>
            <InputGroup.Text style={{ fontSize: "0.9em" }} size="sm">
              $
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Form.Control
            isValid={this.props.isValid}
            name={this.props.name}
            id={this.props.id}
            size={this.props.size}
            {...toexpand}
            onChange={this.onChange}
            onBlur={this.onBlur}
            type="text"
            ref={this.ref}
            required={this.props.required}
            pattern="^[0-9,]+$"
          />
        </InputGroup>
      </span>
    );
  }
}

export class CommaizedInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
      minimum: this.props.min,
      maximum: this.props.max,
      step: this.props.step,
      wrap: this.props.wrap,
    };
    if (typeof this.state.minimum === "undefined") this.state.minimum = 0;
    if (typeof this.state.step === "undefined") this.state.step = 100;
    this.intervalid = 0;
    this.intervaldirecton = 0;

    this.ref = React.createRef();
  }
  componentDidMount() {
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }
  }

  componentWillUnmount() {}
  decommaizeNumeric = (v) => {
    let n = v.replace(/[^0-9-]/g, "");
    if (n === "") return n;
    return parseInt(n);
  };
  commaize = (v) => {
    if (null === v || typeof v === "undefined") {
      if (typeof this.state.minimum !== "undefined")
        return this.state.minimum.toString();
      return "";
    }

    v = v.toString();
    let input = v.replace(/[^0-9-]/g, "");

    if (input === "") {
      return "";
    }

    input = parseInt(input);
    input = input.toString();
    let output = "";
    for (;;) {
      if (input.length <= 3) {
        output = input + output;
        break;
      }
      let right = input.substr(-3);
      let left = input.substr(0, input.length - 3);
      input = left;
      output = "," + right + output;
    }
    return output;
  };
  onChange = (e) => {
    let v = e.target.value;
    e.target.value = this.commaize(v);
    let value = this.decommaizeNumeric(v).toString();

    this.props.onChange({ target: { value: value } });
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }
  };
  onBlur = (e) => {
    if (this.props.onValid) {
      this.props.onValid(this.ref.current.validity.valid);
    }

    let v = this.decommaizeNumeric(e.target.value);
    if (isNaN(v) || e.target.value === "") {
      v = "";
      if ("undefined" !== typeof this.props.onBlur)
        this.props.onBlur({ target: { value: "" } });
      return;
    }

    v = this.limit(v).toString();

    e.target.value = this.commaize(v);
    let value = this.decommaizeNumeric(v);

    if (!this.props.onBlur) return;

    this.props.onBlur({ target: { value: value } });
  };
  limit = (v) => {
    if (typeof this.state.minimum !== "undefined" && v < this.state.minimum)
      v = this.state.minimum;
    if (typeof this.state.maximum !== "undefined" && v > this.state.maximum)
      v = this.state.maximum;
    return v;
  };
  render() {
    let toexpand = {};
    if ("undefined" !== typeof this.props.defaultValue) {
      toexpand["defaultValue"] = this.commaize(this.props.defaultValue);
    }
    if ("undefined" !== typeof this.props.value) {
      toexpand["value"] = this.commaize(this.props.value);
    }

    return (
      <span
        data-reactroot=""
        className="react-numeric-input"
        style={this.state.wrap}
      >
        <InputGroup size="sm">
          {this.props.withDollarSign && <InputGroup.Prepend >
            <InputGroup.Text  >
              $
            </InputGroup.Text>
          </InputGroup.Prepend>}
          <Form.Control
            isValid={this.props.isValid}
            name={this.props.name}
            id={this.props.id}
            size={this.props.size}
            {...toexpand}
            onChange={this.onChange}
            onBlur={this.onBlur}
            type="text"
            ref={this.ref}
            required={this.props.required}
            style={this.props.style}
            pattern="^[0-9,]+$"
          />
        </InputGroup>
      </span>
    );
  }
}