import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

export default function StepOne({ openAddLender }) {
    const dispatch = useDispatch()
    return (
        <>
            <div className='ModalTitle'>
                Welcome to Zeitro
            </div>
            <div style={{ marginTop: 38, fontSize: 20, color: "#474849", fontWeight: 400,fontFamily:"Inter" }}>
                To fully explore features like the Rate Quoter, POS System, Personal Site, and GuidelineGPT, please add your lenders; <span style={{
                    fontWeight: 600
                }}>
                    without adding lenders, you won't be able to utilize our platform's capabilities.
                </span>
            </div>
            <div className='ModalShow'>
                <div> <img src='/images/moneyBag.svg'></img></div>
                <Button onClick={() => {

                    dispatch({ type: "SHOW_LENDERS", data: true })


                }} style={{
                    marginTop: 25,
                    color: '#fff',
                    fontSize: 24,
                    backgroundColor: '#325CEB',
                    height: 48,
                    borderRadius: 6,
                }}>Add lenders now</Button>
            </div>


        </>

    )
}
