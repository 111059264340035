import * as React from 'react';
import Prices from './Prices';

function Billing() {
  return (
    <div>
      <Prices />
    </div>
  );
}

export default Billing;