import React, { useState, useEffect, useRef } from "react";
import { useBreakPoints, useViewport } from "../Common/Hooks";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tips } from "../Common/Tooltip";
import { Result } from "antd";
import { Form, Col, Row, InputGroup, Button, Container } from "react-bootstrap";
import { Spin, Alert, Divider, Select } from "antd";
import { withRouter } from "react-router-dom";
import * as com from "../Common"
import "./CRACalculator.css";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import { CommaizedInput } from "../NumericalInput";
import DividerWithText from "../Common/components/DividerLineWithText";

const matrices = require("./CRAEligibilityMatrix.json");

function CRACalculator(props) {
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [purpose, setPurpose] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [unit, setUnit] = useState("1");
  const [firstTime, setFirstTime] = useState(false)
  const [loanAmount, setLoanAmount] = useState("");
  const [propertyValue, setPropertyValue] = useState("");
  const [downpayment, setDownpayment] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState("");
  const [fico, setFico] = useState("")

  const [baseIncome, setBaseIncome] = useState("")
  const [bonusIncome, setBonusIncome] = useState("")
  const [rsuIncome, setRsuIncome] = useState("")

  const [income, setIncome] = useState("");
  const [qualified, setQualified] = useState(null)
  const [incentives, setIncentives] = useState("")
  const [isHighCostArea, setIsHighCostArea] = useState(false)
  const [highDownpay, setHighDownpay] = useState(null)

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showPropertyInfo, setShowPropertyInfo] = useState(false);
  const [medianIncome, setMedianIncome] = useState(null);
  const [mfi, setMfi] = useState(null);
  const [incomeIndicator, setIncomeIndicator] = useState(null);
  const [loanLimit, setLoanLimit] = useState(null);

  const [borrowerName, setBorrowerName] = useState("")
  const [borrowerPhone, setBorrowerPhone] = useState("")
  const [borrowerEmail, setBorrowerEmail] = useState("")
  const [loadingContact, setLoadingContact] = useState(false)
  const [language, setLanguage] = useState("english")
  const [qualifiedPrograms, setQualifiedPrograms] = useState([])
  const [loanLimits, setLoanLimits] = useState({})
  const [reasons, setReasons] = useState({})
  const [message, setMessage] = useState("")
  const [showResult, setShowResult] = useState(false)
  let avatarSrc = "/images/avatar.png"
  const email = props?.aboutInfo?.email
  const phone = props?.aboutInfo?.phone
  const urlParams = new URLSearchParams(window.location.search);
  const loEmail = urlParams.get('email');
  const loFname = urlParams.get('fn');
  const loLname = urlParams.get('ln');
  const loNMLS = urlParams.get('nmls');
  const loPhone = urlParams.get('phone');
  const loCCID = urlParams.get('ccid');

  let applyUrl = props.customerid === "gmcc" ? "https://app.gmccloan.com/services/preapp?customerid=gmcc&loid=" + props.loid : "https://app.zeitro.com/services/preapp?customerid=gmcc&loid=" + props.loid
  if (props.customerid === "gmcc" && props.loid === "gmcc") {
    applyUrl = "https://app.gmccloan.com/services/preapp?customerid=gmcc&loid=83ab3e1e-c696-4bd5-b8ca-91f567925b99"
  }

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "chinese", label: "Chinese" },
    // { value: "spanish", label: "Spanish" },
  ]

  useEffect(() => {
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e)
  }

  const getStates = () => {
    let states = []
    let keys = Object.keys(loanLimits)
    keys.forEach((key) => {

      states.push(key)
    })
    console.log(states)
    return states
  }

  const cronusQualifiedCounties = {
    "California": [
      "Alameda",
      "Contra Costa",
      "Fresno",
      "Los Angeles",
      "Kern",
      "Kings",
      "Marin",
      "San Francisco",
      "San Mateo",
      "Merced",
      "Orange",
      "Sacramento",
      "Riverside",
      "San Bernardino",
      "San Diego",
      "Santa Clara",
      "Santa Cruz",
      "San Joaquin",
      "Stanislaus",
      "Ventura"
    ],
    "Connecticut": ["Fairfield"],
    "Florida": ["Broward", "Miami-Dade", "Palm Beach", "Duval"],
    "Illinois": ["Cook", "DuPage", "Kane", "Lake", "McHenry", "Will"],
    "Maryland": ["Montgomery", "Prince George's"],
    "Nevada": ["Clark"],
    "New Jersey": ["Bergen", "Hudson", "Passaic", "Essex"],
    // "New York": ["Bronx", "Kings", "New York", "Queens", "Richmond", "Rockland", "Westchester", "Nassau", "Suffolk"],
    "South Dakota": ["Lincoln", "Turner", "McCook", "Minnehaha"],
    "Virginia": ["Arllington", "Alexandria", "Fairfax", "Fairfax City", "Falls Church"],
    "Washington": ["Columbia"],
  }

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    setPropertyState(state)
    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );


    let sfx = "";
    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
  };

  const displayPropertyInfo = () => {
    if (medianIncome === null || incomeIndicator === null || loanLimit === null) {
      return ""
    }

    const mapIncomeIndicator = (indicator) => {
      switch (indicator) {
        case 1:
          return "low income"
        case 2:
          return "moderate income"
        case 3:
          return "middle income"
        case 4:
          return "upper income"
        default:
          return "Unknown"
      }
    }
    if (isHighCostArea) {
      return <div className="mdaPropertyInfo craMarginAdjuster">The property is located in a {mapIncomeIndicator(incomeIndicator)}, high cost area, where the 80% area median income is ${com.commaize(medianIncome * 0.8)}, 80% Median Family Income is ${com.commaize(mfi * 0.8)}, and the conforming loan limit for a {unit}-unit property is ${com.commaize(loanLimit)}.</div>
    }
    return <div className="mdaPropertyInfo craMarginAdjuster">The property is located in a {mapIncomeIndicator(incomeIndicator)} area, where the 100% median income is ${com.commaize(medianIncome)}, and the conforming loan limit for a {unit}-unit property is ${com.commaize(loanLimit)}.</div>
  }

  const checkHomeRun = (income, mfincome, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit) => {
    if ((incomeIndicator===3 || incomeIndicator===4) && income > mfincome * 0.8) {
      return [false, "Your subject property is not located in a low-to-moderate income area. Addtionally, your income exceeds the 80% median family income limit required for HomeRun"]
    }
    if ((incomeIndicator === 1 || incomeIndicator === 2) || income <= mfincome * 0.8) {
      for (let matrix of matrices["HomeRun"]) {
        if (matrix["PurposeTypes"].includes(purpose) && matrix["OccupancyTypes"] === occupancy && matrix["PropertyTypes"].includes(propertyType) && matrix["UnitInterval"].includes(com.safeParseInt(unit)) && FICO >= matrix["MinFICO"] && (currentLTV * 100 <= matrix["MaxLTV"])) {
          return [true, "You are eligible for the Home Run program"]
        }
      }
      return [false, "Your condition (FICO, LTV, loan amount, purpose, occupancy and property type) does not meet the eligibility requirements for Home Run program."]
    }
  }

  const checkJumbo = (income, mfincome, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit) => {
    if (incomeIndicator === 3 || incomeIndicator === 4) {
      return [false, "Your subject property is not located in a low-to-moderate income area."]
    } else{
      for (let matrix of matrices["CronusJumbo"]) {
        if (matrix["PurposeTypes"].includes(purpose) && matrix["OccupancyTypes"] === occupancy && matrix["PropertyTypes"].includes(propertyType) && matrix["UnitInterval"].includes(com.safeParseInt(unit)) && FICO >= matrix["MinFICO"] && (currentLTV * 100 <= matrix["MaxLTV"]) && loanamount <= matrix["MaxLoanAmount"]) {
          return [true, "You are eligible for the Cronus Jumbo program"]
        }
      }
      return [false, "Your condition (FICO, LTV, loan amount, purpose, occupancy and property type) does not meet the eligibility requirements for Cronus Jumbo program."]
    }
  }

  const checkReady = (income, ami, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit) => {
    if (income > ami * 0.8) {
      return [false, "Your income exceeds the 80% area median income limit required by Home Ready program"]
    }
    for (let matrix of matrices["HomeReady"]) {
      if (matrix["PurposeTypes"].includes(purpose) && matrix["OccupancyTypes"] === occupancy && matrix["PropertyTypes"].includes(propertyType) && matrix["UnitInterval"].includes(com.safeParseInt(unit)) && FICO >= matrix["MinFICO"] && (currentLTV * 100 <= matrix["MaxLTV"])) {
        return [true, "You are eligible for the Home Ready program"]
      }
    }
    return [false, "Your condition (FICO, LTV, loan amount, purpose, occupancy and property type) does not meet the eligibility requirements for Home Ready program."]
  }

  const checkPossible = (income, ami, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit) => {
    if (income > ami * 0.8) {
      return [false, "Your income exceeds the 80% area median income limit required by Home Possible program"]
    }
    for (let matrix of matrices["HomePossible"]) {
      if (matrix["PurposeTypes"].includes(purpose) && matrix["OccupancyTypes"] === occupancy && matrix["PropertyTypes"].includes(propertyType) && matrix["UnitInterval"].includes(com.safeParseInt(unit)) && FICO >= matrix["MinFICO"] && (currentLTV * 100 <= matrix["MaxLTV"])) {
        return [true, "You are eligible for the Home Possible program"]
      }
    }
    return [false, "Your condition (FICO, LTV, loan amount, purpose, occupancy and property type) does not meet the eligibility requirements for Home Possible program."]
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setHighDownpay(null)
    setShowError(false)
    setQualified(null)
    setIncentives("")
    setShowPropertyInfo(false)
    setMedianIncome(null)
    setIncomeIndicator(null)
    setLoanLimit(null)
    // setIncome("")
    setIsHighCostArea(false)
    setShowResult(false)

    setLoading(true)
    let medianFamilyIncome = 0
    fetch("/data/gethudmedianfamilyincome", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        State: com.mapAbbrFullName[propertyState],
        County: propertyCounty,
      })
    })
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          setLoading(false)
          setShowError(true)
          return
        }
        res.json().then((js) => {
          setMfi(js.MedianIncome)
          medianFamilyIncome = js.MedianIncome
        });
      })
      .catch((err) => {
        console.error(err);
        alert("Some internal error happened. Please try again later.")
      });

    fetch("/data/loanlimitswithincome", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
      })
    })
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          setLoading(false)
          setShowError(true)
          return
        }
        res.json().then((js) => {

          let conformingLoanLimit = [766550, 981500, 1186350, 1474400]
          let highCost = false
          let ll = ""
          switch (unit) {
            case "1":
              ll = js.OneUnit
              setLoanLimit(js.OneUnit)
              if (js.OneUnit > conformingLoanLimit[0]) {
                highCost = true
                setIsHighCostArea(true)
                break
              }
            case "2":
              ll = js.TwoUnit
              setLoanLimit(js.TwoUnit)
              if (js.TwoUnit > conformingLoanLimit[1]) {
                highCost = true
                setIsHighCostArea(true)
                break
              }
            case "3":
              ll = js.ThreeUnit
              setLoanLimit(js.ThreeUnit)
              if (js.ThreeUnit > conformingLoanLimit[2]) {
                highCost = true
                setIsHighCostArea(true)
                break
              }
            case "4":
              ll = js.FourUnit
              setLoanLimit(js.FourUnit)
              if (js.FourUnit > conformingLoanLimit[3]) {
                highCost = true
                setIsHighCostArea(true)
                break
              }
            default:
              break
          }

          let ami = js.MedianIncome
          let incomeIndicator = js.IncomeIndicator
          setMedianIncome(ami)
          setIncomeIndicator(incomeIndicator)

          setShowPropertyInfo(true)
          setLoading(false)
          if (downpayment !== "" && propertyValue !== "" && income !== "" && fico !== "") {
            let dp = com.safeParseInt(downpayment)
            let pv = com.safeParseInt(propertyValue)
            let co = com.safeParseInt(cashOutAmount)

            let loanamount = purpose === "PURCHASE" ? pv - dp : purpose === "RATE_TERM_REFI" ? dp : co + dp
            let currentLTV = purpose === "PURCHASE" ? (pv - dp) / pv : purpose === "RATE_TERM_REFI" ? dp / pv : (co + dp) / pv
            let FICO = com.safeParseInt(fico)
            console.log("loan amount", loanamount)
            if (Object.keys(cronusQualifiedCounties).includes(com.mapAbbrFullName[propertyState]) && cronusQualifiedCounties[com.mapAbbrFullName[propertyState]].includes(propertyCounty.replace(" County", ""))) {
              if (loanamount > ll) {
                let [JumboCheck, JumboResult] = checkJumbo(income, medianFamilyIncome, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
                if (JumboCheck) {
                  setShowResult(true)
                  setQualifiedPrograms(["Cronus Jumbo CRA"])
                  let noHitReasons = {}
                  noHitReasons["Cronus Home Run"] = "Your loan amount exceeds conforming loan limit required by Home Run program."
                  noHitReasons["Home Possible"] = "Your loan amount exceeds agency loan limit required by Home Possible program."
                  noHitReasons["Home Ready"] = "Your loan amount exceeds agency loan limit required by Home Ready program."
                  setReasons(noHitReasons)
                  return
                } else {
                  setShowResult(true)
                  setQualifiedPrograms([])
                  let noHitReasons = {}
                  noHitReasons["Cronus Home Run"] = "Your loan amount exceeds conforming loan limit required by Home Run program."
                  noHitReasons["Home Possible"] = "Your loan amount exceeds agency loan limit required by Home Possible program."
                  noHitReasons["Home Ready"] = "Your loan amount exceeds agency loan limit required by Home Ready program."
                  noHitReasons["Cronus Jumbo"] = JumboResult
                  setReasons(noHitReasons)
                  return
                }
              }
              else if (ll === 1149825 && loanamount > 766550 && loanamount <= ll) {
                let [ReadyCheck, ReadyResult] = checkReady(income, ami, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
                let [PossibleCheck, PossibleResult] = checkPossible(income, ami, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
                let [JumboCheck, JumboResult] = checkJumbo(income, medianFamilyIncome, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
                let programsA = []
                let noHitReasons = {}
                noHitReasons["Cronus Home Run"] = "Your loan amount exceeds conforming loan limit required by Home Run program."
                if (ReadyCheck) {
                  programsA.push("Home Ready")
                } else {
                  noHitReasons["Home Ready"] = ReadyResult
                }
                if (PossibleCheck) {
                  programsA.push("Home Possible")
                } else {
                  noHitReasons["Home Possible"] = PossibleResult
                }
                if (JumboCheck) {
                  programsA.push("Cronus Jumbo CRA")
                } else {
                  noHitReasons["Cronus Jumbo CRA"] = JumboResult
                }
                setReasons(noHitReasons)
                if (programsA.length > 0) {
                  setShowResult(true)
                  setQualifiedPrograms(programsA)
                  return
                } else {
                  setShowResult(true)
                  setQualifiedPrograms([])
                  return
                }

              }
              else {
                let [RunCheck, RunResult] = checkHomeRun(income, medianFamilyIncome, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
                let [PossibleCheck, PossibleResult] = checkPossible(income, ami, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
                let [ReadyCheck, ReadyResult] = checkReady(income, ami, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
                let programsB = []
                let noHitReasons = {}
                noHitReasons["Cronus Jumbo CRA"] = "Your loan amount is under minimun loan amount requirement of Cronus Jumbo."
                if (RunCheck) {
                  programsB.push("Cronus Home Run")
                } else {
                  noHitReasons["Cronus Home Run"] = RunResult
                }
                if (PossibleCheck) {
                  programsB.push("Home Possible")
                } else {
                  noHitReasons["Home Possible"] = PossibleResult
                }
                if (ReadyCheck) {
                  programsB.push("Home Ready")
                } else {
                  noHitReasons["Home Ready"] = ReadyResult
                }
                setReasons(noHitReasons)
                if (programsB.length > 0) {
                  setShowResult(true)
                  setQualifiedPrograms(programsB)
                  return
                }
                else {
                  setShowResult(true)
                  setQualifiedPrograms([])
                  return
                }
              }
            }
            else {
              if (loanamount > ll) {
                setShowResult(true)
                setQualifiedPrograms([])
                let noHitReasons = {}
                noHitReasons["Cronus Home Run"] = "Not available in your subject property area."
                noHitReasons["Home Possible"] = "Your loan amount exceeds agency loan limit required by Home Possible program."
                noHitReasons["Home Ready"] = "Your loan amount exceeds agency loan limit required by Home Ready program."
                noHitReasons["Cronus Jumbo CRA"] = "Not available in your subject property area."
                setReasons(noHitReasons)
                return
              }
              let [ReadyCheck, ReadyResult] = checkReady(income, ami, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
              let [PossibleCheck, PossibleResult] = checkPossible(income, ami, loanamount, currentLTV, FICO, purpose, occupancy, propertyType, unit)
              let programsC = []
              let noHitReasons = {}
              let homeRunReason = "Not available in your subject property area."
              // if (income > medianFamilyIncome * 0.8) {
              //   homeRunReason += " Addtionally, your income exceeds the 80% median family income limit required for HomeRun."
              // }
              noHitReasons["Cronus Home Run"] = homeRunReason
              noHitReasons["Cronus Jumbo CRA"] = "Not available in your subject property area."
              if (ReadyCheck) {
                programsC.push("Home Ready")
              } else {
                noHitReasons["Home Ready"] = ReadyResult
              }
              if (PossibleCheck) {
                programsC.push("Home Possible")
              } else {
                noHitReasons["Home Possible"] = PossibleResult
              }
              setReasons(noHitReasons)
              if (programsC.length > 0) {
                setShowResult(true)
                setQualifiedPrograms(programsC)
                return
              }
              else {
                setShowResult(true)
                setQualifiedPrograms([])
                return
              }
            }
          }

        });

      })
      .catch((err) => {
        console.error(err);
        alert("Some internal error happened. Please try again later.")
      });

  }

  const displayedLoanAmount = () => {
    if (propertyValue === "" || downpayment === "") return ""
    let dp = com.safeParseInt(downpayment)
    let pv = com.safeParseInt(propertyValue)

    let loanAmount = purpose === "PURCHASE" ? pv - dp : dp
    if (purpose === "CASH_OUT_REFI") loanAmount += com.safeParseInt(cashOutAmount)

    return <Row className="blueTips tipsMarginAdjuster bold mt-3">
      <Col>
        <div>Your loan amount is ${com.commaize(loanAmount)}</div>
      </Col>
    </Row>
  }

  const submitContact = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setLoadingContact(true)
    fetch("/borrower/cra/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
        Purpose: purpose,
        Occupancy: occupancy,
        PropertyType: propertyType,
        Unit: unit,
        FirstTime: firstTime,
        LoanAmount: loanAmount,
        PropertyValue: propertyValue,
        BaseIncome: String(com.safeParseInt(baseIncome)),
        BonusIncome: String(com.safeParseInt(bonusIncome)),
        RSUIncome: String(com.safeParseInt(rsuIncome)),
        MedianIncome: String(medianIncome === null ? "" : medianIncome),
        IncomeIndicator: String(incomeIndicator === null ? "" : incomeIndicator),
        LoanLimit: String(loanLimit === null ? "" : loanLimit),
        Incentive: incentives,
        IsHighCost: isHighCostArea,

        BorrowerName: borrowerName,
        BorrowerPhone: borrowerPhone,
        BorrowerEmail: borrowerEmail,

        LOEmail: props.loEmail,
        LOPhone: props.loPhone,
        CustomerID: props.customerid,
      })
    })
      .then((res) => {
        if (!res.ok) {
          setLoadingContact(false)
          console.log(res);
          return
        }

        setLoadingContact(false)
        alert("Thanks for your submission. We will contact you shortly.")

      })
      .catch((err) => {
        console.error(err);
        setLoadingContact(false)
        alert("Some internal error happened. Please try again later.")
      });

  }

  const contactMe = <Row className="mt-5">

    <Col md={6} xs={12} className="text-left">
      <div className="callbackTitle-1 mb-2">Request a call back from your loan officer</div>
      <div className="callback-lo">
        <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{ width: 30 }} />Your Loan Officer</div>
        <div className="callback-loinfo">
          {props.loid !== "gmcc" && <div className="callbackTitle-4">{props.loName}</div>}
          <div className="callbackTitle-4">{props.loEmail}</div>
          {props.loid !== "gmcc" && <div className="callbackTitle-4">{props.loPhone !== null ? com.fixPhoneInput(props.loPhone) : ""}</div>}
          <div className="callbackTitle-4">NMLS #{props.loNMLS}</div>
        </div>
      </div>


      <Form onSubmit={submitContact}>
        <Form.Group controlId="name">
          <Form.Label className="callbackTitle-2">Your name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" onChange={e => setBorrowerName(e.target.value)} required />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
          <Form.Control type="text" placeholder="Enter your phone number" onChange={e => setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label className="callbackTitle-2">Your email</Form.Label>
          <Form.Control type="email" placeholder="Enter your email" onChange={e => setBorrowerEmail(e.target.value)} required />
        </Form.Group>
        <Button variant="zeitro-primary" type="submit" className="w-100" >
          {loadingContact ? <div className="craSpin"><Spin /></div> : "Submit"}
        </Button>
        <DividerWithText text={"Or"} />
        <Button variant="zeitro-primary" className="w-100" onClick={() => window.open(applyUrl, '_blank', 'noopener,noreferrer')}>
          Apply Now
        </Button>
      </Form>
    </Col>
    <Col>
      <div className='request_right  contactMeRequest'>
        <div style={{ position: 'relative' }} className='green'>
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <circle cx="28" cy="28" r="28" fill="#E3F5E3" />
          </svg>
          <div className='quote'>
            “
          </div>
        </div>
        <div className='Dream'>
          Make a Dream.

        </div>
        <div className='request_info'>
          <div className='line' />

          <div className='touch'>

            <div className='width_line' />
            <div className='touch_text'>We’ll be in touch.Shortly!</div>
          </div>
        </div>
        <div className='information '>

          <div>
            <div style={{ display: 'flex' }}>
              <img className='info_img' src={typeof props.basicInfo.file !== "undefined" ? props.basicInfo.file[0].url : avatarSrc}></img>
              <div className='info_span'>
                <div>{props.basicInfo.firstname}</div>
                <div>
                  {props.basicInfo.lastname}
                </div>
              </div>
            </div>
            <div>{email}</div>

          </div>


          <div >
            <div>NMLS {loNMLS}</div> <div > {phone}</div>
          </div>


        </div>
      </div>
    </Col>
  </Row>

  return (
    <>
      <Row className="craWrap">
        <Col style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <div className="title-3 text-center mt-3 mb-3">GMCC - Community Benefits Program</div>
          <div className="hfaSubTitle text-center mb-4 ">
            Check your eligibility for special incentive programs with attractive rates and start your homeownership journey!
          </div>


          <div className="craFormWrap">
            <Form onSubmit={handleSubmit} className="qualifierForm">
              <Row>
                <Col md={6} xs={12}>
                  <AddressAutocomplete
                    id="craAddress"
                    label="Property address"
                    required={true}
                    name="addessline"
                    defaultValue={displayedAddress}
                    onChange={(e) => setDisplayedAddress(e.target.value)}
                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                    placeholder="Please type your home address and select from the dropdown addresses"
                    goodfeedback="Looks good!"
                    badfeedback="Please provide your street address."
                    onCompletion={e => onStreetAddressCompletion(e)}
                    size="md"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Credit Score</Form.Label>
                    <Form.Control type="number" placeholder="" onChange={e => setFico(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={propertyType === "TWO_TO_FOUR_UNIT" ? 3 : 4} xs={12}>
                  <Form.Group  >
                    <Form.Label className="text-left craMarginAdjuster" >Loan purpose</Form.Label>
                    <Form.Control required name="currentdwelling" as="select"
                      size="md"
                      value={purpose}
                      onChange={(e) => setPurpose(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="PURCHASE">Purchase</option>
                      <option value="RATE_TERM_REFI">Refinance</option>
                      <option value="CASH_OUT_REFI">Cash out</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={propertyType === "TWO_TO_FOUR_UNIT" ? 3 : 4} xs={12}>
                  <Form.Group controlId="currentdwelling" >
                    <Form.Label className="text-left" >Property type</Form.Label>
                    <Form.Control required name="currentdwelling" as="select"
                      size="md"
                      value={propertyType}
                      onChange={(e) => setPropertyType(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="SFR">Single Family</option>
                      <option value="CONDO">Condo</option>
                      <option value="PUD">PUD</option>
                      <option value="TWO_TO_FOUR_UNIT">2 - 4 Units</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {propertyType === "TWO_TO_FOUR_UNIT" && <Col md={3} xs={12}>
                  <Form.Group controlId="unit" >
                    <Form.Label className="text-left" >Units</Form.Label>
                    <Form.Control required name="unit" as="select"
                      size="md"
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Form.Control>
                  </Form.Group>
                </Col>}
                <Col md={propertyType === "TWO_TO_FOUR_UNIT" ? 3 : 4} xs={12}>
                  <Form.Group  >
                    <Form.Label className="text-left" >Occupancy</Form.Label>
                    <Form.Control required name="currentdwelling" as="select"
                      size="md"
                      value={occupancy}
                      onChange={(e) => setOccupancy(e.target.value)}
                    >
                      <option value="">...</option>
                      <option value="PRIMARY">Primary</option>
                      <option value="SECOND_HOME">Second home</option>
                      <option value="INVESTMENT">Investment</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
              <Col>
                {showPropertyInfo && displayPropertyInfo()}
              </Col>
            </Row> */}
              <Row>
                <Col md={purpose === "CASH_OUT_REFI" ? 3 : 4} xs={12}>
                  <Form.Label >{purpose === "PURCHASE" ? "Purchase price" : "Home value"}</Form.Label>
                  <CommaizedInput value={propertyValue} onChange={e => setPropertyValue(e.target.value)} withDollarSign required />
                </Col>
                <Col md={purpose === "CASH_OUT_REFI" ? 3 : 4} xs={12} >
                  <Form.Label >{purpose === "PURCHASE" ? "Down payment" : "Current balance"}</Form.Label>
                  <CommaizedInput value={downpayment} onChange={e => setDownpayment(e.target.value)} withDollarSign required />
                </Col>
                {purpose === "CASH_OUT_REFI" && <Col md={3} xs={12}>
                  <Form.Label>Cash out amount</Form.Label>
                  <CommaizedInput value={cashOutAmount} onChange={e => setCashOutAmount(e.target.value)} withDollarSign />
                </Col>
                }
                <Col md={purpose === "CASH_OUT_REFI" ? 3 : 4} xs={12} className="craMarginAdjuster">
                  <Form.Label >Yearly income</Form.Label>
                  <CommaizedInput value={income} onChange={e => setIncome(e.target.value)} withDollarSign required />
                </Col>
              </Row>
              {displayedLoanAmount()}
              <Row className="mt-3">
                <Col>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="I am first time home buyer" checked={firstTime} onChange={(e) => setFirstTime(e.target.checked)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="text-center" >
                  <Button type="submit" variant="zeitro-primary">{loading ? <div className="craSpin"><Spin /></div> : "Check my eligibility"}</Button>
                </Col>
              </Row>
            </Form>

            {!loading && showError && <div className="mx-2 mt-3">
              <Alert message="Sorry, we're unable to process the information provided. Please verify your address and select an option from the dropdown list." type="warning" showIcon />
            </div>}
            {!loading && !showError && showResult && qualifiedPrograms.length > 0 && <Result
              status="success"
              // title={`You may be qualified for (${qualifiedPrograms.join(' & ')})!`}
              title={[
                <>
                  <div>You may be qualified for:</div>
                  {qualifiedPrograms.map((program) => {
                    return <div style={{ fontWeight: "500", color: "#1630AC" }}>{program}</div>
                  })}
                </>
              ]}
              subTitle={"Please contact your loan officer for more details."}
              extra={[
                <>
                  <div style={{ fontWeight: "500", color: "#CB8600" }}>Unqualied programs & reasons:</div>
                  {reasons && Object.keys(reasons).map((key) => {
                    return <div style={{ color: "#CB8600" }}><b>{key}</b>: {reasons[key]}</div>
                  })}
                </>
              ]}
            />}
            {!loading && !showError && showResult && qualifiedPrograms.length === 0 && <Result
              status="warning"
              title="You're not qualified for the Community Benefits programs."
              subTitle="Excections may apply. Please contact your loan officer for more details."
              extra={[
                <div style={{ color: "#6E6E70" }}>
                  <div style={{ textDecoration: "underline" }}>Unqualied programs and reasons:</div>
                  {reasons && Object.keys(reasons).map((key) => {
                    return <div><b>{key}</b>: {reasons[key]}</div>
                  })}
                </div>
              ]}
            />}
            {/* {!loading && qualified === false && highDownpay &&   <Result
                status="warning"
                title="Your down payment percentage exceeds the suitable range for this program."
                subTitle={highDownpay}
            />} */}
          </div>
        </Col>
      </Row>
      <Divider />
      <Row className="qualifierDisclaimerWrap">
        <div className="disclaimerContainer px-4">
          <div className="title-6 mb-3">
            Disclaimer:
          </div>
          <div className="title-6">
            All loans are subject to credit approval. Interest rates are subject to change daily and without notice. Current interest rates shown our indicative of market conditions and individual qualifications and will vary upon your lock-in period, loan type, credit score, purpose and loan to value.
          </div>
        </div>
      </Row>
      <div className="mb-5">
        {contactMe}
      </div>
    </>
  );
};

export default withRouter(CRACalculator);
