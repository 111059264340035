import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Col,
  Row,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Card,
  Button,
} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner'
import Alert from "react-bootstrap/Alert";
import NumericalInput, {NumericalInputSimple} from "../../NumericalInput";
import { commaize, MonthlyPayment, logo } from "../../Common";
import { withRouter } from "react-router-dom";
import "./SampleRatesConfig.css";
import RatesPreview from "./Preview";

const emptyConfig = {
  loanAmount: "",
  minPoints: 0,
  maxPoints: 5000,
  fico: 780,
  ltv: 75,
  selectedTermTypes: ["Fixed 30", "Fixed 15", "ARM 5/6"],
};

function SampleRatesConfig(props) {
  const configFormRef = useRef();
  const [config, setConfig] = useState({});
  const [newConfig, setNewConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [validatedConfigForm, setValidatedConfigForm] = useState(false);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const ref = useRef();

  const getConfig = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getsampleratesconfig", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((r) => {
          let c = JSON.parse(r);
          setConfig(c);
          setNewConfig(c);
          let sprd = [];
          for (let p in c) {
            sprd.push(p);
          }
          setSelectedProductTypes(sprd);
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };
  useEffect(() => {
    getConfig();
  }, []);

  const changeSelectedProductTypes = (e) => {
    const t = e.target.id;
    const p = selectedProductTypes;
    if (p.includes(t)) {
      setSelectedProductTypes(p.filter((el) => el !== t));
      delete config[t];
    } else {
      setSelectedProductTypes([...p, t]);
      config[t] = emptyConfig;
      if (t=="FHA") config[t].ltv = 81
    }
  };

  const showSelectedProductTypes = () => {
    let out = [];
    console.log(selectedProductTypes, config);
    selectedProductTypes.forEach((p) => {
      const changeSelectedTermTypes = (e) => {
        const t = e.target.name;
        let st = config[p].selectedTermTypes;
        if (st.includes(t)) {
          config[p].selectedTermTypes = st.filter((el) => el !== t);
        } else {
          config[p].selectedTermTypes = [...st, t];
        }
        console.log(p, config[p].selectedTermTypes);
      };

      out.push(
        <div className="configForm" key={"configform_" + p}>
          <h3>{p} Configuration</h3>
          <Row>
            <Col>
              <Form.Group controlId={"idloanamount_" + p}>
                <Form.Label>Loan Amount:</Form.Label>
                <NumericalInput
                  size="sm"
                  defaultValue={config[p].loanAmount}
                  type="number"
                  onChange={(e) => {
                    config[p].loanAmount = parseFloat(e.target.value);
                  }}
                  step={10000}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="text-left " controlId={"idfico_" + p}>
                <Form.Label>FICO</Form.Label>
                <Form.Control
                  size="sm"
                  as="input"
                  onChange={(e) => (config[p].fico = parseInt(e.target.value))}
                  type="number"
                  defaultValue={config[p].fico}
                  pattern="^[0-9]+$"
                  min={500}
                  max={1000}
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="text-left " controlId={"idltv_" + p}>
                <Form.Label>LTV</Form.Label>
                <Form.Control
                  size="sm"
                  as="input"
                  onChange={(e) => (config[p].ltv = parseFloat(e.target.value))}
                  type="number"
                  defaultValue={config[p].ltv}
                  pattern="^[0-9]+$"
                  min={0}
                  max={100}
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId={"idminpoints_" + p}>
                <Form.Label>Min Points:</Form.Label>
                <NumericalInputSimple
                  size="sm"
                  defaultValue={config[p].minPoints}
                  type="number"
                  onChange={(e) => {
                    config[p].minPoints = parseFloat(e.target.value);
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
            <Form.Group controlId={"idmaxpoints_" + p}>
              <Form.Label>Max Points:</Form.Label>
                <NumericalInputSimple
                  size="sm"
                  defaultValue={config[p].maxPoints}
                  type="number"
                  onChange={(e) => {
                    config[p].maxPoints = parseFloat(e.target.value);
                  }}
                  required
                />
              </Form.Group>                  
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId={"idtermtypes_" + p}>
                <Form.Label>Types:</Form.Label>
                <div>
                  {[
                    "Fixed 30",
                    "Fixed 25",
                    "Fixed 20",
                    "Fixed 15",
                    "Fixed 10",
                    "ARM 5/6",
                    "ARM 7/6",
                    "ARM 10/6",
                    "Second Home",
                    "Investment",
                  ].map((ttype) => (
                    <Form.Check
                      className="termtypecheckbox"
                      custom
                      inline
                      type="checkbox"
                      id={p + ttype}
                      name={ttype}
                      label={ttype}
                      defaultChecked={config[p].selectedTermTypes.includes(
                        ttype
                      )}
                      onChange={(e) => {
                        changeSelectedTermTypes(e);
                      }}
                    />
                  ))}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>
      );
    });
    return out;
  };

  const submitConfig = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    setValidatedConfigForm(true);

    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/updatesampleratesconfig", {
      method: "POST",
      body: JSON.stringify(config),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          setLoading(false);
          return;
        } else {
          setNewConfig(config);
          setLoading(false);
          alert("Sample rates configuration has been updated.");
        }
      })
      .catch(function (err) {
        setLoading(false);
        console.log("Fetch Error :-S", err);
      });
  };

  return (
    <div className="settings">
      <div className="settingsForm text-left">
        <p className="previewTitle">Configuration</p>
        <hr />
        <Form>
          <Form.Group className="producttype">
            <Form.Label>Product Types:</Form.Label>
            <div key="checkboxes" className="settingsFormCheckBox">
              {["Conforming", "High Balance", "Jumbo", "FHA"].map((type) => (
                <Form.Check
                  custom
                  inline
                  type="switch"
                  id={type}
                  label={type}
                  checked={selectedProductTypes.includes(type)}
                  onChange={changeSelectedProductTypes}
                />
              ))}
              <Button
                size="sm"
                variant="updateproducttype"
                type="submit"
                form="idConfigForm"
              >
                Update
              </Button>
            </div>
          </Form.Group>
        </Form>
        <Form ref={configFormRef} id="idConfigForm" onSubmit={submitConfig}>
          {showSelectedProductTypes()}
        </Form>
      </div>
      <div className="preview">
        <p className="previewTitle">Sample Rates Preview</p>
        <hr />
        {loading? <Spinner animation="border" variant="primary" />: <RatesPreview config={newConfig} />}
      </div>
    </div>
  );
}

export default withRouter(SampleRatesConfig);
