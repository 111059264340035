import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Spin } from 'antd'
import { useImmer } from "use-immer"
import { CheckCircleOutlined, CheckCircleFilled, CheckOutlined } from '@ant-design/icons'
import Upgrade from './upgrade'
import UserModal from './userModal'
import './index.css'

import FullHeightModal from "../../Common/components/FullHeightModal"
import { useSelector, useDispatch } from 'react-redux'
export default function PlanPricing(props) {
    const dispatch = useDispatch()
    const subscription = useSelector(state => {

        return state.subscriptionReducer.currentInfo
    })
    const { open } = useSelector(state => {
        return state.manager
    })
    const { invite } = props
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUserModalOpen, setIsUserModalOpen] = useState(false)
    const [isPlanModalOpen, setPlanIsModalOpen] = useState(false)
    const [isSupportModalOpen, setSupportModalOpen] = useState(false)
    const [messageReceivedModalOpen, setMessageReceivedModalOpen] = useState(false)
    const [isUpgrade, setIsUpgrade] = useState(false)
    const [checkUser, setCheckUser] = useState([])
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { TextArea } = Input
    const pricingContent = [
        {
            title: "Starter",
            price: 'Free',
            date: "per month",
            button: 'Get Started Free',
            details: [
                'Unlimited price engine requests',
                'Unlimited 1003 PDF generations',
                '3 MISMO 3.4 Exports per month',
                'Agent personal webpage'

            ]

        },
        {
            title: "Business",
            price: '$99',
            date: "per user per month",
            button: 'Purchase Now',
            details: [
                'Unlimited price engine requests',
                'Unlimited 1003 PDF generation',
                'Unlimited MISMO 3.4 Export',
                'Credit pull intergration',
                'Agent personal webpage & customized url',
                'Rate & affordability calculator widget',
                'MortgageGPT',
                'Document autoprocessing',
                'Team & branch setup'

            ]

        },
        {
            title: "Enterprise",
            price: 'Let’s talk',
            date: "per month",
            button: 'Contact Us',
            details: [
                'Customized software solutions',
                'Dedicated customer support'
            ]

        },



    ]

    const dataSource = [
        {
            title: (<div className='UserSource' style={{ color: '#1F2890', fontWeight: 'bold' }}>Basic</div>),
            Starter: false,
            Business: false,
            Enterprise: false,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Cross Platform</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Agent Webpage</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Customized Web Page URL</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Team & Branch Setup</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Activity Logs</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Pricing Engine Requests</div>),
            Starter: "Unlimited",
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Add Wholesale Lenders</div>),
            Starter: 'Unlimited',
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Borrower Rate Pricer</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Affordability Calculator</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Compensation Editor</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>  Overlay Editor</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Plug-in and Widgets</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Multi-Users 1003 System</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Automated Pre-Qual System</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Automated Soft Credit Pulls</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Automated Liability Review</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>1003 PDF Generation</div>),
            Starter: "Unlimited",
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>MISMO 3.4 Export</div>),
            Starter: "3/month",
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Document Management</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Document Auto Processing</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Email Communication System</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Unlimited File Storage</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ color: '#1F2890', fontWeight: 'bold' }}>MortgageGPT</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Guideline Reader</div>),
            Starter: false,
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Q&A</div>),
            Starter: false,
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Help Center</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Onboarding Support</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Dedicated Support</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Rest APIs</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Modular Solutions</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Full Customization Solution</div>),
            Starter: false,
            Business: false,
            Enterprise: true,
        },

    ]
    const [currentInfo, setCurrentInfo] = useState({ loanOfficers: [] })
    useEffect(

        async () => {

            getcurrentsubscription()
        }, [])
    const getcurrentsubscription = async () => {


        setLoading(true)

        const { customerID, loanOfficers, plan, stripeCustomer, recurringInterval } = subscription
        console.log(loanOfficers, 7)
        setCurrentInfo({
            customerID,
            loanOfficers: loanOfficers.filter(e => e.Status !== "disabled"),
            plan,
            stripeCustomer,
            recurringInterval
        })
        setCheckUser(loanOfficers.filter(e => e.Status !== "disabled"))
        setTimeout(() => {
            setLoading(false)
            setIsUpgrade(false)

        }, 500)
    }
    const columns = [
        {
            title: '',
            dataIndex: 'title',
            width: 20,
            ellipsis: true,
            key: 'title',
        },
        {
            title: (<>
                <div className='tableHeader'  >Starter</div>
            </>
            ),
            dataIndex: 'Starter',
            align: 'center',
            key: 'Starter',
            width: '60px',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#1F2890", fontSize: '16px', fontWeight: '600' }}>{text}</span> : text ? <CheckOutlined style={{ color: "#1F2890", fontSize: '18px', fontWeight: '600' }} /> : <></>}</>,

        },
        {
            align: 'center',
            title: (<>
                <div className='tableHeader'>Business</div>
            </>
            ),
            width: '60px',
            dataIndex: 'Business',
            key: 'Business',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#1F2890", fontSize: '16px', fontWeight: '600' }}>{text}</span> : text ? <CheckOutlined style={{ color: "#1F2890", fontSize: '18px', fontWeight: '600' }} /> : <></>}</>,

        },
        {
            title: (<>
                <div className='tableHeader'>Enterprise </div>

            </>
            ),
            align: 'center',
            width: '60px',
            dataIndex: 'Enterprise',
            key: 'Enterprise',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#1F2890", fontSize: '16px', fontWeight: '600' }}>{text}</span> : text ? <CheckOutlined style={{ color: "#1F2890", fontSize: '18px', fontWeight: '600' }} /> : <></>}</>,

        },


    ]
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    async function getPlans() {
        let token = sessionStorage.getItem("ZeitroA")
        setLoading(true)
        const response = await fetch('/los/getcurrentsubscription', {
            method: 'get',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const DataInfo = await response.json()
        const { customerID, loanOfficers, plan, stripeCustomer, recurringInterval } = DataInfo
        dispatch({ type: "SUBSCRIPTION_INFO", data: { ...DataInfo, loading: true } })
        setCurrentInfo({
            customerID,
            loanOfficers,
            plan,
            stripeCustomer,
            recurringInterval
        })
        setLoading(false)

    }
    return (
        <>
            <div className='mx-3'>

                <div className='User_title mt-4 mb-3 mx-2 Support'>
                    <div>
                        Plans and Pricing
                    </div>
                    <div >
                        <Button className='ant-btn-default-zeitro' onClick={() => {


                            setSupportModalOpen(true)
                        }}>Support</Button>
                    </div>

                </div>

                <Spin spinning={loading}>
                    <div className='pricingContent'>
                        {isUpgrade ? <Upgrade back={() => {
                            setIsUpgrade(false)
                            getPlans()

                        }} loanOfficers={checkUser} stripeCustomer={currentInfo.stripeCustomer} userNumber={currentInfo.loanOfficers.length}></Upgrade> : <div className='price'>
                            {pricingContent.map((item, index) => {

                                return (
                                    // <Col lg={{ span: 6, offset: index > 0 ? 2 : 1 }}>
                                    <div style={open ? {
                                        height: "75vh"
                                    } : {}} className={currentInfo.plan === item.title.toLocaleLowerCase() ? 'pricingBlock chosen' : 'pricingBlock'} onClick={() => {
                                        // setCurrent(index)

                                    }}>
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                {currentInfo.plan === item.title.toLocaleLowerCase() ? <CheckCircleFilled style={{ fontSize: "20px", color: "#325CE9" }} /> : <div className='circle'></div>
                                                }
                                            </div>
                                            <div className='pricingTitle'>{item.title}</div>
                                            <div className='pricingPrice'>{item.price}</div>
                                            {index === 1 ? <div>{item.date}</div> : <div style={{ opacity: 0 }}>{item.date}</div>}


                                            <div className='scroll' style={index == 1 ? { marginTop: '20px', height: '60%' } : { marginTop: '20px', height: '60%' }}>      {item.details.map(item => {

                                                return (
                                                    <div className='pricingDetails'>
                                                        <CheckCircleOutlined style={{ color: "#325CEB", fontSize: '20px' }} />
                                                        <div title={item} style={{ marginLeft: '10px' }} className='pricingDetailsCon'>{item}</div>
                                                    </div>

                                                )

                                            })}</div>

                                        </div>
                                        <div>
                                            {index === 1 &&
                                                <Button type='primary' style={{ width: '100%', height: '44px', marginTop: '20px' }} onClick={() => {
                                                    if (open) {
                                                        if (currentInfo.plan === 'starter') {
                                                            setIsModalOpen(true)
                                                            return
                                                        }
                                                        dispatch({ type: "SHOW_PRICiNG", data: false })
                                                        dispatch({ type: "STEP", data: 1 })
                                                        return

                                                    }
                                                    if (currentInfo.plan !== 'starter') {
                                                        invite(false)
                                                        return
                                                    }
                                                    if (currentInfo.loanOfficers.length > 1) {
                                                        setIsUserModalOpen(true)
                                                        return
                                                    }
                                                    if (index === 1) {

                                                        setIsModalOpen(true)
                                                    }


                                                }}>{currentInfo.plan === 'starter' ? item.button : 'Manage'}</Button>
                                            }
                                            {
                                                index === 2 && <Button className='ant-btn-default-zeitro' style={{ width: '100%', height: '44px', marginTop: '20px' }} onClick={() => {
                                                    if (index === 2) {
                                                        setSupportModalOpen(true)

                                                    }
                                                }}>Contact Us</Button>
                                            }
                                            <div style={{ color: '#325CEB', textAlign: 'center', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setPlanIsModalOpen(true) }}>Plan Details</div>

                                        </div>

                                    </div>
                                    // </Col>

                                )

                            })
                            }


                        </div>
                        }
                    </div>
                </Spin>
                <Modal open={isModalOpen} footer={null} onCancel={() => {
                    setIsModalOpen(false)
                }}>
                    <div style={{ textAlign: 'center', marginTop: '80px', fontSize: '18px', fontWeight: '600' }}>
                        <img src='/images/email.svg'></img>

                        <p>Before we upgrade you to the Zeitro Business Plan,</p>
                        <p>would you like to invite other team members to join?</p>

                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '80px', marginTop: '60px', }}>
                        <Button className='ant-btn-default-zeitro' style={{ height: '44px' }} onClick={() => {
                            if (currentInfo.loanOfficers.length > 1) {

                                setIsUserModalOpen(true)
                            } else {

                                setIsModalOpen(false)
                                setIsUpgrade(true)
                            }


                        }}>Proceed to upgrade</Button>
                        <Button style={{ width: '40%', marginLeft: '10px', height: '44px' }} type='primary' onClick={() => {
                            if (open) {

                                dispatch({ type: "SHOW_PRICiNG", data: false })
                                dispatch({ type: "STEP", data: 1 })
                                return

                            }
                            invite(true)

                        }}>Invite user</Button>

                    </div>

                </Modal>
                <Modal wrapClassName='modal_warp'
                    width={800}
                    classNames={{
                        content: 'modal_content',
                        body: 'modal_body'
                    }} open={isSupportModalOpen} footer={null} onCancel={() => {
                        setSupportModalOpen(false)
                    }}>
                    <div className='User_modal' >
                        <div>
                            <div className='User_modal_title'>How can we help?</div>
                            <div className='title-5 text-center'>For inquiries regarding billing, payments, product feedback, or any issues encountered while using Zeitro, we're here to assist you promptly. </div>

                            <Form
                                onFinish={onFinish}
                                form={form}
                                style={{ marginTop: '20px', }}
                                layout='vertical'
                                validateMessages={validateMessages}

                            >
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="message"
                                            label="Message"
                                        >
                                            <TextArea placeholder="Please leave us a message..." rows={4} style={{ height: '200px' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='title-5 text-center'>Alternatively, you could also email us at contact@zeitro.com </div>

                                <div style={{ textAlign: 'center', marginBottom: '40px', marginTop: '60px', }}>
                                    <Button style={{ width: '40%', height: '44px' }} onClick={() => {
                                        setSupportModalOpen(false)

                                    }}>Cancel</Button>
                                    <Button style={{ width: '40%', marginLeft: '10px', height: '44px' }} type='primary' htmlType="submit">Send Message</Button>

                                </div>
                            </Form>
                        </div>
                    </div>


                </Modal>

                <FullHeightModal open={isPlanModalOpen} width={1000} footer={null} onClose={() => {
                    setPlanIsModalOpen(false)
                }}
                    content={<>
                        <div className='User_modal_title'>Plan Details</div>
                        <div style={{ fontSize: "16px", color: "#6E6E70", marginTop: "30px", textAlign: "center" }}>Unlock seamless loan origination with Zeitro's tailored subscription plans</div>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            className="allLoansTable"
                            showSorterTooltip={false}
                            pagination={false}
                            scroll={{
                                x: true,
                                y: "calc(100vh - 245px)"
                            }}
                        ></Table></>}>

                </FullHeightModal>
                <Modal open={messageReceivedModalOpen} footer={null} onCancel={() => {
                    setMessageReceivedModalOpen(false)
                }}>
                    <div className='text-center p-5'>
                        <div><img src='/images/emailSent.svg' /></div>
                        <div className='User_modal_title my-3'>We've received your message</div>
                        <div className='title-5 text-center'>Thank you for reaching out. </div>
                        <div className='title-5 text-center'>We will get back to you via email as soon as possible.</div>
                        <Button type='primary' size='large' className='mt-4' onClick={() => setMessageReceivedModalOpen(false)}>Done</Button>
                    </div>
                </Modal>
                <UserModal isModalOpen={isUserModalOpen} loanOfficers={currentInfo.loanOfficers} closeModal={(e) => {
                    if (!e) {
                        setIsUserModalOpen(false)
                        return
                    }
                    setCheckUser(e)
                    setIsModalOpen(false)
                    setIsUpgrade(true)
                    setIsUserModalOpen(false)

                }}></UserModal>
            </div>

        </>
    )
    function onFinish(val) {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/customersupporttoslack', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify({
                Message: val.message
            })
        }).then(async response => {
            if (!response.ok) {
                return
            }
        })
        setSupportModalOpen(false)
        setMessageReceivedModalOpen(true)
    }

}