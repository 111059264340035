import React, { useState, useEffect, useRef } from "react";
import { QRCode } from "react-qrcode-logo";
import { Spinner } from "react-bootstrap";
import { Carousel, Button, Modal, Progress, message, Row, Col, Spin, Tabs, Skeleton, Popconfirm, Checkbox, Switch, Tooltip, Collapse, Dropdown, Popover, Input } from 'antd';
import { InfoCircleTwoTone, DownOutlined } from "@ant-design/icons";
import Lenders from "../Management/Lenders";
import BasicInfoSetup from "./BasicInfoSetup"
import AboutmeSetup from "./AboutmeSetup"
import CompanySetup from "./CompanySetup"
import CustomerReviewSetup from "./CustomerReviewSetup";
import EditParams from "./EditParams";
import EditFaq from './EditFaq'
import BasicInfo from "../LandingPage/BrandNewLandingPage/components/BasicInfo";
import AboutMe from "../LandingPage/BrandNewLandingPage/components/AboutMe";
import Company from '../LandingPage/BrandNewLandingPage/components/Company'
import Mobile from "../LandingPage/BrandNewLandingPage/components/mobile";
import BrandNewLandingPage from '../LandingPage/BrandNewLandingPage/index'
import CustomerReview from "../LandingPage/CustomerReview"
import Programs from '../LandingPage/BrandNewLandingPage/components/programs'
import Faq from "../LandingPage/BrandNewLandingPage/components/Faq";
import { LandingAffordability, LandingRateQuoter } from "../LandingPage/Tools";
import { CheckGroupWithImage } from "../Common/components/CheckGroup";
import Profile from "../Profile";
import LandingPage from "../LandingPage/LandingPage";
import { getLoId, getCustomerId } from "../Common";
import "./InitialSetup.css"
import CallBack from "../LandingPage/CallBack";
import CelebrityQualifier, { UniverseQualifier } from "../GMCCCalculator/GMCCCalculator";
import eventBus from '../Bus';
import * as com from "../Common.js";
import { Helmet } from 'react-helmet'
import { checkAccess } from "../Auth";
import { normalPrograms, programs, faqData, moreFaqData } from '../LandingPage/BrandNewLandingPage/commonData'
import { useSelector } from 'react-redux'
import { UR_Owner, UR_LoanOfficerManager, UR_LoanOfficer } from "../State";
import PlanModal from "../planModal";
import { throttle } from 'lodash';
const title = {
  '1': 'Module 1: Basic info',
  '2': 'Module 2: About me',
  '3': 'Module 2: Mortgage Calculators',
  '4': 'Edit Testimonial',
  '5': 'Edit banner',
  '6': 'Edit FAQs'
}

const describe = {
  '1': 'In this step, please provide your essential details, including your profile photo, NMLS number, and license. This information is crucial for verifying your identity as an authorized loan officer.',
  '2': 'In this step, please provide your essential details, including your profile photo, NMLS number, and license. This information is crucial for verifying your identity as an authorized loan officer.',
  '3': 'In this step, you need to add at least one lender to activate the calculator feature,allowing your potential customers to access real-time rates based on demand.',
  '4': 'Customer feedback is crucial in helping borrowers understand the benefits of your services. By sharing your experiences, you can help borrowers make informed decisions.',
  '5': 'In this step, please list your strengths as a loan officer. Of course, you may choose to use the default copy.',
  '6': 'In this section, you have the option to display common questions asked by borrowers.'
}

const LandingPageSetup = () => {
  const subscription = useSelector(state => {

    return state.subscriptionReducer.currentInfo
  })
  const [appUrl, setAppUrl] = useState("");
  const [landingUrl, setLandingUrl] = useState("");
  const [urlShort, setUrlShort] = useState("");
  const [nameinUrl, setNameinUrl] = useState("");
  const [usingDomain, setUsingDomain] = useState(false);
  const [paramsInfo, setParamsInfo] = useState({
    title: '',
    paramsList: []
  });
  const [faqInfo, setFaqInfo] = useState([]);
  const [photo, setPhoto] = useState("");
  const [loading, setLoading] = useState(true);
  const [showSetupProfile, setShowSetupProfile] = useState(false);
  const [showSetupLenders, setShowSetupLenders] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [publishModule, setPublishModule] = useState([]);
  const [openEditModule, setOpenEditModule] = useState(false);
  const [openConfigureModule, setOpenConfigureModule] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [moduleType, setModuleType] = useState('1');
  const [basicInfo, setBasicInfo] = useState({});
  const [aboutInfo, setAboutInfo] = useState({});
  const [lenderList, setLenderList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [hasLenders, setHasLenders] = useState(false);
  const [siteVersion, setSiteVersion] = useState(1)
  const [showChooseVesion, setChooseVersion] = useState(false)
  const [loadingPublish, setLoadingPublish] = useState(false)
  const [published, setPublished] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const reviewRef = useRef(null);
  const paramsReviewRef = useRef(null);
  const faqReviewRef = useRef(null);
  const aboutMeRef = useRef(null);
  const basicInfoRef = useRef(null);
  const calculatorsRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const refAffordability = useRef(null);
  const refRateQuoter = useRef(null);
  const [toolstoDisplay, setToolstoDisplay] = useState(["ratequoter", "affordability", "celebrity15K", "celebrityNoratio"])
  const containerRef = useRef(null);
  const [activeKey, setActiveKey] = useState(['1'])
  const [sectionId, setSectionId] = useState('')
  const [companyInfoDisable, setCompanyInfoDisable] = useState(true)
  const [changeTestimonial, setChangeTestimonial] = useState(false)
  const [moduleManage, setModuleManage] = useState([
    {
      id: 'basic',
      module: 1,
      title: 'Basic information',
    },
    {
      id: 'widgets',
      module: 3,
      title: 'Manage widgets',
      manage: [
        {
          key: 'mortgage',
          name: 'Mortgage Calculator',
          describe: 'Add lenders to put this widget on your landing page.',
          status: true,
          modalType: '3',
        },
        {
          key: 'affordability',
          name: 'Affordability Calculator',
          describe: 'Essential Feature:  Accurately estimates the maximum home borrowers can afford.',
          status: true,
          modalType: "",
        }
      ]
    },
    {
      id: 'website',
      module: 4,
      title: 'Manage website section',
      manage: [
        {
          key: 'FAQs',
          name: 'FAQs',
          describe: 'Address common questions, reducing customer support inquiries.',
          status: true,
          modalType: '6',
        },
        {
          key: 'testimonial',
          name: 'Testimonial',
          describe: "Build trust and credibility, influencing potential clients' decisions.",
          status: false,
          modalType: '4',
        },
        {
          key: 'CTA',
          name: 'Banner',
          describe: 'Guide users towards desired actions, increasing engagement.',
          status: true,
          modalType: '5',
        }
      ]
    }
  ])
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    // console.log(width, height)
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    getProfile()
    getCustomerLenders()
    getCompanyName()
    getAppUrl()
  }, [])
  useEffect(() => {
    if (Object.keys(basicInfo).length !== 0 && basicInfo.file.length > 0 && basicInfo.firstname !== '' && basicInfo.lastname !== '' && !publishModule.includes(1)) {
      setPublishModule(prev => [...prev, 1])
    } else if (publishModule.includes(1) && (Object.keys(basicInfo).length === 0 || basicInfo.file.length === 0 || basicInfo.firstname === '' || basicInfo.lastname === '')) {
      setPublishModule(prev => prev.filter(module => module !== 1))
    }
    if (Object.keys(aboutInfo).length !== 0 && aboutInfo.introduction !== '' && aboutInfo.phone !== '' && aboutInfo.email !== '' && !publishModule.includes(2)) {
      setPublishModule(prev => [...prev, 2])
    } else if (publishModule.includes(2) && (Object.keys(aboutInfo).length === 0 || aboutInfo.introduction === '' || aboutInfo.phone === '' || aboutInfo.email === '')) {
      setPublishModule(prev => prev.filter(module => module !== 2))
    }
    if (hasLenders && !publishModule.includes(3)) {
      setPublishModule(prev => [...prev, 3])
    }
    if (reviewList.length > 0 && reviewList.filter(item => item.firstTime !== '' && item.review !== '').length > 0 && !publishModule.includes(4)) {
      setPublishModule(prev => [...prev, 4])
    }
    if (paramsInfo && paramsInfo.paramsList.length > 0 && paramsInfo.paramsList.filter(item => item.title !== '' && item.review !== '') && !publishModule.includes(5)) {
      setPublishModule(prev => [...prev, 5])
    }
    if (faqInfo.length > 0 && faqInfo.filter(item => item.label !== '' && item.children !== '').length > 0 && !publishModule.includes(6)) {
      setPublishModule(prev => [...prev, 6])
    }

  }, [basicInfo, aboutInfo, reviewList, hasLenders, paramsInfo, faqInfo])

  const getCustomerLenders = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/data/getcustomerlenders", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          )
          return
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log(js)
          } else {
            console.log(js)
            const hasLenders = js.Lenders ? js.Lenders.some(item => item.Active) : false
            setHasLenders(hasLenders)
          }
        })
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err)
      })
  }
  const getCompanyName = () => {
    fetch('/data/companycustomization', {
      headers: {
        'X-CustomerId': com.getCustomerId() || 'zeitro',
      }
    }).then(async response => {
      if (!response.ok) {
        return;
      }
      response.json().then(json => {
        setCompanyName(json.CompanyName)
      })
    })
  }
  const getProfile = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(response => {
      if (!response.ok) {
        setLoading(false);
      } else {
        response.json().then(js => {

          let lo = js
          let su = lo.LandingPageSetUp
          let name = lo.FirstName + " " + lo.LastName
          setBasicInfo({
            file: lo.Image === '' ? [] : [
              {
                url: lo.Image
              }
            ],
            firstname: lo.FirstName,
            lastname: lo.LastName,
            nmls: lo.NMLS,
            states: typeof su.States === "undefined" ? [] : su.States,
            tagsList: typeof su.TagsList === "undefined" ? [] : su.TagsList,
            popularTags: ['I speak Mandarin', 'Top 10 Loan offier', "Bay Area for 10+ years"],
            google: su.Google === undefined ? '' : su.Google,
            yelp: su.Yelp === undefined ? '' : su.Yelp,
            linkedin: su.Linkedin === undefined ? '' : su.Linkedin,
            twitter: su.Twitter === undefined ? "" : su.Twitter,
            appLink: su.appLink === undefined ? "" : su.appLink,
          })
          setName(name)
          setEmail(lo.Email)
          setPhone(lo.Phone)
          setNMLS(lo.NMLS)
          setAboutInfo({
            introduction: lo.Introduction,
            phone: lo.Phone,
            email: lo.Email,
            companyWebsite: su.CompanyWebsite === undefined ? '' : su.CompanyWebsite,
            tagline: su.tagline === undefined ? "" : su.tagline,
            companyIntroduction: su.companyIntroduction === undefined ? "" : su.companyIntroduction,
            companyLink: su.companyLink === undefined ? "" : su.companyLink,
            banner: su.banner === undefined ? "" : su.banner,
          })
          setParamsInfo(su.paramsInfo === undefined ? {
            title: 'How Do I Empower You to Build Your Solid Financial Foundation?',
            paramsList: getCustomerId() === "gmcc" ? programs : normalPrograms
          } : {
            title: su.paramsInfo.title === '' ? 'How Do I Empower You to Build Your Solid Financial Foundation?' : su.paramsInfo.title,
            paramsList: su.paramsInfo.paramsList
          })
          setFaqInfo(su.faqInfo === undefined ? [...faqData, ...moreFaqData] : su.faqInfo)
          setReviewList(su.ReviewsList === undefined ? [] : su.ReviewsList)
          if (typeof su.SiteVersion !== "undefined") setSiteVersion(su.SiteVersion)
          if (js.Domain !== "") {
            setLandingUrl("https://" + js.Domain)
            setUsingDomain(true)
          } else {
            setUsingDomain(false)
            setLandingUrl(window.location.hostname.includes("localhost") ? "http://localhost:3001" + js.LandingUrl : "https://" + window.location.hostname + js.LandingUrl)
            setUrlShort(window.location.hostname.includes("localhost") ? "http://localhost:3001" + "/" + js.LandingUrl.split("/")[1] + "/" : "https://" + window.location.hostname + "/" + js.LandingUrl.split("/")[1] + "/")
            setNameinUrl(js.LandingUrl.split("/")[2])
          }
          const newModuleManage = moduleManage.map(module => {
            if (module.module === 3 || module.module === 4) {
              return {
                ...module,
                manage: module.manage.map(item => {
                  if (item.key === 'FAQs') {
                    return { ...item, status: su.showFAQ !== undefined ? su.showFAQ : true };
                  } else if (item.key === 'testimonial') {
                    return { ...item, status: su.showTestimonial !== undefined ? su.showTestimonial : false };
                  } else if (item.key === 'CTA') {
                    return { ...item, status: su.showCTA !== undefined ? su.showCTA : true };
                  } else if (item.key === 'mortgage') {
                    return { ...item, status: su.showMortgage !== undefined ? su.showMortgage : true };
                  } else if (item.key === 'affordability') {
                    return { ...item, status: su.showAffordability !== undefined ? su.showAffordability : true };
                  } else {
                    return item;
                  }
                })
              }
            } else {
              return module;
            }
          })
          setCompanyInfoDisable(su.showCompanyInfo !== undefined ? su.showCompanyInfo : true)
          setModuleManage(newModuleManage)
          getCompanyInfo();
          setLoading(false);

        })
      }
    }).catch(error => {
      console.log("Revalidate catch error " + error)
    })
  };

  const getAppUrl = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/getappurl', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then((response) => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      response.json().then((js) => {    
          setAppUrl(js.AppUrl)
      })
    })
    .catch(function (err) {
      console.log('Fetch Error :-S', err);
    })
  }


  const getSetupProfile = () => {
    return (
      <Modal
        show={showSetupProfile}
        onHide={() => setShowSetupProfile(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body><Profile /></Modal.Body>
      </Modal>
    );
  }

  const getSetupLenders = () => {
    return (
      <Modal
        open={showSetupLenders}
        onCancel={() => setShowSetupLenders(false)}

        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Lenders />
      </Modal>
    );
  }
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nmls, setNMLS] = useState("");
  const [phone, setPhone] = useState("");
  const [basicProgress, setBasicProgress] = useState(0);
  const [aboutProgress, setAboutProgress] = useState(0);
  const [showContactmeModal, setShowContactmeModal] = useState(false);
  const getContactmeModal = () => {
    return (
      <Modal
        open={showContactmeModal}
        onCancel={() => setShowContactmeModal(false)}
        footer={null}
        closeIcon={null}
      >
        <CallBack id={getCustomerId()} name={name} email={email} forHeader={true} />
      </Modal>
    );
  };
  const carouselChange = (currentSlide) => {
    console.log(currentSlide);
  }
  const toUpdate = () => {
    setFirstTime(false)
  }
  const isPublish = () => {
    return publishModule.includes(1) && publishModule.includes(2) && publishModule.includes(3);
  }

  const toPublish = () => {
    setChooseVersion(true)
  }
  
  const updateLandingUrl = (newUrl) => {
    let token = sessionStorage.getItem("ZeitroA")
    let newlandingUrl= "/"+ newUrl.split("/")[newUrl.split("/").length-2] + "/" + newUrl.split("/")[newUrl.split("/").length-1]
    let tosend = {
      "LandingUrl": newlandingUrl
    }
    fetch('/los/updatelandingurl', {
      method: 'POST',
      body: JSON.stringify(tosend),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      },
    }).then(
      response => {
        if (response.status !== 200) {
          console('Looks like there was a problem. Status Code: ' +
            response.status);
          loadingPublish(false)
          setPublished(true)
          return;
        }
        console.log("updateLandingUrl success")
      }
    ).catch(function (err) {
      setOpenConfigureModule(false)
      setLoadingPublish(false)
      setPublished(true)
      console.log('Fetch Error :-S', err);
    })
  }

  const publish = () => {
    setPublished(false)
    setLoadingPublish(true)
    let newUrl = urlShort + nameinUrl
    if (landingUrl !== newUrl) {
      updateLandingUrl(newUrl)
    }
    let tosend = {
      "Email": aboutInfo.email,
      "FirstName": basicInfo.firstname,
      "LastName": basicInfo.lastname,
      "Phone": aboutInfo.phone,
      "NMLS": basicInfo.nmls,
      "Image": basicInfo.file[0].url,
      "Introduction": aboutInfo.introduction,
      "LandingPageSetUp": {
        appLink: basicInfo.appLink,
        ReviewsList: reviewList,
        CompanyWebsite: aboutInfo.companyWebsite,
        Google: basicInfo.google,
        Yelp: basicInfo.yelp,
        Linkedin: basicInfo.linkedin,
        Twitter: basicInfo.twitter,
        TagsList: basicInfo.tagsList,
        SiteVersion: siteVersion,
        States: basicInfo.states,
        tagline: aboutInfo.tagline,
        companyIntroduction: aboutInfo.companyIntroduction,
        companyLink: aboutInfo.companyLink,
        banner: aboutInfo.banner,
        paramsInfo: paramsInfo,
        faqInfo: faqInfo,
        toolstoDisplay: getCustomerId() === "gmcc" ? toolstoDisplay : ["ratequoter", "affordability"],
        showMortgage: moduleManage.find(module => module.module === 3).manage.find(item => item.key === 'mortgage').status,
        showAffordability: moduleManage.find(module => module.module === 3).manage.find(item => item.key === 'affordability').status,
        showFAQ: moduleManage.find(module => module.module === 4).manage.find(item => item.key === 'FAQs').status,
        showTestimonial: moduleManage.find(module => module.module === 4).manage.find(item => item.key === 'testimonial').status,
        showCTA: moduleManage.find(module => module.module === 4).manage.find(item => item.key === 'CTA').status,
        showCompanyInfo: companyInfoDisable
      }
    }
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/updateprofilesetup', {
      method: 'POST',
      body: JSON.stringify(tosend),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      },
    }).then(
      response => {
        setOpenConfigureModule(false)
        if (response.status !== 200) {
          console('Looks like there was a problem. Status Code: ' +
            response.status);
          loadingPublish(false)
          setPublished(true)
          return;
        }
        eventBus.emit('updateProfile')
        setLoadingPublish(false)
        setPublished(true)
        message.success('Publish Success')

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    ).catch(function (err) {
      setOpenConfigureModule(false)
      setLoadingPublish(false)
      setPublished(true)
      console.log('Fetch Error :-S', err);
    })
  }
  const copyUrl = () => {
    navigator.clipboard.writeText(landingUrl)
      .then(() => {
        messageApi.success('copy success!')
      })
      .catch((error) => {
        messageApi.error('copy error!')
      })
  }
  const contentStyle = {
    margin: 0,
    height: '430px',
    width: '100%',
  }
  const opneModal = (type) => {
    setModuleType(type)
    setOpenEditModule(true)
  }
  const handleOk = async () => {
    // setOpenEditModule(false)
    if (moduleType === '1' && basicInfoRef.current) {
      if (basicInfoRef.current.getPercent() < 50) return
      if (await basicInfoRef.current.validate() && await aboutMeRef.current.validate()) {
        basicInfoRef.current.validateForm()
        aboutMeRef.current.validateForm()
      }
    } else if (moduleType === '2' && aboutMeRef.current) {
      aboutMeRef.current.validateForm()
    } else if (moduleType === '4' && reviewRef.current) {
      reviewRef.current.validateForm()
    } else if (moduleType === '5' && paramsReviewRef.current) {

      paramsReviewRef.current.validateForm()
    } else if (moduleType === '6' && faqReviewRef.current) {

      faqReviewRef.current.validateForm()
    }
  }
  const handleCancel = () => {
    if (moduleType === '1' && basicInfoRef.current) {
      basicInfoRef.current.resetForm()
    } else if (moduleType === '2' && aboutMeRef.current) {
      aboutMeRef.current.resetForm()
    } else if (moduleType === '3') {
      getCustomerLenders()
    } else if (moduleType === '4' && reviewRef.current) {
      reviewRef.current.resetForm()
    }
    setOpenEditModule(false)
  }
  const configureCancel = () => {
    setOpenConfigureModule(false)
  }
  const basicInfoFinish = (val) => {
    if (!publishModule.includes(1)) {
      setPublishModule(prev => [...prev, 1])
    }
    setBasicInfo(val)
    handleCancel()
  }
  const changeBasicProgress = (val) => {
    setBasicProgress(val)
  }
  const changeAboutProgress = (val) => {
    setAboutProgress(val)
  }
  const changeCompanyProgress = (val) => {
    
  }
  const getCompanyInfo = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch("/los/getcompanyprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(response => {
      if (!response.ok) {
      } else {
        response.json().then(js => {
            let data = js
            setAboutInfo(aboutInfo => ({
              ...aboutInfo,
              companyWebsite: aboutInfo.companyWebsite === '' ? data.Name : aboutInfo.companyWebsite,
              companyIntroduction: aboutInfo.companyIntroduction === '' ? data.Introduction : aboutInfo.companyIntroduction,
              companyLink: aboutInfo.companyLink === '' ? data.Link : aboutInfo.companyLink,
              banner: aboutInfo.banner === '' ? data.Banner : aboutInfo.banner,
            }))
        })
      }
    }).catch(error => {
      console.log("Revalidate catch error " + error)
    })
  }
  const basicInfoChange = (val) => {
    setBasicInfo(val)
  }
  const aboutInfoChange = (val) => {
    setAboutInfo(val)
  }
  const companyChange = (val) => {
    setAboutInfo(aboutInfo => ({
      ...aboutInfo,
      companyWebsite: val.companyWebsite,
      companyIntroduction: val.companyIntroduction,
      companyLink: val.companyLink,
      banner: val.banner,
    }))
  }
  const reviewFinish = (val) => {
    if (!publishModule.includes(4)) {
      setPublishModule(prev => [...prev, 4])
    } else {
      const arr = val.customerReview.filter(item => item.firstTime !== '' && item.review !== '')
      if (arr.length === 0) {
        setPublishModule(prev => prev.filter(item => item !== 4))
      }
    }
    setReviewList(val.customerReview)
    handleCancel()
  }
  const paramsReviewFinish = (val) => {
    if (!publishModule.includes(5)) {
      setPublishModule(prev => [...prev, 5])
    } else {
      const arr = val.customerReview.filter(item => item.title !== '' && item.review !== '')
      if (arr.length === 0) {
        setPublishModule(prev => prev.filter(item => item !== 5))
      }
    }
    setParamsInfo({
      title: val.header,
      paramsList: val.customerReview.length === 0 ? (getCustomerId() === "gmcc" ? programs : normalPrograms) : val.customerReview

    })
    handleCancel()
  }
  const faqReviewFinish = (val) => {
    if (!publishModule.includes(6)) {
      setPublishModule(prev => [...prev, 6])
    } else {
      const arr = val.customerReview.filter(item => item.label !== '' && item.children !== '')
      if (arr.length === 0) {
        setPublishModule(prev => prev.filter(item => item !== 6))
      }
    }
    const newList = val.customerReview.map((item, index) => {

      return {
        key: index + 11,
        label: item.label,
        children: item.children

      }
    })
    setFaqInfo(newList)
    handleCancel()
  }
  const aboutMeFinish = (val) => {
    if (!publishModule.includes(2)) {
      setPublishModule(prev => [...prev, 2])
    }
    setAboutInfo(val)
  }
  const calculatorsFinish = (val) => {
    if (!publishModule.includes(3)) {
      setPublishModule(prev => [...prev, 3])
    }
    setLenderList(val)
    handleCancel()
    window.location.reload()
  }
  const [currentFormData, setCurrentFormData] = useState(aboutInfo)
  const showModule = () => {
    switch (moduleType) {
      case "1":
        return <BasicInfoSetup aboutMe={currentFormData} info={basicInfo} ref={basicInfoRef} finish={basicInfoFinish} ><AboutmeSetup info={aboutInfo} basicInfo={basicInfo} ref={aboutMeRef} finish={aboutMeFinish} currentForm={(val) => {

          setCurrentFormData(val)

        }} /> </BasicInfoSetup>;
      case "2":
        return <AboutmeSetup info={aboutInfo} basicInfo={basicInfo} ref={aboutMeRef} finish={aboutMeFinish} />;
      case "3":
        return <Lenders ref={calculatorsRef} finish={calculatorsFinish} cancel={handleCancel} />;
      case "4":
        return <CustomerReviewSetup reviewList={reviewList} ref={reviewRef} finish={reviewFinish} />;
      case '5':
        return <EditParams reviewList={paramsInfo} ref={paramsReviewRef} finish={paramsReviewFinish}></EditParams>
      case '6':
        return <EditFaq reviewList={faqInfo} ref={faqReviewRef} finish={faqReviewFinish}></EditFaq>
    }
  }
  const toolsOptions = [
    { label: 'Rate Quoter', value: 'ratequoter' },
    { label: 'Affordability Calculator', value: 'affordability' },
  ]

  const onToolsCheckboxChange = (checkedValues) => {
    setToolstoDisplay(checkedValues)
  }
  const tabsItems = [
    getCustomerId() === "gmcc" && checkAccess([UR_Owner]) && toolstoDisplay.includes("ratequoter") && {
      key: '1',
      label: 'Check Current Rate',
      children:
        <div
          style={{ backgroundColor: "#F7F8FC" }}
          className="landingRateQuoter"
          id="landingRateQuoter"
          ref={refRateQuoter}
        >
          <LandingRateQuoter customerid={getCustomerId()} loid={getLoId()} width={width} height={height} />
        </div>,
    },
    getCustomerId() !== "gmcc" && toolstoDisplay.includes("ratequoter") && {
      key: '1',
      label: 'Check Current Rate',
      children:
        <div
          style={{ backgroundColor: "#F7F8FC" }}
          className="landingRateQuoter"
          id="landingRateQuoter"
          ref={refRateQuoter}
        >
          <LandingRateQuoter customerid={getCustomerId()} loid={getLoId()} width={width} height={height} />
        </div>,
    },
    toolstoDisplay.includes("affordability") && {
      key: '2',
      label: 'Check Affordability',
      children:
        <div
          style={{ backgroundColor: "white" }}
          className="landingTools pb-2"
          id="landingTools"
          ref={refAffordability}
        >
          <LandingAffordability customerid={getCustomerId()} loid={getLoId()} width={width} height={height} />
        </div>,
    },
    // toolstoDisplay.includes("celebrity15K") && getCustomerId()==="gmcc" &&{
    //   key: '3',
    //   label: 'Celebrity 15K Promotion',
    //   children: 
    //   <div
    //   style={{ backgroundColor: "white" }}
    //   className="landingTools pb-2"
    //   id="landingTools"
    //   ref={refAffordability}
    //   >
    //     <CelebrityQualifier customerid={getCustomerId()} loid={getLoId()} width={width} height={height} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}/>
    //   </div>
    // },
    // toolstoDisplay.includes("celebrityNoratio") && getCustomerId()==="gmcc" &&{
    //   key: '4',
    //   label: 'No Ratio Primary',
    //   children: 
    //   <div
    //   style={{ backgroundColor: "white" }}
    //   className="landingTools pb-2"
    //   id="landingTools"
    //   ref={refAffordability}
    //   >
    //         <UniverseQualifier customerid={getCustomerId()} loid={getLoId()} width={width} height={height} loEmail={email} loPhone={phone} loName={name} loNMLS={nmls}/>
    //   </div>
    // },
  ]
  const tabsOnChange = (key) => {
    console.log(key)
  }

  const sendMsgToSlack = () => {

    let token = sessionStorage.getItem("ZeitroA");

    fetch("/los/changedomainrequest", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        console.log(
          "/los/addlenderrequest: Looks like there was a problem. Status Code: " +
          response.status
        );
        return;
      }
    });
  };
  const finish = () => {
    setChooseVersion(false)
    setPublished(false)
  }
  const changeModuleManage = (key, status) => {
    if (key === 'testimonial') {
      setChangeTestimonial(true)
    }
    setModuleManage(prevModuleManage => 
      prevModuleManage.map(section => 
        section.module === 3 || section.module === 4
          ? {
              ...section,
              manage: section.manage.map(item => 
                item.key === key ? { ...item, status: status } : item
              )
            }
          : section
      )
    );
  }
  const getChooseVersionModal = () => {
    return <Modal
      className="choose-version-modal"
      title="Which site style would you like to use?"
      centered
      open={showChooseVesion}
      onOk={() => { published ? finish() : publish() }}
      okText={loadingPublish ? <Spinner animation="border" size="sm" /> : published ? "OK" : "Publish"}
      onCancel={() => setChooseVersion(false)}
      width={"80%"}
    >
      <div className="d-flex justify-content-center">
        {published ? <div className="text-center">
          <img src="/images/congratulation.png" />
          <div style={{ marginTop: 20, fontWeight: 500 }}>You can view your personal site now at <a href={landingUrl} target="_blank">{landingUrl}</a></div>
        </div> : <CheckGroupWithImage
          style={{ width: "100%" }}
          imageWidth="350px"
          imageHeight="250px"
          data={[{
            value: 1,
            label: 'Style 1',
            image: "/images/landingPageSample1.png"
          },
          {
            value: 2,
            label: 'Style 2',
            image: '/images/landingPageSample2.png'
          }
          ]}
          onChangeSelect={v => setSiteVersion(v)}
          value={siteVersion}
          type='radio'
          direction="horizontal" />}

      </div>
    </Modal>
  }
  const showConfigureModal = async () => {
    if (await basicInfoRef.current.validate() && await aboutMeRef.current.validate()) { 
      setOpenConfigureModule(true)
    }
  }
  const openTab = () => {
    window.open(landingUrl, '_blank')
  }
  const handlePublishClick = async () => {
    try {
      if (!isPublish()) {
        const isBasicInfoValid = await basicInfoRef.current.validate()
        const isAboutMeValid = await aboutMeRef.current.validate()
        if (!isBasicInfoValid || !isAboutMeValid) {
          message.warning('Please complete your basic information to publish your personal website')
          return
        }
        if (!publishModule.includes(3)) {
          message.warning('Please add lenders before using the mortgage calculator and  then publish your website.')
          return
        }
      }
      showConfigureModal()
    } catch (error) {
      message.warning('Please complete your basic information to publish your personal website')
    }
  }
  const progressList = [
    {
      key: '1',
      name: 'Basic information'
    },
    {
      key: '3',
      name: 'Manage widgets'
    },
    {
      key: '4',
      name: 'Manage website section'
    }
  ]
  const completeStatus = (item, key) => {
    if (item.key === '1') {
      const fieldsToCheck = [
        { value: basicInfo.file },
        { value: basicInfo.firstname },
        { value: basicInfo.lastname },
        { value: aboutInfo.introduction },
        { value: aboutInfo.phone },
        { value: aboutInfo.email },
      ]
      let completed = countFilledFields(fieldsToCheck)
      if (completed === 6) {
        return `current-${key}`
      } else {
        return ''
      }
    } else if (item.key === '3') {
      if (publishModule.includes(3)) {
        return `current-${key}`
      } else {
        return ''
      }
    } else if (item.key === '4') {
      let testimonialStatus = moduleManage.find(module => module.module === 4).manage.find(item => item.key === 'testimonial').status
      if (testimonialStatus || changeTestimonial) {
        return `current-${key}`
      } else {
        return ''
      }
    }
  }
  const topBarContent = () => {
    return (
      <>
        <div className="website-tooltip">Complete these steps to build your personal website</div>
        <div className="step-bar">
          {
            progressList.map((item, index) => (
              <div className="step-bar-item">
                <div className={`${completeStatus(item, 'num')} step-num`}>{index + 1}</div>
                <div className={`${completeStatus(item, 'name')} step-name`}>{item.name}</div>
                {index !== progressList.length - 1 && <div className="step-separate"></div>}
              </div>
            ))
          }
        </div>
      </>
    )
  }
  const handleCollapseChange = (key) => {
    const keyToSectionId = {
      '1': 'basic',
      '3': 'widgets',
      '4': 'website'
    }
    setActiveKey(key)
    setSectionId(keyToSectionId[key[0]] || '')
  }
  const countFilledFields = (fields) => {
    return fields.reduce((count, field) => {
      if (Array.isArray(field.value)) {
        return field.value.length > 0 ? count + 1 : count
      }
      return field.value ? count + 1 : count
    }, 0)
  }
  const customHeader = (item, isActive) => {
    const fieldsToCheck = [
      { value: basicInfo.file },
      { value: basicInfo.firstname },
      { value: basicInfo.lastname },
      { value: aboutInfo.introduction },
      { value: aboutInfo.phone },
      { value: aboutInfo.email },
    ]
    let completed = countFilledFields(fieldsToCheck)
    let percent = (completed / 6) * 100
    return (
        <div className='module-collapse-header'>
            <div className="module-name">{item.title}</div>
            {item.module === 1 && !isActive && <div className="module-completed"><Progress type="circle" percent={percent} size={36} strokeWidth={14} trailColor="#E3EAFD" strokeColor="#325CEB" format={() => <span style={{fontSize: 14, color: '#325CEB', fontWeight: 700}}>{completed}</span>} /><span className="module-completed-num">{ `${completed} / 6 COMPLETED`}</span></div>}
        </div>   
    )
  }

  const handleChangeNameinUrl = (e) => {
    setNameinUrl(e.target.value)
  }

  const moduleContent = (module) => {
    if (module === 1) {
      return (
        <>
          <div className="module-tips">
            <div>Please provide your basic information to be displayed on your personal website.</div>
          </div>
          <div className="module-info-wrap">
            <BasicInfoSetup
              ref={basicInfoRef}
              aboutMe={currentFormData} 
              changeProgress={changeBasicProgress} 
              info={basicInfo}  
              change={basicInfoChange}
              finish={basicInfoFinish} >
                <AboutmeSetup 
                  info={aboutInfo} 
                  changeProgress={changeAboutProgress} 
                  change={aboutInfoChange} 
                  ref={aboutMeRef} 
                  finish={aboutMeFinish} 
                  currentForm={(val) => {setCurrentFormData(val)}}
                /> 
            </BasicInfoSetup>
            <div className="module-manage-top">
              <div className="module-manage-title">Company information</div>
              <div className="module-info-operate"><Switch checked={companyInfoDisable} onChange={(status) => setCompanyInfoDisable(status)} /><span style={{marginLeft: 10}}>show on</span></div>
            </div>
            <CompanySetup disabled={!companyInfoDisable} info={aboutInfo} change={companyChange} changeProgress={changeCompanyProgress} />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="module-tips">
            <div>{ module === 3 ? 'Please  add at least one lender to activate the Mortgage calculator.' : 'After turning on the switch, please provide genuine testimonials.'}</div>
          </div>
          <div className="manage-card-list">
            {
              moduleManage[module - 2].manage.map(item => (
                <div className="manage-card-item">
                  <div className="card-name"><div className="icon-box"><img src={`/images/mySite-${item.key}.svg`} /></div><span>{item.name}</span>{item.key === 'mortgage' && checkAccess([UR_Owner, UR_LoanOfficerManager]) && <div className="add-lenders" onClick={() => opneModal(item.modalType)}>Add Lenders</div>}</div>
                  <div className="setting-bar">
                    {(
                      <Switch checked={item.status} onChange={(status) => changeModuleManage(item.key, status)} />
                    )}
                    {item.key !== 'mortgage' && item.key !== 'affordability' && <div className="setting-btn" onClick={() => opneModal(item.modalType)}><img src="/images/website-edit.svg" /></div>}
                  </div>
                </div>
              ))
            }
          </div>
        </>
      )
    }
  }
  return (
    <>
      {
        basicInfo.firstname && companyName !== '' && (
          <Helmet>
            <title>{`${companyName}-${basicInfo.firstname} mortgage service`}</title>
          </Helmet>
        )
      }
      {
        firstTime ? (
          <div className="firstTimeWrap">
            <div className="firstTimeTitle">
              "Ready to create your personal website in just a few minutes?"
            </div>
            <div style={{ width: '60%', marginBottom: '82px' }}>
              <Carousel className="firstTimeCarousel" afterChange={carouselChange}>
                <div>
                  <img style={contentStyle} src="https://img2.baidu.com/it/u=2479569717,2708545456&fm=253&fmt=auto&app=138&f=JPEG" />
                </div>
                <div>
                  <img style={contentStyle} src="https://hbimg.b0.upaiyun.com/c2dc04f7f4fcf8a4026e8eaec5ee6b2963a99d492aaa-WZq2hv_fw658" />
                </div>
                <div>
                  <img style={contentStyle} src="https://img2.baidu.com/it/u=2479569717,2708545456&fm=253&fmt=auto&app=138&f=JPEG" />
                </div>
              </Carousel>
            </div>
            <Button className="firstTimeBtn" type="primary" shape="circle" size="large" onClick={toUpdate}>Update my plan</Button>
            <div className="firstTimeTips">Update to premium program to access this feature</div>
          </div>
        ) : (
          <div className="text-body landing-setup">
            <div>
                {contextHolder}
                <div className="module-box">
                  <div>
                    <div className="module_pc">
                      {/* <div className="ModuleButton">
                        <div className="ModalButton container customerReview">
                          <div className="module-title">Module 1: Basic info</div>
                          <div className="module-btn"><Button size="large" onClick={() => opneModal('1')}>Edit info</Button></div></div>
                      </div> */}
                      <Row gutter={18}>
                        <Col className="module-progress-wrap" span={24} lg={14} ref={containerRef}>
                        {
                          loading ? <div style={{padding: 24}}><Skeleton active /></div> : (
                            <>
                              <div className="module-progress-bar">
                                {topBarContent()}
                              </div>
                              <div className="module-manage">
                                <Collapse 
                                  className="module-manage-collapse" 
                                  accordion
                                  collapsible="header"
                                  activeKey={activeKey}
                                  onChange={handleCollapseChange}
                                  expandIcon={({ isActive }) => <img className={`${isActive ? 'expand' : ''} collapse-icon`} src="/images/collapse-arrow.svg" /> }
                                  expandIconPosition="end"
                                >
                                  {
                                      moduleManage.map(item => (
                                          <Collapse.Panel className="module-collapse-item" header={customHeader(item, activeKey.includes(item.module.toString()))} key={item.module}>
                                              <div className="module-collapse-wrap">
                                                  {moduleContent(item.module)}
                                              </div>
                                              
                                          </Collapse.Panel>
                                      ))
                                  }
                                </Collapse>
                              </div>
                            </>
                          )
                        }
                        </Col>
                        <Col span={0} lg={10}>
                          <div className="published-bar">
                            <div className="published-bar-l">
                              <div className="url-wrap justify-content-between">
                                <div className="url-text">{landingUrl}</div>
                                <Popover
                                  rootClassName="EditUrlTipPopover"
                                  content={
                                    <>
                                      <div>
                                        Please click the "Publish" button and a pop-up window will appear. 
                                      </div>
                                      <div>
                                      You can then edit or request customization on your website address in the pop-up window and then publish your website settings with the new web address.
                                      </div>
                                    </>
                                  }
                                >
                                  <span className="EditUrlTip">Customize?</span>
                                </Popover>
                              </div>
                              <img className="url-copy-btn" src="/images/copy.svg" onClick={copyUrl} />
                            </div>
                            <Button onClick={handlePublishClick} className={`${!isPublish() ? 'publish-disable' : ''} publish-module`}>Publish</Button>
                          </div>
                          <div style={{height: 'calc(100vh - 104px)', backgroundColor: '#FFF', margin: '10px 24px 0 0', padding: '0 0 0 15px', borderRadius: '6px'}}>
                            <div className="browser-top"><img src="/images/traffic-lights.svg" /></div>
                            <div style={{height: 'calc(100vh - 130px)', overflowX: "hidden", overflowY: 'scroll', margin: '0 4px'}}>
                              <BrandNewLandingPage sectionId={sectionId} mode="preview" faqInfo={faqInfo} paramsInfo={paramsInfo} companyInfoDisable={companyInfoDisable} moduleManage={moduleManage} reviewList={reviewList} basicInfo={basicInfo} aboutInfo={aboutInfo} customerid={getCustomerId()} loid={getLoId()} url={''} appurl={appUrl} toolstoDisplay={"[\"ratequoter\",\"affordability\",\"celebrity15K\",\"celebrityNoratio\"]"} />
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </div>
                  </div>
                </div>

              </div>
            {showSetupProfile && getSetupProfile()}
            {/* {showSetupProperties && getSetupProperties()}
            {showSetupResources && getSetupResources()} */}
            {showSetupLenders && getSetupLenders()}
            {showContactmeModal && getContactmeModal()}
            <Modal
              className="configure-website-modal"
              centered
              open={openConfigureModule}
              title=""
              onCancel={configureCancel}
              width={"auto"}
              footer={null}
            >
              <div className="configure-website-title">
                <div className="configure-website-title-main">Configure your personal website</div>
                <div className="configure-website-title-sub">Set your website address to publish and access your site.</div>
              </div>
              <div className="configure-website-content">
                <div className="configure-website-content-url">
                  { usingDomain ?
                    <div className="url-wrap">{landingUrl}</div>
                  :
                    <>
                      <div className="url-wrap">{urlShort}</div>
                      <div className="mx-2">+</div>
                      <Input className="url-wrap" type="text" value={nameinUrl} onChange={handleChangeNameinUrl} />
                    </>
                  }
                  <img className="url-copy-btn" src="/images/copy.svg" onClick={copyUrl} />
                  <Button loading={loadingPublish} onClick={publish} className="configure-publish-btn">Publish</Button>
                </div>
                <Popconfirm
                  title="Would you like to submit a request to customize the domain for your personal website?"
                  description=""
                  onConfirm={() => {
                    sendMsgToSlack()
                    message.success("Your request has been sent!")
                  }}
                  onCancel={() => { }}
                  okText="Submit request"
                  placement="bottom"
                  overlayStyle={{ width: 400 }}
                  cancelText="Cancel"
                  icon={<InfoCircleTwoTone />}
                >
                  <Button style={{fontWeight: 600, marginTop: 5}} type="link">Customize the URL</Button>
                </Popconfirm>
              </div>
            </Modal>
            <Modal
              className={`EditModuleModal ${moduleType === '3' ? 'add-lenders-modal' : ''}`}
              centered
              destroyOnClose={true}
              open={openEditModule}
              title=""
              onOk={handleOk}
              onCancel={handleCancel}
              width={moduleType === '3' ? 1200 : 635}
              footer={moduleType !== '3' ? [
                <Button className="btn moduleCancel" key="back" size="large" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button className="btn moduleSave" key="submit" size="large" onClick={handleOk}>
                  Save
                </Button>
              ] : null}
            >
              <div className="moduleTitle">{title[moduleType]}</div>
              <div className="moduleDescribe">{describe[moduleType]}</div>
              {showModule()}
            </Modal>
            <PlanModal isModalOpen={isModalOpen} closeModal={() => {



            }} ></PlanModal>
            {showChooseVesion && getChooseVersionModal()}
          </div>
        )
      }
    </>
  );
};

export default LandingPageSetup;
