

import * as progress from '../Defs/progressdefs'



export function UpdateChangeProgress(t, n) {
    return { type: progress.CHANGE_PROGRESS, payload: t, stage: n  };
}
export function UpdateChangeProgressType(n) {
    return { type: progress.CHANGE_PROGRESS_TYPE, stage: n  };
}

export function UpdateChangeProgressLastVisited(t, n) {
    return { type: progress.CHANGE_PROGRESS_LASTVISITED, payload: t  };
}
export function UpdateChangeLastTouch(t) {
    return { type: progress.CHANGE_PROGRESS_LASTTOUCH, payload: t  };
}
export function UpdateChangeVisitedStage(verb) {
    return { type: progress.CHANGE_PROGRESS_VISITED, verb: verb  };
}
export function UpdateChangeProgressStep(step) {
    return { type: progress.CHANGE_PROGRESS_STEP, payload: step }
}
