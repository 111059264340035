import React,  { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import {
    Button,
    Form, 
    Input,
    Col, 
    Row,
    Rate
  } from 'antd'
import "./CustomerReviewSetup.css"
const { TextArea } = Input
const CustomerReviewSetup = forwardRef(({ reviewList, finish }, ref) => {
    const [form] = Form.useForm();


    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const onFinish = (val) => {

    }
    const formInitial = {
        customerReview: [...reviewList]
    }
    useImperativeHandle(ref, () => ({
        validateForm() {
            form.validateFields().then((values) => {
                finish(values)
            })
            .catch((errorInfo) => {
                console.log('Validation failed:', errorInfo);
            });
        },
        resetForm() {
            form.resetFields()
        }
    }))
    return (
      <div className="customer-review-setup">
        <div className="form-wrap">
          <div className="theme-title">Customer review</div>
          <Form
            className="customer-review-form"
            layout="vertical"
            form={form}
            initialValues={formInitial}
            onFinish={onFinish}
            validateMessages={validateMessages}
            size="large"
          >
            <Form.List name="customerReview">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row
                      key={key}
                      gutter={20}
                    >
                        <Col style={{marginBottom: 24, paddingBottom: 30, borderBottom: '1px solid #DBDCDE'}} span={24}>
                            <Row justify='space-between' align='middle'>
                                <Col style={{display: 'flex', alignItems: 'center'}}>
                                    <span className='testimonial-title'>{`${index + 1}. Your client’s name`}</span>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "star"]}
                                    >
                                        <Rate className='testimonial-rate' />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <img className='remove-photo' src='/images/remove.svg' onClick={() => remove(name)} />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24} sm={12} style={{marginBottom: '12px'}}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "firstname"]}
                                    >
                                        <Input placeholder="First Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12} style={{marginBottom: '12px'}}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "lastname"]}
                                    >
                                        <Input placeholder="Last Name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <div className='testimonial-label'>Testimonial</div>
                                <Col span={24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "review"]}
                                    >
                                        <TextArea
                                            autoSize={{
                                                minRows: 4,
                                                maxRows: 6
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <span className='add-review' onClick={() => add()}>Add more</span>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </div>
      </div>
    );
})
export default CustomerReviewSetup;