import React from 'react';
import { Form, Col, InputGroup, Button, Modal, Alert, FormControl, Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';

import { LOBS_TODOs, LOBS_In_Progress, LOBS_Done } from '../State';
import classes from './Fix.module.css';

class TaskCreation extends React.Component {

  constructor(props) {
    super(props)
    this.defaultMonth = '01'
    this.defaultDay = '01'
    this.defaultYear = new Date().getFullYear()
    this.state = {
      validated: false,
      newTaskTitle: '',
      newTaskMonth: this.defaultMonth,
      newTaskDay: this.defaultDay,
      newTaskYear: this.defaultYear,
      newTaskStage: LOBS_TODOs,
      newTaskSubtasks: [],
      showDateWarning: false
    }
    this.newTaskDeadline = ''
    this.userDocuments = {}
    this.subtaskTitleCreationRef = React.createRef()
    this.idSeparator = '!@$%^&*(ZAqweasd123A12Nmc1Jas4iuF7hasDi345nfa3Jsdjnl'
  }

  componentDidMount = () => {
    let token = sessionStorage.getItem("ZeitroA")
    let body = { ID: this.props.borrowerId, LoanID: this.props.loanId, WhoseDocuments: 'customer' }
    fetch('/los/documents', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            for (let i = 0; i < js.Docs.length; i++) {
              this.userDocuments[js.Docs[i].FileName] = false
            }
          }
          this.forceUpdate()
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if ((!form.checkValidity()) || (!this.checkDateValidity()) || (this.state.newTaskTitle === '') || (this.state.newTaskStatus === '')) {
      this.setState({ validated: true })
    }
    if ((form.checkValidity()) && (this.checkDateValidity()) && (this.state.newTaskTitle !== '') && (this.state.newTaskStatus !== '')) {
      let checkedDDocuments = Object.entries(this.userDocuments).filter(document => document[1] === true)
      checkedDDocuments = checkedDDocuments.map(document => document[0])
      let token = sessionStorage.getItem("ZeitroA")
      const body = {
        LoanID: this.props.loanId,
        Task: this.state.newTaskTitle,
        Url: this.state.newTaskUrl,
        Created: new Date().toUTCString(),
        Deadline: this.newTaskDeadline,
        Status: 'open',
        Stage: this.state.newTaskStage,
        Subtasks: JSON.stringify(this.state.newTaskSubtasks),
        ListOfDocuments: JSON.stringify(checkedDDocuments)
      }
      fetch('/los/createlotask/', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache"
        }
      })
        .then(() => {
          this.props.onSubmit()
        })
    }
  }


  handleTitleChange = (e) => {
    this.setState({ newTaskTitle: e.target.value })
  }

  handleMonthChange = (e) => {
    this.setState({ newTaskMonth: e.target.value })
  }

  handleDayChange = (e) => {
    this.setState({ newTaskDay: e.target.value })
  }

  handleYearChange = (e) => {
    this.setState({ newTaskYear: e.target.value })
  }

  checkDateValidity = () => {
    this.newTaskDeadline = new Date(`${this.state.newTaskYear}-${this.state.newTaskMonth}-${this.state.newTaskDay}`)
    const today = new Date()

    // converting date to the appropriate UTC format, working from current timestamp
    this.newTaskDeadline = new Date(new Date(this.newTaskDeadline.toUTCString()).getTime() + new Date().getTimezoneOffset() * 60000)

    if (this.newTaskDeadline <= today) {
      this.setState({ showDateWarning: true })
      return false
    }

    const daysInMonth = function (m, y) {
      switch (m) {
        case '02':
          return (parseInt(y) % 4 === 0 && parseInt(y) % 100) || parseInt(y) % 400 === 0 ? 29 : 28;
        case '09': case '04': case '06': case '11':
          return 30;
        default:
          return 31
      }
    };

    let valid = parseInt(this.state.newTaskDay) <= daysInMonth(this.state.newTaskMonth, this.state.newTaskYear)
    this.setState({ showDateWarning: !valid })
    return valid
  }

  handleStageChange = (e) => {
    if (e.target.value === 'Tasks In Progress') {
      this.setState({ newTaskStage: LOBS_In_Progress })
    } else {
      this.setState({ newTaskStage: e.target.value })
    }
  }

  handleAttachmentsChange = (e) => {
    this.userDocuments[e.target.id] = e.target.checked
  }

  handleSubtaskTitleCreation = (e) => {
    // this method is tied to html structure inside of <Form.Group controlId="TaskCreationSubtasks">
    // if you change the structure, change this method.
    if (this.subtaskTitleCreationRef.current.value !== '') {
      let sameName = this.state.newTaskSubtasks.find(element => element[0] === this.subtaskTitleCreationRef.current.value)
      if (sameName) {
        let filtered = this.state.newTaskSubtasks.filter(element => element[0] !== sameName[0])
        this.setState({ newTaskSubtasks: [...filtered, [this.subtaskTitleCreationRef.current.value, []]] })
      } else {
        this.setState({ newTaskSubtasks: [...this.state.newTaskSubtasks, [this.subtaskTitleCreationRef.current.value, []]] })
      }
    }
    this.subtaskTitleCreationRef.current.value = ''
  }

  handleSubtaskTitleDelete = (e) => {
    // this method is tied to html structure inside of <Form.Group controlId="TaskCreationSubtasks">
    // if you change the structure, change this method.
    const subtaskTitle = e.target.parentElement.childNodes[0].innerHTML
    const newSubtasksArray = this.state.newTaskSubtasks.filter(element => element[0] !== subtaskTitle)
    this.setState({ newTaskSubtasks: newSubtasksArray })
  }

  handleCheckboxAdd = (e) => {
    // this method is tied to html structure inside of <Form.Group controlId="TaskCreationSubtasks">
    // if you change the structure, change this method.
    const subtaskTitle = e.target.parentElement.parentElement.parentElement.childNodes[0].childNodes[0].innerHTML
    const taskInSubtaskTitle = e.target.parentElement.parentElement.childNodes[0].value
    e.target.parentElement.parentElement.childNodes[0].value = ''
    if (taskInSubtaskTitle !== '') {
      let index = this.state.newTaskSubtasks.findIndex(element => element[0] === subtaskTitle)
      let subtask = this.state.newTaskSubtasks[index]
      if (String(subtask[1].find(element => element[0] === taskInSubtaskTitle)) === 'undefined') {
        subtask[1].push([taskInSubtaskTitle, false])
        let newSubtasks = cloneDeep(this.state.newTaskSubtasks)
        newSubtasks.splice(index, 1, subtask)
        this.setState({ newTaskSubtasks: newSubtasks })
      }
    }
  }

  handleCheckboxChange = (e) => {
    // this method is tied to html structure inside of <Form.Group controlId="TaskCreationSubtasks">
    // if you change the structure, change this method.
    const values = e.target.id.split(this.idSeparator)
    let index = this.state.newTaskSubtasks.findIndex(element => element[0] === values[0])
    let newSubtasks = cloneDeep(this.state.newTaskSubtasks)
    let subtask = newSubtasks[index]
    let tmp = subtask[1].find(element => element[0] === values[1])
    tmp[1] = e.target.checked
    newSubtasks.splice(index, 1, subtask)
    this.setState({ newTaskSubtasks: newSubtasks })
  }

  handleCheckboxDelete = (e) => {
    // this method is tied to html structure inside of <Form.Group controlId="TaskCreationSubtasks">
    // if you change the structure, change this method.
    const values = e.target.parentElement.childNodes[0].childNodes[0].childNodes[0].id.split(this.idSeparator)
    let newSubtasks = cloneDeep(this.state.newTaskSubtasks)
    let index = newSubtasks.findIndex(element => element[0] === values[0])
    let tmp = newSubtasks[index]
    tmp[1] = tmp[1].filter(element => element[0] !== values[1])
    newSubtasks.splice(index, 1, tmp)
    this.setState({ newTaskSubtasks: newSubtasks })
  }

  render() {
    return (
      <Modal
        onHide={this.props.onHide}
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

            <Form.Group controlId="TaskCreationTitle">
              <Form.Label>Enter title</Form.Label>
              <Form.Control required placeholder="Enter title" onChange={this.handleTitleChange} />
              <Form.Text className="text-muted">
                Enter title.
              </Form.Text>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Title is required.</Form.Control.Feedback>
            </Form.Group>

            <Form.Label>Enter deadline</Form.Label>
            <Form.Row>
              <Form.Group as={Col} controlId="TaskCreationDeadlineMonth">
                <Form.Label>Choose month</Form.Label>
                <Form.Control defaultValue={this.defaultMonth} as="select" onChange={this.handleMonthChange} className={this.state.showDateWarning ? 'validationWrongField custom-bootstrap-validated-dropdown-fix' : 'custom-bootstrap-validated-dropdown-fix'}>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="TaskCreationDeadlineDay">
                <Form.Label>Choose day</Form.Label>
                <Form.Control defaultValue={this.defaultDay} as="select" onChange={this.handleDayChange} className={this.state.showDateWarning ? 'validationWrongField custom-bootstrap-validated-dropdown-fix' : 'custom-bootstrap-validated-dropdown-fix'}>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                  <option>16</option>
                  <option>17</option>
                  <option>18</option>
                  <option>19</option>
                  <option>20</option>
                  <option>21</option>
                  <option>22</option>
                  <option>23</option>
                  <option>24</option>
                  <option>25</option>
                  <option>26</option>
                  <option>27</option>
                  <option>28</option>
                  <option>29</option>
                  <option>30</option>
                  <option>31</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="TaskCreationDeadlineYear">
                <Form.Label>Choose year</Form.Label>
                <Form.Control defaultValue={this.defaultYear} as='select' onChange={this.handleYearChange} className={this.state.showDateWarning ? 'validationWrongField custom-bootstrap-validated-dropdown-fix' : 'custom-bootstrap-validated-dropdown-fix'}>
                  <option>{new Date().getFullYear()}</option>
                  <option>{new Date().getFullYear() + 1}</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            {this.state.showDateWarning && <Alert variant='danger'>This date is either passed or doesn't exist.</Alert>}

            {Object.entries(this.userDocuments).length !== 0 &&
              <Form.Group controlId="TaskCreationAttachments">
                <Form.Label>
                  Choose relevant documents
                </Form.Label>
                {Object.entries(this.userDocuments).map((attachment) =>
                  <Form.Check
                    key={`${attachment[0]}`}
                    type={'checkbox'}
                    id={`${attachment[0]}`}
                    label={attachment[0]}
                    defaultChecked={this.userDocuments[attachment[0]]}
                    onChange={this.handleAttachmentsChange}
                  />
                )}
              </Form.Group>}

            <Form.Group controlId="TaskCreationStage">
              <Form.Label>Task stage</Form.Label>
              <Form.Control required as='select' defaultValue={this.state.newTaskStage} onChange={this.handleStageChange} className='custom-bootstrap-validated-dropdown-fix'>
                <option>{LOBS_TODOs}</option>
                <option>Tasks In Progress</option>
                <option>{LOBS_Done}</option>
              </Form.Control>
              <Form.Text className="text-muted">
                Task's stage
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="TaskCreationSubtasks">
              <Form.Label>Task's subtasks</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Subtask's title"
                  aria-label="Subtask's title"
                  aria-describedby="basic-addon2"
                  ref={this.subtaskTitleCreationRef}
                />
                <InputGroup.Append style={{ height: '100%' }}>
                  <Button variant="secondary" onClick={this.handleSubtaskTitleCreation}>Add subtask's title</Button>
                </InputGroup.Append>
              </InputGroup>
              {this.state.newTaskSubtasks.length !== 0 &&
                this.state.newTaskSubtasks.map(title =>
                  <div key={title[0]}>
                    <Row>
                      <Col sm='11'>{title[0]}</Col>
                      <Col className='DeleteButton' sm='1' onClick={this.handleSubtaskTitleDelete}>&times;</Col>
                    </Row>
                    {title[1].length !== 0 && title[1].map((item, index) =>
                      <Row key={`${title[0]} ${item[0]} ${index}`}>
                        <Col sm='11'>
                          <Form.Check
                            type={'checkbox'}
                            id={`${title[0]}${this.idSeparator}${item[0]}`}
                            label={item[0]}
                            defaultChecked={item[1]}
                            onChange={this.handleCheckboxChange}
                          />
                        </Col>
                        <Col className='DeleteButton' sm='1' onClick={this.handleCheckboxDelete}>&times;</Col>
                      </Row>)}
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Subtask"
                        aria-label="Subtask"
                        aria-describedby="basic-addon2"
                        subtasktitle={title[0]}
                      />
                      <InputGroup.Append className={classes.fix}>
                        <Button variant="secondary" onClick={this.handleCheckboxAdd}>Add subtask</Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                )}
              <Form.Text className="text-muted">
                Task's subtasks
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type='submit' onClick={e => this.handleSubmit(e)}>Submit</Button>
          <Button variant="danger" onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default TaskCreation;