import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { getCustomerId } from './Common';


function LogoutPage()  {
    const customerid = getCustomerId()
    const url = "/login/" + customerid

    window.sessionStorage.removeItem("ZeitroA")

    window.location.href = url
    return (
        <div>
        </div>
    )
}

export default LogoutPage;


