import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

import Spinner from './CommonUIComponents/Spinner';

import classes from './DocumentPreview.module.css'

export default function DocumentPreview(props) {
  //component's state
  const [preview, setPreview] = useState([]);

  //redux state
  const borrower = useSelector(state => state.borrower);


  const prefix = "data:image/png;base64,"

  useEffect(() => {
    /* using closure, we can prevent unnecessary updates
     * shouldUpdate is set in the closure, update is happening, only if shouldUpdate is true
     * function we return, where shouldUpdate is set to false, is executed, when props.fileName is changed
     * meaning that state update for old value of props.fileName will not happen
     * Ex. if we change props.fileName twice in 2 seconds, first request will be sent, but the response
     * (let's assume) will not be received yet, but shouldUpdate will already be false for that response
    */
    setPreview([])
    let token = sessionStorage.getItem("ZeitroA")
    let shouldUpdate = true
    if (props.fileName !== '') {
      fetch(`/los/documentpreview/${props.fileName.replace('.pdf', '')}`, {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "X-Borrower": borrower.id
        }
      }).then(
        async response => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
              response.status);
            return;
          }
          await response.json().then(json => {
            if (shouldUpdate) {
              setPreview(json.Document.pngPagesBase64)
            }
            return;
          });
        }
      ).catch((err) => {
        console.log('Fetch Error :', err);
      });
      return () => {
        shouldUpdate = false
      }
    }
  }, [props.fileName, borrower.id]);

  return (
    <div style={{ backgroundColor: 'white' }} onClick={props.onClick} className={props.className}>
      <div className={classes.documentHeader}>
        {props.onClose && <Button variant="danger" onClick={props.onClose} style={{ float: 'left' }}>&times;</Button>}
        <p className={classes['documentTitle']}>{props.fileName}</p>
      </div>
      {preview.length !== 0 ?
        preview.map((page, index) => <img style={{ width: '100%' }} key={index} alt="view" src={prefix + page} />) :
        <Spinner addSpinnerClass={true} style={{ top: '30vh' }} />
      }
    </div>
  );

}