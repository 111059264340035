import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import "./RateQuote.css";
import { Col, Row, Card, Container, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { termOptions } from "./common";
import * as com from "../../Common.js";

export default function RateReport(props) {
  let headerStyle = {
    backgroundColor: "#F7F8FC",
    border:"#F7F8FC",
  };

  const columns = [
    {
      dataField: "type",
      text: "",
      headerStyle: headerStyle
    },
    {
      dataField: "rate",
      text: "Base Rate/APR",
      headerStyle: headerStyle,

    },
    {
      dataField: "monthlypay",
      text: "Monthly Payment",
      headerStyle: headerStyle,

    },
    {
      dataField: "closingcosts",
      text: "Est. Closing Costs",
      headerStyle: headerStyle,

    },
  ];
  let selected = [];
  let alternatives = [];

  const isSelected = (x) => {
    for (let s of props.data.selected) {
      if (s.value == x) {
        return true;
      }
    }
    return false;
  };
  const getType = (x) => {
    for (let s of termOptions) {
      if (s.value == x) {
        return s.label;
      }
    }
    return false;
  };

  let m = {
    fixed30: props.data.fixed30,
    fixed25: props.data.fixed25,
    fixed20: props.data.fixed20,
    fixed15: props.data.fixed15,
    fixed10: props.data.fixed10,
    arm56: props.data.arm56,
    arm76: props.data.arm76,
    arm106: props.data.arm106,
  };
  for (let k in m) {
    if (m[k] != undefined) {
      let d = {
        type: getType(k),
        rate: m[k].base_rate.toFixed(3) + "/" + m[k].APR,
        monthlypay: "$" + com.commaize(m[k].monthly_payment),
        // closingcosts: "$" + com.commaize(m[k].closing_cost),
        closingcosts: "$" + com.commaize(m[k].lender_credit),
      };
      if (isSelected(k)) {
        selected.push(d);
      } else {
        alternatives.push(d);
      }
    }
  }

  return (
    <Container id="ratesReport" className="ratesReportPreviewContainer">
      <div className="previewTitle">Preview</div>
      <div className="previewInfo">
          <div className="previewInfoText1">Loan Purpose</div>
          <div className="previewInfoText2">{props.data.Purpose.toLowerCase()}</div>
          <div className="previewInfoText1">Loan Amount</div>
          <div className="previewInfoText2">${com.commaize(props.data.LoanAmount)}</div>
      </div>
      <div className="previewInfo">
          <div className="previewInfoText1">FICO</div>
          <div className="previewInfoText2">{props.data.Fico}</div>
          <div className="previewInfoText1">Occupancy</div>
          <div className="previewInfoText2">{props.data.Occupancy.toLowerCase()}</div>
      </div>
      <div className="previewInfo">
          <div className="previewInfoText1">Property Type</div>
          <div className="previewInfoText2">{props.data.PropertyType.replaceAll("_", " ").toLowerCase()}{" "}</div>
        <div className="previewInfoText1"></div>
        <div className="previewInfoText2"></div>
      </div>
      {/* <div className="previewTitle mt-5">Preferred Products</div> */}
      <div className="previewTable mt-4">
        <BootstrapTable
          keyField="type"
          data={selected}
          columns={columns}
          bordered={false} 
          rowStyle ={{backgroundColor:"transparent", border:"none"}}
          classes="table-borderless"
        />
      </div>
      {/* {alternatives.length !== 0 && (
        <div className="previewTitle mt-5">Alternative Products</div>
      )}
      {alternatives.length !== 0 && (
        <div className="previewTable">
          <BootstrapTable
            bootstrap4
            keyField="type"
            data={alternatives}
            columns={columns}
            bordered={false} 
            rowStyle ={{backgroundColor:"transparent", border:"none"}}
            classes="table-borderless"
          />
        </div>
      )} */}

      {/* <hr /> */}
      <div className="previewClaimer">
        Disclaimer: Mortgage interest rate and cost is changing all the time.
        Your actual rate, payment, and costs could be different. Get an
        official Loan Estimate before choosing a loan.
      </div>
    </Container>
  );
}

export function RatesReportTable(props) {
  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "transparent",
    fontSize: "13px",
    whiteSpace: "nowrap",
  };

  const removeColumnFormatter = (cell, row, i) => (
    <div>
      <Button variant="link" onClick={() => props.onRemoveRow(i)}>
        Remove
      </Button>
    </div>
  );

  let columns = [
    {
      dataField: "type",
      text: "Amortization Type",
      headerStyle: headerStyle,
      style: {
        fontWeight: "bold",
        width: "120px",
      },
    },
    {
      dataField: "rate",
      text: "Base Rate/APR",
      headerStyle: headerStyle,
      style: {
        fontWeight: "bold",
        color: "blue",
        width: "120px",
      },
    },
    {
      dataField: "monthlypay",
      text: "Monthly Payment",
      headerStyle: headerStyle,
      style: {
        width: "110px",
      },
    },
    {
      dataField: "points",
      text: "Points",
      headerStyle: headerStyle,
      style: {
        width: "110px",
      },
    },
    {
      dataField: "closingcosts",
      text: "Est. Closing Costs",
      headerStyle: headerStyle,
      style: {
        width: "130px",
      },
    },
    {
      dataField: "remove",
      text: "",
      isDummyField: true,
      formatter: removeColumnFormatter,
      headerStyle: headerStyle,
      style: {
        width: "130px",
      },
    },
  ];

  if (props.hideRemoveButton === true) {
    columns = columns.slice(0, columns.length - 2);
  }

  let tblData = [];
  for (let dat of props.rows) {
    let row = {
      type:
        dat.MortgageAmortization === "fixed"
          ? dat.MortgageAmortization + dat.Term
          : dat.MortgageAmortization,
      rate: dat.base_rate.toFixed(3) + "/" + dat.APR,
      monthlypay: "$" + com.commaize(dat.monthly_payment),
      points: dat.points + "/$" + com.commaize(dat.lender_credit),
      // closingcosts: "$" + com.commaize(dat.lender_credit),
      closingcosts: "$" + com.commaize(dat.closing_cost),
    };
    tblData.push(row);
  }
  const rowStyle = { backgroundColor: "white" };
  return (
    <div id="ratesreporttable">
      <BootstrapTable
        bootstrap4
        keyField="type"
        data={tblData}
        columns={columns}
        rowStyle={rowStyle}
        condensed
        striped
        bordered={false}
      />
    </div>
  );
}
