import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

import classes from '../DocumentVerification.module.css'

const W2 = ({ doc, onFocus, onChangeTextInput }) => {
  return (
    doc.map((item, i) =>
      <div key={i}>
        <Form.Group className={classes['p-x-1']} controlId={`Employer${i}`}>
          <Form.Label>Employer</Form.Label>
          <Form.Control defaultValue={item['Employer']['Value']} type="text" onFocus={() => onFocus(item['Employer']['BBox'], item['Employer']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'Employer', 'Value'])} />
        </Form.Group>
        <Form.Group className={classes['p-x-1']} controlId={`Employee${i}`}>
          <Form.Label>Employee</Form.Label>
          <Form.Control defaultValue={item['Employee']['Value']} type="text" onFocus={() => onFocus(item['Employee']['BBox'], item['Employee']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'Employee', 'Value'])} />
        </Form.Group>
        <Row>
          <Form.Group as={Col} controlId={`SSN${i}`}>
            <Form.Label>SSN</Form.Label>
            <Form.Control defaultValue={item['SSN']['Value']} type="text" onFocus={() => onFocus(item['SSN']['BBox'], item['SSN']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'SSN', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`Year${i}`}>
            <Form.Label>Year</Form.Label>
            <Form.Control defaultValue={item['Year']['Value']} type="text" onFocus={() => onFocus(item['Year']['BBox'], item['Year']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'Year', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`State${i}`}>
            <Form.Label>State</Form.Label>
            <Form.Control defaultValue={item['State']['Value']} type="text" onFocus={() => onFocus(item['State']['BBox'], item['State']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'State', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`WagesTipsComp${i}`}>
            <Form.Label>Wages, tips, other comp.</Form.Label>
            <Form.Control defaultValue={item['WagesTipsComp']['Value']} type="text" onFocus={() => onFocus(item['WagesTipsComp']['BBox'], item['WagesTipsComp']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'WagesTipsComp', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`FederalIncomeTaxWithHeld${i}`}>
            <Form.Label>Federal income tax withheld</Form.Label>
            <Form.Control defaultValue={item['FederalIncomeTaxWithHeld']['Value']} type="text" onFocus={() => onFocus(item['FederalIncomeTaxWithHeld']['BBox'], item['FederalIncomeTaxWithHeld']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'FederalIncomeTaxWithHeld', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`SocialSecurityWages${i}`}>
            <Form.Label>Social security wages</Form.Label>
            <Form.Control defaultValue={item['SocialSecurityWages']['Value']} type="text" onFocus={() => onFocus(item['SocialSecurityWages']['BBox'], item['SocialSecurityWages']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'SocialSecurityWages', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`SocialSecurityTaxWithHeld${i}`}>
            <Form.Label>Social security tax withheld</Form.Label>
            <Form.Control defaultValue={item['SocialSecurityTaxWithHeld']['Value']} type="text" onFocus={() => onFocus(item['SocialSecurityTaxWithHeld']['BBox'], item['SocialSecurityTaxWithHeld']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'SocialSecurityTaxWithHeld', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`MedicareWagesTips${i}`}>
            <Form.Label>Medicare wages and tips</Form.Label>
            <Form.Control defaultValue={item['MedicareWagesTips']['Value']} type="text" onFocus={() => onFocus(item['MedicareWagesTips']['BBox'], item['MedicareWagesTips']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'MedicareWagesTips', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`MedicareTaxWithheld${i}`}>
            <Form.Label>Medicare tax withheld</Form.Label>
            <Form.Control defaultValue={item['MedicareTaxWithheld']['Value']} type="text" onFocus={() => onFocus(item['MedicareTaxWithheld']['BBox'], item['MedicareTaxWithheld']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'MedicareTaxWithheld', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`SocialSecurityTips${i}`}>
            <Form.Label>Social security tips</Form.Label>
            <Form.Control defaultValue={item['SocialSecurityTips']['Value']} type="text" onFocus={() => onFocus(item['SocialSecurityTips']['BBox'], item['SocialSecurityTips']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'SocialSecurityTips', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`AllocatedTips${i}`}>
            <Form.Label>Allocated tips</Form.Label>
            <Form.Control defaultValue={item['AllocatedTips']['Value']} type="text" onFocus={() => onFocus(item['AllocatedTips']['BBox'], item['AllocatedTips']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'AllocatedTips', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`Box9${i}`}>
            <Form.Label>Box9</Form.Label>
            <Form.Control defaultValue={item['Box9']['Value']} type="text" onFocus={() => onFocus(item['Box9']['BBox'], item['Box9']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'Box9', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`DependentCareBenefits${i}`}>
            <Form.Label>Dependent care benefits</Form.Label>
            <Form.Control defaultValue={item['DependentCareBenefits']['Value']} type="text" onFocus={() => onFocus(item['DependentCareBenefits']['BBox'], item['DependentCareBenefits']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'DependentCareBenefits', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`NonqualifiedPlans${i}`}>
            <Form.Label>Nonqualified plans</Form.Label>
            <Form.Control defaultValue={item['NonqualifiedPlans']['Value']} type="text" onFocus={() => onFocus(item['NonqualifiedPlans']['BBox'], item['NonqualifiedPlans']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'NonqualifiedPlans', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`Box12${i}`}>
            <Form.Label>Box12</Form.Label>
            {item['Box12'].map((item2, j) =>
              <Form.Control className={classes['m-bottom-5px']} key={`Box12${i}${j}`} defaultValue={item2['Value']} type="text" onFocus={() => onFocus(item2['BBox'], item2['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'Box12', j, 'Value'])} />
            )}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="Other">
            <Form.Label>Other</Form.Label>
            {item['Other'].map((item2, j) =>
              <Form.Control className={classes['m-bottom-5px']} key={`Other${i}${j}`} defaultValue={item2['Value']} type="text" onFocus={() => onFocus(item2['BBox'], item2['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'Other', j, 'Value'])} />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="StateWages">
            <Form.Label>State wages</Form.Label>
            <Form.Control defaultValue={item['StateWages']['Value']} type="text" onFocus={() => onFocus(item['StateWages']['BBox'], item['StateWages']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'StateWages', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="StateIncomeTax">
            <Form.Label>State income tax</Form.Label>
            <Form.Control defaultValue={item['StateIncomeTax']['Value']} type="text"  onFocus={() => onFocus(item['StateIncomeTax']['BBox'], item['StateIncomeTax']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'StateIncomeTax', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="LocalWages">
            <Form.Label>Local wages</Form.Label>
            <Form.Control defaultValue={item['LocalWages']['Value']} type="text"  onFocus={() => onFocus(item['LocalWages']['BBox'], item['LocalWages']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'LocalWages', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="LocalIncomeTax">
            <Form.Label>Local income tax</Form.Label>
            <Form.Control defaultValue={item['LocalIncomeTax']['Value']} type="text"  onFocus={() => onFocus(item['LocalIncomeTax']['BBox'], item['LocalIncomeTax']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'LocalIncomeTax', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="LocalityName">
            <Form.Label>Locality name</Form.Label>
            <Form.Control defaultValue={item['LocalityName']['Value']} type="text"  onFocus={() => onFocus(item['LocalityName']['BBox'], item['LocalityName']['Page'])} onChange={e => onChangeTextInput('w2.pdf', e.target.value, [i, 'LocalityName', 'Value'])} />
          </Form.Group>
        </Row>
      </div>)
  );
}

export default W2;