import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
export default function Copyright() {
    return (
        <div className="mt-5 p-0 w-100" >
            <Row style={{height: '60px'}} className="m-0 p-0 border-top">
                    <Col className="text-left ml-3 pl-5 align-self-center">
                    < a href="/"><span style={{color: '#999999', position: 'relative', top: '2px', fontSize: '0.8em'}}>Powered by </span><img className="" style={{width: '70px'}} src="/gray.png" alt="zeitro" /></a>


                    </Col>
                    <Col style={{color: '#999999', fontSize: '0.9em'}} className="mr-5 py-0 my-0 pr-5 text-right align-self-center">
                    © 2023 Zeitro. All rights reserved.
                    </Col>

                </Row>
            </div>        
    )
};