import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "./LandingPage.css";

const CallBack = (props) => {
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [showContactModal, setShowContactModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickContact = () => {
    if (contactName !== "" && (contactEmail !== "" || contactPhone !== "")) {
      setLoading(true);
      fetch("/agent/sendcontactmeemail", {
        method: "POST",
        body: JSON.stringify({
          Name: contactName,
          Email: contactEmail,
          Phone: contactPhone,
          Message: contactMessage,
          AgentName: props.name,
          AgentEmail: props.email,
          AgentPhone: props.phone,
        }),
        headers: {
          Cache: "no-cache",
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            setLoading(true);
            alert(
              "Looks like there was a problem. Status Code: " + response.status
            );
            return;
          }
          setLoading(false);
          setShowContactModal(true);
        })
        .catch(function (err) {
          setLoading(false);
          console.log("Fetch Error :/agent/sendcontactmeemail", err);
        });
    }
  };

  const getContactModal = () => {
    return (
      <Modal show={showContactModal} onHide={() => setShowContactModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>The agent will contact you soon.</Modal.Body>
        <Modal.Footer><Button onClick={()=>window.location.reload()}>OK</Button></Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className={!props.forHeader ? "callback" : "callbackInModal"}>
      <div className={!props.forHeader ? "callbackForm" : "callbackFormInModal"}>
        <div className="callbackTitle">
           Receive a callback from {props.name}
        </div>
        <Form.Group className="mt-4">
          <Form.Label>Name:</Form.Label>
          <Form.Control
            className="callbackInput"
            placeholder="Name"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email:</Form.Label>
          <Form.Control
            className="callbackInput"
            placeholder="Email"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone:</Form.Label>
          <Form.Control
            className="callbackInput"
            placeholder="Phone"
            value={contactPhone}
            onChange={(e) => setContactPhone(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Message:</Form.Label>
          <Form.Control
            as="textarea"
            rows={12}
            className="callbackMessage"
            placeholder="Type your message here..."
            value={contactMessage}
            onChange={(e) => setContactMessage(e.target.value)}
          />
        </Form.Group>
        <Button variant="callbackButton" onClick={() => handleClickContact()}>
          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            "Contact me"
          )}
        </Button>
      </div>
      {showContactModal && getContactModal()}
    </div>
  );
};

export default CallBack;
