import React from "react";

export const DollarPrefix = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingRight: "1em",
      }}
    >
      $
    </div>
  );
};
