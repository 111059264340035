import React, { Component } from "react";
import Spinner from 'react-bootstrap/Spinner'
import "./Progress.css";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="d-flex position-absolute p-0 m-0">
      <Spinner className="position-relative" size="sm" variant="primary" animation={ ((this.props.progress > 0) && (this.props.progress < 100)) ? "border" : ""}/>  
<i hidden={( this.props.progress < 100)} className="position-relative fas fa-check text-success"></i>
</div>
      );
  }
}

export default Progress;
