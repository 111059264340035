import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';
import * as com from '../../Common'

const Paystub = ({ doc, onFocus, onChangeTextInput }) => {
  return (
    doc.map((tmp, i) =>
      <div key={i} className='pt-1 pb-3'>
        <div className='title-1 ml-3 mt-2'>Periods</div>
        <Row>
          <Form.Group as={Col} controlId={`payDate${i}`}>
            <Form.Label>Pay date</Form.Label>
            <Form.Control defaultValue={tmp['Periods']['payDate']['Value']} type="text" onFocus={() => onFocus(tmp['Periods']['payDate']['BBox'], tmp['Periods']['payDate']['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Periods', 'payDate', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`payFrequency${i}`}>
            <Form.Label>Pay frequency</Form.Label>
            <Form.Control defaultValue={tmp['Periods']['payFrequency']} type="text"  onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Periods', 'payFrequency'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`payPeriodStartDate${i}`}>
            <Form.Label>Pay period start date</Form.Label>
            <Form.Control defaultValue={tmp['Periods']['payPeriodStartDate']['Value']} type="text" onFocus={() => onFocus(tmp['Periods']['payPeriodStartDate']['BBox'], tmp['Periods']['payPeriodStartDate']['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Periods', 'payPeriodStartDate', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`payPeriodEndDate${i}`}>
            <Form.Label>Pay period end date</Form.Label>
            <Form.Control defaultValue={tmp['Periods']['payPeriodEndDate']['Value']} type="text" onFocus={() => onFocus(tmp['Periods']['payPeriodEndDate']['BBox'], tmp['Periods']['payPeriodEndDate']['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Periods', 'payPeriodEndDate', 'Value'])} />
          </Form.Group>
        </Row>
        <div className='title-1 ml-3 mt-2'>Earnings</div>
        {Object.keys(tmp['Earnings']).map(key => <>
          {tmp['Earnings'][key].length !== 0 &&
            <>
              <div className='title-3 ml-3'>{key}</div>
              {tmp['Earnings'][key].map((earning, k) =>
                <>
                  <Row>
                    <Form.Group as={Col} controlId={`Hour${i}${key}${k}`}>
                      <Form.Label>Hour</Form.Label>
                      <Form.Control defaultValue={earning["Hour"]['Value']} type="text" onFocus={() => onFocus(earning["Hour"]['BBox'], earning["Hour"]['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Earnings', key, k, 'Hour', 'Value'])} />
                    </Form.Group>
                    <Form.Group as={Col} controlId={`Rate${i}${key}${k}`}>
                      <Form.Label>Rate</Form.Label>
                      <Form.Control defaultValue={earning["Rate"]['Value']} type="text" onFocus={() => onFocus(earning["Rate"]['BBox'], earning["Rate"]['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Earnings', key, k, 'Rate', 'Value'])} />
                    </Form.Group>
                    <Form.Group as={Col} controlId={`YearToDate${i}${key}${k}`}>
                      <Form.Label>Year to date</Form.Label>
                      <Form.Control defaultValue={earning["YearToDate"]['Value']} type="text" onFocus={() => onFocus(earning["YearToDate"]['BBox'], earning["YearToDate"]['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Earnings', key, k, 'YearToDate', 'Value'])} />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId={`Description${i}${key}${k}`}>
                      <Form.Label>Description</Form.Label>
                      <Form.Control defaultValue={earning["Description"]['Value']} type="text" onFocus={() => onFocus(earning["Description"]['BBox'], earning["Description"]['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Earnings', key, k, 'Description', 'Value'])} />
                    </Form.Group>
                    <Form.Group as={Col} controlId={`ThisPeriodAmount${i}${key}${k}`}>
                      <Form.Label>This period amount</Form.Label>
                      <Form.Control defaultValue={earning["ThisPeriodAmount"]['Value']} type="text" onFocus={() => onFocus(earning["ThisPeriodAmount"]['BBox'], earning["ThisPeriodAmount"]['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Earnings', key, k, 'ThisPeriodAmount', 'Value'])} />
                    </Form.Group>
                  </Row>
                </>
              )}
            </>
          }
        </>)}
        <div className='title-1 ml-3 mt-2'>Deductions</div>
        {Object.keys(tmp['Deductions']).map((key, k) =>
        (tmp['Deductions'][key].length !== 0 &&
          <div key={`Deductions${key}${i}${k}`}>
            <div className='title-3 ml-3'>{com.unCamelCase(key)}</div>
            {tmp['Deductions'][key].map((item, h) =>
              <Row key={`Deductions${key}${i}${k}${h}`}>
                <Form.Group as={Col} controlId={`ThisPeriod${i}${k}${h}`}>
                  <Form.Label>This period</Form.Label>
                  <Form.Control defaultValue={item['ThisPeriod']['Value']} type="text" onFocus={() => onFocus(item['ThisPeriod']['BBox'], item['ThisPeriod']['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Deductions', key, h, 'ThisPeriod', 'Value'])} />
                </Form.Group>
                <Form.Group as={Col} controlId={`YearToDate${i}${k}${h}`}>
                  <Form.Label>Year to date</Form.Label>
                  <Form.Control defaultValue={item['YearToDate']['Value']} type="text" onFocus={() => onFocus(item['YearToDate']['BBox'], item['YearToDate']['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Deductions', key, h, 'YearToDate', 'Value'])} />
                </Form.Group>
                <Form.Group as={Col} controlId={`Description${i}${k}${h}`}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control defaultValue={item['Description']['Value']} type="text" onFocus={() => onFocus(item['Description']['BBox'], item['Description']['Page'])} onChange={e => onChangeTextInput('paystub.pdf', e.target.value, [i, 'Deductions', key, h, 'Description', 'Value'])} />
                </Form.Group>
              </Row>
            )}
          </div>)
        )}
      </div>
    )
  );
}

export default Paystub;