import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";

import "../../App.css";
import * as com from "../../Common.js";
import * as act from "../../Store/actions.js";
import "./PreQualPlanPicker.css";
import { parseStringToNumber, USDollarCurrencyLocale } from "./utils";
import {
  getPrequalPDFBlobAndContent,
  getPrequalPDFBlobURL,
  sendPrequalPDFToBorrowerAndSaveToDB,
} from "./utils_pdf";
import { calculateDownPayAndLoanSize, getProductType } from "./utils_loans";

const { Text, Title } = Typography;
const { confirm } = Modal;

type PreQualPlanProps = {
  borrowerID: string;
  loanID: string;
  ltv: number;
  purchasePrice: number;
  bestProduct: any;
  borrowerName: string;
  email: string;
  loading: boolean;
  onClickSendToBorrower: () => void;
};

const PreQualPlan = forwardRef((props: PreQualPlanProps, ref) => {
  const borrowerID = props.borrowerID;
  const loanID = props.loanID;
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [sendingPdf, setSendingPdf] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [pdfURLForDownload, setPdfURLForDownload] = useState("");
  const [pdfDownload, setPDFDownload] = useState("");
  const [pdfPreviewModelOpen, setPdfPreviewModelOpen] = useState(false);
  const hiddenPdfDownloadButtonRef = useRef<HTMLAnchorElement>(null);
  const dispatch = useDispatch();

  const downloadPrequalPDF = async () => {
    const { downPayment, loanSize } = calculateDownPayAndLoanSize(
      props.purchasePrice,
      props.ltv
    );
    dispatch(act.UpdateSelectField(downPayment, "downpayment"));
    dispatch(act.UpdateSelectField(loanSize, "loansize"));

    setLoadingPdf(true);
    const { blob, download } = await getPrequalPDFBlobAndContent({
      salesPrice: props.purchasePrice,
      ltv: props.ltv,
      borrowerID,
      loanID,
    });
    const url = window.URL.createObjectURL(blob);
    setPdfURLForDownload(url);
    setPDFDownload(download);
    if (hiddenPdfDownloadButtonRef.current) {
      hiddenPdfDownloadButtonRef.current.click();
    }
    setLoadingPdf(false);
  };

  const previewPrequalPDF = async () => {
    setPdfURL("");
    setPdfPreviewModelOpen(true);
    const { downPayment, loanSize } = calculateDownPayAndLoanSize(
      props.purchasePrice,
      props.ltv
    );
    dispatch(act.UpdateSelectField(downPayment, "downpayment"));
    dispatch(act.UpdateSelectField(loanSize, "loansize"));
    const blobURL = await getPrequalPDFBlobURL({
      salesPrice: props.purchasePrice,
      ltv: props.ltv,
      borrowerID,
      loanID,
    });
    setPdfURL(blobURL);
  };

  useImperativeHandle(ref, () => ({
    previewPrequalPDF,
  }));

  const backToEdit = () => {
    setPdfPreviewModelOpen(false);
    setPdfURL("");
  };

  const sendPrequalPDFToBorrower = async () => {
    console.log("Sending prequal letter to borrower");
    const { downPayment, loanSize } = calculateDownPayAndLoanSize(
      props.purchasePrice,
      props.ltv
    );
    dispatch(act.UpdateSelectField(downPayment, "downpayment"));
    dispatch(act.UpdateSelectField(loanSize, "loansize"));

    setSendingPdf(true);
    await sendPrequalPDFToBorrowerAndSaveToDB({
      salesPrice: props.purchasePrice,
      ltv: props.ltv,
      borrowerID,
      loanID,
      email: props.email,
      borrowerName: props.borrowerName,
    });
    setSendingPdf(false);
    setPdfPreviewModelOpen(false);

    props.onClickSendToBorrower();
    confirm({
      title: (
        <Row justify="center">
          <img
            src="/images/emailSent.svg"
            alt="Email Sent"
            style={{ height: "4em", padding: "10px" }}
          />
        </Row>
      ),
      width: "80vw",
      content: (
        <Row justify="center" style={{ width: "100%" }}>
          <Row className="letter_sent_container">
            <Col span={6} />
            <Col span={12}>
              <Text className="letter_sent_title">Pre-qual letter sent!</Text>
              <br />
              <Text className="letter_sent_text">
                Your pre-qualification has been sent to your borrower's email
                and their Zeitro account.
              </Text>
            </Col>
            <Col span={6} />
          </Row>
          {props.bestProduct && (
            <Card
              style={{ width: "100%" }}
              className="product_details_container"
            >
              <Row className="product_details_text_container">
                <Text className="product_details_text">
                  Here is the best product we found for your borrower, with
                  either the lowest rates or the lowest closing costs when rates
                  are equal.
                </Text>
              </Row>

              <Card className="loan_rates_container">
                <Row gutter={16} className="loan_details">
                  <Col span={6} className="header">
                    Term, Type
                  </Col>
                  <Col span={6} className="header">
                    Rate/APR
                  </Col>
                  <Col span={6} className="header">
                    Est closing cost
                  </Col>
                  <Col span={6} className="header">
                    Est mo. payment
                  </Col>
                </Row>
                <Row gutter={16} className="loan_details">
                  <Col span={6} className="content">
                    {`${props.bestProduct.Term} years, ${getProductType(
                      props.bestProduct
                    )}`}
                  </Col>
                  <Col span={6} className="content">
                    {`${props.bestProduct.base_rate.toFixed(3)}%`}/
                    {`${props.bestProduct.APR}%`}
                  </Col>
                  <Col span={6} className="content">
                    {USDollarCurrencyLocale.format(
                      Number(props.bestProduct.closing_cost)
                    )}
                  </Col>
                  <Col span={6} className="content">
                    {USDollarCurrencyLocale.format(
                      com.safeParseInt(props.bestProduct.monthly_payment)
                    )}
                  </Col>
                </Row>
              </Card>
            </Card>
          )}
        </Row>
      ),
      okText: "Done",
      okButtonProps: {
        style: {
          display: "block",
          margin: "0 auto",
        },
      },
      cancelButtonProps: { style: { display: "none" } },
      icon: null,
    });
  };

  return (
    <div className="preQualPlanContainer">
      <a
        className="hiddenPdfDownloadButton"
        hidden
        ref={hiddenPdfDownloadButtonRef}
        href={pdfURLForDownload}
        download={pdfDownload}
      >
        &nbsp;
      </a>
      {props.loading ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <div>
          <div className="loan_details_container">
            <Row className="loan_details_title">Loan details</Row>
            <Row className="loan_details_row">
              <Col span={12} className="loan_details_label">
                Purchase price:
              </Col>
              <Col span={12} className="loan_details_value">
                {USDollarCurrencyLocale.format(props.purchasePrice)}
              </Col>
            </Row>
            <Row className="loan_details_row">
              <Col span={12} className="loan_details_label">
                Loan amount:
              </Col>
              <Col span={12} className="loan_details_value">
                {USDollarCurrencyLocale.format(
                  Math.round((props.ltv * props.purchasePrice) / 100)
                )}
              </Col>
            </Row>
            <Row className="loan_details_row">
              <Col span={12} className="loan_details_label">
                Down payment:
              </Col>
              <Col span={12} className="loan_details_value">
                {USDollarCurrencyLocale.format(
                  Math.round((props.purchasePrice * (100 - props.ltv)) / 100)
                )}
                /{100 - props.ltv}%
              </Col>
            </Row>
            <Row className="loan_details_row">
              <Col span={12} className="loan_details_label">
                LTV:
              </Col>
              <Col span={12} className="loan_details_value">
                {props.ltv}%
              </Col>
            </Row>
            <Row className="loan_details_row">
              <Col span={12} className="loan_details_label">
                Expiration date:
              </Col>
              <Col span={12} className="loan_details_value">
                {moment().add(1, "month").format("MMMM DD, YYYY")}
              </Col>
            </Row>
          </div>
          <div className="loan_details_disclaimer">
            Borrower's DTI is calculated at 36%.
          </div>

          <Row style={{ justifyContent: "center" }}>
            <Button
              disabled={!!props.loading}
              type="text"
              style={{
                marginTop: "10px",
                padding: "0px 1em",
                height: "3em",
              }}
              onClick={previewPrequalPDF}
            >
              <Text>Preview letter</Text>
            </Button>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Button
              disabled={!!props.loading}
              type="primary"
              style={{
                marginTop: "10px",
                padding: "0px 1em",
                height: "3em",
              }}
              onClick={sendPrequalPDFToBorrower}
            >
              <Space>
                {sendingPdf && <Spin />}
                <Text style={{ color: "white" }}>Send to borrower</Text>
              </Space>
            </Button>
          </Row>
        </div>
      )}

      <Modal
        title={
          <Row style={{ alignItems: "center" }}>
            <LeftOutlined rev={null} onClick={backToEdit} />
            <span style={{ marginLeft: "0.5em", fontSize: "20px" }}>
              Preview pre-qualification letter
            </span>
          </Row>
        }
        open={pdfPreviewModelOpen}
        footer={null}
        onCancel={() => {
          setPdfPreviewModelOpen(false);
        }}
        width="85%"
        zIndex={1100}
        style={{ position: "fixed", top: 0, left: "7.5%", height: "100vh" }}
      >
        {pdfURL ? (
          <div style={{ height: "100vh" }}>
            <iframe
              src={pdfURL}
              style={{ height: "calc(100vh - 150px)", width: "100%" }}
            />

            <Row justify="space-between">
              <Col span={16}></Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col>
                  <Button
                    type="text"
                    style={{ marginTop: "20px", height: "3em" }}
                    onClick={downloadPrequalPDF}
                  >
                    <Space>
                      {loadingPdf && <Spin />}
                      <Text
                        style={{
                          color: "#325CEB",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                      >
                        Download
                      </Text>
                    </Space>
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    style={{ marginTop: "20px", height: "3em" }}
                    onClick={sendPrequalPDFToBorrower}
                  >
                    <Space>
                      {sendingPdf && <Spin />}
                      <Text
                        style={{
                          color: "white",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                      >
                        Send to borrower
                      </Text>
                    </Space>
                  </Button>
                </Col>
              </Col>
            </Row>
          </div>
        ) : (
          <Skeleton active paragraph={{ rows: 4 }} />
        )}
      </Modal>
    </div>
  );
});

export default PreQualPlan;
