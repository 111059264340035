import React from 'react';
import { Modal, Pagination, Button } from 'antd';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf';
import './PreviewModal.css';

export default function PreviewModal(props) {
    const {
        open, data, pdf, pageNumber, numPages, width, scale,
        showVerify, onCancel, handleZoom, download, handlePage,
        verify, loadSuccess
    } = props;

    const previewCancel = () => {
        onCancel();
    };

    const onDocumentLoadSuccess = (page) => {
        loadSuccess(page);
    };

    const verifyDocument = () => {
        verify(data);
    };

    const previewDownload = () => {
        download(data);
    };

    const onChangePage = (page, pageSize) => {
        handlePage(page, pageSize);
    };

    const changeZoom = (type) => {
        if (type === 'add') {
            handleZoom(+(Math.min(scale + 0.1, 3).toFixed(1)));
        } else {
            handleZoom(+(Math.max(scale - 0.1, 0.5).toFixed(1)));
        }
    };

    return (
        <>
            <Modal
                className="document-preview-modal"
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
                destroyOnClose
                maskClosable={true}
                width={width * scale}
                closeIcon={false}
                open={open}
                title=""
                onCancel={previewCancel}
                footer={null}
            >
                {data?.mime === "application/zip" ? (
                    <div className='d-flex justify-content-center align-items-center py-5 text-center'>
                        <div className="">
                            <p>This file is a zip archive. Click the button below to download.</p>
                            <Button type='primary' onClick={previewDownload}>Download</Button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='document-preview-content'>
                            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} width={width * scale} />
                            </Document>
                        </div>
                        <div className='document-preview-top-bar'>
                            <div className="document-preview-top-bar-l">
                                <img style={{ width: 22, height: 22 }} src={data?.mime === "application/zip" ? '/images/document-zip.svg' : '/images/document-pdf.svg'} />
                                <span className='document-preview-top-name'>{data?.filename}</span>
                            </div>
                            <div className="document-preview-top-bar-l">
                                {showVerify && <div className="document-preview-verify" onClick={verifyDocument}>{data?.verifiedData ? "Reverify" : "Verify"}</div>}
                                <img style={{ marginRight: 24, cursor: 'pointer' }} onClick={previewDownload} src="/images/document-download-white.svg" />
                                <CloseOutlined onClick={previewCancel} style={{ fontSize: 24, cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className='document-preview-bottom-bar'>
                            <div className="document-preview-pagination">
                                <Pagination size='small' simple current={pageNumber} pageSize={1} total={numPages} onChange={onChangePage} />
                            </div>
                            <div className="document-preview-scale">
                                <div className='zoom-min'><MinusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('min')} /></div>
                                <div className='zoom-value'>{Math.round(scale * 100)}%</div>
                                <div className='zoom-add'><PlusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('add')} /></div>
                            </div>
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
}
