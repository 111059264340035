import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import * as com from "../Common"
import * as act from "../Store/actions"



const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateApplicationAttribute: (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb))
  },
  updateSelectedLoanAttribute: (t, verb) => {
    dispatch(act.UpdateSelectedLoanAttribute(t, verb))
  },

});

export default class PersonalReview extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  displayApplicant = (who) => {

    return <div className="mt-4">
      <b>{this.props.application[who].firstname} {this.props.application[who].lastname}</b>, Residing at {com.getPrimaryAddress(who, this.props.application)} {this.showButton(<Button className="ml-4 my-1 py-0"
          href={who === "borrower" ? "/app/interview/main/" + (com.stageToNumber.personal) : "/app/interview/main/" + (com.stageToNumber.personal)} size="sm" variant="outline-primary"> Edit</Button>)}
      <div>
      </div>
    </div>
  }
  showButton = (s) => {
    if (this.props.borrowerid != null) return ""
    return s
  }
  displayApplicants = () => {
    let out = []
    out.push(this.displayApplicant("borrower"))

    if (this.props.application.hascoborrower === "withcoborrower" ||
      this.props.application.hascoborrower === "multiple") {
      out.push(this.displayApplicant("coborrower"))
    }
    return out
  }
  displayLoan = () => {
    let isPreapproval = () => {
      return false === this.props.application.property.hascontract && this.props.application.property.purpose === "purchase"
    }
    let getAddress = () => {

      let st = this.props.application.property.address
      if (isPreapproval())
        return "TBD"
      return st
    }

    let purpose
    switch (this.props.application.property.purpose) {
      case "refinance": {
        purpose = "refinance loan"
        break;
      }
      case "cashoutrefinance": {
        purpose = "cash out refinance loan"
        break;
      }
      default:
      case "purchase": {
        purpose = "purchase loan"
        break;
      }
    }
    let getPropertyPage = () => {
      if (this.props.application.property.purpose !== "purchase" && true === this.props.application.financingsameproperty) {
        return "/app/interview/main/3"
      }
      return "/app/interview/main/7"
    }
    if (isPreapproval()) {
      purpose = "preapproval application"
    }
    let propertyType = () => {
      switch (this.props.application.property.propertytype) {
        case "singlefamilyattached": return "single family attached"
        case "singlefamilydetached": return "single family detached"
        case "condominium": return "condominium attached"
        case "condominiumdetached": return "condominium detached"
        case "pudattached": return "PUD attached"
        case "puddetached": return "PUD detached"
        case "twotofourfamily": return "2 to 4 Unit Multifamily"
        default: return "You should not see this"
      }
    }
    let occupancy = () => {
      switch (this.props.application.property.occupancy) {

        case "principal": return "a primary residence"
        case "secondhome": return "a second home"
        case "investment": return "an investment property"
        default: return "Should not see this"
      }
    }
    let getEditOccupancy = () => {
      if (this.props.application.property.purpose !== "purchase" && this.props.application.financingsameproperty)
        return "/app/interview/main/1"
      return "/app/interview/main/property"
    }


    return <div>
      <div className="mt-4">Doing a {purpose}</div>
      <div>for the property located at {getAddress()} {this.showButton(<Button className="ml-4 my-1 py-0"
        href={getPropertyPage()} size="sm" variant="outline-primary"> Edit</Button>)}</div>
      <div>The property is {propertyType()}</div>

      <div>It will be used as {occupancy()}</div>

      {false === this.props.application.property.hascontract && this.props.application.property.purpose === "purchase" ?
        <div>You're applying for a preapproval for up to ${com.commaizeFloat(this.props.application.property.salesprice)}
          </div>
        :
        <div>Estmated home value ${com.commaizeFloat(this.props.application.property.appraisal)}
          {this.props.application.property.purpose !== "purchase" ? "" :
            <span>
              ,  purchase price ${com.commaizeFloat(this.props.application.property.salesprice)}
            </span>}
          </div>
      }
    </div>


  }
  render() {

    return (
      <div className="mb-5  text-left">
        <div className="text-left mt-2 mb-4 pt-2 ">
          <h2 className=" heading">Please Review Your Personal and Loan Information </h2>
          <div className="viewport" >
            <div className="text-left sectionquestion">{(this.props.application.hascoborrower === "withcoborrower" ||
              this.props.application.hascoborrower === "multiple") ? "Applicants" : "Applicant"}:</div>
            {this.displayApplicants()}
          </div>
        </div>

        <div className="viewport" >
          <div className="text-left sectionquestion">Loan:</div>
          {this.displayLoan()}

        </div>

      </div>
    )
  }
}
PersonalReview = connect(mapStateToProps, mapDispatchToProps, null)(PersonalReview)
