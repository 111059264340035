import React, { useState } from 'react';
import './AboutMe.css';

const ReadMore = ({ children, maxCharacterCount = 100 }) => {
  const text = children;
  const [isTruncated, setIsTruncated] = useState(true);
  const resultString = isTruncated ? text.slice(0, maxCharacterCount) + '...' : text;
  function toggleIsTruncated() {
    setIsTruncated(!isTruncated);
  }
  return (
    <div style={{width:"80%"}}>
      <div className='introduction-new'>
        {resultString}
        <span onClick={toggleIsTruncated} className="readMore">
            {isTruncated ? 'Read More' : 'Close'}
        </span>
      </div>
    </div>
  );
};
export default ReadMore;