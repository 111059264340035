import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import * as com from "../Common.js"
import Form from 'react-bootstrap/Form'
import NumericalInput from '../NumericalInput'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { tooltipFlat } from '../Common/Tooltip'
import { Link } from 'react-router-dom'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import { property } from '../State'
import { getCurrentState } from '../Store'
import { calculateBalancesAndPayments } from './Liabilities'

const mapStateToProps = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    addBorrowerLoan: (event, who) => {
        dispatch(act.AddBorrowerLoan(event, who))
    },
    clearBorrowerLoans: (event, who) => {
        dispatch(act.ClearBorrowerLoans(event, who))
    },
    changeBorrowerLoan: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerLoan(payload, index, verb))
    },
    updateFinances: (t, who, verb) => {
        dispatch(act.UpdateFinances(t, who, verb))
    },
    updateJointly: (event) => {
        dispatch(act.UpdateJointly(event.target.checked))
    },
    addAlimony: (event, who) => {
        dispatch(act.AddAlimony(event, who))
    },
    removeAlimony: (i, who) => {
        dispatch(act.RemoveAlimony(i, who))
    },
    clearAlimonies: (event, who) => {
        dispatch(act.ClearAlimonies(event, who))
    },
    changeOwedTo: (event, i, who) => {
        dispatch(act.ChangeOwedTo(event.target.value, i, who))
    },
    changeAlimony: (event, i, who) => {
        dispatch(act.ChangeAlimony(event.target.value, i, who))
    },
    changeAlimonyField: (value, verb, who, i) => {
        dispatch(act.ChangeAlimonyField(value, verb, who, i))
    },
    changePaymentType: (event, i, who) => {
        dispatch(act.ChangePaymentType(event.target.value, i, who))
    },

    updateSolarPanels: (payload, verb) => {
        dispatch(act.UpdateSolarPanels(payload, verb))
    },
    addOtherLien: () => {
        dispatch(act.AddOtherLien())
    },
    changeBorrowerOtherLienValue: (t, i, verb) => {
        dispatch(act.ChangeBorrowerOtherLienValue(t, i, verb))
    },

    addBorrowerAdditionalProperty: (event, who) => {
        dispatch(act.AddBorrowerAdditionalProperty(event, who))
    },
    clearBorrowerAdditionalProperty: (event, who) => {
        dispatch(act.ClearBorrowerAdditionalProperty(event, who))
    },
    removeBorrowerAdditionalProperty: (i, who) => {
        dispatch(act.RemoveBorrowerAdditionalProperty(i, who))
    },
    changeBorrowerAdditionalPropertyAddress: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyAddress(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyAttribution: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyAttribution(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyStatus: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyStatus(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyType: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyType(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyValue: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyValue(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyLiens: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyLiens(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyGrossIncome: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyGrossIncome(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyPayment: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyPayment(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyExpenses: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyExpenses(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyNetIncome: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyNetIncome(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyAttribute: (value, index, verb) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyAttribute(value, index, verb))
    },

    updateApplicationAttribute: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.value, verb))
    },

    addBorrowerAdditionalPropertiesFromCredit: (payload) => {
        dispatch(act.AddBorrowerAdditionalPropertiesFromCredit(payload))
    },
    addNewMortgageLoan: () => {
        dispatch(act.AddNewMortgageLoan())
    },
    removeNewMortgageLoan: (index) => {
        dispatch(act.RemoveNewMortgageLoan(index))
    },
    clearNewMortgageLoans: () => {
        dispatch(act.ClearNewMortgageLoans())
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeBorrowerLoanSatisfiedUpon: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanSatisfiedUpon(event.target.value, i, who))
    },
});

export function skipLoan(l) {

    if (l.correction !== "none" && l.correction !== "")
        return true
    if (!(l.creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
        l.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
        l.creditloantype.toLowerCase().indexOf("realestate") >= 0))
        return true

    return false

}


export function countSubjectMortgages(purpose, loans, otherliens) {
    if (purpose === "purchase")
        return 0
    let counter = 0

    loans.forEach(l => {
        if (skipLoan(l))
            return
        if (l.forsubjectproperty) {
            counter += 1
        }
    })
    if (otherliens != null) {
        otherliens.forEach(l => {
            counter += 1
        })
    }
    return counter
}


export default class AlimonyAndMore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validated: false,
            loanbalance: parseInt(this.props.application.loanbalance),
            showWarning: false
        }
        this.getBalancePayment = calculateBalancesAndPayments.bind(this)
        /* eslint-disable-next-line no-unused-vars */
        let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()
        this.state.loanbalance = mortgagebalance
        window.setTimeout(() => {

            if (parseInt(this.props.application.loanbalance) === 0 &&
                !this.props.application.assetsandliabilities.propertyownedfreeandclear && (this.props.application.otherliens == null ||
                    (this.props.application.otherliens !== null && this.props.application.otherliens.length === 0))
            ) {
                this.props.addOtherLien()
            }
        }, 100)
        // build the additional properties
        this.buildAdditionalRealEstate()

        let getOptions = () => {
            let out = []
            let loans = this.props.application.assetsandliabilities.loans
            let otherliens = this.props.application.otherliens

            for (let i = 0; i < loans.length; i++) {
                let l = loans[i]
                if (skipLoan(l))
                    continue
                if (com.isHeloc(l))
                    continue
                if (l.forsubjectproperty) {
                    let line = `${l.name}, Account XXXX${l.accountnumber.slice(l.accountnumber.length - 4)},  Balance $${com.commaizeFloat(l.remainingbalance)}`
                    out.push(<option value={i + 1}>{line}</option>)
                    if (l.isprimary)
                        this.state.primary = i + 1
                }

            }
            if (otherliens) {
                for (let i = 0; i < otherliens.length; i++) {
                    let l = otherliens[i]
                    if (l.isHELOC)
                        continue
                    let line = `${l.lender}, Account XXXX${l.account.slice(l.account.length - 4)}, Balance $${com.commaizeFloat(l.balance)}`
                    out.push(<option value={-i - 1}>{line}</option>)
                    if (l.isprimary)
                        this.state.primary = -i - 1
                }
            }
            return out
        }
        this.subjectMortgages = getOptions()
    }


    editAdditionalRealEstate = () => {
        // check if this is new code
        let loans = this.props.application.assetsandliabilities.loans
        let additionalproperties = []
        let propertiesbyaddress = {}

        let getLoanByKey = (key) => {
            for (let i = 0; i < loans.length; i++) {
                let loan = loans[i]
                if (loan.key === key) {
                    return loan
                }
            }
            return null
        }
        let getAdditionalByKey = (key) => {
            for (let i = 0; i < additionalproperties.length; i++) {
                if (key === additionalproperties[i].key)
                    return additionalproperties[i]
            }
            return null
        }

        // remove additional properties that are not subject
        for (let i = 0; i < this.props.application.assetsandliabilities.propertiesfrompull.length; i++) {
            let additional = { ...this.props.application.assetsandliabilities.propertiesfrompull[i] }
            let loan = getLoanByKey(additional.key)
            if (null === loan)
                continue
            if (loan.forsubjectproperty === true)
                continue
            if (loan.correction !== "none")
                continue
            if ("undefined" === typeof propertiesbyaddress[loan.propertyaddress]) {
                additional.address = loan.propertyaddress
                additional.partoforiginalpurchase = loan.partoforiginalpurchase
                additional.includesmi = loan.includesmi

                additional.ownedfreeandclear = false
                additional.liens = loan.remainingbalance
                additional.payment = loan.monthlypayment

                additionalproperties.push(additional)
                propertiesbyaddress[additional.address] = additional
            } else {
                let p = propertiesbyaddress[loan.propertyaddress]
                p.liens = parseInt(loan.remainingbalance) + parseInt(p.liens)
                p.payment = parseInt(loan.monthlypayment) + parseInt(p.payment)
            }
        }
        // now all present in additionalproperties are actual
        // see if there are more:
        for (let i = 0; i < loans.length; i++) {
            let loan = loans[i]
            if (null != getAdditionalByKey(loan.key))
                continue
            if (loan.propertyaddress === null || loan.propertyaddress === "")
                continue
            if (loan.forsubjectproperty === true)
                continue
            if (loan.correction !== "none")
                continue
            if ("undefined" === typeof propertiesbyaddress[loan.propertyaddress]) {
                let p = new property()

                p.address = loan.propertyaddress
                p.creditor = loan.name
                p.account = loan.accountnumber
                p.ownedfreeandclear = false
                p.liens = loan.remainingbalance
                p.payment = loan.monthlypayment
                p.attribution = loan.attribution
                p.key = loan.key
                propertiesbyaddress[loan.propertyaddress] = p
                additionalproperties.push(p)
            } else {
                let p = propertiesbyaddress[loan.propertyaddress]

                p.liens = parseInt(loan.remainingbalance) + parseInt(p.liens)
                p.payment = parseInt(loan.monthlypayment) + parseInt(p.payment)
            }
        }

        this.props.addBorrowerAdditionalPropertiesFromCredit(additionalproperties)
    }
    buildAdditionalRealEstate = () => {

        if (null !== this.props.application.assetsandliabilities.propertiesfrompull)
            return this.editAdditionalRealEstate()// EDIT!!!

        let loans = this.props.application.assetsandliabilities.loans
        if (loans == null) return
        let additionalproperties = []
        let propertiesbyaddress = {}

        for (let i = 0; i < loans.length; i++) {
            let loan = loans[i]
            if (loan.propertyaddress === null || loan.propertyaddress === "")
                continue
            if ("undefined" === typeof propertiesbyaddress[loan.propertyaddress]) {
                let p = new property()
                propertiesbyaddress[loan.propertyaddress] = p
                additionalproperties.push(p)

                p.address = loan.propertyaddress
                p.creditor = loan.name
                p.account = loan.accountnumber
                p.ownedfreeandclear = false
                p.liens = loan.remainingbalance
                p.payment = loan.monthlypayment
                p.attribution = loan.attribution
                p.key = loan.key
            } else {
                let p = propertiesbyaddress[loan.propertyaddress]

                p.liens = parseInt(loan.remainingbalance) + parseInt(p.liens)
                p.payment = parseInt(loan.monthlypayment) + parseInt(p.payment)
            }
        }
        this.props.addBorrowerAdditionalPropertiesFromCredit(additionalproperties)
        getCurrentState()
    }

    componentDidMount() {

        this.recalcLoan()
    }

    componentWillUnmount() { }

    updateJointly = (e) => {
        com.touch()
        let f = e.target.value === "true"
        this.props.updateJointly({ target: { checked: f } })
    }

    processOtherCredits = (val, index) => {
        if (null === val)
            return ""
        let { altname, creditor, account, key } = val

        return this.renderRow(index, altname, creditor, account, key)
    }
    updateFinancesCheck = (verb) => {
        return e => {
            let val = e.target.value === "true"
            this.props.updateFinances(val, this.props.who, verb)
        }
    }
    updateFinances = (verb) => {
        return e => this.props.updateFinances(e.target.value, this.props.who, verb)
    }

    nbsp = () => {
        return (<div className="mt-4">&nbsp;<br />&nbsp;</div>)
    }
    addOtherSources = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerLoans(0, this.props.who)
        } else {
            this.props.addBorrowerLoan(0, this.props.who)
        }
    }

    processAlimonies = (val, index) => {
        if (null === val)
            return ""

        let amount = val.amount;
        let owedto = val.owedto;
        let whatisit = val.alimonyorsupport;
        let lessthan10 = val.lessthan10
        let monthslefttopay = val.monthstopay
        let key = val.key
        return this.generateAlimony(index, amount, owedto, lessthan10, whatisit, monthslefttopay, key)
    }

    generateAlimony = (index, amount, owedto, lessthan10, whatisit, monthslefttopay, key) => {
        index = parseInt(index)
        let removeAlimony = (event) => {
            com.touch()
            this.props.removeAlimony(index, this.props.who)
        }
        let changeAlimony = (event) => {
            com.touch()
            this.props.changeAlimony(event, this.props.who, index)
        }
        let changeOwedTo = (event) => {
            com.touch()
            this.props.changeOwedTo(event, this.props.who, index)
        }
        let addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, this.props.who)
        }
        let updateWhat = (event) => {
            com.touch()
            this.props.changePaymentType(event, this.props.who, index)
        }
        let changeMonths = (event) => {
            com.touch()
            this.props.changeAlimonyField(event.target.value, "monthstopay", this.props.who, index)

        }
        let updateKnowledge = event => {
            com.touch()
            this.props.changeAlimonyField(event.target.value, "lessthan10", this.props.who, index)
        }
        return (
            <div key={key} className="incard">

                <Row className="p-0">
                    <Col className="p-0 px-1">
                        <Row >

                            <Col xs="auto" style={{ size: "4em" }}>
                                <Form.Group controlId="Amount" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Monthly Amount:</Form.Label>
                                    <NumericalInput isValid={false} size="sm"
                                        required
                                        name={"amount" + index}

                                        type="text"
                                        defaultValue={amount}
                                        onChange={changeAlimony}

                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="type" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Payment type</Form.Label>
                                    <Form.Control name="purpose" as="select"
                                        value={whatisit}
                                        onChange={updateWhat}
                                        size="sm">

                                        <option value="alimony">Alimony</option>
                                        <option value="childsupport">Child Support</option>
                                        <option value="maintenancepayments">Separate Maintenance Payments</option>

                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col   >
                                <Form.Group controlId="owedto" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Owed To:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"owedto" + index}
                                        type="text"
                                        defaultValue={owedto}
                                        onChange={changeOwedTo}

                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the payee.
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {tooltipFlat(<span>Will this obligation be paid off in <b>10 months or less</b></span>,
                                    <div><div>If your child support, or alimony will be completely paid off in 10 months or less, these payments can be excluded from determining your debt to income ration, potentially resulting in a better rate</div>

                                        <div>If you don't know the number of months, please upload your divorse decree, or separation agreement.</div>
                                    </div>,
                                    "auto", "", true, true)}
                                <div className="d-flex mt-1">

                                    <div className="mr-3 ">
                                        <input onChange={updateKnowledge} value={"lessthan10"} checked={lessthan10 !== null && lessthan10 === "lessthan10"} type="radio" id="lessthan10" name="lessthan10"></input> <label className="divlink" htmlFor="lessthan10">Yes, it will be paid off in 10 months or less</label></div>
                                    <div className="mr-3" ><input onChange={updateKnowledge} value={"morethan10"} checked={lessthan10 !== null && lessthan10 === "morethan10"} type="radio" id="morethan10" name="lessthan10"></input> <label className="divlink" htmlFor="morethan10">No, it won't</label></div>
                                    <div><input onChange={updateKnowledge} value={"notsure"} checked={lessthan10 !== null && lessthan10 === "notsure"} type="radio" id="notsure" name="lessthan10"></input> <label className="divlink" htmlFor="notsure">I'm not sure</label></div>
                                </div>
                            </Col>
                        </Row>
                        {lessthan10 !== "notsure" ? "" :
                            <Row>
                                <Col xs="auto">
                                    Please <Link to="/app/docs">upload</Link> your divorse decree or separation agreement, and your loan officer will help you!
                                </Col>
                            </Row>
                        }
                        {lessthan10 !== "lessthan10" ? "" :
                            <Row>
                                <Col xs="auto">Months left to pay:</Col>
                                <Col xs="auto">
                                    <Form.Group controlId="monthslefttopay" className="text-left" size="sm" >

                                        <Form.Control isValid={false} size="sm"
                                            required
                                            name={"monthslefttopay" + index}
                                            type="number"
                                            defaultValue={monthslefttopay}
                                            onChange={changeMonths}
                                            max={10}
                                            min={1}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please enter number of months
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>
                        }
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top rightmobile">
                        <i hidden={index !== this.props.application[this.props.who].finances.alimony.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addAlimony} ></i>
                        <i hidden={index === this.props.application[this.props.who].finances.alimony.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>

                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeAlimony} ></i>
                    </Col>
                </Row>
            </div >
        )
    }

    sameaddress = () => {
        let addr = com.formatAddress(this.props.application.borrower)
        return addr
    }

    processAdditionalRealEstate = (val, index) => {
        if (null === val)
            return ""
        let { address, status, propertytype, marketvalue, liens,
            grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key } = val

        return this.renderAdditional(index, address, status, propertytype, marketvalue, liens,
            grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key)
    }

    renderAdditional = (index, address, status, propertytype, marketvalue, liens,
        grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key) => {
        index = parseInt(index)
        let removeBorrowerAdditionalProperty = (event) => {
            com.touch()
            return this.props.removeBorrowerAdditionalProperty(index, this.props.who)
        }
        let addBorrowerAdditionalProperty = (event) => {
            com.touch()
            return this.props.addBorrowerAdditionalProperty(index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyAddress = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyAddress(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyStatus = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyStatus(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyType = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyType(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyValue = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyValue(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyLiens = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyLiens(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyGrossIncome = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyGrossIncome(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyPayment = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyPayment(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyExpenses = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyExpenses(event, index, this.props.who)
        }

        let changeBorrowerAdditionalPropertyAttribution = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyAttribution(event, index, this.props.who)
        }
        let updateFreeAncClear = (event) => {
            com.touch()
            this.props.changeBorrowerAdditionalPropertyAttribute(event.target.checked, index, "ownedfreeandclear")
        }
        let changeBorrowerAdditionalRE = verb => {
            return e => {
                com.touch()
                this.props.changeBorrowerAdditionalPropertyAttribute(e.target.value, index, verb)
            }
        }
        return (
            <div key={key} className="incard">
                <Row>
                    <Col className="p-0 ">
                        <Row className="p-0 ">
                            <Col >
                                <AddressAutocomplete id={"Address" + index} label="Property Address:"
                                    required={true} name={"address" + index}
                                    defaultValue={this.props.application.assetsandliabilities.properties[index].address}
                                    onChange={changeBorrowerAdditionalPropertyAddress}
                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    goodfeedback="Looks good!"
                                    badfeedback="Please provide address."
                                />
                            </Col>

                            {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                                <Col xs="2">
                                    <Form.Label className="text-left" >Ownership</Form.Label>
                                    <Form.Group controlId="employment" >

                                        <Form.Control name="employment" as="select" size="sm"
                                            defaultValue={attribution}
                                            onChange={changeBorrowerAdditionalPropertyAttribution}
                                            required
                                        >
                                            <option value="">...</option>
                                            <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                            <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                            <option value="3">Joint</option>

                                        </Form.Control>
                                    </Form.Group>
                                </Col> : ""
                            }


                        </Row>
                        <Row>
                            <Col xs="auto" >
                                <Form.Group controlId="propertytype" className="text-left" >
                                    <Form.Label className="text-left" >Property Type:&nbsp;
                                    </Form.Label>
                                    <Form.Control name="propertytype" as="select"
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].propertytype}
                                        type="text"
                                        size="sm"

                                        onChange={changeBorrowerAdditionalPropertyType}
                                    >
                                        <option value="singlefamilyattached">Single Family Attached</option>
                                        <option value="singlefamilydetached">Single Family </option>
                                        <option value="condominium">Condominium</option>
                                        <option value="pudattached">PUD attached</option>
                                        <option value="puddetached">PUD detached</option>
                                        <option value="twotofourfamily">Two to Four Unit Multifamily</option>
                                        <option value="land">Land</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" >
                                <Form.Group controlId={"Status" + index} className="text-left" >
                                    <Form.Label className="text-left" >Status:&nbsp;</Form.Label>
                                    <Form.Control name={"status" + index} as="select" size="sm"
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].status}
                                        onChange={changeBorrowerAdditionalPropertyStatus}

                                    >
                                        <option value="other">Keeping in posession</option>
                                        <option value="sold">Sold</option>
                                        <option value="pending">Pending sale</option>
                                        <option value="rental">Rental</option>
                                        <option value="second">Second home</option>

                                    </Form.Control>

                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="bonuses" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Present Market Value:</Form.Label>
                                    <NumericalInput isValid={false}
                                        required
                                        size="sm"
                                        name="assets"
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].marketvalue}
                                        onChange={changeBorrowerAdditionalPropertyValue}

                                        min={1}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the value
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>



                        </Row>
                        <Row>
                            <Col xs="auto">
                                <Form.Group controlId={"taxes" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Insurance, Maintenance, Taxes/mo: </Form.Label>

                                    <NumericalInput isValid={false}
                                        required
                                        size="sm"

                                        name={"taxes" + index}
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].insurancemaintenancetaxes}
                                        onChange={changeBorrowerAdditionalPropertyExpenses}

                                        min={0}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the value.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId={"income" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Gross Rental Income/mo:</Form.Label>

                                    <NumericalInput isValid={false}
                                        required
                                        size="sm"

                                        name={"income" + index}
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].grossrentalincome}
                                        onChange={changeBorrowerAdditionalPropertyGrossIncome}

                                        min={0}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the value
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col><br />
                                <div className="mt-3">
                                    <input type="checkbox" id={"freenadclear" + index}
                                        defaultChecked={this.props.application.assetsandliabilities.properties[index].ownedfreeandclear}
                                        onChange={updateFreeAncClear} ></input>
                                    <label id={"freenadclearlabel" + index} style={{ textDecoration: "underline" }}
                                        className="divlink ml-1 text-wrap" htmlFor={"freenadclear" + index}>I own this property free and clear</label>
                                </div>

                            </Col>

                        </Row>

                        {this.props.application.assetsandliabilities.properties[index].ownedfreeandclear ? "" :
                            <Row>
                                <Col>
                                    <Form.Group controlId={"creditor" + index} className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Creditor:</Form.Label>
                                        <Form.Control isValid={false}
                                            required
                                            type="text"
                                            size="sm"
                                            defaultValue={this.props.application.assetsandliabilities.properties[index].creditor}
                                            onChange={changeBorrowerAdditionalRE("creditor")}

                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the value
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId={"creditor" + index} className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Account Number:</Form.Label>
                                        <Form.Control isValid={false}
                                            required
                                            type="text"
                                            size="sm"
                                            defaultValue={this.props.application.assetsandliabilities.properties[index].account}
                                            onChange={changeBorrowerAdditionalRE("account")}

                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the value
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs="auto">
                                    <Form.Group controlId="Liens" className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Mortgages and Liens:</Form.Label>
                                        <NumericalInput isValid={false}
                                            required
                                            size="sm"
                                            name="liens"
                                            defaultValue={this.props.application.assetsandliabilities.properties[index].liens}
                                            onChange={changeBorrowerAdditionalPropertyLiens}
                                            min={0}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the value
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs="auto">
                                    <Form.Group controlId={"mortgage" + index} className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Mortgage Payments:</Form.Label>

                                        <NumericalInput isValid={false}
                                            required
                                            size="sm"

                                            name={"mortgage" + index}
                                            defaultValue={this.props.application.assetsandliabilities.properties[index].payment}
                                            onChange={changeBorrowerAdditionalPropertyPayment}

                                            min={0}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the value
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top rightmobile">
                        <i hidden={index !== this.props.application.assetsandliabilities.properties.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addBorrowerAdditionalProperty} ></i>
                        <i hidden={index === this.props.application.assetsandliabilities.properties.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeBorrowerAdditionalProperty}></i>

                    </Col>
                </Row>
            </div>
        )
    }
    addOtherProperties = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerAdditionalProperty(0, this.props.who)
        } else {
            this.props.addBorrowerAdditionalProperty(0, this.props.who)
        }
    }

    updateApplicationAttribute = (value) => {

        this.props.updateApplicationAttribute({ target: { value } }, "loanbalance")
    }

    recalcLoan = () => {
        /* eslint-disable-next-line no-unused-vars */
        let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()
        this.setState({ loanbalance: mortgagebalance + otherbalance })

        this.updateApplicationAttribute((mortgagebalance + otherbalance).toString())
        if (this.props.application.property.purpose === st.POL_Cashoutrefinance) {
            this.props.updateSelectField(cashout, "mincashout")
        }

    }
    recalcLoanDelayed = () => {
        setTimeout(() => { this.recalcLoan() }, 100)
    }
    handleSubmit = e => {
        if (this.props.application.property.purpose !== st.POL_Purchase) {



            if (this.props.application.otherliens !== null && this.props.application.otherliens.length === 0 &&
                parseInt(this.props.application.loanbalance) === 0)
                return false
            if (parseInt(this.props.application.loanbalance) === 0 &&
                !this.props.application.assetsandliabilities.propertyownedfreeandclear &&
                this.props.application.otherliens.length === 0
            ) {
                window.setTimeout(this.props.addOtherLien, 100)
                return false
            }
            this.recalcLoan()
        }

        return true
    }
    showALimony = () => {
        if (this.props.application.property.purpose === st.POL_Purchase)
            return true
        if (this.props.application.otherliens !== null && this.props.application.otherliens.length === 0 &&
            parseInt(this.props.application.loanbalance) === 0)
            return false
        return this.props.application.otherliens !== null
    }
    showSolar = () => {
        return this.showALimony() && this.props.application[this.props.who].finances.alimony !== null
    }
    showAdditionalRealEstate = () => {
        return true
    }
    addOtherMortgages = e => {
        let val = e.target.value === "true"
        com.touch()
        if (val)
            this.props.addNewMortgageLoan()
        else
            this.props.clearNewMortgageLoans()
    }
    isPreapproval = () => {
        return false === this.props.application.property.hascontract && this.props.application.property.purpose === "purchase"
    }

    render = () => {
        let iwe = (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
            "we" : "I"
        //let label = `Do ${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname} and 
        //${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} report assets and liabilities jointly?`

        let meus = (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ? 'we' : 'I'

        let clearAlimony = (event) => {
            com.touch()
            this.props.clearAlimonies(event, this.props.who)
        }

        let addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, this.props.who)
        }
        let solarPanelsToggle = (verb) => {
            return e => {
                let val = e.target.value === "true"
                return this.props.updateSolarPanels(val, verb)
            }
        }
        let hasPanels = e => {
            this.props.updateSolarPanels(true, "havesolarpanels")
        }
        let noPanels = e => {
            this.props.updateSolarPanels(false, "havesolarpanels")
        }
        let areLeased = e => {
            this.props.updateSolarPanels(true, "areleased")
        }
        let notLeased = e => {
            this.props.updateSolarPanels(false, "areleased")
        }
        let updateMonthlypayment = e => {
            this.props.updateSolarPanels(e.target.value, "monthlypayment")
        }

        let purchaseHasPanels = () => {
            if (!this.props.application.property.solarpanels.havesolarpanels)
                return ""
            return (
                <div className="mx-0 px-0 ">
                    <Row>
                        <Col xs="auto" style={{ width: '2em' }}>&nbsp;</Col>
                        <Col ><div className="sectionquestion">
                            Will the solar panels remain on the property after close of escrow?
                        </div>
                            <div className="d-flex mt-1">

                                <div className="mr-3 ">
                                    <input onChange={solarPanelsToggle("remainonproperty")} value={true} checked={this.props.application.property.solarpanels.remainonproperty} type="radio" id="remainonproperty" name="remainonproperty"></input> <label className="divlink" htmlFor="remainonproperty">Yes, they will</label></div>
                                <div><input onChange={solarPanelsToggle("remainonproperty")} value={false} checked={!this.props.application.property.solarpanels.remainonproperty} type="radio" id="remainonpropertynot" name="remainonproperty"></input> <label className="divlink" htmlFor="remainonpropertynot">No, they won't</label></div>
                            </div>
                        </Col>
                    </Row>
                    {this.props.application.property.solarpanels.remainonproperty ?
                        <Row>
                            <Col xs="auto" style={{ width: '2em' }}>&nbsp;</Col>
                            <Col ><div className="sectionquestion">
                                Are these panels leased?
                            </div>
                                <div className="d-flex mt-1">

                                    <div className="mr-3 ">
                                        <input onChange={solarPanelsToggle("areleased")} value={true} checked={this.props.application.property.solarpanels.areleased} type="radio" id="leased" name="leased"></input> <label className="divlink" htmlFor="leased">Yes, they are</label></div>
                                    <div><input onChange={solarPanelsToggle("areleased")} value={false} checked={!this.props.application.property.solarpanels.areleased} type="radio" id="leasednot" name="leased"></input> <label className="divlink" htmlFor="leasednot">No, they are not</label></div>
                                </div>
                            </Col>
                        </Row>
                        : ""}
                    {this.props.application.property.solarpanels.remainonproperty &&
                        this.props.application.property.solarpanels.areleased ?
                        <Row>
                            <Col xs="auto" style={{ width: '2em' }}>&nbsp;</Col>
                            <Col ><div className="sectionquestion">
                                Will you be taking over the lease?
                            </div>
                                <div className="d-flex mt-1">

                                    <div className="mr-3 ">
                                        <input onChange={solarPanelsToggle("takeoverthelease")} value={true} checked={this.props.application.property.solarpanels.takeoverthelease} type="radio" id="takeoverthelease" name="takeoverthelease"></input> <label className="divlink" htmlFor="takeoverthelease">Yes, {iwe} will</label></div>
                                    <div><input onChange={solarPanelsToggle("takeoverthelease")} value={false} checked={!this.props.application.property.solarpanels.takeoverthelease} type="radio" id="takeovertheleasenot" name="takeoverthelease"></input> <label className="divlink" htmlFor="takeovertheleasenot">No, {iwe} won't</label></div>
                                </div>
                            </Col>
                        </Row>
                        : ""
                    }

                    {(this.props.application.property.solarpanels.remainonproperty &&
                        this.props.application.property.solarpanels.havesolarpanels &&
                        this.props.application.property.solarpanels.takeoverthelease
                    ) ?
                        <Row>
                            <Col xs="auto" style={{ width: '2em' }}>&nbsp;</Col>
                            <Col xs="auto">
                                <Form.Group controlId="monthly" className="text-left" size="sm">
                                    <Form.Label className="text-left" >Mo. Payment: <br /><div style={{ fontSize: '0.8em' }}>(lease agreement will be required)</div></Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="monthly"

                                        type="number"
                                        defaultValue={this.props.application.property.solarpanels.monthlypayment}
                                        onChange={updateMonthlypayment}

                                        min={1}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>
                        </Row>
                        : ""
                    }
                </div>
            )
        }
        let refinanceHasPanels = () => {
            if (!this.props.application.property.solarpanels.havesolarpanels)
                return ""
            return (
                <div>
                    <Row>
                        <Col xs="auto" style={{ width: '2em' }}>&nbsp;</Col>
                        <Col ><div className="sectionquestion">
                            Are these panels leased?
                        </div>
                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mt-1">

                                        <div className="mr-3 ">
                                            <input required onChange={areLeased} value={true} checked={true === this.props.application.property.solarpanels.areleased} type="radio" id="leased" name="leased"></input>
                                            <label className="zeitro-radio divlink" htmlFor="leased">Yes, they are</label></div>
                                        <div><input required onChange={notLeased} value={false} checked={false === this.props.application.property.solarpanels.areleased} type="radio" id="leasednot" name="leased"></input>
                                            <label className="zeitro-radio divlink" htmlFor="leasednot">No, they are not</label></div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please specify if the panels are leased
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    {(this.props.application.property.solarpanels.areleased) ?
                        <Row>
                            <Col xs="auto" style={{ width: '2em' }}>&nbsp;</Col>
                            <Col xs="auto">
                                <Form.Group controlId="monthly" className="text-left" size="sm">
                                    <Form.Label className="text-left" >Monthly Payment: <br /><div style={{ fontSize: '0.8em' }}>(lease agreement will be required)</div></Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="monthly"

                                        type="number"
                                        defaultValue={this.props.application.property.solarpanels.monthlypayment}
                                        onChange={updateMonthlypayment}

                                        min={1}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your monthly lease payment.
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>
                        </Row>
                        : ""
                    }
                </div>
            )
        }

        let showOnlyForBorrower = () => {
            if (this.props.application.hascoborrower !== "withcoborrower")
                return true
            return this.props.who === com.borrower
        }

        let havePartOfOriginalTransaction = () => {
            if (this.props.application.property.purpose === "purchase")
                return false
            let loans = this.props.application.assetsandliabilities.loans

            for (let i = 0; i < loans.length; i++) {
                let l = loans[i]
                if (skipLoan(l))
                    continue
                if (l.forsubjectproperty && !l.isprimary && l.partoforiginalpurchase) {
                    return true
                }
            }
            let otherliens = this.props.application.otherliens
            for (let i = 0; i < otherliens.length; i++) {
                let l = otherliens[i]
                if (l.isHELOC === true)
                    return
                if (!l.isprimary && l.partoforiginalpurchase) {
                    return true
                }
            }

            return false
        }
        let isPrimarySelected = () => {
            if (this.props.application.property.purpose === "purchase")
                return false
            let loans = this.props.application.assetsandliabilities.loans

            for (let i = 0; i < loans.length; i++) {
                let l = loans[i]
                if (skipLoan(l))
                    continue
                if (l.forsubjectproperty && l.isprimary) {
                    return true
                }
            }
            let otherliens = this.props.application.otherliens
            for (let i = 0; i < otherliens.length; i++) {
                let l = otherliens[i]
                if (l.isHELOC === true)
                    return
                if (l.isprimary) {
                    return true
                }
            }

            return false
        }
        let displaySubjectPropertyLoans = () => {
            let out = []
            let loans = this.props.application.assetsandliabilities.loans
            let otherliens = this.props.application.otherliens
            out.push(<div className="ml-3 sectionquestion mb-3">Let's review your secondary loans:</div>)
            for (let i = 0; i < loans.length; i++) {
                let l = loans[i]
                if (skipLoan(l) || l.isprimary === true)
                    continue
                let changePartOfOriginal = e => {
                    com.touch()
                    this.props.changeBorrowerLoan(e.target.value, i, "partoforiginalpurchase")
                    setTimeout(() => this.recalcLoan(), 500)
                }

                let changeBorrowerLoanSatisfiedUpon = e => {
                    com.touch()
                    this.props.changeBorrowerLoanSatisfiedUpon(e, i, this.props.who)
                    setTimeout(() => this.recalcLoan(), 500)
                }
                if (l.forsubjectproperty) {
                    let line =
                        <div className="secondarycard">
                            <Row className="mb-2">
                                <Col xs="auto" className="pr-0 sectionquestion">Lender:</Col>
                                <Col xs="auto" className="pl-1 sectionquestion" style={{ fontWeight: 'bold' }}>{l.name}</Col>
                                <Col xs="auto" className="pr-0 sectionquestion">Balance:</Col>
                                <Col xs="auto" className="pl-1 sectionquestion" style={{ fontWeight: 'bold' }}>${com.commaizeFloat(l.remainingbalance)}</Col>
                                {com.isHeloc(l) ?
                                    <Col className="pl-0 sectionquestion" style={{ fontWeight: 'bold' }}>Home Equity Loan</Col>
                                    : ""}
                                <Col></Col>
                            </Row>
                            <Row className="">
                                <Col >
                                    <div className="sectionquestion"><i className="fas fa-angle-right"></i>Was this loan a part of the original purchase of the property?</div>
                                    <div className="d-flex mb-0">

                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changePartOfOriginal} value="true" checked={true === l.partoforiginalpurchase} type="radio" id={"partoforiginalpurchase" + i} name={"partoforiginalpurchase" + i}></input>
                                            <label id={"partoforiginalpurchaselabel" + i} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchase" + i}>Yes, it was</label>
                                        </div>
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changePartOfOriginal} value="false" checked={false === l.partoforiginalpurchase} type="radio" id={"partoforiginalpurchasenot" + i} name={"partoforiginalpurchase" + i}></input>
                                            <label id={"partoforiginalpurchasenotlabel" + i} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchasenot" + i}>No, it wasn't</label>
                                        </div>

                                    </div>

                                </Col>

                            </Row>
                            {l.partoforiginalpurchase != null &&
                                (this.props.application.property.purpose === st.POL_Refinance && l.partoforiginalpurchase === false) ?
                                <Row>
                                    <Col style={{ color: '#662222' }}>
                                        This loan can't be refinanced along with the primary mortgage unless you apply for a cash out refinance.

                                    </Col>
                                </Row>
                                : ""}
                            {l.partoforiginalpurchase != null &&
                                (this.props.application.property.purpose === st.POL_Refinance && l.partoforiginalpurchase === true) ?
                                <Row>
                                    <Col style={{ color: '#226622' }}>
                                        Good news! This loan can be refinanced along with the primary mortgage.

                                    </Col>
                                </Row>
                                : ""}

                            {l.partoforiginalpurchase != null ?
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <div className="sectionquestion"><i className="fas fa-angle-right"></i>Will you pay the balance at or before this transaction?
                                            </div>
                                            <fieldset>
                                                <div className="d-flex mb-0">
                                                    {
                                                        ((this.props.application.property.purpose === st.POL_Refinance && l.partoforiginalpurchase === true)
                                                            || this.props.application.property.purpose === st.POL_Cashoutrefinance)
                                                            ?
                                                            <div className="ml-2 mr-3 ">
                                                                <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withproceeds" checked={l.satisfiedupon === "withproceeds"} type="radio" id={"satisfied" + i} name={"satisfied" + i}></input>
                                                                <label id={"satisfiedlabel" + i} className="zeitro-radio divlink" htmlFor={"satisfied" + i}>I want this loan to be part of my refinance</label>
                                                            </div>
                                                            : ""}
                                                    <div className="ml-2 mr-3 ">
                                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={l.satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown" + i} name={"satisfied" + i}></input>
                                                        <label id={"satisfieddownlabel" + i} className="zeitro-radio divlink" htmlFor={"satisfiedown" + i}>I'm paying it off myself</label>
                                                    </div>
                                                    <div className="ml-2 mr-3 ">
                                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="not" checked={l.satisfiedupon === "not"} type="radio" id={"notsatisfied" + i} name={"satisfied" + i}></input>
                                                        <label id={"notsatisfiedlabel" + i} className="zeitro-radio divlink" htmlFor={"notsatisfied" + i}>No, this loan won't be touched</label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please make your choice.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row> : ""}
                        </div>
                    out.push(line)
                }

            }

            for (let i = 0; i < otherliens.length; i++) {
                let l = otherliens[i]
                if (l.isprimary === true)
                    continue
                let changePartOfOriginal = e => {
                    com.touch()
                    this.props.changeBorrowerOtherLienValue(e.target.value === "true", i, "partoforiginalpurchase")
                    setTimeout(() => this.recalcLoan(), 500)
                }
                let changeBorrowerLoanSatisfiedUpon = e => {
                    this.props.changeBorrowerOtherLienValue(e.target.value, i, "satisfiedupon")

                    com.touch()
                    this.props.changeBorrowerOtherLienValue(e.target.value, i, "satisfiedupon")
                    setTimeout(() => this.recalcLoan(), 500)
                }
                let line =
                    <div className="secondarycard">
                        <Row className="mb-2">
                            <Col xs="auto" className="pr-0 sectionquestion">Lender:</Col>
                            <Col xs="auto" className="pl-1 sectionquestion" style={{ fontWeight: 'bold' }}>{l.lender}</Col>
                            <Col xs="auto" className="pr-0 sectionquestion">Balance:</Col>
                            <Col xs="auto" className="pl-1 sectionquestion" style={{ fontWeight: 'bold' }}>${com.commaizeFloat(l.balance)}</Col>
                            {l.isHELOC ?
                                <Col xs="" className="pl-1 sectionquestion" style={{ fontWeight: 'bold' }}>Home Equity Loan</Col>
                                : ""}
                            <Col></Col>
                        </Row>
                        <Row >
                            <Col >
                                <div className="sectionquestion"><i className="fas fa-angle-right"></i>Was this loan a part of the original purchase of the property?</div>
                                <div className="d-flex mb-0">

                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changePartOfOriginal} value="true" checked={true === l.partoforiginalpurchase} type="radio" id={"partoforiginalpurchase-" + i} name={"partoforiginalpurchase-" + i}></input>
                                        <label id={"partoforiginalpurchaselabel-" + i} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchase-" + i}>Yes, it was</label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changePartOfOriginal} value="false" checked={false === l.partoforiginalpurchase} type="radio" id={"partoforiginalpurchasenot-" + i} name={"partoforiginalpurchase-" + i}></input>
                                        <label id={"partoforiginalpurchasenotlabel-" + i} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchasenot-" + i}>No, it wasn't</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {l.partoforiginalpurchase != null &&
                            (this.props.application.property.purpose === st.POL_Refinance && l.partoforiginalpurchase === false) ?
                            <Row>
                                <Col style={{ color: '#662222' }}>
                                    This loan can't be refinanced along with the primary mortgage unless you apply for a cash out refinance.

                                </Col>
                            </Row>
                            : ""}
                        {l.partoforiginalpurchase != null &&
                            (this.props.application.property.purpose === st.POL_Refinance && l.partoforiginalpurchase === true) ?
                            <Row>
                                <Col style={{ color: '#226622' }}>
                                    Good news! This loan can be refinanced along with the primary mortgage.

                                </Col>
                            </Row>
                            : ""}
                        {l.partoforiginalpurchase != null
                            ?
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <div className="sectionquestion"><i className="fas fa-angle-right"></i>Will you pay the balance at or before this transaction?
                                        </div>
                                        <fieldset>
                                            <div className="d-flex mb-0">

                                                {((this.props.application.property.purpose === st.POL_Refinance && l.partoforiginalpurchase === true)
                                                    || this.props.application.property.purpose === st.POL_Cashoutrefinance)
                                                    ?
                                                    <div className="ml-2 mr-3 ">
                                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withproceeds" checked={l.satisfiedupon === "withproceeds"} type="radio" id={"satisfied-" + i} name={"satisfied-" + i}></input>
                                                        <label id={"satisfiedlabel-" + i} className="zeitro-radio divlink" htmlFor={"satisfied-" + i}>I want this loan to be part of my refinance</label>
                                                    </div>
                                                    : ""}
                                                <div className="ml-2 mr-3 ">
                                                    <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={l.satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown-" + i} name={"satisfied-" + i}></input>
                                                    <label id={"satisfieddownlabel-" + i} className="zeitro-radio divlink" htmlFor={"satisfiedown-" + i}>I'm paying it off myself</label>
                                                </div>
                                                <div className="ml-2 mr-3 ">
                                                    <input required onChange={changeBorrowerLoanSatisfiedUpon} value="not" checked={l.satisfiedupon === "not"} type="radio" id={"notsatisfied-" + i} name={"satisfied-" + i}></input>
                                                    <label id={"notsatisfiedlabel-" + i} className="zeitro-radio divlink" htmlFor={"notsatisfied-" + i}>No, this loan won't be touched</label>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please make your choice.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row> : ""
                        }
                    </div>
                out.push(line)
            }
            return out
        }
        let subjCount = countSubjectMortgages(this.props.application.property.purpose,
            this.props.application.assetsandliabilities.loans,
            this.props.application.otherliens)

        let onChangePrimary = e => {

            if (this.state.primary > 0) {
                this.props.changeBorrowerLoan(false, this.state.primary - 1, "isprimary")
            } else if (this.state.primary < 0) {
                this.props.changeBorrowerOtherLienValue(false, this.state.primary + 1, "isprimary")
            }
            let newi = parseInt(e.target.value)
            if (newi > 0) {
                this.props.changeBorrowerLoan(true, newi - 1, "isprimary")
                this.props.changeBorrowerLoan("withproceeds", newi - 1, "satisfiedupon")
            } else if (newi < 0) {
                this.props.changeBorrowerOtherLienValue(true, newi + 1, "isprimary")
                this.props.changeBorrowerOtherLienValue("withproceeds", newi + 1, "satisfiedupon")
            } else {

            }
            setTimeout(() => this.recalcLoan(), 500)
            this.setState({ primary: newi })
        }

        return (
            <div
                className=" mb-4 text-left px-0" >
                <h2 className=" heading">
                    <span>
                        Almost done with {
                            `${this.props.application[this.props.who].firstname} ${this.props.application[this.props.who].lastname} `
                        }'s Liabilities
                    </span>
                    {this.props.application.property.purpose !== "purchase" ?
                        <span className="hideonphone700" style={{ marginTop: "4px", fontSize: "0.7em", fontWeight: "normal", position: "absolute", right: "4em" }}> loan balance: ${(com.commaizeFloat(this.state.loanbalance))}</span>
                        : ""}
                </h2>

                {
                    this.props.application.property.purpose !== "purchase" && subjCount > 1 ?
                        <div className="viewport mt-3">
                            <Row>
                                <Col><div className="sectionquestion">You have {subjCount} loans for {com.formatAddress(this.props.application.borrower)}.
                                    <div className="mb-2"></div>
                                </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="auto">
                                    <Form.Group controlId="primaryselect">
                                        <Form.Label className="text-left" >Please select the primary loan:</Form.Label>
                                        <Form.Control required name="purpose" as="select" size="sm"
                                            defaultValue={this.state.primary}
                                            onChange={onChangePrimary}
                                        >

                                            <option value="">...</option>
                                            {this.subjectMortgages}
                                        </Form.Control>
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please select the primary loan.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Col>
                            </Row>
                            {isPrimarySelected() ?
                                displaySubjectPropertyLoans() : ""
                            }
                        </div>

                        : ""
                }
                <div className="viewport mt-3">

                    <Row>
                        <Col><div className="sectionquestion">Do you pay alimony, child support or separate maintenance payments?</div>
                            <div className="d-flex mt-1">
                                <div className="mr-3 ">
                                    <input onChange={addAlimony} value={true} required
                                        checked={null != this.props.application[this.props.who].finances.alimony && this.props.application[this.props.who].finances.alimony.length > 0}
                                        type="radio" id="alimony" name="alimony"></input>
                                    <label className="zeitro-radio divlink" htmlFor="alimony">Yes, {iwe} do</label></div>
                                <div><input onChange={clearAlimony} value={false} required
                                    checked={null != this.props.application[this.props.who].finances.alimony && this.props.application[this.props.who].finances.alimony.length === 0}
                                    type="radio" id="alimonynot" name="alimony"></input>
                                    <label className="zeitro-radio divlink" htmlFor="alimonynot">No, {iwe} don't</label></div>
                            </div>
                        </Col>
                    </Row>
                    {this.props.application[this.props.who].finances.alimony != null ? this.props.application[this.props.who].finances.alimony.map(this.processAlimonies) : ""}
                </div>
                {(this.props.who === "borrower" && !this.isPreapproval()) ?
                    <div className="viewport mt-3">
                        <Row>
                            <Col><div className="sectionquestion">
                                {
                                    (this.props.application.property.purpose === st.POL_Purchase) ?
                                        "Does the property you're purchasing have solar panels installed?"
                                        :
                                        "Does the property you're refinancing have solar panels installed?"
                                }
                            </div>
                                <div className="d-flex mt-1">

                                    <div className="mr-3 ">
                                        <input required onChange={hasPanels} value={true} checked={true === this.props.application.property.solarpanels.havesolarpanels} type="radio" id="solarpanels" name="solarpanels"></input>
                                        <label className="zeitro-radio divlink" htmlFor="solarpanels">Yes, it does</label></div>
                                    <div><input required onChange={noPanels} value={false} checked={false === this.props.application.property.solarpanels.havesolarpanels} type="radio" id="solarpanelsnot" name="solarpanels"></input>
                                        <label className="zeitro-radio divlink" htmlFor="solarpanelsnot">No, it does not</label></div>
                                </div>
                            </Col>
                        </Row>
                        {(this.props.application.property.purpose === st.POL_Purchase)
                            ?
                            purchaseHasPanels()
                            :
                            refinanceHasPanels()
                        }
                    </div>
                    : ""}

                {showOnlyForBorrower() ?
                    <div className="viewport mt-3">
                        <Row>
                            <Col><div className="sectionquestion">
                                Do you have additional real estate, including residential or commercial, owned by an LLC, or free and clear, or vacant land? <span style={{ fontWeight: '500' }}>All of them must be disclosed!</span>
                            </div>
                                <div className="d-flex mt-1">

                                    <div className="mr-3 ">
                                        <input required onChange={this.addOtherProperties} value={true} checked={null != this.props.application.assetsandliabilities.properties && this.props.application.assetsandliabilities.properties.length !== 0} type="radio" id="real" name="real"></input>
                                        <label className="zeitro-radio divlink" htmlFor="real">Yes, {meus} do</label></div>
                                    <div><input required onChange={this.addOtherProperties} value={false} checked={null != this.props.application.assetsandliabilities.properties && this.props.application.assetsandliabilities.properties.length === 0} type="radio" id="realnot" name="real"></input>
                                        <label className="zeitro-radio divlink" htmlFor="realnot">No, {meus} don't</label></div>
                                </div>
                            </Col>
                        </Row>
                        {null != this.props.application.assetsandliabilities.properties ?
                            this.props.application.assetsandliabilities.properties.map(this.processAdditionalRealEstate) : ""}
                    </div> : ""}


            </div>
        )
    }
}

AlimonyAndMore = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AlimonyAndMore)