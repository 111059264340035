import React from 'react';

export const GMCCDisclaimer = () => {
    return (
            <div className="loLandingPageFooter" style={{fontSize:10}}>
            <div className="mb-3">
                General Mortgage Capital Corporation  |  
                HQ: 1350 Bayshore Highway STE 740, Burlingame, CA94010  |  
                CA DRE# 01509029 NMLS #254895 Real Estate Broker  |  
                California Department of Real Estate  |  
                Tel: 650-340-7800 Email: info@gmccloan.com  |  
                GMCCLOAN.COM. JOINGMCC.COM
            </div>
            <div className="mb-3">
            © 2024 General Mortgage Capital Corporation Company and/or its affiliates. General Mortgage Capital Corporation provides home loans; General Mortgage Capital Corporation Mortgage Corporation is a direct lender. General Mortgage Capital Corporation is a family of companies. eMeta Funding; General Mortgage; GMCC; GMCC Alternative Financing Division; GMCC Investment Property Division; GMCC Private Client Group; GMCC Private Lending Group; GMCC Self-Employed Mortgage Lending; Meta Funding; Metafunding. Products not available in all states. All rights reserved.
            </div>
            <div className="mb-3">
                Interest rates and annual percentage rates (APRs) are based on current market rates, are for informational purposes only,
                are subject to change without notice and may be subject to pricing add-ons related to property type, loan amount, loan-to-value,
                credit score and other variables. This is not a credit decision or a commitment to lend. Depending on loan guidelines, mortgage
                insurance may be required. If mortgage insurance is required, the mortgage insurance premium could increase the APR and the
                monthly mortgage payment. Additional loan programs may be available. Some programs are subject to income/census tract limit
                and available only in selected counties/states. APR reflects the effective cost of your loan on a yearly basis, considering such
                items as interest, most closing costs, discount points (also referred to as “points”) and loan-origination fees. One point is 1.000%
                of the mortgage amount (e.g., $1,000 on a $100,000 loan). Your monthly payment is not based on APR, but instead on the
                interest rate on your note. Adjustable-rate mortgage (ARM) rates assume no increase in the financial index after the initial fixed
                period. ARM rates and monthly payments are subject to increase after the fixed period: ARMs assume 30-year term. General
                Mortgage Capital Corporation and eMeta Funding are licensed in the following state: Alabama – NMLS: 23260, Arkansas –
                NMLS: 129989, Arizona – NMLS: 1026178, California CFL – NMLS: 60DBO-66060, California DRE – NMLS: 01509029,
                Colorado – NMLS: 254895, Connecticut – NMLS: 254895, Delaware – NMLS: 038061, Florida – NMLS: MLD2191, Georgia –
                NMLS – 254895, Hawaii – NMLS: HI-254895, Idaho- NMLS: MBL-2080254895, Illinois – NMLS: MB.6761662, Indiana –
                NMLS: None, Iowa – NMLS: 2022-0038, Kansas- NMLS: MC.0025844, Kentucky – NMLS: MC782027, Louisiana – NMLS:
                254895, Maryland – NMLS: 254895, Maine- NMLS: 254895, Massachusetts – NMLS: MC254895, Michigan – NMLS:
                FR0024243, Minnesota – NMLS: MO-MN-254895, Mississippi – NMLS:254895, Missouri – NMLS: 254895, Montana –
                NMLS: 254895, Nevada – NMLS: 5419, Nebraska – NMLS: 254895, New Hampshire, New Jersey – NMLS: 254895, New
                Mexico: NMLS – 254895: New York – NMLS: LMB 110903, North Carolina – NMLS: L-208502, North Dakota – NMLS;
                MB104140, Oklahoma – NMLS: ML014608, Ohio – NMLS: RM.804881.000, Oregon – NMLS: 254895, Pennsylvania –
                NMLS: 96566, Rhode Island – NMLS: None, South Carolina – NMLS: 254895, Tennessee – NMLS: 254895, Texas – NMLS:
                254895, Utah – NMLS: 12679832, Vermont – NMLS: LL-254895, Virginia – NMLS: MC-7314, Washington – NMLS: CL254895, Washington DC – NMLS: MLB254895, West Virginia – NMLS: ML-254895, Wisconsin – NMLS: 254895BA,
                Wyoming – NMLS: 4763. General Mortgage Capital Corporation NMLS: 254895.
            </div>
            <div className="mb-3">Equal Housing Opportunity. All rights reserved.</div>
            <div className="mb-3">For liscensing information go to: <a className="aAsLink" target='_blank' onClick={()=> window.location.href="https://www.nmlsconsumeraccess.org/"}>https://www.nmlsconsumeraccess.org/</a></div>
            <div className="mb-3">Texas: Any consumer complaints please check: <a className="aAsLink" target='_blank' onClick={()=> window.location.href="https://www.sml.texas.gov/wp-content/uploads/2021/07/rmlo_81_200_c_recovery_fund_notice.pdf"}>https://www.sml.texas.gov/wp-content/uploads/2021/07/rmlo_81_200_c_recovery_fund_notice.pdf</a></div>
            <div className="mb-3">Any unauthorized use of any proprietary or intellectual property is strictly prohibited. All trademarks, service marks, trade names, logos, icons, and domain names are proprietary to General Mortgage Capital Corporation Company. General Mortgage Capital Corporation Company trademarks are federally registered with the U. S. Patent and Trademark Office.</div>
            <div className="d-flex text-left">
            <img src="/images/equal_housing_oppotunity.png" style={{marginRight:"10px", width:"10%", heigh:"10%"}}/>
            <img src="/images/equal_housing_lender.png" style={{marginRight:"10px", width:"10%", heigh:"10%"}}/>
            </div>
            <hr />
            <div className="mb-3 ">© 2024 General Mortgage Capital Corporation.  All rights reserved</div>

            </div>
    )
} 