export const USDollarCurrencyLocale = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
  style: "currency",
  currency: "USD",
});

export const USDollarCurrencyNoSignLocale = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
  style: "decimal",
});

/**
 * Parses a string number to a number.
 *
 * If the value is a string, it will remove the dollar sign and commas, and then convert it to a number.
 */
export const parseStringToNumber = (value: string): number => {
  let newVal = value;

  if (value.startsWith("$")) {
    newVal = value.replace("$", "");
  }
  if (value.includes(",")) {
    newVal = value.replaceAll(",", "");
  }
  return Number(newVal);
};
