import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

import StateSelector from "../StateSelector";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import NumericalInput from "../NumericalInput";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import * as com from "../Common.js";
import DateOfBirth from "../DateOfBirth";
import { connect } from "react-redux";
import * as act from "../Store/actions";
import * as st from "../State";
import { tooltip } from "../Common/Tooltip";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import throttle from "lodash.throttle";

const visas = [
  st.VT_E_1,
  st.VT_E_2,
  st.VT_E_3,
  st.VT_F_1,
  st.VT_G_1,
  st.VT_G_2,
  st.VT_G_3,
  st.VT_G_4,
  st.VT_H_1B,
  st.VT_H_4,
  st.VT_L_1,
  st.VT_L_2,
  st.VT_O_1,
  st.VT_O_2,
  st.VT_O_3,
  st.VT_P_1,
  st.VT_P_2,
  st.VT_P_3,
  st.VT_P_4,
  st.VT_TN,
  /*
    st.VT_A_1, st.VT_A_2, st.VT_A_3, st.VT_B_1, st.VT_B_2, st.VT_B_3, st.VT_C_1, st.VT_C_2, st.VT_C_3,
st.VT_C_4, st.VT_C_1D, st.VT_CP, st.VT_D_1, st.VT_D_2, st.VT_E_1, st.VT_E_2, st.VT_E_3, st.VT_F_1, st.VT_F_2, st.VT_F_3,
st.VT_G_1, st.VT_G_2, st.VT_G_3, st.VT_G_4, st.VT_G_5, st.VT_H_1A, st.VT_H_1B, st.VT_H_1B1, st.VT_H_2, st.VT_H_2A, st.VT_H_2B,
st.VT_H_3, st.VT_H_4, st.VT_I, st.VT_OR_1, st.VT_J_1, st.VT_J_2, st.VT_K_1, st.VT_K_2, st.VT_K_3, st.VT_K_4, st.VT_L_1,
st.VT_L_2, st.VT_M_1, st.VT_M_2, st.VT_M_3, st.VT_N_8, st.VT_N_9, st.VT_NACARA, st.VT_NATO_1_5, st.VT_NATO_7,
st.VT_O_1, st.VT_O_2, st.VT_O_3, st.VT_P_1, st.VT_P_2, st.VT_P_3, st.VT_P_4, st.VT_Q_1, st.VT_Q_2, st.VT_Q_3,
st.VT_R_1, st.VT_R_2, st.VT_S_5, st.VT_S_6, st.VT_S_7, st.VT_T_1, st.VT_T_2, st.VT_T_3, st.VT_T_4, st.VT_TD, st.VT_TN,
st.VT_U_1, st.VT_U_2, st.VT_U_3, st.VT_U_4, st.VT_V_1, st.VT_V_2, st.VT_V_3, */
];

const mapStateToProps = (state) => {
  return {
    hascoborrower: state.application.hascoborrower,
    cohabiting: state.application.cohabiting,
    application: state.application,
    property: state.application.property,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeBorrowerInfo: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb));
  },
  changeBorrowerInfoCheck: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb));
  },
  changePhoneInfo: (input, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(input, who, verb));
  },

  updatePreviousAddress: (event, who, verb, n) => {
    dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n));
  },
  addPreviousAddress: (event, who) => {
    dispatch(act.AddPreviousAddress(event.target.value, who));
  },
  removePreviousAddress: (event, who, n) => {
    dispatch(act.RemovePreviousAddress(event.target.value, who, n));
  },
  clearPreviousAddress: (event, who) => {
    dispatch(act.ClearPreviousAddress(event.target.value, who));
  },
  changeMainProperty: (value, verb) => {
    dispatch(act.ChangeMainProperty(value, verb));
  },
  changeMainPropertyUnits: (event) => {
    dispatch(act.ChangeMainPropertyUnits(event.target.value));
  },
  changeMainPropertyYearBuilt: (event) => {
    dispatch(act.ChangeMainPropertyYearBuilt(event.target.value));
  },
  changeMainPropertyRefinanceYearAcquired: (event) => {
    dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event));
  },
  changeMainPropertyRefinanceOriginalCost: (event) => {
    dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event));
  },
});

class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      oldaddress: "",
    };

    this.isValidated = this.isValidated.bind(this);
    this.form = React.createRef();

    if (
      this.props.who === "borrower" &&
      this.props.application.borrower.firstname === "" &&
      this.props.application.borrower.lastname === ""
    ) {
      this.getName();
    }
  }

  getName = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/auth/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": this.props.borrowerid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          window.document.dispatchEvent(new Event("reauthenticate"), "");
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text);
            window.document.dispatchEvent(new Event("reauthenticate"), "");
          } else {
            this.props.changeBorrowerInfo(
              { target: { value: js.FirstName } },
              "borrower",
              "firstname"
            );
            this.props.changeBorrowerInfo(
              { target: { value: js.LastName } },
              "borrower",
              "lastname"
            );
          }
        });
      })
      .catch((err) => {
        console.log("Fetch Error :", err);
      });
  };
  componentDidMount() {}

  componentWillUnmount() {}

  isValidated() {
    if (!this.form.current.reportValidity()) {
      this.setState({ validated: true });
      return false;
    }
    return true;
  }

  updateYears = (value) => {
    com.touch();
    if (
      value !== "" &&
      parseInt(value) < 2 &&
      0 === this.props.application[this.props.who].previousaddresses.length
    ) {
      this.props.addPreviousAddress({ target: { value: 0 } }, this.props.who);
      this.props.changeBorrowerInfo(
        { target: { value: "0" } },
        this.props.who,
        "presentaddressmonths"
      );
    }

    if (
      value !== "" &&
      parseInt(value) >= 2 &&
      0 !== this.props.application[this.props.who].previousaddresses.length
    ) {
      this.props.clearPreviousAddress({ target: { value: 0 } }, this.props.who);
    }

    this.props.changeBorrowerInfo(
      { target: { value } },
      this.props.who,
      "presentaddressyears"
    );
  };
  updateMonths = (event) => {
    com.touch();
    this.props.changeBorrowerInfo(
      event,
      this.props.who,
      "presentaddressmonths"
    );
  };

  hideOwnership() {
    if (!this.props.cohabiting && this.props.who !== com.borrower)
      // only matters if not cohabiting
      return false;

    if (
      this.props.application.property.purpose !== "purchase" &&
      this.props.application.financingsameproperty
    )
      return true;
    if (this.props.application.property.purpose === "purchase") return true;

    return false;
  }

  renderAdditional = () => {
    let changeBorrowerInfo = (who, verb) => {
      return (event) => {
        com.touch();
        this.props.changeBorrowerInfo(event, who, verb);
      };
    };

    if (this.props.who === "coborrower" && this.props.application.married)
      return "";

    let changemyhouses =
      this.props.application.property.purpose === "purchase" &&
      this.props.application[this.props.who].presentaddressownership === "own";
    let refinance =
      this.props.application.property.purpose !== "purchase" &&
      this.props.application.financingsameproperty;

    let ownedprimary = changemyhouses || refinance;

    if (!ownedprimary) return "";
    else
      return (
        <div className="">
          <Form.Row>
            {!(
              this.props.application.property.purpose !== "purchase" &&
              this.props.application.financingsameproperty
            ) && this.props.who !== "coborrower" ? (
              <Col md={3}>
                <Form.Group controlId="propvalue" className="text-left">
                  <Form.Label className="text-left text-nowrap">
                    Present Market Value:
                  </Form.Label>

                  <NumericalInput
                    isValid={false}
                    size="sm"
                    required
                    name="propvalue"
                    defaultValue={
                      this.props.application[this.props.who].marketvalue
                    }
                    onChange={changeBorrowerInfo(this.props.who, "marketvalue")}
                    min={1}
                  />

                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide the value
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ) : (
              ""
            )}

            <Col
              md={3}
              hidden={this.props.application.property.purpose === "refinance"}
            >
              {(!this.props.cohabiting && this.props.who === com.coborrower) ||
              this.props.application.property.purpose === "purchase" ? (
                <Form.Group controlId={"Status"} className="text-left">
                  <Form.Label className="text-left">
                    Property Disposition:&nbsp;
                  </Form.Label>
                  <Form.Control
                    name={"status"}
                    as="select"
                    size="sm"
                    defaultValue={this.props.application[this.props.who].status}
                    onChange={changeBorrowerInfo(this.props.who, "status")}
                  >
                    <option value="sold">Sold</option>
                    <option value="pending">Pending sale</option>
                    <option value="rental">Rental kept for income</option>
                    <option value="second">Second home</option>
                    <option value="other">Keeping in posession</option>
                  </Form.Control>
                </Form.Group>
              ) : (
                ""
              )}
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={3}></Col>
            <Col md={3}></Col>
          </Form.Row>
        </div>
      );
  };
  lookupProperty = (num, prefix, short, sfx, city, zip) => {
    let body = {
      number: num,
      prefix: prefix,
      street: short,
      suffix: sfx,
      city: city,
      zip: zip,
    };
    let token = sessionStorage.getItem("ZeitroA");

    fetch("/borrower/findproperty", {
      method: "POST",
      headers: {
        Cache: "no-cache",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "X-Borrower": this.props.borrowerid,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          window.document.dispatchEvent(new Event("reauthenticate"), "");
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (
            this.props.property.yearbuilt === "" &&
            js.Yearbuilt !== "" &&
            !this.props.property.lookupsuccessful
          ) {
            this.props.changeMainPropertyYearBuilt({
              target: { value: js.Yearbuilt },
            });
            this.props.changeMainPropertyUnits({ target: { value: js.Units } });
            this.props.changeMainProperty(true, "lookupsuccessful");

            let dt = js.Lastsale;
            let dat = dt.split("T")[0].split("-");
            let d = parseInt(dat[0]);
            this.props.changeMainPropertyRefinanceYearAcquired(d);
            this.props.changeMainPropertyRefinanceOriginalCost(
              js.Lastsaleprice
            );
          }
        });
      })
      .catch((err) => {
        console.log("Fetch Error :", err);
      });
  };
  handleSubmit = (e) => {
    if (this.props.cohabiting && this.props.who !== com.borrower) return true;

    let originalmonths =
      12 * parseInt(this.props.application[this.props.who].presentaddressyears);
    if (originalmonths >= 24) {
      this.props.clearPreviousAddress({ target: { value: 0 } }, this.props.who);
      return true;
    }

    originalmonths += parseInt(
      this.props.application[this.props.who].presentaddressmonths
    );
    if (0 === this.props.application[this.props.who].previousaddresses.length) {
      this.props.addPreviousAddress({ target: { value: 0 } }, this.props.who);
      return false;
    }

    for (
      let i = 0;
      i < this.props.application[this.props.who].previousaddresses.length;
      i++
    ) {
      let years = parseInt(
        this.props.application[this.props.who].previousaddresses[i].years
      );
      originalmonths += years * 12;
      if (years < 2)
        originalmonths += parseInt(
          this.props.application[this.props.who].previousaddresses[i].months
        );
    }
    if (originalmonths < 24) {
      this.props.addPreviousAddress({ target: { value: 0 } }, this.props.who);
      return false;
    }

    return originalmonths >= 24;
  };
  onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    let zip = com.getSubaddress(address, "postal_code", "long_name");

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    this.props.changeMainProperty(county, "county");
    this.props.changeMainProperty(longstate, "state");

    this.props.changePhoneInfo(city, this.props.who, "presentaddresscity");
    this.props.changePhoneInfo(state, this.props.who, "presentaddressstate");
    this.props.changePhoneInfo(zip, this.props.who, "presentaddresszip");
    let sfx = "";
    let short = com.getSubaddress(address, "route", "short_name");
    this.props.changeMainProperty(short, "street");
    let stradd = num + " " + short;
    this.props.changeBorrowerInfo(
      { target: { value: stradd } },
      this.props.who,
      "presentaddressstreet"
    );

    let shorts = short.split(" ");
    if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
      sfx = shorts.pop();

      if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
        sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase();

      short = shorts.join(" ");
    }
    let prefix = "";
    let s = shorts[0].toUpperCase();

    if (com.prefixes.includes(s)) {
      prefix = shorts[0];
      shorts = short.split(" ");
      short = shorts.slice(1, shorts.length).join(" ");
    }

    if (
      this.props.who === "borrower" &&
      this.props.application.property.purpose !== "purchase" &&
      this.props.application.financingsameproperty
    )
      this.lookupProperty(num, prefix, short, sfx, city, zip);
  };
  displayPreviousAddress = (address, index) => {
    let changeAddressInfo = (who, verb) => {
      return (event) =>
        this.props.updatePreviousAddress(event, who, verb, index);
    };
    let addPreviousAddress = (e) => {
      this.props.addPreviousAddress(e, this.props.who);
    };
    let removePreviousAddress = (e) => {
      this.props.removePreviousAddress(e, this.props.who, index);
    };
    let onPreviousStreetAddressCompletion = (address) => {
      let city = com.getSubaddress(address, "locality", "long_name");
      let state = com.getSubaddress(
        address,
        "administrative_area_level_1",
        "short_name"
      );
      let zip = com.getSubaddress(address, "postal_code", "long_name");
      let zips = com.getSubaddress(address, "postal_code_suffix", "short_name");
      if (zips !== "") zip = zip + "-" + zips;

      let num = com.getSubaddress(address, "street_number", "long_name");
      let short = com.getSubaddress(address, "route", "short_name");
      let stradd = num + " " + short;

      this.props.updatePreviousAddress(
        { target: { value: stradd } },
        this.props.who,
        "street",
        index
      );
      this.props.updatePreviousAddress(
        { target: { value: city } },
        this.props.who,
        "city",
        index
      );
      this.props.updatePreviousAddress(
        { target: { value: state } },
        this.props.who,
        "state",
        index
      );
      this.props.updatePreviousAddress(
        { target: { value: zip } },
        this.props.who,
        "zip",
        index
      );
    };
    return (
      <div className="viewport mt-3" key={"prev_" + index}>
        <Row>
          <Col>
            <Form.Row>
              <Col>
                <AddressAutocomplete
                  label="Previous Street Address:"
                  required={true}
                  name={"oldadressline" + index}
                  id={"prevaddr" + index}
                  defaultValue={address.street}
                  onChange={changeAddressInfo(this.props.who, "street")}
                  pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                  placeholder="No PO Box addresses are accepted"
                  goodfeedback="Looks good!"
                  badfeedback="Please provide previous street address."
                  onCompletion={onPreviousStreetAddressCompletion}
                />
              </Col>
              <Col xs="auto">
                <Form.Group
                  controlId="previouspropertytype"
                  className="text-left"
                >
                  <Form.Label className="text-left">
                    Ownership&nbsp;
                  </Form.Label>
                  <Form.Control
                    name={"propertytype" + index}
                    as="select"
                    size="sm"
                    type="text"
                    value={address.ownership}
                    onChange={changeAddressInfo(this.props.who, "ownership")}
                  >
                    <option value="own">Owned</option>
                    <option value="rent">Rented</option>
                    <option value="rentfree">Lived Rent Free</option>
                  </Form.Control>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Form.Group
                  controlId={"PreviousYears" + index}
                  className="text-left"
                >
                  <Form.Label className="text-left">Year&nbsp;</Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    required
                    id={"prevyears" + index}
                    name="oldyears"
                    type="number"
                    min={0}
                    value={address.years}
                    onChange={changeAddressInfo(this.props.who, "years")}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide years at this residence.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="auto">
                {parseInt(address.years) < 2 ? (
                  <Form.Group
                    controlId={"PreviousMonths" + index}
                    className="text-left"
                  >
                    <Form.Label className="text-left">Months:&nbsp;</Form.Label>
                    <Form.Control
                      isValid={false}
                      size="sm"
                      required={parseInt(address.years) < 2}
                      name="oldmonths"
                      type="number"
                      min={0}
                      max={11}
                      value={address.months}
                      onChange={changeAddressInfo(this.props.who, "months")}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please provide months.
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  ""
                )}
              </Col>
            </Form.Row>

            <Form.Row>
              <Col xs="auto">
                <Form.Group controlId="PrCty" className="text-left">
                  <Form.Label className="text-left">City</Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    required
                    name="previouscty"
                    type="text"
                    value={address.city}
                    onChange={changeAddressInfo(this.props.who, "city")}
                    autoComplete="off"
                    pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide the city.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Form.Group controlId="PrStte" className="text-left">
                  <Form.Label className="text-left">State</Form.Label>
                  <StateSelector
                    isValid={false}
                    size="sm"
                    required
                    id={"prevstate" + index}
                    name="previousstte"
                    autoComplete="off"
                    type="text"
                    pattern="[A-Z]{2,2}"
                    value={address.state}
                    onChange={changeAddressInfo(this.props.who, "state")}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide valid state.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Form.Group controlId="PrZp" className="text-left">
                  <Form.Label className="text-left">ZIP code</Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    required
                    name="previouszip"
                    type="text"
                    placeholder="#####"
                    value={address.zip}
                    onChange={changeAddressInfo(this.props.who, "zip")}
                    pattern="^([0-9]{5}(?:-[0-9]{4})?|([a-zA-Z][0-9]){3})$"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide the ZIP code.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Col>
          <Col xs="auto" as="div" className="text-right aligh-top px-0 mx-0">
            <i
              hidden={
                index !==
                this.props.application[this.props.who].previousaddresses
                  .length -
                  1
              }
              className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink"
              onClick={addPreviousAddress}
            ></i>
            <i
              className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
              onClick={removePreviousAddress}
            ></i>
          </Col>
        </Row>
      </div>
    );
  };
  displayPreviousAddresses = () => {
    if (
      this.props.who === com.borrower &&
      this.props.application[this.props.who].presentaddressyears !== "" &&
      parseInt(this.props.application[this.props.who].presentaddressyears) < 2
    )
      return this.props.application[this.props.who].previousaddresses.map(
        this.displayPreviousAddress
      );

    if (this.props.who === com.coborrower) {
      if (
        this.props.application.cohabiting &&
        parseInt(this.props.application.borrower.presentaddressyears) < 2
      )
        return this.props.application[this.props.who].previousaddresses.map(
          this.displayPreviousAddress
        );

      if (
        !this.props.application.cohabiting &&
        parseInt(this.props.application.coborrower.presentaddressyears) < 2
      )
        return this.props.application[this.props.who].previousaddresses.map(
          this.displayPreviousAddress
        );
    }
    return "";
  };
  render() {
    let changeBorrowerInfo = (who, verb) => {
      return (event) => {
        com.touch();
        if (event.preventDefault) {
          event.preventDefault();
        }
        return this.props.changeBorrowerInfo(event, who, verb);
      };
    };
    let onAddressChange = (e) => {
      com.touch();
      let addr = e.target.value;
      if (addr === "") {
        this.props.changeMainProperty(false, "lookupsuccessful");
      }
      return this.props.changeBorrowerInfo(
        e,
        this.props.who,
        "presentaddressstreet"
      );
    };
    let changeBorrowerState = (who, verb) => {
      return (event) => {
        com.touch();
        let state = com.convertStateToAbbr(event.target.value);
        if (event.preventDefault) event.preventDefault();
        return this.props.changePhoneInfo(state, who, verb);
      };
    };
    let throttled = throttle(this.updateYears.bind(this), 400, {
      leading: false,
      trailing: true,
    });
    let onYearsThrottled = (e) => {
      throttled(e.target.value);
    };
    let years = (
      <div>
        If you reside in your current place of residence for no more than two
        years, you need to supply your previous address.
      </div>
    );
    let onApt = (e) => {
      this.props.changeBorrowerInfo(e, this.props.who, "presentaddressunit");
    };
    return (
      <div className=" mb-4  text-left" key={this.props.who}>
        {this.props.who === com.borrower ? (
          <h2 className=" heading">
            Please tell us about yourself and your primary residence
          </h2>
        ) : (
          <h2 className=" heading">Please enter co-applicant's information</h2>
        )}
        <div className="text-center mt-2 mb-4 pt-2  ">
          <div className="viewport">
            <Form.Row>
              <Col xs="auto">
                <Form.Group controlId="Name" className="text-left">
                  <Form.Label className="text-left">First Name:</Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    required
                    name="firstname"
                    autoFocus
                    type="text"
                    autoComplete="given-name"
                    value={this.props.application[this.props.who].firstname}
                    onChange={changeBorrowerInfo(this.props.who, "firstname")}
                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide your first name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="auto" className="hideonphone">
                <Form.Group controlId="Middle" className="text-left">
                  <Form.Label className="text-left">Middle Name:</Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    name="middlename"
                    type="text"
                    autoComplete="additional-name"
                    value={this.props.application[this.props.who].middlename}
                    onChange={changeBorrowerInfo(this.props.who, "middlename")}
                    pattern="^[\w ']*$"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide your middle name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs>
                <Form.Group controlId="Last" className="text-left">
                  <Form.Label className="text-left">Last Name:</Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    required
                    name="lastname"
                    type="text"
                    autoComplete="family-name"
                    value={this.props.application[this.props.who].lastname}
                    onChange={changeBorrowerInfo(this.props.who, "lastname")}
                    pattern="^[\w\s'-]+$"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide your last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="1" className="hideonphone">
                <Form.Group controlId="Suffix" className="text-left">
                  <Form.Label className="text-left">Suffix:</Form.Label>
                  <Form.Control
                    size="sm"
                    name="suffix"
                    type="text"
                    autoComplete="honorific-suffix	"
                    value={this.props.application[this.props.who].suffix}
                    onChange={changeBorrowerInfo(this.props.who, "suffix")}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>

            {this.props.cohabiting && this.props.who !== com.borrower ? (
              ""
            ) : (
              <div>
                <Form.Row>
                  <Col xs>
                    <AddressAutocomplete
                      id="Address"
                      label="Current Street Address:"
                      required={true}
                      name="addessline"
                      defaultValue={
                        this.props.application[this.props.who]
                          .presentaddressstreet
                      }
                      onChange={onAddressChange}
                      pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                      placeholder="No PO Box addresses are accepted"
                      goodfeedback="Looks good!"
                      badfeedback="Please provide your street address."
                      onCompletion={this.onStreetAddressCompletion}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Group controlId="apt" className="text-left">
                      <Form.Label className="text-left">Apt:</Form.Label>
                      <Form.Control
                        isValid={false}
                        size="sm"
                        name="unit"
                        type="text"
                        defaultValue={
                          this.props.application[this.props.who]
                            .presentaddressunit
                        }
                        onChange={onApt}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide years at this residence.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {this.hideOwnership() ? (
                    ""
                  ) : (
                    <Col xs="auto">
                      <Form.Group
                        controlId="propertytype"
                        className="text-left"
                      >
                        <Form.Label className="text-left">
                          Ownership&nbsp;
                        </Form.Label>
                        <Form.Control
                          name="propertytype"
                          as="select"
                          size="sm"
                          type="text"
                          required
                          value={
                            this.props.application[this.props.who]
                              .presentaddressownership
                          }
                          onChange={changeBorrowerInfo(
                            this.props.who,
                            "presentaddressownership"
                          )}
                        >
                          <option value="">...</option>
                          <option value="own">Own</option>
                          <option value="rent">Rent</option>
                          <option value="rentfree">Live rent free</option>
                        </Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  )}
                  <Col xs="auto">
                    <Form.Group controlId="Years" className="text-left">
                      <Form.Label className="text-left">
                        {tooltip("Years at place", years, "auto", "")}
                      </Form.Label>
                      <Form.Control
                        isValid={false}
                        size="sm"
                        required
                        name="years"
                        type="number"
                        min={0}
                        defaultValue={
                          this.props.application[this.props.who]
                            .presentaddressyears
                        }
                        onChange={onYearsThrottled}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide years at this residence.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {this.props.application[this.props.who]
                    .presentaddressyears !== "" &&
                  parseInt(
                    this.props.application[this.props.who].presentaddressyears
                  ) < 2 ? (
                    <Col xs="auto">
                      <Form.Group controlId="Months" className="text-left">
                        <Form.Label className="text-left">
                          {tooltip("Months", years, "auto", "")}
                        </Form.Label>
                        <Form.Control
                          isValid={false}
                          size="sm"
                          required
                          name="months"
                          type="number"
                          min={0}
                          max={11}
                          defaultValue={
                            this.props.application[this.props.who]
                              .presentaddressmonths
                          }
                          onChange={this.updateMonths}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please provide months.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ) : (
                    <Col xs="2"></Col>
                  )}
                </Form.Row>

                <Form.Row>
                  <Col xs="auto">
                    <Form.Group controlId="City" className="text-left">
                      <Form.Label className="text-left">City</Form.Label>
                      <Form.Control
                        isValid={false}
                        size="sm"
                        required
                        name="city"
                        type="text"
                        autoComplete="locality"
                        value={
                          this.props.application[this.props.who]
                            .presentaddresscity
                        }
                        onChange={changeBorrowerInfo(
                          this.props.who,
                          "presentaddresscity"
                        )}
                        pattern="^[\p{L}\p{N}\s,]+$"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide the city.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Form.Group controlId="State" className="text-left">
                      <Form.Label className="text-left">State</Form.Label>
                      <StateSelector
                        isValid={false}
                        size="sm"
                        required
                        name="state"
                        autoComplete="state"
                        type="text"
                        placeholder="##"
                        value={
                          this.props.application[this.props.who]
                            .presentaddressstate
                        }
                        onChange={changeBorrowerState(
                          this.props.who,
                          "presentaddressstate"
                        )}
                        pattern="^[A-Z]{2,2}$"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide the State.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Form.Group controlId="Zip" className="text-left">
                      <Form.Label className="text-left">ZIP code</Form.Label>
                      <Form.Control
                        isValid={false}
                        size="sm"
                        required
                        name="zip"
                        type="text"
                        placeholder="#####"
                        autoComplete="postal-code"
                        value={
                          this.props.application[this.props.who]
                            .presentaddresszip
                        }
                        onChange={changeBorrowerInfo(
                          this.props.who,
                          "presentaddresszip"
                        )}
                        pattern="^([0-9]{5}(?:-[0-9]{4})?|([a-zA-Z][0-9]){3})$"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Zip code.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
            )}
            {this.renderAdditional()}
          </div>
          {this.displayPreviousAddresses()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Personal);

const mapStateToPropsMore = (state) => {
  let s = {
    hascoborrower: state.application.hascoborrower,
    cohabiting: state.application.cohabiting,
    application: state.application,
  };

  return s;
};

const mapDispatchToPropsMore = (dispatch) => ({
  changeBorrowerInfo: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb));
  },

  changePhoneInfo: (input, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(input, who, verb));
  },

  changeBorrowerCheck: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb));
  },
  updateApplicationAttribute: (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb));
  },
  updateBorrowerAttribute: (who, value, verb) => {
    dispatch(act.UpdateBorrowerAttribute(who, value, verb));
  },
});

export class Personal1 extends Component {
  constructor(props) {
    super(props);
    this.refages = React.createRef();
    this.refdependents = React.createRef();

    this.state = {
      store: this.props.application,
      areagesrequired: {},
    };
    this.isValidated = this.isValidated.bind(this);
    this.phone = "";

    if (this.props.who === "borrower") {
      let email = this.props.application.borrower.email;
      if (email === "" || this.props.application.borrower.homephone === "") {
        this.getEmail();
      }
    }
    this.expiration = React.createRef();
  }

  getEmail = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/auth/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": this.props.borrowerid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          window.document.dispatchEvent(new Event("reauthenticate"), "");
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text);
            window.document.dispatchEvent(new Event("reauthenticate"), "");
          } else {
            this.profile = js;
            js.Method = parseInt(js.Method);
            this.phone = js.Phone;

            this.props.changeBorrowerInfo(
              { target: { value: js.Email } },
              "borrower",
              "email"
            );
          }
        });
      })
      .catch((err) => {
        console.log("Fetch Error :", err);
      });
  };
  handleSubmit = (event) => {
    if (this.props.application.hascreditfreeze === true) return false;

    let exp = new Date(this.props.application[this.props.who].visaexpiration);
    let now = new Date();
    let diff = exp.getTime() - now.getTime();
    let days = diff / (24 * 60 * 60000);
    if (days < 90) {
      this.expiration.current.setCustomValidity(
        "Should be valid for more than 90 days"
      );
      return false;
    }
    if (this.props.who === "borrower" && this.phone === "") {
      // need to update the phone
      this.sendPhone();
    }
    return true;
  };
  sendPhone = () => {
    let tosend = {
      phone: this.props.application.borrower.homephone,
    };
    let token = sessionStorage.getItem("ZeitroA");

    fetch("/auth/updatephone", {
      method: "POST",
      body: JSON.stringify(tosend),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-Borrower": this.props.borrowerid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          window.document.dispatchEvent(new Event("reauthenticate"), "");
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text);
            window.document.dispatchEvent(new Event("reauthenticate"), "");
          } else {
          }
        });
      })
      .catch((err) => {
        console.log("Fetch Error :", err);
      });
  };
  componentDidMount() {}

  componentWillUnmount() {}
  askIfFHA() {
    /*
        // TODO - reenable this when Valya's done with
        return ""

        if (this.props.application.borrower.maritalstatus !== "married")
            return ""
        return (
            <div className="viewport my-3 text-wrap">
                <div className="mb-3 sectionquestion">
                    <div>You're married, but applying alone. If you'd like to consider FHA mortgages, please provide the details of your spouse.</div>

                    <div>They will only be used to pull your joint credit report. Only your income and assets will be considered. </div>
                    This choice is only available on this page at this time.
                    <div className="mt-2">
                        <a href="https://www.hud.gov/buying/loans" target="about:blank">Click to learn about FHA.</a> 
                    </div>
                </div>
                <div>
                    <input type="checkbox" id="wantfha" name="wantfha" defaultChecked={this.props.application.aloneMarriedWantsFHA}
                        onChange={(e) => {
                            this.props.updateApplicationAttribute(e.target.checked, "aloneMarriedWantsFHA")

                        }} ></input>
                    <label id="wantfhalabel" style={{ textDecoration: "underline" }} className="divlink"
                        htmlFor="wantfha">Yes, I want to see FHA loan options!</label>

                </div>
                {this.props.application.aloneMarriedWantsFHA === true ?
                    this.askDetails(com.coborrower, true)

                    : ""}
            </div>
        )
        */
  }
  isValidated() {
    if (!this.form.current.reportValidity()) {
      this.setState({ validated: true });
      return false;
    }
    return true;
  }
  updateMail = (event) => {
    com.touch();
    let val = event.target.value === "true";
    this.props.changeBorrowerCheck(
      { target: { checked: val } },
      this.props.who,
      "samemailingaddress"
    );
  };

  askDetails = (who, ask) => {
    let changePhoneInfo = (who, verb) => {
      return (event) => {
        com.touch();
        let phone = com.fixPhoneInput(event.target.value);
        return this.props.changePhoneInfo(phone, who, verb);
      };
    };

    let changeBorrowerInfo = (who, verb) => {
      return (event) => {
        com.touch();
        this.props.changeBorrowerInfo(event, who, verb);
      };
    };

    const updateSSN = (event) => {
      com.touch();
      let ssn = com.fixSSN(event.target.value);
      return this.props.changePhoneInfo(ssn, who, "socialsecurity");
    };
    let displayvisa = (value, index) => {
      return (
        <option key={"visa" + index} value={value}>
          {value}
        </option>
      );
    };
    let defDate = new Date();
    defDate.setFullYear(defDate.getFullYear() + 3);
    let changeExpiration = (event) => {
      com.touch();
      this.expiration.current.setCustomValidity("");

      let date = com.fixDateInput(event.target.value);
      return this.props.changePhoneInfo(date, this.props.who, "visaexpiration");
    };
    let massage = (visas) => {
      if (who === "coborrower") return visas;
      return visas.filter((x) => {
        if ([st.VT_H_4, st.VT_L_2, st.VT_O_3, st.VT_P_4].includes(x))
          return false;
        return true;
      });
    };
    return (
      <div className="viewport mt-3" key={"details_" + who}>
        {who === com.borrower ? (
          <div className="text-wrap">
            <div className="mb-2">
              We care about your privacy and want to make sure that nobody can
              impersonate you. This information is only sent to credit bureaus
              for your identification, and not sold to or shared with anyone
              else.{" "}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="">
          {ask ? (
            <div>
              <Form.Row>
                <Col xs={2}>
                  <Form.Group controlId="Name" className="text-left">
                    <Form.Label className="text-left">First Name:</Form.Label>
                    <Form.Control
                      isValid={false}
                      size="sm"
                      required
                      name="firstname"
                      autoFocus
                      type="text"
                      autoComplete="given-name"
                      value={this.props.application["coborrower"].firstname}
                      onChange={changeBorrowerInfo("coborrower", "firstname")}
                      pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please provide your first name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={3}>
                  <Form.Group controlId="Last" className="text-left">
                    <Form.Label className="text-left">Last Name:</Form.Label>
                    <Form.Control
                      isValid={false}
                      size="sm"
                      required
                      name="lastname"
                      type="text"
                      autoComplete="family-name"
                      value={this.props.application["coborrower"].lastname}
                      onChange={changeBorrowerInfo("coborrower", "lastname")}
                      pattern="^[\w\s'-]+$"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please provide your last name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
          ) : (
            this.props.application[who].firstname +
            " " +
            this.props.application[who].lastname
          )}
        </div>
        <Form.Row>
          <Col xs="auto">
            <Form.Group controlId="SSN" className="text-left">
              <Form.Label className="text-left">SSN:</Form.Label>
              <Form.Control
                isValid={false}
                size="sm"
                required
                name="ssn"
                placeholder="###-##-####"
                value={this.props.application[who].socialsecurity}
                onChange={updateSSN}
                pattern="^[0-9]{3,3}-[0-9]{2,2}-[0-9]{4,4}$"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide the SSN without dashes.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Form.Group controlId="Email" className="text-left">
              <Form.Label className="text-left">Email:</Form.Label>
              <Form.Control
                isValid={false}
                size="sm"
                required
                name="email"
                type="text"
                defaultValue={this.props.application[who].email}
                onChange={changeBorrowerInfo(who, "email")}
                min={0}
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Form.Group controlId="Phone" className="text-left">
              <Form.Label className="text-left  text-nowrap">
                Contact Phone:
              </Form.Label>
              <Form.Control
                isValid={false}
                size="sm"
                required
                name="phone"
                placeholder="(###)-###-####"
                type="text"
                value={this.props.application[who].homephone}
                onChange={changePhoneInfo(who, "homephone")}
                pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide the phone number.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xs="auto">
            <Form.Group controlId="citizenship" className="text-left">
              <Form.Label className="text-left">Citizenship:</Form.Label>
              <Form.Control
                required
                name="citizenship"
                as="select"
                size="sm"
                defaultValue={this.props.application[who].citizenship}
                onChange={changeBorrowerInfo(who, "citizenship")}
              >
                <option value="">...</option>
                <option value="citizen">US Citizen</option>
                <option value="permanent">US Permanent Resident</option>
                <option value="alien">Non-US Citizen</option>
              </Form.Control>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide your citizenship status.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs="auto">
            {this.props.application[who].citizenship === "alien" ? (
              <Form.Group controlId="visa" className="text-left">
                <Form.Label className="text-left">Visa:</Form.Label>
                <Form.Control
                  name="visa"
                  as="select"
                  size="sm"
                  defaultValue={this.props.application[who].visa}
                  onChange={changeBorrowerInfo(who, "visa")}
                >
                  {massage(visas).map(displayvisa)}
                </Form.Control>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please provide your citizenship.
                </Form.Control.Feedback>
              </Form.Group>
            ) : (
              ""
            )}
          </Col>
          <Col xs="auto">
            {this.props.application[who].citizenship === "alien" ? (
              <Form.Group controlId="expiration" className="text-left">
                <Form.Label className="text-left">Expires:</Form.Label>
                <DateOfBirth
                  size="sm"
                  isValid={false}
                  maxdate={defDate}
                  //ref={this.refsfrom[index]}
                  onRef={(ref) => {
                    this.expiration = ref;
                  }}
                  required
                  placeholder="MM/DD/YYYY"
                  name="expiration"
                  type="text"
                  value={this.props.application[this.props.who].visaexpiration}
                  onChange={changeExpiration}
                  negativefeedback="Visa should be valid for at least 90 days."
                  pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                />
              </Form.Group>
            ) : (
              ""
            )}
          </Col>
        </Form.Row>
        {this.props.application[who].visa === st.VT_F_1 &&
        this.props.application[who].citizenship === "alien" ? (
          <Form.Row>
            <Col className="text-danger">
              F-1 visa requires OPT and job income to qualify!
            </Col>
          </Form.Row>
        ) : (
          ""
        )}
        {this.props.application[who].visa === st.VT_H_4 ? (
          <Form.Row>
            <Col className="text-info">H-1B holder must be on loan</Col>
          </Form.Row>
        ) : (
          ""
        )}
        {this.props.application[who].visa === st.VT_O_3 ? (
          <Form.Row>
            <Col className="text-info">
              O-1 or O-2 visa holder must be on loan
            </Col>
          </Form.Row>
        ) : (
          ""
        )}
        {this.props.application[who].visa === st.VT_P_4 ? (
          <Form.Row>
            <Col className="text-info">
              P-1, P-2 or P-3 visa holder must be on loan
            </Col>
          </Form.Row>
        ) : (
          ""
        )}
      </div>
    );
  };
  showCoborrower = () => {
    if (this.props.hascoborrower === "withcoborrower") return true;
    if (
      this.props.hascoborrower === "multiple" &&
      this.props.application.married
    )
      return true;
    return false;
  };
  render() {
    let onFreeze = (val) => {
      com.touch();
      let v = val.target.value === "true";

      this.props.updateApplicationAttribute(v, "hascreditfreeze");
    };
    let showDetails = () => {
      let out = [];
      out.push(this.askDetails(com.borrower));
      if (this.props.application.hascoborrower === "alone") {
        out.push(this.askIfFHA());
      }

      if (this.showCoborrower()) {
        out.push(this.askDetails(com.coborrower));
      }
      return out;
    };
    return (
      <div className="mb-3 text-left">
        <h2 className=" heading">
          You're about to review your debts and liabilities
        </h2>

        <div className="viewport mt-3 text-wrap">
          <div className="mb-3">
            We use a soft pull to help calculate your debt and provide loan
            choices that work best for you. It's also an opportunity to verify
            that your debt is up-to-date and accurate.
            <a
              className="ml-1"
              style={{ color: "grey", fontSize: "1.0em" }}
              target="about:blank"
              href="https://www.smartlymortgage.net/blogs/whats-the-difference-between-a-soft-pull-and-a-hard-pull"
            >
              Learn more about soft pull vs. hard pull...
            </a>
          </div>

          <div className="my-3">
            <b style={{ fontSize: "1.1em", fontWeight: "800" }}>
              This will not impact your FICO score.
            </b>
          </div>
          <div className="mt-3 mb-2 sectionquestion">
            <b>Important!</b> Have you placed a credit freeze at any of the
            credit bureau such as Experian, Equifax or TransUnion?
          </div>
          <Form.Group>
            <fieldset>
              <div>
                <div>
                  <input
                    required
                    onChange={onFreeze}
                    value={false}
                    checked={this.props.application.hascreditfreeze === false}
                    type="radio"
                    id="freezenot"
                    name="freeze"
                  ></input>
                  <label className="zeitro-radio divlink" htmlFor="freezenot">
                    No, I have not
                  </label>
                </div>
                <div>
                  <input
                    required
                    onChange={onFreeze}
                    value={true}
                    checked={this.props.application.hascreditfreeze === true}
                    type="radio"
                    id="freeze"
                    name="freeze"
                  ></input>
                  <label className="zeitro-radio divlink" htmlFor="freeze">
                    Yes, I have
                  </label>
                </div>
              </div>
            </fieldset>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please specify if you have credit freeze
            </Form.Control.Feedback>
          </Form.Group>

          {this.props.application.hascreditfreeze === true ? (
            <div style={{ color: "#633" }}>
              If you have previously placed a credit freeze at Experian, Equifax
              or TransUnion, you must unfreeze all of them before proceeding to
              the next page. Each credit bureau has to be contacted
              independently.
              <br />
              <br />
              <strong>Equifax:</strong> Call 800-349-9960 or click{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://my.equifax.com/membercenter/#/login"
              >
                here
              </a>
              <br />
              <strong>Experian:</strong> Call 888‑397‑3742 or click{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.experian.com/ncaconline/removefreeze"
              >
                here
              </a>
              <br />
              <strong>TransUnion:</strong> Call 888-909-8872 or click{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://service.transunion.com/dss/login.page"
              >
                here
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
        {this.props.application.hascreditfreeze === false ? showDetails() : ""}
        {this.props.application.hascreditfreeze === false ? (
          <div className="viewport ">
            <div style={{ fontWeight: "bold" }} className="text-wrap">
              By clicking on Next you agree to Zeitro requesting your soft
              credit report.
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Personal1 = connect(mapStateToPropsMore, mapDispatchToPropsMore, null, {
  forwardRef: true,
})(Personal1);

const mapStateToPropsMore2 = (state) => {
  let s = {
    hascoborrower: state.application.hascoborrower,
    cohabiting: state.application.cohabiting,
    application: state.application,
  };

  return s;
};

const mapDispatchToPropsMore2 = (dispatch) => ({
  changeBorrowerInfo: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb));
  },

  changePhoneInfo: (input, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(input, who, verb));
  },

  changeBorrowerCheck: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb));
  },
  updateBorrowerAttribute: (who, value, verb) => {
    dispatch(act.UpdateBorrowerAttribute(who, value, verb));
  },

  updateApplicationAttribute: (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb));
  },
});

export class Personal2 extends Component {
  constructor(props) {
    super(props);
    this.refages = React.createRef();
    this.refdependents = React.createRef();

    this.state = {
      store: this.props.application,
      areagesrequired: {},
    };
    this.isValidated = this.isValidated.bind(this);

    if ("" !== this.props.application[this.props.who].numberofdependents)
      this.state.areagesrequired = { required: true };
    this.hideMailingAddress();

    let email = this.props.application.borrower.email;
    if (email === "") {
      this.getEmail();
    }
    this.dob = React.createRef();
    this.relref = React.createRef();

    if (this.props.application.status === "interviewstarted") {
      this.props.updateApplicationAttribute("productselected", "status");
    }
  }

  getEmail = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/auth/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": this.props.borrowerid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          window.document.dispatchEvent(new Event("reauthenticate"), "");
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text);
            window.document.dispatchEvent(new Event("reauthenticate"), "");
          } else {
            js.Method = parseInt(js.Method);
            this.props.changeBorrowerInfo(
              { target: { value: js.Email } },
              "borrower",
              "email"
            );
          }
        });
      })
      .catch((err) => {
        console.log("Fetch Error :", err);
      });
  };

  processDependents = (element) => {
    let val = this.refdependents.current.value;
    let num = parseInt(val);
    if (val === "" || num === 0) {
      this.setState({ areagesrequired: {} });
      this.refages.current.value = "";
      this.props.changeBorrowerInfo(
        { target: { value: "" } },
        this.props.who,
        "dependentsages"
      );
      this.refages.current.setCustomValidity("");
    } else {
      this.setState({ areagesrequired: { required: true } });
      // create a regexp
      let seed = "^[1-9][0-9]*";
      for (let i = 0; i < num - 1; i++) {
        seed += ",[1-9][0-9]*";
      }
      seed += "$";
      let r = new RegExp(seed);
      let res = element.value.match(r);
      if (null === res)
        element.setCustomValidity(
          "Please fill out comma-separated list of ages"
        );
      else element.setCustomValidity("");
    }
  };
  componentDidMount() {
    this.processDependents(this.refages.current);
  }
  componentWillUnmount() {}

  isValidated() {
    if (!this.form.current.reportValidity()) {
      this.setState({ validated: true });
      return false;
    }
    return true;
  }
  updateMail = (event) => {
    com.touch();
    let val = event.target.value === "true";
    this.props.changeBorrowerCheck(
      { target: { checked: val } },
      this.props.who,
      "samemailingaddress"
    );
  };

  updateDependents = (e) => {
    com.touch();
    let ret = this.props.changeBorrowerInfo(
      e,
      this.props.who,
      "numberofdependents"
    );
    this.processDependents(this.refages.current);
    return ret;
  };
  updateAges = (e) => {
    com.touch();
    let ret = this.props.changeBorrowerInfo(
      e,
      this.props.who,
      "dependentsages"
    );
    this.validateAges(e);
    return ret;
  };
  validateAges = (event) => {
    com.touch();
    this.processDependents(event.target);
  };
  hideMailingAddress = () => {
    return false;
  };
  onMailingAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");
    let city = com.getSubaddress(address, "locality", "long_name");
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    let zip = com.getSubaddress(address, "postal_code", "long_name");
    let zips = com.getSubaddress(address, "postal_code_suffix", "short_name");
    if (zips !== "") zip = zip + "-" + zips;

    let short = com.getSubaddress(address, "route", "short_name");
    let stradd = num + " " + short;
    this.props.changeBorrowerInfo(
      { target: { value: stradd } },
      this.props.who,
      "mailingaddressstreet"
    );

    this.props.changeBorrowerInfo(
      { target: { value: city } },
      this.props.who,
      "mailingaddresscity"
    );
    this.props.changeBorrowerInfo(
      { target: { value: state } },
      this.props.who,
      "mailingaddressstate"
    );
    this.props.changeBorrowerInfo(
      { target: { value: zip } },
      this.props.who,
      "mailingaddresszip"
    );
  };
  handleSubmit = (event) => {

    return true;
  };
  onRef = (ref) => {
    this.dob = ref;
  };
  render() {
    let changeBorrowerState = (who, verb) => {
      return (event) => {
        com.touch();
        let state = com.convertStateToAbbr(event.target.value);
        return this.props.changePhoneInfo(state, who, verb);
      };
    };

    let changeBorrowerDOB = (who, verb) => {
      return (event) => {
        com.touch();
        this.dob.current.setCustomValidity("");
        let date = com.fixDateInput(event.target.value);
        return this.props.changePhoneInfo(date, who, verb);
      };
    };
    let changeBorrowerInfo = (who, verb) => {
      return (event) => {
        com.touch();
        this.props.changeBorrowerInfo(event, who, verb);
      };
    };
    let changeRelationshipState = (who, verb) => {
      return (event) => {
        com.touch();
        this.props.updateBorrowerAttribute(who, event.target.value, verb);
      };
    };
    let relationshipDescriptionChange = (event) => {
      com.touch();

      this.props.updateBorrowerAttribute(
        this.props.who,
        event.target.value,
        "relationship"
      );
    };
    let relationshiSelect = (event) => {
      com.touch();
      this.props.updateBorrowerAttribute(
        this.props.who,
        event,
        "relationshiptype"
      );
      if (event === "other") {
        this.props.updateBorrowerAttribute(this.props.who, "", "relationship");
        this.relref.current.focus();
      } else
        this.props.updateBorrowerAttribute(
          this.props.who,
          com.relationships[event],
          "relationship"
        );
    };
    let onHasPartner = (e) => {
      this.props.updateBorrowerAttribute(
        this.props.who,
        e.target.value === "true",
        "haspartner"
      );
    };
    return (
      <div className="text-left">
        {this.props.who === com.borrower ? (
          <h2 className="heading">
            Please tell us more about{" "}
            {this.props.application.borrower.firstname}{" "}
            {this.props.application.borrower.lastname}
          </h2>
        ) : (
          <h2 className=" heading">
            Please tell us more about{" "}
            {this.props.application.coborrower.firstname}{" "}
            {this.props.application.coborrower.lastname}
          </h2>
        )}

        <div className="text-left mt-2 mb-4 pt-2 ">
          <div className="viewport" hidden={this.hideMailingAddress()}>
            <Form.Row>
              <Col>
                <div className="sectionquestion">
                  Is your mailing address same as your residence?
                </div>
                <div className="d-flex mt-1">
                  <div className="mr-3 ">
                    <input
                      onChange={this.updateMail}
                      value={true}
                      defaultChecked={
                        this.props.application[this.props.who]
                          .samemailingaddress
                      }
                      type="radio"
                      id="mailsame"
                      name="mailingsame"
                    ></input>{" "}
                    <label className="divlink" htmlFor="mailsame">
                      Yes, it is
                    </label>
                  </div>
                  <div>
                    <input
                      onChange={this.updateMail}
                      value={false}
                      defaultChecked={
                        !this.props.application[this.props.who]
                          .samemailingaddress
                      }
                      type="radio"
                      id="mailnotsame"
                      name="mailingsame"
                    ></input>{" "}
                    <label className="divlink" htmlFor="mailnotsame">
                      No, it is not
                    </label>
                  </div>
                </div>
              </Col>
            </Form.Row>
            {this.props.application[this.props.who].samemailingaddress ? (
              ""
            ) : (
              <div className="mb-2" key="qrwerqwe">
                <Form.Row>
                  <Col>
                    <AddressAutocomplete
                      size="sm"
                      required
                      id="mailaddessline"
                      name="mailaddessline"
                      pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                      defaultValue={
                        this.props.application[this.props.who]
                          .mailingaddressstreet
                      }
                      onChange={changeBorrowerInfo(
                        this.props.who,
                        "mailingaddressstreet"
                      )}
                      goodfeedback="Looks good!"
                      badfeedback="Please provide your mailing address."
                      onCompletion={this.onMailingAddressCompletion}
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs="auto">
                    <Form.Group controlId="mailingCity" className="text-left">
                      <Form.Label className="text-left">City</Form.Label>
                      <Form.Control
                        isValid={false}
                        size="sm"
                        required
                        name="mailingcity"
                        type="text"
                        autoComplete="locality"
                        value={
                          this.props.application[this.props.who]
                            .mailingaddresscity
                        }
                        onChange={changeBorrowerInfo(
                          this.props.who,
                          "mailingaddresscity"
                        )}
                        pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide city.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Form.Group controlId="mailingState" className="text-left">
                      <Form.Label className="text-left">State</Form.Label>
                      <StateSelector
                        isValid={false}
                        size="sm"
                        required
                        autoComplete="state"
                        name="mailingstate"
                        type="text"
                        value={
                          this.props.application[this.props.who]
                            .mailingaddressstate
                        }
                        onChange={changeBorrowerState(
                          this.props.who,
                          "mailingaddressstate"
                        )}
                        pattern="^[A-Z]{2,2}$"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide state.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Form.Group controlId="mailingZip" className="text-left">
                      <Form.Label className="text-left">ZIP code</Form.Label>
                      <Form.Control
                        isValid={false}
                        size="sm"
                        required
                        name="mailingzip"
                        type="text"
                        value={
                          this.props.application[this.props.who]
                            .mailingaddresszip
                        }
                        onChange={changeBorrowerInfo(
                          this.props.who,
                          "mailingaddresszip"
                        )}
                        pattern="^([0-9]{5}(?:-[0-9]{4})?|([a-zA-Z][0-9]){3})$"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid zip code.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
            )}
          </div>
          <div className="viewport mt-3">
            <Form.Row>
              <Col>
                <Form.Group controlId="dob" className="text-left ">
                  <Form.Label className="text-left  text-nowrap">
                    Date&nbsp;of&nbsp;Birth:&nbsp;MM/DD/YYYY
                  </Form.Label>

                  <DateOfBirth
                    onRef={this.onRef}
                    name="dateofbirth"
                    required
                    size="sm"
                    placeholder="MM/DD/YYYY"
                    value={this.props.application[this.props.who].dateofbirth}
                    onChange={changeBorrowerDOB(this.props.who, "dateofbirth")}
                    type="text"
                    negativefeedback="Provide date of birth (should be over 18)"
                    pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="YeardOfSchool" className="text-left">
                  <Form.Label className="text-left">
                    {tooltip(
                      "Years of School(Optional)",
                      <div>
                        Years of school should include all of your education
                        starting with the elementary school.
                      </div>,
                      "auto",
                      ""
                    )}
                  </Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    name="YeardOfSchool"
                    type="number"
                    defaultValue={
                      this.props.application[this.props.who].yearsofschool
                    }
                    onChange={changeBorrowerInfo(
                      this.props.who,
                      "yearsofschool"
                    )}
                    min={0}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/*
                            <Col>
                            <Form.Group controlId="StateId" className="text-left" >
                                <Form.Label className="text-left  text-nowrap" >Driver License/State ID:</Form.Label>
                                <Form.Control isValid={false} size="sm"
                                    required
                                    ref={this.stateid}
                                    name="stateid"
                                    type="text"
                                    defaultValue={this.props.application[this.props.who].stateid}
                                    onChange={changeStateID(this.props.who, "stateid")}

                                    pattern="^[\w]+$"
                                />
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide a valid state ID.
                                </Form.Control.Feedback>
                            </Form.Group>
                            </Col>
                            */}
            </Form.Row>
            <Form.Row>
              <Col xs="auto">
                <Form.Group controlId="numofdependents" className="text-left">
                  <Form.Label className="text-left text-nowrap">
                    {tooltip(
                      this.props.who === "borrower"
                        ? "# of dependents(Optional)"
                        : "# of dependents not listed by borrower",
                      <div>
                        Dependents are defined in the same way as on your tax
                        return. Coborrower should not list the same dependents
                        twice. <br />
                        Keep empty if no dependents
                      </div>,
                      "auto",
                      ""
                    )}
                  </Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    name="numofdependents"
                    type="number"
                    defaultValue={
                      this.props.application[this.props.who].numberofdependents
                    }
                    min={0}
                    ref={this.refdependents}
                    onChange={this.updateDependents}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide a number of dependents.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Form.Group controlId="agesofdependents" className="text-left">
                  <Form.Label className="text-left">
                    {tooltip(
                      "Their Ages(Optional)",
                      <div>
                        Comma separated list of ages. Keep empty if no
                        dependents.
                      </div>,
                      "auto",
                      ""
                    )}
                  </Form.Label>
                  <Form.Control
                    isValid={false}
                    size="sm"
                    {...this.state.areagesrequired}
                    name="agesofdependents"
                    type="text"
                    ref={this.refages}
                    defaultValue={
                      this.props.application[this.props.who].dependentsages
                    }
                    placeholder="#,##,##,..."
                    onChange={this.updateAges}
                    onBlur={this.validateAges}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide comma-separated ages.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Form.Group controlId="MaritalStatus" className="text-left">
                  <Form.Label className="text-left">
                    {tooltip(
                      "Marital Status",
                      <div>
                        If you are in a domestic partnership, single or
                        divorced, select Unmarried
                      </div>,
                      "auto",
                      "",
                      ""
                    )}
                  </Form.Label>
                  <Form.Control
                    required
                    name="maritalstatus"
                    as="select"
                    size="sm"
                    defaultValue={
                      this.props.application[this.props.who].maritalstatus
                    }
                    onChange={changeBorrowerInfo(
                      this.props.who,
                      "maritalstatus"
                    )}
                  >
                    <option required value="">
                      ...
                    </option>
                    <option value="married">Married</option>
                    <option value="separated">Separated</option>
                    <option value="unmarried">Unmarried</option>
                  </Form.Control>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please provide your marital status
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            {this.props.application[this.props.who].maritalstatus ===
            "unmarried" ? (
              <>
                <>
                  <Form.Row>
                    <Col>
                      Is there a person who's not your legal spouse but has
                      similar property rights?
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <fieldset>
                          <div className="d-flex mt-1">
                            <div className="mr-3 ">
                              <input
                                required
                                onChange={onHasPartner}
                                value="false"
                                checked={
                                  false ===
                                  this.props.application[this.props.who]
                                    .haspartner
                                }
                                type="radio"
                                id="haspartnernot"
                                name="haspartner"
                              ></input>
                              <label
                                className="zeitro-radio divlink"
                                htmlFor="haspartnernot"
                              >
                                No, there isn't
                              </label>
                            </div>
                            <div className="mr-3 ">
                              <input
                                required
                                onChange={onHasPartner}
                                value="true"
                                checked={
                                  true ===
                                  this.props.application[this.props.who]
                                    .haspartner
                                }
                                type="radio"
                                id="haspartner"
                                name="haspartner"
                              ></input>
                              <label
                                className="zeitro-radio divlink"
                                htmlFor="haspartner"
                              >
                                Yes, there is
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please specify if you're in a relationship similar to
                          legal marriage.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </>
                {this.props.application[this.props.who].haspartner === true ? (
                  <Form.Row>
                    <Col>
                      <Form.Group controlId="unmarriedtype">
                        <Form.Label className="text-left">
                          Relationship Description:
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            required
                            size="sm"
                            ref={this.relref}
                            type="text"
                            value={
                              this.props.application[this.props.who]
                                .relationship
                            }
                            readOnly={
                              this.props.application[this.props.who]
                                .relationshiptype !== "other"
                            }
                            onChange={relationshipDescriptionChange}
                            pattern="^.+$"
                          />
                          <DropdownButton
                            as={InputGroup.Append}
                            variant="dropdown"
                            title=""
                            className="state-dropdown-menu"
                            id="dropdown-relationship"
                            size="sm"
                          >
                            {" "}
                            {Object.keys(com.relationships).map((key) => {
                              return (
                                <Dropdown.Item
                                  onSelect={relationshiSelect}
                                  selected={key === "other"}
                                  key={key}
                                  eventKey={key}
                                  href="#"
                                >
                                  {com.relationships[key]}
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Please enter marital status!
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="State" className="text-left">
                        <Form.Label className="text-left">
                          State in which the relationship was formed:
                        </Form.Label>
                        <StateSelector
                          isValid={false}
                          size="sm"
                          required
                          name="state"
                          autoComplete="state"
                          type="text"
                          placeholder="##"
                          value={
                            this.props.application[this.props.who]
                              .stateofrelationship
                          }
                          onChange={changeRelationshipState(
                            this.props.who,
                            "stateofrelationship"
                          )}
                          pattern="^[A-Z]{2,2}$"
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please provide the State.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

Personal2 = connect(mapStateToPropsMore2, mapDispatchToPropsMore2, null, {
  forwardRef: true,
})(Personal2);
