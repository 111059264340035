import React, { useState, useEffect, useRef } from "react";
import { Input, Result } from "antd";
import { Form, Col, Row, InputGroup, Button, Container } from "react-bootstrap";
import { Spin, Alert, Divider, Select  } from "antd";
import * as com from "../Common"
import "./GMCCCalculator.css";
import DividerWithText from "../Common/components/DividerLineWithText";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import { CommaizedInput } from "../NumericalInput";

export default function GMCCHermesQualifier(props) {
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [units, setUnits] = useState("1");
  const [fico, setFico] = useState(700);
  const [citizenship, setCitizenship] = useState("");
  const [purpose, setPurpose] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [visa, setVisa] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState("");
  const [requiredReserve, setRequiredReserve] = useState(null)
  const [employmentOnCereditReport, setEmploymentOnCreditReport] = useState(false)
  const [haveBusinessLetter, setHaveBusinessLetter] = useState(false)
  const [haveCPALetter, setHaveCPALetter] = useState(false)
  let avatarSrc = "/images/avatar.png"
  const email = props?.aboutInfo?.email
  const phone = props?.aboutInfo?.phone

  const [incomeDoc, setIncomeDoc] = useState("")


  const [qualified, setQualified] = useState(null)
  const [unqualifiedReason, setUnqualifiedReason] = useState("")
    
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [medianIncome, setMedianIncome] = useState(null);

  const [borrowerName, setBorrowerName] = useState("")
  const [borrowerPhone, setBorrowerPhone] = useState("")
  const [borrowerEmail, setBorrowerEmail] = useState("")
  const [borrowerPreferredTimeZone, setBorrowerPreferredTimeZone] = useState("")
  const [borrowerPreferredTime, setBorrowerPreferredTime] = useState("")
  const [loadingContact, setLoadingContact] = useState(false)
  const [language, setLanguage] = useState("english")
  const [loanLimits, setLoanLimits] = useState({})
  const loName = props.loName
  const loEmail = props.loEmail
  const loNMLS = props.loNMLS
  const loPhone = props.loPhone

  const handleLanguageChange = (value) => {
    setLanguage(value)
  }

  const fetchLoanLimits = () =>
  fetch("/data/loanlimits")
    .then((res) => {
      if (!res.ok) {
        console.log(res);
        throw Error(res.statusText);
      }
      return res.json();
    })
    .then((res) => {
      setLoanLimits(res)
    })
    .catch((err) => {
      console.error(err);
    });

  useEffect(()=> {
    fetchLoanLimits()
  }, [])

  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);
    options.push(<option value=""></option>);
    keys.forEach((x) => {
      options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];
    options.push(<option value=""></option>);
    try {
        if (loanLimits[propertyState.toUpperCase()]) {
            let keys = Object.keys(loanLimits[propertyState.toUpperCase()]);
            keys.forEach((x) => {
                options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
            });
        }
    } catch (error) {
        console.log(error)
    }

    return options;
  };

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "chinese", label: "Chinese" },
  ]

  const qualifiedCounties = ["California", "Colorado", "Georgia", "Illinois", "New York", "New Jersey", "Texas", "Virginia", "Washington", "Nevada"]

  const visas = ["H1B", "O", "L", "EAD (not F1 EAD)", "Other"]

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    
    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
      );
      console.log(longstate)
    setPropertyState(longstate)

    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
  };

  const matrices = [
    [["singlefamily", "pud"], 1500000, 70, 680],
    [["singlefamily", "pud"], 2000000, 65, 680],
    [["singlefamily", "pud"], 2500000, 60, 680],
    [["condo"], 1500000, 70, 680],
    [["condo"], 2000000, 65, 680],
    [["2"], 1500000, 70, 680],
    [["2"], 2000000, 65, 680],
    [["2"], 2500000, 60, 680],
    [["3", "4"], 1500000, 70, 680],
    [["3", "4"], 2000000, 65, 680],
    [["3", "4"], 2500000, 60, 680],

  ]

  const checkMatrices = () => {
    if (purchasePrice !== "" && downPayment !== "")  {
      let dp = com.safeParseInt(downPayment)
      let pp = com.safeParseInt(purchasePrice)
      let co = com.safeParseInt(cashOutAmount)

  
      let loanAmount = purpose === "purchase" ? pp - dp : purpose === "rateTermRefinance"? dp : co+dp
      let currentLTV = purpose === "purchase" ? (pp - dp)/pp : purpose === "rateTermRefinance"? dp/pp : (co+dp)/pp
      currentLTV = currentLTV * 100
      if (purpose === "cashOutRefinance") loanAmount += com.safeParseInt(cashOutAmount)  

      if (loanAmount > 2500000) {
        setQualified(false)
        setUnqualifiedReason("The program offers a maximum loan amount up to $2,500,000.")
        return false
      }

      if (citizenship === "foreinger") {
        // if (loanAmount <=1500000 && currentLTV > 60) {
        //     setQualified(false)
        //     setUnqualifiedReason("The program offers a maximum LTV up to 60% for loan amount less than $1,500,000.")
        //     return false
        // }
        if (loanAmount <=2500000 && currentLTV > 60) {
            setQualified(false)
            setUnqualifiedReason("The program offers a maximum LTV up to 60% for loan amount less than $2,500,000.")
            return false
        }
      }

      let found = null 
      for (let m of matrices) {
        let pt = m[0]
        let maxLL = m[1]
        let maxLTV = m[2]
        let minFico = m[3]
        if (pt.includes(propertyType) && loanAmount <= maxLL && currentLTV <= maxLTV && com.safeParseInt(fico) >= minFico) {
          found = m
          console.log(found)
        }
      }
      if (found===null) {
        setQualified(false)
        setUnqualifiedReason("Based on your loan amount, FICO score and LTV, you are not eligible for this program.")
        return
      }
      setQualified(true)
      return true 
    }
    return false
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setShowError(false)
    setQualified(null)
    setUnqualifiedReason("")
    setMedianIncome(null)
    setRequiredReserve(null)

    if (visa === "Other") {
      setQualified(false)
      setUnqualifiedReason("The following Visa types are eligible: " + visas.slice(0, -1).join(", "))
      return
    }


    if (qualifiedCounties.includes(propertyState)) {
      checkMatrices()
    }
  }
  const submitContact = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setLoadingContact(true)
    fetch("/borrower/gmcc/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
        Units: units,
        From:"Universe",
        PropertyValue: purchasePrice,
        Downpayment: downPayment,
        FICO: fico === "" ? "-":fico,
        Citizenship: citizenship,
        Visa: visa==="" ? "-":visa,
        BorrowerName: borrowerName,
        BorrowerPhone: borrowerPhone,
        BorrowerEmail: borrowerEmail,
        BorrowerPreferredTimeZone: borrowerPreferredTimeZone,
        BorrowerPreferredTime: borrowerPreferredTime,

        LOID: props.loid,
      })
    })
    .then((res) => {
      if (!res.ok) {
        setLoadingContact(false)
        console.log(res);
        return
      }

      setLoadingContact(false)
      alert("Thanks for your submission. We will contact you shortly.")

    })
    .catch((err) => {
      console.error(err);
      setLoadingContact(false)
      alert("Some internal error happened. Please try again later.")
    });

  }
  const applyUrl = props.loid==="gmcc" ? "https://app.gmccloan.com/services/preapp?customerid=gmcc&loid=83ab3e1e-c696-4bd5-b8ca-91f567925b99":  "https://app.gmccloan.com/services/preapp?customerid=gmcc&loid="+props.loid

  const contactMe = <Row className="mt-5">

  <Col md={6} xs={12} className="text-left">
    <div className="callbackTitle-1 mb-2">Request a call back from your loan officer</div>
        <div className="callback-lo">
        <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{width:30}}/>Your Loan Officer</div>
        <div className="callback-loinfo">
          {props.loid !== "gmcc" && <div className="callbackTitle-4">{props.loName}</div>}
          <div className="callbackTitle-4">{props.loEmail}</div>
          {props.loid !== "gmcc" && <div className="callbackTitle-4">{props.loPhone!==null? com.fixPhoneInput(props.loPhone):""}</div>}
          <div className="callbackTitle-4">NMLS #{props.loNMLS}</div>
        </div>
      </div>


    <Form onSubmit={submitContact}>
      <Form.Group controlId="name">
        <Form.Label className="callbackTitle-2">Your name</Form.Label>
        <Form.Control type="text" placeholder="Enter your name" onChange={e=>setBorrowerName(e.target.value)} required/>
      </Form.Group>
      <Form.Group controlId="phone">
        <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
        <Form.Control type="text" placeholder="Enter your phone number" onChange={e=>setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required/>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label className="callbackTitle-2">Your email</Form.Label>
        <Form.Control type="email" placeholder="Enter your email" onChange={e=>setBorrowerEmail(e.target.value)} required/>
      </Form.Group>
      <Button variant="zeitro-primary" type="submit" className="w-100" >
        {loadingContact ? <div className="craSpin"><Spin  /></div> : "Submit"}
      </Button>
      <DividerWithText text={"Or"}/>
      <Button variant="zeitro-primary" className="w-100" onClick={()=>window.open(applyUrl, '_blank', 'noopener,noreferrer')}>
        Apply Now
      </Button>
    </Form>
  </Col>
  <Col>
      <div className='request_right  contactMeRequest'>
        <div style={{ position: 'relative' }} className='green'>
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <circle cx="28" cy="28" r="28" fill="#E3F5E3" />
          </svg>
          <div className='quote'>
            “
          </div>
        </div>
        <div className='Dream'>
          Make a Dream.

        </div>
        <div className='request_info'>
          <div className='line' />

          <div className='touch'>

            <div className='width_line' />
            <div className='touch_text'>We’ll be in touch.Shortly!</div>
          </div>
        </div>
        <div className='information '>

          <div>
          <div style={{ display: 'flex' }}> 
                              <img className='info_img' src={typeof props.basicInfo.file !== "undefined" ? props.basicInfo.file[0].url : avatarSrc}></img>
                            <div className='info_span'>
                                <div>{props.basicInfo.firstname}</div>
                                <div>
                                    {props.basicInfo.lastname}
                                </div>
                            </div>
                        </div>
            <div>{email}</div>

          </div>


          <div >
            <div>NMLS {loNMLS}</div> <div > {phone}</div>
          </div>


        </div>
      </div>
    </Col>
  </Row>

  const showAddressMessage = () => {
    if (unqualifiedReason.includes("not available in your area")) {
      return <div>
        <div>The program is only available in the following areas:</div>
          <div>{qualifiedCounties.join(", ")}</div>
      </div>
    }
  }

  const displayedLoanAmount = () => {
    if (purchasePrice === "" || downPayment === "") return ""
    let dp = com.safeParseInt(downPayment)
    let pp = com.safeParseInt(purchasePrice)

    let loanAmount = purpose === "purchase" ? pp - dp : dp
    if (purpose === "cashOutRefinance") loanAmount += com.safeParseInt(cashOutAmount)

    return <Row className="blueTips bold mb-2">
    <Col>
      <div>Your loan amount is ${com.commaize(loanAmount)}</div>
    </Col>
    </Row>
  }

  const displayFlyer = () => {

    if (language === "english") return <img src="/images/gmccflyers/GMCC_HermesFlyer_zeitro.png" className="w-100"/>
    if (language === "chinese") return <img src="/images/gmccflyers/GMCC_HermesFlyer_zeitroChinese.png" className="w-100"/>
  }

  const displayConditions = () => {
    let conditions
    switch (incomeDoc) {
        case "wageEarnerWVOE":
            conditions =  <ul>
                <li>WVOE (Fannie Mae form) signed by HR, Accounting or owner. Income should be inline with position.</li>
                <li>Requires two years of work history at the same place of employment or in a similar field.</li>
                <li>If employment info is NOT on credit report, need LOE.</li>
            </ul>
            break;
        case "wageEarnerVVOE":
            conditions =  <ul>
                <li>Employment info must be on credit report.</li>
                <li>You need to have six times your monthly income in the bank to validate the income stated on form 1003. Joint accounts with non-family members are not allowed.</li>
            </ul>
            break;
        case "selfEmployedPL":
            conditions =  <ul>
                <li>P&L can be prepared by tax preparer (CPA, EA, CTEC, PTIN) or by borrower. </li>
                <li>You need to have 2 Years of current business license.</li>
                <li>At least a two-year history of self-employment is required. </li>
            </ul>
            break;
        case "selfEmployedVVOE":
            conditions =  <ul>
                <li>Employment MUST be on credit report。</li>
                <li>The program requires tax preparer letter (on formal letter head) confirming (CPA, EA, CTEC, PTIN), or 2 Years of current business license.</li>
            </ul>
            break;
        default:
            break;
    }
    return <div className="text-left">
        <div>There are additional qualifications required for this program:</div>
        {conditions}
        <div>Additional restriction required and subject to underwriting approval. Please contact your GMCC agent for more details.</div>
    </div>
  }

  return (
    <>
    <Row className="qualifierWrap">
      <Col md={6} className="text-center">
        <div className="d-flex align-items-center mt-3" style={{justifyContent:"center", marginBottom:"10px"}}>
          <div className="mr-2">Change language to</div>
          <Select defaultValue="english" onChange={handleLanguageChange} options={languageOptions}/>
        </div>
        {displayFlyer()}

      </Col>
      <Col md={6} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start"}}>
          <div className="title-3 text-center mt-3 mb-3">GMCC - Hermes</div>
          <div  className="programHighlights">
            <div className="bold"><img src="/images/bulb.svg" style={{width:28}} /> Program highlights</div>
            <ul>
              <li>Alternative Doc – WVOE or VVOE for salaried borrower, borrower prepared P&L for self-employed </li>
              <li>100% gift funds acceptable</li>
              <li>No tax documents needed</li>
              <li>Loan amount up to $2.5M</li>
              <li>For foreign nationals, 40% down required</li>
              <li>Applicable to eight states: CA, CO, GA, IL, NV, NJ, TX, VA and WA</li>
            </ul>
          </div> 

        <div className="hfaSubTitle text-center mb-4 ">
        Check your eligibility for special incentive programs with attractive rates and start your homeownership journey!
        </div>


        <div className="qualifierFormWrap">   
          <Form onSubmit={handleSubmit} className="qualifierForm">
          <Row>
            <Col  md={6} >
              <AddressAutocomplete
              id={"voeaddress"}
              label="Property address"
              required={true}
              name="addessline"
              defaultValue={displayedAddress}
              onChange={(e) => setDisplayedAddress(e.target.value)}
              pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
              placeholder="Please type your home address and select from the dropdown addresses"
              goodfeedback="Looks good!"
              badfeedback="Please provide your street address."
              onCompletion={e=>onStreetAddressCompletion(e)}
              size="md"
              />
            </Col>
            <Col md={6}>
              <Form.Group controlId="purpose" className="">
                <Form.Label className="text-left">Property type</Form.Label>
                <Form.Control
                    required
                    name="propertyType"
                    as="select"
                    size="md"
                    defaultValue={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="singlefamily">Single family</option>
                    <option value="condo">Condo</option>
                    <option value="pud">PUD</option>
                    <option value="2">2 units property</option>
                    <option value="3">3 units property</option>
                    <option value="4">4 units property</option>
                </Form.Control>
                </Form.Group>
            </Col>
          </Row>
          <Row className=" ">
              <Col xs={6}>
                  <Form.Label className="text-left">Property state</Form.Label>
                  <Form.Group >
                      <Form.Control
                      required
                      size="sm"
                      as="select"
                      placeholder="State"
                      onChange={(e) => {
                          setPropertyState(e.target.value, 'borrower');
                      }}
                      value={propertyState}
                      >
                      {getStates()}
                      </Form.Control>
                  </Form.Group>
              </Col>
              <Col xs={6}>
                  <Form.Group >
                  <Form.Label className="text-left">Property county</Form.Label>
                      <Form.Control
                      required
                      size="sm"
                      as="select"
                      onChange={(e) => {
                          setPropertyCounty(e.target.value, 'borrower');
                      }}
                      value={propertyCounty}
                      >
                      {getCounties()}
                      </Form.Control>
                  </Form.Group>
              </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="purpose" className="">
                <Form.Label className="text-left">Loan purpose</Form.Label>
                <Form.Control
                    required
                    name="purpose"
                    as="select"
                    size="md"
                    defaultValue={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="purchase">Purchase</option>
                    <option value="rateTermRefinance">Rate/Term refinance</option>
                    <option value="cashOutRefinance">Cash out refinance</option>
                </Form.Control>
                </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="purpose" className="">
                <Form.Label className="text-left">Occupancy</Form.Label>
                <Form.Control
                    required
                    name="occupancy"
                    as="select"
                    size="md"
                    defaultValue={occupancy}
                    onChange={(e) => setOccupancy(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="primary">Primary</option>
                    <option value="secondHome">Second home</option>
                    <option value="investment">Investment(rental)</option>
                </Form.Control>
                </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col  md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
              <Form.Group>
                <Form.Label>Property price</Form.Label>
                <CommaizedInput value={purchasePrice} onChange={e=>setPurchasePrice(e.target.value)} withDollarSign/>
              </Form.Group>
            </Col>
            <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
              <Form.Group>
                <Form.Label>{purpose === "purchase"? "Down payment": "Current balance"}</Form.Label>
                <CommaizedInput className="gmccCalculatorInput" value={downPayment} onChange={e=>setDownPayment(e.target.value)} withDollarSign/>
              </Form.Group>
            </Col>
            {purpose === "cashOutRefinance" && <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Cash out amount</Form.Label>
                <CommaizedInput className="gmccCalculatorInput" value={cashOutAmount} onChange={e=>setCashOutAmount(e.target.value)} withDollarSign/>
              </Form.Group>
            </Col>
            }
          </Row>
          {displayedLoanAmount()}
          <Row className="">
            <Col md={citizenship ==="foreigner"? 12 : 6} >
                <Form.Group controlId="citizenship" className="">
                <Form.Label className="text-left">Citizenship</Form.Label>
                <Form.Control
                    name="citizenship"
                    as="select"
                    size="md"
                    defaultValue={citizenship}
                    onChange={(e) => setCitizenship(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="citizen">US Citizen</option>
                    <option value="permanent">Permanent Resident</option>
                    <option value="alien">Non Permanent Resident</option>
                    <option value="foreigner">Foreign Nationals</option>
                </Form.Control>
                </Form.Group>
            </Col>

            { citizenship !== "alien" && citizenship !== "foreigner"  ? <Col md={6}>
              <Form.Group>
                <Form.Label>FICO </Form.Label>
                <Form.Control type="number" placeholder="" onChange={e=>setFico(e.target.value)} />
              </Form.Group>
            </Col> : citizenship !== "foreigner" ? <Col md={6} >
                <Form.Group controlId="visa">
                    <Form.Label className="text-left">Visa</Form.Label>
                    <Form.Control
                    name="visa"
                    as="select"
                    size="md"
                    defaultValue={visa}
                    onChange={(e) => setVisa(e.target.value)}
                    >
                      {visas.map((v, i) => {
                        return <option key={i} value={v}>{v}</option>
                      }
                      )}
                    </Form.Control>
                </Form.Group>
            </Col> : null
            }
          </Row>

          <Row className="">
            <Col md={12} >
                <Form.Group controlId="incomeDoc" className="">
                <Form.Label className="text-left">Income documentation type</Form.Label>
                <Form.Control
                    name="incomeDoc"
                    as="select"
                    size="md"
                    defaultValue={incomeDoc}
                    onChange={(e) => setIncomeDoc(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="wageEarnerWVOE">Wage earner - WVOE</option>
                    {citizenship !== "foreigner" && <option value="wageEarnerVVOE">Wage earner - VVOE</option>}
                    {citizenship !== "foreigner" &&<option value="selfEmployedPL">Self-employed - P & L</option>}
                    {citizenship !== "foreigner" &&<option value="selfEmployedVVOE">Self-employed - VVOE</option>}
                </Form.Control>
                </Form.Group>
            </Col>
          </Row>

          <Row className="ml-2">
            <Col className="text-center" >
              <Button type="submit" variant="zeitro-primary">{loading? <div className="gmccSpin"><Spin  /></div>: "Check my eligibility"}</Button>
            </Col>
          </Row>

          </Form>
          
          {!loading && showError && <div className="mx-2 mt-3">
            <Alert message="Sorry, we're unable to process the information provided. Please verify your address and select an option from the dropdown list." type="warning" showIcon/>
            </div>}
          {!loading && qualified ===true &&   <Result
              status="success"
              title="You may be qualified for the program!"
              subTitle={displayConditions()}
          />}
          {!loading && qualified === false &&   <Result
              status="warning"
              title="You're not qualified for the program."
              subTitle={<div>
                <div>{unqualifiedReason}</div>
                {showAddressMessage()}
                <div>You might be qualified for other programs, please contact the loan officer for more information.</div>
              </div>}
          />}
          <Divider />
          <Row className="qualifierDisclaimerWrap">
            <div className="disclaimerContainer">
              <div className="title-6 mb-2">
                Disclaimer:
              </div>
              {props.type === "celebrityLargerAssets" && <div className="title-6 mb-2">
                The Debt-to-Asset qualifier will require 1.2 – 2 times more assets, as there is no income qualification. Contact a GMCC Loan Officer for more details.
              </div>}
              <div className="title-6">
                All loans are subject to credit approval. Interest rates are subject to change daily and without notice. Current interest rates shown our indicative of market conditions and individual qualifications and will vary upon your lock-in period, loan type, credit score, purpose and loan to value.
              </div>
            </div>
          </Row>
        </div>
      </Col>
    </Row>
    <Divider />
    <div className="mb-5">
        {contactMe}  
      </div>
    </>
  );
};