import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { Link } from "react-router-dom";
import {  Dropdown } from 'antd';
import { Redirect, withRouter } from "react-router-dom";
import "./Sidebar.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { connect } from "react-redux";
import { getCustomerId } from "../Common";
import { checkAccess, RBAC } from "../Auth";
import { UR_LoanOfficer, UR_LoanOfficerManager, UR_LoanProcessor, UR_Owner, UR_PriceEngineUser } from "../State";
const mapStateToProps = (state) => {
  return {
    borrower: state.borrower,
  };
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "",
      current: [],
      old: [],
      showList: true,
      customerSearch: "",
      oldCustomersChecked: false,
    };
    this.currentBorrowers = [];
    this.oldBorrowers = [];
  }


  render() {
    let siderbarItem = (link, imgName, text, putEnd) => {

      let selected = false
      if (window.location.href.includes(link)) {
        selected = true
      }
      let st = putEnd? {marginTop:"auto"}: {}
      return <Link to={link} style={st}>
              <Col className='d-flex flex-column align-items-center justify-content-center' >
                  <div className={selected ? "mainSiderbarItemSelected" : "mainSiderbarItem"}>
                    <img src={"/images/dashboard-" + imgName +".svg"} 
                    className= "mainSiderbarImg"/>
                   </div>
                    <span className="mainSidebarText">{putEnd ? "" : text}</span>

              </Col>
            </Link>
    }

    let siderbarMoreItem = () => {
      const items = [
        {
          key: '1',
          label: (
            <Link to="/app/affordability" className="d-flex">
              <img src="/images/dashboard-affordability.svg" className= "mainSiderbarDropdownImg"/>
              <div>Affordability check</div>
            </Link>
          ),
        },
        // {
        //   key: '2',
        //   label: (
        //     <Link to="/app/landingpage" className="d-flex">
        //       <img src="/images/dashboard-landing.svg" className= "mainSiderbarDropdownImg"/>
        //       <div>My Landing page</div>
        //     </Link>
        //   ),
        // },
        // {
        //   key: '3',
        //   label: (
        //     <Link to="/app/distribution" className="d-flex">
        //       <img src="/images/dashboard-leads.svg" className= "mainSiderbarDropdownImg"/>
        //       <div>Leads</div>
        //     </Link>
        //   ),
        // },
        {
          key: '4',
          label: (
            <Link to="/app/profile" className="d-flex">
              <img src="/images/dashboard-settings.svg" className= "mainSiderbarDropdownImg"/>
              <div>Settings</div>
            </Link>
          ),
        },
        {
          type: 'divider',
          },
        {
          key: '5',
          label: (
            <Link to="/app/logout">
              Log out
            </Link>
          ),
        },
      ];
      return <a>
      <Col className='d-flex flex-column align-items-center justify-content-center' >
              <div className={ "mainSiderbarItem"}>
              <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <img src="/images/dashboard-more.svg" className= "mainSiderbarImg"/>
                  </Dropdown>
               </div>
               <span className="mainSidebarText">More</span>
        </Col>
        </a>
    }

    if (checkAccess(UR_LoanProcessor)) {
      return (
        <div id="mainSidebar" className="sidenav h-100">
  
            {siderbarItem("/app/borrowers", "borrowers", "Loans") }
            {siderbarItem("/app/purchasecalculator", "rates", "Rates")}
            {siderbarItem("/app/mortgagegpt", "mortgagegpt", "GuidelineGPT")}
            {siderbarMoreItem()}
            {/* {siderbarItem("/app/logout", "logout", "Logout", true)} */}
        </div>
      );
    }

    return (
      <div id="mainSidebar" className="sidenav h-100">

          {siderbarItem("/app/welcome", "home", "Home")}
          {/* {siderbarItem("/app/loans", "loans", "Loans")} */}
          {/* {siderbarItem("/app/prospect-loans", "prospect-loans", "Prospect Loans")}
          {siderbarItem("/app/active-loans", "active-loans", "Active Loans")}
          {siderbarItem("/app/funded-loans", "funded-loans", "Funded Loans")} */}
          {siderbarItem("/app/billing", "billing", "Billing")}
          {siderbarItem("/app/loans", "loans", "Loans")}
          {siderbarItem("/app/borrowers", "borrowers", "Borrowers")}
          {siderbarItem("/app/purchasecalculator", "rates", "Rates")}
          {getCustomerId()==="gmcc" && siderbarItem("/app/gmccuniverserates", "gmccuniverserates", "Universe")}
          {getCustomerId()==="gmcc" && siderbarItem("/app/gmcccelebrityrates", "rates", "Celebrity")}
          {siderbarItem("/app/mortgagegpt", "mortgagegpt", "GuidelineGPT")}
          {siderbarItem("/app/landingpage", "landing", "My Site")}
          {checkAccess([UR_LoanOfficerManager, UR_Owner]) && siderbarItem("/app/management", "management", "Management")}
          {siderbarMoreItem()}
          {/* {siderbarItem("/app/logout", "logout", "Logout", true)} */}

      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Sidebar));
