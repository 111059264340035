import React, { Component } from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

export default class Multiselect extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef()
        this.state = {
            value: props.defaultValue,

        }
    }
    onChange = (e) => {
        let selectedValues = []
        if(typeof this.props.radio !== "undefined" ) {

            if(e.target.checked) {                   
                for(let i = 0; i < this.props.options.length; i++) {
                    if (this.props.options[i].id === e.target.id) {
                        selectedValues.push(this.props.options[i])
                    }
                }
            }
        } else {

            if(e.target.checked) {
                selectedValues = this.props.selectedValues
                for(let i = 0; i < this.props.options.length; i++) {
                    if (this.props.options[i].id === e.target.id) {
                        selectedValues.push(this.props.options[i])
                    }
                }
            } else {
                for(let i = 0; i < this.props.selectedValues.length; i++) {
                    if (this.props.selectedValues[i].id !== e.target.id) {
                        selectedValues.push(this.props.selectedValues[i])
                    }                  
                }
            }

        }

        this.props.onSelect(selectedValues)
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps) {

    } 

    display = (opt, index) => {
        let isChecked = () => {
       
            let id = this.props.options[index].id
            let checked = false
            for(let i = 0; i < this.props.selectedValues.length; i++) {
                if (this.props.selectedValues[i].id === id)
                    checked = true
            }
            return checked
        }

        let typ="checkbox"
        let name=opt.id
        let callback = this.onChange
        if(typeof this.props.radio !== "undefined" ) {
            typ="radio"
            name=this.props.name       
        }

        let humanname = opt.name
        if(this.props.optionsmap != null && this.props.optionsmap[humanname])
            humanname = this.props.optionsmap[humanname]
        return <Row key={this.props.name + index.toString()}>
            <Col>
            <input type={typ} id={opt.id} name={name} 
                required = {this.props.required}
                checked={isChecked()} onChange={callback} ></input>
                <label className="ml-1 divlink" 
                htmlFor={opt.id}>{humanname}</label>
            </Col>
        </Row>
    }

    render = () => {
        return (
            <div>
                {this.props.options.map(this.display)}
            </div>

        )
    }
}