import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import * as com from "../Common"
import * as act from "../Store/actions"
import { getTotalExpenses } from './Expenses'
import { skipLoan } from './AlimonySolar'
var parser = require('parse-address');

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateApplicationAttribute: (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb))
  },
  updateSelectedLoanAttribute: (t, verb) => {
    dispatch(act.UpdateSelectedLoanAttribute(t, verb))
  },

});

export function countMortgagesToRefinance(purpose, loans, otherliens) {
  if (purpose === "purchase")
    return 0
  let counter = 0
  loans.forEach(l => {
    if (skipLoan(l))
      return
    if (l.forsubjectproperty && l.satisfiedupon === "withproceeds") {
      counter += 1
    }
  })
  if (otherliens != null) {
    otherliens.forEach(l => {
      if (l.satisfiedupon === "withproceeds") {
        counter += 1
      }
    })
  }
  return counter
}
export default class LiabilitiesReview extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.totalExpenses = getTotalExpenses.bind(this)
  }

  ifPrimaryResidenceNotCounted = () => {
    if (this.props.application.borrower.presentaddressownership !== "own")
      return false

    let compare = (a, b) => {
      return a.city.toUpperCase() === b.city.toUpperCase() &&
        a.street.toUpperCase() === b.street.toUpperCase() &&
        a.number.toUpperCase() === b.number.toUpperCase() &&
        a.state.toUpperCase() === b.state.toUpperCase()
    }
    let addr = com.formatAddress(this.props.application.borrower)

    let primarycounted = false
    let primary = parser.parseLocation(addr)
    let loans = this.props.application.assetsandliabilities.propertiesfrompull
    for (let i = 0; i < loans.length; i++) {
      let loan = loans[i]
      let la = parser.parseLocation(loan.address)
      if (compare(la, primary)) {
        primarycounted = true
        break
      }
    }
    if (!primarycounted) {
      let properties = this.props.application.assetsandliabilities.properties
      for (let i = 0; i < properties.length; i++) {
        let loan = properties[i]
        let la = parser.parseLocation(loan.address)
        if (compare(la, primary)) {
          primarycounted = true
          break
        }
      }
    }
    return !primarycounted
  }

  showButton = (s) => {
    if (this.props.borrowerid != null) return ""
    return s
  }
  render() {
    let total = null
    let monthlypayment = 0
    let rate = null
    let remainingbalance = 0
    let totalloans = 0
    let totalhelocs = 0
    let totalhelocbalance = 0
    let totalhelocpayment = 0

    let calculateMortgage = () => {
      if (null == total) {
        total = 0
        let loans = this.props.application.assetsandliabilities.loans
        for (let i = 0; i < loans.length; i++) {
          let loan = loans[i]
          if (loan.correction !== "none") continue
          if (!(loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
            loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
            loan.creditloantype.toLowerCase().indexOf("realestate") >= 0)) continue
          if (loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0) {
            totalhelocs += 1
            totalhelocbalance += parseFloat(loan.remainingbalance)
            totalhelocpayment += parseFloat(loan.monthlypayment)
            continue
          }
          if (rate == null)
            rate = loan.rate
          remainingbalance += parseFloat(loan.remainingbalance)
          totalloans += 1
          monthlypayment += parseFloat(loan.monthlypayment)
        }
        // now go over manual loans
        loans = this.props.application.assetsandliabilities.properties
        for (let i = 0; loans != null && i < loans.length; i++) {
          let loan = loans[i]
          if (loan.ownedfreeandclear === true) continue


          remainingbalance += parseFloat(loan.liens)
          totalloans += 1
          monthlypayment += parseFloat(loan.payment)
        }
        loans = this.props.application.otherliens
        for (let i = 0; loans != null && i < loans.length; i++) {
          let loan = loans[i]
          if (loan.balance === "")
            continue

          remainingbalance += parseFloat(loan.balance)
          totalloans += 1
          monthlypayment += parseFloat(loan.monthly)
        }
      }
    }
    calculateMortgage()
    let heloccount = () => {
      return totalhelocs
    }
    let helocbalance = () => {
      return totalhelocbalance
    }
    let helocpayment = () => {
      return totalhelocpayment
    }
    let getRate = () => {
      return rate
    }
    let totalMortgagePayments = () => {
      return monthlypayment
    }
    let countMortgages = () => {
      return totalloans
    }
    let mortgageBalance = () => {

      if (remainingbalance === "")
        remainingbalance = 0
      return remainingbalance
    }
    let countProperties = () => {
      let count = 0
      if (this.props.application.property.purpose !== "purchase") {
        if (this.props.application.property.purpose === "cashoutrefinance" && this.props.application.property.ownedfreeandclear)
          count = 1
      } else {
        if (this.props.application.borrower.presentaddressownership === "own" && this.props.application.property.ownedfreeandclear)
          count = 1
      }
      let properties = this.props.application.assetsandliabilities.properties
      for (let i = 0; properties != null && i < properties.length; i++) {
        count += 1
      }

      properties = this.props.application.assetsandliabilities.propertiesfrompull
      for (let i = 0; properties != null && i < properties.length; i++) {
        count += 1
      }
      if (this.props.application.property.purpose === "purchase" && this.ifPrimaryResidenceNotCounted()) {
        count += 1
      }
      return count
    }
    let countFinanced = () => {
      let count = 0

      let properties = this.props.application.assetsandliabilities.properties
      for (let i = 0; properties != null && i < properties.length; i++) {
        if (properties[i].ownedfreeandclear === true)
          continue
        console.log(properties[i].address, " financed")
        count += 1
      }

      properties = this.props.application.assetsandliabilities.propertiesfrompull
      for (let i = 0; properties != null && i < properties.length; i++) {
        if (properties[i].ownedfreeandclear === true)
          continue
        count += 1
        console.log(properties[i].address, " financed")
      }
      /* should not count? These are newly applied loans and
            let other = this.props.application.otherliens
            for (let i = 0; other != null && i < other.length; i++) {
              let loan = other[i]
              if(loan.balance === "")
                continue
              count += 1
              console.log(other[i].address, " financed")
            }
            */
      return count
    }
    let countFullyOwned = () => {
      return countProperties() - countFinanced()
    }
    let balanceToRefinance = () => {
      return this.props.application.loanbalance
    }
    let mopoToRefinance = () => {
      return com.getMonthlyValue(this.props.application.borrower.expenses.currentfirstmortgage,
        this.props.application.borrower.expenses.currentfirstmortgageperiod)
    }
    let showForbearance = () => {
      let loans = this.props.application.assetsandliabilities.loans
      let hasforbearance = false
      let fbloan
      for (let i = 0; i < loans.length; i++) {
        let loan = loans[i]
        if (loan.comment.toLowerCase().indexOf("forbearance") >= 0) {
          hasforbearance = true
          fbloan = loan
          break
        }
      }
      if (!hasforbearance)
        return ""
      return (
        <div className="viewport mt-4" >
          <div className="text-left sectionquestion" style={{ color: '#993333', fontSize: '1.3em' }}>You have an account in forbearance! You can't proceed with the loan unless forbearance is taken care of.</div>
          <div>
            {fbloan.name}, account {fbloan.accountnumber}, {fbloan.comment}
          </div>
          <div>
            <Button href="/app/contact" variant="link">Please contact your loan officer for assistance.</Button>
          </div>
        </div>)
    }
    let reficount = countMortgagesToRefinance(this.props.application.property.purpose,
      this.props.application.assetsandliabilities.loans, this.props.application.otherliens)
    return (
      <div className="mb-5  text-left">
        <div className="text-left mt-2 mb-4 pt-2 ">
          <h2 className=" heading">Please Review Your Liabilities and Expenses </h2>
          {showForbearance()}
          <div className="viewport" >
            <div className="text-left sectionquestion">Expenses:</div>
            {(mortgageBalance() === 0 || totalMortgagePayments() === 0) ? <div>You have no mortgages</div> :
              <div>
                Your <b>monthly mortgage payments</b> are <b>${com.commaizeFloat(totalMortgagePayments())}</b> for {countMortgages()} mortgage loan{countMortgages() > 1 ? "s" : ""} with a <b>total
                  balance of ${com.commaizeFloat(mortgageBalance())}</b> 
              </div>
            }

            <div>
              {heloccount() > 0 ?
                <div>
                  You have {heloccount()} HELOC{heloccount() > 1 ? "s" : ""} for ${com.commaizeFloat(helocbalance())} with monthly payment of ${com.commaizeFloat(helocpayment())}  
                </div>
                :
                ""}
              <div>
                Your <b>primary residence</b> costs you <b>${com.commaizeFloat(this.totalExpenses("borrower"))} monthly</b> 
              </div>

            </div>
          </div>
          {countProperties() > 0 ?
            <div className="viewport mt-4" >
              <div className="text-left sectionquestion">Properties:</div>
              <div>
                Your currently <b>own {countProperties()} properties</b> 
              </div>
              <div>
                <b>{countFinanced()} of them financed</b> and <b>{countFullyOwned()} owned free and clear</b>
              </div>
            </div> : ""}


          {this.props.application.property.purpose !== "purchase" ?
            <div className="viewport mt-4" >
              {reficount === 1 ?
                <div className="text-left sectionquestion">The loan you're refinancing:</div>
                : <div className="text-left sectionquestion">The {reficount} loans you're refinancing:</div>
              }
              <div>
                The balance of the {reficount === 1 ? "loan" : "loans"} is <b>${com.commaizeFloat(balanceToRefinance())}</b>, with the <b>monthly mortgage payment of ${com.commaizeFloat(mopoToRefinance())}</b> at the <b>rate {getRate()}%</b>
              </div>
            </div> :

            this.props.application.property.hascontract ?
              <div className="viewport mt-4" >
                <div className="text-left sectionquestion">Your loan:</div>
                <div>
                  You're financing a purchase transaction with the amount of ${com.commaizeFloat(this.props.application.property.salesprice)}
                </div>
              </div>

              :
              <div className="viewport mt-4" >
                <div className="text-left sectionquestion">Your preapproval:</div>
                <div>
                  You're applying for preapproval for up to <b>${com.commaizeFloat(this.props.application.property.appraisal)}</b>
                </div>
              </div>


          }


        </div>


      </div>
    )
  }
}
LiabilitiesReview = connect(mapStateToProps, mapDispatchToProps, null)(LiabilitiesReview)
