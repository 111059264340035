import React, { useState, useEffect } from 'react';
import Company from './Company'
import { Form, Button, Container } from "react-bootstrap";
import "./mobile.css";

export default function Mobile({ showCompanyInfo, basicInfo, aboutInfo, customerid, loid, appurl, contact }) {
    const dynamicExternalLink = (href) => {
        if (!/^https?:\/\//i.test(href)) {
            return `https://${href}`
        }
        return href
    }
    const [checked, setChecked] = useState(false);
    const [showContactmeModal, setShowContactmeModal] = useState(false);
    const getPreQualificationLink = () => {

        if (window.location.host.includes("zeitro.us")) {
            return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        if (window.location.host.includes("localhost")) {
            return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
    }
  
    const contactMe = () => {
        contact()
    }
    let avatarSrc = "/images/avatar.png"
   
    return (
        <div className='mobile_info_wrap'>
            <div className="mobile_info">
                {/* <div id="google_translate_element"></div> */}
                {/* 
                    <div className='switch' onClick={() => {
                        setChecked(!checked)
                    }

                    }>
                        <div className={checked ? 'text checked' : "text"}>中</div>
                        <div className={!checked ? 'text checked' : "text"}>EN</div>
                    </div> */}

                <div style={{ position: 'relative', textAlign: "center" }}>
                    <img className="mobile_avatar mb-4" src={typeof basicInfo.file !== "undefined" && basicInfo.file.length > 0 ? basicInfo.file[0].url : avatarSrc}></img>
                    <div>
                        {basicInfo.nmls && <div className='mobile-introduction-headings'>Verified Loan officer (NMLS ID: {basicInfo.nmls})</div>}
                        <div className='mobile-landing-name'>{`${basicInfo.firstname} ${basicInfo.lastname}`}</div>
                        <div className='mobile-landing-mainText'>{`License State: ${basicInfo.states}`}</div>
                    </div>
                </div>
                <div className='mobile-app-list'>
                    <div className='list'>
                        {basicInfo.google && <img src='/images/google.svg' alt='google' onClick={() => window.location.href = dynamicExternalLink(basicInfo.google)} />}
                        {basicInfo.linkedin && <img src='/images/linkedln.svg' alt='linkedln' onClick={() => window.location.href = dynamicExternalLink(basicInfo.linkedin)} />}
                        {basicInfo.twitter && <img src='/images/twitter.svg' alt='twitter' onClick={() => window.location.href = dynamicExternalLink(basicInfo.twitter)} />}
                        {basicInfo.yelp && <img src='/images/yelp.svg' alt='yelp' onClick={() => window.location.href = dynamicExternalLink(basicInfo.yelp)} />}
                    </div>
                </div>
                <div className='mobile_introduction'>{aboutInfo.introduction}</div>
                {
                    basicInfo.tagsList.length > 0 && (
                        <div className='mobile-tag-list-new'>
                            {
                                basicInfo.tagsList.map((tag, index) =>
                                    <div className='tag' key={index}>{tag}</div>
                                )
                            }
                        </div>
                    )
                }
                <div className="mobile-navList">
                    <Button className='contact-btn ' onClick={() => window.location.href = getPreQualificationLink()}>Apply Now</Button>

                </div>
                <Button className='mobile_contact-btn  about-apply-now-btn' onClick={contactMe}>Contact me today</Button>
        
                {/* <div className='mobile_company'>
                    <div className='mobile_info_title mb-3'>
                        {aboutInfo.banner}
                    </div>
                    <img src='/company.png'></img>
                    <div className='mobile_title mt-3'>{aboutInfo.companyWebsite}</div>
                    <div className='mobile_companyWebsite mt-2'>{aboutInfo.companyIntroduction}</div>
                </div> */}
            </div>
            {showCompanyInfo && <div className="companyWraper">
                <Company aboutInfo={aboutInfo}></Company>
                <div className="company-bg-img">
                    <img src='/circleHouse.svg'/>
                </div>
            </div>}
        </div>
    );
}