import React from 'react';
import { Progress } from 'antd';
import usePercent from './usePercent';

const AutoProgress = ({ percent, ...rest }) => {
  const autoPercent = usePercent(percent);

  return (
    <Progress
        percent={autoPercent}
        {...rest}
    />
  );
};

export default AutoProgress;