import React, { useState, useEffect, useRef } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import ReProfile, { LoProfile } from "./Profile";
import Properties from "./Properties";
import Resources from "./Resources";
import Tools, { LandingAffordability, LandingRateQuoter } from "./Tools";
import CallBack from "./CallBack";
import CustomerReview from "./CustomerReview"
import "./LandingPage.css";

const backgroundColor = "#F7F8FC";

const LandingPage = (props) => {
  // if (window.location.href.includes("/re/") || props.url.includes("/re/")) {
  //   return <ReLandingPage {...props} />;
  // } else {
  // } 
  return <LoLandingPage {...props} />;
};

const ReLandingPage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const refProperties = useRef(null);
  const refRecommendations = useRef(null);
  const refTools = useRef(null);

  const handleClickRefProperties = () => {
    if (refProperties.current)
      refProperties.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickRefRecommendations = () => {
    if (refRecommendations.current)
      refRecommendations.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickRefTools = () => {
    if (refTools.current)
      refTools.current.scrollIntoView({ behavior: "smooth" });
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    // console.log(width, height)
    console.log(window.location.href);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div>
      <div>
        <div>
          <Navbar expand="lg" className="p-0 m-0  landingNav">
            <Nav className="landingnav">
              <Nav.Item className="landingNavName">{name}</Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link
                  eventKey="affordability"
                  onClick={handleClickRefProperties}
                >
                  Properties
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link
                  eventKey="prequal"
                  onClick={handleClickRefRecommendations}
                >
                  Recommendations
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link eventKey="resources" onClick={handleClickRefTools}>
                  Mortgage Tools
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar>
          <div className="landingProfile">
            <ReProfile
              id={props.id}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
            />
          </div>
        </div>
      </div>

      <div ref={refProperties} style={{ backgroundColor: "white" }}>
        <Properties id={props.id} {...props}/>
      </div>
      <div
        ref={refRecommendations}
        style={{ backgroundColor: backgroundColor }}
        className="pb-5"
      >
        <Resources id={props.id} width={width} height={height} />
      </div>
      <div
        style={{ backgroundColor: "white" }}
        className="landingTools"
        id="landingTools"
        ref={refTools}
      >
        <Tools id={props.id} width={width} height={height} {...props}/>
      </div>
      <div>
        <CallBack id={props.id} name={name} email={email} />
      </div>
      <div style={{ backgroundColor: backgroundColor }}>
        <div className="landingFooterClaim">
          The Security of your personal information is our highest priority. We
          use bank-level encryption (256-bit AEE) to ensure it is never
          compromised.
        </div>
        <div className="landingFooter">
          © Zeitro, Inc. All Rights Reserved 2023.
        </div>
      </div>
    </div>
  );
};

const LoLandingPage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showContactmeModal, setShowContactmeModal] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const refAffordability = useRef(null);
  const refRateQuoter = useRef(null);

  const handleClickRefAffordability = (e) => {
    if (refAffordability.current)
      refAffordability.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickRefRateQuoter = () => {
    if (refRateQuoter.current)
      refRateQuoter.current.scrollIntoView({ behavior: "smooth" });
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    getProfile()
    window.addEventListener("resize", updateDimensions);
    // console.log(width, height)
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const getContactmeModal = () => {
    return (
      <Modal
        show={showContactmeModal}
        onHide={() => setShowContactmeModal(false)}
      >
        <Modal.Body>
          <CallBack id={props.id} name={name} email={email} forHeader={true} />
        </Modal.Body>
      </Modal>
    );
  };

  const getProfile = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "X-CustomerID": props.customerid,
          "X-LoID": props.loid,
      },
  }).then(response => {
      if (!response.ok) {
          console.log(response)
      } else {
          response.json().then(js => {
              let su = js.LandingPageSetUp
              setReviewList( su.ReviewsList===undefined ? []: su.ReviewsList)
          })
      }
  }).catch(error => {
      console.log("Revalidate catch error " + error)
  })
  };

  const getPreQualificationLink = () => {
    if (window.location.host.includes("zeitro.us")) {
      return "https://app.zeitro.us/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    } 
    if (window.location.host.includes("localhost")) {
      return "http://localhost:3000/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    }
    return "https://app.zeitro.com/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
  }

  return (
    <div>
      <div>
        <div>
          <Navbar expand="lg" className="p-0 m-0  landingNav">
            <Nav.Item className="landingNavName">{name}</Nav.Item>
            <Nav className="landingnav1 mr-auto">
              <Nav.Item className="landingnavitem">
                <Nav.Link
                  eventKey="affordability"
                  onClick={handleClickRefAffordability}
                >
                  Affordability
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link eventKey="prequal" onClick={handleClickRefRateQuoter}>
                  Rate Quoter
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link eventKey="getprequalified" onClick={() =>
                      (window.location.href = getPreQualificationLink())
                    }>
                Get Prequalified
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Nav>
              <Nav.Item className="landingnavcontact">
                <Nav.Link eventKey="rates">
                  <Button
                    variant="landingNavContact"
                    onClick={() =>
                      (window.location.href = getPreQualificationLink())
                    }
                  >
                    Apply Now
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavcontact">
                <Nav.Link eventKey="contact">
                  <Button
                    variant="landingNavContact"
                    onClick={() => setShowContactmeModal(true)}
                  >
                    Contact me
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar>

          <div className="landingProfile">
            <LoProfile
              customerid={props.customerid}
              loid={props.loid}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              handleClickRefAffordability={handleClickRefAffordability}
              handleClickRefRateQuoter={handleClickRefRateQuoter}
            />
          </div>
        </div>
      </div>

       <div
        style={{ backgroundColor: "white" }}
        className="landingTools pb-2"
        id="landingTools"
        ref={refAffordability}
      >
        <LandingAffordability customerid={props.customerid} loid={props.loid} width={width} height={height} />
      </div>

      <div
        style={{ backgroundColor: backgroundColor }}
        className="landingRateQuoter"
        id="landingRateQuoter"
        ref={refRateQuoter}
      >
        <LandingRateQuoter customerid={props.customerid} loid={props.loid} width={width} height={height} {...props}/>
      </div>
      <CustomerReview reviewList={reviewList} />
      <div>
        <CallBack id={props.id} name={name} email={email} />
      </div>
      {/* <div style={{ backgroundColor: backgroundColor }}>
        <div className="landingFooterClaim">
          The Security of your personal information is our highest priority. We
          use bank-level encryption (256-bit AEE) to ensure it is never
          compromised.
        </div>
        <div className="landingFooter">
          © Zeitro, Inc. All Rights Reserved 2023.
        </div>
      </div>  */}
      {showContactmeModal && getContactmeModal()}
    </div>
  );
};

export default LandingPage;
