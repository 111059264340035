import React, { useState, useEffect } from "react";
import "./Management.css";
import { Menu } from "antd";
import LoanOfficers from "./LoanOfficers";
import Borrowers from "./Borrowers";
import Pipeline from "./Pipeline";
import Integration from "./Integration";
import Customization from "./Customization/Customization";
import Lenders from "./Lenders";
import CommunicationSetup from "./CommunicationSetup/CommunicationSetup";
import CreditReportSettings from "./CreditReportSettings";
import SampleRatesConfig from "./SampleRatesConfig/SampleRatesConfig";
import { checkAccess } from "../Auth";
import UserManagement from "./userManagement";
import PlanPricing from "./planPricing";
import { UR_Owner } from "../State";
import BillingManagement from './billingManagement'
import CallReports from "./CallReports/CallReports";
import "./Fixes.css";
import { useLocation } from 'react-router-dom';
import * as com from "../Common";
import GMCCProgramEngine from "../GMCCCalculator/GMCCProgramEngine";
import RateQuote from "../Tools/MortgageCalculators/RateQuote";
import GMCCUniverseCalculator from "../Tools/MortgageCalculators/GMCCUniverseCalculator";
import GMCCDiamond from "../GMCCCalculator/GMCCDiamond";
const ManagementIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.825 15.9H15.9V18.825C15.9 19.3632 16.3368 19.8 16.875 19.8H18.825C19.3632 19.8 19.8 19.3632 19.8 18.825V16.875C19.8 16.3368 19.3632 15.9 18.825 15.9ZM19.8 8.1V5.175C19.8 4.6368 19.3632 4.2 18.825 4.2H16.875C16.3368 4.2 15.9 4.6368 15.9 5.175V8.1H19.8ZM10.05 10.05H13.95V13.95H10.05V10.05ZM7.125 4.2H5.175C4.6368 4.2 4.2 4.6368 4.2 5.175V7.125C4.2 7.6632 4.6368 8.1 5.175 8.1H8.1V5.175C8.1 4.6368 7.6632 4.2 7.125 4.2ZM4.2 16.875V18.825C4.2 19.3632 4.6368 19.8 5.175 19.8H7.125C7.6632 19.8 8.1 19.3632 8.1 18.825V15.9H5.175C4.6368 15.9 4.2 16.3368 4.2 16.875ZM19.8 21.75H15.9C14.8226 21.75 13.95 20.8774 13.95 19.8V15.9H10.05V19.8C10.05 20.8774 9.17738 21.75 8.1 21.75H4.2C3.12262 21.75 2.25 20.8774 2.25 19.8V15.9C2.25 14.8226 3.12262 13.95 4.2 13.95H8.1V10.05H4.2C3.12262 10.05 2.25 9.17737 2.25 8.1V4.2C2.25 3.12263 3.12262 2.25 4.2 2.25H8.1C9.17738 2.25 10.05 3.12263 10.05 4.2V8.1H13.95V4.2C13.95 3.12263 14.8226 2.25 15.9 2.25H19.8C20.8774 2.25 21.75 3.12263 21.75 4.2V8.1C21.75 9.17737 20.8774 10.05 19.8 10.05H15.9V13.95H19.8C20.8774 13.95 21.75 14.8226 21.75 15.9V19.8C21.75 20.8774 20.8774 21.75 19.8 21.75Z"
      fill="#474849"
    />
  </svg>
);

function getItem(label, key, icon, children, type) {
  return { key, icon, children, label, type };
}


const items = [
  getItem(
    <div
      style={{
        fontSize: 20,
        paddingLeft: 5,
        paddingTop: 10,
        display: "flex",
        alignItems: "center",
        color: "#474849",
        minWidth:"250px"
      }}>
      <ManagementIcon />
      <div className="ml-2">GMCC Specials</div>
    </div>,
    "1",
    null,
    [
      getItem("Program maps", "programengine"), 
      getItem("Celebrity", "gmcccelebrityrates"), 
      getItem("Universe", 'gmccuniverserates'),
      getItem("Diamond", 'gmccdiamond')
    ],
    "group"
  ),
];

const GMCCCalculators = (props) => {
  const [selectedKey, setSelectedKey] = useState("programengine")
  const [current, setCurrent] = useState(<Borrowers />);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    const queryParams = new URLSearchParams(location.search);
    const hasParam = queryParams.has('select');
    if (hasParam) {
      const params = {
        key: queryParams.get('select')
      }
      onClick(params)
    } else {

      onClick({

        key: sessionStorage.getItem('path') === undefined ? 'programengine' : sessionStorage.getItem('path')
      })
    }
    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, []);
  const [showUser, setShowUser] = useState(null)
  useEffect(() => {

    if (showUser === null) return
    onClick({ key: 'usermanagement' })
  }, [

    showUser
  ])

  const visitCelebrityfromProgramMap = () => {
    setSelectedKey("gmcccelebrityrates")
    sessionStorage.setItem('path', "gmcccelebrityrates")
    setCurrent(<RateQuote isGMCCCelebrity={true} />);
  }

  const onClick = (e) => {
    setSelectedKey(e.key)
    sessionStorage.setItem('path', e.key)
    switch (e.key) {
      case "programengine":
        setCurrent(<GMCCProgramEngine gotoCelebrity={visitCelebrityfromProgramMap} />);
        break;
      case "gmcccelebrityrates":
        setCurrent(<RateQuote isGMCCCelebrity={true} />);
        break;
      case "gmccuniverserates":
        setCurrent(<GMCCUniverseCalculator />);
        break;
      case "gmccdiamond":
        setCurrent(<GMCCDiamond />);
        break;
      default:
        break;
    }
  };

  return (
    <div className={isMobile ? "mobileManagementWrap" : "managementWrap"}>
      <Menu
        onClick={onClick}
        className={isMobile ? "mobileManagementMenu" : "managementMenu"}
        selectedKeys={[selectedKey]}
        mode={isMobile ? "horizontal" : "inline"}
        items={items}
      />
      <div className={selectedKey === "programengine" || selectedKey==="gmcccelebrityrates"? "programEnginePane":"w-100"}>{current}</div>
    </div>
  );
};

export default GMCCCalculators;
