import React from "react";

interface PDFViewerProps {
  src: string; // The URL of the PDF
  fileName: string; // The name of the PDF file
}

const PDFViewer: React.FC<PDFViewerProps> = ({ src, fileName }) => {
  return (
    <div style={{ textAlign: "center", marginTop: "20px", height: "100vh" }}>
      <iframe
        src={src}
        title={fileName}
        width="100%"
        height="90%"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default PDFViewer;
