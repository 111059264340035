import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { sortBy } from "lodash";
import moment from "moment";
import { Table, Space, message, Modal, Row, Button, Typography } from "antd";

import PDFViewer from "./PDFViewer";
import "./PrequalLetters.css";
import { USDollarCurrencyLocale } from "./utils";

const { confirm } = Modal;
const { Paragraph } = Typography;

// Define the structure of the data
interface PreQualLetter {
  serialID: string;
  issueDate: string;
  loanAmount: string;
  expirationDate: string;
}

interface PreQualLetterHistoryProps {
  name: string;
}

const PreQualLetterHistory = forwardRef(
  ({ name }: PreQualLetterHistoryProps, ref) => {
    let token = sessionStorage.getItem("ZeitroA");
    const paths = window.location.pathname.split("/");
    const loanID = paths[paths.length - 1];

    const [data, setData] = useState<PreQualLetter[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [trigger, setTrigger] = useState<number>(0); // Trigger refetch by updating this
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pdfUrl, setPdfUrl] = useState<string>("");
    // Fetch all documents for the loan
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch("/los/get_all_prequal_letters_for_loan", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
          },
          body: JSON.stringify({ loanid: loanID }),
        });
        const data = await response.json();
        const sortedData = sortBy(
          data,
          (record) => -moment(record.issueDate).unix()
        );
        setData(sortedData);
        setLoading(false);
      } catch (error) {
        message.error("Failed to fetch data");
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      fetchData,
    }));

    useEffect(() => {
      let ignore = false;
      fetchData();
      return () => {
        ignore = true;
      };
    }, [trigger]);

    // Function to handle the delete action
    const handleDelete = async (documentSerialID: string) => {
      confirm({
        title: "Are you sure you want to delete this pre-qual letter?",
        onOk: async () => {
          try {
            // Replace with your actual delete API
            const response = await fetch(`/los/delete_one_prequal_letter`, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                Cache: "no-cache",
              },
              body: JSON.stringify({ documentSerialID }),
            });
            const data = await response.json();
            if (data.status !== "OK") {
              message.error("Failed to delete the selected pre-qual letter.");
            } else {
              message.success("Pre-qual letter deleted successfully.");
              setTrigger(trigger + 1);
            }
          } catch (error) {
            message.error("Failed to delete the selected pre-qual letter.");
          }
        },
      });
    };

    const showPDFViewer = async (documentSerialID: string) => {
      const response = await fetch(`/los/get_one_prequal_letter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body: JSON.stringify({ documentSerialID }),
      });

      const pdfBlob = await response.blob(); // Fetch PDF as Blob
      const pdfBlobUrl = URL.createObjectURL(pdfBlob); // Convert Blob to URL
      setPdfUrl(pdfBlobUrl); // Set Blob URL for iframe
      setTimeout(() => {
        setIsModalVisible(true); // Open the modal to show the pdf file
      }, 100);
    };

    const columns = [
      {
        title: "Borrower Name",
        key: "borrowerName",
        render: () => name,
      },
      {
        title: "Issue Date",
        dataIndex: "issueDate",
        key: "issueDate",
        render: (_: any, record: PreQualLetter) =>
          moment(record.issueDate).format("MM/DD/YYYY"),
      },
      {
        title: "Loan Amount",
        dataIndex: "loanAmount",
        key: "loanAmount",
        render: (_: any, record: PreQualLetter) =>
          USDollarCurrencyLocale.format(Number(record.loanAmount)),
      },
      {
        title: "Expiration Date",
        dataIndex: "expirationDate",
        key: "expirationDate",
        render: (_: any, record: PreQualLetter) =>
          moment(record.expirationDate).format("MM/DD/YYYY"),
      },
      {
        title: "Action",
        key: "action",
        render: (_: any, record: PreQualLetter) => (
          <Space size="middle">
            <Button
              type="default"
              style={{ padding: 0, border: "none" }}
              onClick={() => showPDFViewer(record.serialID)}
            >
              <img
                src="/images/document-magnifier.svg"
                alt="Details"
                style={{ height: "60%" }} // Adjust size as needed
              />
            </Button>

            <Button
              type="default"
              style={{ padding: 0, border: "none" }}
              onClick={() => handleDelete(record.serialID)}
            >
              <img
                src="/images/document-delete.svg"
                alt="Delete"
                style={{ height: "60%" }} // Adjust size as needed
              />
            </Button>
          </Space>
        ),
      },
    ];

    return (
      <div style={{ padding: "20px" }}>
        {data.length === 0 && (
          <div className="prequal-requested-container">
            <Row className="prequal-requested-header">
              <img
                src="/images/prequal-requested.svg"
                alt="Prequal Lette Requested"
              />
            </Row>
            <Row justify="center">
              <Paragraph className="prequal-requested-text">
                Your borrower has applied for a pre-qualification letter. Please
                select the appropriate plan and send it to them.
              </Paragraph>
            </Row>
          </div>
        )}
        {data.length > 0 && (
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={false}
            rowKey="serialID"
            sortDirections={["descend"]}
          />
        )}
        <Modal
          title=""
          open={isModalVisible}
          className="history-letter-modal"
          onCancel={() => setIsModalVisible(false)} // Close modal on cancel
          footer={null} // No footer (buttons)
          width="85%"
          style={{
            position: "fixed",
            top: 0,
            left: "12.5%",
            height: "100vh",
            display: "flex",
            overflow: "hidden",
            padding: 0,
          }}
        >
          <PDFViewer src={pdfUrl} fileName="prequal_letter.pdf" />
        </Modal>
      </div>
    );
  }
);

export default PreQualLetterHistory;
