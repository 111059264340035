import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

export default function Warning({ content }) {
  return (
    <Container
      style={{
        backgroundColor: "rgba(255, 170, 41, 0.3)",
        padding: "15px",
        borderRadius: "4px",
      }}
    >

        <Row>
          <Col md={1}>
              <img
              src="/images/warningSignYellow.png"
              style={{ width: "35px", paddingRight:"10px" }}
            />
          </Col>
          <Col md={11} className="text-left">
            {content}
          </Col>
        </Row>
    </Container>
  );
}
