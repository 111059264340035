import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ListGroup from "react-bootstrap/ListGroup";
import { ausOptions, termOptions } from "./common";
import { HorizontalSimpleSlider } from "../../Common/HorizontalSlider";
import HorizontalSliderV2 from "../../Common/HorizontalSliderV2";
import "../../App.css";
import "./RateQuote.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import * as com from "../../Common.js";
import NumericalInput, {CommaizedInput} from "../../NumericalInput";
import { withRouter } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import IneligibleProductsModal from "./IneligibleProducts";
import AllRatesModal, { AllRatesTable } from "./AllRates";
import ReportIssuesModal from "./ReportIssues";
import { YellowWarning } from "../../Common/Warnings";
import Spinner from "react-bootstrap/Spinner";
import Select from 'react-select';

const DEFAULT_LOAN_TERM = 30; //year

let fixed30 = [];
let fixed25 = [];
let fixed20 = [];
let fixed15 = [];
let fixed10 = [];
let arm56 = [];
let arm76 = [];
let arm106 = [];
let allRates = {};

const RateQuoteLOVersion = (props) => {
  let customerid = com.getCustomerId();

  const [loanLimits, setLoanLimits] = useState({});
  const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM);
  const [propertyType, setPropertyType] = useState("singlefamilydetached");
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");
  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(100000);
  const [county, setCounty] = useState("SANTA CLARA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [dti, setDTI] = useState(30);
  const [monthlyIncome, setMonthlyIncome] = useState(50000);
  const [monthlyDebt, setMonthlyDebt] = useState(1000);
  const [estOtherCosts, setEstOtherCosts] = useState(3195);
  const [units, setUnits] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState(fixed30);
  const [fico, setFico] = useState(780);
  const [lockDay, setLockDay] = useState(30);
  const [citizenship, setCitizenship] = useState("US_CITIZENS");
  const [visaType, setVisaType] = useState("H_1B");
  const [numberOfOwnedProperties, setNumberOfOwnedProperties] = useState(1);
  const [currentJobInMonths, setCurrentJobInMonths] = useState(24);
  const [secondMortageAmount, setSecondMortgageAmount] = useState(0);
  const [escrowWaiver, setEscrowWaiver] = useState(false)
  const [selfEmployed, setSelfEmployed] = useState(false)
  const [rentalIncomeUsed, setRentalIncomeUsed] = useState(false)
  const [firstTimeHomeBuyer, setfirstTimeHomeBuyer] = useState(false)
  const [appraisalWaiver, setAppraisalWaiver] = useState(false)
  const [rsuAsIncome, setRsuAsIncome] = useState(false)
  const [giftFundUsed, setGiftFundUsed] = useState(false)
  const [workForSpecialBusiness, setWorkForSpecialBusiness] = useState(false)
  const [nonOccupancyCoBorrower, setNonOccupancyCoBorrower] = useState(false)
  const [borrowerPaidCompensation, setBorrowerPaidCompensation] = useState(false)
  const [borrowerPaidCompensationAmount, setBorrowerPaidCompensationAmount] = useState(0)
  
  const [indexfixed30, setIndexFixed30] = useState(8);
  const [indexfixed25, setIndexFixed25] = useState(6);
  const [indexfixed20, setIndexFixed20] = useState(6);
  const [indexfixed15, setIndexFixed15] = useState(6);
  const [indexfixed10, setIndexFixed10] = useState(6);
  const [indexarm56, setIndexArm56] = useState(6);
  const [indexarm76, setIndexArm76] = useState(6);
  const [indexarm106, setIndexArm106] = useState(6);
  const [ineligibleProducts, setIneligibleProducts] = useState({});
  const [showIneligible, setShowIneligible] = useState(false);
  const [noProducts, setNoProducts] = useState(false);
  const [showReportIssues, setShowReportIssues] = useState(false);
  const [showRatePreview, setShowRatePreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [loProfile, setLOProfile] = useState({});

  const [message, setMessage] = useState("");
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [showFileModal, setShowFileModel] = useState(false);
  const handleCloseFileModal = () => setShowFileModel(false);
  const handleShowFileModal = () => setShowFileModel(true);

  const [showEditCostDetails, setShowEditCostDetails] = useState(false);
  const handleEditCostDetailsModal = () => setShowEditCostDetails(false);
  const [cannotshop, setCannotshop] = useState([
    { name: "Appraisal Fee", value: 750 },
    { name: "Credit Report", value: 65 },
    { name: "Flood Certification", value: 10 },
    { name: "HOA Certification Fee", value: 350 },
    { name: "Lender Doc Prep", value: 75 },
    { name: "Tax Service Fee", value: 80 },
  ]);
  const [canshop, setCanshop] = useState([
    { name: "Pest Inspection", value: 175 },
    { name: "Title-Closing/Escrow Fee", value: 475 },
    { name: "Title-Courier Fee", value: 35 },
    { name: "Title-Endorsement Fee", value: 25 },
    { name: "Title-Lender's Title Insurance", value: 645 },
    { name: "Title-Loan Tie in Fee", value: 280 },
    { name: "Title-Notary Fee", value: 200 },
    { name: "Title-Processing Fees to Escrow", value: 15 },
    { name: "Title-Wire Fee to Title", value: 15 },
  ]);

  let preselectedTermOptions = [
    { name: "Fixed 30 Yr", key: "fixed30" },
    { name: "Fixed 15 Yr", key: "fixed15" },
    { name: "ARM 5/6", key: "arm56" },
  ];

  let fileSuffixMap = {
    Fairway: "pdf",
    HomePoint: "xlsx",
    PRMG: "xlsx",
    AmeriHome: "xlsx",
    HomePointDelegated: "xlsx",
    FifthThirdBank: "xls",
    RocketPro: "xlsx",
    USBank: "pdf",
    PRMG: "xls",
    Caliber: "pdf",
    LoanDepot: "pdf",
    Amwest: "pdf",
    DivineMortgage: "pdf",
    LoanStore: "pdf",
    NewWaveLending: "pdf",
    WholesaleMortgageLender: "pdf",
    ResiCentral: "pdf",
    Provident: "csv",
    GMCCCelebrities : "xlsx",
    FlagStar : "xlsx",
    PlanetHome : "xlsx",
  };

  // useEffect(() => {
  //   console.log(showIneligible, message, ineligibleProducts);
  // });

  const [selectedTerms, setSelectedTerms] = useState(preselectedTermOptions);
  const underwritingOptions = [
    { label: "DU", value: "DU" },
    { label: "LP", value: "LP" },
    { label: "Manual", value: "Manual" },
  ];
  const [selectedAUS, setSelectedAUS] = useState(underwritingOptions);

  const form = useRef();

  let getLtv = () => {
    let ltv = Math.ceil((getLoanAmount() / parseInt(propertyValue)) * 100);
    let cltv =  Math.ceil(((getLoanAmount() + parseInt(secondMortageAmount)) / parseInt(propertyValue)) * 100);
    let hcltv =  Math.ceil(((getLoanAmount() + parseInt(secondMortageAmount))/ parseInt(propertyValue)) * 100);
    // let cltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(helocsize)) / parseInt(propertyValue) * 100)
    // let hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloclimit)) / parseInt(propertyValue) * 100)
    return [ltv, cltv, hcltv];
  };

  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };

  let downloadRatesheet = (loan) => {
    let s2ab = (s) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    let blob = new Blob([s2ab(atob(loan.FileB))], { type: "" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    if (fileSuffixMap[loan.Lender] == undefined) {
      handleShowFileModal();
      return;
    }

    link.download = loan.Lender + "_Ratesheet." + fileSuffixMap[loan.Lender];
    link.click();
  };


  let handleEditCostDetails = () => {
    if (showEditCostDetails) {
      setShowEditCostDetails(false);
    } else {
      setShowEditCostDetails(true);
    }
  };

  let getProducts = () => {
    let pmi = 0.51;
    let zeitro_fee = [{ lender: "default", percent: 1.0, fixed: 500 }];
    let _units = 1;
    let _county = "ZAVALA COUNTY";
    if (propertyType === "twotofourfamily") _units = units;

    setMessage("");

    arm56 = [];
    arm76 = [];
    arm106 = [];
    fixed30 = [];
    fixed25 = [];
    fixed20 = [];
    fixed15 = [];
    fixed10 = [];
    let [ltv, cltv, hcltv] = getLtv();

    if (state != "TEXAS") _county = county;

    let aus = []
    for (let a of selectedAUS) {
      aus.push(a.label)
    }

    let body = {
      state: state,
      county: _county,
      purpose: purpose,
      loanAmount: getLoanAmount(),
      units: parseInt(_units),
      LTV: ltv,
      CLTV: cltv,
      HCLTV: hcltv,
      FICO: fico,
      occupancy: com.getOccupancy(occupancy),
      property: com.getPropertyType(propertyType),
      point: zeitro_fee,
      estimatedClosingCosts: parseFloat(estOtherCosts),
      pmi: pmi,
      getFiles: false,
      monthlyIncome: parseFloat(monthlyIncome),
      monthlyDebt: parseFloat(monthlyDebt),
      firsttimehomebuyer: firstTimeHomeBuyer,
      selfemployed: selfEmployed,
      citizenship: citizenship,
      visaType: visaType,
      numberOfOwnedProperties: numberOfOwnedProperties,
      escrowWaiver: escrowWaiver,
      appraisalWaiver: appraisalWaiver,
      currentJobInMonths: currentJobInMonths,
      rsuAsIncome: rsuAsIncome ? 10 : -1,
      giftFundUsed: giftFundUsed,
      familyRelatedOrSmallBusiness: workForSpecialBusiness,
      borrowerPaidCompensationAmount: borrowerPaidCompensationAmount,
      dti:dti,
    };
    setLoading(true);
    fetch("/data/lopriceengine/" + customerid, {
      method: "POST",
      headers: {
        Cache: "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          setMessage("An internal error has occured");
          return;
        }
        response.json().then((pr) => {
          let products = pr.result;
          if (pr.ineligible_reasons != undefined) {
            setIneligibleProducts(pr.ineligible_reasons);
          } else {
            setIneligibleProducts({});
          }
          if (pr.message != undefined) {
            setMessage(pr.message);
          } else {
            setMessage("");
          }
          if (products === undefined) {
            setNoProducts(true)
            setShowIneligible(true);
            setLoading(false);
            return;
          } else {
            setNoProducts(false)
          }
          products.forEach((product) => {
            // console.log("prd", product);
            let lender = product.Lender;
            let fb = product.FileB;
            product.ProductRatesCosts.forEach((pr) => {
              let commonproduct = pr.product;
              let adjustments = pr.appliedAdjustments;
              // if (commonproduct.Term !== loanTerm) return;
              let ausengine = pr.AUSEngine;
              pr.rateCosts.forEach((rate) => {
                let pdkt = {
                  Lender: lender,
                  ...commonproduct,
                  Aus: ausengine,
                  ...rate,
                  Adjustments: adjustments,
                  FileB: fb,
                };

                if (rate.lockDay !== lockDay) {
                  return;
                }
                let commonAUS = selectedAUS.filter(val => pdkt.Aus.includes(val.label));
                if (commonAUS.length === 0) {
                  return
                }
                const filterPdkt = () => {
                  if (customerid==="siliconcitymortgage" && pdkt.Mtype.includes("FHA")) {
                    return false
                  }
                  return true
                }
                /// SOMRTHING ELSE HERE
                pdkt.loanAmount = getLoanAmount();
                if (filterPdkt()) {
                  if (pdkt.Arm != null) {
                    if (pdkt.Arm.fixedperiod == 5) {
                      arm56.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 7) {
                      arm76.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 10) {
                      arm106.push(pdkt);
                    }
                  } else {
                    if (pdkt.Term == 30) {
                      fixed30.push(pdkt);
                    } else if (pdkt.Term == 25) {
                      fixed25.push(pdkt);
                    } else if (pdkt.Term == 20) {
                      fixed20.push(pdkt);
                    } else if (pdkt.Term == 15) {
                      fixed15.push(pdkt);
                    } else if (pdkt.Term == 10) {
                      fixed10.push(pdkt);
                    }
                  }
                }
              });
            });
          });

          let compareRate = (a, b) => {
            if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate;

            return a.closing_cost - b.closing_cost; // take into account remaining credits
          };

          let cullRate = (products) => {
            const filteredPrds = products.filter((p) => {
              return p.lender_credit > 0;
            });

            if (filteredPrds.length === 0) return filteredPrds;
            let newproducts = [];
            newproducts.push(filteredPrds[0]);
            let base_rate = filteredPrds[0].base_rate;
            let closing_cost = filteredPrds[0].closing_cost;

            for (let i = 1; i < filteredPrds.length; i++) {
              // console.log(filteredPrds[i]);
              if (filteredPrds[i].base_rate !== base_rate) {
                if (filteredPrds[i].closing_cost < closing_cost) {
                  newproducts.push(filteredPrds[i]);
                  closing_cost = filteredPrds[i].closing_cost;
                }
                base_rate = filteredPrds[i].base_rate;
              } else {
              }
            }
            return newproducts;
          };

          let getIndex = (loans) => {
            let bestIndex = 0;
            let bestPoints = 1000000;
            for (let i = 0; i < loans.length; i++) {
              if (Math.abs(loans[i].lender_credit - 1500) < bestPoints) {
                bestPoints = Math.abs(loans[i].lender_credit - 1500);
                bestIndex = i;
              }
            }
            return bestIndex;
          };

          allRates = {};
          allRates.fixed30 = fixed30;
          allRates.fixed25 = fixed25;
          allRates.fixed20 = fixed20;
          allRates.fixed15 = fixed15;
          allRates.fixed10 = fixed10;
          allRates.arm56 = arm56;
          allRates.arm76 = arm76;
          allRates.arm106 = arm106;

          fixed30.sort(compareRate);
          fixed25.sort(compareRate);
          fixed20.sort(compareRate);
          fixed15.sort(compareRate);
          fixed10.sort(compareRate);
          arm56.sort(compareRate);
          arm76.sort(compareRate);
          arm106.sort(compareRate);
          fixed30 = cullRate(fixed30);
          fixed25 = cullRate(fixed25);
          fixed20 = cullRate(fixed20);
          fixed15 = cullRate(fixed15);
          fixed10 = cullRate(fixed10);
          arm56 = cullRate(arm56);
          arm76 = cullRate(arm76);
          arm106 = cullRate(arm106);

          setIndexFixed30(getIndex(fixed30));
          setIndexFixed25(getIndex(fixed25));
          setIndexFixed20(getIndex(fixed20));
          setIndexFixed15(getIndex(fixed15));
          setIndexFixed10(getIndex(fixed10));
          setIndexArm56(getIndex(arm56));
          setIndexArm76(getIndex(arm76));
          setIndexArm106(getIndex(arm106));

          if (fixed30.length !== 0) {
            setLoans(fixed30);
          } else if (arm56.length !== 0) {
            setLoans(arm56);
          }
          setLoading(false);
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  const getLoanLimits = () => {
    setLoading(true);
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          // console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    };


  const ref = useRef();
  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });

  useEffect(() => {
    getProducts();
    getLoanLimits();

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error: " + js.Error);
          } else {
            let lo = js.LoanOfficers[0];
            // console.log("profile info returned: %s ", JSON.stringify(js));
            setLOProfile({
              firstName: lo.FirstName,
              lastName: lo.LastName,
              email: lo.Email,
              phone: lo.Phone,
              avatarsrc: lo.Image,
              nmls: lo.Nmls,
            });
          }
        });
      })
      .catch(function (err) {
        alert("Fetch Error :-S", err);
      });
    // console.log("lo", loProfile);
  }, []);

  useEffect(() => {
    if (!showSubscribe) setShowRatePreview(false);
  }, [showSubscribe, showRatePreview]);

  let showCounties = () => {
    let options = [<option value=""></option>];
    if (state in loanLimits) {
      let keys = Object.keys(loanLimits[state]);
      
      keys.forEach((x) => {
        options.push(<option value={x}> {com.capitalize(x)}</option>);
      });
    }
    return options;
  };

  let showStates = () => {
    let options = [<option value=""></option>];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  }

  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }
    if (parseInt(downpayment) === 0) {
      setValidated(true);
      alert("Downpayment can't be zero");
      return false;
    }
    if (parseInt(downpayment) >= parseInt(propertyValue)) {
      setValidated(true);
      alert("Downpayment can't be bigger than purchase price");
      return false;
    }
    if (parseInt(fico) < 300 || parseInt(fico) > 1000) {
      setValidated(true);
      alert("FICO score should be in the range of 300 - 1000");
      return false;
    }
    getProducts();
    return false;
  };

  let changeBalancefixed30 = (v) => {
    setIndexFixed30(v);
  };
  let changeBalancefixed25 = (v) => {
    setIndexFixed25(v);
  };
  let changeBalancefixed20 = (v) => {
    setIndexFixed20(v);
  };
  let changeBalancefixed15 = (v) => {
    setIndexFixed15(v);
  };
  let changeBalancefixed10 = (v) => {
    setIndexFixed10(v);
  };
  let changeBalancearm56 = (v) => {
    setIndexArm56(v);
  };
  let changeBalancearm76 = (v) => {
    setIndexArm76(v);
  };
  let changeBalancearm106 = (v) => {
    setIndexArm106(v);
  };

  let cardStyle = {
    backgroundColor: "#f7f7f7",
    borderRadius: 5,
    margin: "auto",
  };

  let showCard = (type) => {
    if (loans.length === 0) return "";
    let cbfunc = changeBalancefixed30;
    let allloans = fixed30;
    let curindex = indexfixed30;

    if (type == "fixed25") {
      allloans = fixed25;
      cbfunc = changeBalancefixed25;
      curindex = indexfixed25;
    } else if (type == "fixed20") {
      allloans = fixed20;
      cbfunc = changeBalancefixed20;
      curindex = indexfixed20;
    } else if (type == "fixed15") {
      allloans = fixed15;
      cbfunc = changeBalancefixed15;
      curindex = indexfixed15;
    } else if (type == "fixed10") {
      allloans = fixed10;
      cbfunc = changeBalancefixed10;
      curindex = indexfixed10;
    } else if (type == "arm56") {
      allloans = arm56;
      cbfunc = changeBalancearm56;
      curindex = indexarm56;
    } else if (type == "arm76") {
      allloans = arm76;
      cbfunc = changeBalancearm76;
      curindex = indexarm76;
    } else if (type == "arm106") {
      allloans = arm106;
      cbfunc = changeBalancearm106;
      curindex = indexarm106;
    }

    let i = curindex;
    let loan = allloans[Math.max(0, i - 1)];
    let titleStyle = { fontSize: "0.8em" };
    let textStyle = { fontSize: "1.2em" };

    if (loan == undefined) {
      return (
        <Container className="text-center p-3" style={cardStyle}>
          <p>No qualified product for loan type {type}</p>
        </Container>
      );
    }

    const getDetails = (loan) => {
      let priceData = [];
      let priceColumns = [];
      if (typeof loan.prices.CloseOverPoint === "underfined") {
        priceData = [
          {
            lock: loan.prices.LockDayPoint.toFixed(3),
            adj: loan.prices.AdjustmentPoint.toFixed(3),
            comp: loan.prices.CompensationPoint.toFixed(3),
            total:
              loan.points.toFixed(3) + "/$" + com.commaize(loan.lender_credit),
          },
        ];
        priceColumns = [
          {
            dataField: "lock",
            text: "LockDay Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "adj",
            text: "Adjustment Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "comp",
            text: "Compensation %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "total",
            text: "Total",
            headerStyle: { backgroundColor: "white" },
          },
        ];
      } else {
        priceData = [
          {
            lock: loan.prices.LockDayPoint.toFixed(3),
            adj: loan.prices.AdjustmentPoint.toFixed(3),
            comp: loan.prices.CompensationPoint.toFixed(3),
            closeover: loan.prices.CloseOverPoint.toFixed(3),
            total:
              loan.points.toFixed(3) + "/$" + com.commaize(loan.lender_credit),
          },
        ];
        priceColumns = [
          {
            dataField: "lock",
            text: "LockDay Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "adj",
            text: "Adjustment Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "comp",
            text: "Compensation %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "closeover",
            text: "Close Over %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "total",
            text: "Total",
            headerStyle: { backgroundColor: "white" },
          },
        ];
      }

      let ccData = [
        {
          estimated: com.commaize(estOtherCosts),
          lenderfee: com.commaize(loan.lender_fee),
          pi: com.commaize(loan.prepaid_interest),
          total: com.commaize(loan.closing_cost),
        },
      ];
      let ccColumns = [
        {
          dataField: "estimated",
          text: "Est. Costs(LE part B+C)",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "lenderfee",
          text: "Lender Fee",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "pi",
          text: "Est. Prepaid Interest(15 Day)",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "total",
          text: "Total Closing Costs",
          headerStyle: { backgroundColor: "white" },
        },
      ];
      return (
        <div>
          <ListGroup variant="pricedetail">
            <ListGroup.Item>
              <b>
                {loan.Lender} : {loan.Name}
              </b>
            </ListGroup.Item>
            <ListGroup.Item className="align-items-start">
              <div>
                <b>Applied adjustments:</b>
              </div>
              {Object.keys(loan.Adjustments).map((adj) => (
                <div>
                  {adj}: {loan.Adjustments[adj]}
                </div>
              ))}
            </ListGroup.Item>
            <div style={{ backgroundColor: "white" }}>
              <BootstrapTable
                bootstrap4
                condensed={true}
                bordered={false}
                striped={false}
                keyField="lock"
                data={priceData}
                columns={priceColumns}
              />
            </div>
            <div style={{ backgroundColor: "white" }}>
              <BootstrapTable
                bootstrap4
                condensed={true}
                bordered={false}
                striped={false}
                keyField="total"
                data={ccData}
                columns={ccColumns}
              />
            </div>
            <ListGroup.Item>
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() => downloadRatesheet(loan)}
              >
                Download Ratesheet
              </Button>{" "}
            </ListGroup.Item>
            <Modal show={showFileModal} onHide={handleCloseFileModal}>
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Something was wrong with downloading function. Please contact
                Zeitro team.
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </ListGroup>
        </div>
      );
    };

    const pointsPopover = (
      <Popover className="pointsPopover">
        <Popover.Title
          className="pointsPopoverTitle"
          style={{ fontSize: "13px" }}
        >
          Mortgage points are the fees a borrower pays a mortgage lender to
          lower the interest rate on the loan. This is sometimes called "buying
          down the rate". Try moving the slider to see more rates options.
        </Popover.Title>
        <Popover.Content className="text-center">
          <img src={require("./points.gif")} alt="loading..." width={"300px"} />
        </Popover.Content>
      </Popover>
    );
    return (
      <Container className="text-center p-3" style={cardStyle}>
        <Row>
          <Col className="text-left" md>
            <Row style={titleStyle}>Loan Type</Row>
            {type.includes("fixed") ? (
              <Row style={textStyle}>{loan.Term} Year Fixed</Row>
            ) : (
              <Row style={textStyle}>
                {loan.Arm.fixedperiod}/{loan.Arm.adjustmentperiod} ARM
              </Row>
            )}
          </Col>

          <Col md>
            <Row style={titleStyle}>Rate/APR</Row>
            <Row style={textStyle}>
              {loan.base_rate.toFixed(3)}%/{loan.APR.toFixed(3)}%
            </Row>
          </Col>

          <Col md>
            <Row style={titleStyle}>Monthly Pay</Row>
            <Row style={textStyle}>
              ${com.commaizeFloat(loan.monthly_payment.toFixed(2))}
            </Row>
          </Col>

          <Col md>
            <Row style={titleStyle}>
              <OverlayTrigger
                trigger="hover"
                placement="top"
                overlay={pointsPopover}
              >
                <a>
                  Points{" "}
                  <i class="fa fa-question-circle" aria-hidden="true"></i>
                </a>
              </OverlayTrigger>
            </Row>
            <Row style={textStyle}>
              ${com.commaizeFloat(loan.lender_credit.toFixed(0))}
            </Row>
            <HorizontalSimpleSlider
              id={"yearstohold" + type}
              onUpdate={cbfunc}
              onChange={cbfunc}
              step={1}
              domain={[1, allloans.length]}
              values={[Math.min(curindex, allloans.length)]}
            />
          </Col>
        </Row>
        {showDetails ? (
          <div>
            <br /> {getDetails(loan)}
          </div>
        ) : (
          ""
        )}
      </Container>
    );
  };

  let showCards = () => {
    let s = [];
    selectedTerms.forEach((t) => {
      s.push(<br></br>);
      s.push(showCard(t.key));
    });
    return s;
  };

  let multiSelectStyle = {
    chips: {
      background: "#505050",
      fontSize: "10px"
    },
    multiselectContainer: {
      color: "black",
    },
    searchBox: {
      border: "none",
      "border-bottom": "2px solid black",
      "border-radius": "0px",
    },
    option: {
      background: "white",
      color: "black",
    },
  };

  let onSelectTerms = (selectedList, selectedItem) => {
    setSelectedTerms(selectedList);
  };

  let onRemoveTerms = (selectedList, removedItem) => {
    setSelectedTerms(selectedList);
  };

  let onSelectAUS = (selectedList, selectedItem) => {
    setSelectedAUS(selectedList);
  };

  let onRemoveAUS = (selectedList, removedItem) => {
    setSelectedAUS(selectedList);
  };

  let handleChangeUnderwritingOptions = (e) => {
    setSelectedAUS(e)
  }

  let onMoreOptions = (e) => {
    if (showMoreOptions) {
      setShowMoreOptions(false);
    } else {
      setShowMoreOptions(true);
    }
  };

  let getEditCostsModal = () => {
    let updateTotal = (f1, f2) => {
      let t = 0;
      let all = [...f1, ...f2];
      for (let fe of all) {
        t += parseFloat(fe.value);
      }
      setEstOtherCosts(t);
    };

    let handleCannotshop = (i, e) => {
      let newFormValues = [...cannotshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };

    let addCannotshop = () => {
      setCannotshop([...cannotshop, { name: "", value: "" }]);
    };

    let removeCannotshop = (i) => {
      let newFormValues = [...cannotshop];
      newFormValues.splice(i, 1);
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };
    let handleCanshop = (i, e) => {
      let newFormValues = [...canshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };

    let addCanshop = () => {
      setCanshop([...canshop, { name: "", value: "" }]);
    };

    let removeCanshop = (i) => {
      let newFormValues = [...canshop];
      newFormValues.splice(i, 1);
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };

    return (
      <Modal
        show={showEditCostDetails}
        onHide={handleEditCostDetailsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Fees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h3>
              <b>Services you cannot shop for</b>
            </h3>
            {cannotshop.map((element, index) => (
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleCannotshop(index, e)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    name="value"
                    type="number"
                    value={element.value || ""}
                    onChange={(e) => handleCannotshop(index, e)}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => removeCannotshop(index)}
                    variant="danger"
                  >
                    X
                  </Button>
                </Col>
              </Form.Group>
            ))}
            <Button variant="link" onClick={() => addCannotshop()}>
              Add More Fees
            </Button>
            <h3>
              <b>Services you can shop for</b>
            </h3>
            {canshop.map((element, index) => (
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleCanshop(index, e)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    name="value"
                    type="number"
                    value={element.value || ""}
                    onChange={(e) => handleCanshop(index, e)}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => removeCanshop(index)}
                    variant="danger"
                  >
                    X
                  </Button>
                </Col>
              </Form.Group>
            ))}
            <Button variant="link" onClick={() => addCanshop()}>
              Add More Fees
            </Button>
          </Form>
          <h3>
            <b>Total estimated fees: ${com.commaize(estOtherCosts)}</b>
          </h3>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={handleEditCostDetailsModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditCostDetailsModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const updateProperValue = (e) => {
    let val = e.target.value
    if (val==="") {
      setPropertyValue(100000)
    } else {
      setPropertyValue(parseInt(val))
    }
  }

  const updateDownpayment = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(Math.round(propertyValue * 0.03))
    } else {
      setDownpayment(parseInt(val))
    }
  }
  const updateLTV = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(Math.round(propertyValue))
    } else {
      let ltv = parseInt(val)
      if (ltv <= 0) { // TBD
        setDownpayment(Math.round(propertyValue * 0.9))
      } else if (ltv > 97) {
        setDownpayment(Math.round(propertyValue * 0.03))
      } else {
        setDownpayment(Math.round(propertyValue * (1 - 0.01 * ltv)))
      }
    }
  }

  const updateDTI = (e) => {
    let val = e.target.value
    if (val==="") {
      setDTI(0)
    } else {
      setDTI(parseInt(val))
    }
  }
  const getDownpaymentDomain = () => {
    if (purpose === "Purchase") {
      return [propertyValue*0.03, propertyValue*0.6]
    } else {
      return [0, propertyValue*0.97]
    }
  }
  return (
    <div
      id="contain"
      ref={ref}
      style={{ color: "black" }}
      className="w-100 text-center "
    >
      <Container className="p-1">
        <div className="text-center" md="auto">
          <Form
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Col md>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Loan Purpose
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    onChange={(e) => setPurpose(e.target.value)}
                    required
                  >
                    <option value="Purchase">Purchase</option>
                    <option value="RateTermRefi">Refinance</option>
                    <option value="CashOutRefi">Cashout Refinance</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    {purpose === "Purchase"
                      ? "Est. Purchase Price"
                      : "Home Value"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign">$</div>
                      <CommaizedInput style={{border:"none", maxWidth:"100px"}} value={propertyValue} onChange={updateProperValue} />

                    <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div>
                  </div>
                </Form.Group>

              </Col>

              <Col xs>
                <Form.Group className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0" }}
                  >
                    {purpose === "Purchase"
                      ? "Down Payment"
                      : "Mortgage Balance"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign">$</div>
                    <CommaizedInput style={{border:"none", maxWidth:"78px"}} value={downpayment} onChange={updateDownpayment} />
                    <div className="slash">/</div>
                    <input className="ltv" type="number" style={{maxWidth:"38px"}} value={getLtv()[0]} onChange={updateLTV}/>
                    <div className="ltvSuffix">% LTV</div>
                    <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={getDownpaymentDomain()} defaultValues={[downpayment]} onChange={setDownpayment} onUpdate={setDownpayment} step={1000}/></div>
                  </div>
                </Form.Group>
              </Col>
              <Col xs>
                <Form.Group className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0" }}
                  >
                    DTI
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <input className="ltv" style={{height:"43px"}} type="number" value={dti} onChange={updateDTI}/>
                    <div className="ltvSuffix">%</div>
                    <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[0, 100]} defaultValues={[dti]} onChange={(e)=>{setDTI(e[0])}} onUpdate={setDTI} step={1}/></div>
                  </div>
                </Form.Group>
              </Col>

            </Form.Row>

            <Form.Row>
              <Col md>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>State</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    id="state"
                    onChange={(e) => setState(e.target.value)}
                    required
                  >
                  {showStates()}
                  </Form.Control>
                </Form.Group>
              </Col>
              {state !== "TEXAS" ? (
                <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      County
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      id="country"
                      name="country"
                      onChange={(e) => setCounty(e.target.value)}
                      required
                    >
                      {showCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md>
                <Form.Group controlId="propertytype" className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Property Type
                  </Form.Label>
                  <Form.Control
                    name="propertytype"
                    as="select"
                    size="sm"
                    type="text"
                    required
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                  >
                    <option value="singlefamilydetached">
                      Single Family Home, Detached
                    </option>
                    <option value="singlefamilyattached">
                      Single Family Home, Attached
                    </option>
                    <option value="condominiumdetached">
                      Condominium, Detached
                    </option>
                    <option value="condominium">Condominium, Attached</option>
                    <option value="pudattached">
                      Planned Unit Development, attached
                    </option>
                    <option value="puddetached">
                      Planned Unit Development, detached
                    </option>
                    <option value="twotofourfamily">
                      2 to 4 Unit Multifamily
                    </option>
                    <option value="manufacturedhome">Manufactured Home</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {propertyType === "twotofourfamily" ? (
                <Col md>
                  <Form.Group controlId="propertytype" className="text-left ">
                    Number of Units
                    <Form.Control
                      name="propertytype"
                      as="select"
                      size="sm"
                      type="text"
                      required
                      value={units}
                      onChange={(e) => setUnits(e.target.value)}
                    >
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md>
                <Form.Group controlId="occupancy" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0" }}
                  >
                    Occupancy
                  </Form.Label>
                  <Form.Control
                    name="occupancy"
                    as="select"
                    size="sm"
                    defaultValue={occupancy}
                    onChange={(e) => {
                      onOccupancy(e.target.value);
                    }}
                  >
                    <option value="principal">Primary Residence</option>
                    <option value="secondhome">Second Home</option>
                    <option value="investment">Investment</option>
                  </Form.Control>
                </Form.Group>
              </Col>

            </Form.Row>

            <Form.Row>
            <Col md>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Credit Score
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="input"
                    onChange={(e) => e.target.value===""? setFico(0) : setFico(parseInt(e.target.value))}
                    value={fico}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
            <Col md >
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Lock Day
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={(e) => setLockDay(parseInt(e.target.value))}
                      value={lockDay}
                      required
                    >
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                      <option value="60">60</option>
                      <option value="75">75</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              <Col md >
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Estimated Fees (LE part B+C){" "}
                    <a
                      onClick={handleEditCostDetails}
                      style={{
                        color: "blue",
                        textDecorationLine: "underline",
                      }}
                    >
                      <b>EDIT</b>
                    </a>
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    value={estOtherCosts}
                    required
                    onChange={(e) => setEstOtherCosts(e.target.value)}
                    min={0}
                    noarrow={true}
                  />
                </Form.Group>
              </Col>
              {/* <Col md style={{maxWidth: "500px"}}>
                <Form.Group controlId="selectedterms" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0" }}
                  >
                    Loan Types
                  </Form.Label>
                  <Multiselect
                    options={termOptions} // Options to display in the dropdown
                    selectedValues={preselectedTermOptions} // Preselected value to persist in dropdown
                    onSelect={onSelectTerms} // Function will trigger on select event
                    onRemove={onRemoveTerms} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    style={multiSelectStyle}
                    placeholder="Select More"
                  />
                </Form.Group>
              </Col> */}
              <Col >
                <Form.Group controlId="selectedterms" className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Underwriting
                  </Form.Label>
                <Select
                  defaultValue={underwritingOptions}
                  isMulti
                  name="colors"
                  options={underwritingOptions}
                  className="rateQuoterTermsForm"
                  classNamePrefix="rateQuoterMultiSelect"
                  closeMenuOnSelect={false}
                  isClearable={false}
                  onChange={handleChangeUnderwritingOptions}
                />
                </Form.Group>
              </Col>
            </Form.Row>

            {showMoreOptions && (
              <Form.Row>
              <Col md>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Second Mortgage Loan Amount
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    value={secondMortageAmount}
                    required
                    onChange={(e) => setSecondMortgageAmount(e.target.value)}
                    min={0}
                    step={10000}
                    noarrow={true}
                  />
                </Form.Group>
              </Col>

                <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Citizenship
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={(e) => setCitizenship(e.target.value)}
                      value={citizenship}
                      required
                    >
                      <option value="US_CITIZENS">US Citizen</option>
                      <option value="PERMANENT_RESIDENT_ALIEN">
                        Permanet Resident Alien
                      </option>
                      <option value="NON_RESIDENT_ALIEN">
                        Non Resident Alien
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {citizenship !== "US_CITIZENS" && (
                  <Col md>
                    <Form.Group className="text-left ">
                      <Form.Label style={{ marginBottom: "0" }}>
                        Visa Type
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        onChange={(e) => setVisaType(e.target.value)}
                        value={visaType}
                        required
                      >
                        <option value="A_1"> A-1</option>
                        <option value="A_2"> A-2</option>
                        <option value="A_3"> A-3</option>
                        <option value="B_1"> B-1</option>
                        <option value="B_2"> B-2</option>
                        <option value="B_3"> B-3</option>
                        <option value="C_1"> C-1</option>
                        <option value="C_2"> C-2</option>
                        <option value="C_3"> C-3</option>
                        <option value="C_4"> C-4</option>
                        <option value="C_1D"> C-1D</option>
                        <option value="CP"> CP</option>
                        <option value="D_1"> D-1</option>
                        <option value="D_2"> D-2</option>
                        <option value="E_1"> E-1</option>
                        <option value="E_2"> E-2</option>
                        <option value="E_3"> E-3</option>
                        <option value="F_1"> F-1</option>
                        <option value="F_2"> F-2</option>
                        <option value="F_3"> F-3</option>
                        <option value="G_1"> G-1</option>
                        <option value="G_2"> G-2</option>
                        <option value="G_3"> G-3</option>
                        <option value="G_4"> G-4</option>
                        <option value="G_5"> G-5</option>
                        <option value="H_1A"> H-1A</option>
                        <option value="H_1B"> H-1B</option>
                        <option value="H_1B1"> H-1B1</option>
                        <option value="H_1C"> H-1C</option>
                        <option value="H_2"> H-2</option>
                        <option value="H_2A"> H-2A</option>
                        <option value="H_2B"> H-2B</option>
                        <option value="H_3"> H-3</option>
                        <option value="H_4"> H-4</option>
                        <option value="I"> I </option>
                        <option value="OR_1"> OR-1</option>
                        <option value="J_1"> J-1</option>
                        <option value="J_2"> J-2</option>
                        <option value="K_1"> K-1</option>
                        <option value="K_2"> K-2</option>
                        <option value="K_3"> K-3</option>
                        <option value="K_4"> K-4</option>
                        <option value="L_1"> L-1</option>
                        <option value="L_2"> L-2</option>
                        <option value="M_1"> M-1</option>
                        <option value="M_2"> M-2</option>
                        <option value="M_3"> M-3</option>
                        <option value="N_8"> N-8</option>
                        <option value="N_9"> N-9</option>
                        <option value="NACARA"> NACARA</option>
                        <option value="NATO_1_6"> NATO-1-6</option>
                        <option value="NATO_7"> NATO-7</option>
                        <option value="O_1"> O-1</option>
                        <option value="O_2"> O-2</option>
                        <option value="O_3"> O-3</option>
                        <option value="P_1"> P-1</option>
                        <option value="P_2"> P-2</option>
                        <option value="P_3"> P-3</option>
                        <option value="P_4"> P-4</option>
                        <option value="Q_1"> Q-1</option>
                        <option value="Q_2"> Q-2</option>
                        <option value="Q_3"> Q-3</option>
                        <option value="R_1"> R-1</option>
                        <option value="R_2"> R-2</option>
                        <option value="S_5"> S-5</option>
                        <option value="S_6"> S-6</option>
                        <option value="S_7"> S-7</option>
                        <option value="T_1"> T-1</option>
                        <option value="T_2"> T-2</option>
                        <option value="T_3"> T-3</option>
                        <option value="T_4"> T-4</option>
                        <option value="TD"> TD</option>
                        <option value="TN_1"> TN-1</option>
                        <option value="TN_2"> TN-2</option>
                        <option value="U_1"> U-1</option>
                        <option value="U_2"> U-2</option>
                        <option value="U_3"> U-3</option>
                        <option value="U_4"> U-4</option>
                        <option value="V_1"> V-1</option>
                        <option value="V_2"> V-2</option>
                        <option value="V_3"> V-3</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}
                <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Number Of Owned Properties
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="input"
                      onChange={(e) =>
                        setNumberOfOwnedProperties(
                          e.target.value !== "" ? parseInt(e.target.value) : 0
                        )
                      }
                      value={numberOfOwnedProperties}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Current Job In Month
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="input"
                      onChange={(e) =>
                        setCurrentJobInMonths(
                          parseInt(e.target.value !== "" ? e.target.value : 0)
                        )
                      }
                      value={currentJobInMonths}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            )}
            {showMoreOptions && (
              <div>
              <Form.Row>
                <Col>
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="Self Employed" defaultChecked={selfEmployed} onClick={(e) => {setSelfEmployed(e.target.checked)}}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="Rental Income Used" defaultChecked={rentalIncomeUsed} onClick={(e) => {setRentalIncomeUsed(e.target.checked)}}/>
                  </Form.Group>
                </Col>
                <Col >
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="RUS As Income" defaultChecked={rsuAsIncome} onClick={(e) => {setRsuAsIncome(e.target.checked)}}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="Gift Fund Used" defaultChecked={giftFundUsed} onClick={(e) => {setGiftFundUsed(e.target.checked)}}/>
                  </Form.Group>
                </Col>
                <Col lg="auto">
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="Work for Small or Family Business" defaultChecked={workForSpecialBusiness} onClick={(e) => {setWorkForSpecialBusiness(e.target.checked)}}/>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="First Time Home Buyer" defaultChecked={firstTimeHomeBuyer} onClick={(e) => {setfirstTimeHomeBuyer(e.target.checked)}}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="Escrow Waiver" defaultChecked={escrowWaiver} onClick={(e) => {setEscrowWaiver(e.target.checked)}}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="Appraisal Waiver" defaultChecked={appraisalWaiver} onClick={(e) => {setAppraisalWaiver(e.target.checked)}}/>
                  </Form.Group>
                </Col>
                <Col  lg="auto">
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="Non-Occupancy Co-Borrower" defaultChecked={nonOccupancyCoBorrower} onClick={(e) => {setNonOccupancyCoBorrower(e.target.checked)}}/>
                  </Form.Group>
                </Col>
                <Col  lg="auto">
                  <Form.Group className="text-left ">
                    <Form.Check type="checkbox" label="Borrower Paid Compensation" defaultChecked={borrowerPaidCompensation} onClick={(e) => {
                      setBorrowerPaidCompensation(e.target.checked)
                      if (!e.target.checked) setBorrowerPaidCompensationAmount(0)
                    }}/>
                    {borrowerPaidCompensation && <Form.Control
                      size="sm"
                      as="input"
                      onChange={(e) =>
                        setBorrowerPaidCompensationAmount(
                          parseInt(e.target.value !== "" ? e.target.value : 0)
                        )
                      }
                      value={borrowerPaidCompensationAmount}
                      required
                    />}
                  </Form.Group>
                </Col>
              </Form.Row>
              </div>
            )}
            <Form.Row>
                <Button
                  variant="rateQuoterAdvancedOptions"
                  onClick={onMoreOptions}
                  size="sm"
                >
                  {showMoreOptions ? <>Less Options<img src="/images/blueMinusInCircleMark.png" style={{maxWidth:"16px", paddingLeft:"5px"}}/></> : <>Advanced Options<img src="/images/bluePlusInCircleMark.png" style={{maxWidth:"16px", paddingLeft:"5px"}}/></>}
                  
                </Button>{" "}
            </Form.Row>
            <hr className="advancedOptionsDivider" />
            <Form.Row>
              <Col xs className="text-center w-100">
                <Button type="submit" size="sm">
                  Update Results
                </Button>{" "}
                {Object.keys(ineligibleProducts).length > 0 && (
                  <Button
                    variant="rateQuoterUpdateButton"
                    style={{backgroundColor:"grey"}}
                    size="sm"
                    onClick={() => setShowIneligible(true)}
                  >
                    Check Ineligible Products
                  </Button>
                )}
              </Col>
            </Form.Row>
          </Form>
        </div>
      </Container>

      {loading ? (
        <Row>
          <div className="text-center w100 justify-content-center  align-content-center">
            <div id="spinner" className="pt-5 mt-4 ml-5 text-center">
              <img
                alt="spinner"
                className="loader mt-5"
                src={
                  com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"
                }
              ></img>
            </div>
          </div>
        </Row>
      ) : (
        noProducts ? <YellowWarning text="No qualified products."/> :
        <Container>
          <AllRatesTable
          allRates={allRates}
          borrowerPaidCompensationAmount={borrowerPaidCompensationAmount}
          loanInfo={{loanAmount: getLoanAmount(), occupancy: occupancy, purpose: purpose, propertyType: propertyType, fico:fico}}
        />
        </Container>
      )}

      {showReportIssues && (
        <ReportIssuesModal
          show={true}
          onHide={() => setShowReportIssues(false)}
        />
      )}
      {showIneligible && (
        <IneligibleProductsModal
          show={true}
          onHide={() => setShowIneligible(false)}
          ineligibleProducts={ineligibleProducts}
          message={message}
        />
      )}
      {showEditCostDetails ? getEditCostsModal() : ""}
    </div>
  );
};
export default withRouter(RateQuoteLOVersion);
