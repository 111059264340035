import React, { useState, useEffect } from "react";
import { ListFormat } from "typescript";
import { commaizeFloat } from "../../Common";
import "./Preview.css";

function RatesPreview ({config}) {
  const [loading, setLoading] = useState(false);
  const [sampleRates, setSampleRates] = useState({});

  const getSampleRates = () => {
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getsamplerates", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false)
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          let r = JSON.parse(js);
          setSampleRates(r);
          setLoading(false)
        });
      })
      .catch(function (err) {
        setLoading(false)
        alert("Fetch Error :-S", err);
      });
  }


  useEffect(()=>{
    getSampleRates()
  }, [config])

  const showRates = () => {
    let out = []
    let ordered = []
    for (let prd in sampleRates) {
      let order = 0
      if (prd==="Conforming") order = 1
      if (prd==="High Balance") order = 2
      if (prd==="Jumbo") order = 3
      if (prd==="FHA") order = 4
      ordered.push({prd: prd, order: order, data: sampleRates[prd]})
    }
    ordered.sort((a,b)=>(a.order>b.order?1:-1))
    
    for (let p of ordered) {
        let ratesCards = []
        for (let termtype in p.data) {
            let d = p.data[termtype]
            ratesCards.push(
                <div className="ratesCard">
                    <div className="termType"><span>{termtype}</span></div>
                    <div className="rateApr">{d.BaseRate.toFixed(3)}/{d.APR}</div>
                    <div className="rateAprTitle">Rate/APR</div>
                    <div className="costsRow">
                        <div className="costsCol rightBorder">
                            <div className="costsColTitle" >Monthly Payment</div>
                            <div className="costs" >${commaizeFloat(d.MonthlyPayment)}</div>
                        </div>
                        <div className="costsCol">
                            <div className="costsColTitle" >Points</div>
                            <div className="costs" >${commaizeFloat(d.Points>0?Math.round(d.Points):0)}</div>
                        </div>
                    </div>
                </div>
            )
        }
        if (config[p.prd]!==undefined) {
          out.push(
            <div className="product">
                <div className="productTitle">{p.prd}</div>
                <div className="productAssumption">Assumption: loan amount ${commaizeFloat(config[p.prd].loanAmount)} | FICO {config[p.prd].fico} | LTV {config[p.prd].ltv}%</div>
                <div className="productRates">{ratesCards}</div>
                
            </div>
        )
      }
    }
    return out
  }

  return <div className="ratesPreview">{loading?<p>Still loading...</p>:showRates()}</div>;
};
export default RatesPreview;
