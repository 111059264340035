import React, { Component } from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
import '../Utils.css'
import * as com from "../Common"

class Integration extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }

  }
  componentDidMount() {
    this.setState({
      loginpath: com.returnURLs.loginpath,
      logoutpath: com.returnURLs.logoutpath,
      resettingpath: com.returnURLs.resettingpath,
      handoffpath: com.returnURLs.handoffpath,

    })

  }
  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    let token = sessionStorage.getItem("ZeitroA")
    let body = JSON.stringify(this.state)
  
    fetch('/los/updatereturnpaths', {
      method: 'POST',
      body,
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          alert('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        alert("Paths updated successfully")
        com.updateReturnUrls(  this.state  )
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });

    return false
  }

  render() {
    function copyToClipboard(id)  {      
      return e => {
        let ev = document.getElementById(id)

        navigator.clipboard.writeText(ev.textContent)
      }
    }
    let displayCard = (verbiage, id) => {
      return <Row className="mt-2 text-left viewwindow mx-4 px-2 ">
        <Col xs="auto" className="pr-0 mr-0 px-0 mx-0" >
          <div className=""> {verbiage}</div>
          <div style={{ fontWeight: 'bold' }} className="mt-1 d-flex p-0 m-0">
            <div className="text-wrap text-fixed" id={id}>&lt;script id="zeitrotag" src={com.returnURLs.appurl + "/api/zeitrotag.js?customerid=" + com.getCustomerId() + "&loid=&pageid=" + id}&gt;
              &lt;/script&gt;
            </div>
            <Button className="p-0 m-0 ml-1" onClick={copyToClipboard( id )} variant="link"><i class=" fa fa-copy " ></i></Button>
          </div>
        </Col>

      </Row>
    }

    let onChange = e => {
       let name = e.target.id
       let value =  e.target.value
       let j = {}
        j[name] = value
        this.setState(j)
    }
    let updatePath = (text, name) => {
      return <Row className="p-1">
        <Col xs={12} className="ml-3">
          {text}
        </Col>
        <Col xs={12} >
          <Form.Control
            size="sm"
            style={{ width: '40em' }}
            required
            id={name}
            value={this.state[name]}
            onChange={onChange}
            type="text"
            pattern="^.+$"
          />
        </Col>
        <Col></Col>
      </Row>
    }
    return (
      <div className="text-left">
        <h5 className="ml-4 mt-3">Foundational Integration Pages:</h5>
        {displayCard(<span>JavaScript Tag to inject into the <b>Login</b> page. We recommend for this page
          to have a link to the Sign Up page, and Password Reset page.</span>, "login")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Signup</b> page. We recommend for this page
          to have a link to the Signup page.</span>, "signup")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Resend Account Valudation Email</b> page.
          This is a rarely used page that is used after signup if account validation email did not arrive.</span>, "revalidate")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Account Handoff</b> page.</span>, "handoff")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Password Reset Request</b> page.</span>, "recover")}

        {displayCard(<span>JavaScript Tag to inject into the <b>New Password</b> page.</span>, "resetpassword")}

        <h5 className="ml-4 mt-5">Return pages paths:</h5>
        <Form id="myform" noValidate validated={false} onSubmit={this.handleSubmit}>
          {updatePath("Login page:", "loginpath") }
          {updatePath("Logout page:", "logoutpath") }    
          {updatePath("Password reset:", "resettingpath") }       
          {updatePath("Handoff:", "handoffpath") }     
          <Row className="mt-3">
            <Col xs="2">
            </Col>
            <Col xs="auto">
              <Button type="submit">Submit</Button>  
            </Col>            
          </Row>      
                     
        </Form>

        <h5 className="ml-4 mt-5">Customer Research Resource Pages:</h5>

        {displayCard(<span>JavaScript Tag to inject into the <b>Mortgage Calculator</b> page.</span>, "calculator")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Historic Rates.</b> page.</span>, "historicalchart")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Home Affordability Tool.</b> page.</span>, "affordability")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Refinance Tool</b> page.</span>, "refinance")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Purchase Tool</b> page.</span>, "purchase")}

        {displayCard(<span>JavaScript Tag to inject into the <b>Today's Best Rates</b>.</span>, "todayrates")}
        <div className="mt-3">&nbsp;</div>
      </div>


    )
  }
}

export default Integration