import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import EmailSetup from './EmailSetup';
import SMSSetup from './SMSSetup';
import Emails from '../Customization/Communication/Emails';
import SMS from '../Customization/Communication/SMS';
import Other from '../Customization/Other/Other';

const CommunicationSetup = () => {
  return (
    <Tabs mountOnEnter={true} unmountOnExit="true">
      <Tab eventKey="emailsetup" title="Email Setup">
        <EmailSetup />
      </Tab>
      <Tab eventKey="smssetup" title="SMS Setup">
        <SMSSetup />
      </Tab>
      <Tab eventKey="email" title="Email Templates">
        <Emails />
      </Tab>
      <Tab eventKey="sms" title="SMS Templates">
        <SMS />
      </Tab>
      <Tab eventKey="other" title="Other">
        <Other />
      </Tab>
    </Tabs>
  );
}

export default CommunicationSetup;