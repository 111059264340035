import React, {useEffect, useState} from 'react';
import {withRouter, useParams} from 'react-router-dom';
import {Skeleton, Input} from "antd"
import './Chat.css';
import MarkdownRenderer from './MarkdownRender';

function ShareChat() {
    const [loadingShare, setLoadingShare] = useState(false)
    const [chatData, setChatData] = useState([])
    const { threadid } = useParams();

    useEffect(()=>{
        setLoadingShare(true)
        let body = { ThreadID: threadid }
        fetch('/los/mortgagegpt/sharechat', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.error('Looks like there was a problem. Status Code: ' + response.status)
                    setLoadingShare(false)
                    alert("Something went wrong, please try again later")
                    return;
                }
                response.json().then(js => {
                    setChatData(js.chat_history)
                    setLoadingShare(false)

                });
            }
        ).catch(function (err) {
            setLoadingShare(false)
            console.log('Fetch Error :-S', err);
        });
    },[])

    return (
        <div className='shareChatWrap'>
            {loadingShare ? (
                <Skeleton active />
            ) : (
                <div className="shareChatContainer">
                    {chatData.map((msg, index) => {
                        // Check if the message is from the user
                        if (msg.role === "user") {
                            return (
                                <div className="shareChatUser" key={index}>
                                    <img
                                        src="/images/chatbotuseravatar.png"
                                        style={{ width: 30, height: 30, marginRight: 10 }}
                                        alt="User Avatar"
                                    />
                                    <div>
                                        <div className="bold">You</div>
                                        <div>{msg.content}</div>
                                    </div>
                                </div>
                            );
                        } else {
                            // Message from the bot
                            return (
                                <div className="shareChatBot" key={index}>
                                    <img
                                        src="/images/chatbot.png"
                                        style={{ width: 30, height: 30, marginRight: 10 }}
                                        alt="Bot Avatar"
                                    />
                                    <div>
                                        <div className="bold">GuidelineGPT</div>
                                        <MarkdownRenderer answer={msg.content} referenceFunc={() => {}} />
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            )}
        </div>
    );
}
export default withRouter(ShareChat);