import React from 'react';
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap';
import './App.css';
import Menu from "./Menu.js"
import Copyright from "./Copyright.js"
import * as com from './Common';

const Loading = (props) => {

  return (
    <div className=" py-0 my-0">
      <Menu />
      <div id="landing1" className="container my-5" >
        <div className="mt-5">&nbsp;</div>
        <div className="mt-5">&nbsp;</div>
        <Spinner animation='border' variant='primary' ></Spinner>
        <div className="mt-5">&nbsp;</div>

      </div>

      <Copyright />

    </div>
  )
}

export default Loading;
