import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

const Form1120 = ({ doc, onFocus, onChangeTextInput }) => {
  return (
    <>
      <Row>
        <Form.Group as={Col} controlId="Year">
          <Form.Label>Year</Form.Label>
          <Form.Control defaultValue={doc['Year']['Value']} type="text" onFocus={() => onFocus(doc['Year']['BBox'], doc['Year']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Year', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line8CapitalGainNetIncome">
          <Form.Label>Line 8: Capital Gain Net Income</Form.Label>
          <Form.Control defaultValue={doc['Line8CapitalGainNetIncome']['Value']} type="text" onFocus={() => onFocus(doc['Line8CapitalGainNetIncome']['BBox'], doc['Line8CapitalGainNetIncome']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line8CapitalGainNetIncome', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line9NetGainOrLoss">
          <Form.Label>Line 9: Net Gain Or Loss</Form.Label>
          <Form.Control defaultValue={doc['Line9NetGainOrLoss']['Value']} type="text" onFocus={() => onFocus(doc['Line9NetGainOrLoss']['BBox'], doc['Line9NetGainOrLoss']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line9NetGainOrLoss', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line20Depreciation">
          <Form.Label>Line 20: Depreciation</Form.Label>
          <Form.Control defaultValue={doc['Line20Depreciation']['Value']} type="text" onFocus={() => onFocus(doc['Line20Depreciation']['BBox'], doc['Line20Depreciation']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line20Depreciation', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line25DomesticProductionActivities">
          <Form.Label>Line 25: Domestic Production Activities</Form.Label>
          <Form.Control defaultValue={doc['Line25DomesticProductionActivities']['Value']} type="text" onFocus={() => onFocus(doc['Line25DomesticProductionActivities']['BBox'], doc['Line25DomesticProductionActivities']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line25DomesticProductionActivities', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line26Other">
          <Form.Label>Line 26: Other</Form.Label>
          <Form.Control defaultValue={doc['Line26Other']['Value']} type="text" onFocus={() => onFocus(doc['Line26Other']['BBox'], doc['Line26Other']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line26Other', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line29aNetOperatingLoss">
          <Form.Label>Line 29a: Net Operating Loss</Form.Label>
          <Form.Control defaultValue={doc['Line29aNetOperatingLoss']['Value']} type="text" onFocus={() => onFocus(doc['Line29aNetOperatingLoss']['BBox'], doc['Line29aNetOperatingLoss']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line29aNetOperatingLoss', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line29bSpecialDeduction">
          <Form.Label>Line 29b: Special Deduction</Form.Label>
          <Form.Control defaultValue={doc['Line29bSpecialDeduction']['Value']} type="text" onFocus={() => onFocus(doc['Line29bSpecialDeduction']['BBox'], doc['Line29bSpecialDeduction']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line29bSpecialDeduction', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line29cNetOperatingLossAndSpecialDeductions">
          <Form.Label>Line 29c: Net Operating Loss And Special Deductions</Form.Label>
          <Form.Control defaultValue={doc['Line29cNetOperatingLossAndSpecialDeductions']['Value']} type="text" onFocus={() => onFocus(doc['Line29cNetOperatingLossAndSpecialDeductions']['BBox'], doc['Line29cNetOperatingLossAndSpecialDeductions']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line29cNetOperatingLossAndSpecialDeductions', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line30TaxableIncome">
          <Form.Label>Line 30: Taxable Income</Form.Label>
          <Form.Control defaultValue={doc['Line30TaxableIncome']['Value']} type="text" onFocus={() => onFocus(doc['Line30TaxableIncome']['BBox'], doc['Line30TaxableIncome']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line30TaxableIncome', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="Line31TotalTax">
          <Form.Label>Line 31: Total Tax</Form.Label>
          <Form.Control defaultValue={doc['Line31TotalTax']['Value']} type="text" onFocus={() => onFocus(doc['Line31TotalTax']['BBox'], doc['Line31TotalTax']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line31TotalTax', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="Line32Depletion">
          <Form.Label>Line 32: Depletion</Form.Label>
          <Form.Control defaultValue={doc['Line32Depletion']['Value']} type="text" onFocus={() => onFocus(doc['Line32Depletion']['BBox'], doc['Line32Depletion']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['Line32Depletion', 'Value'])} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="SchLLine17dMortgagesOrNotesPayableInLessThan1Year">
          <Form.Label>Schedule L Line 17d: Mortgages Or Notes Payable In Less Than 1 Year</Form.Label>
          <Form.Control defaultValue={doc['SchLLine17dMortgagesOrNotesPayableInLessThan1Year']['Value']} type="text" onFocus={() => onFocus(doc['SchLLine17dMortgagesOrNotesPayableInLessThan1Year']['BBox'], doc['SchLLine17dMortgagesOrNotesPayableInLessThan1Year']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['SchLLine17dMortgagesOrNotesPayableInLessThan1Year', 'Value'])} />
        </Form.Group>

        <Form.Group as={Col} controlId="SchM1Line5cTravelAndEntertainmen">
          <Form.Label>Schedule M1 Line 5c: Travel And Entertainmen</Form.Label>
          <Form.Control defaultValue={doc['SchM1Line5cTravelAndEntertainmen']['Value']} type="text" onFocus={() => onFocus(doc['SchM1Line5cTravelAndEntertainmen']['BBox'], doc['SchM1Line5cTravelAndEntertainmen']['Page'])} onChange={e => onChangeTextInput('form1120.pdf', e.target.value, ['SchM1Line5cTravelAndEntertainmen', 'Value'])} />
        </Form.Group>
      </Row>
    </>
  );
}

export default Form1120;