import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import NumericalInput from "../NumericalInput";
import { Link } from 'react-router-dom'
import * as com from "../Common.js"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import { tooltip } from '../Common/Tooltip'

import {
    getFees, getDTI, getCitizenship, getOccupancy, getPropertyType,
    productReload
} from './ProductPicker'
import { Skeleton } from "antd";
const mapStateToProps = (state) => {
    return {
        hascoborrower: state.application.hascoborrower,
        cohabiting: state.application.cohabiting,
        application: state.application,
        property: state.application.property,
        selection: state.application.selection,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changeBorrowerInfoCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },

    updatePreviousAddress: (event, who, verb, n) => {
        dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n))
    },
    addPreviousAddress: (event, who) => {
        dispatch(act.AddPreviousAddress(event.target.value, who,))
    },
    removePreviousAddress: (event, who, n) => {
        dispatch(act.RemovePreviousAddress(event.target.value, who, n))
    },
    clearPreviousAddress: (event, who) => {
        dispatch(act.ClearPreviousAddress(event.target.value, who))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
});


class Prequal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingPdf: false,
            purpose: "Purchase", // propagated
            loansize: 0.8 * com.safeParseInt(this.props.property.salesprice),
            show: false,
            download: "",            
            url: ""
        }
        this.aref = React.createRef();

        this.Loan25 = null
        this.Loan20 = null
        this.Loan10 = null
        // this.fhaLoan = null
        this.count = 0
    }
    componentDidMount() {
        this.getFees(this.props.loanid)
    }
    getFees = getFees.bind(this)
    getDTI = getDTI.bind(this)
    getCitizenship = getCitizenship.bind(this)
    getOccupancy = getOccupancy.bind(this)
    getPropertyType = getPropertyType.bind(this)
    productReload = productReload.bind(this)

    getLTV = () => {
        let loansize = this.state.loansize
        let ltv = com.safeParseInt(loansize) * 100 / com.safeParseInt(this.props.property.salesprice)
        let cltv = (com.safeParseInt(loansize) + com.safeParseInt(this.cltv)) * 100 / com.safeParseInt(this.props.property.salesprice)
        let hcltv = (com.safeParseInt(loansize) + com.safeParseInt(this.hcltv)) * 100 / com.safeParseInt(this.props.property.salesprice)
        return [ltv, cltv, hcltv]
    }

    askedForAssistance = () => false
    failedProduct = () => {
        
        switch (this.count) {
            case 0: {
                this.count = 1
                let newloan = parseInt(0.90 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees(this.props.loanid)
                })
                break
            }
            case 1: {
                this.count = 2
                let newloan = parseInt(0.965 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees(this.props.loanid)
                })
                break
            }
            case 2: {
                this.setState({ loading: false })
                this.count = 3
                let newloan = parseInt(0.75 * com.safeParseInt(this.props.property.salesprice) )
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees(this.props.loanid)
                })                
                break
            }
            default:
                break
        }        
    }
    renderProduct = (pr, ltv) => {
        let getType = (card) => {
            if (card.Arm === null || card.Arm === 0)
                return "Fixed rate"
            return "ARM " + card.Arm.fixedperiod + "/6"
        }

        let getPrequal = () => {
            let token = com.getUserToken()
            let disposition
            let loansize = parseInt(com.safeParseInt(this.props.property.salesprice) * ltv)
            let downpayment = parseInt(com.safeParseInt(this.props.property.salesprice) - loansize)
            this.props.updateSelectField( downpayment, "downpayment")
            this.props.updateSelectField( this.state.loansize, "loansize")
    
            let data = {
                price: this.props.property.salesprice.toString(),
                loan: (loansize).toString(),
                loanType: `${pr.Term} year ${getType(pr)}` ,
                isFHA: pr.FHAMIP != null     
            }
            this.setState({loadingPdf: true})
            fetch('/borrower/getprequal', {
              method: 'POST',
              headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
                "FromLO": "true",                
              },
              body: JSON.stringify(data),
            }).then(response => {
                disposition = response.headers.get("Content-Disposition").split('=')
    
                return response.body
            })
                .then(body => {
                    const reader = body.getReader();
    
                    return new ReadableStream({
                        start(controller) {
                            return pump();
    
                            function pump() {
                                return reader.read().then(({ done, value }) => {
                                    // When no more data needs to be consumed, close the stream
                                    if (done) {
                                        controller.close();
                                        return;
                                    }
    
                                    // Enqueue the next data chunk into our target stream
                                    controller.enqueue(value);
                                    return pump();
                                });
                            }
                        }
                    })
                })
                .then(stream => new Response(stream))
                .then(r => r.blob())
                .then(blob => {
    
    
                    let download = disposition.slice(1).join().replace(/"/g, '')
    
                    const url = window.URL.createObjectURL(blob);
                    this.setState({ url: url, download: download })
    
                    this.aref.current.click()
                    this.forceUpdate();
                    this.setState({loadingPdf: false})

                })
                .catch( (err) => {
                    alert('Error reading preaopproval '+ err);
                    this.setState({loadingPdf: false})

                });
  
        }
        
        return (
            <div className="drop-shadow  viewport p-3  mb-4 text-left">
                <Row>
                    <Col xs="auto">Name:</Col><Col>{pr.Name}</Col>
                </Row>
                <Row>
                    <Col xs="auto">Term:</Col><Col>{pr.Term} years</Col>
                </Row>
                <Row>
                    <Col xs="auto">Type:</Col><Col>{getType(pr)}</Col>
                </Row>
                <Row>
                    <Col xs="auto">Estimated Purchase Price:</Col><Col>${com.commaizeFloat(this.props.property.salesprice)}</Col>
                </Row>
                <Row>
                    <Col xs="auto">Loan Amount:</Col><Col><b>Up to ${com.commaizeFloat(com.safeParseInt(this.props.property.salesprice) * ltv)}</b></Col>
                </Row>
                <Row>
                    <Col xs="auto">Downpayment:</Col><Col>${com.commaizeFloat(Math.round((1 - ltv) * com.safeParseInt(this.props.property.salesprice) ))} / { (100*(1 - ltv)).toFixed(1) }%</Col>
                </Row>
                <Row>
                    <Col xs="auto">Estimated closing costs:</Col><Col>${com.commaizeFloat(pr.closing_cost > 0 ? com.safeParseInt(pr.closing_cost / 100) * 100 : 0)}</Col>
                </Row>
                <Row>
                    <Col xs="auto">Estimated monthly payment:</Col><Col>${com.commaizeFloat(pr.monthly_payment)}</Col>
                </Row>
                { pr.FHAMIP != null ? 
                    <div>
                        <Row>
                            <Col xs="auto">Mortgage Insurance Premium:</Col><Col>${com.commaizeFloat(pr.FHAMIP.upfrontMIP)}</Col>
                        </Row>                        
                        <Row>
                            <Col xs="auto">Mortgage Insurance Monthly Payment:</Col><Col>${com.commaizeFloat(pr.FHAMIP.monthlyMIP)}</Col>
                        </Row>                        
                    </div> : ""
                }
                <Row>
                    <Col xs="auto">Rate:</Col><Col>{pr.base_rate.toFixed(3)}%</Col>
                </Row>
                <Row>
                    <Col xs="auto">APR:</Col><Col>{pr.APR}%</Col>
                </Row>
                {pr.PMICost === 0 ? "" :
                    <div>
                        <Row>
                            <Col xs="auto">Private Mortgage Insurance:</Col><Col>${com.commaizeFloat(pr.PMICost)}/mo</Col>
                        </Row>
                    </div>
                }
                <Row><Col className="pt-2"><Button variant="primary" onClick={getPrequal}>{this.state.loadingPdf ?  <img src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"} alt="logo" style={{marginRight: '6px', opacity: '1', filter: 'invert(100%) grayscale(1)', width: '20px', height: '20px'}} className="loader" />: ""
} Download prequalification letter</Button></Col></Row>
            </div>
        )
    }
    renderPrequal = () => {

        if (this.Loan20 == null && this.Loan10 == null && this.Loan25 == null && !this.state.loading)
            return (
                <div className="viewport text-left">
                    We could not find loan products for you. Try reducing the target property price.
                </div>
            )

        let out = []
        if (this.Loan25 != null)
            out.push(this.renderProduct(this.Loan25, 0.75))
        if (this.Loan20 != null)
            out.push(this.renderProduct(this.Loan20, 0.8))
        if (this.Loan10 != null)
            out.push(this.renderProduct(this.Loan10, 0.9))
        // if (this.fhaLoan != null)
        //     out.push(this.renderProduct(this.fhaLoan, 0.965))

        return out
    }
    cullRate = products => {
        if (products.length === 0)
            return products

        let newproducts = []
        newproducts.push(products[0])
        let base_rate = products[0].base_rate

        for (let i = 1; i < products.length; i++) {
            if (products[i].base_rate !== base_rate) {
                newproducts.push(products[i])
                base_rate = products[i].base_rate
            } else {

            }
        }
        return newproducts
    }
    processProducts = (products) => {
        this.setState(
            { loading: true }
        )
        let rearranged = []
        products.forEach(product => {
            let lender = product.Lender

            product.ProductRatesCosts.forEach(pr => {
                let commonproduct = pr.product
                let ausengine = pr.AUSEngine

                if (!this.props.application.property.propertytype === "mobilehome" ) {
                    if (commonproduct.Term !== 30)
                        return
                }
                pr.rateCosts.forEach(rate => {

                    let typ = commonproduct.Arm
                    if (typ === null || typeof typ === "undefined")
                        typ = 0
                    else
                        typ = typ.fixedperiod
                    // filter out non-30 day locks
                    if (rate.lockDay !== 30)
                        return

                    let pdkt = {
                        Lender: lender,
                        ...commonproduct,
                        Aus: ausengine,
                        ...rate
                    }
                    rearranged.push(pdkt)
                })
            })
        });
        rearranged.sort(this.compareRate)
        
        rearranged = this.cullRate(rearranged)
        // let getBest = (rearranged) => {
        //     let mn = 100000000
        //     let loan = rearranged.pop()

        //     while (rearranged.length > 1) {
        //         let ln = rearranged.pop()
        //         if (ln.closing_cost < 0)
        //             continue
        //         let lrate = Math.log(ln.base_rate)
        //         let lcost = Math.log(ln.closing_cost)
        //         let bal = 5 * lrate + lcost

        //         if (bal < mn) {
        //             console.log("new min")
        //             mn = bal
        //             loan = ln
        //         }
        //     }
        //     return loan
        // }
        let getBest = (rearranged) => {
            let minAPR = 99.9
            let maxClosingCost = 10000
            let loan = null
            for (let ln of rearranged) {
                if (ln.closing_cost < 0)
                    continue
                if (ln.closing_cost < maxClosingCost && ln.APR < minAPR) {
                    minAPR = ln.APR
                    loan = ln
                }
            }

            if (loan !== null) {
                return loan
            }

            for (let ln of rearranged) {
                if (ln.closing_cost>0) {
                    return ln
                }
            }
            return rearranged[0]
        }
        
        switch (this.count) {
            case 0: {
                this.Loan20 = getBest(rearranged)
                let downpayment = Math.ceil( com.safeParseInt(this.props.property.salesprice) - this.state.loansize)
                this.props.updateSelectField( downpayment, "downpayment")
                this.props.updateSelectField( this.state.loansize, "loansize")
                this.count = 1
                let newloan = parseInt(0.90 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees(this.props.loanid)
                })
                break
            }
            case 1: {
                this.Loan10 = getBest(rearranged)
                this.count = 2
                let downpayment = Math.ceil(com.safeParseInt(this.props.property.salesprice) - this.state.loansize)
                this.props.updateSelectField( downpayment, "downpayment")
                this.props.updateSelectField( this.state.loansize, "loansize")
                let newloan = parseInt(0.75 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees(this.props.loanid)
                })
                break
            }
            case 2: {
                this.Loan25 = getBest(rearranged)
                let downpayment = Math.ceil(com.safeParseInt(this.props.property.salesprice) - this.state.loansize)
                this.props.updateSelectField( downpayment, "downpayment")
                this.props.updateSelectField( this.state.loansize, "loansize")
                this.setState({ loading: false })
                break
            }
            // case 3: {                
            //     this.Loan25 = getBest(rearranged)
            //     let downpayment = Math.ceil(com.safeParseInt(this.props.property.salesprice) - this.state.loansize)
            //     this.props.updateSelectField( downpayment, "downpayment")
            //     this.props.updateSelectField( this.state.loansize, "loansize")
            //     this.setState({ loading: false })
            //     break
            // }            
            default:
                break
        }
    }

    compareRate = (a, b) => {
        if (a.base_rate !== b.base_rate)
            return b.base_rate - a.base_rate
        return b.closing_cost - a.closing_cost // take into account remaining credits
    }

    handleSubmit = (e) => {
        

        if (this.state.loading === true) {
            e.preventDefault();
            e.stopPropagation()
            return false
        }
        return true
    }
    handleClose = () => { 
        this.setState({show: false, loansize: 0.8 * com.safeParseInt(this.props.property.salesprice)},
            () => {
                this.count = 0
                this.Loan25 = null
                this.Loan20 = null
                this.Loan10 = null
                // this.fhaLoan = null
                this.getFees(this.props.loanid)
            }
        )
    }

    render = () => {
        let qualifyForLess = () => {
            return <Button variant="link" onClick={e=>this.setState({show: true})}>Click here to try qualifying for less expensive property</Button>
        }
        let onPriceChange = (e) => {
            
            this.props.changeMainProperty(e.target.value, "salesprice")
            this.props.changeMainProperty(e.target.value, "appraisal")
            
        }        
        let errorMessage = () => {
            let msg=""
            switch (this.state.errorCode) {
                case "0":
                default:
                    msg = <div className='sectionquestion mt-5 text-wrap'>{this.state.errorText}</div>
                    break;
                case "1":
                    msg = <div className='sectionquestion mt-5 text-wrap'>Unfortunately, it looks like your FICO score is too low to qualify for a loan.</div>
                    break
                case "2":
                    msg = <div className='sectionquestion mt-5 text-wrap'>Unfortunately, your debt to income ratio is too high to qualify for a loan. <br />
                    {qualifyForLess()}</div>
                    break
                case "3":
                    msg = <div className='sectionquestion mt-5 text-wrap'>Unfortunately, your loan amount to property value ratio is too high to qualify for a loan. <br />
                    You may want to consider taking a smaller loan. {qualifyForLess()}</div>
                    break
                case "4":
                    msg = <div className='sectionquestion mt-5 text-wrap'>Unfortunately, your income is too low to qualify for a loan.</div>
                    break
                case "5":
                    msg = <div className='sectionquestion mt-5 text-wrap'>Unfortunately, we could not find a loan product for your particular situation. You may want to consider taking a smaller loan. {qualifyForLess()}</div>
                    break
                case "6":
                    msg = <div className='sectionquestion mt-5 text-wrap'>Unfortunately, your property is not qualified for a loan.</div>
                    break

            }
            return msg
        }
        return (
            <div className=" mb-4  text-center text-wrap" >
                <Modal show={this.state.show} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Enter the new target property price</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>  

                    <NumericalInput size="sm"

                        type="text"
                        name="description"
                        defaultValue={parseInt(this.props.property.salesprice)}
                        step={1000}
                        onChange={onPriceChange}

                        pattern="^([0-9]]+)$"

                        />


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <a hidden ref={this.aref} href={this.state.url} download={this.state.download}>&nbsp;</a>

                <div className="text-left ">
                    {this.Loan10 != null || this.Loan20 != null || this.Loan25 != null?
                        <div className="ml-4 mb-2">
                            <Row><Col>
                                {this.renderPrequal()}</Col></Row>


                        </div>

                        : ""}
                    <Row><Col className="text-center">
                        {this.state.loading ? <Skeleton className="m-5" /> : ""}
                    </Col></Row>
                    {this.Loan10 != null || this.Loan20 != null || this.Loan25 != null ?
                    "" : 
                    this.state.loading ? ""  : <div> 
                        <div></div>
                        <div className="ml-3">{errorMessage()}</div>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Prequal)
