import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover, Spin } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

export default function Achievement() {
    const { open, step, Lenders, isFinished, showAchievement } = useSelector(state => {


        return state.manager
    })
    const dispatch = useDispatch()
    const [LoanOfficersList, setLoanOfficersList] = useState([])
    const [CompanyInfo, setCompanyInfo] = useState(false)
    const [EmailInfo, setEmailInfo] = useState(false)
    const [WebsiteInfo, setWebsiteInfo] = useState(false)
    const [LendersList, setLenders] = useState(0)
    const [loading, setLoading] = useState(false)
    const InitList = [
        {
            title: 'Add lenders',
            img: LendersList > 0 ? 'moneyBag' : "finishMoneyBag",
        },
        {
            title: "Add team members",
            img: LoanOfficersList > 1 ? 'finishThreePeople' : "threePeople",
        },
        {
            title: "Add company information",
            img: CompanyInfo ? 'finishIdCard' : "idCard",
        },
        {
            title: "Setup email communication",
            img: EmailInfo ? 'finishMessage' : "message",
        },
        {
            title: "Integrate Zeitro widgets in your website",
            img: WebsiteInfo ? 'finishTable' : "table",
        },

    ]
    const getLender = async (token) => {

        const response = await fetch("/data/getcustomerlenders", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            },
        })
        const data = await response.json()



        setLenders(data.Lenders == null ? 0 : data.Lenders.filter(item => item.Active).length)

    }
    const getCompany = async (token) => {
        const response = await fetch("/los/getcompanyprofilesetup", {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        })

        const data = await response.json()
        Object.keys(data).forEach(key => {

            if (data[key] !== '') {
                setCompanyInfo(true)

            }

        })
    }
    const getWebsite = async (token) => {
        const response = await fetch("/los/getprofilesetup", {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        })
        const data = await response.json()

        const website = data?.LandingPageSetUp?.SetupGuideStatus?.ZeitroWidget

        setWebsiteInfo(website == undefined ? false : website)
        dispatch({ type: "WEB_SITE_INFO", data: website == undefined ? false : website })
    }
    const getEmail = async (token) => {


        const response = await fetch('/los/getemailconfigs', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            }
        })
        const data = await response.json()



        if (data['EmailProvider'] == '') {
            setEmailInfo(false)


        } else {
            setEmailInfo(true)

        }

    }
    const setFirstFinish = () => {

        if (LendersList == 0) {
            dispatch({ type: "FIRST_NO_FINISH", data: 0 })
            return

        }
        if (LoanOfficersList == 1) {
            dispatch({ type: "FIRST_NO_FINISH", data: 1 })
            return

        }
        if (!CompanyInfo) {
            dispatch({ type: "FIRST_NO_FINISH", data: 2 })
            return

        }
        if (!EmailInfo) {
            dispatch({ type: "FIRST_NO_FINISH", data: 3 })
            return

        }
        if (!WebsiteInfo) {
            dispatch({ type: "FIRST_NO_FINISH", data: 4 })
            return

        }
    }
    useEffect(async () => {



        // setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        getCompany(token)
        getLender(token)
        getEmail(token)
        getWebsite(token)
        const response = await fetch('/los/loanofficerlist', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Image-Free": "true",
            }
        })
        const { LoanOfficers } = await response.json()

        setLoanOfficersList(LoanOfficers.length)
    }, [])

   
  
    useEffect(() => {


        dispatch({ type: "FIRST", data: LoanOfficersList > 1 && CompanyInfo && EmailInfo && WebsiteInfo && LendersList > 0 })
        dispatch({ type: "IS_FINISHED", data: LoanOfficersList > 1 || CompanyInfo || EmailInfo || WebsiteInfo || LendersList > 0 })

        // setLoading(false)

        setFirstFinish()

    }, [LoanOfficersList, CompanyInfo, EmailInfo, WebsiteInfo, LendersList])
    const currentStepAchievement = [
        () => {

            return LendersList > 0 ? 'StepTwoData' : 'StepTwoData disabled'
            return 'StepTwoData'


        },
        () => {


            return LoanOfficersList > 1 ? 'StepTwoData' : 'StepTwoData disabled'


        },
        () => {

            return CompanyInfo ? 'StepTwoData' : 'StepTwoData disabled'


        },
        () => {

            return EmailInfo ? 'StepTwoData' : 'StepTwoData disabled'


        },
        () => {

            return WebsiteInfo ? 'StepTwoData' : 'StepTwoData disabled'


        },
    ]
    return (
        <Spin spinning={loading}>

            <div>
                <div className='ModalTitle'>
                    Initial setup
                </div>
                <div style={{ marginTop: 15, color: "#222", fontSize: 16, fontWeight: 400 }}>
                    Configure the following settings for your team so our platform will be ready for them to use when they log in.
                </div>
                <div className='StepTwoContent'>

                    {
                        InitList.map((item, index) => {

                            return (
                                <div key={index} onClick={() => {
                                    if (index === 0) {
                                        dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                                        dispatch({ type: "STEP", data: index })
                                        dispatch({ type: "SHOW_LENDERS", data: true })
                                        return
                                    }
                                    dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                                    dispatch({ type: "STEP", data: index })

                                }} className={currentStepAchievement[index]() ? currentStepAchievement[index]() : 'StepTwoData'}>
                                    <div>
                                        <img src={`/images/${item.img}.svg`}></img>

                                    </div>
                                    <div style={{ width: '100%' }}>
                                        {item.title}
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>




        </Spin>

    )
}
