import React, { useState, useEffect, useRef } from "react";
import Menu from './Menu'
import InnerHTML from "dangerously-set-html-content";
import { Col, Row, Card, Container } from "react-bootstrap";
import { Link } from 'react-router-dom'


const BorrowerLoginPage = (props) => {

  
  let html = `<script id="zeitrotag" src=`+props.appUrl+ `/api/zeitrotag.js?customerid=`+ props.customerid+ `&loid=&pageid=login> </script>`;

  return (
    <div>
      <Menu noLogout={true}/>
      <div className="mt-5">
        <Container>
          <Row className="text-center mb-1">
            <Col>
              <h1>Welcome Back!</h1>
            </Col>
          </Row>
        <InnerHTML html={html} />
        <Row className="text-center">
          <Col>
          <Link to= "/services/forgotpassword">Forgot your password?</Link>
          </Col>
          </Row>
        <Row className="text-center">
          <Col>
          <Link to= {props.url + "/services/signup"}>Sign up for a new account</Link>
          </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BorrowerLoginPage;
