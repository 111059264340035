import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default {
  async processFile(file, scale) {
    try {
      let pdfDocument;
      if (typeof file === 'string') {
        // 如果file是URL
        pdfDocument = await pdfjs.getDocument(file).promise;
      } else if (file instanceof Blob) {
        // 如果file是Blob对象
        const arrayBuffer = await file.arrayBuffer();
        pdfDocument = await pdfjs.getDocument(arrayBuffer).promise;
      } else {
        throw new Error('Invalid file type');
      }

      const numPages = pdfDocument.numPages;
      const pages = [];

      for (let i = 1; i <= numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const viewport = page.getViewport({ scale });
        pages.push({ pageNumber: i, viewport });
      }

      return { type: 'success', numPages, pages };
    } catch (error) {
      return { type: 'error', error: error.message };
    }
  }
};
