import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import "./BasicInfo.css";
export default function BasicInfo({ appurl, customerid, loid, basicInfo, contact, aboutInfo }) {
    const dynamicExternalLink = (href) => {
        if (!/^https?:\/\//i.test(href)) {
            return `https://${href}`
        }
        return href
    }
    const [checked, setChecked] = useState(false);
    const getPreQualificationLink = () => {

        if (window.location.host.includes("zeitro.us")) {
            return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        if (window.location.host.includes("localhost")) {
            return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        return appurl + "/services/preapp?customerid=" + customerid + "&loid=" + loid
    }
    const contactMe = () => {
        contact()
    }
    useEffect(() => {
        loadGoogleTranslateScript(() => {

            if (window.screen.availWidth < 600) return
            new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
        });
    }, []);

    const loadGoogleTranslateScript = (callback) => {
        const script = document.createElement('script');
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        window.googleTranslateElementInit = callback;
    };

    useEffect(() => {

        const googTeCombo = document.querySelector('.goog-te-combo');
        if (!googTeCombo) return
        console.log(checked);
        const children = Array.prototype.slice.call(googTeCombo.children || []);
        console.log(children);
        let zhIdx = children.findIndex((item) => item.getAttribute('value') === 'zh-CN');
        let idx = children.findIndex((item) => item.getAttribute('value') === 'en');
        console.log(idx);
        if (checked) {
            googTeCombo.selectedIndex = zhIdx;

        } else {
            googTeCombo.selectedIndex = idx
        }
        googTeCombo.dispatchEvent(new Event('change'));

    }, [checked])
    let avatarSrc = "/images/avatar.png"
    return (
        <div className="basic-info-new">
            <div>
                {/* <div id="google_translate_element"></div>
                <div className='switch' style={{ float: 'right' }} onClick={() => {
                    setChecked(!checked)
                }

                }>
                    <div className={checked ? 'text checked' : "text"}>中</div>
                    <div className={!checked ? 'text checked' : "text"}>EN</div>
                </div> */}
                <div className='avatar-box-new basic_box'>
                    <img className="basic-info-avatar-new" src={typeof basicInfo.file !== "undefined" && basicInfo.file.length > 0 ? basicInfo.file[0].url : avatarSrc}></img> 
                </div>

            </div>
            <div className='basic_info'>
                <div>
                    <span className='basic_info_prefix'>Email:</span><span className='basic_info_con' onClick={contactMe}>{aboutInfo.email}</span>
                </div>
                <div>
                    <span className='basic_info_prefix'>Phone:</span><span className='basic_info_con' onClick={contactMe}>{aboutInfo.phone}</span>
                </div>
            </div>
            <div className='app-list'>
                <div className='list'>

                    {basicInfo.google && <img src='/images/google.svg' alt='google' onClick={() => window.location.href = dynamicExternalLink(basicInfo.google)} />}
                    {basicInfo.linkedin && <img src='/images/linkedln.svg' alt='linkedln' onClick={() => window.location.href = dynamicExternalLink(basicInfo.linkedin)} />}
                    {basicInfo.twitter && <img src='/images/twitter.svg' alt='twitter' onClick={() => window.location.href = dynamicExternalLink(basicInfo.twitter)} />}
                    {basicInfo.yelp && <img src='/images/yelp.svg' alt='yelp' onClick={() => window.location.href = dynamicExternalLink(basicInfo.yelp)} />}
                </div>
            </div>

        </div>
    );
}