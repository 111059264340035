import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import NumericalInput from '../NumericalInput'
import * as com from "../Common.js"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import HorizontalSlider from '../Common/HorizontalSlider'
import Prequal from './Prequal'
const mapStateToProps = (state) => {
    return {
        hascoborrower: state.application.hascoborrower,
        cohabiting: state.application.cohabiting,
        application: state.application,
        property: state.application.property,
        selection: state.application.selection,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changeBorrowerInfoCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },

    updatePreviousAddress: (event, who, verb, n) => {
        dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n))
    },
    addPreviousAddress: (event, who) => {
        dispatch(act.AddPreviousAddress(event.target.value, who,))
    },
    removePreviousAddress: (event, who, n) => {
        dispatch(act.RemovePreviousAddress(event.target.value, who, n))
    },
    clearPreviousAddress: (event, who) => {
        dispatch(act.ClearPreviousAddress(event.target.value, who))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
});

class Refinance extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        if(isNaN(this.props.application.keepinghousefor ))
            this.props.updateApplicationAttribute(7, "keepinghousefor")
    }
    changeYears = e => {
        com.touch()
        this.props.updateApplicationAttribute(parseInt(e), "keepinghousefor")
        return false
    }
    qualifiesForAssistance = () => {
        let onAmericanIndian = e => {
            
            com.touch()
   
            let val = e.target.value === "true"
            let qualified = this.props.application.qualifiesforassistance
            
            if(null === qualified)
                qualified = 0
            if(val)
                qualified = qualified | 0x1
            else
                qualified = qualified & ~0x1
            
            
            this.props.updateApplicationAttribute(qualified, "qualifiesforassistance")
        }
        let updateFlag = (flag) => {
            return e => {
                com.touch()

                let val = e.target.checked
                let qualified = this.props.application.qualifiesforassistance
            
                if(null === qualified)
                    qualified = 0
                if(val)
                    qualified = qualified | flag
                else
                    qualified = qualified & ~flag
                
                
                this.props.updateApplicationAttribute(qualified, "qualifiesforassistance")                
            }
        }
        let onAssistance = e => {
            com.touch()
            let val = e.target.value === "true"

            this.props.updateApplicationAttribute(val, "askedforassistance")                
        }
        return (
            <div className="viewport text-left mt-3">
                <div className="sectionquestion mb-3">As a first time buyer of primary residence you may qualify for downpayment assistance. </div>            
                <div className=" d-block text-left">    
                <div className="sectionquestion text-left">
                Are you or co-applicant American Indian?            
                </div>                
                <fieldset>
                        <div className="d-flex  ">
                            <div className="mr-3">
                                <input required onChange={onAmericanIndian} value={true} 
                                checked={this.props.application.qualifiesforassistance !== null && ((this.props.application.qualifiesforassistance & 0x1) !== 0)} type="radio" 
                                id="americanindian" name="americanindian"></input>
                                <label id="americanindianlabel" className="zeitro-radio divlink" htmlFor="americanindian">Yes</label></div>
                            <div><input required onChange={onAmericanIndian} value={false} 
                            checked={this.props.application.qualifiesforassistance !== null && ((this.props.application.qualifiesforassistance & 0x1) === 0)} type="radio" 
                            id="americanindiannot" name="americanindian"></input>
                                <label id="americanindiannotlabel" className="zeitro-radio divlink" htmlFor="americanindiannot">No</label></div>
                        </div>
                    </fieldset>


                <div className="sectionquestion text-left mt-2">
                Are you or co-applicant a current or former member of the US military?
            
                </div>
                <div style={{fontStyle: "italic"}} className="mb-2 d-block w-100">Check all that apply</div>
                <Form.Row className="text-left w-100">

                                <Col xs="auto">
                                    <input type="checkbox" id="activeduty" name="activeduty" defaultChecked={this.props.application.qualifiesforassistance & 2} onChange={updateFlag(2)} ></input>
                                    <label id="activedutylabel" style={{ textDecoration: "underline" }} 
                                    className="divlink mr-2" htmlFor="activeduty">Active Duty</label>
                                </Col>

                                <Col xs="auto">
                                    <input type="checkbox" id="reserve" name="reserve" defaultChecked={this.props.application.qualifiesforassistance & 4} onChange={updateFlag(4)} ></input>
                                    <label id="reservelabel" style={{ textDecoration: "underline" }} 
                                    className="divlink mr-2" htmlFor="reserve">Reserve/National Guard</label>
                                </Col>
                                <Col xs="auto">
                                    <input type="checkbox" id="veteran" name="veteran" defaultChecked={this.props.application.qualifiesforassistance & 8} onChange={updateFlag(8)} ></input>
                                    <label id="veteranlabel" style={{ textDecoration: "underline" }} 
                                    className="divlink mr-2" htmlFor="veteran">Veteran</label>
                                </Col>
                                <Col xs="auto">
                                    <input type="checkbox" id="spouse" name="spouse" defaultChecked={this.props.application.qualifiesforassistance & 16} onChange={updateFlag(16)} ></input>
                                    <label id="spouselabel" style={{ textDecoration: "underline" }} 
                                    className="divlink" htmlFor="spouse">Surviving Spouse</label>
                                </Col>

                </Form.Row>

                <div className="mt-3 sectionquestion text-left">
                Do you or co-applicant work in the following professions?
            
                </div>
                <div style={{fontStyle: "italic"}} className="mb-2 d-block w-100">Check all that apply</div>
                <Form.Row className="text-left w-100">

                                <Col xs="auto">
                                    <input type="checkbox" id="education" name="education" defaultChecked={this.props.application.qualifiesforassistance & 32} onChange={updateFlag(32)} ></input>
                                    <label id="educationlabel" style={{ textDecoration: "underline" }} 
                                    className="divlink mr-2" htmlFor="education">Education</label>
                                </Col>

                                <Col xs="auto">
                                <input type="checkbox" id="cop" name="cop" defaultChecked={this.props.application.qualifiesforassistance & 64} onChange={updateFlag(64)} ></input>
                                    <label id="coplabel" style={{ textDecoration: "underline" }} 
                                    className="divlink mr-2" htmlFor="cop">Law Enforcement</label>

                                </Col>
                                <Col xs="auto">
                                <input type="checkbox" id="Firefighter" name="Firefighter" defaultChecked={this.props.application.qualifiesforassistance & 128} onChange={updateFlag(128)} ></input>
                                    <label id="Firefighterlabel" style={{ textDecoration: "underline" }} 
                                    className="divlink mr-2" htmlFor="Firefighter">Firefighter</label>

                                </Col>
                                <Col xs="auto">
                                <input type="checkbox" id="Healthcare" name="Healthcare" defaultChecked={this.props.application.qualifiesforassistance & 256} onChange={updateFlag(256)} ></input>
                                    <label id="Healthcarelabel" style={{ textDecoration: "underline" }} 
                                    className="divlink" htmlFor="Healthcare">Healthcare</label>
                                </Col>

                </Form.Row>               
            {this.props.application.qualifiesforassistance !== 0 ?
            <div>
                <div className="mt-3 sectionquestion text-left">
                As a First Time Home Buyer have you applied, or will you apply for Down Payment Assistance/Grants?          
                </div>     
                <a href="https://downpaymentresource.com/are-you-eligible/" target="about:blank">Click to learn more and check eligibility</a>           
                <fieldset>
                        <div className="d-flex  ">
                            <div className="mr-3">
                                <input required onChange={onAssistance} value={true} checked={true === this.props.application.askedforassistance} type="radio" id="askedforassistance" name="askedforassistance"></input>
                                <label id="askedforassistanceabel" className="zeitro-radio divlink" htmlFor="askedforassistance">Yes</label></div>
                            <div><input required onChange={onAssistance} value={false} checked={false === this.props.application.askedforassistance} type="radio" id="askedforassistancenot" name="askedforassistance"></input>
                                <label id="askedforassistancenotlabel" className="zeitro-radio divlink" htmlFor="askedforassistancenot">No</label></div>
                        </div>
                    </fieldset>
                    </div> : "" }
            </div>
           
            </div>
        )
    }
    render = () => {
        let changeHorizon = e => {

            let keepfor = e.target.value === "true"
            if (keepfor)
                this.props.updateApplicationAttribute(7, "keepinghousefor")
            else
                this.props.updateApplicationAttribute(100, "keepinghousefor")
        }
        let changeMaxUpfront = e => {
            let w = e.target.value === "true"
            if (w) {
                this.props.updateSelectField("4000", "maxupfrontcost")
            } else {
                this.props.updateSelectField("0", "maxupfrontcost")
            }
        }
        let onUpfront = e => {
            if (e.target.value === "")
                return
            this.props.updateSelectField(e.target.value, "maxupfrontcost")
        }
        let updateDownpayment = e => {
            let val = parseInt(e.target.value)
            if(isNaN(val))
                val = 0
            this.props.updateSelectField(val, "downpayment")
        }
        return (
            <div>

                <div className="viewport text-left mt-0">
                    <Form.Row className="text-left">
                        <Col>
                            <div className="sectionquestion"> Do you have a rough idea for how long you going to stay in your house? </div>
                        </Col>
                    </Form.Row>
                    <Form.Row className="text-left">
                        <Col>
                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mt-1">
                                        <div className="mr-3 ">
                                            <input required onChange={changeHorizon} value="false"
                                                checked={false !== this.props.application.keepinghousefor && 30 < this.props.application.keepinghousefor} type="radio" id="hashorizonnot" name="hashorizon"></input>
                                            <label className="zeitro-radio divlink" htmlFor="hashorizonnot">No, I don't</label></div>
                                        <div className="mr-3 ">
                                            <input required onChange={changeHorizon} value="true"
                                                checked={false !== this.props.application.keepinghousefor && 30 >= this.props.application.keepinghousefor} type="radio" id="hashorizon" name="hashorizon"></input>
                                            <label className="zeitro-radio divlink" htmlFor="hashorizon">Yes, I do</label></div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please specify your time horizon
                                    </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    {this.props.application.keepinghousefor <= 30 ?
                        <Form.Row>
                            <Col>
                                <span>Time horizon: {this.props.application.keepinghousefor} {this.props.application.keepinghousefor === 1 ? "year" : "years"}</span>
                                <HorizontalSlider id="yearstohold" onUpdate={this.changeYears} onChange={this.changeYears} step={1} domain={[1, 30]} values={[this.props.application.keepinghousefor]} width="200px" style={{ width: "200px" }} />

                            </Col>
                        </Form.Row>
                        :
                        ""}
                </div>
                { this.props.application.property.purpose !== "purchase" ?
                    <div className="viewport text-left mt-3">
                    <Form.Row className="text-left">
                        <Col>
                            <div className="sectionquestion"> Would you consider paying some amount of money upfront to get the lowest possible monthly payment?</div>
                        </Col>
                    </Form.Row>
                    <Form.Row className="text-left">
                        <Col>
                            <Form.Group>
                                <fieldset>
                                    <div className=" mt-1 d-flex">
                                        <div className="mr-3 ">
                                            <input required onChange={changeMaxUpfront} value="false"
                                                checked={"0" === this.props.selection.maxupfrontcost} type="radio" id="upfrontnot" name="upfront"></input>
                                            <label className="zeitro-radio divlink" htmlFor="upfrontnot">No, I will only refinance if it costs me nothing upfront</label></div>
                                        <div className="mr-3 ">
                                            <input required onChange={changeMaxUpfront} value="true"
                                                checked={0 < parseInt(this.props.selection.maxupfrontcost)} type="radio" id="upfront" name="upfront"></input>
                                            <label className="zeitro-radio divlink" htmlFor="upfront">Yes, I don't mind paying some money upfront</label></div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please specify if you would consider paying points to get a better rate.
                                    </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    {this.props.selection.maxupfrontcost !== "0" &&  this.props.selection.maxupfrontcost !==  null ?
                        <Form.Row className="text-left">
                            <Col md="2">
                                <Form.Group controlId="xxx" className="text-left  m-0 p-0" >
                                    <Form.Label className="text-left" >Your ceiling for loan cost:</Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        required
                                        style={{ width: '8em' }}
                                        name="xxx"
                                        defaultValue={ parseInt(this.props.selection.maxupfrontcost)}
                                        onChange={onUpfront}
                                        min={1}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your tolerance for closing costs.
                            </Form.Control.Feedback>
                                </Form.Group>

                            </Col>
                        </Form.Row> : ""}
                    {this.props.selection.maxupfrontcost !== null ?
                        <div>
                            <div>You can always adjust this choice with the Limit max closing cost slider on the next page <img alt="" style={{ width: '200px' }} src="/maxcc.png"></img></div>
                            <div>As you increase the maximum allowed closing cost, you will see products with the lower rate and monthly payment</div>
                        </div>
                        : ""
                    }
                </div> : "" }

                {  this.props.application.property.purpose === "purchase" ?
              <div className="viewport text-left mt-3">
              <Form.Row className="text-left">
                  <Col xs="auto" >
                      <div className="sectionquestion">What is the approximate size of your downpayment?</div>
                      <Form.Group style={{width: '12em'}} controlId="Commissions" className="text-left" >
                                <Form.Label className="text-left text-nowrap" >Amount:</Form.Label>

                                <NumericalInput isValid={false}
                                    required
                                    size="sm"
                                    min={0}
               
                                    defaultValue={this.props.application.selection.downpayment}
                                    onChange={updateDownpayment}
                                />

                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide commission.
                            </Form.Control.Feedback>
                            </Form.Group>

                      <div className="text-wrap"> You'll be able to play with the downpayment size in the next page with the slider in the top left corner of the next page. This might be very important if your loan size is just
                                slightly above the threshold for Jumbo loan (above 822,375) or conforming loan (below $548,250). Higher priced loans typically come at higher rates.
                  </div>
                  </Col>
               
              </Form.Row>
      </div>
                : "" }
                {  (this.props.application.property.purpose === "refinance") && this.props.selection.maxupfrontcost !== null ?
                    <div className="viewport text-left mt-3 hideonphone">
                        <Form.Row className="text-left">
                            <Col>
                                <div className="sectionquestion">Would you be able to adjust the size of your loan?</div>
                                <div className="text-wrap"> Absolutely! You can specify paydown towards the principal with the slider in the top left corner of the next page. This might be very important if your loan size is just
                                slightly above the threshold for Jumbo loan (above 822,375) or conforming loan (below $548,250). Higher priced loans typically come at higher rates.
                            </div>
                            </Col>
                        </Form.Row>
                </div> : ""}
                {  (this.props.application.property.purpose === "cashoutrefinance") && this.props.selection.maxupfrontcost !== null ?
                    <div className="viewport text-left mt-3 hideonphone">
                        <Form.Row className="text-left">
                            <Col>
                                <div className="sectionquestion">Would you be able to adjust the size of your cash out?</div>
                                <div className="text-wrap"> Absolutely! You can specify the cash out amount with the slider in the top left corner of the next page.
                            </div>
                            </Col>
                        </Form.Row>
                </div> : ""}                
                
                {  this.props.application.property.purpose === "purchase" || this.props.selection.maxupfrontcost !== null ?
                    <div className="viewport text-left mt-3 hideonphone">
                        <div className="sectionquestion">How do you choose a loan?</div>
                        <div>Review the choices. Experiment with the selections for amortization, and loan terms, if you interested in loans with shorter than 30 year term.</div>
                        <div>When ready, just click on the <img alt="" style={{ width: '100px' }} src="/selectloan.png"></img> link, then click on Next to proceed to the remaining questions. </div>
                        <div><b>Important!</b> The rates are floating!</div>
                        <div>At the end of interview you will be able to lock the rate, and submit your application.</div>
                    </div> : ""}

                {this.props.application.property.purpose === "purchase"  && this.props.application.property.occupancy === "principal" 
                    && true === this.props.application.firsttimebuyer ? this.qualifiesForAssistance() : ""}

            </div>
        )
    }
}
Refinance = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Refinance)
class PreChoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.prequal = React.createRef()
    }

    componentDidMount() {

    }

    componentWillUnmount() { }


    handleSubmit = e => {
        if(this.props.property.purpose === "purchase" && false === this.props.application.property.hascontract) {            
            return this.prequal.current.handleSubmit(e)
        }
        return true
    }


    render() {
        let showSpecific = () => {
            switch (this.props.property.purpose) {
                case "purchase":
                    if(false === this.props.application.property.hascontract) {
                        return <Prequal borrowerid={this.props.borrowerid} ref={this.prequal} />
                    } else
                        return <Refinance />
                default:
                    return "not done yet"
                case "refinance":
                    return <Refinance />
                case "cashoutrefinance":
                    return <Refinance />
            }
        }
        return (
            <div className=" mb-4  text-left text-wrap" key={this.props.who}>
                {this.props.property.purpose === "purchase" && false === this.props.application.property.hascontract ?
               <h2 className="heading">Let's see if you qualify for a loan for a property valued up to ${com.commaizeFloat(this.props.property.salesprice)}</h2> :
                <h2 className="heading">Before we show you the best products out of thousands...</h2> }
                {showSpecific()}
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PreChoice)

