import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import {
    Progress,
    Tabs,
    Tag,
    DatePicker,
    Button,
    Checkbox,
    Tooltip,
    Select,
    Switch,
    Modal,
    Divider,
    Form,
    Input,
    InputNumber,
    Slider,
    Col,
    Row,
    Upload,
    Popover,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import "./BasicInfoSetup.css";
import CropperModal from '../Photo/CropperModal';
import { isEmpty } from 'lodash';
const BasicInfoSetup = forwardRef(({ info, finish, change, children, changeProgress }, ref,) => {
    const [form] = Form.useForm();
    const [applink, setApplink] = useState(info.appLink);
    const [percent, setPercent] = useState(0);
    const [required, setRequired] = useState(50);
    const [cropperVisible, setCropperVisible] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [tags, setTags] = useState([]);
    const [popularTags, setPopularTags] = useState([])
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    const [profilePhoto, setProfilePhoto] = useState('');
    const [linkList, setLinkList] = useState([
        {
            name: 'Linkedin',
            link: '',
            edit: false
        },
        {
            name: 'Google',
            link: '',
            edit: false
        },
        {
            name: 'Yelp',
            link: '',
            edit: false
        },
        {
            name: 'Twitter',
            link: '',
            edit: false
        }
    ])
    const isInit = useRef(true)
    useEffect(() => {
        if (inputVisible && inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputVisible]);
    useEffect(() => {
        if (editInputRef.current) {
            editInputRef.current.focus()
        }
    }, [editInputValue]);
    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag)
        setTags(newTags)
    }
    const handleClick = (tag) => {
        if (!tags.includes(tag) && tags.length < 5) {
            setTags([...tags, tag])
        }
    }

    useEffect(() => {
        if (isInit.current) {
            isInit.current = false
        } else {
            const formValues = form.getFieldValue()
            const obj = {
                ...formValues,
                tagsList: tags,
                popularTags: popularTags,
                google: getLinkByName('Google'),
                yelp: getLinkByName('Yelp'),
                linkedin: getLinkByName('Linkedin'),
                twitter: getLinkByName('Twitter'),
                appLink: applink
            }
            change(obj)

        }
    }, [tags])

    useEffect(() => {
        const formValues = form.getFieldValue()
        const obj = {
            ...formValues,
            tagsList: tags,
            popularTags: popularTags,
            google: getLinkByName('Google'),
            yelp: getLinkByName('Yelp'),
            linkedin: getLinkByName('Linkedin'),
            twitter: getLinkByName('Twitter'),
            appLink: applink
        }
        setProgressValue(obj)

    }, [percent, tags, linkList])
    
    useEffect(() => {
        const { tagsList, popularTags, google, linkedin, twitter, yelp, appLink, ...formValue } = info
        const initialLink = {
            google,
            linkedin,
            twitter,
            yelp
        }
        form.setFieldsValue(formValue)
        setTags(tagsList)
        setPopularTags(popularTags)
        const updatedLinkList = linkList.map(item => {
            const key = item.name.toLowerCase()
            return {
              ...item,
              link: initialLink[key],
              edit: initialLink[key] !== ''
            }
        })
        setLinkList(updatedLinkList)
        setApplink(appLink)

    }, [info])
    const getLinkByName = (name) => {
        const item = linkList.find(item => item.name === name)
        return item ? item.link : null
    }
    const showInput = () => {
        setInputVisible(true)
    }
    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }
    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue])
        }
        setInputVisible(false)
        setInputValue('')
    }
    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value)
    }
    const handleEditInputConfirm = () => {
        const newTags = [...popularTags]
        newTags[editInputIndex] = editInputValue
        setPopularTags(newTags)
        setEditInputIndex(-1)
        setEditInputValue('')
    }
    const tagInputStyle = {
        width: 100,
        height: 32,
        marginInlineEnd: 8,
        verticalAlign: 'top',
    }
    const states = {
        AL: "Alabama",
        AK: "Alaska",
        AS: "American Samoa",
        AZ: "Arizona",
        AR: "Arkansas",
        CA: "California",
        CO: "Colorado",
        CT: "Connecticut",
        DE: "Delaware",
        DC: "District Of Columbia",
        FM: "Federated States Of Micronesia",
        FL: "Florida",
        GA: "Georgia",
        GU: "Guam",
        HI: "Hawaii",
        ID: "Idaho",
        IL: "Illinois",
        IN: "Indiana",
        IA: "Iowa",
        KS: "Kansas",
        KY: "Kentucky",
        LA: "Louisiana",
        ME: "Maine",
        MH: "Marshall Islands",
        MD: "Maryland",
        MA: "Massachusetts",
        MI: "Michigan",
        MN: "Minnesota",
        MS: "Mississippi",
        MO: "Missouri",
        MT: "Montana",
        NE: "Nebraska",
        NV: "Nevada",
        NH: "New Hampshire",
        NJ: "New Jersey",
        NM: "New Mexico",
        NY: "New York",
        NC: "North Carolina",
        ND: "North Dakota",
        MP: "Northern Mariana Islands",
        OH: "Ohio",
        OK: "Oklahoma",
        OR: "Oregon",
        PW: "Palau",
        PA: "Pennsylvania",
        PR: "Puerto Rico",
        RI: "Rhode Island",
        SC: "South Carolina",
        SD: "South Dakota",
        TN: "Tennessee",
        TX: "Texas",
        UT: "Utah",
        VT: "Vermont",
        VI: "Virgin Islands",
        VA: "Virginia",
        WA: "Washington",
        WV: "West Virginia",
        WI: "Wisconsin",
        WY: "Wyoming",
    }
    const getOptions = () => {
        let options = []
        for (let s in states) {
            options.push({ value: s, label: states[s] })
        }
        return options
    }
    const progressStatus = () => {
        if (percent < required) {
            return 'undone'
        } else if (percent >= required && percent < 100) {
            return 'normal'
        }
    }
    const validateMessages = {
        required: '${label} is required!',
    }
    const onFinish = (val) => {

    }
    useImperativeHandle(ref, () => ({
        getPercent() {

            return percent
        },
        async validate() {
            return await form.validateFields()

        },
        validateForm() {
            form.validateFields().then((values) => {

                const info = {
                    ...values,
                    tagsList: tags,
                    popularTags: popularTags
                }

                finish(info)
            })
                .catch((errorInfo) => {

                    console.log('Validation failed:', errorInfo);
                });

        },
        resetForm() {
            form.resetFields()
        }
    }))
    const handleValuesChange = (changedValues, allValues) => {
        const obj = {
            ...allValues,
            tagsList: tags,
            popularTags: popularTags,
            google: getLinkByName('Google'),
            yelp: getLinkByName('Yelp'),
            linkedin: getLinkByName('Linkedin'),
            twitter: getLinkByName('Twitter'),
            appLink: applink
        }
        setProgressValue(obj)
        change(obj)
    }
    const handleUploadChange = async ({ file, fileList }) => {
        if (file.status === 'done') {
            const base64Data = await readFileAsDataURL(file.originFileObj)
            setImageSrc(base64Data)
            setCropperVisible(true)
            const formValues = form.getFieldValue()
            const newFileList = fileList.map((f) => {
                if (f.uid === file.uid) {
                    return { ...f, url: base64Data }
                }
                return f
            })
            form.setFieldsValue({
                file: newFileList,
            })
            const obj = {
                ...formValues,
                file: newFileList,
                tagsList: tags,
                popularTags: popularTags,
                google: getLinkByName('Google'),
                yelp: getLinkByName('Yelp'),
                linkedin: getLinkByName('Linkedin'),
                twitter: getLinkByName('Twitter'),
                appLink: applink
            }
            change(obj)
        } else if (file.status === 'error') {

        }
    }
    const handleSave = (croppedImage) => {
        const formValues = form.getFieldValue()
        let imgList = form.getFieldValue('file')
        const newFileList = imgList.map((f) => {
            return { ...f, url: croppedImage }
        })
        form.setFieldsValue({
            file: newFileList,
        })
        const obj = {
            ...formValues,
            file: newFileList,
            tagsList: tags,
            popularTags: popularTags,
            google: getLinkByName('Google'),
            yelp: getLinkByName('Yelp'),
            linkedin: getLinkByName('Linkedin'),
            twitter: getLinkByName('Twitter'),
            appLink: applink
        }
        change(obj)
    }
    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) => resolve(e.target.result)
            reader.onerror = (error) => reject(error)
        })
    }
    const setProgressValue = (form) => {
        const fillNum = Object.keys(form).filter((key) => {
            const value = form[key];

            return !isEmpty(value)

        }).length
        const progressValue = Math.round((fillNum / 14) * 100)
        setPercent(progressValue)
        changeProgress(progressValue)
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    }
    const handleCustomRequest = (options) => {
        const { onSuccess } = options
        onSuccess()
    }
    const uploadBtn = () => {
        let file = form.getFieldValue('file')
        return file && file.length > 0 ? null : <div className='upload-btn'><img src='/images/upload-img.svg' /></div>
    }
    const handleCropper = () => {
        const base64Data = form.getFieldValue('file')[0].url
        setImageSrc(base64Data)
        setCropperVisible(true)
    }
    const itemRender = (originNode, file, fileList, actions) => (
        <div style={{ marginTop: '6px', display: 'flex' }}>
            <img onClick={handleCropper} className='profile-photo' src={profilePhoto || file.url}></img>
            <img className='remove-photo' src='/images/remove.svg' onClick={() => { actions.remove(file) }} />
        </div>
    )
    const changeEdit = (name) => {
        setLinkList(prevList =>
          prevList.map(item => (
            item.name === name ? { ...item, edit: true } : item
          ))
        )
    }

    const changeLink = (name, newLink) => {
        setLinkList(prevList =>
          prevList.map(item =>
            item.name === name ? { ...item, link: newLink } : item
          )
        )
        const formValues = form.getFieldValue()
        const obj = {
            ...formValues,
            tagsList: tags,
            popularTags: popularTags,
            google: name === 'Google' ? newLink : getLinkByName('Google'),
            yelp: name === 'Yelp' ? newLink : getLinkByName('Yelp'),
            linkedin: name === 'Linkedin' ? newLink : getLinkByName('Linkedin'),
            twitter: name === 'Twitter' ? newLink : getLinkByName('Twitter'),
            appLink: applink
        }
    
        change(obj)
    }

    const changePrequalLink = (newLink) => {
        // if (!newLink.includes("http")) {
        //     newLink = "https://" + newLink
        // }
        setApplink(newLink)
        const formValues = form.getFieldValue()
        const obj = {
            ...formValues,
            tagsList: tags,
            popularTags: popularTags,
            google:  getLinkByName('Google'),
            yelp:  getLinkByName('Yelp'),
            linkedin: getLinkByName('Linkedin'),
            twitter:  getLinkByName('Twitter'),
            appLink: newLink
        }
        change(obj)
    }

    const linkRemove = (name) => {
        setLinkList(prevList =>
          prevList.map(item => (
            item.name === name ? { ...item, link: '', edit: false } : item
          ))
        )
        const formValues = form.getFieldValue()
        const obj = {
            ...formValues,
            tagsList: tags,
            popularTags: popularTags,
            google: name === 'Google' ? '' : getLinkByName('Google'),
            yelp: name === 'Yelp' ? '' : getLinkByName('Yelp'),
            linkedin: name === 'Linkedin' ? '' : getLinkByName('Linkedin'),
            twitter: name === 'Twitter' ? '' : getLinkByName('Twitter'),
            appLink: applink
        }
        change(obj)
    }
    return (
        <div className="basic-info-setup">
            <div className='form-wrap'>
                <Form
                    className='basic-info-form'
                    layout='vertical'
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={handleValuesChange}
                    validateMessages={validateMessages}
                    size="large"
                >
                    <Row gutter={32}>
                        <Col span={24} sm={12}>
                            <Form.Item
                                name="firstname"
                                label="First name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern:/^[a-zA-Z\u4e00-\u9fa5]+$/,
                                        message: 'Please enter your first name',
                                    },
                                ]}
                            >
                                <Input placeholder=""  maxLength={20} />
                            </Form.Item>
                            <Form.Item
                                name="lastname"
                                label="Last name"
                                rules={[
                                    {
                                        required: true,
                                        
                                    },
                                    {
                                        pattern:/^[a-zA-Z\u4e00-\u9fa5]+$/,
                                        message: 'Please enter your last name',
                                    },
                                ]}
                            >
                                <Input placeholder=""  maxLength={20}/>
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={10}>
                            <Form.Item
                                name="file"
                                label="Profile photo"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                            >
                                <Upload
                                    maxCount={1}
                                    customRequest={handleCustomRequest}
                                    itemRender={itemRender}
                                    onChange={handleUploadChange}
                                >
                                    {uploadBtn()}

                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col span={24} sm={12}>
                            <Form.Item
                                name="nmls"
                                label="NMLS License"
                                rules={[
                                    {
                                        pattern:/^\d+$/,
                                        message: 'Please enter your NMLS ID',
                                    },
                                ]}
                            >
                                <Input placeholder=""  maxLength={20}/>
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12}>
                            <Form.Item
                                name="states"
                                label="License States"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    options={getOptions()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {children}
                    <div className='form-item-title'>Personalized tag<span style={{fontSize: 14}}>（* Up to 5 tags）</span></div>

                    <div className='tag-list' style={{ marginBottom: 0 }}>
                        {
                            tags.map((tag, index) => {
                                const tagElem = (
                                    <Tag
                                        key={tag}
                                        closable
                                        color='default'
                                        style={{
                                            fontFamily: 'Inter',
                                            color: '#000',
                                            backgroundColor: '#EAEFFD',
                                            userSelect: 'none',
                                            lineHeight: '28px',
                                            marginBottom: '20px',
                                            borderRadius: '6px',
                                            padding: '2px 10px',
                                            fontSize: '16px',
                                            border: '1px solid #F7F8FF',
                                            marginInlineEnd: '10px',
                                            cursor: 'pointer'
                                        }}
                                        onClose={() => handleClose(tag)}
                                    >
                                        <span>
                                            {tag}
                                        </span>
                                    </Tag>
                                );
                                return tagElem
                            })
                        }
                    </div>
                    {
                        tags.length < 5 && (
                            inputVisible ? (
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    size="small"
                                    style={tagInputStyle}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onBlur={handleInputConfirm}
                                    onPressEnter={handleInputConfirm}
                                />
                            ) : (
                                <Tag 
                                    style={{
                                        fontFamily: 'Inter',
                                        color: '#000',
                                        backgroundColor: '#EAEFFD',
                                        userSelect: 'none',
                                        lineHeight: '28px',
                                        marginBottom: '20px',
                                        borderRadius: '6px',
                                        padding: '2px 10px',
                                        fontSize: '16px',
                                        marginInlineEnd: '10px',
                                        cursor: 'pointer',
                                        display: 'inline-flex',
                                        alignItems: 'center'
                                    }} 
                                    onClick={showInput} 
                                    bordered={false} 
                                    color='default'
                                >
                                    <img style={{width: 12, height: 12, marginRight: 10}} src='/images/site-add.svg' />Add yours
                                </Tag>
                            )
                        )
                    }

                    <div className='form-item-title' style={{ marginTop: 16 }}>Popular tags</div>
                    <div className='popular-tags'>
                        {popularTags.map((tag, index) => {
                            if (editInputIndex === index) {
                                return (
                                    <Input
                                        ref={editInputRef}
                                        key={tag}
                                        size="small"
                                        style={tagInputStyle}
                                        value={editInputValue}
                                        onChange={handleEditInputChange}
                                        onBlur={handleEditInputConfirm}
                                        onPressEnter={handleEditInputConfirm}
                                    />
                                );
                            } else {
                                if (!tags.includes(tag)) {
                                    const tagElem = (
                                        <Tag
                                            key={tag}
                                            style={{
                                                fontFamily: 'Inter',
                                                color: '#000',
                                                backgroundColor: '#EAEFFD',
                                                userSelect: 'none',
                                                lineHeight: '28px',
                                                marginBottom: '20px',
                                                borderRadius: '6px',
                                                padding: '2px 10px',
                                                fontSize: '16px',
                                                marginInlineEnd: '10px',
                                                cursor: 'pointer'
                                            }}
                                            bordered={false}
                                            onClick={() => handleClick(tag)}
                                        >
                                            <span
                                                onDoubleClick={(e) => {
                                                    setEditInputIndex(index)
                                                    setEditInputValue(tag)
                                                    e.preventDefault()
                                                }}
                                            >
                                               {tag}
                                            </span>
                                        </Tag>
                                    );
                                    return tagElem
                                }
                            }
                        })}
                    </div>

                    <div className='form-item-title d-flex align-items-center' style={{marginTop: 16}}>
                        <div>Application button URL (optional)</div>
                        <Popover 
                            rootClassName="applyBtnTooltip" 
                            content={
                                <>
                                    <div className="mb-2">
                                        This field allows you to customize the URL for the "Apply now" buttons on your website. 
                                    </div>
                                    <div className="mb-2">
                                        By default, the buttons will link to your system's application URL. 
                                        If you prefer to direct your website visitors to a different application URL, 
                                        please enter your desired URL here. 
                                    </div>
                                    <div className="mb-2">
                                        Make sure the URL begins with "http://" or "https://".
                                    </div>
                                </>
                            }
                        > 
                            <QuestionCircleOutlined className="ml-1" /> 
                        </Popover>
                    </div>
                    <Input defaultValue={applink} onChange={(e) => changePrequalLink(e.target.value)} />


                    <div className='form-item-title' style={{ marginTop: 16 }}>Social media links</div>
                    {linkList.map((item, index) => (
                        item.edit && (
                            <div key={item.name} className='link-edit-item'>
                                <div className='link-title'>{item.name}</div>
                                <div className='link-edit'><Input defaultValue={item.link} onChange={(e) => changeLink(item.name, e.target.value)} /> <img className='link-remove' src='/images/remove.svg' onClick={() => linkRemove(item.name)} /></div>
                            </div>
                        )
                    ))}
                    <Row gutter={20} className='link-list'>
                        {
                            linkList.map((item, index) => (
                                !item.edit && item.link === '' && (
                                    <Col span={24} sm={6} key={item.name}>
                                        <div className={`link-list-item ${ item.link !== '' ? 'link-completed' : '' }`} onClick={() => changeEdit(item.name)}>{item.link === '' && <img style={{width: 12, height: 12, marginRight: 5}} src='/images/site-add.svg' />} <span style={{lineHeight: '32px'}}>{item.name}</span></div>
                                    </Col>
                                )
                            ))
                        }
                    </Row>
                </Form>
                <CropperModal
                    aspectRatio={1}
                    type="circle"
                    visible={cropperVisible}
                    imageSrc={imageSrc}
                    onClose={() => setCropperVisible(false)}
                    onSave={handleSave}
                />
            </div>
        </div>
    );
})
export default BasicInfoSetup;