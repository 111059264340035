import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./RateQuote.css";
import { Form, Col, Button, Row, Container } from "react-bootstrap";

export default function ReportIssuesModal(props) {
  const [files, setFiles] = useState("");

  let handleReportIssues = (event) => {
    event.preventDefault();
    let token = sessionStorage.getItem("ZeitroA");
    const formData = new FormData();
    formData.append("upload", files[0]);

    fetch("/los/reportissues", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Message": event.target.elements.message.value,
      },
      body: formData,
    }).then((response) => {
      if (response.status !== 200) {
        console.log(
          "/los/reportissues: Looks like there was a problem. Status Code: " +
            response.status
        );
        return;
      }
    });
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Report a issue to Zeitro
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleReportIssues}>
        <Modal.Body>
          <Form.Group controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={4} />
          </Form.Group>
          <Form.Group>
            <Form.File
              id="file"
              label="Add a screenshot if applied"
              onChange={(e) => setFiles(e.target.files)}
              multiple
              accept=".png,.jpg,.jpeg"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Confirm</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function ReportIssuesForm() {
  const [files, setFiles] = useState("");
  const [message, setMessage] = useState("");

  let handleReportIssues = (event) => {
    event.preventDefault();
    let token = sessionStorage.getItem("ZeitroA");
    const formData = new FormData();
    formData.append("upload", files[0]);

    fetch("/los/reportissues", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Message": event.target.elements.message.value,
      },
      body: formData,
    }).then((response) => {
      if (response.status !== 200) {
        console.log(
          "/los/reportissues: Looks like there was a problem. Status Code: " +
            response.status
        );
        setMessage("Looks like there was a problem. Status Code: " + response.status)
        return;
      } else {
        setMessage("Your feedback has been sent.")
      }
    });
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h2>Send feedback to Zeitro</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={handleReportIssues}>

                <Form.Group controlId="message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={5} />
                </Form.Group>
                <Form.Group>
                  <Form.File
                    id="file"
                    label="Add a screenshot if applied"
                    onChange={(e) => setFiles(e.target.files)}
                    multiple
                    accept=".png,.jpg,.jpeg"
                  />
                </Form.Group>

                <Button type="submit">Send</Button>
            </Form>
          </Col>
        </Row>
        <br/>
        <Row><Col><h5>{message !== "" ? message : "" }</h5></Col></Row>
      </Container>
    </div>
  );
}
