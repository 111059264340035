import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button, Form, Alert } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { companyName } from '../../../Common';
import InfoModal from '../../../CommonUIComponents/InfoModal';

import classes from './Communication.module.css';


const Emails = () => {

  const initial = {
    'Additional Conditions': {
      subject: "Additional conditions required for your loan application.",
      content: `
        <p>Additional Conditions Required</p>
        <p>You are almost there! The following list of outstanding conditions are either still required or were added. If new conditions were added, this typically means the underwriter discovered new information. Submitting supporting documents will help the underwriter get a better sense of your financial situation.</p>
        <p><strong>Fill in the list below.</strong></p>
        <ol>
        <li></li>
        <li></li>
        <li></li>
        </ol>
        <p>Once you’ve submitted your outstanding conditions you can view the status of your loan by logging into your portal.</p>
        <p>Thank you,</p>
        
        <p>Team ${companyName}</p>`,
      clientSide: true,
    },
    'Conditional Approval': {
      subject: "You are conditionally approved for your mortgage loan.",
      content: `
        <p>Conditional Approval. You are almost there!</p>
        <p>Your mortgage underwriter has conditionally approved your mortgage. To be fully approved, the mortgage underwriter requires a few more conditions to be met. The following items below should get you to an official approval.</p>
        <p><strong>Fill in the list below.</strong></p>
        <ol>
        <li></li>
        <li></li>
        <li></li>
        </ol>
        <p>Please login to your portal to add your missing conditions.</p>
        <p>Be aware that making changes to your income, assets, liabilities, or any new discovery of undisclosed debt may void the Conditional Approval and/or require a reconsideration at different terms or loan denial.</p>
        <p>If you have questions please contact your loan officer</p>
        <p>Thank you!</p>
        <p>Team ${companyName}</p>`,
      clientSide: true,
    },
    'Clear To Close': {
      subject: "Your loan is approved.",
      content: `<p>Congratulations!</p>
        <p>Your loan is now approved. The next step is to sign the necessary legal documents.  Your Escrow Officer will be contacting you to schedule a time for you to sign. If you prefer a mobile notary, the Escrow Officer can help make that arrangement.</p>
        <p>Escrow Officer Contact Information: <strong>______.</strong></p>
        <p>Once your Loan documents are fully executed, they will be forwarded to the Lender for funding.</p>
        <p>You can view the status of your loan by logging into your portal.</p>
        <p>Again, thank you for choosing ${companyName}! Please feel free to contact us with any questions.</p>`,
      clientSide: true,
    },
    'Loan Funded': {
      subject: 'Congratulations your Loan has Funded.',
      content: `<p>Congratulations your Loan has Funded!</p>
      <p>Thank your for choosing ${companyName} in helping you get the right loan. If you have any follow-up questions please feel free to email your loan officer.</p>
      <p>One last thing. We love listening to our clients. Your feedback helps improve our process and platform. If you have and comments or suggestions we would be happy to hear from you. It is valuable to us!</p>
      <p>Once again, thank you!</p>
      <p>All the best,</p>
      <p>Team ${companyName}</p>`,
      clientSide: true,
    },
    'Conditions Submitted': {
      subject: 'Your conditions have been submitted for underwriting review.',
      content: `<p>Thank you for submitting your conditions.</p>
      <p>Your conditions have been submitted and will be reviewed by the underwriter. To check the status and timelines please login into your portal. If there are any other requirements or questions from the underwriter, we will promptly let you know.</p>
      <p>Thank you!</p>       
      <p>Team ${companyName}</p>`,
      clientSide: true,
    },
    'Request Acknowledgement': {
      subject: `Request acknowledgement from ${companyName}`,
      content: `<h4>Dear {{.CustomerFullName}}</h4>
      <p>Thank you for requesting information from ${companyName}</p>
      <p>I will respond to your request shortly. Please do not reply to this email.</p>
      <p>Yours, {{.LoanOfficerFullName}}.</p>`,
      clientSide: false,
    },
    'New account created': {
      subject: 'New account created',
      content: `<h4>New account created!</h4>
      <p>A new account has been created by  {{.CustomerFullName}}, email address: {{.CustomerEmail}}</p>
      <p>Please do not reply to this email.</p>
      <p>Yours, ${companyName}.</p>`,
      clientSide: false,
    },
    'Pre-Qualification Letter': {
      subject: 'Pre-Qualification Letter Generated',
      content: `<h4>Dear {{.LoanOfficerFullName}}</h4>
      <p>A pre-qualification letter has been generated for customer: {{.CustomerFullName}} ({{.CustomerEmail}})</p>
      <p>Please do not reply to this email. </p>
      <p>Yours, ${companyName}.</p>`,
      clientSide: false,
    },
    'Account Verification': {
      subject: `${companyName} account verification`,
      content: `<h4>Dear {{.CustomerFullName}}</h4>
      <p>Thank you for your trust. This is a verification email for your account.</p>
      <p><a href="{{.Host}}/validated/{{.Hash}}/{{.CustomerID}}">Click here in order to login to ${companyName} and start working on your loan application.</a></p>
      <p>Yours, ${companyName}.</p>`,
      clientSide: false,
    },
    'Remote Coborower Invite': {
      subject: `${companyName} account invitation`,
      content: `<h4>Dear {{.CoborrowerFullName}}</h4>
      <p>You have been invited by {{.CustomerFullName}} to be a coapplicant on a mortgage loan.</p>
      <p><a href="https://{{.Host}}/services/invitedcoborrower/{{.Hash}}/{{.Hexnames}}/{{.Hexemail}}">Click here in order to create an account at ${companyName} and start working on your part of the application.</a></p>
      <p>Please do not reply to this email.</p>
      <p>Yours, {{.LoanOfficerFullName}}.</p>`,
      clientSide: false,
    },
    'Account Handoff': {
      subject: `Please Finish Setting Up Your ${companyName} Account`,
      content: `<h4>Congratulations, your mortgage application journey has begun!</h4> 
      <p>A ${companyName} account was created by your loan officer {{.LoanOfficerFullName}}, NMLS: #{{.LoanOfficerNmls}}.</p>
      <p>To continue with the application journey, please <a href="{{.Apphost}}/api/handoff/{{.Hash}}/{{.Email}}/{{.CustomerID}}" target="about:blank"> click here </a> (Link expires on {{.Expires}})</p>
      <p>For any other questions, you can email your loan officer at <a href='mailto:{{.LoanOfficerEmail}}'>{{.LoanOfficerEmail}}</a> or call at {{.LoanOfficerPhone}}.</p>
      <p>Please do not reply to this email.</p>`,
      clientSide: false,
    },
    'Password Recovery': {
      subject: `Password Recovery for ${companyName} Account`,
      content: `<h4> Password recovery for ${companyName} Account</h4>
      <p>This is the recovery email for your ${companyName} account. If you did not request a password reset, please disregard this email.</p>
      <p>If you want to reset the password for your ${companyName} account, please<a href="{{.Host}}/api/reset/{{.Hash}}/{{.Email}}/{{.CustomerID}}"> click here, and follow instructions on the resulting web page.</a></p>
      <p>This link will be valid for only 15 minutes.</p>
      <p>Please do not reply to this email.</p>
      <p>Yours, ${companyName}.</p>`,
      clientSide: false,
    },
    'Document Request': {
      subject: `Document request for your loan`,
      content: `<h4>Dear borrower</h4>
      <p>Please address the following action requests for your loan application with ${companyName}:
      {{range .Tasks}}
      <li>{{.TaskName}}</li>
      {{end}}
      </p>
      <p><a href="{{.Host}}/d/{{.Hash}}/{{.CustomerID}}"> click here, log in, and follow instructions on the resulting web page.</a></p>
      </p>
      <p>Yours, {{.LoanOfficerFullName}}.</p>`,
      clientSide: false,
    },
    'Customer progress notification': {
      subject: 'Customer progress notification',
      content: `<h4>Update on the loan application for {{.CustomerFullName}}</h4>
      <p>{{.Message}}</p>
      <p>Please do not reply to this email.</p>
      <p>Yours, ${companyName}</p>`,
      clientSide: false,
    }
  };

  const [options, setOptions] = useState({ ...initial })
  const [selected, setSelected] = useState('');
  const [buttonStyles, setButtonStyles] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [infoModal, setInfoModal] = useState({ show: false, modalTitle: null, modalBody: null, modalFooter: null });


  useEffect(() => {

    const token = sessionStorage.getItem("ZeitroA")

    fetch('/los/getemailtemplates', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        'X-Keys': '',
        'X-IsServerSide': false
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      response.json().then(js => {
        let templates = JSON.parse(js.Emails);
        let tmp = {};
        for (let key in templates.ServerSide) {
          setButtonStyles(prev => ({ ...prev, [key]: true }));
          tmp[key] = {};
          tmp[key].subject = templates.ServerSide[key].Subject;
          tmp[key].content = templates.ServerSide[key].Content;
          tmp[key].clientSide = false;
        }

        for (let key in templates.ClientSide) {
          setButtonStyles(prev => ({ ...prev, [key]: true }));
          tmp[key] = {};
          tmp[key].subject = templates.ClientSide[key].Subject;
          tmp[key].content = templates.ClientSide[key].Content;
          tmp[key].clientSide = true;
        }

        setOptions(prev => ({ ...prev, ...tmp }));
      })
    })
  }, []);

  const showButton = (key) => {

    const clickHandler = () => {
      setSelected(key);

      const contentBlock = htmlToDraft(options[key].content);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
      setOptions(prev => ({ ...prev, [key]: { ...prev[key], subject: prev[key].subject } }));
    }

    return <Button key={key} variant={buttonStyles[key] ? "success" : "light"} onClick={clickHandler}>{key}</Button>
  };

  const onEditorStateChange = e => {
    setEditorState(e)
  };


  const subjectChangeHandler = e => {
    setOptions(prev => ({ ...prev, [selected]: { ...prev[selected], subject: e.target.value } }));
  }

  const submitChange = () => {
    // var html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let current = editorState.getCurrentContent().getPlainText()
    var div = document.createElement("div");
    div.innerHTML = options?.[selected]?.content;
    var initial = div.textContent || div.innerText || "";
    div.remove();

    if (initial.replace(/\s/g, "") === current.replace(/\s/g, "")) {
      let modalTitle = <p><i className={`fas fa-exclamation-triangle ${classes.orange}`} ></i> Email body has not been changed.</p>
      let modalBody = 'Are you sure you want to proceed without any changes?';
      let modalFooter = <>
        <Button variant="primary" onClick={() => saveTemplate()}>Save</Button>
        <Button variant="danger" onClick={() => hideInfoModal()}>Cancel</Button>
      </>;
      setInfoModal({ show: true, modalTitle, modalBody, modalFooter })
    } else {
      saveTemplate();
    }
  }

  const saveTemplate = () => {
    const token = sessionStorage.getItem("ZeitroA")

    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const body = JSON.stringify({
      Key: selected,
      Subject: options[selected].subject,
      Content: content,
      ClientSide: options[selected].clientSide,
    });

    fetch('/los/updateemailtemplate', {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body,
    }).then(response => {
      if (response.status !== 200) {
        alert('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      setButtonStyles(prev => ({ ...prev, [selected]: true }));
      setInfoModal({ show: false });
      alert('Email template updated successfully');
    })
  }

  const copyToClipboard = (id) => {
    let ev = document.getElementById(id)
    navigator.clipboard.writeText(ev.textContent)
  }

  const hideInfoModal = () => {
    setInfoModal({ show: false, modalTitle: null, modalBody: null, modalFooter: null })
  }

  return (
    <div className='p-4'>
      <ButtonGroup aria-label="Emails" className={`my-4 ${classes['button-group']}`}>
        {Object.keys(options).map(el => showButton(el))}
      </ButtonGroup>
      {!options?.[selected]?.clientSide && typeof options?.[selected]?.clientSide !== 'undefined' &&
        <>
          <p className={classes.p}>You can use templates below to substitute them with real values during execution. Copy the values
            (including dot and curly braces) and paste them in email below.</p>
          <div className={classes['flex-row']}>
            {["{{.CustomerFullName}}", "{{.CustomerPhone}}", "{{.CustomerEmail}}", "{{.CoborrowerFullName}}", "{{.LoanOfficerFullName}}", "{{.LoanOfficerPhone}}", "{{.LoanOfficerEmail}}", "{{.LoanOfficerNmls}}"].map(el => {
              return <Alert key={el} id={el} variant='dark'>{el}<Button onClick={() => copyToClipboard(el)} variant="link"><i className=" fa fa-copy " ></i></Button></Alert>
            })}
          </div>
        </>
      }
      {options?.[selected]?.clientSide && <Alert variant="info">This email will be prompted to be sent by loan officer  during a workflow.
        Loan officer will have a chance to modify email text.</Alert>}
      {selected === 'Request Acknowledgement' && <Alert variant="info">This email will be sent as an automatic response, when customer sends an email to loan officer.</Alert>}
      {selected === 'New account created' && <Alert variant="info">This email will be sent to a loan officer as a form of notification about a new customer.</Alert>}
      {['Account Verification', 'Remote Coborower Invite', 'Account Handoff'].includes(selected) && <Alert variant="info">This email contains a preconfigured link that would take customer to work on an application.</Alert>}
      {selected === 'Password Recovery' && <Alert variant="info">This email contains a preconfigured link that would take customer to recover a password.</Alert>}
      {selected === 'Document Request' && <Alert variant="info">{`This email contains specific {{range .Tasks}}. and {{end}} tags that help to generate a list of taks during runtime it also contains a preconfigured link that would take customer to work on tasks.`}</Alert>}
      {selected === 'Customer progress notification' && <Alert variant="info">This email will be sent to Loan Officer to notify them about customers progress.</Alert>}
      <div className={`${classes['flex-row']} ${classes['flex-center']}`} style={{ flexWrap: 'nowrap' }}>
        <p className='my-1 p-0'>Subject: </p>
        <Form.Control className='my-4' required type="text" onChange={subjectChangeHandler} placeholder='Subject' value={options?.[selected]?.subject} />
      </div>
      <Editor
        wrapperClassName='my-4'
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history']
        }}
      />
      <Button onClick={() => submitChange()}>Submit</Button>
      <InfoModal
        onHide={hideInfoModal}
        show={infoModal.show}
        modalTitle={infoModal.modalTitle}
        modalBody={infoModal.modalBody}
        modalFooter={infoModal.modalFooter}
      />
    </div>
  );
};

export default Emails;