import React, { useState, useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const { Dragger } = Upload;

const MobileHomeLoanApplicationFormExtractor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBorrowerLoanID, setNewBorrowerLoanID] = useState("");

  const history = useHistory();

  const handleCancel = () => {
    window.location.href = "/app/borrower/" + newBorrowerLoanID + "?handingoff=true&type=newaccount"
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (newBorrowerLoanID) {
      setIsModalOpen(true);
    }
  }, [newBorrowerLoanID]);

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload(file) {
      message.loading({ content: 'Processing...', key: 'uploadStatus', duration: 0 });
      return true;
    },
    customRequest({ file, onSuccess, onError }) {
      setNewBorrowerLoanID("");

      const formData = new FormData();
      formData.append('file', file);
      let token = sessionStorage.getItem("ZeitroA");
      fetch('/los/extractmanufacturedhomeloanapplication', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            message.error({ content: 'File upload failed.', key: 'uploadStatus', duration: 3 });
            onError('File extract failed.');
            throw new Error('File extract failed.');
          }
        })
        .then(data => {
          if (data.Status === "OK") {
            message.destroy('uploadStatus');
            onSuccess(data);
            setNewBorrowerLoanID(data.Token);
          } else {
            message.error({ content: data.Text, key: 'uploadStatus', duration: 3 });
            onError(data.Text);
            throw new Error(data.Text);
          }
        })
        .catch(error => {
          message.destroy('uploadStatus');
          message.error({ content: 'File upload failed.', key: 'uploadStatus', duration: 3 });
          onError(error);
        });
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <div className='p-5'>
      <div className='title-2'>Automatically creating a loan from the loan application form.</div>
      <div className='title-5 mt -2 mb-5'>By uploading the mobile home application form PDF, a loan will be automatically generated using data extracted from the document.</div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>

      <Modal footer={null} width={786} open={isModalOpen} onCancel={handleCancel}>
        <div className='text-center p-5 '>
          <img src="/images/create-success.svg" alt="success"/>
          <div className="success-text title-3 my-3">Loan created successfully</div>
          <div className="create-application-tip text-center">The loan for your customer has been successfully set up. You can now review and fill out the rest application.</div>
          <div className="mt-3">
            <Button type="primary" onClick={handleCancel}>Proceed to review the borrower information</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MobileHomeLoanApplicationFormExtractor;
