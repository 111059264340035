import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';

import useInput, { helper } from '../hooks/use-input';
import inputs from '../hooks/hook.module.css';
import classes from './General.module.css';
import { ESP_Gmail, ESP_Bluehost, ESP_PersonalGmail, ESP_Outlook, ESP_PersonalOutlook } from '../../State';

const validateEmail = email => String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const emailProviderValidator = value => value === ESP_Gmail || value === ESP_Bluehost || value === ESP_PersonalGmail;
const allwaysTrue = value => true;

const EmailSetup = () => {

  const [submitted, setSubmitted] = useState(false);
  const [header, setHeader] = useState(<p className='mt-3'>What email service provider do you use?</p>);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [bConfigs, setBConfigs] = useState('');
  const [refreshHeader, setRefreshHeader] = useState(false);

  const configRef = useRef();


  const {
    value: emailProvider,
    valueInputChangeHandler: emailProviderChangeHandler,
    valueInputBlurHandler: emailProviderBlurHandler,
    reset: resetEmailProvider,
    cssClasses: emailProviderClasses,
  } = useInput(emailProviderValidator, submitted);

  const {
    value: clientID,
    valueIsValid: clientIDIsValid,
    valueInputChangeHandler: clientIDChangeHandler,
    valueInputBlurHandler: clientIDBlurHandler,
    reset: resetClientID,
    cssClasses: clientIDClasses,
  } = useInput(allwaysTrue, submitted);

  const {
    value: secret,
    valueIsValid: secretIsValid,
    valueInputChangeHandler: secretChangeHandler,
    valueInputBlurHandler: secretBlurHandler,
    reset: resetSecret,
    cssClasses: secretClasses,
  } = useInput(allwaysTrue, submitted);

  const {
    value: tenantID,
    valueIsValid: tenantIDIsValid,
    valueInputChangeHandler: tenantIDChangeHandler,
    valueInputBlurHandler: tenantIDBlurHandler,
    reset: resetTenantID,
    cssClasses: tenantIDClasses,
  } = useInput(allwaysTrue, submitted);

  const {
    value: singleEmail,
    valueIsValid: singleEmailIsValid,
    valueInputChangeHandler: singleEmailChangeHandler,
    valueInputBlurHandler: singleEmailBlurHandler,
    reset: resetSingleEmail,
    validationCondition: validationConditionForSingleEmail,
    cssClasses: singleEmailClasses,
  } = useInput(validateEmail, submitted);

  const {
    value: password,
    valueIsValid: passwordIsValid,
    valueInputChangeHandler: passwordChangeHandler,
    valueInputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
    cssClasses: passwordClasses,
  } = useInput(allwaysTrue, submitted);

  const {
    value: password2,
    valueIsValid: password2IsValid,
    valueInputChangeHandler: password2ChangeHandler,
    valueInputBlurHandler: password2BlurHandler,
    reset: resetPassword2,
    cssClasses: password2Classes,
  } = useInput(allwaysTrue, submitted);

  useEffect(() => {
    setPasswordMatch(password === password2);
  }, [password, password2])

  useEffect(() => {
    const token = sessionStorage.getItem("ZeitroA");
    fetch('/los/getemailconfigs', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      }
    }).then(response => {
      if (!response.ok) {
        alert(`contact engineering team please, error: ${response.statusText}`);
        return;
      }

      response.json().then(json => {
        console.log(json.EmailProvider)
        console.log(json)
        if (json.EmailProvider !== '') {
          let header = `Your email provider is: ${json.EmailProvider}.`
          if (ESP_Gmail === json.EmailProvider) {
            header = header + ` API keys are ${json.KeysPresent ? '' : 'not '}present`
          } else if (ESP_Bluehost === json.EmailProvider || ESP_PersonalGmail === json.EmailProvider || ESP_PersonalOutlook === json.EmailProvider) {
            header = header + ` Email address for comunication is${json.SingleEmail === '' ? ' not present' : `: ${json.SingleEmail}`}. Password is ${json.SingleEmailPasswordPresent ? '' : 'not '}present`
            singleEmailChangeHandler(helper(json.SingleEmail));
          } else if (ESP_Outlook === json.EmailProvider) {
            header = header + ` Credentials are ${json.OutlookCredentialsPresent ? '' : 'not '}present`
          }
          setHeader(<Alert className='mt-3' variant='info'>{header}</Alert>)
          emailProviderChangeHandler(helper(json.EmailProvider));
        }
      })
    })
  }, [singleEmailChangeHandler, setHeader, emailProviderChangeHandler,refreshHeader])



  const updateEmailConfigs = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let condition = (emailProvider === ESP_Bluehost || emailProvider === ESP_PersonalGmail || emailProvider === ESP_PersonalOutlook) ? singleEmailIsValid && passwordMatch && passwordIsValid && password2IsValid : (emailProvider === ESP_Gmail ? bConfigs !== '': (emailProvider === ESP_Outlook? clientIDIsValid && tenantIDIsValid && secretIsValid : bConfigs !== ''))
    if (condition) {

      const token = sessionStorage.getItem("ZeitroA");

      const body = JSON.stringify({
        SingleEmail: singleEmail,
        Password: password,
        EmailProvider: emailProvider,
        Keys: bConfigs,
        ClientID: clientID,
        Secret: secret,
        TenantID: tenantID,
      });
      console.log(body)
      fetch('/los/updateemailconfigs', {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body,
      }).then(response => {
        if (!response.ok) {
          alert(`contact engineering team please, error: ${response.statusText}`);
          return;
        }

        alert("Email information is updated successfully")

      }).finally(() => {
        resetSingleEmail();
        resetPassword();
        resetPassword2();
        resetClientID();
        resetSecret();
        resetTenantID()
        setSubmitted(false);
        setRefreshHeader(!refreshHeader);
        setBConfigs('');
        if (configRef.current) {
          configRef.current.value = '';
        }
      })
    } else {
      if(emailProvider === ESP_Gmail && bConfigs === ''){
        alert('File is not uploaded');
      } else {
        alert('Something is wrong');
      }
    }
  };

  const configUploadHandler = event => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      let base64String;

      reader.onload = function () {
        base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        setBConfigs(base64String);
      }
      reader.readAsDataURL(file);
    }
  }

  return <div>
    {header}
    <Row>
      <Form.Group as={Col} controlId="emailProvider" className={emailProviderClasses}>
        <Form.Label>Email Provider</Form.Label>
        <Form.Control as='select' onChange={emailProviderChangeHandler} onBlur={emailProviderBlurHandler} value={emailProvider}>
          <option hidden></option>
          <option value={ESP_Gmail}>Gmail(Company Account)</option>
          <option value={ESP_PersonalGmail}>Gmail(Personal Account)</option>
          <option value={ESP_Outlook}>Outlook(Company Account)</option>
          <option value={ESP_PersonalOutlook}>Outlook(Personal Account)</option>
          <option value={ESP_Bluehost}>{ESP_Bluehost}</option>
        </Form.Control>
        <Form.Text className="text-muted">
          Email Provider
        </Form.Text>
      </Form.Group>
    </Row>
    {emailProvider === ESP_Bluehost &&
      <>
        <Row>
          <Form.Group as={Col} className={singleEmailClasses} controlId='singleEmail'>
            <Alert variant='info'>This email will be used for all email communication.</Alert>
            <Form.Label>Single outbound email address</Form.Label>
            <Form.Control type="text" placeholder={validationConditionForSingleEmail ? 'Should be left balnk or follow email format' : 'Enter Address or leave blank'} value={singleEmail} onChange={singleEmailChangeHandler} onBlur={singleEmailBlurHandler} />
            <Form.Text className="text-muted mb-3">Should be empty or follow email format.</Form.Text>
          </Form.Group>
          <Form.Group as={Col} controlId="Password" className={passwordMatch ? passwordClasses : inputs['invalid-input']}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={passwordChangeHandler}
              onBlur={passwordBlurHandler}
              placeholder="Password"
            />
            <Form.Text className="text-muted mb-3">Password</Form.Text>
          </Form.Group>
          <Form.Group as={Col} controlId="Passwor2" className={passwordMatch ? password2Classes : inputs['invalid-input']}>
            <Form.Label>Verify Password</Form.Label>
            <Form.Control
              type="password"
              value={password2}
              onChange={password2ChangeHandler}
              onBlur={password2BlurHandler}
              placeholder="Verify Password"
            />
            <Form.Text className="text-muted mb-3">Verify Password</Form.Text>
          </Form.Group>
        </Row>
        {!passwordMatch && <Alert variant='danger'>Passwords don't match</Alert>}
        <Row className='m-3'>
          <Col>
            <Button variant="primary" onClick={updateEmailConfigs}>
              Submit
            </Button>
          </Col>
        </Row>
      </>
    }
    {emailProvider === ESP_PersonalGmail &&
      <>
        <Row><Col className='text-left my-4 p-4'>
        <div>Create & use App Passwords</div>
        <div>If you use 2-Step-Verification, please enter an App Password.</div>

        <div>1. Go to your Google Account.https://myaccount.google.com/</div>
        <div>2. Select Security.</div>
        <div>3. Under "Signing in to Google," select App Passwords. You may need to sign in. If you don’t have this option, it might be because:
        <li>2-Step Verification is not set up for your account.</li>
        <li>2-Step Verification is only set up for security keys.</li>
        <li>Your account is through work, school, or other organization.</li>
        <li>You turned on Advanced Protection.</li></div>
        <div>4. At the bottom, choose Select app and choose "Mail" and then Select device and choose "Other" and then Generate.</div>
        <div>5. Follow the instructions to enter the App Password. The App Password is the 16-character code in the yellow bar on your device.
        Tap Done. And copy the generated password to input field below.</div>
        </Col></Row>
        <Row>
          <Col xs={12} md>
          <Form.Group as={Col} className={singleEmailClasses} controlId='singleEmail'>
            <Form.Label>Single outbound email address</Form.Label>
            <Form.Control type="text" placeholder={validationConditionForSingleEmail ? 'Should be left balnk or follow email format' : 'Enter Address or leave blank'} value={singleEmail} onChange={singleEmailChangeHandler} onBlur={singleEmailBlurHandler} />
            <Form.Text className="text-muted mb-3">Can be your noreply email address, follow email format.</Form.Text>
          </Form.Group>
          </Col>
          <Col xs={12} md>
          <Form.Group as={Col} controlId="Password" className={passwordMatch ? passwordClasses : inputs['invalid-input']}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={passwordChangeHandler}
              onBlur={passwordBlurHandler}
              placeholder="Password"
            />
            <Form.Text className="text-muted mb-3">Password</Form.Text>
          </Form.Group>
          </Col>
          <Col xs={12} md>
          <Form.Group as={Col} controlId="Passwor2" className={passwordMatch ? password2Classes : inputs['invalid-input']}>
            <Form.Label>Verify Password</Form.Label>
            <Form.Control
              type="password"
              value={password2}
              onChange={password2ChangeHandler}
              onBlur={password2BlurHandler}
              placeholder="Verify Password"
            />
            <Form.Text className="text-muted mb-3">Verify Password</Form.Text>
          </Form.Group>
          </Col>
        </Row>
        {!passwordMatch && <Alert variant='danger'>Passwords don't match</Alert>}
        <Row className='m-3'>
          <Col>
            <Button variant="primary" onClick={updateEmailConfigs}>
              Submit
            </Button>
          </Col>
        </Row>
      </>
    }
    {emailProvider === ESP_PersonalOutlook && 
      <>
      <Row><Col className='text-left my-4 p-4'>
      <div>Create & use App Passwords</div>

      <div>1. Sign in your Outlook Account.<span style={{color:"#325CEB",textDecoration:"underline", cursor:"pointer"}} onClick={() => window.open("https://account.microsoft.com","_blank")}>https://account.microsoft.com</span></div>
      <div>2. On the left side bar, select Security.</div>
      <div>3. In the Sercurity page, under open the "Account" category.</div>
      <div>4. Click on "Manage how i sign in".</div>
      <div>5. After it redirects you to the new page, locate the section "Addtional security"</div>
      <div>6. Check if the "Two-step verification" is turned on. If not, turn it on.</div>
      <div>7. After you turn on "Two-step verification", you should be able to see the "App passwords" in the lower part of the page.</div>
      <div>8. Click on "Create a new app password".</div>
      <div>9. Copy the generated password to the input field in here.</div>
      <div>10.Click on the "Submit" button in here, and the set up is done. </div>
      
      </Col></Row>
      <Row>
        <Col xs={12} md>
        <Form.Group as={Col} className={singleEmailClasses} controlId='singleEmail'>
          <Form.Label>Single outbound email address</Form.Label>
          <Form.Control type="text" placeholder={validationConditionForSingleEmail ? 'Should be left balnk or follow email format' : 'Enter Address or leave blank'} value={singleEmail} onChange={singleEmailChangeHandler} onBlur={singleEmailBlurHandler} />
          <Form.Text className="text-muted mb-3">Can be your noreply email address, follow email format.</Form.Text>
        </Form.Group>
        </Col>
        <Col xs={12} md>
        <Form.Group as={Col} controlId="Password" className={passwordMatch ? passwordClasses : inputs['invalid-input']}>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            placeholder="Password"
          />
          <Form.Text className="text-muted mb-3">Password</Form.Text>
        </Form.Group>
        </Col>
        <Col xs={12} md>
        <Form.Group as={Col} controlId="Passwor2" className={passwordMatch ? password2Classes : inputs['invalid-input']}>
          <Form.Label>Verify Password</Form.Label>
          <Form.Control
            type="password"
            value={password2}
            onChange={password2ChangeHandler}
            onBlur={password2BlurHandler}
            placeholder="Verify Password"
          />
          <Form.Text className="text-muted mb-3">Verify Password</Form.Text>
        </Form.Group>
        </Col>
      </Row>
      {!passwordMatch && <Alert variant='danger'>Passwords don't match</Alert>}
      <Row className='m-3'>
        <Col>
          <Button variant="primary" onClick={updateEmailConfigs}>
            Submit
          </Button>
        </Col>
      </Row>
    </>
    }
    {emailProvider === ESP_Gmail &&
      <>
        <div className={`${classes.textAlignLeft} m-3`}>
          <h3>1. Create a project</h3>
          <ol>
            <li>Sign in to <a href="https://console.cloud.google.com/" target='_blank' rel="noopener noreferrer">Google Cloud Platform console</a> using your corporate email address.</li>
            <li>Create a new project, using your domain as a value for organization field.</li>
          </ol>
          <h3>2. Enable API for Gmail</h3>
          <ol>
            <li>Select the project from a drop-down list at the top of the page.</li>
            <li>Go to APIs, click on enable APIs and services.</li>
            <li>Search for Gmail and click on Gmail API from the search results.</li>
            <li>Enable Gmail API.</li>
          </ol>
          <h3>3. Create a service account and private keys</h3>
          <ol>
            <li>Go to the <a href="https://console.cloud.google.com/iam-admin/serviceaccounts" target='_blank' rel="noopener noreferrer">Service Accounts</a> page. Make sure you are still using same email address and the project is selected in the drop-down list at the top of the page.</li>
            <li>Click Create Service Account.</li>
            <li>Fill in the form, only "Service account name" and "Service account ID" fields are mandatory.</li>
            <li>Click on the email of a newly created service account in the list.</li>
            <li>Go to keys tab.</li>
            <li>Click on "Add key" &#8594; "Create new key" &#8594; select JSON and click create &#8594; download the file.</li>
            <p className={classes.warningText}>Warning: Anybody, who has access to this file or it's content will be able to send and recieve emails on behalf of people within your organization.</p>
            <li>Upload downloaded file below. We will syphered content and store it. This configurations will be used to send emails on behalf of people within your organization.</li>
            <Form.Group controlId="JSONconfig" className={bConfigs === '' && submitted ? `${inputs['invalid-input']} mb-3` : 'mb-3'}>
              <Form.Control ref={configRef} type="file" onChange={configUploadHandler} />
            </Form.Group>
            <li>Go to details tab.</li>
            <li>Copy Unique ID (Client ID).</li>
          </ol>
          <h3>4.Grant permissions to the service account in G-Suit</h3>
          <ol>
            <li>Sign in to <a href='http://admin.google.com/' target='_blank' rel="noopener noreferrer">G-Suite admin portal</a>  using your G-Suite admin account.</li>
            <li>Go to Security.</li>
            <li>Click API controls.</li>
            <li>Click Manage Domain Wide Deligation.</li>
            <li>Click Add new</li>
            <li>Use Unique ID (Client ID) from 3.9.</li>
            <li>Add following API Scopes (without quotes) : "https://www.googleapis.com/auth/gmail.metadata", "https://www.googleapis.com/auth/gmail.modify". For you reference, list of scopes and explanations to them: <a href='https://developers.google.com/gmail/api/auth/scopes' target='_blank' rel="noopener noreferrer">here</a></li>
          </ol>
          <h3>5. Click on the "Submit" button.</h3>
          <div>*Reminder: Please send an email to us at <span style={{color:"#325CEB", fontWeight:"500"}}>andrew.zhang@zeitro.com</span> to inform us that you have finished the setup. </div>
          <div>Addtionally, in the email, please also specify the "noreply" email address and the "support" email address that you would like to use for system communication.</div>

        </div>
        <Row>
          <Col>
            <Button onClick={updateEmailConfigs}>Submit</Button>
          </Col>
        </Row>
      </>
    }
    {emailProvider === ESP_Outlook &&
            <>
            <div className={`${classes.textAlignLeft} m-3`}>
              <h3>1. App registration</h3>
              <ol>
                <li>Sign in to <a href="https://portal.azure.com/" target='_blank' rel="noopener noreferrer">Microsoft Azure Poral</a> using your corporate admin email address.</li>
                <li>In the search bar at the top of the page, search for "App registrations" and select.</li>
                <li>Select "new registration"</li>
                <li>In the "Name" input box, enter "zeitro"</li>
                <li>Under the question "Who can use this application or access this API?", select "Accounts in this organizational directory only".</li>
                <li>Click on "Register"</li>
              </ol>
              <h3>2. Enable Microsoft API permissions for Zeitro to send email through your business account.</h3>
              <ol>
                <li>Still in the "App registration" page, select the "All applications" tab.</li>
                <li>Click open the app named "zeitro", which you just registered.</li>
                <li>Under the title "Build your application with the Microsoft identity platform", select "View API permission".</li>
                <li>In the "Configured permissions" section, select "Add a permission".</li>
                <li>In the tab "Microsoft APIs", select "Microsoft Graph"</li>
                <li>Select "Application permissions"</li>
                <li>Scroll down until you find the "Mail" category and then expand it.</li>
                <li>Check the "Mail.Send" and "Mail.ReadWrite" permissions.</li>
                <li>Scroll down to the very bottom until you find the "User" category and then expand it.</li>
                <li>Check the "User.Read.All" permissions.</li>
                <li>Select "Add permissions".</li>
                <li>Now you should be back to the "API permissions" page, select "Grant admin consent for Zeitro.</li>
                <li>Select "Yes" in the pop up window.</li>
                <li>Wait a few seconds for the website to load the changes.</li>
              </ol>
              <h3>3. Copy and submit app credentials</h3>
              <ol>
                <li>On the left sidebar, select "Overview".</li>
                <li>In the "Essentials" section, copy the value of "Application (client) ID", and paste it into the correponding input box below.</li>
                <li>In the "Essentials" section, copy the value of "Directory (tenant) ID", and paste it into the correponding input box below.</li>
                <li>In the "Essentials" section, click open the "Client credentials".</li>
                <li>Now you should be in the "Certificates & secrets" page, and make sure your are in "Client secrets" tab.</li>
                <li>Select "New client secret</li>
                <li>In the pop up window on the right side, enter "zeitro-key" into the "Description" input box.</li>
                <li>Select "730 days" for the "Expires" box.</li>
                <li>Click "Add".</li>
                <li>Now you should see a record inserted into the table under "Client secrets" tab, in "Certificates & secrets" page.</li>
                <li>Copy the text under the "Value" column of the table, and paste it into the corresponding input box below.</li>
              </ol>
              <h3>4.Click on the "Submit" button.</h3>
              <div>*Reminder: Please send an email to us at <span style={{color:"#325CEB", fontWeight:"500"}}>andrew.zhang@zeitro.com</span> to inform us that you have finished the setup. </div>
              <div>Addtionally, in the email, please also specify the "noreply" email address and the "support" email address that you would like to use for system communication.</div>

            </div>
            <Row>
              <Col xs={12} md>
                <Form.Group as={Col} controlId="clientID" className={clientIDClasses}>
                  <Form.Label>Client ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={clientID}
                    onChange={clientIDChangeHandler}
                    onBlur={clientIDBlurHandler}
                    placeholder="Client ID"
                  />
                  <Form.Text className="text-muted mb-3">Copy the client id from Azure portal here</Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} md>
                <Form.Group as={Col} controlId="secretValue" className={secretClasses}>
                  <Form.Label>Client credentials (secret value) </Form.Label>
                  <Form.Control
                    type="text"
                    value={secret}
                    onChange={secretChangeHandler}
                    onBlur={secretBlurHandler}
                    placeholder="Secret Value"
                  />
                  <Form.Text className="text-muted mb-3">Copy the secret value from Azure portal here</Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} md>
                <Form.Group as={Col} controlId="tenantID" className={tenantIDClasses}>
                  <Form.Label>Directory ID (tenant ID)</Form.Label>
                  <Form.Control
                    type="text"
                    value={tenantID}
                    onChange={tenantIDChangeHandler}
                    onBlur={tenantIDBlurHandler}
                    placeholder="Tenant ID"
                  />
                  <Form.Text className="text-muted mb-3">Copy the tenant id from Azure portal here</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <div className='d-flex justify-content-center'>
              <div>
                <Button onClick={updateEmailConfigs}>Submit</Button>
              </div>
            </div>
          </>
    }
  </div>
}

export default EmailSetup;