import InnerHTML from "dangerously-set-html-content";
import React from 'react';
import { useLocation } from "react-router-dom";

const InterviewBorrowerView = (props) => {
  const location = useLocation();
  const pathSections = location.pathname.split('/');
  const loanid = pathSections[pathSections.length - 1];
  const customerid = localStorage.getItem("customerid")
  const redirects = JSON.parse(localStorage.getItem("redirects"))
  const appUrl = redirects["appurl"]

  let finalhtml = `<script id="zeitrotag" src=`+appUrl+`/api/zeitrotag.js?customerid=`+customerid+`&loid=&pageid=borrowerinterviewforlo&loanid=`+loanid+`> </script>`;

  return (
    <>
      <div className="text-center" style={{paddingTop:"30px",paddingBottom:"10px",color:"#202020d1",fontWeight:"700",fontSize:"20px"}}>Monitor your borrower's progress on their application.</div>
      <div className="borrowerViewWrap" style={{display:"flex",justifyContent:"center"}}>
          <InnerHTML className="innerHTML" style={{height:"100%",width:"90%"}} html={finalhtml} />
      </div>
    </>
  );
};

export default InterviewBorrowerView;