import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import {
    Form,
    Input,
} from 'antd'
import "./AboutmeSetup.css"
import "./CompanySetup.css"
import { isEmpty } from 'lodash'
const { TextArea } = Input
const CompanySetup = forwardRef(({ info, disabled, finish, change, changeProgress }, ref) => {
    const [form] = Form.useForm();
    const [percent, setPercent] = useState(0);
    const [required, setRequired] = useState(38);
    useEffect(() => {
        const formValues = form.getFieldValue()
        setProgressValue(formValues)
    }, [])
    useEffect(() => {
        form.setFieldsValue({
          banner: info.banner,
          companyIntroduction: info.companyIntroduction,
          companyLink: info.companyLink,
          companyWebsite: info.companyWebsite,
        })
    }, [info])
    const progressStatus = () => {
        if (percent < required) {
            return 'undone'
        } else if (percent >= required && percent < 100) {
            return 'normal'
        }
    }
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const onFinish = (val) => {

    }
    const formInitial = { 
        banner: info.banner,
        companyIntroduction: info.companyIntroduction,
        companyLink: info.companyLink,
        companyWebsite: info.companyWebsite
     }
    const handleValuesChange = (changedValues, allValues) => {
        const form = { ...allValues }
        setProgressValue(form)
        change(form)
    }
    const setProgressValue = (form) => {
        const fillNum = Object.keys(form).filter((key) => {
            const value = form[key];
            return !isEmpty(value)
        }).length
        const progressValue = Math.round((fillNum / 4) * 100)
        setPercent(progressValue)
        changeProgress(progressValue)
    }
    return (
        <div className="about-me-setup">
            <div className='form-wrap'>
                <Form
                    className='about-me-form'
                    layout='vertical'
                    form={form}
                    initialValues={formInitial}
                    onFinish={onFinish}
                    onValuesChange={handleValuesChange}
                    validateMessages={validateMessages}
                    size="large"
                    disabled={disabled}
                >
                    <Form.Item
                        name="companyWebsite"
                        label="Company name"
                    >
                        <Input placeholder=""   maxLength={40}/>
                    </Form.Item>
                    <Form.Item
                        name="banner"
                        label="Company banner"
                    >
                        <Input placeholder="" maxLength={200}/>
                    </Form.Item>
                    <Form.Item
                        name="companyLink"
                        label="Company website link"
                    >
                        <Input placeholder="" maxLength={200}/>
                    </Form.Item>
                    <Form.Item
                        name="companyIntroduction"
                        label="Company introduction "
                    >
                        <TextArea placeholder="" maxLength={200}/>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
})
export default CompanySetup;