import React from 'react';
import { Form, Button, Modal, Col, Row } from 'react-bootstrap';

import { LOBS_TODOs, LOBS_In_Progress, LOBS_Done } from '../State';
import DocumentPreview from '../DocumentPreview';

class CardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocument: false,
      documentTitle: '',
      documentOnTop: false,
      url: "",
      download: "",
      secondShowDocument: false,
      secondDocumentTitle: '',
      secondDocumentOnTop: false,
    }
    this.isLastShownSecond = true
    this.shownDocuments = { first: false, second: false }
    this.aref = React.createRef();
    this.prefix = "data:image/png;base64,"
  }

  showDocument = (attachment) => {
    if (!this.shownDocuments.first && !this.shownDocuments.second) {
      this.setState({ showDocument: true, documentTitle: attachment.filename })
      this.shownDocuments.first = true
    } else if (this.shownDocuments.first && !this.shownDocuments.second) {
      this.setState({ secondShowDocument: true, secondDocumentTitle: attachment.filename })
      this.shownDocuments.second = true
    } else if (!this.shownDocuments.first && this.shownDocuments.second) {
      this.setState({ showDocument: true, documentTitle: attachment.filename })
      this.shownDocuments.first = true
    } else if (this.shownDocuments.first && this.shownDocuments.second) {
      if (this.isLastShownSecond) {
        this.setState({ showDocument: true, documentTitle: attachment.filename })
        this.shownDocuments.first = true
      } else {
        this.setState({ secondShowDocument: true, secondDocumentTitle: attachment.filename })
        this.shownDocuments.second = true
      }
    }
    this.isLastShownSecond = !this.isLastShownSecond

  }

  download = (id, index) => {
    let token = sessionStorage.getItem("ZeitroA")
    let body = { ID: this.props.borrowerId }

    let disposition

    let ifNotPdf = (body) => {
      new Promise((resolve, reject) => {
        const reader = body.getReader();

        resolve(new ReadableStream({
          start(controller) {
            return pump();

            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }

                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          }
        }))
      }).then(stream => new Response(stream))
        .then(r => r.blob())
        .then(blob => {

          let download = disposition.slice(1).join().replace(/"/g, '')

          const url = window.URL.createObjectURL(blob);
          this.setState({ url: url, download: download })

          this.aref.current.click()
          this.forceUpdate();
        })
    }

    fetch('/los/downloaddoc/' + id, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(response => {
      if (response.headers.get("Content-Type") === 'application/pdf') {
        response.json().then(js => {
          let byteCharacters = atob(js.Base64);
          let byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);
          let file = new Blob([byteArray], { type: 'application/pdf;base64' });
          window.open(URL.createObjectURL(file), "_blank");
        })
      } else {
        disposition = response.headers.get("Content-Disposition").split('=')
        return response.body
      }
    }).then(body => {
      if (typeof body !== 'undefined') {
        ifNotPdf(body)
      }
    }).catch(function (err) {
      console.log('Fetch Error :-S', err);
    })
  }

  hideDocument = (e) => {
    e.stopPropagation()
    this.shownDocuments.first = false
    this.setState({ showDocument: false })
  }

  secondHideDocument = (e) => {
    e.stopPropagation()
    this.shownDocuments.second = false
    this.setState({ secondShowDocument: false })
  }

  zIndexToogler = () => {
    this.setState({ documentOnTop: !this.state.documentOnTop });
  }

  secondZIndexToogler = () => {
    this.setState({ secondDocumentOnTop: !this.state.secondDocumentOnTop });
  }

  handleModalCloseInternal = () => {
    this.isLastShownSecond = true
    this.shownDocuments.first = false
    this.shownDocuments.second = false
    this.setState({ showDocument: false, secondShowDocument: false })
  }

  documentsBellowModal = () => {
    if (this.state.secondDocumentOnTop) {
      this.setState({ documentOnTop: false, secondDocumentOnTop: false });
    }
  }

  render() {
    let downloadFile = (id) => {
      return (e) => {
        this.download(id)
      }
    }
    let documentPreview = (attachment) => {
      return (e) => {
        e.stopPropagation()
        this.showDocument(attachment)
      }
    }

    let stageDefault = ''
    switch (this.props.modalStage) {
      case LOBS_TODOs:
        stageDefault = LOBS_TODOs;
        break;
      case LOBS_In_Progress:
        stageDefault = "In Progress";
        break;
      case LOBS_Done:
        stageDefault = LOBS_Done;
        break
      default:
        console.log("Should not be here!")
        break
    }
    return (
      <Modal
        onHide={this.props.onHide}
        onExited={this.handleModalCloseInternal}
        show={true}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={`${(this.state.showDocument && !this.state.secondShowDocument) ? 'card-modal-loboard' : ''}`}
      >
        <a hidden ref={this.aref} href={this.state.url} download={this.state.download}> </a>
        <Modal.Header closeButton onClick={this.documentsBellowModal}>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body onClick={this.documentsBellowModal}>
          <Form>
            <p style={{ 'marginBottom': 5 }}>Task was created: {new Date(this.props.modalCreated).toLocaleDateString()}</p>
            <p style={{ 'marginBottom': 5 }}>Deadline: {new Date(this.props.modalDeadline).toLocaleDateString()}</p>
            {this.props.modalAttachments.length !== 0 &&
              <Form.Group controlId="formAttachments">
                <Form.Label>
                  Attached documents:
                </Form.Label>
                {this.props.modalAttachments.map((attachment) =>
                  <Row key={attachment.serialId}>
                    <Col className='documentPreview hover' sm='11' onClick={attachment.filename.endsWith('.pdf') ? documentPreview(attachment) : downloadFile(attachment.serialId)}>{attachment.filename}</Col>
                    <Col className='documentDownload' onClick={downloadFile(attachment.serialId)} sm='1'><i style={{ fontSize: '20px' }} className="fas fa-file-download"></i></Col>
                  </Row>
                )}
              </Form.Group>}

            <Form.Group controlId="formStage">
              <Form.Label>Task stage</Form.Label>
              <Form.Control as='select' onChange={this.props.onStageChange} defaultValue={stageDefault}>
                <option>{LOBS_TODOs}</option>
                <option>In Progress</option>
                <option>{LOBS_Done}</option>
              </Form.Control>
              <Form.Text className="text-muted">
                Tasks's stage
              </Form.Text>
            </Form.Group>

            {this.props.modalSubtasks.length !== 0 && <Form.Group controlId="formSubtasks">
              <Form.Label>Checkboxes</Form.Label>
              {this.props.modalSubtasks.map((item, index1) => (
                <div key={item[0]} className="mb-3">
                  <h2 style={{ 'marginBottom': 10, 'color': 'black', 'fontSize': '16px' }}>{item[0]}</h2>
                  {item[1].map((item2, index2) => (
                    <Form.Check
                      key={`${item[0]} ${item2[0]}`}
                      type={'checkbox'}
                      id={`${item[0]} ${item2[0]}`}
                      label={item2[0]}
                      defaultChecked={item2[1]}
                      onChange={this.props.onCheckboxChange}
                    />
                  ))}
                </div>
              ))}
            </Form.Group>}
          </Form>
        </Modal.Body>
        <Modal.Footer onClick={this.documentsBellowModal}>
          <Button variant="primary" onClick={this.props.onSubmit}>Submit</Button>
          <Button variant="danger" onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
        {this.state.showDocument && <DocumentPreview
          onClick={this.zIndexToogler}
          className={`document ${this.state.documentOnTop ? 'document-on-top' : ''}`}
          onClose={this.hideDocument}
          fileName={this.state.documentTitle}
        />}
        {this.state.secondShowDocument && <DocumentPreview
          onClick={this.secondZIndexToogler}
          className={`second-document ${this.state.secondDocumentOnTop ? 'second-document-on-top' : ''}`}
          onClose={this.secondHideDocument}
          fileName={this.state.secondDocumentTitle}
        />}
      </Modal>)
  }
}

export default CardDetails;