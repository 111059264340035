import React,{ useState } from 'react';
import {
  Checkbox,
  InputNumber,
  Button,
  Modal
} from 'antd';
import "./Common.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import "./Declarations.css";

const mapStateToProps = (state) => {
    return {
        property: state.application.property,
        application: state.application,
        progress: state.progress,
    }
  }

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
      dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
      dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
      dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
    changeDeclaration: (val, verb) => {
        dispatch(act.ChangeDeclaration(val, "borrower", verb))
    },
  });


export default function Declarations(props) {
    let dec = props.application.borrower.declarations
    const bankrupcies = ["chapter7", "chapter11", "chapter12", "chapter13"]
    const initialList =[
        {
            question: 'Will you occupy the property as your primary residence?',
            id: 'occupyasprimary',
            select: dec.occupyasprimary,
        },
        {
            question: 'If this is a Purchase Transaction. Do you have a family relationship or business affiliation with the seller of the property?',
            id: 'familyrelationship',
            select: dec.familyrelationship,
        },
        {
            question: 'Are you borrowing any money for this real estate transaction (e.g. money for your closing costs or down payment) or obtaining any money from another party such as the seller or realtor, that you have not disclosed on this loan application?',
            id: 'downpaymentborrowed',
            select: dec.downpaymentborrowed,
            money: dec.moneyborrowed,
        },
        {
            question: 'Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?',
            id: 'applyingonanother',
            select: dec.applyingonanother,
        },
        {
            question: 'Have you or will you be applying for any new credit (e.g. installment loan, credit card, etc.) on or before closing this loan that is not disclosed with this application?',
            id: 'applyingnewcredit',
            select: dec.applyingnewcredit,
        },
        {
            question: 'Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?',
            id: 'cleanenergylien',
            select: dec.cleanenergylien,
        },
        {
            question: 'Are there any outstanding judgments against you?',
            id: 'judgements',
            select: dec.judgements,
        },
        {
            question: 'Are you currently delinquent or in default on a Federal Debt?',
            id: 'delinquentondebt',
            select: dec.delinquentondebt,
        },
        {
            question: 'Are you a party to a lawsuit in which you potentially have any personal financial liability?',
            id: 'lawsuit',
            select: dec.lawsuit,
        },
        {
            question: 'Have you conveyed title to any property in lieu of foreclosure in the past 7 years?',
            id: 'conveyedtitle',
            select: dec.conveyedtitle,
        },
        {
            question: 'Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?',
            id: 'loanforeclosure',
            select: dec.loanforeclosure,
        },
        {
            question: 'Have you had property foreclosed upon in the last 7 years?',
            id: 'propertyforeclosure',
            select: dec.propertyforeclosure,
        },
        {
            question: 'Have you declared bankruptcy within the past 7 years?',
            id: 'bancrupt',
            select: dec.bancrupt,
            bankruptcyType: bankrupcies.filter(n=>dec[n]===true),
        }
    ]
    const [questionList, setQuestionList] = useState(initialList);
    const [submitModal, setSubmitModal] = useState(false)
    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }
    const onChangeRadio = (item, radio) => {
        let list = questionList.map(question => {
            if (question.id === item.id) {
                props.changeDeclaration(radio.value, item.id)
                return { ...question, select: radio.value };
            } else {
                return question;
            }
        })
        props.onSelect(list)
        setQuestionList(list)
    }
    const onChangeBankruptcyType =(checkedValues) => {
        setQuestionList(questionList.map(question => {
            if (question.id === 'bancrupt') {
                bankrupcies.map(b=>{
                    if (checkedValues.includes(b)) {
                        props.changeDeclaration(true, b)
                    } else {
                        props.changeDeclaration(false, b)
                    }
                })
                return { ...question, bankruptcyType: checkedValues };
            } else {
                return question;
            }
        }));
    }
    const onChangeMoney = (value) => {
        setQuestionList(questionList.map(question => {
            if (question.id === 'downpaymentborrowed') {
                props.changeDeclaration(String(value), "moneyborrowed")
                return { ...question, money: value };
            } else {
                return question;
            }
        }));
    };
    const btnDisable = () => {
        let disable = questionList.some(item => item.select === '')
        let questionId3 = questionList.find(item => item.id === 'downpaymentborrowed')
        let questionId13 = questionList.find(item => item.id === 'bancrupt')
        if (questionId3.select === 'yes' && questionId3.money === '') {
            disable = true
        } else if (questionId13.select === 'yes' && questionId13.bankruptcyType.length === 0) {
            disable = true
        }
        return disable
    }
    const Review = () => {
        console.log(questionList)
        props.finish(questionList)
        setSubmitModal(true)
    }
    const options = [
        {
            value: 'yes',
            label: 'Yes'
        },
        {
            value: 'no',
            label: 'No'
        }
    ]
    const bankruptcyTypeOptions = [
        {
            value: 'chapter7',
            label: 'Chapter 7'
        },
        {
            value: 'chapter11',
            label: 'Chapter 11'
        },
        {
            value: 'chapter12',
            label: 'Chapter 12'
        },
        {
            value: 'chapter13',
            label: 'Chapter 13'
        }
    ]
    return (
        <div className="application-form text-left">
            <Modal title="You've submitted your application!" open={submitModal} onOk={()=>setSubmitModal(false)} >
                <p style={{fontWeight: 500, marginBottom: 10, marginTop: 15}}>What comes next?</p>
                <p>Your loan officer will review your application and request any necessary supporting documents. If you have any questions, don't hesitate to reach out to them before they contact you.</p>
            </Modal>
            <div className='form-title'>About this property and your money for this loan</div>
            <div className='question-list'>
                {
                    questionList.map((item, index) =>
                        <>
                            <div className='question-item' key={item.id}>
                                <div className='question-content'>{item.question}</div>
                                <div className='question-options'>
                                    {
                                        props.editMode ? options.map((radio, radioIndex) =>
                                            <div className={`question-btn ${(item.select === radio.value && radio.value === 'yes') ? 'select-yes' : (item.select === radio.value && radio.value === 'no') ? 'select-no' : ''}`}  key={`${item.id}${radioIndex}`} onClick={() => onChangeRadio(item, radio)}>
                                                <div className='radio'>
                                                    {
                                                        item.select === radio.value && (
                                                            <div className='circle-center'></div>
                                                        )
                                                    }
                                                </div>
                                                <span className='radio-text'>{radio.label}</span>
                                            </div>
                                        ) : (
                                            <div className='select-value'>{item.select}</div>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                item.id === 'downpaymentborrowed' && item.select === 'yes' && (
                                    <div className='sub-select money'>
                                        <span className='sub-select-text'> · What is the amount of this money?</span>
                                        {
                                            props.editMode ? (
                                                <InputNumber
                                                    prefix="$"
                                                    size="large"
                                                    style={{ width: '212px' }}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={onChangeMoney}
                                                    defaultValue={props.application.borrower.declarations.moneyborrowed}
                                                />
                                            ) : (
                                                <div className='select-value'>{`$ ${inputFormatter(props.application.borrower.declarations.moneyborrowed)}`}</div>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                item.id === 'bancrupt' && item.select === 'yes' && (
                                    <div className='sub-select bankruptcy'>
                                        <span className='sub-select-text'> · Please identify the type(s) of bankruptcy.</span>
                                        <Checkbox.Group options={bankruptcyTypeOptions} disabled={!props.editMode} onChange={onChangeBankruptcyType} defaultValue={bankrupcies.filter(n=>props.application.borrower.declarations[n]===true)}/>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
        </div>
    )
}

Declarations = connect(mapStateToProps, mapDispatchToProps)(Declarations);
