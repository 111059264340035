import { useReducer, useCallback } from "react";

import classes from './hook.module.css';

const inputStateReducer = (prevState, action) => {

  if (action.type === 'INPUT') {
    return { ...prevState, value: action.value };
  }
  if (action.type === 'BLUR') {
    return { ...prevState, isTouched: true };
  }
  if (action.type === 'RESET') {
    return { isTouched: false, value: '' };
  }
  return {
    value: '',
    isTouched: false,
  }
};

const useInput = (validationFunction, submitted) => {

  const [state, dispatch] = useReducer(inputStateReducer, {
    value: '',
    isTouched: false,
  });

  const valueIsValid = validationFunction(state.value);
  const hasError = !valueIsValid && state.isTouched;
  const validationCondition = hasError || (!valueIsValid && submitted);
  const cssClasses = `mb-3 ${validationCondition ? classes['invalid-input'] : submitted ? classes['valid-input'] : ''}`;

  const valueInputChangeHandler = useCallback(e => {
    dispatch({ type: 'INPUT', value: e.target.value });
  },[]);

  const valueInputBlurHandler = useCallback(e => {
    dispatch({ type: 'BLUR' });
  }, [])

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, [])



  return { value: state.value, valueIsValid, valueInputChangeHandler, valueInputBlurHandler, reset, validationCondition, cssClasses };
}

export const helper = (value) => ({ target: { value } });

export default useInput;