import React, { Component } from 'react';
import { Form, Spinner, Col, Alert, Button } from 'react-bootstrap';
import '../../Utils.css';

import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const sendingMessage = "Sending email..."
const sentMessage = "Email has been sent succsessfully."

class ContactCustomer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      body: "",
      progress: 0,
      message: "",
      editorState: this.props.body ?
        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.body)))
        : EditorState.createEmpty(),
      customers: [],
      email: "",
      subject: this.props.subject || "",
      firstName: "",
      lastName: "",
      loEmail: "",
      loPhone: ""
    };
    this.myForm = React.createRef();
    this.textarea = React.createRef();
    this.editor = React.createRef();
    this.dropDown = React.createRef();
    this.emailTemplates = {
      'Additional Conditions': {},
      'Conditional Approval': {}
    }
  }
  componentDidMount = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/customers', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Past": false,
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            this.setState({ customers: js.Customers }, () => {
              if (this.props.customerID) {
                let tmp = this.state.customers.find(c => c.ID === this.props.customerID)
                let { FirstName, LastName, Email } = tmp
                this.dropDown.current.value = `${LastName} ${FirstName} <${Email}>`
                this.setState({ email: Email })
              }
            })
          }
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });

    fetch('/los/getprofile', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          alert('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        // Examine the text in the response
        response.json().then(js => {
          if (js.Status !== "OK") {
            alert("Error: " + js.Error)
          } else {
            let lo = js.LoanOfficers[0]
            this.setState({
              firstName: lo.FirstName, lastName: lo.LastName,
              loEmail: lo.Email, loPhone: lo.Phone
            })
          }
        });
      }
    ).catch(function (err) {
      alert('Fetch Error :-S', err);
    });

    fetch('/los/getemailtemplates', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        'X-Keys': JSON.stringify(Object.keys(this.emailTemplates)),
        'X-IsServerSide': false
      }
    }).then(response => {
      if (response.status !== 200) {
        alert('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      response.json().then(js => {
        this.emailTemplates = JSON.parse(js.Emails)
      })
    })
  }
  isValidated() {
    let condition = this.state.email !== ''
    condition = condition && this.state.subject !== ''
    this.setState({ validated: !condition })
    return condition;
  }
  sendMail = (html) => {
    if (this.isValidated()) {
      let token = sessionStorage.getItem("ZeitroA")
      this.setState({ progress: 1, message: sendingMessage })

      let customer = this.state.customers.find(c => c.Email === this.state.email)

      fetch('/los/sendemail', {
        method: 'POST',
        body: JSON.stringify({ 
          BorrowerID: customer.ID,   
          Subject: this.state.subject,
          Body: "<html><body>" + html + "</body></html>" 
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
          Cache: "no-cache"
        },
      }).then(
        response => {
          if (response.status !== 200) {
            let m = <Alert variant='danger'>Looks like there was a problem sending email. Error {response.status}</Alert>
            this.setState({ progress: 0, message: m })

            return;
          }
          // Examine the text in the response
          response.json().then(js => {
            this.setState({ progress: 0 })
            if (js.Status !== "OK") {
              let m = <Alert variant='danger'>Looks like there was a problem sending email. {js.Error}</Alert>
              this.setState({ progress: 0, message: m })
            } else {
              this.setState({ editorState: EditorState.createEmpty() })
              this.setState({ progress: 0, body: "", message: sentMessage })
            }

          });
        }
      )
        .catch(function (err) {
          console.log('Fetch Error :', err);
        });
    }
  }
  handleSubmit = (event) => {

    var html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))

    event.preventDefault();
    event.stopPropagation();
    this.sendMail(html)
    return false
  }
  changeText = e => {
    this.setState({ body: e.target.value.toString() })
  }
  selectEmail = e => {
    let tmp = this.state.customers.filter(c => e.target.value.includes(c.FirstName) && e.target.value.includes(c.LastName) && e.target.value.includes(c.Email))
    this.setState({ email: tmp[0].Email })
  }
  subjectHandler = e => {
    this.setState({ subject: e.target.value })
  }
  onEditorStateChange = (e) => {

    this.setState({
      editorState: e,
    });
  };

  fillWithAdditionalConditions = () => {
    this.setState({
      editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.emailTemplates['Additional Conditions'].Content))),
      subject: this.emailTemplates['Additional Conditions'].Subject
    })
  }

  fillWithConditionalApproval = () => {
    this.setState({
      editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.emailTemplates['Conditional Approval'].Content))),
      subject: this.emailTemplates['Conditional Approval'].Subject
    })
  }

  render = () => {
    let onEditorStateChange = e => {

      this.onEditorStateChange(e)
    }
    return (
      <div className="mx-2 p-2 whitebackground">
        {!this.props.subject &&
          <div style={{ display: 'flex' }}>
            <Button onClick={this.fillWithAdditionalConditions} className='button-custom'>Additional Conditions Required</Button>
            <Button onClick={this.fillWithConditionalApproval} className='button-custom'>Conditional Approval</Button>
          </div>
        }
        <Form noValidate validated={this.state.validated} ref={this.myForm} onSubmit={this.handleSubmit}>
          <Form.Row className="mt-3">
            <Col>
              <Form.Group controlId="emails">
                <Form.Control as="select" ref={this.dropDown} onChange={this.selectEmail} className={`${this.state.validated && "validationWrongField custom-bootstrap-validated-dropdown-fix"}`}>
                  <option hidden></option>
                  {this.state.customers.map(c => (
                    <option key={c.ID}>{`${c.LastName} ${c.FirstName} <${c.Email}>`}</option>
                  ))}
                </Form.Control>
                <Form.Label>Select an email</Form.Label>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="mt-3">
            <Col>
              <Form.Group controlId="subject">
                <Form.Control required type="text" onChange={this.subjectHandler} placeholder='Subject' value={this.state.subject} />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="mt-3">
            <Col>
              <Form.Group controlId="Sources" className="text-left" >
                <Form.Label className="text-left" ><Spinner className="position-relative" size="sm" variant="primary" animation={((this.state.progress > 0) && (this.state.progress < 100)) ? "border" : ""} />{this.state.message}</Form.Label>
                <Editor ref={this.editor}
                  editorState={this.state.editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history']
                  }}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row >
            <Col   >
              <Button className='button-custom' id="submitme" variant="primary" type="submit">Send</Button>
            </Col>
          </Form.Row>
        </Form>

      </div>
    );
  }
}

export default ContactCustomer
