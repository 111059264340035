// Borrower Page Constants
export const maritalStatusOptions = [
    {
        value: 'married',
        label: 'Married'
    },
    {
        value: 'single',
        label: 'Single'
    },
    {
        value: 'unmarried',
        label: 'Unmarried'
    },
    {
        value: 'separated',
        label: 'Separated'
    },
]
export const borrowerTypeOptions = [
    {
        value: 'Individual',
        label: 'Individual'
    },
    {
        value: 'LLC',
        label: 'LLC'
    },
    {
        value: 'Corporation',
        label: 'Corporation'
    },
    {
        value: 'Partnership',
        label: 'Partnership'
    },
    {
        value: 'Trust',
        label: 'Trust'
    }
]
export const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    }
}

export const initialRaceVal = {
    notfurnished: false,
    asian: false,
    asiandetails: {
        chinese: false,
        filipino: false,
        japanese: false,
        korean: false,
        vietnamese: false,
        asianindian: false,
        otherasian: false,
        otherdesctiption: "",
    },
    pacific: false,
    pacificdetails: {
        hawaian: false,
        samoan: false,
        guamanian: false,
        other: false,
        otherdesctiption: "",
    },
    black: false,
    americanindian: false,
    indiantribe: "",
    white: false,
}

// PropertyAndLoan Page Constants
export const PropertyTypeOptions = [
    {
        value: 'singlefamilyattached',
        label: 'Single family residence (Attached)'
    },
    {
        value: 'singlefamilydetached',
        label: 'Single family residence'
    },
    {
        value: 'twofamily',
        label: '2 unit family',
    },
    {
        value: 'threefamily',
        label: '3 unit family',
    },
    {
        value: 'fourfamily',
        label: '4 unit family',
    },
    {
        value: 'multistory',
        label: '5+ multifamily',
    },
    {
        value: 'condominium',
        label: 'Condo / PUD'
    },
    {
        value: 'townhouse',
        label: 'Townhouse'
    },
    {
        value: 'commercial',
        label: 'Commercial'
    },
    {
        value: 'mixeduse',
        label: 'Mixed use',
    },
]
export const loanTypeOptions = [
    {
        value: 'fixflip',
        label: 'Fix / Sell'
    },
    {
        value: 'fixrent',
        label: 'Fix / Rent'
    },
    {
        value: 'bridge',
        label: 'Bridge'
    },
    {
        value: 'groundup',
        label: 'Ground Up'
    },
    {
        value: 'scrapebuild',
        label: 'Scrape / Build'
    },
    {
        value: 'rental',
        label: 'Rental'
    }
]
export const loanPurposeOptions = [
    {
        value: 'purchase',
        label: 'Purchase'
    },
    {
        value: 'purchaserehab',
        label: 'Purchase and Rehab'
    },
    {
        value: 'refinance',
        label: 'Refinance'
    },
    {
        value: 'cashoutrefinance',
        label: 'Cash-out Refinance'
    },
    // {
    //     value: 'construction',
    //     label: 'Construction'
    // }
]
export const loanTermOptions = []
for (let i = 3; i <= 24; i++) {
    loanTermOptions.push({
        value: i,
        label: `${i} Months`
    })
}
loanTermOptions.push({
    value: 36,
    label: `36 Months`
}, {
    value: 48,
    label: `48 Months`
}, {
    value: 60,
    label: `60 Months`
}, {
    value: 360,
    label: `360 Months`
})
export const exitStrategyOptions = [{
    value: 'fixandflip',
    label: 'Fix / Sell'
}, {
    value: 'fixandrent',
    label: 'Fix / Rent',
}, {
    value: 'buildandflip',
    label: 'Build and Sell',
}, {
    value: 'buildandrent',
    label: 'Build and Rent',
}, {
    value: 'refitoconventional',
    label: 'Refi to Conventional',
}, {
    value: 'refitobuild',
    label: 'Refi to Construction',
}, {
    value: 'refitorent',
    label: 'Refi to Rent',
}, {
    value: 'others',
    label: 'Others',
}]
export const YesNoOptions = [
    {
        value: true,
        label: 'Yes'
    },
    {
        value: false,
        label: 'No'
    }
]

export const DeclarationsYesNoOptions = [
    {
        value: 'yes',
        label: 'Yes'
    },
    {
        value: 'no',
        label: 'No'
    }
]

// Experience Page Constants

// Declarations Page Constants

export const bankruptcyTypeOptions = [
    {
        value: 'chapter7',
        label: 'Chapter 7'
    },
    {
        value: 'chapter11',
        label: 'Chapter 11'
    },
    {
        value: 'chapter12',
        label: 'Chapter 12'
    },
    {
        value: 'chapter13',
        label: 'Chapter 13'
    }
]
