import React, { Component } from "react";
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Button from 'react-bootstrap/Button'
import * as act from "../Store/actions"
import * as st from '../State'
import * as com from '../Common'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
    return {
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({

    addRemoteCoborrower: (i) => {
        dispatch(act.AddRemoteCoborrower(i))
    },
    removeRemoteCoborrower: (index) => {
        dispatch(act.RemoveRemoteCoborrower(index))
    },
    clearRemoteCoborrower: (index) => {
        dispatch(act.ClearRemoteCoborrower(index))
    },

    
    changeRemoteCoborrower: (index) => {
        dispatch(act.ClearRemoteCoborrower(index))
    },
    changeRemoteCoborrowerFirstName: (event, index) => {
        dispatch(act.ChangeRemoteCoborrowerFirstName(event.target.value, index))
    },
    changeRemoteCoborrowerLastName: (event, index) => {
        dispatch(act.ChangeRemoteCoborrowerLastName(event.target.value, index))
    },
    changeRemoteCoborrowerEmail: (event, index) => {
        dispatch(act.ChangeRemoteCoborrowerEmail(event.target.value, index))
    },
    changeRemoteCoborrowerStatus: (value, index) => {
        dispatch(act.ChangeRemoteCoborrowerStatus(value, index))
    },
    changeRemoteCoborrowerUse: (value, index) => {
        dispatch(act.ChangeRemoteCoborrowerUse(value, index))
    },

});

class RemoteCoborrowers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            refs: [],
            email: "",
            positionbyemail: {}
        }
        //this.props.clearRemoteCoborrower()
 
        if (this.props.application.remotecoborrowers.length === 0) {
            this.props.addRemoteCoborrower()
            this.state.refs.push(React.createRef())
        } else {
            for (let i = 0; i < this.props.application.remotecoborrowers.length; i++) {
                this.state.refs.push(React.createRef())
            }
        }
        if (this.props.application.married && this.props.application.remotecoborrowers.length > 2)
            this.props.removeRemoteCoborrower(2)

        let token = sessionStorage.getItem("ZeitroA")
        fetch('/auth/getprofile', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");

                    } else {
                        js.Method = parseInt(js.Method)
                        this.setState({ email: js.Email })
                        this.forceUpdate()
                    }
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });

        if (this.props.application.remotecoborrowers.length === 0 || this.props.application.remotecoborrowers[0].status !== "created") {
            fetch('/data/coborrowerstatus', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            }).then(
                response => {
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' +
                            response.status);
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                        return;
                    }
                    // Examine the text in the response
                    response.json().then(js => {
                        
                        let positionbyemail = {}
                        let proc = (a, i) => {
                            let email = a.Email
                            let position = a.Position 
                            positionbyemail[email] = position                        
                        }
                        js.map(proc)
                        this.setState( {positionbyemail: positionbyemail} )
                    });
                }
            ).catch((err) => {
                console.log('Fetch Error :', err);
            });

        }
    }
    clearCustomValidity = () => {
        for (let i = 0; i < this.state.refs.length; i++) {
            this.state.refs[i].current.setCustomValidity("")
        }
    }
    sendAccounts = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = JSON.stringify(this.props.application.remotecoborrowers)

        fetch('/data/requestcoborrowers', {
            method: 'POST',
            body: body,
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");

                    } else {
                        js.Method = parseInt(js.Method)
                        // set the states here
                        for (let i = 0; i < this.props.application.remotecoborrowers.length; i++) {
                            this.props.changeRemoteCoborrowerStatus(st.C_Invited, i)
                            this.props.changeRemoteCoborrowerUse(true, i)
                        }

                        this.setState({ email: js.Email })
                        this.forceUpdate()
                    }
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    handleSubmit = e => {
        if (this.props.application.remotecoborrowers[0].status !== "created") {
            return true
        }
        let test = {}

        for (let i = 0; i < this.state.refs.length; i++) {
            if (this.state.refs[i].current.value === this.state.email) {
                this.state.refs[i].current.setCustomValidity("Can't use borrower's address!")
                return false
            }
        }

        for (let i = 0; i < this.state.refs.length; i++) {
            if (this.state.refs[i].current.value in test) {
                test[this.state.refs[i].current.value] += 1
            } else
                test[this.state.refs[i].current.value] = 1
        }
        for (let i = 0; i < this.state.refs.length; i++) {
            if (test[this.state.refs[i].current.value] > 1) {
                this.state.refs[i].current.setCustomValidity("Email addresses must be unique!")
            }
        }

        if (Object.keys(test).length < this.state.refs.length) {
            return false
        }

        this.sendAccounts()
        return true
    }

    renderRemoteCoborrowers = (rb, i) => {
        let changeRemoteCoborrowerFirstName = (i) => {
            return e => this.props.changeRemoteCoborrowerFirstName(e, i)
        }
        let changeRemoteCoborrowerLastName = (i) => {
            return e => this.props.changeRemoteCoborrowerLastName(e, i)
        }
        let changeRemoteCoborrowerEmail = (i) => {
            return e => {
                this.clearCustomValidity()
                return this.props.changeRemoteCoborrowerEmail(e, i)
            }
        }
        let removeRemoteCoborrower = (i) => {
            return e => {
                this.setState({ refs: this.state.refs.slice(i) })
                return this.props.removeRemoteCoborrower(i)
            }
        }
        let addRemoteCoborrower = () => {
            this.state.refs.push(React.createRef())
            this.props.addRemoteCoborrower()
        }
        return (
            <Form.Row key={rb.key}>
                <Col  >
                    <Form.Group controlId={"first_name" + rb.key} className="text-left" >
                        <Form.Label className="text-left" >First Name:</Form.Label>
                        <Form.Control isValid={false} size="sm"
                            required
                            name={"first_name" + rb.key}
                            type="text"
                            defaultValue={rb.firstname}
                            onChange={changeRemoteCoborrowerFirstName(i)}
                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                        />
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please provide first name.
                    </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col  >
                    <Form.Group controlId={"last_name" + rb.key} className="text-left" >
                        <Form.Label className="text-left" >Last Name:</Form.Label>
                        <Form.Control isValid={false} size="sm"
                            required
                            name={"last_name" + rb.key}
                            type="text"
                            defaultValue={rb.lastname}
                            onChange={changeRemoteCoborrowerLastName(i)}
                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                        />
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please provide last name.
                    </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col  >
                    <Form.Group controlId={"email" + rb.key} className="text-left" >
                        <Form.Label className="text-left" >Email:</Form.Label>
                        <Form.Control isValid={false} size="sm"
                            required
                            name={"email" + rb.key}
                            type="text"
                            defaultValue={rb.email}
                            onChange={changeRemoteCoborrowerEmail(i)}
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                            ref={this.state.refs[i]}
                            onBlur={this.clearCustomValidity}
                        />
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please provide unique email.
                    </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs="auto" as="div" className="text-right aligh-top">
                    <i hidden={(this.props.application.remotecoborrowers.length >= 3) || (i !== this.props.application.remotecoborrowers.length - 1)} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addRemoteCoborrower} ></i>
                    <i hidden={this.props.application.remotecoborrowers.length < 3 && i === this.props.application.remotecoborrowers.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>

                    <i hidden={this.props.application.remotecoborrowers.length <= 1} className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeRemoteCoborrower(i)} ></i>
                    <i hidden={this.props.application.remotecoborrowers.length > 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                </Col>
            </Form.Row>
        )
    }
    reinvite = (email) => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = JSON.stringify({ email: email })

        fetch('/data/resendcoborrower', {
            method: 'POST',
            body: body,
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert(js.Text)
                    } else {
                        alert(js.Text)
                    }
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }

    renderInvitedCoborrowers = (rb, i) => {
        let changeRemoteCoborrowerUse = (e) => {
            let val = e.target.checked

            this.props.changeRemoteCoborrowerUse(val, i)
        }
        let reinvite = (e) => {
            this.reinvite(rb.email)
        }
        let getStatus = () => {
            let status = "Invited"
            if (rb.email in this.state.positionbyemail) {
                let pos = this.state.positionbyemail[rb.email]
  
                status = com.positionToStatus(pos)
            }
            return status
        }
        return (
            <div className="viewport mb-3 drop-shadow" key={rb.key}>
                <Form.Row>
                    <Col  >
                        <Form.Group controlId={"first_name" + rb.key} className="text-left" >
                            <Form.Label className="text-left" >First Name:</Form.Label><br />
                            <div style={{ fontSize: '1.2em' }}>{rb.firstname}</div>
                        </Form.Group>
                    </Col>
                    <Col  >
                        <Form.Group controlId={"last_name" + rb.key} className="text-left" >
                            <Form.Label className="text-left" >Last Name:</Form.Label><br />
                            <div style={{ fontSize: '1.2em' }}>{rb.lastname}</div>
                        </Form.Group>
                    </Col>
                    <Col  >
                        <Form.Group controlId={"email" + rb.key} className="text-left" >
                            <Form.Label className="text-left" >Email:</Form.Label><br />
                            <div style={{ fontSize: '1.2em' }}>{rb.email}</div>
                        </Form.Group>
                    </Col>
                    <Col  >
                        <Form.Group controlId={"email" + rb.key} className="text-left" >
                            <Form.Label className="text-left" >Status:</Form.Label><br />
        <div style={{ fontSize: '1.2em' }}>{getStatus()}</div>
                        </Form.Group>
                    </Col>
                    <Col className="justify-content-center align-self-center">
                        <input type="checkbox" value="on" onChange={changeRemoteCoborrowerUse}
                            checked={rb.usetoqualify} id={"use_" + rb.key}></input>&nbsp;<label className="divlink" htmlFor={"use_" + rb.key} >Use to qualify</label>
                    </Col>
                    {
                        rb.status === st.C_Invited ?
                            <Col className="justify-content-center align-self-center">
                                <Form.Group controlId={"email" + rb.key} className="text-left" >

                                    <Button onClick={reinvite} size="sm" type="button">Reinvite</Button>
                                </Form.Group>
                            </Col> : <Col></Col>
                    }
                </Form.Row>
            </div>

        )
    }


    render() {
        if (this.props.application.remotecoborrowers.length > 0 && this.props.application.remotecoborrowers[0].status !== st.C_Created) {
            return (
                <div className="mx-2 px-2 text-left mb-3">
                    <h2 className="heading text-wrap">Your invited coborrowers:
                </h2>
                    <div className="text-left pt-2  ">
                        <div className="pb-2 ">
                            {this.props.application.remotecoborrowers.map(this.renderInvitedCoborrowers)}
                        </div>
                    </div>
                </div>
            )
        } else
            return (
                <div className="mx-2 px-2 text-left mb-3">
                    <h2 className="heading text-wrap">Invite your co-applicants{this.props.application.married ? " (except your spouse)" : ""} to fill out applications online
                </h2>
                    <div className="text-left pt-2  ">
                        <div className="pb-2 ">
                            <div className="viewport">
                                <div className="w-100 align-middle  text-wrap sectionquestion"></div>
                                {this.props.application.remotecoborrowers.map(this.renderRemoteCoborrowers)}
                            </div>
                        </div>
                    </div>
                </div>
            )
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RemoteCoborrowers)    
