import React, { Component } from "react";

import '../App.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { PennyInput } from '../NumericalInput'
import state from '../Store/index'
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"

import { getCurrentState } from '../Store'

const mapStateToProps = (state) => {
    return {
        transaction: state.application.transaction,
        product: state.application.product,
        application: state.application,
        selection: state.application.selection,
        EFGH: 0,
    }
}
const sectionToTitle = {
    canshopservices: "Services you can shop for",
    cannotshopservices: "Services you can't shop for",
    othercosts: "Other",
    prepaids: "Prepaids"
}
const mapDispatchToProps = (dispatch) => ({
    updateProduct: (event, verb) => {
        dispatch(act.UpdateProduct(event.target.value, verb))
    },
    updateSelectedLoanField: (input, verb) => {
        dispatch(act.UpdateSelectedLoanField(input, verb))
    },
    updateTransaction: (event, verb) => {
        dispatch(act.UpdateTransaction(event.target.value, verb))
    },
    updateCannotShopServices: (name, amount) => {
        dispatch(act.UpdateCannotShopServices(name, amount))
    },
    updateCanShopServices: (name, amount) => {
        dispatch(act.UpdateCanShopServices(name, amount))
    },
    updateFees: (name, amount) => {
        dispatch(act.UpdateFees(name, amount))
    },
    updateOriginalCosts: (name, amount) => {
        dispatch(act.UpdateOriginalCosts(name, amount))
    },
    updateOtherCosts: (name, amount) => {
        dispatch(act.UpdateOtherCosts(name, amount))
    },
    updatePaymentPeriod: (name, service, period) => {
        dispatch(act.UpdatePaymentPeriod(name, service, period))
    },
    updatePaymentAmount: (name, service, amount) => {
        dispatch(act.UpdatePaymentAmount(name, service, amount))
    },
    addSelectedLoanService: (service, chargename) => {
        dispatch(act.AddSelectedLoanService(service, chargename))
    },
    removeSelectedLoanService: (service, chargename) => {
        dispatch(act.RemoveSelectedLoanService(service, chargename))
    },
    addSelectedLoanCharge: (service, chargename) => {
        dispatch(act.AddSelectedLoanCharge(service, chargename))
    },
    removeSelectedLoanCharge: (service, chargename) => {
        dispatch(act.RemoveSelectedLoanCharge(service, chargename))
    },
    addSelectedLoanPrepaid: (service, chargename, period) => {
        dispatch(act.AddSelectedLoanPrepaid(service, chargename, period))
    },
    removeSelectedLoanPrepaid: (service, chargename, period) => {
        dispatch(act.RemoveSelectedLoanPrepaid(service, chargename, period))
    },
    addSelectedLoanEstimatedClosingCosts: (cc) => {
        dispatch(act.AddSelectedLoanEstimatedClosingCosts(cc))
    },    
    
});

export default class Fees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sumofCannotShopServices: 0,
            sumofCanShopServices: 0,
            sumofOtherCosts: 0,
            ABC: 0,
            EFGH: 0,
            prepaid: 0,
            escrow: 0,
            fees: 0,
            total: 0,
            cashtoclose: 0,
            showDelete: false,
            showAdd: false,
            newfield: "",
            showAddPrepaid: false,
            period: "daily",
        }
        // calculate the set of tasks
        console.log("Loan: state" + state)
        this.total = 0
        this.DI = 0
        if(null == this.props.application.selection.selectedloan.estimatedclosingcosts) {
            this.props.application.selection.selectedloan.estimatedclosingcosts = this.props.application.selection.estimatedclosingcosts
            this.props.addSelectedLoanEstimatedClosingCosts(this.props.application.selection.estimatedclosingcosts)
            getCurrentState()
        }
    }
    recalculate = () => {
        this.recalculateNonshop()
        this.recalculateShop()
        this.recalculateOther()
        this.recalculatePrepaid()
        this.recalculateEscrow()
        this.recalculateFees()

        let lender_fee = parseFloat(this.props.application.selection.selectedloan.lender_fee )
        //let broker_fee = Math.floor(0.01 * parseFloat(this.props.application.selection.loansize))
        let broker_fee = parseFloat(this.props.application.selection.selectedloan.lender_credit)
        if (broker_fee > 0)
            this.A = lender_fee + broker_fee
        else
            this.A = lender_fee


        this.ABC = this.sumofCanShopServices + this.sumofCannotShopServices
            + this.A



        this.setState({ ABC: this.ABC })

        this.EFGH = this.fees + this.prepaid + this.escrow + this.sumofOtherCosts
        this.setState({ EFGH: this.EFGH })

        let DI = this.ABC + this.EFGH
        this.DI = DI
        let total
        if (this.props.application.selection.selectedloan.lender_credit <= 0) {
            total = DI + this.props.application.selection.selectedloan.lender_credit
        } else {
            total = DI
        }
        this.remaining_credits = 0
        if (total < 0) {
            this.remaining_credits = total
            total= 0
        }

        this.total = total
        this.setState({ total })

        let down = 0
        switch (this.props.application.property.purpose) {
            case "purchase":
                down = this.props.selection.selectedloan.downpayment
                break;
            case "refinance":
                down = this.props.selection.selectedloan.paydown
                break;
            default:
                down = 0
                break

        }

        this.cashtoclose = this.total - this.getFee("closingcostsfinanced")
            + down
            - this.getFee("deposit") -
            this.getFee("fundsforborrower") - this.getFee("sellerscredits") - this.getFee("adjustmentsothercredits")
        this.setState({ cashtoclose: this.cashtoclose })

    }
    componentDidMount() {
        this.recalculate()
    }
    getFee = (name) => {
        return this.props.selection.selectedloan.estimatedclosingcosts[name]
    }
    getCanShopService = (name) => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return ""
        let services = this.props.selection.selectedloan.estimatedclosingcosts.canshopservices
        for (let i = 0; i < services.length; i++) {
            if (services[i].servicename === name) {
                return services[i]["estimateamount"]
            }
        }
        return ""
    }
    getCantShopService = (name) => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return ""

        let services = this.props.selection.selectedloan.estimatedclosingcosts.cannotshopservices
        for (let i = 0; i < services.length; i++) {
            if (services[i].servicename === name) {
                return services[i]["estimateamount"]
            }
        }
        return ""
    }
    getOtherService = (name) => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return ""

        let services = this.props.selection.selectedloan.estimatedclosingcosts.othercosts
        for (let i = 0; i < services.length; i++) {
            if (services[i].chargename === name) {
                return services[i]["estimateamount"]
            }
        }
        return ""
    }

    getPeriod = name => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return 
        let obj = this.props.selection.selectedloan.estimatedclosingcosts
        return obj[name].prepaidmonths
    }
    getValue = name => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return 
        let obj = this.props.selection.selectedloan.estimatedclosingcosts
        return obj[name].amount
    }
    recalculateFees = () => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return 
    
        let fees = this.getFee("recordingandgovernmentfees") + this.getFee("transfertax")
        this.fees = fees
        this.setState({ fees })
    }
    recalculateOther = () => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return 

        let services = this.props.selection.selectedloan.estimatedclosingcosts.othercosts
        let sum = 0

        for (let i = 0; i < services.length; i++) {
            sum += services[i]["estimateamount"]
        }
        this.sumofOtherCosts = sum
        this.setState({ sumofOtherCosts: sum })
    }
    recalculateNonshop = () => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return 

        let services = this.props.selection.selectedloan.estimatedclosingcosts.cannotshopservices
        let sum = 0
        for (let i = 0; i < services.length; i++) {
            sum += parseFloat(services[i]["estimateamount"])
        }
        this.sumofCannotShopServices = sum
        this.setState({ sumofCannotShopServices: sum })
    }
    recalculateShop = () => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return 

        let services = this.props.selection.selectedloan.estimatedclosingcosts.canshopservices
        let sum = 0
        for (let i = 0; i < services.length; i++) {
            sum += services[i]["estimateamount"]
        }
        this.sumofCanShopServices = sum

        this.setState({ sumofCanShopServices: sum })
    }

    recalculatePrepaid = () => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return 

        let cc = this.props.application.selection.selectedloan.estimatedclosingcosts

        let total = 0
        Object.keys(cc.prepaids).forEach(function (key) {
            total += parseFloat(cc.prepaids[key].amount) * parseFloat(cc.prepaids[key].prepaidperiods)
        });
        this.prepaidFees = total
        this.setState({ prepaid: total })

    }

    recalculateEscrow = () => {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return 

        let cc = this.props.application.selection.selectedloan.estimatedclosingcosts

        let total = 0
        Object.keys(cc.initialescrowpaymentsatclosing).forEach(function (key) {
            total += parseFloat(cc.initialescrowpaymentsatclosing[key].amount) * parseFloat(cc.initialescrowpaymentsatclosing[key].prepaidperiods)
        });
        this.prepaidFees = total
        this.setState({ escrow: total })

    }
    deleteCost = (service, chargename) => {
        if (service === "othercosts")
            this.props.removeSelectedLoanCharge(service, chargename)
        else
            this.props.removeSelectedLoanService(service, chargename)
        this.setState({ showDelete: false })
    }
    addCost = (service, chargename) => {
        if (service === "othercosts")
            this.props.addSelectedLoanCharge(service, chargename)
        else
            this.props.addSelectedLoanService(service, chargename)
        this.setState({ showAdd: false })
    }
    addPrepaid = (service, chargename, period) => {

        this.props.addSelectedLoanPrepaid(service, chargename, period)
        this.setState({ showAddPrepaid: false })
    }
    render() {
        if(null == this.props.selection.selectedloan.estimatedclosingcosts)
            return <div>Reloading...</div>

        let onUpdateCanShop = (verb) => {
            return e => {
                let val = parseInt(e.target.value)
                this.props.updateCanShopServices(verb, val)
            }
        }
        let onUpdateCantShop = (verb) => {
            return e => {
                let val = parseInt(e.target.value)
                this.props.updateCannotShopServices(verb, val)
            }
        }
        let onUpdateOtherCosts = (verb) => {
            return e => {
                let val = parseInt(e.target.value)

                this.props.updateOtherCosts(verb, val)
            }

        }
        let onUpdateFee = (verb) => {
            return e => {

                let val = parseInt(e.target.value)
                this.props.updateFees(verb, val)
            }

        }

        let renderRow = (title, defval, onupdate, section = false, showminus = true) => {
            let click = e => {
                this.setState({ showDelete: true, section: section, item: title })
            }
            return <Row className=" pt-2">
                <Col >{title}</Col>
                <Col md="auto" className="mr-1 pr-0 text-right">
                    <PennyInput name={title} size="sm"
                        defaultValue={defval}
                        onChange={onupdate}

                        step={1}
                        min={0}
                        onBlur={this.recalculate}
                    />

                </Col><Col md="auto" className="ml-0 pl-0 pt-2">
                    {showminus ? <i class="fahover far fa-minus-square " onClick={click}></i> : <i>&nbsp;</i>}

                </Col>
            </Row>
        }

        let fundsFromBorrower = () => {
            let defval = 0
            let onupdate
            switch (this.props.application.property.purpose) {
                case "purchase":
                    defval = this.props.selection.selectedloan.downpayment
                    onupdate = e => {
                        this.props.updateSelectedLoanField(parseInt(e.target.value), "downpayment")
                    }
                    break;
                case "refinance":
                    defval = this.props.selection.selectedloan.paydown
                    onupdate = e => {
                        this.props.updateSelectedLoanField(parseInt(e.target.value), "paydown")
                    }
                    break;
                default:
                    defval = 0
                    onupdate = () => { }
                    break

            }

            return <Row className=" pt-2">
                <Col >Downpayment/Funds from borrower</Col>
                <Col md="auto" className="mr-1 pr-0 text-right">
                    <PennyInput name="purpose" size="sm"
                        defaultValue={defval}
                        onChange={onupdate}

                        step={1}
                        min={0}
                        onBlur={this.recalculate}
                    />

                </Col>
                <Col md="auto" className="ml-0 pl-0 pt-2"><i >&nbsp;</i></Col>

            </Row>
        }


        let renderCannotShopFor = () => {
            let cc = this.props.application.selection.selectedloan.estimatedclosingcosts

            let total = 0
            Object.keys(cc.cannotshopservices).forEach(function (key) {
                total += parseFloat(cc.cannotshopservices[key].estimateamount)
            });
            this.cantShopForFee = total


            let process = (key, index) => {
                return renderRow(cc.cannotshopservices[key].servicename, this.getCantShopService(cc.cannotshopservices[key].servicename),
                    onUpdateCantShop(cc.cannotshopservices[key].servicename), "cannotshopservices", true)
            }

            return (
                <div>
                    {Object.keys(cc.cannotshopservices).map(process)}
                </div>
            )
        }
        let renderCanShopFor = () => {
            let cc = this.props.application.selection.selectedloan.estimatedclosingcosts

            let total = 0
            Object.keys(cc.canshopservices).forEach(function (key) {
                total += parseFloat(cc.canshopservices[key].estimateamount)
            });
            this.cantShopForFee = total


            let process = (key, index) => {

                return renderRow(cc.canshopservices[key].servicename, this.getCanShopService(cc.canshopservices[key].servicename),
                    onUpdateCanShop(cc.canshopservices[key].servicename), "canshopservices")
            }

            return (
                <div>
                    {Object.keys(cc.canshopservices).map(process)}
                </div>
            )
        }
        let renderOtherCosts = () => {
            let cc = this.props.application.selection.selectedloan.estimatedclosingcosts

            let total = 0
            Object.keys(cc.othercosts).forEach(function (key) {
                total += parseFloat(cc.othercosts[key].estimateamount)
            });
            this.othercostsFee = total


            let process = (key, index) => {

                return renderRow(cc.othercosts[key].chargename, this.getOtherService(cc.othercosts[key].chargename),
                    onUpdateOtherCosts(cc.othercosts[key].chargename), "othercosts")
            }

            return (
                <div>
                    {Object.keys(cc.othercosts).map(process)}
                </div>
            )
        }

        let getGovernmentFees = () => {
            return this.getFee("recordingandgovernmentfees") + this.getFee("transfertax")
        }
        let prepaid = (title, monthvalue, onMonthChange, value, onChange, period, prepaids) => {
            let onDelete = () => {
                this.props.removeSelectedLoanPrepaid(prepaids, title, period)
            }
            let onMonthChangePrepaid = e => {
                this.props.updatePaymentPeriod(title, prepaids, e.target.value);
            }
            let onChangePrepaid = e => {
                this.props.updatePaymentAmount(title, prepaids, e.target.value);
            }

            let prepaidValue = com.commaizeFloat((parseInt(monthvalue) * parseFloat(value)).toFixed(2))
            if(title !== "Prepaid Interest")
                prepaidValue = com.commaizeFloat(Math.ceil(parseInt(monthvalue) * parseFloat(value)).toFixed(2))
            return <div>
                <Row className=" pt-2">
                    <Col >{title}</Col><Col></Col>
                </Row><Row>

                    <Col md={1}>
                        <Form.Control
                            size="sm"
                            style={{ width: '4em' }}
                            value={monthvalue}
                            onChange={onMonthChangePrepaid}
                            type="text"
                            onBlur={this.recalculate}
                            pattern="^.+$"
                        />
                    </Col>
                    <Col md={2} className="pl-4 pt-1 mr-1">{period === "daily" ? "days" : "months"} at</Col>
                    <Col md={3} className="text-nowrap d-flex">
                        <PennyInput style={{ width: '4em' }}
                            size="sm"
                            onBlur={this.recalculate}


                            defaultValue={value}
                            onChange={onChangePrepaid}
                            type="text"
                            pattern="^.+$"
                        />
                    </Col>
                    <Col></Col>
                    <Col md={2} className="text-right mr-0 pt-1 pr-1">${prepaidValue}</Col>
                    <Col md="auto" className="ml-0 pl-0  pt-1 aligh-top"><i onClick={onDelete} class="fahover far fa-minus-square"></i></Col>
                </Row>
            </div>
        }
        let onMonthChange = (paymentname, section) => {
            return e => { }
        }
        let onAmountChange = (paymentname, section) => {
            return e => { }
        }
        let renderPrepaids = () => {
            let cc = this.props.application.selection.selectedloan.estimatedclosingcosts

            let total = 0
            Object.keys(cc.prepaids).forEach(function (key) {
                total += Math.ceil(parseFloat(cc.prepaids[key].amount) * parseFloat(cc.prepaids[key].prepaidperiods))
            });
            this.prepaid = total

            let process = (key, index) => {
                return prepaid(cc.prepaids[key].paymentname, cc.prepaids[key].prepaidperiods,
                    onMonthChange(cc.prepaids[key].paymentname, "prepaids"),
                    cc.prepaids[key].amount.toFixed(2), onAmountChange(cc.prepaids[key].paymentname, "prepaids"), cc.prepaids[key].prepaidperiods, "prepaids")
            }

            return (
                <div>
                    {Object.keys(cc.prepaids).map(process)}
                </div>
            )
        }
        let renderPrepaidsEscrow = () => {
            let cc = this.props.application.selection.selectedloan.estimatedclosingcosts

            let total = 0
            Object.keys(cc.initialescrowpaymentsatclosing).forEach(function (key) {
                total += parseFloat(cc.initialescrowpaymentsatclosing[key].amount) * parseFloat(cc.initialescrowpaymentsatclosing[key].prepaidperiods)
            });
            this.escrow = total

            let process = (key, index) => {
                return prepaid(cc.initialescrowpaymentsatclosing[key].paymentname, cc.initialescrowpaymentsatclosing[key].prepaidperiods,
                    onMonthChange(cc.initialescrowpaymentsatclosing[key].paymentname, "initialescrowpaymentsatclosing"),
                    cc.initialescrowpaymentsatclosing[key].amount, onAmountChange(cc.initialescrowpaymentsatclosing[key].paymentname, "initialescrowpaymentsatclosing"),
                    cc.initialescrowpaymentsatclosing[key].prepaidperiods, "initialescrowpaymentsatclosing")
            }

            return (
                <div>
                    {Object.keys(cc.initialescrowpaymentsatclosing).map(process)}
                </div>
            )
        }
        let addPrepaids = section => {
            return e => {
                this.setState({ showAddPrepaid: true, section })
            }
        }
        let addService = section => {
            return e => {
                this.setState({ showAdd: true, section })
            }
        }
        let newfield = e => {
            this.setState({ newfield: e.target.value })
        }

        let lender_fee = parseFloat(this.props.application.selection.selectedloan.lender_fee)
        let broker_fee = parseFloat(this.props.application.selection.selectedloan.lender_credit)
        if (broker_fee > 0)
            this.originationFees = lender_fee + broker_fee
        else
            this.originationFees = lender_fee
     
        return (
            <div className="mb-5 text-left">
                <h2 className="mt-2 mb-4 ml-4">Closing Costs</h2>
                <Modal show={this.state.showDelete} onHide={() => this.setState({ showDelete: false })} centered >
                    <Modal.Header closeButton>
                        <Modal.Title><div style={{ fontSize: '0.8em' }}>Attention!</div></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you really want to delete {this.state.item} from {sectionToTitle[this.state.section]}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.setState({ showDelete: false })}>No, please cancel</Button>
                        <Button variant="primary" onClick={() => this.deleteCost(this.state.section, this.state.item)}>Yes, I do</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showAdd} onHide={() => this.setState({ showAdd: false })} centered >
                    <Modal.Header closeButton>
                        <Modal.Title><div style={{ fontSize: '0.8em' }}>Add a new entry to {sectionToTitle[this.state.section]}</div></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId={"addfield"} className="text-left  m-0 p-0" >
                            <Form.Control isValid={false} size="sm"
                                required
                                name={"addfield"}
                                type="text"
                                defaultValue=""
                                onChange={newfield}

                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide issuer.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.setState({ showAdd: false })}>Cancel</Button>
                        <Button variant="primary" onClick={() => this.addCost(this.state.section, this.state.newfield)}>OK</Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.showAddPrepaid} onHide={() => this.setState({ showAddPrepaid: false })} centered >
                    <Modal.Header closeButton>
                        <Modal.Title><div style={{ fontSize: '0.8em' }}>Add a new payment to {sectionToTitle[this.state.section]}</div></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId={"addfield"} className="text-left  m-0 p-0" >
                            <Form.Label className="text-left  text-nowrap" >Name:</Form.Label>

                            <Form.Control isValid={false} size="sm"
                                required
                                name={"addfield"}
                                type="text"
                                defaultValue=""
                                onChange={newfield}

                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                            />

                        </Form.Group>

                        <Form.Group controlId="MaritalStatus" className="text-left" >
                            <Form.Label className="text-left" >Period:</Form.Label>
                            <Form.Control required name="maritalstatus" as="select" size="sm"
                                defaultValue={this.state.period}
                                onChange={e => this.setState({ period: e.target.value })}
                            >

                                <option value="daily">Daily</option>
                                <option value="monthly">Monthly</option>

                            </Form.Control>

                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.setState({ showAddPrepaid: false })}>Cancel</Button>
                        <Button variant="primary" onClick={() => this.addPrepaid(this.state.section, this.state.newfield, this.state.period)}>OK</Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col>
                        <div className=" text-left">
                            <div className="viewwindow  ml-3 ">
                                <h4 className="sectionquestion">Loan Costs:</h4>
                                <Row className=" ">
                                    <Col className="ml-3 roundedtab text-600">A. Origination Charges:</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600" md="auto">${com.commaizeFloat(this.originationFees.toFixed(2))}
                                    </Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>
                                <Row className=" pt-2">
                                    <Col >Lender Origination Fee</Col>
                                    <Col md="auto"> ${com.commaizeFloat(this.props.application.selection.selectedloan.lender_fee.toFixed(2))}</Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>
                                {broker_fee > 0 ?
                                    <Row>
                                        <Col>Loan points
                                        </Col>
                                        <Col md="auto" >
                                            ${com.commaizeFloat(broker_fee.toFixed(2))}
                                        </Col>
                                        <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                    </Row> : ""}

                                <Row className="mt-3">
                                    <Col className="ml-3 roundedtab text-600">B. Services You Cannot Shop For:</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600 mr-1 pr-0" md="auto">${com.commaize(this.state.sumofCannotShopServices)}</Col>
                                    <Col md="auto" className="ml-0 pl-0"><i onClick={addService("cannotshopservices")} class="fahover far fa-plus-square"></i></Col>
                                </Row>
                                {renderCannotShopFor()}



                                <Row className="mt-4">
                                    <Col className="ml-3 roundedtab text-600">C. Services You Can Shop For:</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600" md="auto">${com.commaize(this.state.sumofCanShopServices)}</Col>
                                    <Col md="auto" className="ml-0 pl-0"><i onClick={addService("canshopservices")} class="fahover far fa-plus-square"></i></Col>                                </Row>
                                {renderCanShopFor()}


                                <Row className="mt-4">
                                    <Col className="ml-3 roundedtab text-600">D. Total Loan Costs (A+B+C):</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600" md="auto">${com.commaizeFloat(this.state.ABC.toFixed(2))}</Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>
                            </div>

                        </div>
                    </Col>
                    <Col>
                        <div className=" text-left">


                            <div className="viewwindow mr-3 p-2">
                                <h4 className="sectionquestion">Other Costs:</h4>

                                <Row className="">
                                    <Col className="ml-3 roundedtab text-600">E. Taxes and Other Government Fees:</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600" md="auto">${com.commaize(getGovernmentFees())}</Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>
                                {renderRow("Recording Fees and Other Taxes",
                                    this.getFee("recordingandgovernmentfees"), onUpdateFee("recordingandgovernmentfees"),
                                    false, false)}

                                {renderRow("Transfer Taxes",
                                    this.getFee("transfertax"), onUpdateFee("transfertax"), false, false)}


                                <Row className="mt-4 ">
                                    <Col className="ml-3 roundedtab text-600">F. Prepaids:</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600 pl-0 pr-1 text-right" md="auto">${com.commaizeFloat(this.state.prepaid.toFixed(2))}</Col>
                                    <Col md="auto" className="ml-0 pl-0"><i onClick={addPrepaids("prepaids")} class="fahover far fa-plus-square"></i></Col>
                                </Row>
                                {renderPrepaids()}


                                <Row className="mt-4">
                                    <Col className="ml-3 roundedtab text-600">G. Initial Escrow Payments at Closing:</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600 pr-1" md="auto">${this.state.escrow}</Col>
                                    <Col md="auto" className="ml-0 pl-0"><i onClick={addPrepaids("initialescrowpaymentsatclosing")} class="fahover far fa-plus-square"></i></Col>
                                </Row>
                                {renderPrepaidsEscrow()}

                                <Row className="mt-4">
                                    <Col className="ml-3 roundedtab text-600">H. Other:</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600 pr-1" md="auto">${com.commaize(this.state.sumofOtherCosts)}</Col>
                                    <Col md="auto" className="ml-0 pl-0"><i onClick={addService("othercosts")} class="fahover far fa-plus-square"></i></Col>
                                </Row>

                                {renderOtherCosts()}


                                <Row className="mt-4">
                                    <Col className="ml-3 roundedtab text-600">I. Total Other Costs (E + F + G + H):</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600" md="auto">${com.commaizeFloat(this.state.EFGH.toFixed(2))}</Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>

                                <Row className="mt-4">
                                    <Col className="ml-3 roundedtab text-600">J. Total Closing Costs:</Col>
                                    <Col className="tabajacent"></Col>
                                    <Col className="tabajacent text-600" md="auto">${com.commaizeFloat(this.state.total.toFixed(2))
                                    }</Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>
                                <Row className=" pt-2">
                                    <Col >D + I</Col>
                                    <Col md="auto"> ${com.commaizeFloat( (this.DI).toFixed(2) )}</Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>
                                {this.props.application.selection.selectedloan.lender_credit > 0 ?
                                    "" :
                                    <Row>
                                        <Col>Lender Credit (points)
                                        </Col>
                                        <Col md="auto" >
                                            -${com.commaizeFloat(-parseFloat(this.props.application.selection.selectedloan.lender_credit).toFixed(2))}
                                        </Col>
                                        <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>                                        
                                    </Row>
                                }
                                {this.remaining_credits < 0 ?
                                    <Row>
                                        <Col>Remaining Lender Credit
                                        </Col>
                                        <Col md="auto" >
                                            -${com.commaizeFloat(-this.remaining_credits.toFixed(2))}
                                        </Col>
                                        <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>                                        
                                    </Row>

                                    : ""}
                                <Row className="mt-4">
                                    <Col className="text-600">Calculating Cash to Close:</Col>
                                    <Col className="text-600" md="auto"></Col>
                                </Row>
                                <Row className=" pt-2">
                                    <Col >Total Closing Costs (J)</Col>
                                    <Col md="auto"> ${com.commaizeFloat(this.state.total.toFixed(2))}</Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>
                                {renderRow("Closing Costs Financed:",
                                    this.getFee("closingcostsfinanced"), onUpdateFee("closingcostsfinanced"), null, false)}
                                {fundsFromBorrower()}

                                {renderRow("Deposit",
                                    this.getFee("deposit"), onUpdateFee("deposit"), null, false)}
                                {renderRow("Funds for Borrower",
                                    this.getFee("fundsforborrower"), onUpdateFee("fundsforborrower"), null, false)}
                                {renderRow("Seller's Credits",
                                    this.getFee("sellerscredits"), onUpdateFee("sellerscredits"), null, false)}
                                {renderRow("Adjustments/Other credits",
                                    this.getFee("adjustmentsothercredits"), onUpdateFee("adjustmentsothercredits"), null, false)}

                                <hr />
                                <Row className="mt-4">
                                    <Col className="text-600">Cash To Close:</Col>
                                    <Col className="text-600" md="auto">${com.commaizeFloat(this.state.cashtoclose.toFixed(2))}</Col>
                                    <Col md="auto" className="ml-0 pl-0 pt-2"><i ></i></Col>
                                </Row>
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

Fees = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Fees)
