import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function LoanAmountChart(props) {
    console.log(props.statistics)
  const data = [
    {
      name: "0-400k",
      subscribers: props.statistics.lm1,
    },
    {
      name: "400k-800k",
      subscribers: props.statistics.lm2,
    },
    {
      name: "800k-1.2m",
      subscribers: props.statistics.lm3,
    },
    {
      name: "1.2m-2m",
      subscribers: props.statistics.lm4,
    },
    {
      name: "2m+",
      subscribers: props.statistics.lm5,
    },
  ];

  return (
    <div>
      <ResponsiveContainer width={"100%"} aspect={4/1}>
        <BarChart data={data}>
          <Bar
            dataKey="subscribers"
            fill="#8884d8"
            width={120}
            height={40}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 0,
            }}
          />
          <XAxis dataKey="name" />
          <CartesianGrid strokeDasharray="5 5" />
          <Tooltip/>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
