import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux'
import { Pagination } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import DocumentAnalysis from './Analysis';
import Id from './Forms/Id';
import W2 from './Forms/W2';
import Greencard from './Forms/Greencard';
import Bankstatement from './Forms/Bankstatement';
import Paystub from './Forms/Paystub';
import Form1040 from './Forms/Form1040';
import Form1120 from './Forms/Form1120';
import Form1120s from './Forms/Form1120s';
import Form1065 from './Forms/Form1065';
import { Button, Skeleton } from 'antd';
import ImagePreview from './ImagePreview'
import { documents, getDocType } from '../Common';
import '../Utils.css';
import './DocumentsVerification.css'
import ParsedData from './Forms/ParsedData';
export default function DocumentsVerification(props) {
  const [docs, setDocs] = useState(props.docs || {});
  
  const [options, setOptions] = useState(props.options || []);
  const [selected, setSelected] = useState(props.currentDoc.filename || '');
  const [magnifier, setMagnifier] = useState(1);
  const [magnifierAvailable, setMagnifierAvailable] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [showCompare, setShowCompare] = useState(false);
  const [boxConfig, setBoxConfig] = useState({});
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const isMobile = window.innerWidth< 576
  const borrower = useSelector(state => state.borrower);
  const comparibleDocTypes = ["paystub"]

  const [docType, setDocType] = useState(getDocType(props.currentDoc.filename, props.currentDoc.comment, props.currentDoc.parsedData) || '');


  useEffect(() => {
    setShowCompare(false)
    setSelected(props.currentDoc.filename)
    setDocType(getDocType(props.currentDoc.filename, props.currentDoc.comment, props.currentDoc.parsedData))
  }, [props.currentDoc.filename])

  const changeTextInputHandler = (filename, value, path) => {
    let docsCopy = { ...docs }
    let fieldToUpdate = docsCopy[selected]
    for (let i = 0; i < path.length - 1; i++) {
      fieldToUpdate = fieldToUpdate[path[i]];
    }
    fieldToUpdate[path[path.length - 1]] = value
    setDocs(docsCopy)
  }

  const onFieldFocus = (bbox, page) => {
    setBoxConfig({ bbox, page });
    if (page !== 0) {
      setPageNumber(page)
    }
  }

  const changeMagnifierAccessibility = useCallback((value) => {
    setMagnifierAvailable(value);
    if (!value) setMagnifier(1);
  }, [])

  const formRenderer = (doc, fn) => {
    let m = {
      '': null,
      'id': <Id doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'w2': <W2 doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'greencard': <Greencard doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'bankstatement': <Bankstatement doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'paystub': <Paystub doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'form1040': <Form1040 doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'form1120': <Form1120 doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'form1120s': <Form1120s doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'form1065': <Form1065 doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      "mobilehomeloanapplication": <ParsedData parsedData={props.currentDoc.parsedData} />
    }
    return m[doc]
  };




  const mapFilenameToForm = (selectedfilename) => {
    if (selectedfilename==="") return ""
    if (docType) return formRenderer(docType, selectedfilename)
    return <div>Document verification is not available for this type of document</div>
  }

  const verifyDoc = () => {
    setVerificationLoading(true);

    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/verifydocument/' + props.currentDoc.id, {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(docs[selected]),
    }).then(response => {
      if (response.status !== 200) {
        alert('Something went wrong, please try again later.');
        setVerificationLoading(false);
        return;
      }
      setOptions(prev => prev.map(el => el.title === selected ? { ...el, verified: true } : { ...el }))
      setVerificationLoading(false);
      
      // only show comparison for specific document types
      // now paystub only
      if (comparibleDocTypes.includes(docType)) {
        setShowCompare(true)
      } else {
        props.onCancel()
      }
    })
  }

  const onAnalysisBack = () => {
    setShowCompare(false)
  }
  const onCancel = () => {
    setShowCompare(false)
    props.onCancel()
  }
  const onChangePage = (page, pageSize) => {
    setPageNumber(page)
  }
  const onLoadSuccess = (numPages) => {
    setNumPages(numPages)
    setPageNumber(1)
    setMagnifier(1)
    setBoxConfig({})
  }
  const handleZoom = (type) => {
    setMagnifier(prevScale => {
      if (type === 'add') {
        return +(Math.min(prevScale + 0.1, 3).toFixed(1))
      } else {
        return +(Math.max(prevScale - 0.1, 0.5).toFixed(1))
      }
    })
  }
  return (

    <div className='documents-verification h-100'>
      {showCompare ? <div className="d-flex h-100">
        <DocumentAnalysis docType={docType} extractedData={docs[selected]} onBack={onAnalysisBack} onCancel={onCancel}/>
      </div> :
      <div className="d-flex h-100">
        <div className="preview-box">
          <ImagePreview boxConfig={boxConfig} magnifier={magnifier !== 1} scaleFactor={magnifier} onLoadSuccess={onLoadSuccess} pageNumber={pageNumber} changeMagnifierAccessibility={changeMagnifierAccessibility} fileName={selected} fileID={props.currentDoc.id} />
          <div className='document-preview-bottom-bar'>
              <div className="document-preview-pagination">
                  <Pagination size='small' simple current={pageNumber} pageSize={1} total={numPages} onChange={onChangePage} />
              </div>
              <div className="document-preview-scale">
                  <div className='zoom-min'><MinusOutlined style={{fontSize: 16}} onClick={() =>handleZoom('min')} /></div>
                  <div className='zoom-value'>{Math.round(magnifier * 100)}%</div>
                  <div className='zoom-add'><PlusOutlined style={{fontSize: 16}} onClick={() => handleZoom('add')} /></div>
              </div>
          </div>
        </div>
        <div className="d-flex flex-column" style={{flex:1}}>
            <div className='flex-grow-1' style={{overflowY:"scroll"}}>{mapFilenameToForm(selected)}</div>
            <div className='text-right pr-3 pt-3 border-top'><Button type='primary' loading={verificationLoading} onClick={()=>verifyDoc()}>{comparibleDocTypes.includes(docType) ? "Confirm" : "Finish verification"}</Button></div>
        </div>
      </div>}

    </div>
  );
}